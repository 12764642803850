/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, isArray, intersection, isNumber, isEmpty } from 'lodash';
import { Input, Alert, Button, Tag, Spin, Select,InputNumber } from 'antd';
import moment from 'moment';

import {
  handleInitEmailEditor,
  setBeefreeInstance,
  setConfirmCloseModalPath,
  resetState,
  setDraftData,
  handleSaveEmail,
  handleSendTestEmail,
  handleFetchStats,
  setEmailSending,
  handleFetchEmails,
} from '../actions';
import FileUploader from '../../../component/FileUploader';
import notify from '../../../utils/notify';

import {
  getFileSize,
  isTotalAttachmentSizeInLimit,
  mergeTags,
  TIME_FORMAT,
  DATE_FORMAT,
  getSpecialLinks,
} from '../utils';
import { getUploadUrl } from '../../../utils/getUploadUrl';

import {  standardUserTypeOptions } from '../../../utils/helpers';
import ConfirmCloseModal from './ConfirmCloseModal';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import EmailConfig from './EmailConfig';
import MergeTagsModal from './MergeTagsModal';
import CampaignBottomBar from './CampaignBottomBar';
const Option = Select.Option;

class EmailEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'editor',
      actionType: null,
      mergeTagsModalVisible: false,
    };

    this.beeConfig = {
      uid: 'peoplegrove-bulkmessage-superadmin',
      container: 'email-editor',
      language: 'en-US',
      onSave: this.onSave,
      onError: this.onError,
    };
    this.initializingBeeFree = false;
  }

  componentDidMount() {
    const {
      history,
      match,
      handleInitEmailEditor,
      template,
      handleFetchEmails,
      emails,
      isCampaign,
    } = this.props;
    if (!template) {
      notify('Invalid template', 'error');
      history.push(isCampaign ? '/campaigns': '/automated-marketing');
      return;
    } 

    if (!window.BeePlugin) {
      const script = document.createElement('script');
      script.src = 'https://app-rsrc.getbee.io/plugin/BeePlugin.js';
      document.body.appendChild(script);
    }
    handleInitEmailEditor({ id: get(match, 'params.templateId') });

    if (!emails || !isArray(emails) || emails.length === 0) {
      handleFetchEmails();
    }

    window.addEventListener('beforeunload', this.onRedirect);
    this.unblock = history.block(this.onRedirect);
  }

  onRedirect = e => {
    const { setConfirmCloseModalPath } = this.props;
    if (!this.props.messageSaved) {
      if (e.preventDefault) {
        e.preventDefault && e.preventDefault();
        e.stopImmediatePropagation && e.stopImmediatePropagation();
        const returnValue = 'Unsaved changes will be lost!';
        e.returnValue = returnValue;
        return returnValue;
      } else {
        setConfirmCloseModalPath(e.pathname);
        return false;
      }
    } else {
      this.cleanUp();
    }
  };

  cleanUp = () => {
    const { resetState } = this.props;

    resetState();
    this.unblock && this.unblock();
    window.removeEventListener('beforeunload', this.onRedirect);
    sessionStorage.removeItem('selectedUsersForBulkEmail');
    sessionStorage.removeItem('selectedUsersFromUrl');
  };

  onSaveCallback = () => {
    const { history, match: {params: { id }} } = this.props;
    const { actionType } = this.state;
    if(['addEmailToCampaign','editCampaignEmail'].includes(actionType)) {
      this.cleanUp();
      history.push(`/campaigns/${id}`);
    }
    else if (actionType === 'saveTemplate') {
      this.cleanUp();
      history.push('/automated-marketing/templates');
    } else if (actionType !== 'nextPage') {
      this.cleanUp();
      history.push('/automated-marketing');
    } else {
      this.setState({ page: 'config' });
    }
  };

  componentWillUnmount = () => {
    this.cleanUp();
  };

  onSave = (json, html) => {
    const { handleSaveEmail } = this.props;
    const { actionType } = this.state;

    handleSaveEmail({ json, html, actionType, callback: this.onSaveCallback });
  };

  onError = err => {
    // const { setFooterButtonEnabled } = this.props;
    // setFooterButtonEnabled(true);
    console.error('OnError: ', err);
  };

  initBeeFree = () => {
    const {
      token,
      template,
      setBeefreeInstance,
      jobTemplates = [],
    } = this.props;

    if (template) {
      this.beeConfig.mergeTags = mergeTags;
      this.beeConfig.specialLinks = getSpecialLinks(jobTemplates);
      window.BeePlugin.create(token, this.beeConfig, beePluginInstance => {
        beePluginInstance.start(template.messageJson);
        setBeefreeInstance(beePluginInstance);
        // if (
        //   messageActionInfo.isSuperAdminNewTemplate ||
        //   messageActionInfo.isSuperAdminUpdateTemplate
        // ) {
        //   getSuperAdminCategories();
        // } else {
        //   getCategories();
        // }
      });
    }
  };

  handleConfirmCloseModalOk = path => {
    const { history } = this.props;

    this.unblock();
    if (path && path !== '') history.push(path);
    else history.push('/automated-marketing/templates');
  };

  isFormValid = (type = null) => {
    const { draftData = {} } = this.props;

    if (!draftData.name || draftData.name === '') {
      notify('Internal tracking name is required.', 'error');
      return false;
    }

    if (type === 'template') {
      if (!draftData.category || draftData.category === '') {
        notify('Folder is required.', 'error');
        return false;
      }
      if (
        draftData.category === 'addTemplateCategory' &&
        (!draftData.newTemplateCategory || draftData.newTemplateCategory === '')
      ) {
        notify('New folder name is required.', 'error');
        return false;
      }
    } else if (!draftData.messageSubject || draftData.messageSubject === '') {
      notify('Subject is required.', 'error');
      return false;
    }

    return true;
  };
  isCampaignEmailFormValid = () => {
    const { draftData = {} } = this.props;

    if (!draftData.name || draftData.name === '') {
      notify('Tracking name is required.', 'error');
      return false;
    }

    if (!draftData.messageSubject || draftData.messageSubject === '') {
      notify('Subject is required.', 'error');
      return false;
    }

    if (!draftData.importStatus) {
      notify('Joined vs Imported is required.', 'error');
      return false;
    }
    if (isEmpty(draftData.standardUserType)) {
      notify('Standardized user types is required.', 'error');
      return false;
    }
    if (!draftData.delay || !isNumber(draftData.delay)) {
      notify('Delay (days) is required.', 'error');
      return false;
    }
   return true;
  }

  isAudienceTargetFormValid = () => {
    const { draftData = {} } = this.props;

    if (!draftData.importStatus) {
      notify('Joined vs Imported is required.', 'error');
      return false;
    }

    if (!draftData.standardUserType) {
      notify('Standardized user types is required.', 'error');
      return false;
    }

    if (
      intersection(draftData.includeEmails || [], draftData.excludeEmails || [])
        .length
    ) {
      notify(
        '"Exclude previous emails" & "Only send if previous emails" cannot have same emails.',
        'error'
      );
      return false;
    }

    return true;
  };

  isConfigFormValid = () => {
    const { draftData = {} } = this.props;

    if (!draftData.date) {
      notify('Date is required.', 'error');
      return false;
    } else if (moment(draftData.date, DATE_FORMAT).diff(moment(), 'days') < 0) {
      notify('Please select a future date.', 'error');
      return false;
    }

    if (!draftData.startTime) {
      notify('Start time is required.', 'error');
      return false;
    } else if (
      moment(
        `${moment(draftData.date, DATE_FORMAT).format('YYYY-MM-DD')} ${moment(
          draftData.startTime,
          TIME_FORMAT
        ).format('HH:mm:ss')}`,
        'YYYY-MM-DD HH:mm:ss'
      ).diff(moment()) < 0
    ) {
      notify('Please select a future time.', 'error');
      return false;
    }

    if (!draftData.endTime) {
      notify('End time is required.', 'error');
      return false;
    } else if (
      moment(draftData.endTime, TIME_FORMAT).diff(
        moment(draftData.startTime, TIME_FORMAT)
      ) < 0
    ) {
      notify('End time should be after start time.', 'error');
      return false;
    }

    return this.isAudienceTargetFormValid();
  };

  performAction = (actionType = 'saveDraft') => {
    const { beeInstance, setEmailSending } = this.props;

    this.setState({ actionType }, () => {
      if (beeInstance) {
        setEmailSending(true);
        beeInstance.save();
      }
    });
  };

  handleOnSaveDraftClick = () => {
    if (this.isFormValid()) {
      this.performAction('saveDraft');
    }
  };

  handleAddToCampaign = () => {
    if (this.isCampaignEmailFormValid()) {
      this.performAction('addEmailToCampaign');
    }
  }

  handleEditCampaignEmail = () => {
    if (this.isCampaignEmailFormValid()) {
      this.performAction('editCampaignEmail');
    }
  }

  handleOnSaveTemplateClick = () => {
    if (this.isFormValid('template')) {
      this.performAction('saveTemplate');
    }
  };

  handleOnBackClick = () => {
    this.setState({ page: 'editor' });
  };

  handleOnBackCampaignEmail = () => {
    const { history } = this.props;
    history.goBack();
  }

  handleOnNextClick = () => {
    if (this.isFormValid()) {
      this.performAction('nextPage');
    }
  };

  handleOnScheduleClick = () => {
    if (this.isFormValid() && this.isConfigFormValid()) {
      this.performAction('schedule');
    }
  };

  handleAttachmentUpload = file => {
    const { setDraftData, draftData } = this.props;

    if (file.filesUploaded && file.filesUploaded.length > 0) {
      const attachments = get(draftData, 'attachments') || [];

      file.filesUploaded.map(data => {
        const attachment = {
          awsKey: data.key,
          name: data.filename,
          size: data.size,
          fileSize: getFileSize(data.size),
          url: getUploadUrl(data, '/automated-marketing-attachments/'),
          id: `${data.filename}-${new Date().getTime()}`,
        };
        attachments.push(attachment);
      });

      if (isTotalAttachmentSizeInLimit(attachments)) {
        setDraftData({ attachments });
      } else {
        notify('Total email attachment cannot exceed 10 MB.', 'error');
      }
    }
  };

  handleRemoveAttachments = at => () => {
    const { draftData } = this.props;

    if (!at || !at.id) return;

    const attachments = get(draftData, 'attachments') || [];
    setDraftData({
      attachments: attachments.filter(attachment => attachment.id !== at.id),
    });

    notify(`${at.name} has been removed.`, 'success');
  };

  handleFormInputChange = (value, id) => {
    const { setDraftData } = this.props;

    const updateObj = {};
    updateObj[id] = value;

    setDraftData(updateObj);
  };

  handleGetStats = () => {
    const { draftData, handleFetchStats } = this.props;

    const {
      importStatus,
      standardUserType,
      module = null,
      minAudience = 100,
      excludeEmails = [],
      includeEmails = [],
      filterHubs = [],
      currentRole = [],
      canPostProject = false,
      projectCompanyOptional = false,
    } = draftData || {};
    if (this.isAudienceTargetFormValid()) {
      handleFetchStats({
        importStatus,
        standardUserType,
        module,
        minAudience,
        excludeEmails,
        includeEmails,
        filterHubs,
        currentRole,
        canPostProject,
        projectCompanyOptional,
      });
    }
  };

  toggleMergeTagsModal = flag => {
    this.setState({ mergeTagsModalVisible: !!flag });
  };

  handleMergeTagClick = value => {
    const { draftData } = this.props;

    if (!value) return;

    const messageSubject = get(draftData, 'messageSubject') || '';

    this.handleFormInputChange(`${messageSubject}${value}`, 'messageSubject');
  };

  render() {
    const {
      token,
      beeInstance,
      confirmCloseModalPath,
      setConfirmCloseModalPath,
      draftData,
      handleSendTestEmail,
      editorLoading,
      emailSending,
      statsLoading,
      stats,
      templateId,
      templateCategories,
      emails= [],
      emailsLoading,
      hubs,
      isCampaign,
      campaignEmailId,
    } = this.props;
    const { page, mergeTagsModalVisible } = this.state;
    const {
      name,
      messageSubject,
      attachments = [],
      category,
      newTemplateCategory,
      selectedHubs = [],
      delay,
      importStatus,
      standardUserType
    } = draftData || {};

   
    if (
      token &&
      window.BeePlugin &&
      !beeInstance &&
      !this.initializingBeeFree
    ) {
      this.initBeeFree();
      this.initializingBeeFree = true;
    }

    return (
      <>
        <div className="am__email-editor">
          {(!beeInstance || editorLoading) && (
            <div className="am__email-editor__loader">
              <Spin />
            </div>
          )}
          <div
            className="am__email-editor--editor"
            style={page !== 'editor' ? { display: 'none' } : {}}
          >
            <Alert
              message={
                <>
                  <b>Warning:</b> Do not alter the <b>Header</b> or{' '}
                  <b>Footer</b> in this template.
                  Emails to Corporate Partner & Employer usertype will be skipped for Standard Hubs
                </>
              }
              type="warning"
              showIcon
              closable
              className="text-center"
            />
            <div className="am__email-editor__form">
              <div className="am__email-editor__form__group">
                <div className="am__email-editor__form__item">
                  <label className="am__label--required" htmlFor="name">
                    {templateId ? 'Template Name' : isCampaign ? 'Tracking Name (may be shared with clients)': 'Internal Tracking Name'}
                  </label>
                  <Input
                    id="name"
                    placeholder="Example, NYC Event"
                    onChange={e =>
                      this.handleFormInputChange(e.target.value, 'name')
                    }
                    value={name}
                  />
                </div>
                <div className="am__email-editor__form__item">
                  <label
                    className="am__label--required"
                    htmlFor="messageSubject"
                  >
                    Subject
                  </label>
                  <div className="am__email-editor__form__item__group">
                    <Input
                      id="messageSubject"
                      placeholder="Add an email subject"
                      onChange={e =>
                        this.handleFormInputChange(
                          e.target.value,
                          'messageSubject'
                        )
                      }
                      value={messageSubject}
                    />
                  </div>
                </div>
              </div>
              <div
                className="am__email-editor__form__group"
                style={{ marginTop: '5px' }}
              >
                <>
                  <div className="am__email-editor__form__item">
                    <label
                      className="am__label--required"
                      htmlFor="messageSubject"
                    >
                      Folder
                    </label>
                    <div className="am__email-editor__form__item__group">
                      <Select
                        value={category}
                        onChange={val =>
                          this.handleFormInputChange(val, 'category')
                        }
                      >
                        {(templateCategories || []).map(({ category }) => (
                          <Option key={category} value={category}>
                            {category}
                          </Option>
                        ))}
                        <Option
                          key="addTemplateCategory"
                          value="addTemplateCategory"
                        >
                          + Add new folder
                        </Option>
                      </Select>
                      {!templateId && (
                        <Button
                          onClick={() => this.toggleMergeTagsModal(true)}
                          className="am__email-editor__form__item__button"
                        >
                          Add Merge Tags
                        </Button>
                      )}
                      <div className="am__email-editor__form__item__group am__email-editor__form__item--attachments">
                        <FileUploader
                          path="/automated-marketing-attachments/"
                          render={({ onPick }) => (
                            <Button onClick={onPick} tabIndex={6}>
                              Attach Files
                            </Button>
                          )}
                          onSuccess={this.handleAttachmentUpload}
                        />
                        <ul>
                          {(attachments || []).map(at => (
                            <Tag
                              closable
                              onClose={() => this.handleRemoveAttachments(at)}
                              color="blue"
                              key={at.id}
                            >
                              {at.name}
                            </Tag>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {category === 'addTemplateCategory' && (
                    <div className="am__email-editor__form__item">
                      <label
                        className="am__label--required"
                        htmlFor="newTemplateCategory"
                      >
                        New folder
                      </label>
                      <Input
                        id="newTemplateCategory"
                        placeholder="New folder name"
                        onChange={e =>
                          this.handleFormInputChange(
                            e.target.value,
                            'newTemplateCategory'
                          )
                        }
                        value={newTemplateCategory}
                      />
                    </div>
                  )}
                </>
              </div>
             { isCampaign ? <div className="am__email-editor__form__group">
              <div className="am__email-editor__form__item__group">
              <div className="am__email-editor__form__item">
                  <label
                    className="am__label--required"
                    htmlFor="delay"
                  >
                    Delay (days)
                  </label>
                  <InputNumber 
                  defaultValue={1} 
                  id="delay" 
                  min={1} 
                  max={365} 
                  value={delay}
                  onChange={value => this.handleFormInputChange(value, 'delay')}
                  style={{ width: '24em' }}/>
                </div>
                <div className="am__email-editor__form__item">  
                  <label
                  className="am__label--required"
                  htmlFor="standardUserType"
                >
                 Standard User Type
                </label>
                <Select
                mode="multiple"
                id="standardUserType"
                placeholder="Select an user type"
                value={standardUserType}
                onChange={value => this.handleFormInputChange(value, 'standardUserType')}
                getPopupContainer={node => node.parentNode}
                style={{ width: '25em' }}
              >
                {standardUserTypeOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
              </div> 
          </div>
          <div className="am__email-editor__form__item">
          <label
            className="am__label--required"
            htmlFor="newTemplateCategory"
          >
          Imported vs Joined
          </label>
          <Select
                id="importStatus"
                placeholder="Select an option"
                value={importStatus}
                onChange={value => { this.handleFormInputChange(value, 'importStatus')}}
                getPopupContainer={node => node.parentNode}
                style={{ width: '20em' }}
              >
                <Option value="joined">Only joined users</Option>
                <Option value="imported">Only imported users</Option>
                <Option value="joinedAndImported">
                  Joined and imported users
                </Option>
        </Select>       
        </div>
        </div> : null}
              </div>
            <TopBar />
            <div id="email-editor" />
          </div>
          {page !== 'editor' && (
            <EmailConfig
              draftData={draftData}
              handleFormInputChange={this.handleFormInputChange}
              handleSendTestEmail={handleSendTestEmail}
              disabled={emailSending}
              statsLoading={statsLoading}
              stats={stats}
              emails={emails}
              emailsLoading={emailsLoading}
              onGetStatsClick={this.handleGetStats}
              hubs={hubs}
            />
          )}
          {isCampaign ? (<CampaignBottomBar
            onBackClick={this.handleOnBackCampaignEmail}
            onAddToCampaignEmail={this.handleAddToCampaign}
            onEditCampaignEmail={this.handleEditCampaignEmail}
            disabled={emailSending || statsLoading || editorLoading}
            isCampaign={isCampaign}
            campaignEmailId={campaignEmailId}
            />) : (<BottomBar
            onSaveDraftClick={this.handleOnSaveDraftClick}
            onSaveTemplateClick={this.handleOnSaveTemplateClick}
            onBackClick={this.handleOnBackClick}
            onNextClick={this.handleOnNextClick}
            onScheduleClick={this.handleOnScheduleClick}
            onGetStatsClick={this.handleGetStats}
            page={page}
            disabled={emailSending || statsLoading || editorLoading}
            stats={stats}
            editTemplateMode={!!templateId}
            selectedHubs={selectedHubs || []}
          />)}
        </div>
        <ConfirmCloseModal
          path={confirmCloseModalPath}
          onCancel={() => setConfirmCloseModalPath(null)}
          onOk={this.handleConfirmCloseModalOk}
        />
        <MergeTagsModal
          visible={mergeTagsModalVisible}
          onCancel={() => this.toggleMergeTagsModal(false)}
          onMergeTagClick={this.handleMergeTagClick}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  clusterHubs: { hubs = [] },
  automatedMarketing: {
    token,
    template,
    beeInstance,
    confirmCloseModalPath,
    messageSaved,
    draftData,
    editorLoading,
    emailSending,
    statsLoading,
    stats,
    templateId,
    templateCategories,
    emails,
    emailsLoading,
    jobTemplates,
    campaignEmailId
  },
}) => ({
  hubs,
  token,
  template,
  beeInstance,
  confirmCloseModalPath,
  messageSaved,
  draftData,
  editorLoading,
  emailSending,
  statsLoading,
  stats,
  templateId,
  templateCategories,
  emails,
  emailsLoading,
  jobTemplates,
  campaignEmailId,
});

const mapDispatchToProps = {
  handleInitEmailEditor,
  setBeefreeInstance,
  setConfirmCloseModalPath,
  resetState,
  setDraftData,
  handleSaveEmail,
  handleSendTestEmail,
  handleFetchStats,
  setEmailSending,
  handleFetchEmails,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmailEditor)
);
