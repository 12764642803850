export const REFRESH_USERS = '/ManageCache/REFRESH_USERS';
export const REFRESH_ALL = '/ManageCache/REFRESH_ALL';
export const UPDATING_ALL = '/ManageCache/UPDATING_ALL';
export const UPDATING_USERS = '/ManageCache/UPDATING_USERS';

export const refreshUsers = data => ({
  type: REFRESH_USERS,
  data,
});

export const refreshAll = () => ({
  type: REFRESH_ALL,
});

export const updatingAll = data => ({
  type: UPDATING_ALL,
  data,
});

export const updatingUsers = data => ({
  type: UPDATING_USERS,
  data,
});
