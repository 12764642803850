export const SYNC_HUB_USERS = 'SYNC_HUB_USERS';
export const GET_HUBS_WITH_CIO = 'GET_HUBS_WITH_CIO';
export const SET_ANALYTICS_PROFILE = 'SET_ANALYTICS_PROFILE';
export const REMOVE_HUB_SYNC = 'REMOVE_HUB_SYNC';

export const getHubsWithCio = data => ({ type: GET_HUBS_WITH_CIO, data });
export const setAnalyticsProfile = data => ({
  type: SET_ANALYTICS_PROFILE,
  data,
});
export const syncHubUsers = data => ({ type: SYNC_HUB_USERS, data });
export const removeDataFromCustomerIOHubSync = data => ({
  type: REMOVE_HUB_SYNC,
  data,
});
