import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'antd';

import {
  handleActionDeleteModalVisible as handleActionDeleteModalVisibleAction,
  deleteUsertypeLibrary as deleteUsertypeLibraryAction,
  deleteUpsell as deleteUpsellAction,
} from '../../../actions/manageActions';

function DeleteModal({
  deleteModalVisible,
  manageType,
  handleActionDeleteModalVisible,
  match,
  deleteUsertypeLibrary,
  manageTypeOverride = '',
  deleteTemplate,
  templateId = null,
  activeId,
  deleteUpsell,
}) {
  let title = '';
  if (manageType === 'upsells') title = 'Upsell';
  else if (manageType === 'usertype-library') title = 'UserType Library';
  if (manageTypeOverride === 'templates') title = 'UserType Template';

  const handleDeleteOk = () => {
    const { id } = match.params;

    if (manageTypeOverride === 'templates' && templateId) {
      deleteTemplate(templateId);
    }

    if (manageType === 'usertype-library') {
      if (activeId) deleteUsertypeLibrary(activeId);
      else deleteUsertypeLibrary(id);
    }

    if (manageType === 'upsells') {
      if (activeId) deleteUpsell(activeId);
      else deleteUpsell(id);
    }
    handleActionDeleteModalVisible(false);
  };

  const renderBody = () => {
    // TODO: Check if delete is possible or not.
    if (manageType === 'usertype-library' && false) {
      // TODO: Replace with actual content from the product team
      return (
        <p style={{ marginBottom: '0px' }}>
          Oops! To delete this Usertype Library, you must remove the hub(s)
          associated with it.
        </p>
      );
    }

    return (
      <p>
        This is an irreversible action. Are you sure you want to delete this{' '}
        {title}?
      </p>
    );
  };

  const renderFooter = () => {
    // TODO: Check if delete is possible or not.
    if (false) {
      return (
        <div>
          <Button onClick={() => handleActionDeleteModalVisible(false)}>
            Go Back
          </Button>
        </div>
      );
    }

    return (
      <div>
        <Button onClick={() => handleActionDeleteModalVisible(false)}>
          Cancel
        </Button>
        <Button className="delete-btn" onClick={handleDeleteOk}>
          Delete
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={`Delete ${title}`}
      visible={deleteModalVisible}
      onCancel={() => handleActionDeleteModalVisible(false)}
      footer={renderFooter()}
      destroyOnClose
      className="confirmation-modal"
    >
      {renderBody()}
    </Modal>
  );
}

const mapStateToProps = ({
  usertypeManage: { deleteModalVisible, manageType, activeId },
}) => ({
  deleteModalVisible,
  manageType,
  activeId,
});

const mapDispatchToProps = {
  handleActionDeleteModalVisible: handleActionDeleteModalVisibleAction,
  deleteUsertypeLibrary: deleteUsertypeLibraryAction,
  deleteUpsell: deleteUpsellAction,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeleteModal)
);
