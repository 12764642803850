import React from 'react';
import { Tag } from 'antd';
import { isNull, isEmpty } from 'lodash';  
import classes from './HubSFAssociationStatus.module.scss';

const TagData = {
  deleteRequested: {
    color: '#db1626',
    text: 'Deletion Scheduled',
  },
  production: {
    color: '#23c44e',
    text: 'Production',
  },
  notProduction: {
    color: '#ebd409',
    text: 'Not Production',
  },
};

const HubSFAssociationStatus = ({ showBreak, hubData }) => {
  let tagType = 'notProduction';
  if (!isNull(hubData.deleteRequestedAt)) {
    tagType = 'deleteRequested';
  }

  if (!isEmpty(hubData.sfAccountId) && hubData.isProduction) {
    tagType = 'production';
  }

  return (
    <>
      {showBreak && <span className={classes['tag-break']}>|</span>}
      <Tag
        color={TagData[tagType].color}
        className={classes.tag}
      >
        {TagData[tagType].text}
      </Tag>
    </>
  );
}

export default HubSFAssociationStatus;
