/* eslint-disable import/prefer-default-export */
import { useQuery } from '@tanstack/react-query';
import DefaultTextKeys from '../queryKeys';
import { listHubTextsAPI } from '../../../api';
import { notifyError } from '../../../utils/notify';

const { listHubText } = DefaultTextKeys;

const listHubTexts = async ({ isArchived }) => {
  const { data } = await listHubTextsAPI({ isArchived });
  return data;
};

export const useHubTextList = ({ isArchived = false }) =>
  useQuery([listHubText, isArchived], () => listHubTexts({ isArchived }), {
    retry: false,
    onError: e => {
      notifyError(e, false, 'Error fetching hub list');
      console.log('Error in useHubTextList --- ', e);
    },
  });
