import React from 'react';
import { Modal, Form, InputNumber, Select, Button } from 'antd';

const { Option } = Select;

const TriggerModal = ({
  onSave,
  onCancel,
  form,
  processTypes,
  cronJobLoading,
}) => {
  const { getFieldDecorator } = form;
  const handleSave = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSave({ filters: values });
      }
    });
  };

  return (
    <Modal
      title="Trigger Cron Job"
      visible
      width={800}
      footer={[
        <Button onClick={onCancel}>Close</Button>,
        <Button type="primary" onClick={handleSave} loading={cronJobLoading}>
          Run
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Hub ID">
          {getFieldDecorator('hub', {
            rules: [{ required: true }],
          })(<InputNumber placeholder="Hub Id" />)}
        </Form.Item>
        <Form.Item label="Process Types">
          {getFieldDecorator('types', {
            rules: [{ required: true }],
          })(
            <Select mode="multiple" placeholder="Please select process types">
              {processTypes.map(type => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'process_alert_service_trigger_modal' })(
  TriggerModal
);
