/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Button, Select, Checkbox, Modal, Spin } from 'antd';
import { debounce } from 'lodash';
import TilePickerContainer from './TilePickerContainer';
import {
  saveTierData,
  deleteTier,
  fetchHubsBySearch,
  setName,
  setDescription,
  setObjectId,
  setImage,
  setIsActive,
  setDeleteModalVisibility,
  setCancelModalVisibility,
} from '../actions/manage';
import { popupAlert } from '../../../actions/alert';
import DeleteModal from './modals/DeleteModal';
import CancelModal from './modals/CancelModal';
import ProgressBar from '../../../component/ProgressBar';

const { Option } = Select;

const MAX_NAME_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;

class TierForm extends Component {
  fetchHubs = debounce(value => {
    const { fetchHubsBySearch } = this.props;
    fetchHubsBySearch(value);
  }, 300);

  handleInputChange = (key, e) => {
    const { setName, setDescription } = this.props;
    const value = e.target.value;

    if (key === 'name') setName(value);
    else if (key === 'description') setDescription(value);
  };

  onActiveChange = e => {
    const { setIsActive } = this.props;
    setIsActive(e.target.checked);
  };

  handleSubmit = () => {
    const {
      name,
      description,
      objectId,
      image,
      isActive,
      saveTierData,
      match,
      popupAlert,
    } = this.props;
    let error = false;

    if (name.trim().length > MAX_NAME_LENGTH) {
      popupAlert({
        type: 'error',
        message: `Name cannot be more than ${MAX_NAME_LENGTH} characters.`,
      });
      error = true;
    }

    if (description.trim().length > MAX_DESCRIPTION_LENGTH) {
      popupAlert({
        type: 'error',
        message: `Description cannot be more than ${MAX_DESCRIPTION_LENGTH} characters.`,
      });
      error = true;
    }

    if (!image || !name.trim() || !description.trim()) {
      popupAlert({
        type: 'error',
        message: `Please fill all mandatory fields - Name, Description, Tile Picker.`,
      });
      error = true;
    }

    if (error) return;

    const obj = {
      name,
      description,
      objectId,
      image,
      isActive,
    };

    const type = match && match.params.type;
    obj['type'] = type;
    obj['objectType'] = 'Hub';

    const id = match && match.params.id;
    if (id) obj['id'] = id;

    saveTierData(obj);
  };

  renderFooter = () => {
    return (
      <div>
        <Button onClick={this.handleDeleteCancel}>Cancel</Button>
        <Button
          onClick={this.handleDeleteOk}
          className={'delete-modal__delete-btn'}
          type="danger"
        >
          Delete
        </Button>
      </div>
    );
  };

  render() {
    const {
      match,
      manageSingleLoading,
      name,
      description,
      isActive,
      image,
      objectId,
      setImage,
      setObjectId,
      filteredHubs,
      filteredHubsLoading,
      setDeleteModalVisibility,
      setCancelModalVisibility,
      manageSaveLoading,
    } = this.props;

    if (manageSingleLoading || filteredHubsLoading)
      return (
        <div className="form-container">
          <ProgressBar
            style={{ minHeight: '500px', backgroundColor: '#ffffff' }}
          />
        </div>
      );

    return (
      <div className="ck-card form-container">
        <h3 className="form-container__heading">Create Tier</h3>
        <Form>
          <Form.Item label="Name" className="ant-form-item">
            <Input
              placeholder="Set Tier Name"
              onChange={e => this.handleInputChange('name', e)}
              value={name}
            />
          </Form.Item>
          <Form.Item label="Description" className="ant-form-item">
            <Input.TextArea
              placeholder="Set Tier Description"
              onChange={e => this.handleInputChange('description', e)}
              value={description}
            />
          </Form.Item>
          <Form.Item label="Tile Picker">
            <TilePickerContainer
              handleImageChange={setImage}
              tilePickerImage={image}
            />
          </Form.Item>
          <Form.Item label="Hub" className="ant-form-item">
            <Select
              showSearch
              mode="multiple"
              notFoundContent={
                filteredHubsLoading ? <Spin size="small" /> : null
              }
              onSearch={this.fetchHubs}
              placeholder="Select One or More Hubs"
              style={{ width: '100%' }}
              onChange={setObjectId}
              value={objectId}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {filteredHubs.map(hub => (
                <Option value={hub.id} key={hub.id}>
                  {hub.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="ant-form-item is-active-text">
            <Checkbox checked={isActive} onChange={this.onActiveChange}>
              Set Active Tier
            </Checkbox>
          </Form.Item>
          <Form.Item className="ant-form-item">
            <div className="buttons">
              <div className="buttons__left">
                {match.params && match.params.id && (
                  <>
                    <Button
                      type="danger"
                      disabled={!match.params || !match.params.id}
                      onClick={() => setDeleteModalVisibility(true)}
                      className="delete-btn"
                    >
                      Delete
                    </Button>
                    <DeleteModal />
                  </>
                )}
              </div>
              <div className="buttons__right">
                <Button
                  type="default"
                  className={'primary-btn'}
                  onClick={() => setCancelModalVisibility(true)}
                  disabled={manageSaveLoading}
                >
                  Cancel
                </Button>
                <CancelModal />
                <Button
                  type="primary"
                  className="primary-btn"
                  onClick={this.handleSubmit}
                  disabled={manageSaveLoading}
                >
                  {match.params && match.params.id ? 'Update' : 'Save'}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const {
    manageSingleLoading,
    name,
    description,
    isActive,
    image,
    objectId,
    filteredHubs,
    manageSaveLoading,
  } = groupsManage;

  return {
    manageSingleLoading,
    name,
    description,
    isActive,
    image,
    objectId,
    filteredHubs,
    manageSaveLoading,
  };
};

const mapDispatchToProps = {
  saveTierData,
  deleteTier,
  fetchHubsBySearch,
  setName,
  setDescription,
  setObjectId,
  setImage,
  setIsActive,
  popupAlert,
  setDeleteModalVisibility,
  setCancelModalVisibility,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TierForm)
);
