import { takeLatest, put, call, select, delay } from 'redux-saga/effects';

import { FETCH_ALL_HUBS, FETCH_LOGSTASH_STATUS, SET_ES_LOGSTASH, SET_LOGSTASH_STATUS, RUN_LOGSTASH, STOP_LOGSTASH, POLL_LOGSTASH_STATUS } from './actions';
import * as api from '../../api';

export default [
  takeLatest(FETCH_ALL_HUBS, fetchAllHubs),
  takeLatest(FETCH_LOGSTASH_STATUS, fetchLogstashStatus),
  takeLatest(RUN_LOGSTASH, runLogstash),
  takeLatest(STOP_LOGSTASH, stopLogstash),
  takeLatest(POLL_LOGSTASH_STATUS, pollLogstashStatus),
];

function* pollLogstashStatus({ run }) {
  if (!run) return;

  while (true) {
    yield call(fetchLogstashStatus);
    yield delay(5000);
  }
}

function *fetchAllHubs() {
  yield put({ 
    type: SET_ES_LOGSTASH,
    data: { hubsLoading: true }
  })
  let allAvailableHubs = yield call(api.hub.getAll, {
    fields: ['id', 'name', 'identifier']
  });
  allAvailableHubs = allAvailableHubs.data;
  yield put({ 
    type: SET_ES_LOGSTASH,
    data: {
      allAvailableHubs,
      hubsLoading: false 
    }
  })
}

function* fetchLogstashStatus() {
  try {
    yield put({
      type: SET_LOGSTASH_STATUS,
      data: { loading: true },
    });
    let { data } = yield call(api.getLogstashStatus, {});
    yield put({
      type: SET_LOGSTASH_STATUS,
      data: {
        status: data,
        loading: false,
      },
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: SET_LOGSTASH_STATUS,
      data: { loading: false },
    });
  }
}

function* runLogstash() {
  try {
    yield put({
      type: SET_LOGSTASH_STATUS,
      data: { loading: true },
    });
    const esLogstashState = yield select(state => state.esLogstash)
    const { hubs, allFiles, files } = esLogstashState;
    yield call(api.runLogstash, {
      hubs,
      allFiles,
      files: allFiles ? null : files,
    });
    yield call(fetchLogstashStatus);
  } catch (e) {
    console.error(e);
    yield put({
      type: SET_LOGSTASH_STATUS,
      data: { loading: false },
    });
  }
}

function* stopLogstash({ data: hubId }) {
  try {
    yield put({
      type: SET_LOGSTASH_STATUS,
      data: { loading: true },
    });
    yield call(api.stopLogstash, { hubId });
    yield call(fetchLogstashStatus);
  } catch (e) {
    console.error(e);
    yield put({
      type: SET_LOGSTASH_STATUS,
      data: { loading: false },
    });
  }
}
