import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { get } from 'lodash';
import {
  FETCH_ANALYTICS_REPORT_LIST,
  FETCH_ANALYTICS_REPORT,
  SET_REPORT_LIST_STATE,
  SET_REPORT_STATE,
  TEST_REPORT_QUERY,
  SUBMIT_REPORT_CHANGES,
  CREATE_NEW_ANALYTICS_REPORT,
  SET_ANALYTIC_REPORT_STATE,
  FETCH_ALL_HUB_LIST,
  FETCH_ALL_CLUSTER_LIST,
} from './actions';
import * as api from '../../api';
import history from '../../history';
import {
  validateFilters,
  validateHeaders,
  validateEditableColumns,
} from './helper';

export default [
  takeLatest(FETCH_ANALYTICS_REPORT_LIST, fetchAnalyticsReportList),
  takeLatest(FETCH_ANALYTICS_REPORT, fetchAnalyticsReport),
  takeLatest(TEST_REPORT_QUERY, testQuery),
  takeLatest(SUBMIT_REPORT_CHANGES, submitReportChanges),
  takeLatest(CREATE_NEW_ANALYTICS_REPORT, createNewAnalyticsReport),
  takeLatest(FETCH_ALL_HUB_LIST, fetchAllHubs),
  takeLatest(FETCH_ALL_CLUSTER_LIST, fetchAllClusters),
];

function* fetchAnalyticsReportList({ data }) {
  try {
    yield put({ type: SET_REPORT_LIST_STATE, data: { loading: true } });
    const { data } = yield api.getReportListApi();
    yield put({
      type: SET_REPORT_LIST_STATE,
      data: { loading: false, reports: data },
    });
  } catch (e) {
    message.error('There was an error fetching the reports.');
  }
}

function* fetchAllHubs() {
  let allAvailableHubs = yield call(api.hub.getAll, {
    fields: ['id', 'name', 'identifier'],
  });
  allAvailableHubs = allAvailableHubs.data;
  yield put({
    type: SET_ANALYTIC_REPORT_STATE,
    data: {
      allAvailableHubs,
    },
  });
}

function* fetchAllClusters() {
  let allAvailableClusters = yield call(api.cluster.getAll, {
    fields: ['id', 'name', 'subdomain'],
  });
  allAvailableClusters = allAvailableClusters.data;
  yield put({
    type: SET_ANALYTIC_REPORT_STATE,
    data: {
      allAvailableClusters,
    },
  });
}

function* fetchAnalyticsReport({ data: id }) {
  try {
    yield put({ type: SET_REPORT_STATE, data: { loading: true } });
    const { data } = yield api.getReportWithIdApi(id);
    const origQuery = get(data, 'query') || '';
    const origDatabase = get(data, 'database') || '';
    const origQueryLevel = get(data, 'query_level') || '';
    yield put({
      type: SET_REPORT_STATE,
      data: {
        loading: false,
        reportData: { ...data, update_log: '' },
        origQuery,
        origDatabase,
        origQueryLevel,
      },
    });
  } catch (e) {
    message.error('There was an error fetching the report.');
  }
}

function* createNewAnalyticsReport() {
  try {
    yield put({
      type: SET_REPORT_STATE,
      data: {
        loading: false,
        reportData: {
          headers: [],
          filters: [],
          editable_columns: [],
          default_sort: [],
          title: '',
          description: '',
          active: false,
          all_hubs: false,
          hubs: [],
          clusters: [],
          database: 'analytics',
          query_level: 'hub',
          related_chart_id: '',
          modules: [],
          module_mode: 'AND',
          dataviewer_type: 'hub',
          update_log: '',
        },
        origQuery: '',
        origDatabase: 'analytics',
        origQueryLevel: 'hub',
      },
    });
  } catch (e) {
    message.error('There was an error fetching the report.');
  }
}

function* testQuery() {
  try {
    yield put({ type: SET_REPORT_STATE, data: { testLoading: true } });
    const analyticsReport = yield select(state => state.analyticsReport);
    const {
      query,
      database,
      query_level: queryLevel,
      default_values,
    } = get(analyticsReport, 'report.reportData');
    const { data } = yield api.testReportQueryApi({
      query,
      database,
      queryLevel,
      default_values,
    });
    const validQuery = get(data, 'validQuery');
    const newAllHeaders = get(data, 'headers') || [];
    const newAllFilters = get(data, 'filterHeaders') || [];
    const newAllSortColumns = get(data, 'sortColumns') || [];
    if (validQuery) {
      let headers = get(analyticsReport, 'report.reportData.headers');
      let filters = get(analyticsReport, 'report.reportData.filters');
      let defaultSort = get(analyticsReport, 'report.reportData.default_sort');
      let editable_columns = get(
        analyticsReport,
        'report.reportData.editable_columns'
      );
      const reportData = get(analyticsReport, 'report.reportData') || {};
      headers = headers.filter(k => newAllHeaders.indexOf(k.column) > -1);
      filters = filters.filter(k => newAllFilters.indexOf(k.value) > -1);
      editable_columns = editable_columns.filter(
        k => newAllHeaders.indexOf(k.column) > -1
      );
      defaultSort = defaultSort.filter(k => newAllSortColumns.indexOf(k) > -1);
      yield put({
        type: SET_REPORT_STATE,
        data: {
          testLoading: false,
          reportData: {
            ...reportData,
            headers,
            filters,
            editable_columns,
            default_sort: defaultSort,
            allHeaders: newAllHeaders,
            allFilters: newAllFilters,
            allSortColumns: newAllSortColumns,
          },
          origQuery: query,
          origDatabase: database,
          origQueryLevel: queryLevel,
        },
      });
      message.success(
        'Query testing done. You can now add headers and filters.'
      );
    }
  } catch (e) {
    console.log('Error ', e);
    message.error(
      `Error testing query: ${get(e, 'response.data.error') || ''}`
    );
    yield put({ type: SET_REPORT_STATE, data: { testLoading: false } });
  }
}

function* validateData() {
  const analyticsReport = yield select(state => state.analyticsReport);
  const reportData = get(analyticsReport, 'report.reportData');
  const {
    title,
    active,
    query,
    description,
    headers,
    filters,
    all_hubs,
    hubs,
    clusters,
    dataviewer_type,
    editable_columns,
    database,
    related_chart_id,
  } = reportData;
  if (!title) {
    message.error(`Report title is required.`);
    return false;
  }
  const { isValid: isHeaderValid, message: headerMsg } =
    validateHeaders(headers);
  if (!isHeaderValid) {
    message.error(headerMsg);
    return false;
  }
  const { isValid: isFilterValid, message: filterMsg } =
    validateFilters(filters);
  if (!isFilterValid) {
    message.error(filterMsg);
    return false;
  }
  const {
    isValid: isEditableValid,
    message: editableMsg,
    warning: editableWarning,
  } = validateEditableColumns(editable_columns, database);
  if (database === 'analytics' && !related_chart_id) {
    message.error('Related Chart ID is required for analytics database.');
    return false;
  }
  if (!isEditableValid) {
    message.error(editableMsg);
    return false;
  } else if (editableWarning) {
    message.warn(editableWarning);
  }

  if (active) {
    if (!query) {
      message.error(`Report query is required for active query.`);
      return false;
    }
    if (!description) {
      message.error(`Report description is required for active query.`);
      return false;
    }
    if (!headers || !headers.length) {
      message.error(`At least one header is required for active query.`);
      return false;
    }
    if (!all_hubs && dataviewer_type === 'hub' && (!hubs || !hubs.length)) {
      message.error(`No hub is selected for the report.`);
      return false;
    }
    if (
      !all_hubs &&
      dataviewer_type === 'cluster' &&
      (!clusters || !clusters.length)
    ) {
      message.error(`No cluster is selected for the report.`);
      return false;
    }
  }
  return true;
}

function* submitReportChanges() {
  try {
    const analyticsReport = yield select(state => state.analyticsReport);
    const reportData = get(analyticsReport, 'report.reportData');
    const validData = yield call(validateData);
    if (validData) {
      if (reportData.id) {
        yield api.updateReportApi(reportData.id, { data: reportData });
      } else {
        yield api.createReportApi({ data: reportData });
      }
      message.success('Saved!');
      history.push('/analytics-reports');
    }
  } catch (e) {
    message.error(`Error submitting changes`);
    // yield put({ type: SET_REPORT_STATE, data: { testLoading: false } });
  }
}
