import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Select, Row, Icon, Tabs, Table } from 'antd';
import { forEach, find, findIndex, get, map, pull } from 'lodash';

// Temporary fix as Ant v3 Select is not performant for 5000 option values
// Package to be removed after component library or Ant v4 upgrade
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import VirtualizedSelect from 'react-virtualized-select';

import uuid from '../../../utils/uuid';
import { setData } from '../actions';

import '../index.scss';

const { Option } = Select;
const { TabPane } = Tabs;

class FilterSelectModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilterLabels: [],
      selectedFilters: {},
    };
  }

  componentDidMount() {
    const { filters = {}, allFilters } = this.props;
    const selectedFilterLabels = [];

    forEach(filters, (filterVal, filterLabel) => {
      selectedFilterLabels.push({
        ...find(allFilters, { value: filterLabel }),
        uuidVal: uuid(),
      });
    });

    this.setState({
      selectedFilterLabels,
      selectedFilters: filters,
    });
  }

  toggleModal = onSubmit => {
    const { setData, onSave } = this.props;
    const { selectedFilters } = this.state;

    forEach(selectedFilters, (filterVal, filterLabel) => {
      if (!filterVal || !filterVal.length) delete selectedFilters[filterLabel];
    });

    if (onSubmit) {
      onSave(selectedFilters);
    }
    setData({
      showFilterSelectionModal: false,
    });
  };

  getFilterLabelOptions = labelValue => {
    const { allFilters } = this.props;
    const { selectedFilterLabels } = this.state;

    const filterLabelValues = map(selectedFilterLabels, 'value');
    pull(filterLabelValues, labelValue);
    const allAvailableLabels = allFilters.filter(
      ({ value }) => !filterLabelValues.includes(value)
    );

    return map(allAvailableLabels, filter => (
      <Option key={filter.value} value={filter.value}>
        {filter.name}
      </Option>
    ));
  };

  handleFilterLabel = (value, uuidVal) => {
    const { allFilters } = this.props;
    const { selectedFilterLabels, selectedFilters } = this.state;

    const index = findIndex(selectedFilterLabels, { uuidVal });

    if (selectedFilterLabels[index].value)
      delete selectedFilters[selectedFilterLabels[index].value];

    selectedFilterLabels[index] = { ...find(allFilters, { value }), uuidVal };

    this.setState({
      selectedFilterLabels: [...selectedFilterLabels],
      selectedFilters,
    });
  };

  handleFilterValues = (value, filterLabel) => {
    let { selectedFilters } = this.state;
    selectedFilters = {
      ...selectedFilters,
      [filterLabel]: map(value, 'value'),
    };
    this.setState({ selectedFilters });
  };

  addFilter = () => {
    const { selectedFilterLabels } = this.state;
    this.setState({
      selectedFilterLabels: [...selectedFilterLabels, { uuidVal: uuid() }],
    });
  };

  removeFilter = (uuidVal, filterLabel) => {
    const { selectedFilterLabels, selectedFilters } = this.state;

    if (filterLabel) delete selectedFilters[filterLabel];

    this.setState({
      selectedFilters,
      selectedFilterLabels: selectedFilterLabels.filter(
        filter => filter.uuidVal !== uuidVal
      ),
    });
  };

  getColumns = () => [
    {
      title: 'Tag',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Number of Hubs',
      dataIndex: 'hubs',
      key: 'hubs',
    },
  ];

  getColumnData = filterLabel => {
    const { keyedFilterOptions } = this.props;
    const { selectedFilters } = this.state;
    const tableData = [];

    forEach(selectedFilters[filterLabel], value => {
      tableData.push({
        name: value,
        hubs: get(keyedFilterOptions, [filterLabel, value, 'hubs'], []).length,
      });
    });

    return tableData;
  };

  renderTabs = () => {
    const { allFilters } = this.props;
    const { selectedFilters } = this.state;

    return (
      selectedFilters &&
      Object.keys(selectedFilters).length > 0 && (
        <Tabs>
          {map(selectedFilters, (filterVal, filterLabel) => (
            <TabPane
              tab={get(find(allFilters, { value: filterLabel }), 'name')}
              key={filterLabel}
            >
              <Table
                rowkey="name"
                locale={{ emptyText: 'No Tags for Filter Selected.' }}
                columns={this.getColumns()}
                dataSource={this.getColumnData(filterLabel)}
                pagination={filterVal && filterVal.length > 10}
              />
            </TabPane>
          ))}
        </Tabs>
      )
    );
  };

  renderFilter = filter => {
    const { filterOptions } = this.props;
    const { selectedFilters } = this.state;
    const { name, value, uuidVal } = filter;

    return (
      <Row className="single-row" key={uuidVal}>
        <Select
          style={{ width: 250, height: 32 }}
          placeholder="Select a filter"
          value={value}
          onChange={e => this.handleFilterLabel(e, uuidVal)}
        >
          {this.getFilterLabelOptions(value)}
        </Select>
        <div className="inline-text">is</div>
        <VirtualizedSelect
          className="virtual-select"
          multi
          searchable
          clearable={false}
          labelKey="name"
          placeholder={name ? `Select ${name}` : ''}
          value={selectedFilters[value]}
          options={value && filterOptions[value]}
          onChange={e => this.handleFilterValues(e, value)}
          disabled={!value}
          closeOnSelect={false}
          onSelectResetsInput={false}
        />
        <Icon
          type="close"
          className="cross-icon"
          onClick={() => this.removeFilter(uuidVal, value)}
          tabIndex="0"
          onKeyPress={() => this.removeFilter(uuidVal, value)}
        />
      </Row>
    );
  };

  render() {
    const { showFilterSelectionModal } = this.props;
    const { selectedFilterLabels } = this.state;

    return (
      <Modal
        visible={showFilterSelectionModal}
        className="pathway_filter"
        width={1200}
        title="Provisioning Filters"
        destroyOnClose
        onCancel={() => this.toggleModal()}
        footer={[
          <Button key="cancel" onClick={() => this.toggleModal()}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={false}
            onClick={() => this.toggleModal(true)}
          >
            Save
          </Button>,
        ]}
      >
        {!selectedFilterLabels.length && (
          <h2 className="heading">No Filters Added!</h2>
        )}

        {selectedFilterLabels.map(filter => this.renderFilter(filter))}

        {selectedFilterLabels.length < 2 && (
          <Button
            className="add-filters"
            type="dashed"
            size="large"
            icon="plus"
            onClick={this.addFilter}
          >
            Add Filter
          </Button>
        )}

        {this.renderTabs()}
      </Modal>
    );
  }
}

const mapStateToProps = ({
  PathwaysProvisioning: {
    loading,
    filterData: { allFilters, filterOptions, keyedFilterOptions },
    showFilterSelectionModal,
  },
}) => ({
  loading,
  allFilters,
  filterOptions,
  keyedFilterOptions,
  showFilterSelectionModal,
});

const mapDispatchToProps = {
  setData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterSelectModal);
