import React from 'react';
import { capitalize } from 'lodash';

import { Table } from 'antd';
import UserData from '../UserData';

const UserConnections = ({ currentApi, response }) => {
  if (!response?.length) {
    return <div>No Data Found</div>;
  }
  const columns = Object.keys(response[0]).map(key => {
    if (key === 'user_id' && response[0].userData) {
      return {
        title: capitalize(key),
        dataIndex: key,
        key,
        render: (t, r) => <UserData id={r.user_id} data={r.userData} />,
      };
    }
    return {
      title: capitalize(key),
      dataIndex: key,
      key,
    };
  });

  return <Table columns={columns} dataSource={response} />;
};

export default UserConnections;
