import { put, call, select } from 'redux-saga/effects';
import { findIndex, isEmpty, cloneDeep, sortBy, isEqual } from 'lodash';

import {
  isLoading,
  fetchSchools,
  fetchSchoolsSuccess,
  pageSelector,
  filtersEnabledSelector,
  filterSchoolsSuccess,
  setFiltersEnabled,
  searchTextSelector,
  onlyVerifiedSelector,
  schoolsSelector,
  setSchools,
  fetchDegreeTypesSuccess,
  degreeTypesModalOpen,
  degreeTypesLoading,
  degreeTypesSelector,
  degreeTypesUpdating,
  totalSelector,
  pageSizeSelector,
  handlePageChange,
} from '../actions';

import {
  fetchSchoolsAPI,
  filterSchoolsAPI,
  updateSchoolAPI,
  fetchDegreeTypesAPI,
  updateDegreeTypesAPI,
} from '../../../api';

export function* fetchSchoolsSaga() {
  try {
    let page = yield select(pageSelector);
    const filtersEnabled = yield select(filtersEnabledSelector);
    const total = yield select(totalSelector);
    const pageSize = yield select(pageSizeSelector);
    if (total < pageSize) {
      page = 1;
      yield put(handlePageChange(1));
    }
    yield put(isLoading(true));
    if (filtersEnabled) {
      const searchText = yield select(searchTextSelector);
      const onlyVerified = yield select(onlyVerifiedSelector);
      const { data } = yield call(filterSchoolsAPI, {
        search: searchText,
        onlyVerified,
        page: page - 1,
      });
      const schools = data.schools.map(el => {
        return { ...el, key: el.id };
      });
      data.schools = schools;
      yield put(setFiltersEnabled(true));
      yield put(filterSchoolsSuccess(data));
    } else {
      const { data } = yield call(fetchSchoolsAPI, page - 1);
      const schools = data.schools.map(el => {
        return { ...el, key: el.id };
      });
      data.schools = schools;
      yield put(fetchSchoolsSuccess(data));
    }
    yield put(isLoading(false));
  } catch (e) {
    console.log(e);
  }
}

export function* filterSchoolsSaga({ data }) {
  try {
    yield put(isLoading(true));
    const res = yield call(filterSchoolsAPI, { ...data, page: 0 });
    if (!isEmpty(res.data)) {
      const schools = res.data.schools.map(el => {
        return { ...el, key: el.id };
      });
      res.data.schools = schools;
      yield put(setFiltersEnabled(true));
      yield put(filterSchoolsSuccess(res.data));
    }
    yield put(isLoading(false));
  } catch (e) {
    console.log(e);
  }
}

export function* updateSchoolSaga({ data }) {
  try {
    const { newData } = data;
    yield put(isLoading(true));
    const res = yield call(updateSchoolAPI, newData);
    if (res.data.status === 'success') {
      const schools = yield select(schoolsSelector);
      const matchedIndex = findIndex(schools, { id: newData.id });
      schools.splice(matchedIndex, 1, newData);
      yield put(setSchools(schools));
    }
    yield put(isLoading(false));
  } catch (e) {
    console.log(e);
  }
}

export function* fetchDegreeTypesSaga({ data }) {
  try {
    yield put(degreeTypesLoading(true));
    const payload = {
      ids: [data],
      schoolSpecificDegrees: true,
    };
    const res = yield call(fetchDegreeTypesAPI, payload);
    yield put(
      fetchDegreeTypesSuccess({
        degrees: sortBy(res.data.schoolSpecificDegrees, ['name']),
        schoolId: data,
      })
    );
    // console.log('response --- ', res.data);
    yield put(degreeTypesLoading(false));
  } catch (e) {
    console.log(e);
  }
}

export function* updateDegreeTypesSaga({ data }) {
  try {
    yield put(degreeTypesUpdating(true));
    let { schoolId, data: degreeTypes } = cloneDeep(
      yield select(degreeTypesSelector)
    );
    if (data.keys.length === 0) {
      degreeTypes = [];
    } else {
      if (
        !isEqual(sortBy(data.keys, ['name']), sortBy(degreeTypes, ['name']))
      ) {
        degreeTypes = data.keys;
      }
      degreeTypes = degreeTypes.filter(obj => obj?.isNew !== true); // removing isnew column
      Object.keys(data).forEach(async key => {
        if (key !== 'keys') {
          if (key.includes('new')) {
            degreeTypes.push({ name: data[key] });
            const indexToRemove = await findIndex(degreeTypes, { newId: key });
            if (indexToRemove > -1) {
              degreeTypes.splice(indexToRemove, 1);
            }
          } else {
            const matchedIndex = await findIndex(degreeTypes, {
              id: parseInt(key, 10),
            });
            const objToUpdate = degreeTypes[matchedIndex];
            objToUpdate.name = data[key];
            degreeTypes.splice(matchedIndex, 1, {
              ...objToUpdate,
            });
          }
        }
      });
    }
    const payload = { id: schoolId, degreeTypes };
    const res = yield call(updateDegreeTypesAPI, payload);
    yield put(degreeTypesUpdating(false));
    yield put(degreeTypesModalOpen(false));
    yield put(fetchSchools());
  } catch (e) {
    console.log(e);
  }
}
