import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { getRoutedQuesCountApi } from '../../../api/askAnythingMetricsApi';
import { notifyError } from '../../../utils/notify';

const { routedQuesCount } = queryKeys;

const getRoutedQuesCount = async filters => {
  const { data } = await getRoutedQuesCountApi(filters);
  return data;
};

export const useGetRoutedQuesCountQuery = filters =>
  useQuery([routedQuesCount, filters], () => getRoutedQuesCount(filters), {
    onError: err => {
      notifyError(err, false, 'Unable to fetch routed questions count');
    },
  });
