import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../component/Loader/Loader';
import { accessGroupsVerified } from '../../utils/helpers'

const ensureAdminSignIn = (auth) => {
    if (auth.authStatus === 'processing') {
        return 'processing';
    }
    return (auth.authStatus === 'signin' && auth.isAdmin === true);
}

const PrivateRoute = ({ component: Component, auth, accessGroups, path,  admin, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            let authStatus = ensureAdminSignIn(auth);

            if (authStatus === 'processing') {
                return <Loader />
            }
            else if (authStatus === true) {
                let accessVerified = accessGroupsVerified(admin, accessGroups);
                // home page visible to all types of users
                if (accessVerified || path === '/') {
                    return <Component {...props} />
                } else {
                    return <Redirect to={{
                        pathname: '/403'
                      }} />
                }
            }
            else {
                return <Redirect to={{
                    pathname: '/auth',
                    state: { from: props.location }, 
                  }} />
            }
        }} />
    )
}

const mapStateToProps = ({ auth, admin }) => ({ 
    auth,
    admin,
});

export default connect(
    mapStateToProps,
    null
)(PrivateRoute);
