import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, Form, PageHeader, Tooltip } from 'antd';
import { isEmpty, some } from 'lodash';
import { breadCrumbItemRender } from '../../../../utils/helpers';

import ManageTemplateForm from './components/ManageTemplateForm';
import ManageTemplateTable from './components/ManageTemplateTable';
import ProgressBar from '../../../../component/ProgressBar';
import CancelModal from '../Manage/modals/CancelModal';

import {
  fetchTemplate,
  handleActionCancelModalVisible,
  resetTemplateState,
  saveTemplate,
} from '../../actions/manageActions';

import './styles/index.scss';

class ManageTemplate extends PureComponent {
  state = {
    isEditing: false,
    templateId: null,
    tableHasErrors: false,
  };

  componentDidMount() {
    this.checkRoute();
  }

  componentWillUnmount() {
    const { resetTemplateState } = this.props;
    resetTemplateState();
  }

  checkRoute = () => {
    const { match, fetchTemplate } = this.props;
    const id = match.params && match.params.id;

    if (match.path.includes('/new') || id) {
      if (match.path.includes('/new')) {
        // set defaults, create new form
        fetchTemplate();
        this.setState({ isEditing: false });
      }
      if (id) {
        // fetch template by id
        fetchTemplate(id);
        this.setState({ isEditing: true, templateId: id });
      }
    }
  };

  getBreadcrumbConfig = () => {
    const { isEditing, templateId } = this.state;
    const config = {
      routes: [
        {
          path: '/usertype-settings',
          breadcrumbName: 'Usertype Settings',
        },
        {
          path: '/usertype-settings/manage-templates',
          breadcrumbName: 'Templates',
        },
      ],
      itemRender: breadCrumbItemRender,
    };
    if (isEditing && templateId) {
      config.routes.push({
        path: `/usertype-settings/manage-templates/edit/${templateId}`,
        breadcrumbName: 'Update User Type Template',
      });
      return config;
    }
    config.routes.push({
      path: '/usertype-settings/manage-templates/new',
      breadcrumbName: 'Create New User Type Template',
    });
    return config;
  };

  handleSaveTemplate = () => {
    const {
      match,
      templateSettingRows,
      saveTemplate,
      form,
      name,
      standardUsertype,
    } = this.props;
    let hasErrors = false;
    if (form) {
      form.validateFieldsAndScroll(errors => {
        if (
          !isEmpty(errors) &&
          some(errors, el => el.errors && el.errors.length)
        ) {
          hasErrors = true;
        }
      });
    }
    if (
      some(
        templateSettingRows,
        el => el.visible && !el.editable && !el.upsellId
      ) ||
      hasErrors
    ) {
      this.setState({ tableHasErrors: true });
      return;
    }
    this.setState({ tableHasErrors: false });
    const settings = {};
    templateSettingRows.forEach(setting => {
      settings[setting.key] = { ...setting };
    });
    const objToSave = { name, standardUsertype, settings };
    if (match.params && match.params.id) {
      objToSave.id = match.params.id;
    }
    saveTemplate(objToSave);
  };

  handleCancelClick = () => {
    const {
      canEditTemplate,
      handleActionCancelModalVisible,
      history,
    } = this.props;
    if (!canEditTemplate) {
      history.push('/usertype-settings/manage-templates');
      return;
    }
    handleActionCancelModalVisible(true);
  };

  render() {
    const { isEditing, templateId, tableHasErrors } = this.state;
    const { manageLoading, form, isSaving, canEditTemplate } = this.props;
    if (manageLoading) {
      return (
        <div className="manage-usertype-template">
          <ProgressBar style={{ minHeight: '100vh' }} />
        </div>
      );
    }
    return (
      <div className="manage-usertype-template">
        <PageHeader
          title={
            <p className="header__title">
              {isEditing && templateId
                ? 'Update User Type Template'
                : 'Create New User Type Template'}
            </p>
          }
          breadcrumb={this.getBreadcrumbConfig()}
          style={{ backgroundColor: '#fff' }}
        />
        <div className="main-container">
          <Card className="warning__card" style={{ width: '100%' }}>
            <div className="warning__description">
              <p className="warning__text">
                <span className="warning__text-bold">Note: </span>
                {` Any changes you make here will be reflected in all hubs whose user type library contains this template. Only make changes if you feel they are absolutely necessary. `}
              </p>
            </div>
          </Card>
          <ManageTemplateForm form={form} />
          <ManageTemplateTable hasErrors={tableHasErrors} />
          <Card className="footer-actions">
            <Button onClick={this.handleCancelClick} disabled={isSaving}>
              Cancel
            </Button>
            <CancelModal manageType="templates" />
            <Tooltip
              title={
                !canEditTemplate
                  ? `Template currently in use. Can't be edited.`
                  : ''
              }
              placement="top"
            >
              <Button
                type="primary"
                onClick={this.handleSaveTemplate}
                loading={isSaving}
                disabled={!canEditTemplate}
              >
                Save Template
              </Button>
            </Tooltip>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  usertypeManage: {
    manageLoading,
    templateSettingRows,
    template: { standardUsertype, name, settings },
    isSaving,
    canEditTemplate,
  },
}) => ({
  manageLoading,
  name,
  settings,
  standardUsertype,
  templateSettingRows,
  isSaving,
  canEditTemplate,
});

const mapDispatchToProps = {
  fetchTemplate,
  handleActionCancelModalVisible,
  resetTemplateState,
  saveTemplate,
};

export default withRouter(
  Form.create()(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ManageTemplate)
  )
);
