import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ManageCareerPathsContainer from './containers/ManageCareerPathsContainer';
import ManageOccupationsContainer from './containers/ManageOccupationsContainer';

const ManageCareerPaths = () => (
  <Switch>
    <Route
      path="/manage-career-paths/occupations"
      component={ManageOccupationsContainer}
    />
    <Route path="/" component={ManageCareerPathsContainer} />
  </Switch>
);

export default ManageCareerPaths;
