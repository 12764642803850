import React from 'react';
import { Col, Icon, Row, Switch, Table } from 'antd';
import { get } from 'lodash';

// import { HUB_ADDON_MODULES, SUB_SETTINGS_KEYS } from './constants';

const JsonView = ({
  clusterHub,
  clusterModules,
  // handleJsonEdit,
  handleSwitchChange,
  hubModules,
  linkedModules,
}) => {
  const columns = [
    { title: 'Module Name', dataIndex: 'name', key: 'name', width: '50%' },
    { title: 'Enabled', dataIndex: 'action', key: 'action', width: '50%' },
  ];

  const getDataForTable = (
    tableType,
    isEnabledByDefault,
    isDisabledByDefault
  ) => {
    const data = [];
    const hubModulesObj = get(clusterHub, 'hub.settings', {});
    const clusterModulesObj = get(clusterHub, 'cluster.settings', {});

    const pushToDataArray = (module, type, moduleDetails) => {
      const source = type === 'hub' ? hubModulesObj : clusterModulesObj;
      // const addonModules = type === 'hub' ? HUB_ADDON_MODULES : [];
      if (Object.values(linkedModules).includes(module)) return null;
      const dataToPush = {
        name: module === 'advising' ? 'Advising' : module,
        action: (
          <Switch
            key={module}
            checked={get(source, moduleDetails.path, false)}
            onChange={checked =>
              handleSwitchChange(checked, module, type, moduleDetails)
            }
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
          />
        ),
      };
      data.push(dataToPush);
      return true;
    };

    // const pushToDataArrayPlus = (module, key, type) => {
    //   const source = hubModulesObj;
    //   const dataToPush = {
    //     name: key,
    //     action: (
    //       <Switch
    //         key={`${module}.${key}`}
    //         checked={get(source, `${module}.${key}`, false)}
    //         onChange={checked => handleSwitchChange(checked, module, type, key)}
    //         checkedChildren={<Icon type="check" />}
    //         unCheckedChildren={<Icon type="close" />}
    //       />
    //     ),
    //   };
    //   data.push(dataToPush);
    // };

    if (tableType === 'hub') {
      // SUB_SETTINGS_KEYS.forEach(key =>
      //   pushToDataArrayPlus('resources', key, 'hub')
      // );
      const hubModuleKeys = Object.keys(hubModules)
        .sort((a, b) => a.localeCompare(b))
        .filter(module =>
          isEnabledByDefault
            ? hubModules[module].isEnabledByDefault
            : !hubModules[module].isEnabledByDefault
        )
        .filter(module =>
          isDisabledByDefault
            ? hubModules[module].isDisabledByDefault
            : !hubModules[module].isDisabledByDefault
        );
      hubModuleKeys.forEach(module =>
        pushToDataArray(module, 'hub', hubModules[module])
      );
    }
    if (tableType === 'cluster') {
      Object.keys(clusterModules).forEach(module =>
        pushToDataArray(module, 'cluster', clusterModules[module])
      );
    }
    return data;
  };

  return (
    <div className="valid-state--json-view">
      <Row>
        <Col span={7}>
          <div className="valid-state--json-view__table-header">
            Settings enabled by default
          </div>
          <Table
            size="medium"
            columns={columns}
            dataSource={getDataForTable('hub', true)}
            pagination={false}
            bordered={false}
            rowKey="name"
            showHeader
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <div className="valid-state--json-view__table-header">
            Settings disabled by default
          </div>
          <Table
            size="medium"
            columns={columns}
            dataSource={getDataForTable('hub', null, true)}
            pagination={false}
            bordered={false}
            rowKey="name"
            showHeader
          />
        </Col>
        <Col span={1} />
        <Col span={8}>
          <div className="valid-state--json-view__table-header">
            Settings preserved from Classic
          </div>
          <Table
            size="medium"
            columns={columns}
            dataSource={getDataForTable('hub')}
            pagination={false}
            bordered={false}
            rowKey="name"
            showHeader
          />
        </Col>
      </Row>
    </div>
  );
};

export default JsonView;
