import {
  SET_CAREER_PATHS_META,
  SET_CAREER_PATHS,
  SET_IS_LOADING,
  SET_CAREER_PATH_WITH_ASSOCIATED_DATA,
  SET_AUXILIARY_DATA_FOR_CAREER_PATHS,
  SET_CAREER_PATH_CHANGE_LOG,
  CLEAR_FILTERS,
  SEARCH_TEXT_CHANGE,
  SET_FILTERS_ENABLED,
  SORT_CHANGE,
  NUM_PAGE_CHANGE,
  SET_ADDING_KEY,
  SET_EDITING_KEY,
  SET_CHANGELOG_KEY,
  SET_LINKED_PROJECTS,
  SET_PROJECT_CHANGELOG,
  SET_OCCUPATIONS,
} from '../actions';

const initialState = {
  careerPathsMeta: {},
  careerPaths: [],
  isLoading: {},
  careerPathWithAssociatedData: {},
  standardDataForCareerPaths: {},
  careerPathChangeLog: [],
  searchText: '',
  filtersEnabled: false,
  sortBy: '',
  numPages: 1,
  addingKey: -1,
  changeLogKey: -1,
  editingKey: -1,
  linkedProjects: [],
  linkedProjectsCount: 0,
  projectTemplateChangeLog: [],
};

const manageCareerPaths = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_CAREER_PATHS_META: {
      return {
        ...state,
        careerPathsMeta: {
          ...data,
        },
      };
    }

    case SET_CAREER_PATHS: {
      return {
        ...state,
        careerPaths: data,
      };
    }

    case SET_CAREER_PATH_WITH_ASSOCIATED_DATA: {
      return {
        ...state,
        careerPathWithAssociatedData: data,
      };
    }

    case SET_AUXILIARY_DATA_FOR_CAREER_PATHS: {
      return {
        ...state,
        auxiliaryDataForCareerPaths: data,
      };
    }

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          ...data,
        },
      };

    case SET_CAREER_PATH_CHANGE_LOG: {
      return {
        ...state,
        careerPathChangeLog: data,
      };
    }

    case CLEAR_FILTERS:
      return {
        ...state,
        searchText: '',
        sortBy: '',
        filtersEnabled: false,
      };

    case SEARCH_TEXT_CHANGE:
      return {
        ...state,
        searchText: data,
      };

    case SET_FILTERS_ENABLED:
      return {
        ...state,
        filtersEnabled: data,
      };

    case SORT_CHANGE:
      return {
        ...state,
        sortBy: data,
      };
    case NUM_PAGE_CHANGE:
      return {
        ...state,
        numPages: data,
      };
    case SET_ADDING_KEY:
      return {
        ...state,
        addingKey: data,
      };
    case SET_EDITING_KEY:
      return {
        ...state,
        editingKey: data,
      };
    case SET_CHANGELOG_KEY:
      return {
        ...state,
        changeLogKey: data,
      };
    case SET_LINKED_PROJECTS:
      return {
        ...state,
        linkedProjects: data?.linkedProjectTemplates,
        linkedProjectsCount: data?.linkedProjectsCount,
      };
    case SET_PROJECT_CHANGELOG: {
      return {
        ...state,
        projectTemplateChangeLog: data,
      };
    }
    case SET_OCCUPATIONS: {
      const obj = {
        ...state,
        occupations: data.occupations.map(e => ({ ...e, key: e.id })), // key for antd table row identifier
        totalOccupations: data.total,
      };
      if (data.activeCareerPaths) {
        obj.activeCareerPaths = data.activeCareerPaths;
      }
      return obj;
    }
    default:
      return state;
  }
};

export default manageCareerPaths;
