import { request } from '../utils/request';

export const getMessageFeedbackAPI = params =>
  request.get(
    `/admin/message-feedbacks?limit=${params.limit}&page=${params.page}`
  );

export const getMentorshipFeedbackAPI = params =>
  request.get(
    `/admin/mentorship-feedbacks?limit=${params.limit}&page=${params.page}`
  );
