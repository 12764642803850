import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import alertSaga from './alertSaga';
import userDetailSaga from '../containers/UserDetail/sagas';
import seedsSaga from '../containers/DatabaseImages/sagas';
import abTestingSaga from './abTestingSaga';
import adminTagsSaga from './adminTagsSaga';
import clustersSaga from '../containers/ClusterManagement/sagas';
import hubsSaga from '../containers/HubManagement/sagas';
import featuresSaga from '../containers/FeatureManagement/sagas';
import clusterHubSaga from './clusterHubSaga';
import configureBannerSaga from './configureBannerSaga';
import productUpdatePostsSaga from './productUpdatePostsSaga';
import esLogstashSagas from '../containers/EsLogstash/sagas';
import schoolManagementSaga from '../containers/SchoolManagement/sagas';
import feedbackSaga from '../containers/Feedback/sagas';
import cacheSaga from '../containers/ManageCache/sagas';
import analyticsQuerySaga from './analyticsQuerySaga';
import analyticsReportSaga from '../containers/AnalyticsReport/saga';
import esDocumentSaga from '../containers/RefreshES/sagas';
import automatedMarketingSaga from '../containers/AutomatedMarketing/sagas';
import manageImportSaga from '../containers/ManageImport/sagas';
import manageBulkEmailSaga from '../containers/ManageBulkEmail/sagas';
import PathwaysProvisioning from '../containers/PathwaysProvisioning/sagas';
import bridgesAllianceSaga from '../containers/BridgesAlliance/sagas';
import groupsProgramTierSaga from '../containers/GroupProgramTier/sagas';
import accessManagementSaga from '../containers/AccessManagement/sagas';
import sessionManagementSaga from '../containers/SessionManagement/sagas';
import usertypeSettingsSaga from '../containers/UserTypeSettings/sagas';
import analyticsProfileSaga from '../containers/AnalyticsProfile/sagas/analyticsProfile';
import standardHubManagementSaga from '../containers/StandardHubManagement/sagas';
import hubDeletionSagas from '../containers/ApproveDeletedHubs/sagas';
import ProcessAlertServiceSaga from '../containers/ProcessAlertService/sagas';
import manageCareerPathsSaga from '../containers/ManageCareerPaths/sagas';
import CompanyIndustrySaga from '../containers/CompanyIndustry/sagas';
import CMSToolSaga from '../containers/CMSTool/sagas';
import templatesAndTipsSaga from '../containers/TemplatesAndTips/sagas';
import MLServiceSaga from '../containers/MLService/sagas';

export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...alertSaga,
    ...userDetailSaga,
    ...seedsSaga,
    ...abTestingSaga,
    ...adminTagsSaga,
    ...clustersSaga,
    ...hubsSaga,
    ...featuresSaga,
    ...clusterHubSaga,
    ...configureBannerSaga,
    ...esLogstashSagas,
    ...schoolManagementSaga,
    ...feedbackSaga,
    ...cacheSaga,
    ...analyticsQuerySaga,
    ...analyticsReportSaga,
    ...esDocumentSaga,
    ...automatedMarketingSaga,
    ...productUpdatePostsSaga,
    ...manageImportSaga,
    ...manageBulkEmailSaga,
    ...PathwaysProvisioning,
    ...bridgesAllianceSaga,
    ...groupsProgramTierSaga,
    ...accessManagementSaga,
    ...sessionManagementSaga,
    ...usertypeSettingsSaga,
    ...analyticsProfileSaga,
    ...standardHubManagementSaga,
    ...hubDeletionSagas,
    ...ProcessAlertServiceSaga,
    ...manageCareerPathsSaga,
    ...CompanyIndustrySaga,
    ...CMSToolSaga,
    ...templatesAndTipsSaga,
    ...MLServiceSaga,
  ]);
}
