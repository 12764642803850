const prefix = 'COMPANY_INDUSTRY';
export const GET_COMPANY_INDUSTRY_DATA = `${prefix}/GET_COMPANY_INDUSTRY_DATA`;
export const SET_COMPANY_INDUSTRY = `${prefix}/SET_COMPANY_INDUSTRY`;
export const SET_STANDARD_INDUSTRY = `${prefix}/SET_STANDARD_INDUSTRY`;
export const SET_COMPANY_INDUSTRY_LOADING = `${prefix}/SET_COMPANY_INDUSTRY_LOADING`;

export const SAVE_COMPANY_INDUSTRY = `${prefix}/SAVE_COMPANY_INDUSTRY`;
export const SET_COMPANY_INDUSTRY_SAVING = `${prefix}/SET_COMPANY_INDUSTRY_SAVING`;
export const DELETE_COMPANY_INDUSTRY = `${prefix}/DELETE_COMPANY_INDUSTRY`;

export const GET_KAGGLE_COMPANIES_MATCHES = `${prefix}/GET_KAGGLE_COMPANIES_MATCHES`;
export const SET_IS_GETTING_KAGGLE_COMPANIES_MATCHES = `${prefix}/SET_IS_GETTING_KAGGLE_COMPANIES_MATCHES`;
export const SET_KAGGLE_COMPANIES_MATCHES = `${prefix}/SET_KAGGLE_COMPANIES_MATCHES`;

export const getCompanyIndustryDataAction = data => ({
  type: GET_COMPANY_INDUSTRY_DATA,
  data,
});
export const saveCompanyIndustryAction = data => ({
  type: SAVE_COMPANY_INDUSTRY,
  data,
});
export const deleteCompanyIndustryAction = data => ({
  type: DELETE_COMPANY_INDUSTRY,
  data,
});
export const getKaggleComapniesMatchesAction = data => ({
  type: GET_KAGGLE_COMPANIES_MATCHES,
  data,
});

export const setCompanyIndustryAction = data => ({
  type: SET_COMPANY_INDUSTRY,
  data,
});
export const setStandardIndustryAction = data => ({
  type: SET_STANDARD_INDUSTRY,
  data,
});
export const setCompanyIndustryLoadingAction = data => ({
  type: SET_COMPANY_INDUSTRY_LOADING,
  data,
});
export const setCompanyIndustrySavingAction = data => ({
  type: SET_COMPANY_INDUSTRY_SAVING,
  data,
});
export const setIsGettingKaggleCompaniesMatchesAction = data => ({
  type: SET_IS_GETTING_KAGGLE_COMPANIES_MATCHES,
  data,
});
export const setKaggleCompaniesMatchesAction = data => ({
  type: SET_KAGGLE_COMPANIES_MATCHES,
  data,
});
