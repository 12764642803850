import {
  FETCH_SCHOOLS_SUCCESS,
  HANDLE_PAGE_CHANGE,
  SEARCH_TEXT_CHANGE,
  TOGGLE_ONLY_VERIFIED,
  TOGGLE_EXACT_MATCH,
  TOGGLE_CASE_INSENSITIVE,
  IS_LOADING,
  FILTER_SCHOOLS_SUCCESS,
  SET_FILTERS_ENABLED,
  CLEAR_FILTERS,
  UPDATE_EDITING_KEY,
  SET_SCHOOLS,
  DEGREE_TYPES_MODAL_OPEN,
  DEGREE_TYPES_LOADING,
  FETCH_DEGREE_TYPES_SUCCESS,
  DEGREE_TYPES_UPDATING,
  RESET_STATE,
} from '../actions';

const initialState = {
  data: null,
  searchText: '',
  onlyVerified: false,
  exactMatch: false,
  caseInsensitive: true,
  degreeTypes: {
    data: null,
    degreeTypesLoading: false,
    isDegreeTypeModalOpen: false,
    schoolId: null,
    updating: false,
  },
  current: 1,
  total: 0,
  totalPages: 0,
  searchValue: '',
  pageSize: 25,
  loading: false,
  editingKey: null,
  filtersEnabled: false,
};

const schoolManagementReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case IS_LOADING:
      return {
        ...state,
        loading: data,
      };

    case FETCH_SCHOOLS_SUCCESS:
      return {
        ...state,
        data: data.schools,
        total: parseInt(data.count, 10),
        totalPages: data.pages,
      };

    case FILTER_SCHOOLS_SUCCESS:
      return {
        ...state,
        data: data.schools,
        total: parseInt(data.count, 10),
        totalPages: data.pages,
      };

    case SET_FILTERS_ENABLED:
      return {
        ...state,
        filtersEnabled: data,
      };

    case CLEAR_FILTERS:
      return {
        ...state,
        searchText: '',
        onlyVerified: false,
        filtersEnabled: false,
      };

    case HANDLE_PAGE_CHANGE:
      return {
        ...state,
        current: data,
      };

    case SEARCH_TEXT_CHANGE:
      return {
        ...state,
        searchText: data,
      };

    case TOGGLE_ONLY_VERIFIED:
      return {
        ...state,
        onlyVerified: !state.onlyVerified,
      };

    case TOGGLE_EXACT_MATCH:
      return {
        ...state,
        exactMatch: !state.exactMatch,
      };

    case TOGGLE_CASE_INSENSITIVE:
      return {
        ...state,
        caseInsensitive: !state.caseInsensitive,
      };

    case UPDATE_EDITING_KEY:
      return {
        ...state,
        ...data,
      };

    case SET_SCHOOLS:
      return {
        ...state,
        data,
      };

    case DEGREE_TYPES_MODAL_OPEN:
      return {
        ...state,
        degreeTypes: {
          ...state.degreeTypes,
          isDegreeTypeModalOpen: data,
        },
      };

    case DEGREE_TYPES_LOADING:
      return {
        ...state,
        degreeTypes: {
          ...state.degreeTypes,
          degreeTypesLoading: data,
        },
      };

    case FETCH_DEGREE_TYPES_SUCCESS:
      return {
        ...state,
        degreeTypes: {
          ...state.degreeTypes,
          data: data.degrees,
          schoolId: data.schoolId,
        },
      };

    case DEGREE_TYPES_UPDATING:
      return {
        ...state,
        degreeTypes: {
          ...state.degreeTypes,
          updating: data,
        },
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default schoolManagementReducer;
