import { SET_REQUESTED_HUBS_FOR_DELETION_LIST } from '../actions';

const initialState = {
  requestedHubDeletionList: undefined,
  loading: false,
};

const hubDeletionReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_REQUESTED_HUBS_FOR_DELETION_LIST:
      return {
        ...state,
        requestedHubDeletionList: data.requestedHubDeletionList,
        loading: data.loading,
      };
    default:
      return state;
  }
};

export default hubDeletionReducer;
