import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../component/Loader/Loader';

const ensureAdminSignOut = (auth) => {
    if (auth.authStatus === 'processing') {
        return 'processing';
    }
    return (auth.authStatus !== 'signin' || auth.isAdmin === false);
}

const getRedirectFromPathName = (location = {}) => {
    if (location && location.state && location.state.from) {
        return location.state.from.pathname || '/';
    }
    else {
        return '/';
    }
}

const PublicRoute = ({ component: Component, auth, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            let authStatus = ensureAdminSignOut(auth);

            if (authStatus === 'processing') {
                return <Loader />
            }
            else if (authStatus === true) {
                return <Component {...props} />
            }
            else {
                return <Redirect to={{
                    pathname: getRedirectFromPathName(props.location),
                  }} />
            }
        }} />
    )
}

const mapStateToProps = ({ auth }) => ({
    auth,
});

export default connect(
    mapStateToProps,
    null
)(PublicRoute);
