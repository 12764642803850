import { takeLatest } from 'redux-saga/effects';

import {
  FETCH_MENTORSHIP_FEEDBACKS,
  FETCH_MESSAGE_FEEDBACKS,
  OPEN_HUB_FROM_FEEDBACKS,
} from '../actions';

import {
  fetchMentorshipFeedbacksSaga,
  fetchMessageFeedbacksSaga,
  openHubFromFeedbacksSaga,
} from './sagas';

export default [
  takeLatest(FETCH_MENTORSHIP_FEEDBACKS, fetchMentorshipFeedbacksSaga),
  takeLatest(FETCH_MESSAGE_FEEDBACKS, fetchMessageFeedbacksSaga),
  takeLatest(OPEN_HUB_FROM_FEEDBACKS, openHubFromFeedbacksSaga),
];
