import React from 'react';
import { Button } from 'antd';

const Columns = {
  tier: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Number of Hub Assignments',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: () => <Button type="link">Edit</Button>,
    },
  ],

  template: [
    {
      title: 'Internal Name',
      dataIndex: 'internalName',
      key: 'internalName',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Associated Tier',
      dataIndex: 'associatedTier',
      key: 'associatedTier',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: () => <Button type="link">Edit</Button>,
    },
  ],
};

export default Columns;
