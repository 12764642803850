import React, { PureComponent } from 'react';
import {
  Modal,
  Form,
  Input,
  Card,
  Checkbox,
  Select,
  Switch,
  Button,
} from 'antd';
import { isEmpty, isFinite, has, isUndefined } from 'lodash';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import defaultSettingVariations from '../../constants/defaultSettingVariationConstants';
import { updateEditHub as updateEditHubAction } from './actions';
import { isStandardHubCluster } from '../../utils/helpers';
import Loader from '../../component/Loader/Loader';

import ReasonModal from '../../component/ReasonModal/DeleteReasonModal';

const { Option } = Select;

class EditModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isProduction: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { hub } = this.props;
    if (
      !isEmpty(hub) &&
      has(hub, 'isProduction') &&
      prevState.isProduction !== hub.isProduction
    ) {
      this.setisProduction(hub);
    }
  }

  setisProduction = hub => {
    this.setState({
      isProduction: hub.isProduction,
    });
  };

  handleIsProduction = () => {
    this.setState(state => ({
      isProduction: !state.isProduction,
    }));
  };

  handleDeleteHub = () => {
    this.setState(prevState => ({
      isDeleteModalOpen: !prevState.isDeleteModalOpen,
    }));
  };

  onSubmitReason = reason => {
    const {
      onRequestDelete,
      hub,
      form: { setFieldsValue },
    } = this.props;
    setFieldsValue({
      isProduction: false,
    });
    onRequestDelete(hub.id, reason);
  };

  handleSave = e => {
    e.preventDefault();
    const { hub, form, onSave } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        onSave({
          ...hub,
          ...values,
          elOrganizationId: !isUndefined(values.elOrganizationId)
            ? values.elOrganizationId
            : null,
        });
      }
    });
  };

  onJsonEdit = ({ updated_src }) => {
    const { hub, updateEditHub } = this.props;

    updateEditHub({ ...hub, settings: updated_src });
  };

  onClusterFilter = (inp, cluster) => {
    const { clusters } = this.props;
    // eslint-disable-next-line no-param-reassign
    cluster =
      clusters &&
      clusters.find(c => parseInt(c.id, 10) === parseInt(cluster.key, 10));
    return cluster && cluster.name.toLowerCase().includes(inp.toLowerCase());
  };

  calculateHubCount(key) {
    const { groupHubsCount } = this.props;
    const launchGroup = groupHubsCount.filter(obj => obj.launchgroup === key);
    return launchGroup.length && launchGroup[0].count
      ? launchGroup[0].count
      : 0;
  }

  renderContent = () => {
    const {
      form: { getFieldDecorator, getFieldValue },
      clusters,
      hub,
      deleteHubLoading,
      elOrganizations,
    } = this.props;

    if (!hub) return null;

    let hubClusterId = '';
    if (hub.clusters && hub.clusters.length) {
      if (isFinite(hub.clusters[0])) [hubClusterId] = hub.clusters;
      else if (hub.clusters[0].cluster) hubClusterId = hub.clusters[0].cluster;
    }

    const isStandard = isStandardHubCluster(hub);
    const launchGroups = isStandard
      ? Array.from(defaultSettingVariations)
      : defaultSettingVariations.filter(lg => !lg.forStandard);
    const { isProduction } = this.state;

    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input hub name!' }],
            initialValue: hub.name,
          })(
            <Input placeholder="hub Name" disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Subdomain">
          {getFieldDecorator('subdomain', {
            initialValue: hub.subdomain,
            rules: [{ required: true, message: 'Please input a subdomain' }],
          })(
            <Input placeholder="subdomain" disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Domain">
          {getFieldDecorator('domain', { initialValue: hub.domain })(
            <Input placeholder="domain" disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Usertype Library">
          {getFieldDecorator('usertypeLibrary', {
            initialValue: hub.usertypeLibrary,
          })(
            <Select disabled={!!hub.deleteRequestedAt}>
              <Option key="none" value={null}>
                No Library
              </Option>
              {(hub?.libraries ?? []).map(library => (
                <Option key={library.id} value={library.id}>
                  {library.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Tagline">
          {getFieldDecorator('tagline', { initialValue: hub.tagline })(
            <Input placeholder="tagline" disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Logo Url">
          {getFieldDecorator('logoUrl', { initialValue: hub.logoUrl })(
            <Input placeholder="logo Url" disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Cover Url">
          {getFieldDecorator('coverUrl', { initialValue: hub.coverUrl })(
            <Input placeholder="cover Url" disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Identifier">
          {getFieldDecorator('identifier', {
            initialValue: hub.identifier,
          })(
            <Input
              placeholder="identifier"
              disabled={!!hub.deleteRequestedAt}
            />
          )}
        </Form.Item>
        <Form.Item label="School Id">
          {getFieldDecorator('school', { initialValue: hub.school })(
            <Input placeholder="school Id" disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Is Active">
          {getFieldDecorator('isActive', { initialValue: hub.isActive })(
            <Checkbox disabled={!!hub.deleteRequestedAt} />
          )}
        </Form.Item>
        <Form.Item label="Salesforce Account Id">
          {getFieldDecorator('sfAccountId', { initialValue: hub.sfAccountId })(
            <Input
              placeholder="Salesforce account id"
              disabled={!!hub.deleteRequestedAt}
            />
          )}
        </Form.Item>
        <Form.Item label="Is Production">
          {getFieldDecorator('isProduction')(
            <Switch
              disabled={
                isEmpty(getFieldValue('sfAccountId')) || !!hub.deleteRequestedAt
              }
              checked={
                (!isEmpty(getFieldValue('sfAccountId')) && isProduction) ||
                false
              }
              onChange={this.handleIsProduction}
            />
          )}
        </Form.Item>
        <Form.Item label="Request Hub Deletion">
          <Button
            type="danger"
            onClick={() => this.handleDeleteHub()}
            loading={deleteHubLoading}
            disabled={!!hub.deleteRequestedAt}
          >
            Request Hub Deletion
          </Button>
        </Form.Item>
        <Form.Item label="Launch Group">
          {getFieldDecorator('defaultSetting', {
            initialValue: hub.defaultSetting,
          })(
            <Select disabled={!!hub.deleteRequestedAt}>
              {launchGroups.map(
                option =>
                  option.key !== 'default' && (
                    <Option key={option.key} value={option.key}>
                      {`${option.name} (${this.calculateHubCount(option.key)})`}
                    </Option>
                  )
              )}
            </Select>
          )}
        </Form.Item>
        {[
          'standard',
          'transitioned-standard',
          'standard-plus',
          'standard-plus-fpu',
        ].includes(hub?.standardPlatformType) && (
          <Form.Item label="V2 Platform Type">
            {getFieldDecorator('standardPlatformType', {
              initialValue: hub?.standardPlatformType,
            })(
              <Select disabled={!!hub.deleteRequestedAt}>
                <Option value="enterprise">Classic</Option>
                <Option value="standard">V2 | PG Essentials | New</Option>
                <Option value="transitioned-standard">
                  V2 | PG Essentials | Upgraded
                </Option>
                <Option value="standard-plus">V2 | PG + | New</Option>
                <Option value="standard-plus-fpu">V2 | PG + | Upgraded</Option>
              </Select>
            )}
          </Form.Item>
        )}
        {elOrganizations && elOrganizations.length ? (
          <>
            <Form.Item label="Is EL Only?">
              {getFieldDecorator('isElOnly', { initialValue: hub?.isElOnly })(
                <Switch
                  disabled={!!hub.deleteRequestedAt}
                  defaultChecked={hub?.isElOnly}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Associated EL Organization"
              extra="EL Organization already associated with a hub will be disabled"
            >
              {getFieldDecorator('elOrganizationId', {
                rules: getFieldValue('isElOnly')
                  ? [
                      {
                        required: true,
                        message:
                          'Please select EL Organization to associate this hub with.',
                      },
                    ]
                  : [],
                initialValue: hub?.elOrganizationId,
              })(
                <Select
                  style={{ width: 300 }}
                  disabled={!!hub.deleteRequestedAt}
                  placeholder="Select Associated EL Organization"
                  defaultActiveFirstOption={false}
                  allowClear
                >
                  {elOrganizations.map(elOrganization => (
                    <Option
                      value={elOrganization.id}
                      key={elOrganization.id}
                      disabled={!!elOrganization.pgOrganizationId}
                    >
                      {elOrganization.name} | {elOrganization.id}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </>
        ) : null}
        <Form.Item label="Cluster">
          {getFieldDecorator('clusters', {
            initialValue: hubClusterId,
            rules: [{ required: true, message: 'Please input a cluster' }],
          })(
            <Select
              style={{ width: 300 }}
              showSearch
              placeholder="Select Cluster"
              filterOption={this.onClusterFilter}
              disabled={!!hub.deleteRequestedAt}
            >
              {clusters.map(cluster => (
                <Option key={cluster.id} value={cluster.id}>
                  {cluster.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {hub.settings && (
          <Card title="hub Settings">
            <ReactJson
              style={{
                overflow: 'auto',
                fontSize: '12px',
              }}
              name="settings"
              collapsed
              src={hub.settings}
              onEdit={this.onJsonEdit}
              onAdd={this.onJsonEdit}
              onDelete={this.onJsonEdit}
            />
          </Card>
        )}
      </Form>
    );
  };

  render() {
    const { hub, onCancel, editHubLoading } = this.props;
    const { isDeleteModalOpen } = this.state;

    return (
      <>
        {isDeleteModalOpen && (
          <ReasonModal
            isOpen={isDeleteModalOpen}
            onSubmit={this.onSubmitReason}
            onClose={() => this.handleDeleteHub()}
            title="Hub Deletion Reason"
            errorMessage="Please provide a reason to make hub deletion request"
          />
        )}
        <Modal
          title="Edit hub"
          visible={hub != null}
          onOk={this.handleSave}
          onCancel={onCancel}
          okText="Save"
          width={800}
        >
          {editHubLoading ? <Loader /> : this.renderContent()}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  hub: { editHub: hub, deleteHubLoading, editHubLoading },
}) => ({
  hub,
  deleteHubLoading,
  editHubLoading,
});

const mapDispatchToProps = {
  updateEditHub: updateEditHubAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({})(EditModal));
