import React from 'react';
import { Modal, Button } from 'antd';
import { mergeTags } from '../utils';

const MergeTagsModal = ({ visible, onCancel, onMergeTagClick }) => {
  return (
    <Modal
      title="Select Merge Tags"
      closable={true}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={[
        <Button onClick={onCancel} key="close">
          Close
        </Button>,
      ]}
      className="automated-marketing__modal--merge-tags"
    >
      <ul>
        {mergeTags.map(tag => (
          <li key={tag.value}>
            <Button onClick={() => onMergeTagClick(tag.value)}>
              {tag.name}
            </Button>
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default MergeTagsModal;
