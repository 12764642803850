import { request } from '../utils/request';

export const fetchFeatureSettings = params =>
  request.get(`/superadmin/features`, { params });

export const updateFeatureSettings = data =>
  request.put(`/superadmin/features?objectType=${data.objectType}`, data.body);

export const getModulesList = params =>
  request.get(`/superadmin/get-modules-list?moduleType=${params.moduleType}`);

export const fetchLaunchGroupHubs = data => 
  request.post(
    `/superadmin/launch_group_hubs/${data.key}?source=${data.source}${
      data.affectedFeature ? `&affectedFeature=${data.affectedFeature}` : ''
    }`,
    { featureObject: data.featureObject }
  );

export const startDownload = data =>
  request.get(
    `/superadmin/launch_group_download/${data.lg}?source=${data.source}`
  );

export const resetToDefault = data =>
  request.put(
    `/superadmin/launch_group_hubs/${data.lg}/reset_feature?source=${
      data.source
    }&feature=${data.fk}`,
    { filterParams: data.filterParams }
  );

export const fetchGroupHubsCount = data => 
  request.get(`/superadmin/launch_group_count/${data}`);
