/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, PageHeader, Switch } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Content from '../../../component/Content';
import DefaultTextTable from '../DefaultTextTable';
import { useHubTextList } from '../queries';

const HubText = () => {
  const history = useHistory();
  const [isArchived, setIsArchived] = useState(false);
  const { data, isLoading } = useHubTextList({ isArchived });

  const hubTextList = React.useMemo(() => {
    if (!data) return [];
    const hubTexts = get(data, 'hubTexts');
    return (
      hubTexts?.map(d => ({
        id: d.id,
        identifier: d.identifier,
        defaultText: d.text,
        modifiedByHubsCount: (d.modifedhubs || []).length,
        modifiedByHubs: d.modifedhubs || [],
        modifiedOn: d.updated_at,
        module: d.module,
      })) || []
    );
  }, [data]);

  return (
    <>
      <PageHeader
        title="Hub Text Settings"
        extra={[
          <label>Archived Identifiers </label>,
          <Switch
            className="archived-identifiers-toggle"
            checked={isArchived}
            onChange={val => {
              setIsArchived(val);
            }}
            title="Archived Identifiers"
          />,
          <Button
            type="default"
            key="1"
            onClick={() => {
              history.push('/default-text/cluster');
            }}
          >
            Cluster Text Settings
          </Button>,
        ]}
      />
      <Content>
        <DefaultTextTable
          data={hubTextList}
          loading={isLoading}
          isArchived={isArchived}
        />
      </Content>
    </>
  );
};

export default HubText;
