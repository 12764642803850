import { takeLatest, put, call } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import {
  setTableLoading,
  setTableData,
  FETCH_TEMPLATES,
  FETCH_TIPS,
  setTableKey,
  FETCH_ALUM_STUDENT_OUTREACH_TEMPLATES,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';

import {
  fetchAlumStudentOutreachTemplateApi,
  fetchTemplatesApi,
  fetchTipsApi,
} from '../../../api/templatesAndTips';

const sagas = [
  takeLatest(FETCH_TEMPLATES, fetchTemplatesSaga),
  takeLatest(FETCH_TIPS, fetchTipsSaga),
  takeLatest(
    FETCH_ALUM_STUDENT_OUTREACH_TEMPLATES,
    fetchAlumStudentOutreachTemplatesSaga
  ),
];

export default sagas;

function* fetchTemplatesSaga({ data = {} }) {
  try {
    yield put(setTableLoading(true));
    const { searchText, page, sort = {}, showFor } = data;
    const { data: results } = yield call(fetchTemplatesApi, {
      searchText,
      page,
      ...sort,
      showFor,
    });

    yield put(setTableData(results));

    if (isEmpty(data)) {
      yield put(setTableKey());
    }
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Templates.' },
    });
  } finally {
    yield put(setTableLoading(false));
  }
}

function* fetchTipsSaga({ data = {} }) {
  try {
    yield put(setTableLoading(true));
    const { searchText, page, sort = {} } = data;
    const { internalTipName: name, createdAt } = sort;
    const { data: tipData } = yield call(fetchTipsApi, {
      searchText,
      page,
      name,
      createdAt,
    });
    yield put(setTableData(tipData));

    if (isEmpty(data)) {
      yield put(setTableKey());
    }
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Tips.' },
    });
  } finally {
    yield put(setTableLoading(false));
  }
}

function* fetchAlumStudentOutreachTemplatesSaga({ data = {} }) {
  try {
    yield put(setTableLoading(true));
    const { page = 1, limit = 10, sort = null } = data;
    const { data: results } = yield call(fetchAlumStudentOutreachTemplateApi, {
      page,
      limit,
      ...(sort && {
        sort: JSON.stringify(sort),
      }),
    });
    yield put(
      setTableData({
        alumStudentOutreachTemplates: get(results, 'data.templates', []),
      })
    );
    if (isEmpty(data)) {
      yield put(setTableKey());
    }
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Templates.' },
    });
  } finally {
    yield put(setTableLoading(false));
  }
}
