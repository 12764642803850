import { put, call, takeLatest, select } from 'redux-saga/effects';
import get from 'lodash/get';

import {
  updateProductUpdatePostApi,
  saveProductUpdatePostApi,
  fetchProductUpdatePostsApi,
  fetchProductUpdatePostBasedOnIdApi,
  deleteProductUpdatePostApi,
} from '../api/productUpdatePosts';
import history from '../history';
import { POPUP_ALERT } from '../actions/alert';
import {
  SAVE_PRODUCT_UPDATE_POST,
  SET_PRODUCT_UPDATE_POSTS,
  productUpdatePosts,
  FETCH_PRODUCT_UPDATE_POSTS,
  FETCH_INDV_PRODUCT_UPDATE_POST,
  DELETE_PRODUCT_UPDATE_POST,
  setProductUpdatePostsLoading,
  setProductUpdateUpdating,
} from '../actions/productUpdates';

function* saveProductUpdatePost({ data }) {
  try {
    yield put(setProductUpdateUpdating(data));
    if (data.id) {
      const { data: savedPost } = yield call(updateProductUpdatePostApi, data);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Post Updated!' },
      });
      savedPost.objectId = savedPost.objectId ? savedPost.objectId : ['ALL']
      yield put({
        type: SET_PRODUCT_UPDATE_POSTS,
        data: [...Object.values(yield select(productUpdatePosts)), savedPost],
      });
    } else {
      const { data: savedProductUpdatePost } = yield call(
        saveProductUpdatePostApi,
        data
      );
      history.push(`/product-releases/edit/${savedProductUpdatePost.id}`);
      savedProductUpdatePost.objectId = savedProductUpdatePost.objectId ? savedProductUpdatePost.objectId : ['ALL']
      yield put({
        type: SET_PRODUCT_UPDATE_POSTS,
        data: [
          ...Object.values(yield select(productUpdatePosts)),
          savedProductUpdatePost,
        ],
      });

      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Post Saved!' },
      });
    }
    yield put(setProductUpdateUpdating(null));
  } catch (e) {
    yield put(setProductUpdateUpdating(null));
    if (e.response) {
      const message = get(e, 'response.data.error.details.0.message');
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message },
      });
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error Saving Product Update Post' },
      });
    }
  }
}

function* fetchProductUpdatePosts() {
  try {
    yield put(setProductUpdatePostsLoading(true));
    const { data } = yield call(fetchProductUpdatePostsApi);
    yield put({
      type: SET_PRODUCT_UPDATE_POSTS,
      data,
    });
    yield put(setProductUpdatePostsLoading(false));
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Product Update Post' },
    });
  }
}

function* fetchIndvProductUpdatePost({ data }) {
  try {
    const { data: post } = yield call(
      fetchProductUpdatePostBasedOnIdApi,
      data.id
    );
    post.objectId = post.objectId ? post.objectId : ['ALL']
    yield put({
      type: SET_PRODUCT_UPDATE_POSTS,
      data: [...Object.values(yield select(productUpdatePosts)), post],
    });
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Product Update Post' },
    });
  }
}

function* deleteProductUpdatePost({ data }) {
  try {
    yield call(deleteProductUpdatePostApi, data.id);
    yield put({
      type: SET_PRODUCT_UPDATE_POSTS,
      data: Object.values(yield select(productUpdatePosts)).filter(
        post => post.id !== data.id
      ),
    });
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Deleting Product Update Post' },
    });
  }
}

export default [
  takeLatest(SAVE_PRODUCT_UPDATE_POST, saveProductUpdatePost),
  takeLatest(FETCH_PRODUCT_UPDATE_POSTS, fetchProductUpdatePosts),
  takeLatest(FETCH_INDV_PRODUCT_UPDATE_POST, fetchIndvProductUpdatePost),
  takeLatest(DELETE_PRODUCT_UPDATE_POST, deleteProductUpdatePost),
];
