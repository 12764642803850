import React from 'react';
import { Button, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import { stopLogstash } from '../actions';

const locale = {
  emptyText: 'No pending processes.'
}

const LogstashStatus = ({ esLogstash, stopLogstash }) => {
  let { status, loading } = esLogstash.logstashStatus;

  const columns = [
    {
      title: 'Hub',
      dataIndex: 'hub',
      key: 'hub',
      render: (id, hub) => (
        <Tag key={id}>
          {hub.id} - {hub.name}
        </Tag>
      ),
    },
    {
      title: 'Running All Files',
      dataIndex: 'allFiles',
      key: 'allFiles',
      render: () => 'true',
    },
    {
      title: 'Status',
      dataIndex: 'esStatus',
      key: 'esStatus',
      render: status => <Tag>{status}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (id, { esStatus }) => {
        if (['pending', 'processing'].includes(esStatus))
          return (
            <Button
              disabled={loading}
              type="danger"
              onClick={() => stopLogstash(id)}
            >
              Stop
            </Button>
          );
        return null;
      },
    },
  ];

  return (
    <div>
      <h3>Running Processes</h3>
      <Table
        pagination={false}
        columns={columns}
        dataSource={status}
        locale={locale}
      />
    </div>
  );
};

const mapStateToProps = ({ esLogstash }) => ({ esLogstash });

const mapDispatchToProps = {
  stopLogstash,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogstashStatus);
