const prefix = 'Usertype-Settings-Manage';

// CRUD Actions
// For Upsells
export const FETCH_UPSELLS = `${prefix}/FETCH_UPSELLS`;
export const FETCH_UPSELL_BY_ID = `${prefix}/FETCH_UPSELL_BY_ID`;
export const SAVE_UPSELL = `${prefix}/SAVE_UPSELL`;
export const DELETE_UPSELL = `${prefix}/DELETE_UPSELL`;

// For Manage UsertypeLibrary
export const FETCH_USERTYPE_LIBRARIES = `${prefix}/FETCH_USERTYPE_LIBRARIES`;
export const FETCH_USERTYPE_LIBRARY_BY_ID = `${prefix}/FETCH_USERTYPE_LIBRARY_BY_ID`;
export const SAVE_USERTYPE_LIBRARY = `${prefix}/SAVE_USERTYPE_LIBRARY`;
export const DELETE_USERTYPE_LIBRARY = `${prefix}/DELETE_USERTYPE_LIBRARY`;
export const FETCH_USERTYPE_LIBRARY_UPSELLS_AND_TEMPLATES = `${prefix}/FETCH_USERTYPE_LIBRARY_UPSELLS_AND_TEMPLATES`;

// For Manage Template/s
export const FETCH_TEMPLATE = `${prefix}/FETCH_TEMPLATE`;
export const SAVE_TEMPLATE = `${prefix}/SAVE_TEMPLATE`;
export const FETCH_TEMPLATES = `${prefix}/FETCH_TEMPLATES`;
export const FETCH_ACTIVE_HUBS = `${prefix}/FETCH_ACTIVE_HUBS`;
export const DELETE_TEMPLATE = `${prefix}/DELETE_TEMPLATE`;

// Other Actions
export const CLEAR_DATA = `${prefix}/CLEAR_DATA`;
export const SHOW_FORM = `${prefix}/SHOW_FORM`;
export const CLEAR_FORM = `${prefix}/CLEAR_FORM`;
export const MANAGE_LOADING = `${prefix}/MANAGE_LOADING`;
export const IS_SINGLE_LOADING = `${prefix}/IS_SINGLE_LOADING`;
export const IS_SAVING = `${prefix}/IS_SAVING`;
export const SET_TYPE_OF_MANAGE = `${prefix}/SET_TYPE_OF_MANAGE`;
export const SET_MANAGE_DATA = `${prefix}/SET_MANAGE_DATA`;
export const SET_MANAGE_DATA_PAGE = `${prefix}/SET_MANAGE_DATA_PAGE`;
export const SET_MANAGE_DATA_COUNT = `${prefix}/SET_MANAGE_DATA_COUNT`;
export const SET_MANAGE_DATA_PAGE_SIZE = `${prefix}/SET_MANAGE_DATA_PAGE_SIZE`;
export const CANCEL_MODAL_VISIBLE = `${prefix}/CANCEL_MODAL_VISIBLE`;
export const DELETE_MODAL_VISIBLE = `${prefix}/DELETE_MODAL_VISIBLE`;
export const CONFIRM_MODAL_VISIBLE = `${prefix}/CONFIRM_MODAL_VISIBLE`;

// For Upsells
export const SET_UPSELL_DATA = `${prefix}/SET_UPSELL_DATA`;
export const SET_UPSELL_NAME = `${prefix}/SET_UPSELL_NAME`;
export const SET_UPSELL_DESCRIPTION = `${prefix}/SET_UPSELL_DESCRIPTION`;
export const SET_UPSELL_CTA_TEXT = `${prefix}/SET_UPSELL_CTA_TEXT`;
export const SET_UPSELL_CTA_LINK = `${prefix}/SET_UPSELL_CTA_LINK`;
export const SET_UPSELL_TYPE = `${prefix}/SET_UPSELL_TYPE`;
export const SET_UPSELL_EMAILS = `${prefix}/SET_UPSELL_EMAILS`;
export const UPDATE_UPSELL_FORM = `${prefix}/UPDATE_UPSELL_FORM`;

export const SET_USERTYPE_LIBRARY_DATA = `${prefix}/SET_USERTYPE_LIBRARY_DATA`;
export const SET_USERTYPE_LIBRARY_NAME = `${prefix}/SET_USERTYPE_LIBRARY_NAME`;
export const SET_USERTYPE_LIBRARY_SELECTED_USERTYPE_TEMPLATES = `${prefix}/SET_USERTYPE_LIBRARY_SELECTED_USERTYPE_TEMPLATES`;
export const SET_USERTYPE_LIBRARY_UPSELLS = `${prefix}/SET_USERTYPE_LIBRARY_UPSELLS`;
export const SET_USERTYPE_LIBRARY_TEMPLATES = `${prefix}/SET_USERTYPE_LIBRARY_TEMPLATES`;

// For Template
export const SET_TEMPLATE_SETTINGS = `${prefix}/SET_TEMPLATE_SETTINGS`;
export const SET_TEMPLATE_NAME = `${prefix}/SET_TEMPALTE_NAME;`;
export const SET_TEMPLATE_USER_TYPE = `${prefix}/SET_TEPPLATE_USER_TYPE`;
export const SET_UPSELL_OPTIONS = `${prefix}/SET_UPSELL_OPTIONS`;
export const SET_TEMPLATE_TABLE_ROWS = `${prefix}/SET_TEMPLATE_TABLE_ROWS`;
export const SET_GROUPED_TEMPLATES = `${prefix}/SET_GROUPED_TEMPLATES`;
export const ACTIVE_HUBS_MODAL_VISIBLE = `${prefix}/ACTIVE_HUBS_MODAL_VISIBLE`;
export const SET_ACTIVE_HUBS_LOADING = `${prefix}/SET_ACTIVE_HUBS_LOADING`;
export const SET_CAN_EDIT_TEMPLATE = `${prefix}/SET_CAN_EDIT_TEMPLATE`;
export const RESET_TEMPLATE_STATE = `${prefix}/RESET_TEMPLATE_STATE`;

export const SET_ACTIVE_ID_FOR_MODAL = `${prefix}/SET_ACTIVE_ID_FOR_MODAL`;

// Action Creators
// For Upsells
export const fetchUpsells = data => ({
  type: FETCH_UPSELLS,
  data,
});

export const fetchUpsellById = data => ({
  type: FETCH_UPSELL_BY_ID,
  data,
});

export const saveUpsell = data => ({
  type: SAVE_UPSELL,
  data,
});

export const deleteUpsell = data => ({
  type: DELETE_UPSELL,
  data,
});

export const fetchUsertypeLibraries = data => ({
  type: FETCH_USERTYPE_LIBRARIES,
  data,
});

export const fetchUsertypeLibraryById = data => ({
  type: FETCH_USERTYPE_LIBRARY_BY_ID,
  data,
});

export const saveUsertypeLibrary = data => ({
  type: SAVE_USERTYPE_LIBRARY,
  data,
});

export const deleteUsertypeLibrary = data => ({
  type: DELETE_USERTYPE_LIBRARY,
  data,
});

export const fetchUsertypeLibraryUpsellsAndTemplates = data => ({
  type: FETCH_USERTYPE_LIBRARY_UPSELLS_AND_TEMPLATES,
  data,
});

// For Manage Template/s
export const fetchTemplate = data => ({
  type: FETCH_TEMPLATE,
  data,
});

export const saveTemplate = data => ({
  type: SAVE_TEMPLATE,
  data,
});

export const fetchTemplates = () => ({
  type: FETCH_TEMPLATES,
});

export const handleActionClearData = () => ({
  type: CLEAR_DATA,
});

export const handleActionShowForm = data => ({
  type: SHOW_FORM,
  data,
});

export const handleActionClearForm = data => ({
  type: CLEAR_FORM,
  data,
});

export const handleActionSetManageLoading = data => ({
  type: MANAGE_LOADING,
  data,
});

export const handleActionSetIsSingleLoading = data => ({
  type: IS_SINGLE_LOADING,
  data,
});

export const handleActionsSetIsSaving = data => ({
  type: IS_SAVING,
  data,
});

export const handleActionSetManageType = data => ({
  type: SET_TYPE_OF_MANAGE,
  data,
});

export const handleActionSetManageData = data => ({
  type: SET_MANAGE_DATA,
  data,
});

export const handleActionSetManageDataPage = data => ({
  type: SET_MANAGE_DATA_PAGE,
  data,
});

export const handleActionSetManageDataCount = data => ({
  type: SET_MANAGE_DATA_COUNT,
  data,
});

export const handleActionSetManageDataPageSize = data => ({
  type: SET_MANAGE_DATA_PAGE_SIZE,
  data,
});

export const handleActionCancelModalVisible = data => ({
  type: CANCEL_MODAL_VISIBLE,
  data,
});

export const handleActionDeleteModalVisible = data => ({
  type: DELETE_MODAL_VISIBLE,
  data,
});

export const handleActionConfirmModalVisible = data => ({
  type: CONFIRM_MODAL_VISIBLE,
  data,
});

export const setUpsellData = data => ({
  type: SET_UPSELL_DATA,
  data,
});

export const setUpsellName = data => ({
  type: SET_UPSELL_NAME,
  data,
});

export const setUpsellDescription = data => ({
  type: SET_UPSELL_DESCRIPTION,
  data,
});

export const setUpsellCtaText = data => ({
  type: SET_UPSELL_CTA_TEXT,
  data,
});

export const setUpsellCtaLink = data => ({
  type: SET_UPSELL_CTA_LINK,
  data,
});

export const setUpsellType = data => ({
  type: SET_UPSELL_TYPE,
  data,
});

export const setUpsellEmails = data => ({
  type: SET_UPSELL_EMAILS,
  data,
});

export const setUsertypeLibraryData = data => ({
  type: SET_USERTYPE_LIBRARY_DATA,
  data,
});

export const setUsertypeLibraryName = data => ({
  type: SET_USERTYPE_LIBRARY_NAME,
  data,
});

export const setUsertypeLibrarySelectedUsertypeTemplates = data => ({
  type: SET_USERTYPE_LIBRARY_SELECTED_USERTYPE_TEMPLATES,
  data,
});

export const setUsertypeLibraryUpsells = data => ({
  type: SET_USERTYPE_LIBRARY_UPSELLS,
  data,
});

export const setUsertypeLibraryTemplates = data => ({
  type: SET_USERTYPE_LIBRARY_TEMPLATES,
  data,
});

export const setTemplateName = data => ({
  type: SET_TEMPLATE_NAME,
  data,
});

export const setTemplateUserType = data => ({
  type: SET_TEMPLATE_USER_TYPE,
  data,
});

export const setTemplateSettings = data => ({
  type: SET_TEMPLATE_SETTINGS,
  data,
});

export const setUpsellOptions = data => ({
  type: SET_UPSELL_OPTIONS,
  data,
});

export const setTemplateTableRows = data => ({
  type: SET_TEMPLATE_TABLE_ROWS,
  data,
});

export const setGroupedTemplates = data => ({
  type: SET_GROUPED_TEMPLATES,
  data,
});

export const setActiveHubsLoading = data => ({
  type: SET_ACTIVE_HUBS_LOADING,
  data,
});

export const handleActionActiveHubsModalVisible = data => ({
  type: ACTIVE_HUBS_MODAL_VISIBLE,
  data,
});

export const fetchActiveHubs = data => ({
  type: FETCH_ACTIVE_HUBS,
  data,
});

export const deleteTemplate = data => ({
  type: DELETE_TEMPLATE,
  data,
});

export const setCanEditTemplate = data => ({
  type: SET_CAN_EDIT_TEMPLATE,
  data,
});

export const resetTemplateState = () => ({
  type: RESET_TEMPLATE_STATE,
});

export const setActiveIdForModal = data => ({
  type: SET_ACTIVE_ID_FOR_MODAL,
  data,
});

export const updateUpsellForm = data => ({
  type: UPDATE_UPSELL_FORM,
  data,
});

// Selectors
export const selectManageData = ({ usertypeManage }) =>
  usertypeManage.manageData;
export const selectManageDataPage = ({ usertypeManage }) =>
  usertypeManage.manageDataPage;
export const selectUpsellOptions = ({ usertypeManage }) =>
  usertypeManage.upsellOptions;
