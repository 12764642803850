export const GET_SEEDS = 'seeds/GET_SEEDS';
export const SET_SEEDS = 'seeds/SET_SEEDS';
export const CREATE_SEED = 'seeds/CREATE_SEEDS';
export const DELETE_SEED = 'seeds/DELETE_SEED';
export const MAKE_DEFAULT = 'seeds/MAKE_DEFAULT';
export const DOWNLOAD_SEED = 'seeds/DOWNLOAD_SEED';

export const getSeeds = options => ({
  type: GET_SEEDS,
  options
})

export const createSeed = (createOptions, getOptions) => ({
  type: CREATE_SEED,
  createOptions,
  getOptions,
})

export const updateSeeds = data => ({
  type: SET_SEEDS,
  data
}) 

export const deleteSeed = id => ({
  type: DELETE_SEED,
  id
})

export const makeSeedDefault = id => ({
  type: MAKE_DEFAULT,
  id
})

export const downloadSeed = id => ({
  type: DOWNLOAD_SEED,
  id
})