import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Popconfirm, Table } from 'antd';
import { map } from 'lodash';

import {
  fetchLinkedProjects as fetchLinkedProjectsAction,
  setLinkedProjects as setLinkedProjectsAction,
  addLinkedProjects as addLinkedProjectsAction,
  updateLinkedProjects as updateLinkedProjectsAction,
  deleteLinkedProjects as deleteLinkedProjectAction,
  fetchProjectChangelog as fetchProjectChangelogAction,
  revertProjectTemplate as revertProjectTemplateAction,
  setProjectTemplateChangelog as setProjectTemplateChangelogAction,
} from '../../actions';

import classes from './LinkedProjectsModal.module.scss';
import EditTemplate from './EditTemplate';

const LinkedProjectsModal = ({
  careerPathId,
  onClose,
  fetchLinkedProjects,
  setLinkedProjects,
  addLinkedProjects,
  updateLinkedProjects,
  deleteLinkedProjects,
  fetchProjectChangelog,
  revertProjectTemplate,
  linkedProjects,
  linkedProjectsCount,
  projectTemplateChangeLog,
  setProjectTemplateChangelog,
  isLoading,
  form,
}) => {
  React.useEffect(() => {
    fetchLinkedProjects({ careerPathId });

    return () => {
      setLinkedProjects([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerPathId]);

  const [editModal, setEditModal] = React.useState();
  const [currentProject, setCurrentProject] = React.useState({});
  const [showChangeLog, setShowChangeLog] = React.useState();
  const [revertChangeLog, setReverChangeLog] = React.useState();
  const [changeLogId, setChangeLogId] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);

  const closeEditModal = () => {
    setCurrentProject({});
    setChangeLogId(undefined);
    setEditModal(undefined);
  };

  const tableColumns = React.useMemo(
    () => [
      {
        title: 'Project Name',
        dataIndex: 'name',
        key: 'name',
        // render: (text, record) => (
        // ),
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        // render: (text, record) => (
        // ),
      },
      {
        title: 'changelog',
        dataIndex: 'changelog',
        key: 'changelog',
        render: (text, record) => (
          <Button
            size="small"
            type="link"
            onClick={() => {
              fetchProjectChangelog({
                projectTemplateId: record.projectTemplate,
              });
              setShowChangeLog(record.projectTemplate);
            }}
          >
            view changelog
          </Button>
        ),
      },
      {
        title: 'actions',
        dataIndex: 'actions',
        fixed: 'right',
        render: (text, record) => (
          <Button.Group>
            <Button
              size="small"
              onClick={() => {
                setCurrentProject(record);
                setEditModal('edit');
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this linked project?"
              onConfirm={() =>
                deleteLinkedProjects({
                  id: record.id,
                  careerPathId,
                  page: currentPage,
                })
              }
              okText="Yes"
              cancelText="No"
              placement="topLeft"
            >
              <Button size="small" type="danger">
                Delete
              </Button>
            </Popconfirm>
          </Button.Group>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [careerPathId, deleteLinkedProjects, fetchProjectChangelog]
  );

  const changeLogColumns = React.useMemo(
    () => [
      {
        title: 'user',
        dataIndex: 'user',
        key: 'user',
      },

      {
        title: 'updatedAt',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
      },
      {
        title: 'viewChangeLog',
        dataIndex: 'viewChangeLog',
        key: 'viewChangeLog',
        render: (text, record) => (
          <Button
            size="small"
            type="primary"
            onClick={() => {
              setCurrentProject({ formData: record.oldValue });
              setChangeLogId(record.id);
              setEditModal('revert');
            }}
          >
            View changes
          </Button>
        ),
      },
      {
        title: 'action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
          <Button
            size="small"
            type="danger"
            onClick={() => setReverChangeLog(record.id)}
            loading={isLoading.revertProjectTemplate === record.id}
          >
            Revert
          </Button>
        ),
      },
    ],
    [isLoading.revertProjectTemplate]
  );

  return (
    <>
      <Modal
        title="Manage Linked Projects"
        visible
        closable={!isLoading?.linkedProjects}
        maskClosable={!isLoading?.linkedProjects}
        onCancel={() => {
          onClose();
        }}
        footer={null}
        width={840}
        className={classes.root}
        wrapClassName={classes.wrapperModal}
      >
        <div>
          <h4>Add new Project</h4>
          <Button
            type="primary"
            className={classes.addCta}
            onClick={() => setEditModal('add')}
          >
            + Add New Project
          </Button>
        </div>
        <div>
          <h4>Manage Existing Projects</h4>
          <Table
            loading={!!isLoading?.linkedProjects}
            columns={tableColumns}
            dataSource={map(linkedProjects, item => ({
              ...item,
              ...(item?.projectTemplate || {}),
              id: item.id,
              projectTemplate: item?.projectTemplate?.id,
            }))}
            pagination={{
              pageSize: 10,
              total: linkedProjectsCount,
              onChange: page => {
                setCurrentPage(page);
                fetchLinkedProjects({ careerPathId, page });
              },
            }}
          />
        </div>
      </Modal>
      {showChangeLog && (
        <Modal
          title="Project Template Changelog"
          visible={!!showChangeLog}
          onCancel={() => {
            setShowChangeLog(null);
            setProjectTemplateChangelog([]);
          }}
          footer={null}
          wrapClassName={classes.changeLogWrapper}
        >
          <Table
            loading={
              !!(
                isLoading.projectTemplateChangelog ||
                isLoading.revertProjectTemplate
              )
            }
            columns={changeLogColumns}
            dataSource={projectTemplateChangeLog.map(changelog => ({
              ...changelog,
              key: changelog.id,
            }))}
            pagination={false}
          />
        </Modal>
      )}
      {editModal && (
        <>
          <EditTemplate
            editModal={editModal}
            closeEditModal={closeEditModal}
            form={form}
            updateLinkedProjects={updateLinkedProjects}
            addLinkedProjects={addLinkedProjects}
            careerPathId={careerPathId}
            currentProject={currentProject}
            revertProjectTemplate={() => setReverChangeLog(changeLogId)}
            wrapClassName={classes.editModalWrapper}
            currentPage={currentPage}
          />
        </>
      )}
      <Modal
        visible={!!revertChangeLog}
        title="Revert changes?"
        onOk={() => {
          revertProjectTemplate({ changeLogId: revertChangeLog });
          setReverChangeLog(undefined);
        }}
        onCancel={() => {
          setReverChangeLog(undefined);
        }}
        wrapClassName={classes.revertConfirmWrapper}
      >
        Are you sure you would like to revert to this version? The current
        version will be lost and the data for this project template will be
        updated across ALL hubs! Please ensure that the data within this version
        is accurate before confirming.
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  manageCareerPaths: {
    isLoading,
    linkedProjects,
    linkedProjectsCount,
    projectTemplateChangeLog,
  },
}) => ({
  isLoading,
  linkedProjects,
  linkedProjectsCount,
  projectTemplateChangeLog,
});

const mapDispatchToProps = {
  fetchLinkedProjects: fetchLinkedProjectsAction,
  setLinkedProjects: setLinkedProjectsAction,
  addLinkedProjects: addLinkedProjectsAction,
  updateLinkedProjects: updateLinkedProjectsAction,
  deleteLinkedProjects: deleteLinkedProjectAction,
  fetchProjectChangelog: fetchProjectChangelogAction,
  revertProjectTemplate: revertProjectTemplateAction,
  setProjectTemplateChangelog: setProjectTemplateChangelogAction,
};

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(LinkedProjectsModal)
);
