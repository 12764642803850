import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import 'antd/dist/antd.css';

import './index.sass';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import rootSaga from './sagas';
import history from './history';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 60 mins
      cacheTime: 1000 * 60 * 30, // 30 mins
      retry: 1, // retry once automatically after failing
      // failed queries are retried 2 more times on window focus
      refetchOnWindowFocus: q =>
        !(q.state.status === 'error' && q.state.errorUpdateCount > 2),
    },
  },
});

const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </QueryClientProvider>,
  document.getElementById('root')
);

// registerServiceWorker();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
