import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Table, Switch, Popconfirm, Tooltip } from 'antd';
import s from './Alert.module.scss';
import {
  fetchExistingConfiguration as fetchExistingConfigurationAction,
  fetchConfigurationBasedOnId as fetchConfigurationBasedOnIdAction,
  saveBannerConfiguration as saveBannerConfigurationAction,
  clearConfigurationForm as clearConfigurationFormAction,
} from '../../actions/configureBanner';
import FullPageLoading from '../../component/FullPageLoading';
import { shouldShowConfirmation, missingRequiredFieldMsg } from './_utils';

class BannerTable extends React.Component {
  columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Set as active',
      dataIndex: 'active',
      key: 'active',
      render: (t, obj) => {
        const { bannerList } = this.props;
        if (missingRequiredFieldMsg(obj, t)) {
          return (
            <Tooltip title={missingRequiredFieldMsg(obj)}>
              <Switch size="small" checked={t} disabled />
            </Tooltip>
          );
        }

        if (!t && shouldShowConfirmation(bannerList, obj)) {
          return (
            <div role="presentation" onClick={e => e.stopPropagation()}>
              <Popconfirm
                title={
                  <div>
                    There&apos;s already an active banner for one of the hubs
                    you&apos;ve selected.
                    <br />
                    Are you sure you still want to set this banner as active?
                  </div>
                }
                onConfirm={this.onToggleBanner(obj)}
                okText="Yes"
                cancelText="No"
              >
                <Switch size="small" checked={t} />
              </Popconfirm>
            </div>
          );
        }

        return (
          <Switch onClick={this.onToggleBanner(obj)} size="small" checked={t} />
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDateStr',
      key: 'startDateStr',
    },
    {
      title: 'End Date',
      dataIndex: 'endDateStr',
      key: 'endDateStr',
    },
  ];

  componentDidMount() {
    const { fetchExistingConfiguration } = this.props;
    fetchExistingConfiguration();
  }

  onToggleBanner = obj => (c, e) => {
    if (e) {
      e.stopPropagation();
    }
    const { saveBannerConfiguration } = this.props;
    saveBannerConfiguration({ ...obj, active: !obj.active });
  };

  handleCreate = () => {
    const { history, clearConfigurationForm } = this.props;
    clearConfigurationForm();
    history.push('/configure-alert-banner/new');
    // const { setShowForm } = this.props;
    // setShowForm(true);
  };

  onRowClick = record => {
    const { history, fetchConfigurationBasedOnId } = this.props;
    fetchConfigurationBasedOnId(record.id);
    history.push(`/configure-alert-banner/edit/${record.id}`);
  };

  render() {
    const { bannerList, bannerListLoading } = this.props;

    const sortedBannerList = bannerList.sort(
      (b1, b2) =>
        new Date(b2.createdAt).getTime() - new Date(b1.createdAt).getTime()
    );

    return (
      <div className={`ck-card ${s.alert_module_container}`}>
        <div className={s['row-justify']}>
          <h3>Banner List</h3>
          {!bannerListLoading && (
            <Button type="primary" onClick={this.handleCreate}>
              Create
            </Button>
          )}
        </div>
        {bannerListLoading && <FullPageLoading fontSize={30} />}
        {!bannerListLoading && (
          <Table
            dataSource={sortedBannerList}
            columns={this.columns}
            onRow={(record, rowIndex) => ({
              onClick: () => this.onRowClick(record, rowIndex),
            })}
            className={s['banner-table']}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ configureBanner }) => {
  const { bannerList, bannerListLoading } = configureBanner;
  return {
    bannerList,
    bannerListLoading,
  };
};

const mapDispatchToProps = {
  fetchExistingConfiguration: fetchExistingConfigurationAction,
  fetchConfigurationBasedOnId: fetchConfigurationBasedOnIdAction,
  saveBannerConfiguration: saveBannerConfigurationAction,
  clearConfigurationForm: clearConfigurationFormAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannerTable)
);
