import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  FETCH_BULK_EMAIL_LIST,
  HANDLE_RESTART_CLICK,
  setBulkEmailList,
  setBulkEmailListCount,
  setBulkEmailListLoading,
  setBulkQueueStatus,

  //selectors
  bulkEmailPage,
  bulkEmailList,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';
import { get } from 'lodash';
import { fetchList, createRestartJob } from '../../../api/manageBulkEmailApi';

export default [
  takeLatest(FETCH_BULK_EMAIL_LIST, fetchBulkEmailListSaga),
  takeLatest(HANDLE_RESTART_CLICK, handleRestartClickSaga),
];

function* fetchBulkEmailListSaga() {
  try {
    const page = yield select(bulkEmailPage);
    yield put(setBulkEmailListLoading(true));
    const queryParams = {
      page,
    };
    const { data } = yield call(fetchList, queryParams);
    yield put(setBulkEmailList(get(data, 'result', [])));
    yield put(setBulkEmailListCount(get(data, 'totalCount', 0)));
    yield put(setBulkQueueStatus(get(data, 'queueStatus', {})));
    yield put(setBulkEmailListLoading(false));
  } catch (e) {
    console.log('Error', e);
    yield put(setBulkEmailListLoading(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching list.' },
    });
  }
}

function* handleRestartClickSaga({ data: { id, status, sendVia } }) {
  try {
    const list = yield select(bulkEmailList);
    const sendStatus = ['ready', 'sending', 'failed'];
    let object = 'HUB_MANUAL_MESSAGE';
    if (sendStatus.indexOf(status) > -1) {
      object = 'SEND_MANUAL_MESSAGE';
    }
    if (sendVia && sendVia === 2) {
      object = 'BULK_MESSAGE_V2';
    }
    const body = {
      id,
      relatedObject: object,
    };
    if (sendVia) {
      body.sendVia = sendVia;
    }
    const { data: resData } = yield call(createRestartJob, body);
    const modifiedList = list.map(item => {
      if (item.id === id) {
        return {
          ...item,
          restartObj: resData,
        };
      }
      return item;
    });
    yield put(setBulkEmailList(modifiedList));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Successfully restarted!' },
    });
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error restarting.' },
    });
  }
}
