import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setActionItemActive } from '../../../api';
import { notify } from '../../../utils/notify';
import ActionItemKeys from '../queryKeys/ActionItems';

const publishActionItem = async body => {
  const { data } = await setActionItemActive(body);
  return data;
};

const usePublishActionItemMutation = ({ setSelectActionItem, page }) => {
  const client = useQueryClient();
  return useMutation(publishActionItem, {
    onSuccess: (data, payload) => {
      const { active: isActive, id } = payload;

      client.setQueryData([ActionItemKeys.ActionItems, page], oldData => ({
        ...oldData,
        actions: oldData.actions.map(obj =>
          data.id === obj.id ? { ...obj, active: isActive } : obj
        ),
      }));

      notify(
        `Action Item ${isActive ? 'Published' : 'Unpublished'} successfully!`,
        'success'
      );
      setSelectActionItem(payload);
    },
    onsError: err => {
      console.log('err', err);
      notify(`Error in updating List`, 'error');
    },
  });
};

export default usePublishActionItemMutation;
