import React, {Fragment} from 'react';
import { Table, Button, Modal } from 'antd';
import EditAdminTag from './EditAdminTag';
const { confirm } = Modal;
class AdminTagTable extends React.PureComponent {

	state = {
		showEditAdminTag: false,
		editingAdminTagId: null,
	}

	_editAdminTag = ( adminTagId, editing=true ) => {
		this.setState({showEditAdminTag: true, editingAdminTagId: adminTagId, editing});
	}

	_setEditMode = data => {
		this.setState({showEditAdminTag: data});
	}

	_deleteAdminTag = data => {
		const { deleteAdminTag } = this.props;
		deleteAdminTag(data);
	}

	_renderElements = data => data.map(element => element.objectDetails && element.objectDetails.name).join(', ');

	render() {
		const { adminTags, updateAdminTag, addAdminTagReference, deleteAdminTagReference, clusterHubs } = this.props;
		const [editingAdminTag] = adminTags.filter(at => at.id === this.state.editingAdminTagId)
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				width: 250,
				key: 'name',
			},
			{
				title: 'Description',
				dataIndex: 'description',
				width: 600,
				key: 'description',
			},
			{
				title: 'Type',
				dataIndex: 'type',
				width: 250,
				key: 'type',
			},
			{
				title: 'Elements',
				dataIndex: 'adminTagReferences',
				width: 250,
				key: 'adminTagReferences',
				render: (k, v) => this._renderElements(k)
			},
			{
				title: 'Delete',
				dataIndex: 'deletedAt',
				width: 100,
				key: 'deletedAt',
				render: (k, v) => (
					<Button type="danger" icon="delete" onClick={
						() => {
							confirm({
								title: 'Are you sure you want to delete this admin tag?',
								content: 'THIS ACTION CANNOT BE UNDONE! This will remove the mapping of this admin tag from all sources.',
								onOk: () => {
									this._deleteAdminTag(v)
								},
								onCancel() {},
							});
						}
					} />
				),
			},
			{
				title: 'Edit',
				dataIndex: 'updatedAt',
				width: 100,
				key: 'updatedAt',
				render: (k, v) => (
					<Button type="primary" icon="edit" onClick={() => this._editAdminTag(v.id, true)}/>
				),
			},
		];

		return (
		<Fragment>
			<div>
				<Button onClick={() => this._editAdminTag(null, false)}> + Admin Tag </Button>
			</div>
			<Table
				rowKey={at => `adminTag_${at.id}`}
				dataSource={adminTags}
				columns={columns}
				bordered
				scroll={{ x: 'max-content' }}
			/>
			{this.state.showEditAdminTag ? 
				<EditAdminTag 
					adminTag={editingAdminTag} 
					visible={this.state.showEditAdminTag}
					updateAdminTag={updateAdminTag}
					setEditMode = {this._setEditMode}
					editing = { this.state.editing}
					addAdminTag = {this.props.addAdminTag}
					addAdminTagReference = {addAdminTagReference}
					deleteAdminTagReference = {deleteAdminTagReference}
					clusterHubs={clusterHubs}
				/> 
				: null}
		</Fragment>
		)
	}
};

export default AdminTagTable;