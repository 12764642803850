import { takeLatest } from 'redux-saga/effects';

import {
  LIST_MODULE_TEMPLATES,
  GET_HUBS_PATHWAYS,
  SAVE_NEW_TEMPLATE,
  FIND_ONE_TEMPLATE,
  DELETE_TEMPLATE,
  GET_PROVISIONING_FILTERS,
} from '../actions';

import {
  fetchModuleTemplates,
  getHubsPathwayList,
  addNewTemplateSaga,
  fetchTemplateToEdit,
  deleteTemplateSaga,
  getProvisioningFilters,
} from './sagas';

export default [
  takeLatest(LIST_MODULE_TEMPLATES, fetchModuleTemplates),
  takeLatest(GET_HUBS_PATHWAYS, getHubsPathwayList),
  takeLatest(SAVE_NEW_TEMPLATE, addNewTemplateSaga),
  takeLatest(FIND_ONE_TEMPLATE, fetchTemplateToEdit),
  takeLatest(DELETE_TEMPLATE, deleteTemplateSaga),
  takeLatest(GET_PROVISIONING_FILTERS, getProvisioningFilters),
];
