import { useQuery } from '@tanstack/react-query';
import { hub } from '../../../api';

const queryKey = 'AllHubs';

const getHubsApi = async () => {
  const params = {
    fields: [
      'id',
      'name',
      'identifier',
      'settings',
      'defaultSetting',
      'standardPlatformType',
    ],
  };
  const response = await hub.getAll(params);
  const { data } = response;
  return data;
};

const useGetHubsQuery = () => useQuery([queryKey], () => getHubsApi());

export default useGetHubsQuery;
