import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Divider, PageHeader, Row, Input, Icon } from 'antd';

import { isEmpty, has, get, intersection } from 'lodash';
import Content from '../../component/Content';

import useGetHubsQuery from './query/useGetHubsQuery';
import HubsTable from './components/HubsTable';
import TriggerSelect from './components/TriggerSelect';
import MultiSelect from '../../component/MultiSelect';
import variations from '../../constants/defaultSettingVariationConstants';

import TriggerActionModal from './components/TriggerActionModal';
import useGetAutoTriggerListQuery from './query/useGetAutoTriggerListQuery';
import s from './index.module.scss';
import { getEnabledNotifications } from './util';

const { Search } = Input;

function getTableData(data, filter) {
  if (
    isEmpty(filter?.hubSearchText) &&
    isEmpty(filter.launchGroup) &&
    isEmpty(filter.trigger)
  ) {
    return data;
  }

  const filteredData = (data || [])
    .filter(record => {
      let isRecordFilter = false;
      if (isEmpty(filter.hubSearchText)) {
        return true;
      } else if (/^\d+$/.test(filter.hubSearchText)) {
        isRecordFilter = record.id === +filter.hubSearchText;
      } else if (!isEmpty(filter.hubSearchText)) {
        isRecordFilter = record.name
          .toLowerCase()
          .includes(filter.hubSearchText.toLowerCase());
      }
      return isRecordFilter;
    })
    .filter(record => {
      if (isEmpty(filter.launchGroup)) {
        return true;
      }
      return filter.launchGroup.includes(record.defaultSetting);
    })
    .filter(record => {
      if (isEmpty(filter.trigger)) {
        return true;
      }
      const evendDrivenNotificaitons = getEnabledNotifications(record);
      const commonTriggers = intersection(
        evendDrivenNotificaitons,
        filter.trigger
      );

      return (
        commonTriggers.length > 0 &&
        commonTriggers.length === filter.trigger.length
      );
    });

  return filteredData;
}

const EventDrivenAutoNotification = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [action, setModalAction] = useState('');
  const [filter, setFilter] = useState({});

  const { data: triggers } = useGetAutoTriggerListQuery();

  const handleChange = value => {
    setFilter(state => ({ ...state, trigger: value }));
  };

  const handleChangeInSearch = event => {
    setFilter(state => ({ ...state, hubSearchText: event.target.value }));
  };

  const handleLaunchGroupSelect = launchGroup => {
    setFilter(state => ({ ...state, launchGroup }));
  };

  const { data: allHubs, isLoading, refetch, isRefetching } = useGetHubsQuery();
  const hasSelected = selectedRowKeys.length > 0;

  const onSelectChange = useCallback(keys => {
    setSelectedRowKeys(keys);
  }, []);

  const tableData = !isEmpty(filter) ? getTableData(allHubs, filter) : allHubs;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div>
        <PageHeader
          title="Auto Notification"
          subTitle="Tool to Manage Event Driven Hub Auto Messages"
        >
          <Divider />
          <Row type="flex" justify="center">
            <Col md={8} xl={8} sm={24}>
              <TriggerSelect
                handleChange={handleChange}
                value={filter.trigger}
              />
            </Col>
            <Col md={6} xl={6} sm={24}>
              <Search
                placeholder="Search By Name, Hub ID"
                style={{ width: '95%' }}
                value={filter.hubSearchText}
                onChange={handleChangeInSearch}
              />
            </Col>
            <Col md={6} xl={6}>
              <MultiSelect
                optionFilterProp="children"
                value={filter.launchGroup}
                dataSource={variations}
                onChange={handleLaunchGroupSelect}
              />
            </Col>
            <Col md={4} xl={4}>
              <Divider type="vertical" />
              <Button disabled={isEmpty(filter)} onClick={() => setFilter({})}>
                <Icon type="sync" />
                Clear All
              </Button>
            </Col>
          </Row>
        </PageHeader>
        <Content>
          <Card bordered={false}>
            <Row type="flex" align="middle" className={s.taskTriggerRow}>
              <div style={{ flex: 1 }}>
                {has(triggers, 'activeTasks') ? (
                  <div>
                    <span className={s.taskTriggerLabel}>Running tasks:</span>{' '}
                    {triggers?.activeTasks.toString() || 'None'}{' '}
                  </div>
                ) : null}
              </div>
              <div>
                <Button
                  style={{ marginLeft: '16px', cursor: 'pointer' }}
                  onClick={() => {
                    refetch();
                  }}
                >
                  <Icon type="sync" />
                  Refresh Data
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: '16px', cursor: 'pointer' }}
                  onClick={() => {
                    setModalAction('activate');
                  }}
                  disabled={!hasSelected}
                >
                  Activate
                </Button>
                <Button
                  type="danger"
                  style={{ marginLeft: '16px', cursor: 'pointer' }}
                  onClick={() => {
                    setModalAction('de-activate');
                  }}
                  disabled={!hasSelected}
                >
                  Stop
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ''}
                </span>
              </div>
            </Row>
            <HubsTable
              data={tableData}
              isLoading={isLoading || isRefetching}
              rowSelection={rowSelection}
            />
          </Card>
        </Content>
        <TriggerActionModal
          action={action}
          setModalAction={setModalAction}
          selectedHubs={selectedRowKeys}
          setSeletectedHubs={setSelectedRowKeys}
        />
      </div>
    </div>
  );
};

export default EventDrivenAutoNotification;
