import {
  SET_HUBS,
  SET_CLUSTERS,
  SET_CLUSTER_HUBS,
  SET_CLUSTER_HUB_LOADING,
  CLUSTER_HUBS_LOADING,
  SET_LOGIN_MODAL,
  SET_SELECTED_CLUSTER_HUB,
  SET_CLUSTER_HUB_OPENING,
} from '../actions/clusterHubs';

const initialState = {
  clusters: [],
  hubs: [],
  clusterHubs: [],
  loading: false,
  clusterHubsLoading: false,
  isLoginModalOpen: false,
  selectedClusterHub: null,
  clusterHubOpening: null,
};

const clusterHubs = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_CLUSTERS:
      return {
        ...state,
        clusters: data,
      };
    case SET_HUBS:
      return {
        ...state,
        hubs: data,
      };
    case SET_CLUSTER_HUBS:
      return {
        ...state,
        clusterHubs: data,
      };
    case SET_CLUSTER_HUB_LOADING:
      return {
        ...state,
        loading: data,
      };
    case CLUSTER_HUBS_LOADING:
      return {
        ...state,
        clusterHubsLoading: data,
      };
    case SET_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: data,
      };
    case SET_SELECTED_CLUSTER_HUB:
      return {
        ...state,
        selectedClusterHub: data,
      };
    case SET_CLUSTER_HUB_OPENING:
      return {
        ...state,
        clusterHubOpening: data,
      };
    default:
      return state;
  }
};

export default clusterHubs;
