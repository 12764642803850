import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Input,
  Switch,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';
import { isEmpty, set, get, map, isObject, cloneDeep } from 'lodash';

import PathwaySelectModal from './components/PathwaySelectModal';
import HubSelectionModal from './components/HubSelectionModal';
import HubSelectionWarningModal from './components/HubSelectionWarningModal';
import ModuleSelectModal from './components/ModuleSelectModal';
import FilterSelectModal from './components/FilterSelectModal';

import { setData, saveNewTemplate, deleteTemplate } from './actions';

import './index.scss';

const { TextArea } = Input;

class TemplateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableCheck: false,
      fieldError: undefined,
    };
  }

  componentDidMount() {
    this.getDisableCheckStatus();
  }

  componentDidUpdate(prevProps) {
    const { singleTemplate: oldSingleTemplate = {} } = prevProps;
    const { singleTemplate = {} } = this.props;
    const id = get(singleTemplate, 'id');
    if (id !== get(oldSingleTemplate, 'id')) this.getDisableCheckStatus();
  }

  getDisableCheckStatus() {
    const { templateId, singleTemplate } = this.props;
    if (templateId && !isEmpty(singleTemplate)) {
      const isLaunched =
        get(singleTemplate, 'setting.launch_in.hubs', []).length ||
        get(singleTemplate, 'setting.launch_in.groups', []).length;

      const status =
        !!(
          singleTemplate.id &&
          get(singleTemplate, 'setting.publish', false) &&
          isLaunched
        ) || false;
      this.setState({ disableCheck: status });
      return;
    }
    this.setState({ disableCheck: false });
  }

  renderTextRow = (text, record, index) => (
    <div key={index} className="list__table-box">
      {text}
    </div>
  );

  getColumns = () => [
    {
      title: 'Active Launch Groups',
      dataIndex: 'groups',
      key: 'groups',
      render: this.renderTextRow,
    },
    {
      title: 'Active Hubs',
      dataIndex: 'hubs',
      key: 'hubs',
      render: this.renderTextRow,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <div key={index} className="list__table-box">
          <Button type="link" onClick={this.toggleHubSelectionModal}>
            Edit
          </Button>
        </div>
      ),
    },
  ];

  getFilterColumns = () => [
    {
      title: 'Filters Applied',
      dataIndex: 'filters',
      key: 'filters',
      render: this.renderTextRow,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <div key={index} className="list__table-box">
          <Button type="link" onClick={this.handleShowFilterModal}>
            View all
          </Button>
        </div>
      ),
    },
  ];

  getModuleColumns = () => [
    {
      title: 'Required Modules',
      dataIndex: 'modules',
      key: 'modules',
      render: this.renderTextRow,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <div key={index} className="list__table-box">
          <Button type="link" onClick={this.handleShowModuleModal}>
            View all
          </Button>
        </div>
      ),
    },
  ];

  getColumnData = ({ isLaunched }) => {
    const { singleTemplate } = this.props;
    const launch_in = get(singleTemplate, ['setting', 'launch_in']);
    if (launch_in && isLaunched)
      return [
        {
          groups: get(launch_in, 'groups') && launch_in.groups.length,
          hubs: get(launch_in, 'hubs') && launch_in.hubs.length,
          id: 1,
        },
      ];

    return [];
  };

  getFilterColumnData = () => {
    const { singleTemplate } = this.props;
    const filters = Object.keys(
      get(singleTemplate, ['setting', 'filters'], {})
    );
    if (filters && filters.length)
      return [
        {
          filters: filters.length,
          id: 1,
        },
      ];

    return [];
  };

  getModuleColumnData = () => {
    const { singleTemplate } = this.props;
    const modules = get(singleTemplate, ['setting', 'modules']);
    if (modules && modules.length)
      return [
        {
          modules: modules.length,
          id: 1,
        },
      ];

    return [];
  };

  handleShowModuleModal = () => {
    const { setData } = this.props;
    setData({ showModuleSelectionModal: true });
  };

  handleShowFilterModal = () => {
    const { setData } = this.props;
    setData({ showFilterSelectionModal: true });
  };

  toggleHubSelectionModal = () => {
    const { setData } = this.props;
    setData({ showHubSelectionModal: true });
  };

  toggleModal = () => {
    const { setData } = this.props;
    setData({ showPathwayCopyModal: true });
  };

  handleChange = (type, value) => {
    const { singleTemplate, setData } = this.props;
    const { fieldError } = this.state;
    if (type === 'setting.launch') {
      this.toggleHubSelectionModal();
      return;
    }
    if (type === 'templateName' && fieldError && value) {
      this.setState({ fieldError: undefined });
    }
    const clonedTemplate = cloneDeep(singleTemplate);
    set(clonedTemplate, type, value);
    set(clonedTemplate, 'isDirty', true);
    setData({ singleTemplate: clonedTemplate });
  };

  handleSelectionSave = (name, data) => {
    const { setData, singleTemplate } = this.props;
    const clonedTemplate = cloneDeep(singleTemplate);
    set(clonedTemplate, `setting.${name}`, data);
    set(clonedTemplate, 'isDirty', true);
    // const updates = { singleTemplate: { ...singleTemplate, [type]: value } };
    setData({ singleTemplate: clonedTemplate });
  };

  handleCancel = () => {
    const { setData, history, singleTemplate } = this.props;
    const { isDirty = false } = singleTemplate;
    if (!isDirty) {
      setData({
        showPathwayForm: false,
        pathwayData: undefined,
        singleTemplate: undefined,
        preSelectedHubs: [],
      });
    }
    history.push(`/pathway-provisioning`);
  };

  handleSave = () => {
    const { pathwayData, saveNewTemplate, singleTemplate } = this.props;
    const templateName = get(singleTemplate, 'templateName');
    // validation check
    if (isEmpty(templateName)) {
      this.setState({ fieldError: 'error' });
      return;
    }
    const launch_in = get(singleTemplate, ['setting', 'launch_in'], {});
    const { hubs = [], groups = [] } = launch_in;
    const isLaunched = hubs.length || groups.length;
    const createObj = {
      id: get(singleTemplate, 'id'),
      templateName,
      launch: !!isLaunched,
      modules: get(singleTemplate, 'setting.modules', []),
      filters: get(singleTemplate, 'setting.filters', {}),
      publish: get(singleTemplate, 'setting.publish', false),
      launch_in: {
        groups,
        hubs: map(hubs, h => (isObject(h) ? h.key : h)),
      },
      hubId: get(pathwayData, 'hubId'),
      pathwayId: get(pathwayData, 'pathwayId'),
    };
    saveNewTemplate(createObj);
  };

  handleDelete = templateId => {
    const { deleteTemplate } = this.props;

    if (!templateId) return;

    deleteTemplate({ templateId });
  };

  saveLaunchIn = ({ selectedGroups, selectedHubs }) => {
    const launch_in = {
      groups: selectedGroups,
      hubs: selectedHubs,
    };
    this.handleSelectionSave('launch_in', launch_in);
  };

  renderDeleteBtn = () => {
    const { singleTemplate } = this.props;
    const { disableCheck } = this.state;

    if (disableCheck) {
      return (
        <Tooltip
          title={
            <p>
              This pathway is published on at least one hub. It’s not possible
              to delete published default pathways.
            </p>
          }
        >
          <Button disabled>Delete Template</Button>
        </Tooltip>
      );
    }

    return (
      <Button
        type="danger"
        onClick={() => this.handleDelete(singleTemplate.id)}
      >
        Delete Template
      </Button>
    );
  };

  render() {
    const {
      showModuleSelectionModal,
      showFilterSelectionModal,
      showPathwayForm,
      loading,
      singleTemplate = {},
      showHubSelectionModal,
      showHubSelectionWarningModal,
    } = this.props;
    const { disableCheck, fieldError } = this.state;

    if (!showPathwayForm && !get(loading, 'edit_template', false)) return null;

    const isLaunched =
      get(singleTemplate, 'setting.launch_in.hubs', []).length ||
      get(singleTemplate, 'setting.launch_in.groups', []).length;

    return (
      <>
        <Card
          title="Create Pathway"
          className="form"
          bordered={false}
          loading={get(loading, 'edit_template', false)}
        >
          {!singleTemplate.id ? (
            <Button className="form__copy" onClick={this.toggleModal}>
              Copy from other Pathway
            </Button>
          ) : null}
          <Form>
            <Form.Item
              name="internal_template_name"
              label="Internal Template Name"
              colon={false}
              required
              validateStatus={fieldError}
              help={`${
                (fieldError && 'Please enter internal template name') || ''
              }`}
            >
              <Input
                value={get(singleTemplate, 'templateName')}
                onChange={event =>
                  this.handleChange('templateName', event.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              name="source_pathway_link"
              label="Source Pathway Link"
              colon={false}
            >
              <Input disabled value={get(singleTemplate, 'link')} />
            </Form.Item>
            <Form.Item name="pathway_name" label="Pathway Name" colon={false}>
              <Input value={get(singleTemplate, 'title')} disabled />
            </Form.Item>
            <Form.Item
              name="internal_description"
              label="Internal Pathway Description"
              colon={false}
            >
              <TextArea
                rows={4}
                value={get(singleTemplate, 'description')}
                disabled
              />
            </Form.Item>
          </Form>
          <Card
            title="Publish Pathway"
            className="form__card"
            extra={
              <div className="form__card-extra">
                <span>Published</span>
                <Switch
                  checked={get(singleTemplate, 'setting.publish')}
                  disabled={disableCheck}
                  onChange={event =>
                    this.handleChange('setting.publish', event)
                  }
                />
              </div>
            }
            bordered={false}
          >
            <p>
              Do you want this pathway to be published (and immediately start
              assigning to users) when launched? Or do you want it to be
              unpublished when launched? If you mark the pathway as
              “Unpublished” and then launch it, it won’t be assigned to anyone
              but will be visible to admins in the Pathways admin tool.
            </p>
          </Card>
          <Card
            title="Launch Pathway"
            className="form__card"
            extra={
              <div className="form__card-extra">
                <span>Launched</span>
                <Switch
                  checked={isLaunched}
                  disabled={disableCheck}
                  onChange={event => this.handleChange('setting.launch', event)}
                />
              </div>
            }
            bordered={false}
          >
            <p>
              To launch this default pathway, set it to “Launched” and then
              select the hubs or launch groups to which you’d like to launch it.
              It may take several hours for pathways to appear in selected hubs.
              Note that published default pathways cannot be removed from hubs,
              so if you launch a published pathway, or launch a draft pathway
              that a hub admin later publishes, you will not be able to delete
              that pathway.
            </p>
            <div className="form__card-table">
              <Table
                locale={{ emptyText: 'Not Launched Yet!' }}
                columns={this.getColumns()}
                dataSource={this.getColumnData({ isLaunched })}
                pagination={false}
                rowkey="launch"
              />
            </div>
          </Card>
          <Card
            title="Hub Specific Filters"
            className="form__card"
            extra={
              <div className="form__card-extra">
                <Button onClick={this.handleShowFilterModal}>
                  Set Filters
                </Button>
              </div>
            }
            bordered={false}
          >
            <p>Set Hub Specific Filters for Pathway Assignment</p>
            <p>Filters: Industry Interest, Help Topic Interest</p>
            <div className="form__card-table">
              <Table
                locale={{ emptyText: 'No Hub Specific Filters Created!' }}
                columns={this.getFilterColumns()}
                dataSource={this.getFilterColumnData()}
                pagination={false}
                rowkey="filters"
              />
            </div>
          </Card>
          <Card
            title="Require Module(s)"
            className="form__card"
            extra={
              <div className="form__card-extra">
                <Button onClick={this.handleShowModuleModal}>Select</Button>
              </div>
            }
            bordered={false}
          >
            <p>
              Select any modules that are necessary for the pathway to work. Any
              hubs that don’t have these modules enabled will be automatically
              excluded when you launch the pathway. Note that if you remove or
              add new required modules after launching the default pathway, the
              pathway will remain present on any hubs to which it’s already been
              launched; post-launch updates to required modules will only affect
              future launches.
            </p>
            <div className="form__card-table">
              <Table
                locale={{ emptyText: 'No Module Selected!' }}
                columns={this.getModuleColumns()}
                dataSource={this.getModuleColumnData()}
                pagination={false}
                rowkey="modules"
              />
            </div>
          </Card>
          <Row className="form__footer">
            <Col span={17}>
              {singleTemplate.id ? this.renderDeleteBtn() : null}
            </Col>
            <Col span={7} className="form__footer-right">
              <Button type="ghost" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button type="primary" onClick={this.handleSave}>
                Save
              </Button>
            </Col>
          </Row>
        </Card>
        <PathwaySelectModal />

        {showHubSelectionModal ? (
          <HubSelectionModal
            launch_in={get(singleTemplate, 'setting.launch_in', {})}
            saveLaunchIn={this.saveLaunchIn}
            allowDelete={disableCheck}
          />
        ) : null}
        {showHubSelectionWarningModal ? (
          <HubSelectionWarningModal saveLaunchIn={this.saveLaunchIn} />
        ) : null}

        {showModuleSelectionModal ? (
          <ModuleSelectModal
            modules={get(singleTemplate, 'setting.modules', [])}
            onSave={data => this.handleSelectionSave('modules', data)}
          />
        ) : null}
        {showFilterSelectionModal ? (
          <FilterSelectModal
            filters={get(singleTemplate, 'setting.filters', [])}
            onSave={data => this.handleSelectionSave('filters', data)}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({
  PathwaysProvisioning: {
    templates,
    loading,
    pagination,
    hubsPathways,
    showPathwayForm,
    pathwayData,
    singleTemplate,
    showModuleSelectionModal,
    showFilterSelectionModal,
    showHubSelectionModal,
    showHubSelectionWarningModal,
  },
}) => ({
  templates,
  loading,
  pagination,
  hubsPathways,
  showPathwayForm,
  pathwayData,
  singleTemplate,
  showModuleSelectionModal,
  showFilterSelectionModal,
  showHubSelectionModal,
  showHubSelectionWarningModal,
});

const mapDispatchToProps = {
  setData,
  saveNewTemplate,
  deleteTemplate,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemplateForm)
);
