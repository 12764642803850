import {
  SAVE_MODULE_TEMPLATES,
  PATHWAY_SET_DATA,
  SET_LOADING,
} from '../actions';

const initialState = {
  templates: [],
  singleTemplate: undefined,
  loading: {},
  pagination: {
    current: 1,
    total: undefined,
    pageSize: 10,
  },
  hubList: [],
  showPathwayCopyModal: false,
  showHubSelectionModal: false,
  showModuleSelectionModal: false,
  showFilterSelectionModal: false,
  showHubSelectionWarningModal: false,
  selectedGroups: [],
  selectedHubs: [],
  hubsNoFilter: {},
  hubsPathways: undefined,
  showPathwayForm: false,
  pathwayData: {},
  redirectModalPath: undefined,
  filterData: {},
  preSelectedHubs: [],
};

const defaultPathwayReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SAVE_MODULE_TEMPLATES:
      return {
        ...state,
        templates: data,
      };
    case PATHWAY_SET_DATA:
      return {
        ...state,
        ...data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...data,
        },
      };
    default:
      return state;
  }
};

export default defaultPathwayReducer;
