/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-console */
import { takeLatest, put, call } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { message } from 'antd';
import {
  GET_HUBS_WITH_CIO,
  setAnalyticsProfile,
  SYNC_HUB_USERS,
  REMOVE_HUB_SYNC,
} from '../actions/analyticsProfile';

import {
  getHubsWithCIOApi,
  removeHubUsersDataCustomerIOApi,
} from '../../../api';
import { POPUP_ALERT } from '../../../actions/alert';
import * as api from '../../../api';

function* getHubsWithCioSaga({ data }) {
  try {
    let result;
    yield put(setAnalyticsProfile({ loading: true }));
    if (data && !isEmpty(data.search)) {
      ({ data: result } = yield call(getHubsWithCIOApi, data.search));
    } else {
      ({ data: result } = yield call(getHubsWithCIOApi));
    }
    yield put(setAnalyticsProfile({ hubs: result, loading: false }));
  } catch (e) {
    console.error(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Saving Query' },
    });
  }
}

function* syncHubUsers({ data }) {
  try {
    yield put(setAnalyticsProfile({ loadingForHubId: data.hubId }));
    yield call(api.hub.syncHubUsers, data.hubId);
    yield call(getHubsWithCioSaga, {});
    yield put(setAnalyticsProfile({ loadingForHubId: null }));
    message.success('Users queued for syncing');
  } catch (err) {
    console.error(err);
    message.error('Failed to sync users');
  }
}

function* removeDataFromCustomerIOHub({ data }) {
  try {
    yield put(setAnalyticsProfile({ loadingForHubId: data.hubId }));
    yield call(removeHubUsersDataCustomerIOApi, data);
    yield put(setAnalyticsProfile({ loadingForHubId: null }));
    message.success('Users queued to remove from customer.io');
  } catch (err) {
    console.log('error in removeDataFromCustomerIOHub method', err);
    message.error('Failed to remove hub users');
  }
}

export default [
  takeLatest(GET_HUBS_WITH_CIO, getHubsWithCioSaga),
  takeLatest(SYNC_HUB_USERS, syncHubUsers),
  takeLatest(REMOVE_HUB_SYNC, removeDataFromCustomerIOHub),
];
