export const FETCH_MESSAGE_FEEDBACKS = 'FETCH_MESSAGE_FEEDBACKS';
export const FETCH_MESSAGE_FEEDBACKS_SUCCESS =
  'FETCH_MESSAGE_FEEDBACKS_SUCCESS';
export const MESSAGE_FEEDBACKS_LOADING = 'MESSAGE_FEEDBACKS_LOADING';
export const MESSAGE_FEEDBACKS_SET_PAGE = 'MESSAGE_FEEDBACKS_SET_PAGE';
export const MESSAGE_FEEDBACKS_SET_PAGE_SIZE =
  'MESSAGE_FEEDBACKS_SET_PAGE_SIZE';

export const FETCH_MENTORSHIP_FEEDBACKS = 'FETCH_MENTORSHIP_FEEDBACKS';
export const FETCH_MENTORSHIP_FEEDBACKS_SUCCESS =
  'FETCH_MENTORSHIP_FEEDBACKS_SUCCESS';
export const MENTORSHIP_FEEDBACKS_LOADING = 'MENTORSHIP_FEEDBACKS_LOADING';
export const MENTORSHIP_FEEDBACKS_SET_PAGE = 'MENTORSHIP_FEEDBACKS_SET_PAGE';
export const MENTORSHIP_FEEDBACKS_SET_PAGE_SIZE =
  'MENTORSHIP_FEEDBACKS_SET_PAGE_SIZE';

export const OPEN_HUB_FROM_FEEDBACKS = 'OPEN_HUB_FROM_FEEDBACKS';

export const RESET_FEEDBACKS_STATE = 'RESET_FEEDBACKS_STATE';

export const fetchMessageFeedbacks = data => ({
  type: FETCH_MESSAGE_FEEDBACKS,
  data,
});

export const messsageFeedbacksLoading = data => ({
  type: MESSAGE_FEEDBACKS_LOADING,
  data,
});

export const fetchMessageFeedbacksSuccess = data => ({
  type: FETCH_MESSAGE_FEEDBACKS_SUCCESS,
  data,
});

export const messageFeedbacksSetPage = data => ({
  type: MESSAGE_FEEDBACKS_SET_PAGE,
  data,
});

export const messageFeedbacksSetPageSize = data => ({
  type: MESSAGE_FEEDBACKS_SET_PAGE_SIZE,
  data,
});

export const fetchMentorshipFeedbacks = data => ({
  type: FETCH_MENTORSHIP_FEEDBACKS,
  data,
});

export const mentorshipFeedbacksLoading = data => ({
  type: MENTORSHIP_FEEDBACKS_LOADING,
  data,
});

export const fetchMentorshipFeedbacksSuccess = data => ({
  type: FETCH_MENTORSHIP_FEEDBACKS_SUCCESS,
  data,
});

export const mentorshipFeedbacksSetPage = data => ({
  type: MENTORSHIP_FEEDBACKS_SET_PAGE,
  data,
});

export const mentorshipFeedbacksSetPageSize = data => ({
  type: MENTORSHIP_FEEDBACKS_SET_PAGE_SIZE,
  data,
});

export const openHubFromFeedbacks = data => ({
  type: OPEN_HUB_FROM_FEEDBACKS,
  data,
});

export const resetFeedbacksState = () => ({
  type: RESET_FEEDBACKS_STATE,
});
