import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'antd';
import { isEmpty } from 'lodash';
import EditModal from './editModal';

const ChangeLogModal = ({
  isLoading,
  onClose,
  careerPathChangeLog,
  revertCareerPathChange,
  careerPathWithAssociatedData,
  auxiliaryDataForCareerPaths,
}) => {
  const [changeLogColumns, setChangeLogColumns] = useState([]);
  const [currRecord, setCurrRecord] = useState({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [revertLogId, setRevertLogId] = useState(true);

  const handleRevertClick = id => {
    setRevertLogId(id);
    setIsConfirmModalOpen(true);
  };

  useEffect(() => {
    // calculate columns
    if (!isEmpty(careerPathChangeLog)) {
      const tableColumns = [
        {
          title: 'user',
          dataIndex: 'user',
          key: 'user',
        },

        {
          title: 'updatedAt',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
        },
        {
          title: 'viewChangeLog',
          dataIndex: 'viewChangeLog',
          key: 'viewChangeLog',
          render: (text, record) => (
            <Button
              size="small"
              type="primary"
              onClick={() => setCurrRecord(record)}
            >
              View changes
            </Button>
          ),
        },
        {
          title: 'action',
          dataIndex: 'action',
          key: 'action',
          render: (text, record) => (
            <Button
              size="small"
              type="danger"
              onClick={() => handleRevertClick(record.id)}
              loading={isLoading.revertCareerPathChange === record.id}
            >
              Revert
            </Button>
          ),
        },
      ];

      setChangeLogColumns(tableColumns);
    }
  }, [
    careerPathChangeLog,
    isLoading.revertCareerPathChange,
    revertCareerPathChange,
  ]);

  return (
    <>
      <Modal
        title="Career Path Changelog"
        visible
        closable={!isLoading.revertCareerPathChange}
        maskClosable={!isLoading.revertCareerPathChange}
        onCancel={onClose}
        footer={null}
        width={850}
      >
        <Table
          loading={isLoading.careerPathChangeLog}
          columns={changeLogColumns}
          dataSource={careerPathChangeLog.map(changelog => ({
            ...changelog,
            key: changelog.id,
          }))}
          pagination={false}
        />
      </Modal>
      {!isEmpty(currRecord) && (
        <EditModal
          isEditing={false}
          careerPathWithAssociatedData={careerPathWithAssociatedData}
          auxiliaryDataForCareerPaths={auxiliaryDataForCareerPaths}
          onCancel={() => setCurrRecord({})}
          isLoading={isLoading}
          careerPathChangeLog={currRecord}
          revertCareerPathChange={handleRevertClick}
        />
      )}
      <Modal
        title="Revert changes?"
        visible={isConfirmModalOpen}
        onOk={() => {
          revertCareerPathChange({ changeLogId: revertLogId });
          setIsConfirmModalOpen(false);
        }}
        onCancel={() => setIsConfirmModalOpen(false)}
      >
        <p>
          Are you sure you would like to revert to this version? The current
          version will be lost and the data for this career path will be updated
          across ALL hubs! Please ensure that the data within this version is
          accurate before confirming.
        </p>
      </Modal>
    </>
  );
};

export default ChangeLogModal;
