const prefix = `accessManagement`
export const SET_ACCESS_MANAGEMENT_OBJ = `${prefix}/SET_ACCESS_MANAGEMENT_OBJ`;
export const INITIALISE_PAGE = `${prefix}/INITIALISE_PAGE`;
export const FETCH_SUPERADMINS = `${prefix}/FETCH_SUPERADMINS`;
export const FETCH_GROUPS = `${prefix}/FETCH_GROUPS`;
export const ADD_NEW_GROUPS = `${prefix}/ADD_NEW_GROUPS`;
export const SAVE_GROUP = `${prefix}/SAVE_GROUP`;
export const SAVE_PERMISSION = `${prefix}/SAVE_PERMISSION`;
export const DELETE_GROUP = `${prefix}/DELETE_GROUP`;

export const setAccessManagementObj = (data) => ({
	type: SET_ACCESS_MANAGEMENT_OBJ,
	data
});

export const initialisePage = (data) => ({
	type: INITIALISE_PAGE,
	data
});

export const fetchSuperadmins = (data) => ({
	type: FETCH_SUPERADMINS,
	data
});

export const fetchGroups = (data) => ({
	type: FETCH_GROUPS,
	data
});

export const addNewGroups = (data) => ({
	type: ADD_NEW_GROUPS,
	data
});

export const saveGroups = (data) => ({
	type: SAVE_GROUP,
	data
});

export const deleteGroup = (data) => ({
	type: DELETE_GROUP,
	data
});

export const savePermissions = (data) => ({
	type: SAVE_PERMISSION,
	data
});

export const accessManagementState = s => s.accessManagement