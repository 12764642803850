/* eslint-disable react/self-closing-comp */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Alert,
  Radio,
  Switch,
  Modal,
  Spin,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useAsyncFetch from '../../../../hooks/useAsyncFetch';
import { fetchCmsContentApi } from '../../../../api/cmsToolApi';
import { notify } from '../../../../utils/notify';
import {
  saveTipApi,
  updateTipApi,
  fetchTemplatesApi,
  fetchArchetypes,
} from '../../../../api/templatesAndTips';
import { fetchTips, setFormData } from '../../actions';
import { sanitize } from '../../../../utils/dompurify';
import { makeUpdatedValuesFromFormValues } from '../../utils';

const { Option } = Select;

const CreateTip = ({
  form,
  connectionSources,
  preConnectionTemplates,
  topActionWrapperRef,
  threadStages,
  templateModelNameKeywordData,
  onDelete,
  setActiveTab,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [error, setError] = useState(null);
  const [selectedContent, setSelectedContent] = useState({});
  const formData = useSelector(
    ({ templatesAndTips }) => templatesAndTips.formData
  );
  const [currentStage, setCurrentStage] = useState(formData.stage);

  useEffect(() => {
    setCurrentStage(formData.stage);
  }, [formData.stage]);

  const { response: cmsContent, isLoading: cmsContentLoading } = useAsyncFetch(
    true,
    fetchCmsContentApi,
    {
      contentType: 'INBOX_TIP',
      fetchAll: true,
    },
    undefined,
    {
      onError: () => notify(`Couldn't fetch CMS Content!`, 'error'),
    }
  );

  const setPreviewContent = useCallback(
    identifier => {
      if (cmsContent?.data?.contents) {
        const data =
          cmsContent.data.contents.find(c => c.identifier === identifier) || {};
        setSelectedContent(data);
      }
    },
    [cmsContent?.data?.contents]
  );

  useEffect(() => {
    if (formData.id) {
      form.resetFields();
      setPreviewContent(formData.cmsContent);
    } else {
      setSelectedContent({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, setPreviewContent]);

  const { refetch: reSaveTipApi, isLoading: isSavingTip } = useAsyncFetch(
    false,
    saveTipApi,
    undefined,
    {
      onError: e => {
        notify(e.response.data.message, 'error');
      },
      onSuccess: () => {
        topActionWrapperRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        dispatch(fetchTips());
        form.resetFields();

        setSelectedContent({});
        notify('Tip created successfully!', 'success');
      },
    }
  );

  const { refetch: reUpdateTipApi, isLoading: isUpdatingTip } = useAsyncFetch(
    false,
    updateTipApi,
    undefined,
    {
      onError: e => {
        notify(e.response.data.message, 'error');
      },
      onSuccess: () => {
        topActionWrapperRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        dispatch(fetchTips());
        notify('Tip updated successfully!', 'success');
      },
    }
  );

  const {
    response: postConnectionTemplatesData,
    isLoading: isFetchingPostConnectionTemplates,
  } = useAsyncFetch(true, fetchTemplatesApi, { fetchAll: true }, undefined, {
    onError: () => notify(`Couldn't fetch post connection templates!`, 'error'),
  });

  const { response: archetypeData, isLoading: archetypeLoading } =
    useAsyncFetch(true, fetchArchetypes, undefined, {
      onError: () => notify(`Couldn't fetch archetypes!`, 'error'),
    });

  const onFinish = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const {
          preceedingMessageKeywords,
          connectionSource,
          preConnectionTemplate,
          postConnectionTemplate,
          stage,
          active = false,
          activeDays,
        } = values;

        let finalActiveDays;

        if (!activeDays) {
          finalActiveDays = 0;
        } else if (activeDays < 0) {
          return setError(
            'Active days cannot be a negative number, please select a positive integer'
          );
        } else {
          finalActiveDays = activeDays;
        }

        if (
          !connectionSource &&
          !preConnectionTemplate &&
          !postConnectionTemplate &&
          !stage &&
          isEmpty(preceedingMessageKeywords)
        ) {
          return setError(
            'Please enter either connection source, preconnection template, postconnection template, stage or preceeding message template'
          );
        }

        setError(null);

        if (formData.id) {
          const updatedValues = makeUpdatedValuesFromFormValues(values);
          reUpdateTipApi({
            id: formData.id,
            ...updatedValues,
            activeDays: finalActiveDays,
            active,
          });
          return dispatch(
            setFormData({
              id: formData.id,
              ...updatedValues,
              activeDays: finalActiveDays,
              active,
            })
          );
        }

        reSaveTipApi({
          id: formData.id,
          ...values,
          activeDays: finalActiveDays,
          active,
        });
        setActiveTab('tip');
      }
      return null;
    });
  };

  const getSelectedArchetypes = (archetype = []) => {
    const arr = [];
    (archetypeData.data?.archetype?.options || []).forEach(
      ({ children = [] }) =>
        children.forEach(({ optionId, optionTitle }) => {
          if (archetype.includes(optionId)) arr.push(optionTitle);
        })
    );
    return arr.join(', ');
  };

  const makeFinalConditionsPreview = () => {
    const {
      showFor,
      archetype,
      preceedingMessageKeywords,
      connectionSource,
      preConnectionTemplate,
      postConnectionTemplate,
      stage,
      activeDays,
    } = form.getFieldsValue();

    return (
      <>
        <h3>Selected Conditions:</h3>
        <ul>
          {showFor && (
            <li>
              <strong>Show for:</strong> {showFor}
            </li>
          )}
          {Array.isArray(archetype) && !isEmpty(archetype) && (
            <li>
              <strong>Archetype:</strong> {getSelectedArchetypes(archetype)}
            </li>
          )}
          {preceedingMessageKeywords && (
            <li>
              <strong>Preceeding message keywords:</strong>{' '}
              {preceedingMessageKeywords}
            </li>
          )}
          {connectionSource && (
            <li>
              <strong>Connection Source:</strong> {connectionSource}
            </li>
          )}
          {postConnectionTemplate && (
            <li>
              <strong>Post connection template:</strong>{' '}
              {
                (
                  (postConnectionTemplatesData.data?.inboxTemplates || []).find(
                    t => t.id === postConnectionTemplate
                  ) || {}
                ).name
              }
            </li>
          )}
          {preConnectionTemplate && (
            <li>
              <strong>Pre connection template:</strong>{' '}
              {
                (
                  (preConnectionTemplates.data || []).find(
                    t => t.identifier === preConnectionTemplate
                  ) || {}
                ).title
              }
            </li>
          )}
          {stage && (
            <li>
              <strong>Stage in thread:</strong> {stage}
            </li>
          )}
          {activeDays && Number(activeDays) ? (
            <li>
              <strong>Tips to remain active for:</strong> {activeDays}{' '}
              <span>day(s)</span>
            </li>
          ) : null}
        </ul>
      </>
    );
  };

  const getKeywords = () => {
    const data =
      (templateModelNameKeywordData.data || []).find(
        el => el.identifier === form.getFieldValue('preceedingMessageKeywords')
      ) || {};
    return (data.keywords || []).join(', ');
  };

  return (
    <div
      ref={el => {
        containerRef.current = el;
      }}
    >
      <Card>
        <h3>{formData.id ? 'Update' : 'Create'} Tip Form</h3>
        <Form onSubmit={onFinish}>
          <Form.Item label="Internal Tip Name">
            {form.getFieldDecorator('internalTipName', {
              rules: [{ required: true, message: 'Please input tip name!' }],
              initialValue: formData.internalTipName,
            })(<Input />)}
          </Form.Item>
          <Spin spinning={cmsContentLoading}>
            <Form.Item label="Select tip">
              {form.getFieldDecorator('cmsContent', {
                rules: [{ required: true, message: 'Please select a tip!' }],
                initialValue: formData.cmsContent,
              })(
                <Select
                  allowClear
                  getPopupContainer={() => containerRef.current}
                  onChange={v => setPreviewContent(v)}
                  loading={cmsContentLoading}
                  disabled={cmsContentLoading || Boolean(formData.id)}
                >
                  {(cmsContent.data?.contents || []).map(content => (
                    <Option key={content.id} value={content.identifier}>
                      {content.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {selectedContent?.content && (
              <Alert
                style={{ marginBottom: 24 }}
                message={
                  <div>
                    <p style={{ marginBottom: 3 }}>
                      <strong>Content preview: </strong>
                      <Link to={`/cms-tool?contentId=${selectedContent.id}`}>
                        Edit content
                      </Link>
                    </p>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitize(selectedContent?.content || ''),
                      }}
                    ></div>
                  </div>
                }
                type="info"
              />
            )}
          </Spin>
          <Form.Item label="Position">
            {form.getFieldDecorator('position', {
              rules: [{ required: true, message: 'Please select a position!' }],
              initialValue: formData.position || '',
            })(
              <Radio.Group>
                <Radio value="TEMPLATE">With Templates</Radio>
                <Radio value="THREAD">In thread</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="Select audience">
            {form.getFieldDecorator('showFor', {
              rules: [
                {
                  required: !form.getFieldValue('isGeneric'),
                  message: 'Please select audience!',
                },
              ],
              initialValue: formData.showFor,
            })(
              <Select allowClear getPopupContainer={() => containerRef.current}>
                <Option value="mentee">Mentee</Option>
                <Option value="mentor">Mentor</Option>
                <Option value="all">All</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Select archetype">
            {form.getFieldDecorator('archetype', {
              initialValue: formData.archetype,
            })(
              <Select
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                allowClear
                loading={archetypeLoading}
                disabled={archetypeLoading}
              >
                {(archetypeData.data?.archetype?.options || []).reduce(
                  (options, option) => {
                    option.children.forEach(({ optionId, optionTitle }) => {
                      options.push(
                        <Option key={optionId} value={optionId}>
                          {optionTitle}
                        </Option>
                      );
                    });

                    return options;
                  },
                  []
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Keywords in preceding message"
            style={{ marginBottom: 5 }}
          >
            {form.getFieldDecorator('preceedingMessageKeywords', {
              initialValue: formData.preceedingMessageKeywords,
            })(
              <Select
                allowClear
                getPopupContainer={() => containerRef.current}
                loading={threadStages.loading}
                disabled={threadStages.loading}
              >
                {templateModelNameKeywordData.data.map(({ identifier }) => (
                  <Option key={identifier} value={identifier}>
                    {identifier}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {form.getFieldValue('preceedingMessageKeywords') && (
            <Alert
              style={{ marginBottom: 14, padding: 5 }}
              type="info"
              description={
                <p style={{ margin: 0 }}>
                  <strong>Keywords:</strong> {getKeywords()}
                </p>
              }
            />
          )}
          <Form.Item label="Connection source">
            {form.getFieldDecorator('connectionSource', {
              initialValue: formData.connectionSource,
            })(
              <Select
                allowClear
                getPopupContainer={() => containerRef.current}
                loading={connectionSources.loading}
                disabled={threadStages.loading}
              >
                {connectionSources.data.map((source, i) => (
                  <Option key={source} value={source}>
                    {source}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Pre connection template">
            {form.getFieldDecorator('preConnectionTemplate', {
              initialValue: formData.preConnectionTemplate,
            })(
              <Select
                allowClear
                getPopupContainer={() => containerRef.current}
                loading={preConnectionTemplates.loading}
                disabled={preConnectionTemplates.loading}
              >
                {(preConnectionTemplates.data || []).map(
                  ({ identifier, title }) => (
                    <Option key={identifier} value={identifier}>
                      {title}
                    </Option>
                  )
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Post connection template">
            {form.getFieldDecorator('postConnectionTemplate', {
              initialValue: formData.postConnectionTemplate,
            })(
              <Select
                allowClear
                showSearch
                getPopupContainer={() => containerRef.current}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={isFetchingPostConnectionTemplates}
              >
                {(postConnectionTemplatesData.data?.inboxTemplates || []).map(
                  ({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  )
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Stage in thread">
            {form.getFieldDecorator('stage', {
              initialValue: formData.stage,
            })(
              <Select
                allowClear
                getPopupContainer={() => containerRef.current}
                loading={threadStages.loading}
                disabled={threadStages.loading}
                onChange={stage => {
                  setCurrentStage(stage);
                }}
              >
                {threadStages.data.map(({ id, title }) => (
                  <Option key={id} value={id}>
                    {title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {currentStage === 'INITIAL_REPLY' && (
            <Form.Item label="Delay (in hrs)">
              {form.getFieldDecorator('delayHrs', {
                initialValue: formData.delayHrs || 0,
              })(<Input type="number" placeholder="Select in hrs" />)}
            </Form.Item>
          )}
          <Form.Item label="Remain active for">
            {form.getFieldDecorator('activeDays', {
              initialValue: formData.activeDays,
            })(<Input type="number" placeholder="Select in days" />)}
          </Form.Item>
          <Form.Item label="Active">
            {form.getFieldDecorator('active', {
              initialValue: formData.active,
              valuePropName: 'checked',
            })(<Switch />)}
          </Form.Item>
          <Alert
            style={{ marginBottom: 24 }}
            type="info"
            message={makeFinalConditionsPreview()}
          />
          {error && (
            <Alert
              style={{ marginBottom: 15 }}
              description={error}
              type="error"
            />
          )}
          <Form.Item className="templates-and-tips-form-footer">
            {formData.id ? (
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete this Tip?',
                    okText: 'Delete',
                    okButtonProps: {
                      type: 'danger',
                    },
                    onOk: () => onDelete('inboxTips', formData.id),
                  });
                }}
                type="danger"
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <Button
              loading={isSavingTip || isUpdatingTip}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Form.create({ name: ' create inbox tip' })(CreateTip);
