import React, { PureComponent } from 'react';
import { Form, Input, Icon, Button } from 'antd';
import './dynamicForm.css';

let newId = 0;

class DynamicFieldSet extends PureComponent {
  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    if (keys.length === 0) return;

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    ++newId;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat({
      newId: `new${newId}`,
      name: '',
      isNew: true,
    });
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { handleUpdate } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys } = values;
        // console.log('Received values of form: ', values);
        handleUpdate(values);
      }
    });
  };

  render() {
    const { data, updating } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 18, offset: 6 },
      },
    };
    const centeredButton = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 14, offset: 10 },
      },
    };
    getFieldDecorator('keys', { initialValue: data });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item
        {...(keys.length > 0 && !k.isNew
          ? formItemLayout
          : formItemLayoutWithOutLabel)}
        label={keys.length > 0 ? `${k.id ? k.id : ''}` : ''}
        required={false}
        key={index}
      >
        {getFieldDecorator(`${k.id ? k.id : k.newId}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Please input degree type or delete this field.',
            },
          ],
          initialValue: `${k.name}`,
        })(
          <Input
            placeholder="Degree Type"
            style={{ width: '70%', marginRight: 8 }}
          />
        )}
        {keys.length > 0 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => this.remove(k)}
          />
        ) : null}
      </Form.Item>
    ));
    return (
      <Form onSubmit={this.handleSubmit}>
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="dashed" onClick={this.add} style={{ width: '70%' }}>
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>
        <Form.Item {...centeredButton}>
          <Button type="primary" htmlType="submit" loading={updating}>
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const DynamicForm = Form.create()(DynamicFieldSet);

export default DynamicForm;
