export const HUB_ADDON_MODULES = [
  'events',
  'advising',
  'advisingAnalytics',
  'programs',
  'surveys',
  'autoMessages',
];

export const PLUS_OVERRIDE_MODULES = [
  'advising',
  'advisingAnalytics',
  'surveys',
  'resources',
];

export const PLUS_HUB_DEFAULT_ENABLE_PATHS = [
  'hub.settings.moduleSettings.advising.enabled',
  'hub.settings.moduleSettings.advisingAnalytics.enabled',
  'hub.settings.moduleSettings.surveys.enabled',
  'hub.settings.moduleSettings.resources.enabled',
  'hub.settings.moduleSettings.resources.directoryV2',
  'hub.settings.moduleSettings.resources.enableBusinessDirectory',
];

export const SUB_SETTINGS_KEYS = ['directoryV2', 'enableBusinessDirectory'];

export const CLUSTER_ADDON_MODULES = [];
