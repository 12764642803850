export const SET_HUB_BANNER_TITLE = 'SET_HUB_BANNER_TITLE';
export const SET_HUB_BANNER_ACTIVE = 'SET_HUB_BANNER_ACTIVE';
export const SET_HUB_BANNER_DESCRIPTION = 'SET_HUB_BANNER_DESCRIPTION';
export const SET_HUB_BANNER_CTA_TEXT = 'SET_HUB_BANNER_CTA_TEXT';
export const SET_HUB_BANNER_IMAGE = 'SET_HUB_BANNER_IMAGE';
export const SET_HUB_BANNER_START_DATE = 'SET_HUB_BANNER_START_DATE';
export const SET_HUB_BANNER_END_DATE = 'SET_HUB_BANNER_END_DATE';
export const SET_SELECTED_HUB_ID = 'SET_SELECTED_HUB_ID';
export const SET_SELECTED_LAUNCH_GROUP = 'SET_SELECTED_LAUNCH_GROUP';
export const SAVE_BANNER_CONFIGURATION = 'SAVE_BANNER_CONFIGURATION';
export const FETCH_EXISTING_BANNER_CONFIGURATION = 'FETCH_EXISTING_BANNER_CONFIGURATION';
export const SET_BANNER_LIST = 'SET_BANNER_LIST';
export const SET_BANNER_LIST_LOADING = 'SET_BANNER_LIST_LOADING';
export const SET_BANNER_FORM_LOADING = 'SET_BANNER_FORM_LOADING';
export const FETCH_CONFIGURATION_BASED_ON_ID = 'FETCH_CONFIGURATION_BASED_ON_ID';
export const CLEAR_CONFIGURATION_FORM = 'CLEAR_CONFIGURATION_FORM';
export const DELETE_BANNER_CONFIGURATION = 'DELETE_BANNER_CONFIGURATION';
export const GET_IFRAME_CODE_FROM_VIDEO_URL = 'GET_IFRAME_CODE_FROM_VIDEO_URL';
export const SET_IFRAME_CODE_LOADING = 'SET_IFRAME_CODE_LOADING';
export const SET_HUB_BANNER_LINK_OPTION = 'SET_HUB_BANNER_LINK_OPTION';
export const SET_HUB_BANNER_CTA_LINK = 'SET_HUB_BANNER_CTA_LINK';
export const SET_HUB_BANNER_COVER_MEDIA_TYPE = 'SET_HUB_BANNER_COVER_MEDIA_TYPE';
export const SET_HUB_BANNER_VIDEO = 'SET_HUB_BANNER_VIDEO';
export const SET_ALL_CUSTOM_IMAGES = 'SET_ALL_CUSTOM_IMAGES';
export const SET_LOADING_ALL_CUSTOM_IMAGES = 'SET_LOADING_ALL_CUSTOM_IMAGES';
export const GET_ALL_CUSTOM_IMAGES = 'GET_ALL_CUSTOM_IMAGES';

export const setHubBannerTitle = data => ({
  type: 'SET_HUB_BANNER_TITLE',
  data,
});
export const setHubBannerActive = data => ({
  type: 'SET_HUB_BANNER_ACTIVE',
  data,
});
export const setHubBannerCtaText = data => ({
  type: 'SET_HUB_BANNER_CTA_TEXT',
  data,
});
export const setHubBannerDescription = data => ({
  type: 'SET_HUB_BANNER_DESCRIPTION',
  data,
});
export const setHubBannerStartDate = data => ({
  type: 'SET_HUB_BANNER_START_DATE',
  data,
});
export const setHubBannerEndDate = data => ({
  type: 'SET_HUB_BANNER_END_DATE',
  data,
});
export const setHubBannerImage = data => ({
  type: 'SET_HUB_BANNER_IMAGE',
  data,
});
export const setSelectedHubId = data => ({
  type: 'SET_SELECTED_HUB_ID',
  data,
});
export const setSelectedLaunchGroups = data => ({
  type: 'SET_SELECTED_LAUNCH_GROUP',
  data,
});
export const saveBannerConfiguration = data => ({
  type: 'SAVE_BANNER_CONFIGURATION',
  data,
});
export const fetchExistingConfiguration = () => ({
  type: 'FETCH_EXISTING_BANNER_CONFIGURATION'
});
export const fetchConfigurationBasedOnId = id => ({
  type: 'FETCH_CONFIGURATION_BASED_ON_ID',
  id
});

export const clearConfigurationForm = () => ({
  type: CLEAR_CONFIGURATION_FORM,
});

export const deleteBannerConfiguration = id => ({
  type: 'DELETE_BANNER_CONFIGURATION',
  id,
});

export const getIframeCodeFromVideoURL = data => ({
  type: GET_IFRAME_CODE_FROM_VIDEO_URL,
  data,
});

export const setIframeCodeLoading = data => ({
  type: SET_IFRAME_CODE_LOADING,
  data,
});

export const setHubBannerLinkOption = data => ({
  type: 'SET_HUB_BANNER_LINK_OPTION',
  data
});
export const setHubBannerCtaLink = data => ({
  type: 'SET_HUB_BANNER_CTA_LINK',
  data
})

export const setHubBannerCoverMediaType = data => ({
  type: 'SET_HUB_BANNER_COVER_MEDIA_TYPE',
  data
})

export const setHubBannerVideo = data => ({
  type: 'SET_HUB_BANNER_VIDEO',
  data
})

export const getAllCustomImages = data => ({
  type: GET_ALL_CUSTOM_IMAGES,
  data,
})

export const setLoadingAllCustomImages = data => ({
  type: SET_LOADING_ALL_CUSTOM_IMAGES,
  data,
})

export const bannerList = ({ configureBanner }) => configureBanner.bannerList;
