import { SET_USER_DETAIL, FETCH_USER_DETAIL, UPDATE_USER_ACTION_STATUS } from './actions';

const initialState = {};

const userDetail = (state = initialState, { type, data }) => {
  switch (type) {
    case UPDATE_USER_ACTION_STATUS:
      return {
        ...state,
        userActionStatus: data.status
      }
    case FETCH_USER_DETAIL:
      return {
        ...state,
        userFetchstatus: 'process'
      }
    case SET_USER_DETAIL:
      return {
        ...state,
        ...data,
        userFetchstatus: 'success'
      }
    default: return state
  }
};

export default userDetail;