import React, { PureComponent } from 'react';
import { Button, Card, Table, Tooltip, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import Content from '../../../component/Content';

import {
  handleFetchTemplates,
  handleOpenEmailEditor,
  setPreviewTemplate,
  handleDeleteTemplate,
} from '../actions/';
import DeleteTemplateModal from './DeleteTemplateModal';
import { get } from 'lodash';

class Templates extends PureComponent {
  state = { deleteModalData: null,redirectPath: undefined };

  componentDidMount() {
    const { handleFetchTemplates,match, isCampaign } = this.props;
    if(isCampaign) {
      const url = get(match,'url');
      this.setState({redirectPath : url});
    }
    handleFetchTemplates();
  }

  handleUseOnClick = id => {
    const { handleOpenEmailEditor } = this.props;
    const { redirectPath } = this.state;
    handleOpenEmailEditor({ id, type: 'template',redirectPath });
  };

  handleEditOnClick = id => {
    const { handleOpenEmailEditor } = this.props;
    const { redirectPath } = this.state;
    handleOpenEmailEditor({ id, type: 'template', edit: true,redirectPath });
  };

  setDeleteModalData = data => {
    this.setState({ deleteModalData: data });
  };

  handleTemplateDelete = record => {
    const { handleDeleteTemplate } = this.props;

    handleDeleteTemplate(record);
    this.setDeleteModalData(null);
  };

  render() {
    const {
      templatesLoading,
      templates,
      setPreviewTemplate,
      loadingTemplate,
      templateCategories,
      isCampaign,
    } = this.props;
    const { deleteModalData } = this.state;

    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        sorter: (a, b) => a.title.localeCompare(b.title),
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Folder',
        dataIndex: 'category',
        sorter: (a, b) => a.category.localeCompare(b.category),
        filters: (templateCategories || []).map(c => ({
          text: c.category,
          value: c.category,
        })),
        onFilter: (value, record) => record.category === value,
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('MMM D, YYYY'),
        sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        className: 'am__table__actions',
        render: (value, record) => (
          <>
            <Button
              size="small"
              onClick={() => this.handleUseOnClick(record.id)}
              disabled={loadingTemplate === record.id}
              type="primary"
              ghost
            >
              Use
            </Button>
            <Tooltip title="Preview">
              <Button
                size="small"
                onClick={() =>
                  setPreviewTemplate({
                    title: record.title,
                    html: record.messageHtml,
                  })
                }
                disabled={loadingTemplate === record.id}
              >
                <Icon type="eye" />
              </Button>
            </Tooltip>
            {![1, 2].includes(record.id) && !isCampaign && (
              <>
                <Tooltip title="Edit">
                  <Button
                    size="small"
                    onClick={() => this.handleEditOnClick(record.id)}
                    disabled={loadingTemplate === record.id}
                  >
                    <Icon type="form" />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => this.setDeleteModalData(record)}
                    type="danger"
                    ghost
                    disabled={loadingTemplate === record.id}
                  >
                    <Icon type="delete" />
                  </Button>
                </Tooltip>
              </>
            )}
            {loadingTemplate === record.id && <Spin size="small" />}
          </>
        ),
      },
    ];

    return (
      <Content>
        <Card
          title="Template Library"
          extra={
            <Button type="primary" onClick={() => this.handleUseOnClick(1)}>
              Compose Blank Email
            </Button>
          }
        >
          <p>
            Click the Use button next to any template to send an email with that
            template.
          </p>
          <Table
            columns={columns}
            dataSource={templates}
            rowKey="id"
            className="am__table"
            loading={templatesLoading}
          />
        </Card>
        <DeleteTemplateModal
          data={deleteModalData}
          onCancel={() => this.setDeleteModalData(null)}
          onOk={this.handleTemplateDelete}
        />
      </Content>
    );
  }
}

const mapStateToProps = ({
  automatedMarketing: {
    templatesLoading,
    templates,
    loadingTemplate,
    templateCategories,
  },
}) => ({
  templatesLoading,
  templates,
  loadingTemplate,
  templateCategories,
});

const mapDispatchToProps = {
  handleFetchTemplates,
  handleOpenEmailEditor,
  setPreviewTemplate,
  handleDeleteTemplate,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Templates));
