import {
  SET_IMAGE_QUALITY,
  SET_MAX_IMAGE_WIDTH,
  SET_FILE_PATH,
  RESET_FILE_UPLOAD_STATE,
} from '../actions';

const initialState = {
  imageSettings: {
    imageQuality: 85,
    maxImageWidth: 1200,
  },
  filePath: null,
};

const fileUploadReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_IMAGE_QUALITY:
      return {
        ...state,
        imageSettings: {
          ...state.imageSettings,
          imageQuality: data,
        },
      };

    case SET_MAX_IMAGE_WIDTH:
      return {
        ...state,
        imageSettings: {
          ...state.imageSettings,
          maxImageWidth: data,
        },
      };

    case SET_FILE_PATH:
      return {
        ...state,
        filePath: data,
      };

    case RESET_FILE_UPLOAD_STATE:
      return initialState;

    default:
      return state;
  }
};

export default fileUploadReducer;
