import React from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FullPageLoading from '../../component/FullPageLoading';
import { verifyAdminRedirectToken } from '../../actions/auth';

class AuthCallback extends React.Component {
  componentDidMount = () => {
    const { location, handleAdminRedirectToken } = this.props;
    const { adminRedirectToken, error } = queryString.parse(location.search);

    if (adminRedirectToken) {
      handleAdminRedirectToken(adminRedirectToken);
    } else if (error) {
      window.opener.location.href = '/403';
      window.close();
    }
  };

  render() {
    return <FullPageLoading />;
  }
}

const mapDispatchToProps = {
  handleAdminRedirectToken: verifyAdminRedirectToken,
};

export default withRouter(connect(null, mapDispatchToProps)(AuthCallback));
