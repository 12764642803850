export const FETCH_ALL_CLUSTERS = 'clusters/FETCH_ALL_CLUSTERS';
export const SET_CLUSTER_MGMT = 'clusters/SET_CLUSTER_MGMT';
export const SAVE_CLUSTER_CONFIG = 'clusters/SAVE_CLUSTER_CONFIG';
export const FETCH_ALL_CLUSTER_CONFIG = 'clusters/FETCH_ALL_CLUSTER_CONFIG';
export const DELETE_CLUSTER_CONFIG = 'clusters/DELETE_CLUSTER_CONFIG';
export const CREATE_CLUSTER  =  'clusters/CREATE_CLUSTER';
export const SAVE_CLUSTER = 'clusters/SAVE_CLUSTER';
export const EDIT_CLUSTER =  'clusters/EDIT_CLUSTER';
export const CANCEL_EDIT =  'clusters/CANCEL_EDIT';
export const FILTER_CLUSTERS = 'clusters/FILTER_CLUSTERS';
export const FETCH_LAUNCHGROUPS = 'launchgroup/FETCH_LAUNCHGROUPS';
export const SET_LAUNCHGROUPS = 'launchgroup/SET_LAUNCHGROUPS';

export const fetchAllClusters = () => ({ type: FETCH_ALL_CLUSTERS });
export const deleteClusterConfig = clusterConfigId => ({
  type: DELETE_CLUSTER_CONFIG,
  clusterConfigId,
});
export const setClusterManagement = data => ({ type: SET_CLUSTER_MGMT, data });
export const saveClusterConfig  = data => ({ type: SAVE_CLUSTER_CONFIG, data })
export const fetchAllClusterConfig = data => ({ type: FETCH_ALL_CLUSTER_CONFIG, data });
export const createCluster = data => ({ type: CREATE_CLUSTER, data })
export const saveCluster = cluster => ({ type: SAVE_CLUSTER, cluster });
export const editCluster = clusterId => ({ type: EDIT_CLUSTER, clusterId });
export const cancelEdit = () => ({ type: SET_CLUSTER_MGMT, data: { editCluster: null } });
export const updateEditCluster = editCluster => ({ type: SET_CLUSTER_MGMT, data: { editCluster } });
export const filterClusters = data => ({ type: FILTER_CLUSTERS, data });
