import React from 'react';
import { Select } from 'antd';
import { find } from 'lodash';

const { Option } = Select;

const filterHubs = (props) => (input, option) => {
  const { listOptions = [] } = props;
  let opt = find(listOptions, { id: Number(option.key) });
  if (
    opt &&
    (opt.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
      opt.id.toString().indexOf(input.toLowerCase()) > -1)
  ) {
    return true;
  }
  return false;
};

const renderHubListOptions = (props) => {
  const { listOptions = [] } = props;
  if (Array.isArray(listOptions)) {
    return listOptions.map(hub => {
      return (
        <Option key={hub.id} value={hub.id}>
          {hub.id} - {hub.name}
        </Option>
      );
    });
  }
  return [];
};

const ListSelector = (props) => {
  const { onUpdate, type, value, disabled } = props;
  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder={`Select ${type}`}
      filterOption={filterHubs(props)}
      onChange={onUpdate}
      value={value}
      disabled={disabled}
    >
      {renderHubListOptions(props)}
    </Select>
  )
}

export default ListSelector;