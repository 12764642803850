import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Col,
  PageHeader,
  Row,
  Select,
  Spin,
  Collapse,
  Empty,
} from 'antd';
import { get, isEmpty, isNil } from 'lodash';
import ReactJson from 'react-json-view';
import { breadCrumbItemRender } from '../../../../utils/helpers';

import ProgressBar from '../../../../component/ProgressBar';

import {
  clearBaseConfigState as clearBaseConfigStateAction,
  fetchBaseConfig as fetchBaseConfigAction,
  saveBaseConfig as saveBaseConfigAction,
  handleActionSetSelectedHubId as setSelectedHubIdAction,
} from '../../actions';

const { Option } = Select;
const { Panel } = Collapse;

const breadcrumbConf = {
  routes: [
    {
      path: '/standard-hub-management',
      breadcrumbName: 'V2 Hub Management',
    },
    {
      path: '/standard-hub-management/manage-config',
      breadcrumbName: 'Manage V2 Hub Config',
    },
  ],
  itemRender: breadCrumbItemRender,
};

const EmptyState = () => (
  <div className="empty-state">
    <img
      src="https://cdn.peoplegrove.com/admin/1615289299836pg-info-circle-o.svg"
      alt="info-icon"
    />
    <p className="empty-state__heading">
      You don&apos;t have any base configuration saved. Select an hub from above
      dropdown and click on &apos;Save &amp; Publish Config&apos; CTA to
      continue
    </p>
  </div>
);

const ManageBaseConfig = ({
  baseConfig,
  baseConfigDiff,
  baseConfigHubOptions,
  baseConfigLoading,
  baseConfigSaving,
  clearBaseConfigState,
  fetchBaseConfig,
  saveBaseConfig,
  selectedHubId,
  setSelectedHubId,
}) => {
  useEffect(() => {
    fetchBaseConfig({ fetchHubOptions: true });
    return () => clearBaseConfigState();
  }, [clearBaseConfigState, fetchBaseConfig]);

  const handleSelectChange = value => {
    setSelectedHubId(value);
  };

  return (
    <div className="standard-hub--manage-config">
      {baseConfigLoading ? (
        <ProgressBar style={{ minHeight: '100vh' }} />
      ) : (
        <>
          <PageHeader
            title="Manage V2 Hub Config"
            breadcrumb={breadcrumbConf}
          />
          <div className="standard-hub--manage-config--main-container">
            <Card className="config-update-card">
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <Select
                    placeholder="Select V2 Base Hub"
                    style={{ width: '100%' }}
                    value={selectedHubId}
                    onChange={handleSelectChange}
                  >
                    {baseConfigHubOptions.map(el => {
                      const { hubId, clusterId, hubName } = el;
                      return (
                        <Option value={hubId} key={`${hubId}-${clusterId}`}>
                          {hubName} (Hub ID: {hubId}, Cluster ID: {clusterId})
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={saveBaseConfig}
                    disabled={isNil(selectedHubId)}
                    loading={baseConfigSaving}
                  >
                    Save &amp; Publish Config
                  </Button>
                </Col>
              </Row>
            </Card>
            {!isEmpty(baseConfig) && (
              <Card className="warning__card" style={{ width: '100%' }}>
                <div className="warning__description">
                  <p className="warning__text">
                    <span className="warning__text-bold">Note: </span>
                    Updating the hub here will update the base configuration for
                    all the V2 partners. Make this update only when it is an
                    absolute necessity.
                  </p>
                </div>
              </Card>
            )}
            <Collapse
              className="config-content"
              accordion
              destroyInactivePanel
              bordered={false}
            >
              <Panel header="What's changing" key="1">
                {isEmpty(baseConfigDiff) ? (
                  <Empty description={<span>No Changes</span>} />
                ) : (
                  <div className="config-content-card--json">
                    <ReactJson
                      theme="bright:inverted"
                      style={{ overflow: 'auto' }}
                      name="standard-base-config"
                      collapsed={2}
                      src={baseConfigDiff}
                    />
                  </div>
                )}
              </Panel>
              <Panel header="Standard Base Config" key="2">
                <Spin spinning={baseConfigSaving}>
                  {isEmpty(baseConfig) ? (
                    <EmptyState />
                  ) : (
                    <ReactJson
                      theme="bright:inverted"
                      style={{ overflow: 'auto' }}
                      name="standard-base-config"
                      collapsed={false}
                      src={{
                        hubSettings: {
                          ...get(baseConfig, 'hubSettings', {}),
                        },
                        clusterSettings: {
                          ...get(baseConfig, 'clusterSettings', {}),
                        },
                      }}
                    />
                  )}
                </Spin>
              </Panel>
            </Collapse>
            {/* <Card className="config-content-card">
            </Card> */}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({
  standardHubManagement: {
    baseConfig,
    baseConfigDiff,
    baseConfigHubOptions,
    baseConfigLoading,
    baseConfigSaving,
    selectedHubId,
  },
}) => ({
  baseConfig,
  baseConfigDiff,
  baseConfigHubOptions,
  baseConfigLoading,
  baseConfigSaving,
  selectedHubId,
});

const mapDispatchToProps = {
  clearBaseConfigState: clearBaseConfigStateAction,
  fetchBaseConfig: fetchBaseConfigAction,
  saveBaseConfig: saveBaseConfigAction,
  setSelectedHubId: setSelectedHubIdAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageBaseConfig);
