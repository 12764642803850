const prefix = 'clusters/';

export const FETCH_SESSIONS = `${prefix}FETCH_SESSIONS`;
export const SET_SESSIONS = `${prefix}SET_SESSIONS`;
export const SET_PAGE_LOADING = `${prefix}SET_PAGE_LOADING`;
export const DELETE_SESSION = `${prefix}DELETE_SESSION`;

export const fetchSessions = () => ({ type: FETCH_SESSIONS });

export const deleteSession = data => ({ type: DELETE_SESSION, data });

export const setSessions = data => ({
  type: SET_SESSIONS,
  data,
});

export const setPageLoading = data => ({ type: SET_PAGE_LOADING, data });
