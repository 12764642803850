/* eslint-disable no-console */
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { get, uniqBy } from 'lodash';
import {
  FETCH_CAREER_PATHS,
  FETCH_CAREER_PATHS_META,
  FETCH_CAREER_PATH_WITH_ASSOCIATED_DATA,
  ADD_CAREER_PATH,
  UPDATE_CAREER_PATH,
  FETCH_AUXILIARY_DATA_FOR_CAREER_PATHS,
  REVERT_CAREER_PATH_CHANGE,
  DELETE_CAREER_PATH,
  TOGGLE_CAREER_PATH_ACTIVE_STATUS,
  setCareerPathsMeta,
  setCareerPaths,
  setIsLoading,
  setCareerPathWithAssociatedData,
  setAuxiliaryDataForCareerPaths,
  // selectors
  careerPathsSelector,
  careerPathsMetaSelector,
  auxiliaryDataForCareerPathsSelector,
  FETCH_CAREER_PATH_CHANGE_LOG,
  FETCH_LINKED_PROJECTS,
  ADD_LINKED_PROJECTS,
  UPDATE_LINKED_PROJECTS,
  DELETE_LINKED_PROJECTS,
  FETCH_PROJECT_CHANGELOG,
  REVERT_PROJECT,
  setCareerPathChangeLog,
  setFiltersEnabled,
  setNumPages,
  setEditingKey,
  setAddingKey,
  setChangeLogKey,
  setLinkedProjects,
  setProjectTemplateChangelog,
  FETCH_OCCUPATIONS,
  setOccupations,
  UPDATE_OCCUPATIONS,
  occupationsSelector,
  fetchAuxiliaryDataForCareerPaths,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';
import {
  fetchCareerPathsApi,
  fetchCareerPathsMetaApi,
  addCareerPathApi,
  updateCareerPathApi,
  fetchCareerPathWithAssociatedDataApi,
  fetchAuxiliaryDataForCareerPathApi,
  fetchCareerPathChangeLogApi,
  revertCareerPathChangeApi,
  deleteCareerPathApi,
  toggleCareerPathActiveStatusApi,
  getLinkedProjectsAPI,
  createLinkedProjectsAPI,
  updatedLinkedProjectsAPI,
  deleteLinkedProjectsAPI,
  fetchProjectTemplateChangeLogAPI,
  revertProjectTemplateAPI,
  fetchOccupationsAPI,
  updateOccupationsApi,
} from '../../../api/manageCareerPaths';
import { getErrorMessage, shapeCareerPathChanges } from '../utils';

function* fetchCareerPathsMetaSaga() {
  try {
    const { data: careerPathsMeta } = yield call(fetchCareerPathsMetaApi);
    yield put(setCareerPathsMeta(careerPathsMeta));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching careerPathsMeta' },
    });
  }
}

function* fetchCareerPathsSaga({ data }) {
  try {
    const { page = 1, pageSize = 10, title, orderBy } = data;
    yield put(setIsLoading({ careerPathsData: true }));
    if (title || orderBy) yield put(setFiltersEnabled(true));
    const { data: response } = yield call(fetchCareerPathsApi, {
      page,
      limit: pageSize,
      title,
      orderBy,
    });
    const { careerPaths, numPages } = response;
    yield put(setNumPages(numPages));
    yield put(setCareerPaths(careerPaths));
    yield put(setIsLoading({ careerPathsData: false }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching careerPaths' },
    });
  }
}

function* fetchCareerPathWithAssociatedDataSaga({ data }) {
  try {
    const { careerPathId } = data;
    yield put(setIsLoading({ careerPathWithAssociatedData: true }));
    const { data: response } = yield call(
      fetchCareerPathWithAssociatedDataApi,
      { careerPathId }
    );
    const { careerPathWithAssociatedData } = response;

    const abilities = get(careerPathWithAssociatedData, 'abilities', []).map(
      ability => ({ title: ability, description: '' })
    );
    careerPathWithAssociatedData.skillsAbilities = [
      ...get(careerPathWithAssociatedData, 'skillsRequired', []),
      ...abilities,
    ];
    yield put(setCareerPathWithAssociatedData(careerPathWithAssociatedData));
    yield put(setIsLoading({ careerPathWithAssociatedData: false }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching careerPathsData' },
    });
  }
}

function* addCareerPathSaga({ data }) {
  try {
    yield put(setIsLoading({ addCareerPath: true }));
    const { careerPathData } = data;

    const careerPathsMeta = yield select(careerPathsMetaSelector);
    const { careerPathsColumns } = careerPathsMeta || {};
    const auxiliaryDataForCareerPaths = yield select(
      auxiliaryDataForCareerPathsSelector
    );
    const { standardData: standardDataForCareerPaths } =
      auxiliaryDataForCareerPaths;

    const careerPathChanges = shapeCareerPathChanges({
      careerPathData,
      standardDataForCareerPaths,
      careerPathsColumns,
    });

    const { data: response } = yield call(addCareerPathApi, {
      careerPathData: careerPathChanges,
    });
    const { careerPathWithAssociatedData } = response;
    const existingCareerPaths = yield select(careerPathsSelector);
    yield put(
      setCareerPaths([careerPathWithAssociatedData, ...existingCareerPaths])
    );
    yield put(setCareerPathWithAssociatedData(careerPathWithAssociatedData));
    yield put(setIsLoading({ addCareerPath: false }));
    yield put(setEditingKey(-1));
    yield put(setAddingKey(-1));
    yield put(setChangeLogKey(-1));
    yield put(fetchAuxiliaryDataForCareerPaths());
  } catch (e) {
    console.log('Error', e);
    yield put(setIsLoading({ addCareerPath: false }));
    const message = getErrorMessage('Error adding careerPath', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message },
    });
  }
}

function* updateCareerPathSaga({ data }) {
  try {
    yield put(setIsLoading({ updateCareerPath: true }));
    const { newCareerPath, oldCareerPath, careerPathId } = data;

    const careerPathsMeta = yield select(careerPathsMetaSelector);
    const { careerPathsColumns } = careerPathsMeta || {};
    const auxiliaryDataForCareerPaths = yield select(
      auxiliaryDataForCareerPathsSelector
    );
    const { standardData: standardDataForCareerPaths } =
      auxiliaryDataForCareerPaths;
    const careerPathChanges = shapeCareerPathChanges({
      careerPathData: newCareerPath,
      standardDataForCareerPaths,
      careerPathsColumns,
    });

    const { data: response } = yield call(updateCareerPathApi, {
      newCareerPath: careerPathChanges,
      oldCareerPath,
      careerPathId,
    });

    const { updatedCareerPathWithAssociatedData } = response;
    yield put(
      setCareerPathWithAssociatedData(updatedCareerPathWithAssociatedData)
    );
    const existingCareerPaths = yield select(careerPathsSelector);
    const updatedCareerPaths = existingCareerPaths.map(careerPath => {
      if (careerPath.id === updatedCareerPathWithAssociatedData.id) {
        return updatedCareerPathWithAssociatedData;
      }

      return careerPath;
    });
    yield put(setCareerPaths(updatedCareerPaths));
    yield put(setIsLoading({ updateCareerPath: false }));
    yield put(setEditingKey(-1));
    yield put(setAddingKey(-1));
    yield put(setChangeLogKey(-1));
    yield put(fetchAuxiliaryDataForCareerPaths());
  } catch (e) {
    console.log('Error', e);
    const message = getErrorMessage('Error updating careerPath', e);
    yield put(setIsLoading({ updateCareerPath: false }));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message },
    });
  }
}

function* fetchAuxiliaryDataForCareerPathSaga() {
  try {
    yield put(setIsLoading({ auxiliaryDataForCareerPaths: true }));
    const { data } = yield call(fetchAuxiliaryDataForCareerPathApi);
    const {
      standardData,
      jobFunctions,
      personalities,
      abilities,
      skillsRequired,
      educationalRequirements,
      occupations,
    } = data;
    const skillsAbilities = [
      ...skillsRequired,
      ...abilities.map(ability => ({
        title: ability,
        description: '',
      })),
    ];
    yield put(
      setAuxiliaryDataForCareerPaths({
        standardData,
        jobFunctions,
        personalities: uniqBy(personalities, item => item.title),
        // abilities,
        // skillsRequired,
        skillsAbilities: uniqBy(skillsAbilities, item => item.title),
        educationalRequirements,
        occupations,
      })
    );
    yield put(setIsLoading({ auxiliaryDataForCareerPaths: false }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching standard data for careerPath',
      },
    });
  }
}

function* fetchCareerPathChangeLogSaga({ data }) {
  try {
    yield put(setIsLoading({ careerPathChangeLog: true }));
    const { careerPathId } = data;
    const { data: response } = yield call(fetchCareerPathChangeLogApi, {
      careerPathId,
    });
    const { changeLog } = response;
    yield put(setCareerPathChangeLog(changeLog));
    yield put(setIsLoading({ careerPathChangeLog: false }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching changelog for careerPath',
      },
    });
  }
}

function* revertCareerPathChangeSaga({ data }) {
  try {
    const { changeLogId } = data;
    yield put(setIsLoading({ revertCareerPathChange: changeLogId }));
    const { data: response } = yield call(revertCareerPathChangeApi, {
      changeLogId,
    });

    const { updatedCareerPathWithAssociatedData } = response;
    const existingCareerPaths = yield select(careerPathsSelector);
    existingCareerPaths[0] = updatedCareerPathWithAssociatedData;
    yield put(setCareerPaths([...existingCareerPaths]));
    yield put(setIsLoading({ revertCareerPathChange: null }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error reverting changelog for careerPath',
      },
    });
  }
}

function* deleteCareerPathSaga({ data }) {
  try {
    yield put(setIsLoading({ deleteCareerPath: true }));
    const { careerPathId } = data;
    console.log({ careerPathId });
    yield call(deleteCareerPathApi, {
      careerPathId,
    });

    yield put(setIsLoading({ deleteCareerPath: false }));
    yield call(fetchCareerPathsSaga, { data: { page: 1, pageSize: 10 } });
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error deleteing careerPath',
      },
    });
  }
}

function* toggleCareerPathActiveStatusSaga({ data }) {
  try {
    const { careerPathId, isActive } = data;
    yield put(setIsLoading({ toggleCareerPathActiveStatus: careerPathId }));

    yield call(toggleCareerPathActiveStatusApi, {
      careerPathId,
      isActive,
    });

    yield put(setIsLoading({ toggleCareerPathActiveStatus: null }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error while toggling careerPath active status',
      },
    });
  }
}

function* fetchLinkedProjectsSaga({ data }) {
  try {
    const { careerPathId, page = 1 } = data;
    yield put(setIsLoading({ linkedProjects: careerPathId }));
    const { data: response } = yield call(getLinkedProjectsAPI, {
      careerPathId,
      page,
    });
    yield put(setLinkedProjects(response));
  } catch (e) {
    console.log('Error@fetchLinkedProjectsSaga', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching linked project templates',
      },
    });
  }
  yield put(setIsLoading({ linkedProjects: null }));
}

function* addLinkedProjectsSaga({ data }) {
  try {
    const { linkedProject, careerPathId, page } = data;
    yield put(setIsLoading({ linkedProjects: true }));
    yield call(createLinkedProjectsAPI, {
      formData: {
        ...linkedProject,
        jobFlow: 'diyProject',
      },
      name: linkedProject.jobTitle,
      isStandard: true,
      careerPathId,
    });
    yield call(fetchLinkedProjectsSaga, { data: { careerPathId, page } });
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'success',
        message: 'project template added',
      },
    });
  } catch (e) {
    console.log('Error@addLinkedProjectsSata', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error creating project template',
      },
    });
  }
  yield put(setIsLoading({ linkedProjects: null }));
}

function* updateLinkedProjectsSaga({ data }) {
  try {
    const { linkedProject, projectTemplateId, careerPathId, page } = data;
    yield put(setIsLoading({ linkedProjects: true }));
    yield call(updatedLinkedProjectsAPI, {
      projectTemplateId,
      formData: linkedProject,
    });
    yield call(fetchLinkedProjectsSaga, { data: { careerPathId, page } });
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'success',
        message: 'project template updated',
      },
    });
  } catch (e) {
    console.log('Error@updateLinkedProjectsSata', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error updating project template',
      },
    });
  }
  yield put(setIsLoading({ linkedProjects: null }));
}

function* deleteLinkedProjectsSaga({ data: { page, ...data } }) {
  try {
    yield put(setIsLoading({ linkedProjects: true }));
    yield call(deleteLinkedProjectsAPI, data);
    yield call(fetchLinkedProjectsSaga, {
      data: { careerPathId: data.careerPathId, page },
    });
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'success',
        message: 'Linked project template deleted',
      },
    });
  } catch (e) {
    console.log('Error@deleteLinkedProjectsSata', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error deleting linked project',
      },
    });
  }
  yield put(setIsLoading({ linkedProjects: null }));
}

function* fetchProjectTemplateChangelogSaga({ data }) {
  try {
    const { projectTemplateId } = data;
    yield put(setIsLoading({ projectTemplateChangelog: true }));
    const { data: response } = yield call(fetchProjectTemplateChangeLogAPI, {
      projectTemplateId,
    });
    const { changeLog } = response;
    yield put(setProjectTemplateChangelog(changeLog));
  } catch (e) {
    console.log('Error@fetchProjectTemplateChangelog', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching changelog for project template',
      },
    });
  }
  yield put(setIsLoading({ projectTemplateChangelog: false }));
}

function* revertProjectTemplateChangelog({ data }) {
  try {
    const { changeLogId } = data;
    yield put(setIsLoading({ revertProjectTemplate: changeLogId }));
    yield call(revertProjectTemplateAPI, {
      changeLogId,
    });
  } catch (e) {
    console.log('Error@revertProjectTemplateChangelog', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error reverting changelog for project template',
      },
    });
  }
  yield put(setIsLoading({ revertProjectTemplate: null }));
}

function* fetchOccupationsSaga({ data = {} }) {
  try {
    yield put(setIsLoading({ occupations: true }));
    const { fetchCareerPaths, title, current = 1, pageSize = 10 } = data;
    const skip = (current - 1) * pageSize;
    const limit = pageSize;
    const { data: response } = yield call(fetchOccupationsAPI, {
      title,
      skip,
      limit,
      fetchCareerPaths,
    });
    yield put(setOccupations(response));
    yield put(setIsLoading({ occupations: false }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching occupations' },
    });
  }
}

function* updateOccupationsSaga({ data }) {
  try {
    const dbKeyMap = {
      isActive: 'is_active',
      careerPathId: 'career_path_id',
    };
    const { occupationId } = data;
    const payload = { ...data };
    delete payload.occupationId;
    yield put(setIsLoading({ updateOccupations: occupationId }));

    const { data: updatedOccupation } = yield call(updateOccupationsApi, {
      occupationId,
      payload,
    });
    const { occupations, total, activeCareerPaths } = yield select(
      occupationsSelector
    );

    const updatedIndex = occupations.findIndex(
      occupation => occupation.id === occupationId
    );

    const updatedOccupations = [...occupations];
    const occupationCopy = { ...occupations[updatedIndex] };
    Object.keys(payload).forEach(key => {
      occupationCopy[dbKeyMap[key]] = updatedOccupation[key];
    });

    updatedOccupations[updatedIndex] = { ...occupationCopy };

    yield put(
      setOccupations({
        occupations: updatedOccupations,
        total,
        activeCareerPaths,
      })
    );
    yield put(setIsLoading({ updateOccupations: null }));
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error while updating occupation',
      },
    });
  }
}

const manageCareerPathsSaga = [
  takeLatest(FETCH_CAREER_PATHS_META, fetchCareerPathsMetaSaga),
  takeLatest(FETCH_CAREER_PATHS, fetchCareerPathsSaga),
  takeLatest(
    FETCH_CAREER_PATH_WITH_ASSOCIATED_DATA,
    fetchCareerPathWithAssociatedDataSaga
  ),
  takeLatest(ADD_CAREER_PATH, addCareerPathSaga),
  takeLatest(UPDATE_CAREER_PATH, updateCareerPathSaga),
  takeLatest(
    FETCH_AUXILIARY_DATA_FOR_CAREER_PATHS,
    fetchAuxiliaryDataForCareerPathSaga
  ),
  takeLatest(FETCH_CAREER_PATH_CHANGE_LOG, fetchCareerPathChangeLogSaga),
  takeLatest(REVERT_CAREER_PATH_CHANGE, revertCareerPathChangeSaga),
  takeLatest(DELETE_CAREER_PATH, deleteCareerPathSaga),
  takeLatest(
    TOGGLE_CAREER_PATH_ACTIVE_STATUS,
    toggleCareerPathActiveStatusSaga
  ),
  takeLatest(FETCH_LINKED_PROJECTS, fetchLinkedProjectsSaga),
  takeLatest(ADD_LINKED_PROJECTS, addLinkedProjectsSaga),
  takeLatest(UPDATE_LINKED_PROJECTS, updateLinkedProjectsSaga),
  takeLatest(DELETE_LINKED_PROJECTS, deleteLinkedProjectsSaga),
  takeLatest(FETCH_PROJECT_CHANGELOG, fetchProjectTemplateChangelogSaga),
  takeLatest(REVERT_PROJECT, revertProjectTemplateChangelog),
  takeLatest(FETCH_OCCUPATIONS, fetchOccupationsSaga),
  takeLatest(UPDATE_OCCUPATIONS, updateOccupationsSaga),
];
export default manageCareerPathsSaga;
