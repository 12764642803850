import React, { PureComponent } from 'react';
import { Modal, Card } from 'antd';
import ProgressBar from '../../../../component/ProgressBar';
import DynamicForm from '../DynamicForm';

class DegreeTypeModal extends PureComponent {
  handleClose = () => this.props.handleClose(false);
  render() {
    const {
      isDegreeTypeModalOpen,
      loading,
      data,
      updateDegreeTypes,
      updating,
    } = this.props;
    return (
      <Modal
        title="Edit Degree Types"
        visible={isDegreeTypeModalOpen}
        onCancel={this.handleClose}
        footer={false}
        bodyStyle={{
          padding: '12px',
          maxHeight: '500px',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
        destroyOnClose
      >
        {loading || updating ? (
          <ProgressBar />
        ) : (
          <Card>
            <DynamicForm
              data={data}
              handleUpdate={updateDegreeTypes}
              updating={updating}
            />
          </Card>
        )}
      </Modal>
    );
  }
}

export default DegreeTypeModal;
