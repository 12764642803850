import { request } from '../utils/request';

export const getTemplates = () => request.get('/superadmin/admin-templates');

export const getBeefreeTokenAPI = data =>
  request.get(`/hub-admin/manual-messaging/bulk-email/token`, data);

export const postMarketingEmail = data => {
  return request.post('/superadmin/marketing-email', data);
};

export const getMarketingEmails = () => {
  return request.get('/superadmin/marketing-email');
};

export const putMarketingEmail = ({ id, data }) => {
  return request.put(`/superadmin/marketing-email/${id}`, data);
};

export const postTestEmail = data => {
  return request.post(`/superadmin/marketing-email/test`, data);
};

export const postScheduleEmail = ({ data, id = null }) => {
  if (id)
    return request.post(
      `/superadmin/marketing-email/schedule-email/${id}`,
      data
    );
  else return request.post(`/superadmin/marketing-email/schedule-email`, data);
};

export const getStats = params => {
  return request.get('/superadmin/marketing-email/stats', { params });
};

export const postBulkEmailTemplate = data => {
  return request.post('/superadmin/bulk-email-template', data);
};

export const putBulkEmailTemplate = ({ id, data }) => {
  return request.put(`/superadmin/bulk-email-template/${id}`, data);
};

export const deleteBulkEmailTemplate = id =>
  request.delete(`/superadmin/bulk-email-template/${id}`);

export const getTemplateCategories = data =>
  request.get(`/superadmin/bulk-email-template-categories`, data);

export const getJobTemplates = () =>
  request.get(`/job-templates`, { params: { all: true } });

export const fetchAllCampaigns = () => request.get(`/superadmin/campaigns`);

export const createCampaign = (data) =>
  request.post(`/superadmin/campaigns`, data);

export const updateCampaign = ({ id, data }) =>
  request.put(`/superadmin/campaigns/${id}`, data);

export const deleteCampaign = id =>
  request.delete(`/superadmin/campaigns/${id}`);

export const fetchCampaignInfo = id =>
  request.get(`/superadmin/campaigns/${id}`);

export const createCampaignEmail = ({campaignId,data}) =>
  request.post(`/superadmin/campaigns/${campaignId}/email`, data);

export const updateCampaignEmail = ({ campaignId, id, data }) =>
  request.put(`/superadmin/campaigns/${campaignId}/email/${id}`, data);

export const deleteCampaignEmail =  ({ campaignId,id }) => 
  request.delete(`/superadmin/campaigns/${campaignId}/email/${id}`);
