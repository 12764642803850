import React, { PureComponent } from 'react';
import { Modal, Form, message, Select, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';

const { Option } = Select;

const FormItem = Form.Item;
class AddClusterHubsModal extends PureComponent {
  state = {
    clusterId: undefined,
    hubIds: [],
  };

  validateFields = () => {
    let valid = true;
    const { clusterId, hubIds } = this.state;
    if (!clusterId) {
      message.warning('Please select cluster to add.');
      valid = false;
    }
    if (!hubIds.length) {
      message.warning('Please select at least one hub to add.');
      valid = false;
    }
    return valid;
  };

  onOk = () => {
    if (!this.validateFields()) return;
    const { hubsData, addHubsToBAList } = this.props;
    const { hubIds } = this.state;
    let selectedHubs = cloneDeep(hubsData);
    selectedHubs = selectedHubs.map(hubObj => {
      if (!hubObj.hub.settings) {
        hubObj.hub.settings = {};
      }
      hubObj.hub.settings.joinBAHub =
        hubIds.indexOf(hubObj.hub.id) > -1
          ? true
          : hubObj.hub.settings.joinBAHub;
      return hubObj.hub;
    });
    // send data which is updated only
    selectedHubs = selectedHubs.filter((selectedHubObj, index) => {
      return (
        selectedHubObj.settings &&
        hubsData[index].hub.settings &&
        selectedHubObj.settings.joinBAHub !==
          hubsData[index].hub.settings.joinBAHub
      );
    });
    selectedHubs = selectedHubs.map(hubObj => {
      hubObj.join_ba_hub = hubObj.settings.joinBAHub;
      delete hubObj.settings;
      return hubObj;
    });
    this.clearStateData();
    addHubsToBAList(selectedHubs);
  };

  clearStateData = () => {
    this.setState({
      clusterId: undefined,
      hubIds: [],
    });
  };

  onClusterChange = clusterId => {
    const { getAllHubByClusterId } = this.props;
    this.setState({ clusterId, hubIds: [] });
    getAllHubByClusterId(clusterId);
  };

  onClusterFilter = (input, option) => {
    const { clusters } = this.props;
    const cluster = clusters.find(c => c.id === parseInt(option.key));
    return cluster.name.toLowerCase().includes(input.toLowerCase());
  };

  onHubFilter = (input, option) => {
    const { hubsData } = this.props;
    const hubObj = hubsData.find(c => c.hub.id === parseInt(option.key));
    return hubObj.hub.name.toLowerCase().includes(input.toLowerCase());
  };

  onHubChange = value => {
    this.setState({
      hubIds: value,
    });
  };

  onCancel = () => {
    this.clearStateData();
    this.props.onClose();
  };

  render() {
    const {
      clusters,
      clustersLoading,
      hubsData,
      hubDataLoading,
      visible,
    } = this.props;

    const { clusterId, hubIds } = this.state;
    return (
      <Modal
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onCancel}
        title="Add Hub to Invite"
        onClose={() => {}}
      >
        <Form>
          <FormItem label="Cluster">
            <Select
              style={{ width: 300 }}
              showSearch
              placeholder="Select Cluster"
              onChange={this.onClusterChange}
              filterOption={this.onClusterFilter}
              loading={clustersLoading}
              value={clusterId}
            >
              {clusters &&
                clusters.map(cluster => (
                  <Option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </Option>
                ))}
            </Select>
          </FormItem>
          <FormItem label="Hub">
            <Select
              mode="multiple"
              allowClear
              style={{ width: 300 }}
              showSearch
              placeholder="Select Hubs"
              onChange={this.onHubChange}
              filterOption={this.onHubFilter}
              loading={hubDataLoading}
              value={hubIds}
            >
              {hubsData &&
                hubsData.map(hubObj => (
                  <Option
                    key={hubObj.hub.id}
                    value={hubObj.hub.id}
                    disabled={
                      !!(hubObj.hub.settings && hubObj.hub.settings.joinBAHub)
                    }
                  >
                    <Tooltip
                      title={
                        hubObj.hub.settings && hubObj.hub.settings.joinBAHub
                          ? 'This hub is already added.'
                          : undefined
                      }
                      key={hubObj.hub.id}
                    >
                      {hubObj.hub.name}
                    </Tooltip>
                  </Option>
                ))}
            </Select>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default AddClusterHubsModal;
