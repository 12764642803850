export const FETCH_ADMIN_TAGS = 'AdminTag/FETCH_ADMIN_TAGS';
export const SET_ADMIN_TAGS = 'AdminTag/SET_ADMIN_TAGS';
export const DELETE_ADMIN_TAG = 'AdminTag/DELETE_ADMIN_TAG';
export const UPDATE_ADMIN_TAG = 'AdminTag/UPDATE_ADMIN_TAG';
export const ADMIN_TAGS_LOADING = 'AdminTag/ADMIN_TAGS_LOADING';
export const ADMIN_TAG_UPDATING = 'AdminTag/ADMIN_TAG_UPDATING';
export const ADD_ADMIN_TAG = 'AdminTag/ADD_ADMIN_TAG';
export const ADD_ADMIN_TAG_REFERENCE = 'AdminTag/ADD_ADMIN_TAG_REFERENCE';
export const DELETE_ADMIN_TAG_REFERENCE = 'AdminTag/DELETE_ADMIN_TAG_REFERENCE';
export const SET_CLUSTER_HUBS = 'AdminTag/SET_CLUSTER_HUBS';

export const fetchAdminTags = () => ({
	type: FETCH_ADMIN_TAGS,
});

export const setAdminTags = data => ({
	type: SET_ADMIN_TAGS,
	data,
})

export const deleteAdminTag = data => ({
	type: DELETE_ADMIN_TAG,
	data
});

export const updateAdminTag = data => ({
	type: UPDATE_ADMIN_TAG,
	data
});

export const setAdminTagsLoading = data => ({
	type: ADMIN_TAGS_LOADING,
	data,
})

export const setAdminTagUpdating = data => ({
	type: ADMIN_TAG_UPDATING,
	data,
});

export const addAdminTag = data => ({
	type: ADD_ADMIN_TAG,
	data,
})

export const addAdminTagReference = data => ({
	type: ADD_ADMIN_TAG_REFERENCE,
	data,
});

export const deleteAdminTagReference = data => ({
	type: DELETE_ADMIN_TAG_REFERENCE,
	data,
});

export const setClusterHubs = data => ({
	type: SET_CLUSTER_HUBS,
	data,
})

// Selectors:
export const retrieveAdminTags = ({ adminTag }) => adminTag.adminTags;
export const retrieveClusterHubs = ({ adminTag }) => adminTag.clusterHubs;
export const retrieveCurrentUser = ({ admin }) => admin.id;
