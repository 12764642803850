import React, { useMemo, useState } from 'react';
import { Card, Table } from 'antd';
import { get } from 'lodash';
import classes from '../AskAnythingMetrics.module.scss';
import { useGetRoutedQuesCountQuery } from '../query/useGetRoutedQuesCountQuery';
import DataTableFilters from './DataTableFilters';
import { numCompare, stringCompare } from '../util';

const QuestionsRoutedDataTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const pageSize = 10;

  const { data, isLoading: isFetchingPeopleCount } = useGetRoutedQuesCountQuery(
    { ...filters, currentPage, perPage: pageSize }
  );

  const totalRecords = useMemo(() => get(data, '0.total_records', 0), [data]);

  const getTotalPeopleCount = record => {
    const automaticCount = get(record, 'routing_counts.automatic', 0);
    const manualCount = get(record, 'routing_counts.manual', 0);
    return automaticCount + manualCount;
  };

  const columns = [
    {
      title: 'Hub ID',
      dataIndex: 'hub_id',
      key: 'hub_id',
    },
    {
      title: 'Hub Name',
      dataIndex: 'hub_name',
      key: 'hub_name',
      sorter: (r1, r2) => stringCompare(r1, r2, 'hub_name'),
    },
    {
      title: 'Total # of People routed for questions',
      key: 'total_count',
      render: getTotalPeopleCount,
      sorter: (r1, r2) => getTotalPeopleCount(r1) - getTotalPeopleCount(r2),
    },
    {
      title: 'Via Manual Routing',
      key: 'asked_from_Email',
      render: record => get(record, 'routing_counts.manual', 0),
      sorter: (r1, r2) => numCompare(r1, r2, 'routing_counts.manual'),
    },
    {
      title: 'Via Automatic Routing',
      key: 'asked_from_platform',
      render: record => get(record, 'routing_counts.automatic', 0),
      sorter: (r1, r2) => numCompare(r1, r2, 'routing_counts.automatic'),
    },
  ];

  return (
    <>
      <DataTableFilters
        setFiltersToApply={setFilters}
        isApplyingFilters={isFetchingPeopleCount}
      />
      <Card bordered={false}>
        <Table
          className={classes.data_table}
          dataSource={data}
          columns={columns}
          bordered
          loading={isFetchingPeopleCount}
          pagination={{
            current: currentPage,
            pageSize,
            hideOnSinglePage: true,
            onChange: updatedCurrPage => {
              setCurrentPage(updatedCurrPage);
            },
            total: totalRecords,
          }}
        />
      </Card>
    </>
  );
};

export default QuestionsRoutedDataTable;
