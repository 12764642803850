import React, { useState, useRef } from 'react';
import {
  Button,
  Table,
  Tooltip,
  Row,
  Col,
  Switch,
  Popover,
  Icon,
  Spin,
} from 'antd';

import { useDispatch } from 'react-redux';
import { setFormData } from '../../actions';
import useGetHubListQuery from '../../query/useGetHubListQuery';
import useGetGlobalListsQuery from '../../query/useGlobalListsQuery';
import usePublishListMutation from '../../query/usePublishListMutation';
import CreateAchievement from '../CreateAchievement';

function AchievementsTab() {
  const topActionWrapperRef = useRef();
  const [selectedList, setSelectedList] = useState();
  const [page, setCurrentPage] = useState(1);
  const [activeForm, setActiveForm] = useState(null);
  const dispatch = useDispatch();

  const { data, isLoading: globalListsLoading } = useGetGlobalListsQuery(page);

  const { mutate: setListActive } = usePublishListMutation({
    setSelectedList,
    page,
  });

  useGetHubListQuery();

  if (!data) return <Spin />;

  const { lists: globalLists, totalCount } = data;

  const updateActiveForm = type => {
    setSelectedList(null);
    dispatch(setFormData({}));
    setActiveForm(type);
  };

  const handleEdit = record => {
    topActionWrapperRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    setSelectedList(record);
  };

  const makeColumnsByTab = () => [
    {
      title: 'Badge Name',
      dataIndex: 'title',
      key: 'title',
      width: 291,
      ellipsis: true,
      render: val => (
        <div className="templates-and-tips-ellipsis-cell">
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      width: 130,
      key: 'createdAt',
    },
    {
      title: 'Updated on',
      dataIndex: 'updatedAt',
      width: 130,
      key: 'updatedAt',
    },
    {
      title: () => (
        <Popover
          content="Audience and Criteria cannot be updated once the achievement is published. Also publish a list in OFF hours to avoid load on server"
          placement="bottom"
        >
          Published
          <Icon type="info-circle-o" style={{ marginLeft: '8px' }} />
        </Popover>
      ),
      width: 130,
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => (
        <Switch
          checked={record.active}
          onChange={(value, e) => {
            e.stopPropagation();
            setListActive({
              ...record,
              active: value,
              assigned_users: record.assignedUsers,
            });
          }}
        />
      ),
    },
  ];

  const getTableData = () =>
    globalLists.map(({ id: key, createdAt, updatedAt, ...rest }) => ({
      id: key,
      key,
      createdAt: new Date(createdAt).toLocaleDateString(),
      updatedAt: new Date(updatedAt).toLocaleDateString(),
      ...rest,
    }));

  return (
    <div
      ref={el => {
        topActionWrapperRef.current = el;
      }}
      className="manage-table-container"
    >
      <Col span={12} style={{ background: 'white' }}>
        <Row>
          <Button
            style={{ margin: '12px' }}
            key="create-achievements"
            icon="file-add"
            onClick={() => updateActiveForm('achievements')}
            type="primary"
          >
            Create Achievement
          </Button>
        </Row>
        <Row>
          <Table
            key={1}
            rowClassName={record =>
              record.slug === selectedList?.slug ? 'selected-row' : ''
            }
            dataSource={getTableData()}
            scroll={{ x: 500 }}
            loading={globalListsLoading}
            columns={makeColumnsByTab()}
            pagination={{
              simple: false,
              total: totalCount,
              pageSize: 25,
              current: page,
            }}
            onChange={({ current }) => setCurrentPage(current)}
            onRow={record => ({ onClick: () => handleEdit(record) })}
          />
        </Row>
      </Col>
      <Col span={12}>
        {(selectedList || activeForm) && (
          <CreateAchievement
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            page={page}
          />
        )}
      </Col>
    </div>
  );
}

export default AchievementsTab;
