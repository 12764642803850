/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Switch,
  Checkbox,
  Alert,
  Modal,
  Spin,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncFetch from '../../../../hooks/useAsyncFetch';
import { fetchCmsContentApi } from '../../../../api/cmsToolApi';
import clustersApi from '../../../../api/cluster';
import { notify } from '../../../../utils/notify';
import { sanitize } from '../../../../utils/dompurify';
import {
  fetchArchetypes,
  saveTemplateApi,
  updateTemplateApi,
  fetchTemplatesApi,
} from '../../../../api/templatesAndTips';
import { fetchTemplates, setFormData } from '../../actions';
import { makeUpdatedValuesFromFormValues } from '../../utils';

const { Option } = Select;

const CreateTemplate = ({
  form,
  connectionSources,
  preConnectionTemplates,
  topActionWrapperRef,
  threadStages,
  templateModelNameKeywordData,
  onDelete,
  setActiveTab,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [error, setError] = useState(null);
  const [selectedContent, setSelectedContent] = useState({});
  const formData = useSelector(
    ({ templatesAndTips }) => templatesAndTips.formData
  );

  const [currentStage, setCurrentStage] = useState(formData.stage);

  useEffect(() => {
    setCurrentStage(formData.stage);
  }, [formData.stage]);

  const { response: cmsContent, isLoading: cmsContentLoading } = useAsyncFetch(
    true,
    fetchCmsContentApi,
    {
      contentType: 'INBOX_TEMPLATE',
      fetchAll: true,
    },
    undefined,
    {
      onError: () => notify(`Couldn't fetch CMS Contents!`, 'error'),
    }
  );

  const { response: clustersData, isLoading: clustersLoading } = useAsyncFetch(
    true,
    clustersApi.getAll,
    undefined,
    {
      onError: () => notify(`Couldn't fetch clusters!`, 'error'),
    }
  );

  const { response: archetypeData, isLoading: archetypeLoading } =
    useAsyncFetch(true, fetchArchetypes, undefined, {
      onError: () => notify(`Couldn't fetch archetypes!`, 'error'),
    });

  const { refetch: reSaveTemplateApi, isLoading: isSavingTemplate } =
    useAsyncFetch(false, saveTemplateApi, undefined, {
      onError: e => {
        notify(e.response.data.message, 'error');
      },
      onSuccess: () => {
        topActionWrapperRef.current.scrollIntoView({
          behavior: 'smooth',
        });

        dispatch(fetchTemplates());
        form.resetFields();
        setSelectedContent({});
        notify('Template created successfully!', 'success');
      },
    });

  const { refetch: reUpdateTemplateApi, isLoading: isUpdatingTemplate } =
    useAsyncFetch(false, updateTemplateApi, undefined, {
      onError: e => {
        notify(e.response.data.message, 'error');
      },
      onSuccess: () => {
        topActionWrapperRef.current.scrollIntoView({
          behavior: 'smooth',
        });
        dispatch(fetchTemplates());
        notify('Template updated successfully!', 'success');
      },
    });

  const {
    response: postConnectionTemplatesData,
    isLoading: isFetchingPostConnectionTemplates,
  } = useAsyncFetch(true, fetchTemplatesApi, { fetchAll: true }, undefined, {
    onError: () => notify(`Couldn't fetch post connection templates!`, 'error'),
  });

  const setPreviewContent = useCallback(
    identifier => {
      if (cmsContent?.data?.contents) {
        const data =
          cmsContent.data.contents.find(c => c.identifier === identifier) || {};
        setSelectedContent(data);
      }
    },
    [cmsContent?.data?.contents]
  );

  useEffect(() => {
    if (formData.id) {
      form.resetFields();
      setPreviewContent(formData.cmsContent);
    } else {
      setSelectedContent({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, setPreviewContent]);

  const onFinish = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const {
          preceedingMessageKeywords,
          // eslint-disable-next-line no-shadow
          connectionSources,
          preConnectionTemplate,
          postConnectionTemplate,
          stage,
          isGeneric,
        } = values;

        if (
          !(
            !isEmpty(connectionSources) ||
            preConnectionTemplate ||
            postConnectionTemplate ||
            stage ||
            !isEmpty(preceedingMessageKeywords)
          ) &&
          !isGeneric
        ) {
          setError(
            'Please enter either connection sources, preconnection template, postconnection template, stage or preceeding message template'
          );
          return;
        }

        setError(null);

        if (formData.id) {
          const updatedValues = makeUpdatedValuesFromFormValues(values);
          reUpdateTemplateApi({ id: formData.id, ...updatedValues });
          dispatch(setFormData({ id: formData.id, ...values }));
          return;
        }

        reSaveTemplateApi({ id: formData.id, ...values });
        setActiveTab('template');
      }
    });
  };

  const getSelectedArchetypes = (archetype = []) => {
    const arr = [];
    (archetypeData.data?.archetype?.options || []).forEach(
      ({ children = [] }) =>
        children.forEach(({ optionId, optionTitle }) => {
          if (archetype.includes(optionId)) arr.push(optionTitle);
        })
    );
    return arr.join(', ');
  };

  const makeFinalConditionsPreview = () => {
    const {
      showFor,
      archetype,
      preceedingMessageKeywords,
      postConnectionTemplate,
      preConnectionTemplate,
      stage,
      connectionSource,
    } = form.getFieldsValue();

    return (
      <>
        <h3>Selected Conditions:</h3>
        <ul>
          {showFor && (
            <li>
              <strong>Show for:</strong> {showFor}
            </li>
          )}
          {Array.isArray(archetype) && !isEmpty(archetype) && (
            <li>
              <strong>Archetype:</strong> {getSelectedArchetypes(archetype)}
            </li>
          )}
          {preceedingMessageKeywords && (
            <li>
              <strong>Preceeding message keywords:</strong>{' '}
              {preceedingMessageKeywords}
            </li>
          )}
          {postConnectionTemplate && (
            <li>
              <strong>Post connection template:</strong>{' '}
              {
                (
                  (postConnectionTemplatesData.data?.inboxTemplates || []).find(
                    t => t.id === postConnectionTemplate
                  ) || {}
                ).name
              }
            </li>
          )}
          {preConnectionTemplate && (
            <li>
              <strong>Pre connection template:</strong>{' '}
              {
                (
                  (preConnectionTemplates.data || []).find(
                    t => t.identifier === preConnectionTemplate
                  ) || {}
                ).title
              }
            </li>
          )}
          {stage && (
            <li>
              <strong>Stage in thread:</strong> {stage}
            </li>
          )}
          {connectionSource && (
            <li>
              <strong>Connection Source:</strong> {connectionSource}
            </li>
          )}
        </ul>
      </>
    );
  };

  return (
    // eslint-disable-next-line no-return-assign
    <div ref={el => (containerRef.current = el)}>
      <Card>
        <h3>{formData.id ? 'Update' : 'Create'} Template Form</h3>
        <Form onSubmit={onFinish}>
          <Form.Item label="Name">
            {form.getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please input template name!' },
              ],
              initialValue: formData.name,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Internal Name">
            {form.getFieldDecorator('internalName', {
              rules: [
                { required: true, message: 'Please input internal name!' },
              ],
              initialValue: formData.internalName,
            })(<Input />)}
          </Form.Item>
          <Spin spinning={cmsContentLoading}>
            <Form.Item label="Select template body" style={{ marginBottom: 5 }}>
              {form.getFieldDecorator('cmsContent', {
                rules: [
                  {
                    required: true,
                    message: 'Please select template content!',
                  },
                ],
                initialValue: formData.cmsContent,
              })(
                <Select
                  allowClear
                  getPopupContainer={() => containerRef.current}
                  onChange={v => setPreviewContent(v)}
                  loading={cmsContentLoading}
                  disabled={cmsContentLoading || Boolean(formData.id)}
                >
                  {(cmsContent.data?.contents || []).map(content => (
                    <Option key={content.id} value={content.identifier}>
                      {content.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {selectedContent?.content && (
              <Alert
                style={{ marginBottom: 24 }}
                message={
                  <div>
                    <p style={{ marginBottom: 3 }}>
                      <strong>Content preview: </strong>
                      <Link to={`/cms-tool?contentId=${selectedContent.id}`}>
                        Edit content
                      </Link>
                    </p>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitize(selectedContent?.content || ''),
                      }}
                    ></div>
                  </div>
                }
                type="info"
              />
            )}
          </Spin>
          <Form.Item label="Select audience">
            {form.getFieldDecorator('showFor', {
              rules: [
                {
                  required: !form.getFieldValue('isGeneric'),
                  message: 'Please select audience!',
                },
              ],
              initialValue: formData.showFor,
            })(
              <Select allowClear getPopupContainer={() => containerRef.current}>
                <Option value="mentee">Mentee</Option>
                <Option value="mentor">Mentor</Option>
                <Option value="all">All</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Select archetype">
            {form.getFieldDecorator('archetype', {
              initialValue: formData.archetype,
            })(
              <Select
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                allowClear
                loading={archetypeLoading}
                disabled={archetypeLoading}
              >
                {(archetypeData.data?.archetype?.options || []).reduce(
                  (options, option) => {
                    option.children.forEach(({ optionId, optionTitle }) => {
                      options.push(
                        <Option key={optionId} value={optionId}>
                          {optionTitle}
                        </Option>
                      );
                    });

                    return options;
                  },
                  []
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Keywords in preceding message"
            style={{ marginBottom: 5 }}
          >
            {form.getFieldDecorator('preceedingMessageKeywords', {
              initialValue: formData.preceedingMessageKeywords,
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                allowClear
                loading={threadStages.loading}
                disabled={threadStages.loading}
              >
                {templateModelNameKeywordData.data.map(({ identifier }) => (
                  <Option key={identifier} value={identifier}>
                    {identifier}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {form.getFieldValue('preceedingMessageKeywords') && (
            <Alert
              style={{ marginBottom: 14, padding: 5 }}
              type="info"
              description={
                <p style={{ margin: 0 }}>
                  <strong>Keywords:</strong>{' '}
                  {(
                    templateModelNameKeywordData.data.find(
                      k =>
                        k.identifier ===
                        form.getFieldValue('preceedingMessageKeywords')
                    ) || {}
                  ).keywords.join(', ')}
                </p>
              }
            />
          )}
          <Form.Item label="Connection sources">
            {form.getFieldDecorator('connectionSources', {
              initialValue: formData.connectionSources,
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                allowClear
                showSearch
                mode="multiple"
                loading={connectionSources.loading}
                disabled={threadStages.loading}
              >
                {connectionSources.data.map((source, i) => (
                  <Option key={source + i} value={source}>
                    {source}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Exclude connection sources">
            {form.getFieldDecorator('excludedConnectionSources', {
              initialValue: formData.excludedConnectionSources,
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                allowClear
                mode="multiple"
                showSearch
                loading={connectionSources.loading}
                disabled={threadStages.loading}
              >
                {connectionSources.data.map((source, i) => (
                  <Option key={source + i} value={source}>
                    {source}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Pre connection template">
            {form.getFieldDecorator('preConnectionTemplate', {
              initialValue: formData.preConnectionTemplate,
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                loading={preConnectionTemplates.loading}
                disabled={preConnectionTemplates.loading}
              >
                {(preConnectionTemplates.data || []).map(
                  ({ identifier, title }) => (
                    <Option key={identifier} value={identifier}>
                      {title}
                    </Option>
                  )
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Post connection template">
            {form.getFieldDecorator('postConnectionTemplate', {
              initialValue: formData.postConnectionTemplate,
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={isFetchingPostConnectionTemplates}
              >
                {(postConnectionTemplatesData.data?.inboxTemplates || []).map(
                  ({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  )
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Stage in thread">
            {form.getFieldDecorator('stage', {
              initialValue: formData.stage,
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                allowClear
                loading={threadStages.loading}
                showSearch
                disabled={threadStages.loading}
                onChange={stage => {
                  setCurrentStage(stage);
                }}
              >
                {threadStages.data.map(({ id, title }) => (
                  <Option key={id} value={id}>
                    {title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {currentStage === 'INITIAL_REPLY' && (
            <Form.Item label="Delay (in hrs)">
              {form.getFieldDecorator('delayHrs', {
                initialValue: formData.delayHrs || 0,
              })(<Input type="number" placeholder="Select in hrs" />)}
            </Form.Item>
          )}
          <Form.Item label="Generic template" valuePropName="checked">
            <p style={{ marginBottom: 5, lineHeight: '25px' }}>
              (This template would show in the thread for a mentor/mentee at all
              times)
            </p>
            {form.getFieldDecorator('isGeneric', {
              initialValue: formData.isGeneric,
              valuePropName: 'checked',
            })(<Switch />)}
          </Form.Item>
          <Form.Item label="Clusters">
            {form.getFieldDecorator('clusters', {
              initialValue: formData.clusters || [],
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                mode="multiple"
                allowClear
                loading={clustersLoading}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {(clustersData.data || []).map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('isDefault', {
              initialValue: formData.isDefault,
              valuePropName: 'checked',
            })(
              <Checkbox>
                Set as default (This template is enabled for all clusters)
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item label="Tip while writing a message">
            {form.getFieldDecorator('messageTip', {
              initialValue: formData.messageTip,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Active">
            {form.getFieldDecorator('active', {
              initialValue: formData.active,
              valuePropName: 'checked',
            })(<Switch />)}
          </Form.Item>
          <Alert
            style={{ marginBottom: 24 }}
            type="info"
            message={makeFinalConditionsPreview()}
          />
          {error && (
            <Alert
              style={{ marginBottom: 15 }}
              description={error}
              type="error"
            />
          )}
          <Form.Item className="templates-and-tips-form-footer">
            {formData.id ? (
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete this Template?',
                    okText: 'Delete',
                    okButtonProps: {
                      type: 'danger',
                    },
                    onOk: () => onDelete('inboxTemplates', formData.id),
                  });
                }}
                type="danger"
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <Button
              loading={isSavingTemplate || isUpdatingTemplate}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Form.create({ name: ' create inbox templates' })(CreateTemplate);
