const prefix = 'TEMPLATES_AND_TIPS';

export const SET_TABLE_DATA = `${prefix}/SET_TABLE_DATA`;
export const SET_ACTIVE_FORM = `${prefix}/SET_ACTIVE_FORM`; // 'tip' or 'template'
export const SET_FORM_DATA = `${prefix}/SET_FORM_DATA`;
export const UPDATE_FORM_DATA = `${prefix}/UPDATE_FORM_DATA`;

export const FETCH_TEMPLATES = `${prefix}/FETCH_TEMPLATES`;
export const FETCH_TIPS = `${prefix}/FETCH_TIPS`;
export const FETCH_ALUM_STUDENT_OUTREACH_TEMPLATES = `${prefix}/FETCH_ALUM_STUDENT_OUTREACH_TEMPLATES`;

export const SET_TABLE_KEY = `${prefix}/SET_TABLE_KEY`;

export const SET_TABLE_LOADING = `${prefix}/SET_TABLE_LOADING`;

export const CLEAR_DATA = `${prefix}/CLEAR_TABLE_DATA`;

// for api's
export const CREATE_CONTENT = `${prefix}/CREATE_CONTENT`;
export const EDIT_CONTENT = `${prefix}/EDIT_CONTENT`;
export const DELETE_CONTENT = `${prefix}/DELETE_CONTENT`;
export const FETCH_CONTENT = `${prefix}/FETCH_CONTENT`;

export const clearData = () => ({
  type: CLEAR_DATA,
});

export const setTableData = data => ({
  type: SET_TABLE_DATA,
  data,
});

export const setActiveForm = data => ({
  type: SET_ACTIVE_FORM,
  data,
});

export const setFormData = data => ({
  type: SET_FORM_DATA,
  data,
});

export const updateFormData = data => ({
  type: UPDATE_FORM_DATA,
  data,
});

export const fetchTemplates = data => ({
  type: FETCH_TEMPLATES,
  data,
});

export const fetchTips = data => ({
  type: FETCH_TIPS,
  data,
});

export const fetchAlumStudentOutreachTemplates = data => ({
  type: FETCH_ALUM_STUDENT_OUTREACH_TEMPLATES,
  data,
});

export const setTableLoading = data => ({
  type: SET_TABLE_LOADING,
  data,
});

export const setTableKey = data => ({
  type: SET_TABLE_KEY,
  data,
});
