const enablementGroups = [
  {
    key: 'default',
    name: 'Default',
  },
  {
    key: 'standard',
    name: 'V2 | PG Essentials | New | Client',
    forStandard: true,
    defaultsTo: 'standard',
  },
  {
    key: 'opt_in',
    name: 'Classic | Client',
  },
  {
    key: 'sales',
    name: 'Classic | Sales & CS',
  },
  {
    key: 'internal',
    name: 'Classic | Internal',
  },
  {
    key: 'non_fpu_v2',
    name: 'V2 | PG Essentials | Upgraded | Client',
    forStandard: true,
    defaultsTo: 'transitioned-standard',
  },
  {
    key: 'plus_v2',
    name: 'V2 | PG + | New | Client',
    forStandard: true,
    defaultsTo: 'standard-plus',
  },
  {
    key: 'plus_fpu_v2',
    name: 'V2 | PG + | Upgraded | Client',
    forStandard: true,
    defaultsTo: 'standard-plus-fpu',
  },
  {
    key: 'internal_v2',
    name: 'V2 | PG Essentials | New | Internal',
    forStandard: true,
  },
  {
    key: 'plus_internal_v2',
    name: 'V2 | PG + | New | Internal',
    forStandard: true,
  },
  {
    key: 'sales_v2',
    name: 'V2 | PG Essentials | New | Sales & CS',
    forStandard: true,
  },
  {
    key: 'plus_sales_v2',
    name: 'V2 | PG + | New | Sales & CS',
    forStandard: true,
  },
  {
    key: 'trial_v2',
    name: 'V2 PG | Client Trials',
    forStandard: true,
  },
  {
    key: 'non_profit_v2',
    name: 'V2 | PG Non-Profit | New | Client',
    forStandard: true,
  },
  {
    key: 'non_profit_upgrade_v2',
    name: 'V2 | PG Non-Profit | Upgraded | Client',
    forStandard: true,
  },
  {
    key: 'student_success',
    name: 'Student Success Suite | Client',
    forStandard: true,
  },
  {
    key: 'alumni_community',
    name: 'Alumni Community Suite | Client',
    forStandard: true,
  },
  {
    key: 'all_in_one',
    name: 'All in one Suite | Client',
    forStandard: true,
  },
  {
    key: 'enrollment',
    name: 'Enrollment | Client',
  },
  {
    key: 'student_success_internal',
    name: 'Student Success Suite | Internal',
    forStandard: true,
  },
  {
    key: 'alumni_community_internal',
    name: 'Alumni Community Suite | Internal',
    forStandard: true,
  },
  {
    key: 'all_in_one_internal',
    name: 'All in one Suite | Internal',
    forStandard: true,
  },
  {
    key: 'enrollment_internal',
    name: 'Enrollment | Internal',
  },
  {
    key: 'student_success_sales',
    name: 'Student Success Suite | Sales & CS',
    forStandard: true,
  },
  {
    key: 'alumni_community_sales',
    name: 'Alumni Community Suite | Sales & CS',
    forStandard: true,
  },
  {
    key: 'all_in_one_sales',
    name: 'All in one Suite | Sales & CS',
    forStandard: true,
  },
  {
    key: 'enrollment_sales',
    name: 'Enrollment | Sales & CS',
  },
  {
    key: 'v2_automation',
    name: 'V2 | Automation | Internal | Do Not Delete',
    forStandard: true,
  },
  {
    key: 'v2_automation_1',
    name: 'V2 | Automation | Internal 1 | Do Not Delete',
    forStandard: true,
  },
  {
    key: 'classic_automation',
    name: 'Classic | Automation | Do Not Delete',
  },
  {
    key: 'to_be_deleted',
    name: 'To Be Deleted Group',
  },
];

export const getStandardEnablementGroups = () =>
  enablementGroups.filter(el => el.forStandard);

export const getStandardDefaultEnablementGroup = type => {
  const defaultLG = enablementGroups.filter(
    el => el.forStandard && el.defaultsTo === type
  );
  return defaultLG.length > 0 ? defaultLG[0].key : 'non_fpu_v2';
};

export default enablementGroups;
