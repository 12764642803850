import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Switch, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import {
  fetchProductUpdatePosts as fetchProductUpdatePostsAction,
  saveProductUpdatePost as saveProductUpdatePostAction,
} from '../../actions/productUpdates';
import classes from './ProductRelease.module.scss';
import { productUpdatePostPropType } from '../../reducers/productUpdates';
import FullPageLoading from '../../component/FullPageLoading';

function ProductUpdateTable({
  fetchProductUpdatePosts,
  saveProductUpdatePost,
  posts,
  loadingPosts,
  history,
  updatingPost,
}) {
  useEffect(() => {
    fetchProductUpdatePosts();
  }, [fetchProductUpdatePosts]);

  const onRowClick = record => {
    history.push(`/product-releases/edit/${record.id}`);
  };

  const handleCreate = () => {
    history.push(`/product-releases/new`);
  };

  const handleToggleActive = (
    event,
    { createdAt, createdBy, updatedAt, key, ...post }
  ) => {
    saveProductUpdatePost({ ...post, active: !post.active });
  };

  const sortedPosts = Object.values(posts)
    .map(post => ({
      ...post,
      key: post.id,
    }))
    .sort(
      (p1, p2) =>
        new Date(p2.createdAt).getTime() - new Date(p1.createdAt).getTime()
    );

  return (
    <div className={`ck-card ${classes['product-release-container']}`}>
      <div className={classes['row-justify']}>
        <h3>Product Release Posts</h3>
        {!loadingPosts && (
          <Button type="primary" onClick={handleCreate}>
            Create
          </Button>
        )}
      </div>
      {loadingPosts ? (
        // <Table>columns={columns}</Table>
        <FullPageLoading fontSize={30} />
      ) : (
        <Table
          dataSource={sortedPosts}
          columns={[
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: 'Set as active',
              dataIndex: 'active',
              key: 'active',
              render: (t, post) => (
                <Switch
                  onClick={e => handleToggleActive(e, post)}
                  size="small"
                  checked={t}
                  loading={updatingPost?.id === post.id}
                />
              ),
            },
            {
              title: 'Created At',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: t => moment(t).format('Do MMM YYYY, hh:mm A'),
            },
          ]}
          onRow={(record, rowIndex) => ({
            onClick: () => onRowClick(record, rowIndex),
          })}
          className={classes['banner-table']}
        />
      )}
    </div>
  );
}

ProductUpdateTable.propTypes = {
  posts: PropTypes.objectOf(productUpdatePostPropType).isRequired,
  loadingPosts: PropTypes.bool.isRequired,
  fetchProductUpdatePosts: PropTypes.func.isRequired,
  saveProductUpdatePost: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

const mapStateToProps = ({ productUpdates }) => {
  const { posts, loadingPosts, updatingPost } = productUpdates;
  return {
    posts,
    loadingPosts,
    updatingPost,
  };
};

const mapDispatchToProps = {
  fetchProductUpdatePosts: fetchProductUpdatePostsAction,
  saveProductUpdatePost: saveProductUpdatePostAction,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductUpdateTable)
);
