import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TemplateAndTips from './index';

const Routes = () => (
  <Switch>
    <Route exact path="/templates-and-tips" component={TemplateAndTips} />
    <Route exact path="/templates-and-tips/:view" component={TemplateAndTips} />
  </Switch>
);

export default Routes;
