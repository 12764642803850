import React from 'react';
import s from './Loader.module.scss';

const Loader = (props) => {
    return (
        <div className={s.loader__container}>
            <div className={s.loader__spinner}></div>
        </div>
    )
}

export default Loader;
