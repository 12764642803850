import React from 'react';
import { Modal } from 'antd';

const ConfirmCloseModal = ({ path, onCancel, onOk }) => {
  return (
    <Modal
      title={'Leave this page?'}
      closable={true}
      visible={!!path}
      cancelText="No"
      onCancel={onCancel}
      destroyOnClose={true}
      okText={'Yes'}
      onOk={() => onOk && onOk(path)}
    >
      <h3>
        If you leave this page, your unsaved changes will be lost.
        <br />
        Are you sure you want to leave this page?
      </h3>
    </Modal>
  );
};

export default ConfirmCloseModal;
