import classes from './accessManagement.module.scss';
import React, {PureComponent} from 'react';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { Tabs, Row, Col, Select, Input, Divider, Button, Pagination, Icon, Popconfirm, Spin, Checkbox, Tooltip } from 'antd';
const { Option } = Select;

const DEFAULT_CHECK_TOOLTIP = `
  Creating a default group make it non deletable. Mark a group 'default' if the group can be assigned to multiple people.
  (For example Engineering, Product etc can be default groups.) Other groups which are created to assign temporary permissions to people need not to be marked default.
`;
class TabRenderer extends PureComponent {

	constructor(props) {
		super(props);
    this.state = {
      newGroupName: '',
      newGroupIsDefault: false,
    }
	}

  getOptions = () => {
    const { rightColSelection = [], selectOnKey } = this.props;
    return (
      rightColSelection.map(g => {
        let id = g[selectOnKey];
        return (
          <Option key={`${id}`} label={g.name} value={`${id}`}>{g.name}</Option>
        )
      })
    )
  }

  shouldRender = (obj) => {
    const { fieldsToFilter } = this.props;
    let { filterText } = this.props;
    filterText = filterText.toLowerCase();
    let shouldRender = !filterText;
    if (fieldsToFilter && fieldsToFilter.length && obj && filterText) {
      fieldsToFilter.forEach(ff => {
        if (
          obj[ff] && 
          typeof obj[ff] === 'string' && 
          obj[ff].toLowerCase().indexOf(filterText) > -1
        ) {
          shouldRender = true;
        }
      });
    }
    return shouldRender;
  }

  onFilterTextChange = (e) => {
    const { onSearchFilter } = this.props;
    let val = e.target.value;
    onSearchFilter && onSearchFilter(val)
  }

  onNewGroupTextChange = (e) => {
    this.setState({ newGroupName: e.target.value });
  }

  onNewGroupDefaultChange = (e) => {
    this.setState({ newGroupIsDefault: e.target.checked });
  }

  getSelectedOptions = (obj) => {
    const { selectedOptionKey, selectOnKey, rightColSelection = [] } = this.props;
    if (obj && Array.isArray(obj[selectedOptionKey])) {
      let selectedOptions = obj[selectedOptionKey].filter(k=> rightColSelection.find(obj=> obj[selectOnKey] === k)).map(k => `${k}`)
      return selectedOptions;
    }
    return [];
  }

  onSelect = (index) => (v) => {
    let { leftColArray, selectedOptionKey, onSelect } = this.props;
    let values = v;
    if (Array.isArray(values)) {
      values = values.map(vl => {
        if (!isNaN(vl)) {
          return Number(vl)
        }
        return vl;
      })
    }
    leftColArray = cloneDeep(leftColArray);
    if (leftColArray && leftColArray[index]) {
      leftColArray[index][selectedOptionKey] = values;
    }
    onSelect && onSelect(leftColArray);
  }

  onDelete = (id) => () => {
    const { onDelete } = this.props;
    onDelete && onDelete(id);
  }

  onAdd = () => {
    const { onAdd } = this.props;
    const { newGroupName, newGroupIsDefault } = this.state;
    onAdd && onAdd({ name: newGroupName, isDefault: newGroupIsDefault });
  }

	render() {
    const { leftColArray = [], showAddNewBlock, saving, resultCount, currentPage, pageSize, showDelete, onPageChange, filterText, onSave, loading, addingGroup } = this.props
    const { newGroupName, newGroupIsDefault } = this.state;
		return (
			<div className={classes[`admins`]}>
        <Row>
          <Col span={8}>
            <Input placeholder={"Search"} value={filterText} onChange={this.onFilterTextChange} />
          </Col>
          <Col span={4}></Col>
          {showAddNewBlock && <Col span={12}>
            <div className={classes[`add-container`]}>
              <div className={classes[`name-cont`]}>
                <Input placeholder={"New Group Name"} onChange={this.onNewGroupTextChange} value={newGroupName} />
                <div className={classes[`chckbox`]}>
                  <Checkbox checked={newGroupIsDefault} onChange={this.onNewGroupDefaultChange}> Is Default? </Checkbox>
                  <Tooltip title={DEFAULT_CHECK_TOOLTIP}>
                    <Icon type="info-circle" />
                  </Tooltip>
                </div>
              </div>
              <Button type="primary" disabled={!newGroupName} onClick={this.onAdd} loading={addingGroup}>Add New Group</Button>
            </div>
          </Col>}
        </Row>
        <Divider />
        {loading && <Row type="flex" justify="center"><Spin /></Row>}
        {
          !loading && leftColArray.map((obj, ind) => {
            // if (this.shouldRender(obj)) {
            return (
              <Row key={obj.id}>
                <Col span={12}>
                  {obj.name}<br />
                  {obj.email && `(${obj.email})`}<br />
                  {!obj.email && obj.additionalEmails && `Emails on clusters (${obj.additionalEmails})`}<br />
                  {obj.name === 'Name not found' && obj.additionalNames && `Names on clusters (${obj.additionalNames})`}
                </Col>
                <Col span={12}>
                  <div className={classes[`select-container`]}>
                    <Select
                      optionFilterProp={'label'}
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      value={this.getSelectedOptions(obj)}
                      onChange={this.onSelect(ind)}
                    >
                      {this.getOptions()}
                    </Select>
                    {showDelete && !obj.isDefault && (
                      <Popconfirm
                        title="Are you sure to delete this group?"
                        onConfirm={this.onDelete(obj.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Icon style={{color: 'red'}} type="delete" />
                      </Popconfirm>
                    )}
                  </div>
                </Col>
              </Row>
            )
            // } else {
            //   return null;
            // }
          }).filter(e => !!e)
        }
        <Row type="flex" justify="end">
          <Pagination onChange={onPageChange} current={currentPage} total={resultCount} defaultPageSize={pageSize} />
        </Row>
        <Divider />
        <Row type="flex" justify="end">
          <Button type="primary" onClick={onSave} loading={saving}>Save</Button>
        </Row>
      </div>
		)
	}
}

const mapStateToProps = ({ accessManagement }) => ({
	// ...accessManagement
});

const mapDispatchToProps = {
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TabRenderer);