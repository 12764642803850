import { request } from '../utils/request';

export const fetchRequestedHubList = () =>
  request.get('/superadmin/list/get-requested-hub');

export const deleteRequestedHubById = (hubId) =>
  request.delete(`/superadmin/delete-requested-hub/${hubId}`);

  export const requestHubDeletionById = (data) =>
  request.put(`/superadmin/request-hub-deletion/${data.id}`, { reason: data.reason });

export const updateRequestedByHubId = body =>
  request.put(`/superadmin/approve-requested-hub/${body.id}`, body);
