import { takeLatest, put, call, select } from 'redux-saga/effects';
// import { message } from 'antd';
import { reduce } from 'lodash';

import {
  GET_PROCESS_HISTORIES,
  RUN_CRON_JOB,
  setProcessHistoriesAction,
  setCronJobLoadingAction,
  setCurrentModalAction,
  hubUrlsSelector,
} from '../actions';
import { fetchProcessHistories, runCronJobApi, hub } from '../../../api';
import { POPUP_ALERT } from '../../../actions/alert';

const { error: errLog } = console;

function* getProcessHistoriesSaga({ data: payload = {} }) {
  try {
    yield put(setProcessHistoriesAction({ loading: true }));
    const { data } = yield call(fetchProcessHistories, {
      ...payload,
      customStatus: true,
    });
    let hubUrls = (yield select(hubUrlsSelector)) || {};

    // Get hub urls of the processes
    const hubIds = reduce(
      data.processHistories,
      (prev, curr) => {
        if (curr.hub && !prev.includes(curr.hub) && !hubUrls[curr.hub]) {
          prev.push(curr.hub);
        }
        return prev;
      },
      []
    );
    if (hubIds && hubIds.length) {
      const { data: hubData } = yield call(hub.getAll, {
        fields: ['id', 'name', 'identifier', 'subdomain', 'domain'],
        id: hubIds,
      });
      hubUrls = reduce(
        hubData,
        (prev, curr) => {
          // eslint-disable-next-line no-param-reassign
          prev[curr.id] = {
            url: `https://${curr.domain}/hub/${curr.identifier}`,
            name: curr.name,
          };
          return prev;
        },
        hubUrls
      );
    }

    yield put(setProcessHistoriesAction({ ...data, hubUrls, loading: false }));
  } catch (e) {
    yield put(setProcessHistoriesAction({ loading: false }));
    errLog(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error getting process histories' },
    });
  }
}

function* runCronJobSaga({ data: { filters } }) {
  try {
    yield put(setCronJobLoadingAction(true));
    yield call(runCronJobApi, filters);
    yield put(setCronJobLoadingAction(false));
    yield put(setCurrentModalAction(null));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Cron job ran successfully' },
    });
  } catch (e) {
    yield put(setCronJobLoadingAction(false));
    errLog(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error running cron job' },
    });
  }
}

const ProcessAlertServiceSaga = [
  takeLatest(GET_PROCESS_HISTORIES, getProcessHistoriesSaga),
  takeLatest(RUN_CRON_JOB, runCronJobSaga),
];

export default ProcessAlertServiceSaga;
