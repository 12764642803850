import { useState, useRef, useEffect, useCallback } from 'react';

const useAsyncFetch = (shouldFetch, fetcher, initialPayload, config) => {
  const [isLoading, setLoading] = useState(shouldFetch);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState({});

  const memoizedFetcher = useRef((payload = initialPayload) => {
    setLoading(true);
    setSuccess(false);
    fetcher(payload)
      .then(response => {
        setResponse(response);
        setLoading(false);
        setSuccess(true);
        config?.onSuccess?.();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        config?.onError?.(error);
      });
  });

  useEffect(() => {
    if (shouldFetch) {
      memoizedFetcher.current();
    }

    return () => {
      setLoading(false);
      setResponse({});
      setSuccess(false);
      setError(null);
    };
  }, [shouldFetch]);

  const refetch = useCallback(payload => {
    memoizedFetcher.current(payload);
  }, []);

  return { isLoading, error, response, success, refetch };
};

export default useAsyncFetch;
