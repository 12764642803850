import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Form, Select, Spin } from 'antd';
import { debounce, find, pick, get, groupBy, extend, each } from 'lodash';
import {
  fetchHubsBySearch,
  fetchGroupsByHub,
  setSettings,
  setFieldInputsDisabled,
  setCopyModalVisibility,
  setGroupedUsertype,
  setSelectedHubIdToCopy,
  setVisibleToUserType,
  setSelectedGroup,
  setLogoUrl,
  setCoverPhotoUrl,
} from '../../actions/manage';
import { popupAlert } from '../../../../actions/alert';

const { Option } = Select;

class CopyFromProgramModal extends Component {
  fetchHubs = debounce(value => {
    const { fetchHubsBySearch } = this.props;
    fetchHubsBySearch(value);
  }, 300);

  handleSelectHubChange = hubId => {
    const { fetchGroupsByHub, setSelectedHubIdToCopy, match } = this.props;
    const { type } = match.params;
    setSelectedHubIdToCopy(hubId);
    fetchGroupsByHub({ hubId, type });
  };

  handleSelectGroupChange = groupId => {
    const { filteredGroupsByHub, setSelectedGroup } = this.props;
    const group = find(filteredGroupsByHub, {
      id: groupId,
    });
    setSelectedGroup(group);
  };

  handleVisibleToUserType = () => {
    const {
      filteredHubs,
      setGroupedUsertype,
      selectedHubId,
      setVisibleToUserType,
      selectedGroup,
    } = this.props;
    const hub = find(filteredHubs, { id: selectedHubId });
    if (hub) {
      const hubUserType = get(hub, 'settings.userTypes', {});
      const userTypes = [];
      Object.keys(hubUserType)
        .filter(
          key =>
            get(selectedGroup, 'groupSettings.visibleToUserType', []).findIndex(
              obj => obj.id === key
            ) > -1
        )
        .map(key => {
          userTypes.push({
            id: key,
            title: hubUserType[key].title,
            standardUserType: hubUserType[key].standardUserType,
          });
        });
      const groupedUserTypes = groupBy(userTypes, 'standardUserType');
      const visibleToUserType = [];
      Object.keys(groupedUserTypes).map(key => {
        if (groupedUserTypes[key] && groupedUserTypes[key].length === 1) {
          const userTypeId = groupedUserTypes[key][0].id;
          const visibleToUserTypeObj = find(
            selectedGroup.groupSettings.visibleToUserType,
            { id: userTypeId }
          );
          if (visibleToUserTypeObj) {
            visibleToUserType.push({
              id: userTypeId,
              name: groupedUserTypes[key][0].title,
              userType: visibleToUserTypeObj.userType,
              isVisibleToGroup: visibleToUserTypeObj.isVisibleToGroup,
              standardUserType: key,
            });
          }
          // Additional case to copy info if there are multiple values sharing same std usertype but they all have same option selected for them like mentee, mentor, both or userOpt
        } else if (groupedUserTypes[key] && groupedUserTypes[key].length > 1) {
          const uniqueUsertypeSet = new Set();
          each(groupedUserTypes[key], groupedUsertype => {
            const userTypeId = groupedUsertype.id;
            const visibleToUserTypeObj = find(
              selectedGroup.groupSettings.visibleToUserType,
              { id: userTypeId }
            );
            if (visibleToUserTypeObj) {
              uniqueUsertypeSet.add(visibleToUserTypeObj.userType);
            }
          });

          if (uniqueUsertypeSet.size === 1) {
            visibleToUserType.push({
              id: groupedUserTypes[key][0].id,
              name: groupedUserTypes[key][0].title,
              userType: uniqueUsertypeSet.values().next().value,
              isVisibleToGroup: true,
              standardUserType: key,
            });
          }
        }
      });
      setVisibleToUserType(visibleToUserType);
      setGroupedUsertype(groupedUserTypes);
    }
  };

  handleCopyOk = () => {
    const {
      setCopyModalVisibility,
      setFieldInputsDisabled,
      setSettings,
      popupAlert,
      selectedGroup,
      match,
      filteredHubs,
      selectedHubId,
      setLogoUrl,
      setCoverPhotoUrl,
    } = this.props;
    if (!selectedGroup || !selectedGroup.groupSettings) {
      popupAlert({
        type: 'error',
        message: `Please select group/program to copy.`,
      });
      return;
    }

    if (filteredHubs && selectedHubId) {
      const hub = find(filteredHubs, { id: selectedHubId });
      if (hub) {
        selectedGroup['hub_name'] = hub.name;
      }
    }

    if (match && match.params.type === 'program') {
      this.handleVisibleToUserType();
    }

    // TODO: More to Add
    const itemsToPick = [
      'allowBoth',
      'defaultTab',
      'publicPage',
      'showAdmins',
      'recCustomText',
      'recCustomTextVisible',
      'defaultEducationDetail',
      'messageTypes',
      'disableBulkEmailTab',
      'showBulkMatchingTab',
      'skipNetworkOptIn',
      'visibleTabs',
      'linkedEvents',
    ];
    const messageTypesToPick = ['flashMentorship', 'message'];

    if (match.params && match.params.type === 'program') {
      itemsToPick.push('showFavoriteButton');
      itemsToPick.push('mentorshipSettings');
      messageTypesToPick.push('longTermMentorship');
    }

    if (match.params && match.params.type === 'group') {
      itemsToPick.push('adminsLimit');
    }

    const basicSettings = pick(selectedGroup, [
      'public',
      'cover_background_color',
    ]);
    basicSettings.coverBackgroundColor = basicSettings.cover_background_color;
    delete basicSettings.cover_background_color;
    const settings = pick(selectedGroup.groupSettings, itemsToPick);
    settings.messageTypes = pick(
      get(selectedGroup, 'groupSettings.messageTypes', {}),
      messageTypesToPick
    );

    settings['defaultTab'] = settings['defaultTab'] ?? 'about';
    settings['allowBoth'] = settings['allowBoth'] ?? false;
    settings['publicPage'] = settings['publicPage'] ?? false;
    settings['skipNetworkOptIn'] = settings['skipNetworkOptIn'] ?? false;
    if (match.params && match.params.type === 'group') {
      settings['adminsLimit'] = settings['adminsLimit'] ?? 2;
    }
    if (selectedGroup.logo_url) {
      setLogoUrl(selectedGroup.logo_url);
    }

    if (selectedGroup.cover_photo_url) {
      setCoverPhotoUrl(selectedGroup.cover_photo_url);
    }

    setSettings(extend({}, settings, basicSettings));
    setFieldInputsDisabled(false);
    setCopyModalVisibility(false);
  };

  handleCopyCancel = () => {
    const { setCopyModalVisibility } = this.props;
    setCopyModalVisibility(false);
  };

  render() {
    const {
      filteredHubs,
      filteredHubsLoading,
      filteredGroupsByHub,
      filteredGroupsByHubLoading,
      copyModalVisible,
      match,
    } = this.props;
    const { type } = match.params;

    return (
      <Modal
        title={`Copy from Other ${type === 'group' ? 'Group' : 'Program'}`}
        visible={copyModalVisible}
        onOk={this.handleCopyOk}
        onCancel={this.handleCopyCancel}
        okText="Add"
        cancelText="Cancel"
        className="copy-modal"
      >
        <Form>
          <Form.Item label="Select Hub" className="ant-form-item">
            <Select
              mode="single"
              notFoundContent={
                filteredHubsLoading ? <Spin size="small" /> : null
              }
              onSearch={this.fetchHubs}
              onChange={this.handleSelectHubChange}
              style={{ width: '100%' }}
              showSearch
              placeholder="Choose a hub"
              style={{ width: '100%' }}
              optionFilterProp="children"
              showArrow={false}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {(filteredHubs ?? []).map(hub => (
                <Option value={hub.id} key={hub.id}>
                  {hub.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={`Select ${type === 'group' ? 'Group' : 'Program'}`}
            className="ant-form-item"
          >
            <Select
              showSearch
              mode="single"
              placeholder={`Choose a ${type}`}
              style={{ width: '100%' }}
              onChange={this.handleSelectGroupChange}
              loading={filteredGroupsByHubLoading}
              disabled={filteredGroupsByHub && filteredGroupsByHub.length === 0}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {(filteredGroupsByHub ?? []).map(group => (
                <Option value={group.id} key={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const {
    filteredHubs,
    filteredHubsLoading,
    filteredGroupsByHub,
    filteredGroupsByHubLoading,
    copyModalVisible,
    selectedHubId,
    selectedGroup,
  } = groupsManage;
  return {
    filteredHubs,
    filteredHubsLoading,
    filteredGroupsByHub,
    filteredGroupsByHubLoading,
    copyModalVisible,
    selectedHubId,
    selectedGroup,
  };
};

const mapDispatchToProps = {
  fetchHubsBySearch,
  fetchGroupsByHub,
  setSettings,
  setFieldInputsDisabled,
  setCopyModalVisibility,
  popupAlert,
  setGroupedUsertype,
  setSelectedHubIdToCopy,
  setVisibleToUserType,
  setSelectedGroup,
  setLogoUrl,
  setCoverPhotoUrl,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CopyFromProgramModal)
);
