import { get } from 'lodash';
import { message } from 'antd';
import { TEST_REPORT_QUERY, SUBMIT_REPORT_CHANGES } from '../containers/AnalyticsReport/actions';
import { SAVE_ANALYTICS_QUERY, SAVE_ASSOCIATED_CHART, UPDATE_ANALYTICS_QUERY, SET_ANALYTICS_QUERY_ACTIVE, UPDATE_ASSOCIATED_CHART } from '../actions/analyticsQuery';
import { OPEN_HUB } from '../actions/clusterHubs';

const rules = [
  {
    blockActions: [TEST_REPORT_QUERY, SUBMIT_REPORT_CHANGES, SAVE_ANALYTICS_QUERY, SAVE_ASSOCIATED_CHART, UPDATE_ANALYTICS_QUERY, SET_ANALYTICS_QUERY_ACTIVE, UPDATE_ASSOCIATED_CHART],
    if: (adminAccessGroups = []) => adminAccessGroups.indexOf('analytics-reports-readonly') > -1 && adminAccessGroups.indexOf('analytics-reports') < 0,
  },
  {
    blockActions: [OPEN_HUB],
    if: (adminAccessGroups = []) => adminAccessGroups.indexOf('hub-logins') < 0,
  }
];

export const actionRestrictionMiddleware = store => next => action => {
  // Ideally the page should not be visible if the superadmin doesn't have permission of that page.
  // But in some cases we may need function level permission.
  // In that case, this middleware can be used which will block the certain redux action from executing further if admin doesn't have permission.
  // Just add a rule in rules array.
  let state = store.getState();
  const adminAccessGroups = get(state, 'admin.adminAccessGroups', []);
  let blockActions = [];
  rules.forEach((rule) => {
    if (typeof rule.if === 'function' && rule.if(adminAccessGroups)) {
      blockActions = [...blockActions, ...rule.blockActions];
    }
  })
  if (adminAccessGroups.indexOf('full-access') < 0 && blockActions.indexOf(get(action, 'type')) > -1) {
    next(action);
    // message.error('You are not allowed to perform this action.');
    return;
  } else {
    next(action);
  }
}
