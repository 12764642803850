export const FETCH_ALL_SETTINGS = '/FeatureManagement/FETCH_ALL_SETTINGS';
export const FETCH_LAUNCH_GROUP_HUBS = '/FeatureManagement/FETCH_LAUNCH_GROUP_HUBS';
export const SET_GROUP_HUB_FIELDS = '/FeatureManagement/SET_GROUP_HUB_FIELDS';
export const FETCH_GROUP_HUBS_COUNT = '/FeatureManagement/FETCH_GROUP_HUBS_COUNT';
export const SET_GROUP_HUBS_COUNT = '/FeatureManagement/SET_GROUP_HUBS_COUNT';
export const START_DOWNLOAD = '/FeatureManagement/START_DOWNLOAD';
export const RESET_TO_DEFAULT = '/FeatureManagement/RESET_TO_DEFAULT';
export const SET_ALL_SETTINGS = '/FeatureManagement/SET_ALL_SETTINGS';
export const SET_AFFECTED_FEATURE = '/FeatureManagement/SET_AFFECTED_FEATURE';
export const SET_AFFECTED_HUB_FEATURE = '/FeatureManagement/SET_AFFECTED_HUB_FEATURE';
export const SET_ALL_SETTINGS_CLONE =
  '/FeatureManagement/SET_ALL_SETTINGS_CLONE';
export const SET_MODULES_LIST = '/FeatureManagement/SET_MODULES_LIST';
export const SET_PAGE_LOADING = '/FeatureManagement/SET_PAGE_LOADING';
export const UPDATE_LAUNCH_GROUP_SETTINGS =
  '/FeatureManagement/UPDATE_LAUNCH_GROUP_SETTINGS';
export const SET_IS_PAGE_UPDATING = '/FeatureManagement/SET_IS_PAGE_UPDATING';
export const SET_SETTINGS_UPDATED = '/FeatureManagement/SET_SETTINGS_UPDATED';

export const LOG_ERROR = '/FeatureManagement/LOG_ERROR';

export const fetchAllSettings = data => ({
  type: FETCH_ALL_SETTINGS,
  data,
});

export const fetchLaunchGroupHubs = data => ({
  type: FETCH_LAUNCH_GROUP_HUBS,
  data
})

export const startDownload = data => ({
  type: START_DOWNLOAD,
  data
})

export const resetToDefault = data => ({
  type: RESET_TO_DEFAULT,
  data,
})

export const fetchGroupHubsCount = data => ({
  type: FETCH_GROUP_HUBS_COUNT,
  data,
})

export const setAllSettings = data => ({
  type: SET_ALL_SETTINGS,
  data,
});

export const setAffectedFeature = data => ({
  type: SET_AFFECTED_FEATURE,
  data,
});

export const setAffectedHubFeature = data => ({
  type: SET_AFFECTED_HUB_FEATURE,
  data,
});

export const setAllSettingsClone = data => ({
  type: SET_ALL_SETTINGS_CLONE,
  data,
});

export const setModulesList = data => ({
  type: SET_MODULES_LIST,
  data,
});

export const updateLaunchGroupSettings = data => ({
  type: UPDATE_LAUNCH_GROUP_SETTINGS,
  data,
});

export const setSettingsUpdated = data => ({
  type: SET_SETTINGS_UPDATED,
  data,
});

export const logError = data => ({
  type: LOG_ERROR,
  data,
});

// Selectors

export const settingsSelector = ({ features }) => features.settings;
