import { SET_CLUSTER_MGMT, SET_LAUNCHGROUPS } from './actions';

const initialState = {
  allClusters: [],
  clusters: null,
  clustersLoading: false,
  saveModalVisible: false,
  clusterConfigsLoading: false,
  clusterConfigs: null,
  editCluster: null,
  modulesList: [],
  filtersEnabled: false,
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_CLUSTER_MGMT:
      return { ...state, ...data };
    case SET_LAUNCHGROUPS:
      return { ...state, ...data };
    default:
      return state;
  }
};
