import React, { useState } from 'react';

function NotificationAlert({ message = '' }) {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible && (
        <div className="usertype-alert">
          <span className="usertype-alert__message">{message}</span>
          <button
            onClick={() => setVisible(false)}
            type="button"
            className="usertype-alert__btn"
          >
            <img
              src="https://cdn.peoplegrove.com/admin/1616085830446pg-times.svg"
              alt="close"
            />
          </button>
        </div>
      )}
    </>
  );
  // return (
  //   <>
  //     {visible && (
  //       <Alert
  //         message={message}
  //         type={type}
  //         closable
  //         afterClose={() => setVisible(false)}
  //         style={{ minHeight: '48px', marginBottom: '16px' }}
  //       />
  //     )}
  //   </>
  // );
}

export default NotificationAlert;
