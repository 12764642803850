import React from 'react';

import LLMRecommendations from './LLMRecommendations';
import MLRecommendations from './MLRecommendations';
import UserConnections from './UserConnections';

const APIPayload = ({ currentMLService, ...props }) => {
  if (currentMLService === 'pg_ml_llm') {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <LLMRecommendations {...props} />;
  } else if (currentMLService === 'recommendation') {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MLRecommendations {...props} />;
  } else if (currentMLService === 'userConnection') {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <UserConnections {...props} />;
  }
  return null;
};

export default APIPayload;
