import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { map, get, upperFirst, filter, find, forEach } from 'lodash';
import { notify } from '../../../utils/notify';

import { setData } from '../actions';

import '../index.scss';

class HubSelectionModal extends React.Component {
  saveLaunchInWithNotify = () => {
    const { saveLaunchIn, hubsNoFilter, selectedGroups } = this.props;
    let { selectedHubs } = this.props;

    if (selectedHubs.length) {
      forEach(hubsNoFilter, hubIds => {
        selectedHubs = filter(selectedHubs, hub => !hubIds.includes(hub.key));
      });

      if (!selectedHubs.length) {
        return notify('No hubs qualified for launch.', 'error');
      }
    }

    saveLaunchIn({ selectedGroups, selectedHubs });

    if (selectedHubs.length)
      return notify(
        `${selectedHubs.length} hubs qualified for launch.`,
        'success'
      );

    return notify('No hubs were selected for launch.', 'success');
  };

  toggleModal = onSubmit => {
    const { setData } = this.props;

    if (onSubmit) {
      this.saveLaunchInWithNotify();
    }

    setData({
      showHubSelectionWarningModal: false,
    });
  };

  renderHubNoFilter = () => {
    const { allFilters, selectedHubs, hubsNoFilter } = this.props;

    return map(
      hubsNoFilter,
      (hubIds, filterLabel) =>
        hubIds.length > 0 && (
          <>
            <div className="provisioning_launch_modal__header">
              <div>
                Filter:{' '}
                {get(find(allFilters, { value: filterLabel }), 'name', '')}
              </div>
              <div className="header-warning">
                {hubIds.length} Hubs not matching
              </div>
            </div>

            <div className="provisioning_launch_modal__list">
              {map(hubIds, hubId => (
                <div className="provisioning_launch_modal__hub">
                  <span className="hub_name">
                    {upperFirst(
                      get(find(selectedHubs, { key: hubId }), 'label', '')
                        .toString()
                        .replace(/,/g, '')
                    )}
                  </span>
                </div>
              ))}
            </div>
          </>
        )
    );
  };

  render() {
    const { showHubSelectionWarningModal } = this.props;

    return (
      <Modal
        width={650}
        visible={showHubSelectionWarningModal}
        wrapClassName="confirm_hub_filters"
        className="provisioning_launch_modal confirm_hub_filters"
        title="Warning! Pathway could not be assigned to all hubs."
        destroyOnClose
        onCancel={() => this.toggleModal()}
        footer={[
          <Button key="cancel" onClick={() => this.toggleModal()}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="danger"
            disabled={false}
            onClick={() => this.toggleModal(true)}
          >
            Continue
          </Button>,
        ]}
      >
        <h3>
          Hub Specific filter tags did not match all selected hubs.
          <br />
        </h3>
        <h3>
          <b>Cancel</b> to go back and update the Hub Specific filters or{' '}
          <b>Continue</b> to remove the following listed hubs from launch and
          launch to all the remaining hubs.
        </h3>

        {this.renderHubNoFilter()}
      </Modal>
    );
  }
}

const mapStateToProps = ({
  PathwaysProvisioning: {
    showHubSelectionWarningModal,
    selectedGroups,
    selectedHubs,
    filterData: { allFilters },
    hubsNoFilter,
  },
}) => ({
  showHubSelectionWarningModal,
  selectedGroups,
  selectedHubs,
  allFilters,
  hubsNoFilter,
});

const mapDispatchToProps = {
  setData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HubSelectionModal);
