export const FETCH_PRODUCT_UPDATE_POSTS = 'FETCH_PRODUCT_UPDATE_POSTS';
export const FETCH_INDV_PRODUCT_UPDATE_POST = 'FETCH_INDV_PRODUCT_UPDATE_POST';
export const SET_PRODUCT_UPDATE_POSTS_LOADING =
  'SET_PRODUCT_UPDATE_POSTS_LOADING';
export const SET_PRODUCT_UPDATE_POSTS = 'SET_PRODUCT_UPDATE_POSTS';
export const SET_PRODUCT_UPDATE_POST_UPDATING =
  'SET_PRODUCT_UPDATE_POST_UPDATING';
export const SAVE_PRODUCT_UPDATE_POST = 'SAVE_PRODUCT_UPDATE_POST';
export const SET_PRODUCT_UPDATE_POST_SAVING = 'SET_PRODUCT_UPDATE_POST_SAVING';
export const DELETE_PRODUCT_UPDATE_POST = 'DELETE_PRODUCT_UPDATE_POST';

export const setProductUpdatePostsLoading = loading => ({
  type: SET_PRODUCT_UPDATE_POSTS_LOADING,
  data: loading,
});

export const setProductUpdatePosts = posts => ({
  type: SET_PRODUCT_UPDATE_POSTS,
  data: posts,
});

export const setProductUpdateUpdating = data => ({
  type: SET_PRODUCT_UPDATE_POST_UPDATING,
  data,
});

export const fetchProductUpdatePosts = () => ({
  type: FETCH_PRODUCT_UPDATE_POSTS,
});

export const saveProductUpdatePost = data => ({
  type: SAVE_PRODUCT_UPDATE_POST,
  data,
});

export const fetchIndvProductUpdatePost = data => ({
  type: FETCH_INDV_PRODUCT_UPDATE_POST,
  data,
});

export const deleteProductUpdatePost = data => ({
  type: DELETE_PRODUCT_UPDATE_POST,
  data,
});

export const productUpdatePosts = state => state.productUpdates.posts;
