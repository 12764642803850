import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { setCancelModalVisibility } from '../../actions/manage';

class CancelModal extends Component {
  handleCancelOk = () => {
    const { history, match, setCancelModalVisibility, manageType } = this.props;
    const { type } = match.params;

    if (history.action === 'PUSH') history.goBack();
    else history.push(`/groups-programs-tier/manage-${manageType}/${type}`);

    setCancelModalVisibility(false);
  };

  handleOnCancel = () => {
    const { setCancelModalVisibility } = this.props;
    setCancelModalVisibility(false);
  };

  renderFooter = () => (
    <div>
      <Button onClick={this.handleCancelOk}>Don&apos;t Save</Button>
    </div>
  );

  renderBody = () => {
    const { manageType } = this.props;

    return (
      <p>
        Are you sure you don’t want to save this
        {` ${manageType}?`}
      </p>
    );
  };

  render() {
    const { cancelModalVisible, manageType } = this.props;

    return (
      <Modal
        title={`Save ${manageType === 'tier' ? 'Tier' : 'Template'}`}
        visible={cancelModalVisible}
        onCancel={this.handleOnCancel}
        footer={this.renderFooter()}
        destroyOnClose
        className="confirmation-modal"
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const { cancelModalVisible, manageType } = groupsManage;
  return {
    cancelModalVisible,
    manageType,
  };
};

const mapDispatchToProps = {
  setCancelModalVisibility,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CancelModal)
);
