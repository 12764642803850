import React from 'react';

const EmptyTier = ({ goToManageTiers }) => (
  <div className="update-configuration__message">
    <p>
      Please go to <a onClick={goToManageTiers}>Manage tiers</a> to
      associate this hub to one or more tiers first and then retry
      update configuration.{' '}
    </p>
  </div>
);

export default EmptyTier;