import React from 'react';
import moment from 'moment';
import { Button } from 'antd';

const columns = handleRestartClick => [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: '20%',
    render: (title, record) => {
      return (
        <span>
          {record.id} - {title}
        </span>
      );
    },
  },
  {
    title: 'Hub',
    dataIndex: 'hub_id',
    key: 'hub_id',
    width: '20%',
    render: (hub_id, record) => {
      return (
        <span>
          {record.hubName} ({hub_id})
        </span>
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '15%',
  },
  {
    title: 'CreatedAt',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '20%',
    render: created_at => {
      return <span>{moment(created_at).format('MMM Do YYYY, h:mm:ss a')}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    render: status => {
      return <span style={{ textTransform: 'capitalize' }}>{status}</span>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    width: '10%',
    render: (text, record) => {
      return (
        <Button
          icon="redo"
          type="primary"
          onClick={handleRestartClick(record.id, record.status)}
          disabled={
            record.restartObj &&
            ['pending', 'processing'].indexOf(record.restartObj.status) > -1
          }
        >
          Refresh
        </Button>
      );
    },
  },
];

export default columns;
