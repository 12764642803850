import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import history from '../../../history';

import {
  FETCH_GROUP_TIERS,
  FETCH_GROUP_TIER_BASED_ON_ID,
  SAVE_TIER_DATA,
  DELETE_TIER,
  FETCH_GROUP_TEMPLATES,
  FETCH_GROUP_TEMPLATE_BASED_ON_ID,
  FETCH_HUBS_BY_SEARCH,
  FETCH_GROUPS_BY_HUB,
  FETCH_ACTIVE_TIERS,
  SAVE_TEMPLATE_DATA,
  DELETE_TEMPLATE,
  // action creators
  setManageData,
  setManageLoading,
  setManageDataCount,
  setManageDataPageSize,
  setManageSingleLoading,
  setFilteredHubs,
  setFilteredHubsLoading,
  setFilteredGroupsByHub,
  setFilteredGroupsByHubLoading,
  setActiveTiers,
  setActiveTiersLoading,
  setName,
  setInternalName,
  setDescription,
  setImage,
  setIsActive,
  setObjectId,
  setGroupTier,
  setLogoUrl,
  setCoverPhotoUrl,
  setSettings,
  setFieldInputsDisabled,
  setCanDeleteTier,
  setVisibleToUserType,
  setGroupedUsertype,
  setManageSaveLoading,
  setSelectedAssociatedGroupCount,
  // action selectors
  manageData,
  setSelectedGroup,
  setSelectedHubIdToCopy,
  manageDataPage,
} from '../actions/manage';
import { POPUP_ALERT } from '../../../actions/alert';

import {
  fetchAllGroupTierApi,
  fetchGroupTierByIdApi,
  updateTierApi,
  createTierApi,
  deleteTierApi,
} from '../../../api/groupsProgramTierApi';

import {
  fetchAllGroupTemplateApi,
  fetchGroupTemplateByIdApi,
  fetchHubsBySearchApi,
  fetchGroupsByHubApi,
  fetchActiveGroupTiersApi,
  createTemplateApi,
  updateTemplateApi,
  deleteTemplateApi,
} from '../../../api/groupsProgramTemplateApi';

import { get, groupBy } from 'lodash';

export default [
  takeLatest(FETCH_GROUP_TIERS, fetchGroupTierSaga),
  takeLatest(FETCH_GROUP_TIER_BASED_ON_ID, fetchGroupTierByIdSaga),
  takeLatest(SAVE_TIER_DATA, saveTierDataSaga),
  takeLatest(DELETE_TIER, deleteTierSaga),
  takeLatest(FETCH_GROUP_TEMPLATES, fetchGroupTemplateSaga),
  takeLatest(FETCH_GROUP_TEMPLATE_BASED_ON_ID, fetchGroupTemplateByIdSaga),
  takeLatest(FETCH_HUBS_BY_SEARCH, fetchHubsBySearchSaga),
  takeLatest(FETCH_GROUPS_BY_HUB, fetchGroupsByHubSaga),
  takeLatest(FETCH_ACTIVE_TIERS, fetchActiveTiersSaga),
  takeLatest(SAVE_TEMPLATE_DATA, saveTemplateDataSaga),
  takeLatest(DELETE_TEMPLATE, deleteTemplateSaga),
];

function* fetchGroupTierSaga({ data: type = 'group' }) {
  try {
    let page = yield select(manageDataPage);
    if (!page) page = 1;
    yield put(setManageLoading(true));
    const { data } = yield call(fetchAllGroupTierApi, { type, page });
    yield put(setManageData(data.data));
    yield put(setManageDataCount(data.count));
    yield put(setManageDataPageSize(data.pageSize));
    yield put(setManageLoading(false));
  } catch (e) {
    console.log(e);
  }
}

function* fetchGroupTierByIdSaga({ data: id }) {
  try {
    yield put(setManageSingleLoading(true));
    const { data } = yield call(fetchGroupTierByIdApi, id);
    if (data) {
      yield all([
        put(setName(data.name)),
        put(setDescription(data.description)),
        put(setImage(data.image)),
        put(setObjectId(data.objectId)),
        put(setFilteredHubs(data.hubsAssociated)),
        put(setIsActive(data.isActive)),
        put(setCanDeleteTier(data.canDelete)),
      ]);
    }
    yield put(setManageSingleLoading(false));
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Tier Data.' },
    });
  }
}

function* saveTierDataSaga({ data: params }) {
  try {
    const newManageData = yield select(manageData);
    yield put(setManageSaveLoading(true));
    if (params.id) {
      // Update
      const { data } = yield call(updateTierApi, params);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Tier Updated!' },
      });

      for (let i = 0; i < newManageData.length; ++i) {
        if (newManageData[i].id === data.id) {
          newManageData[i] = data;
          break;
        }
      }

      yield put(setManageData(newManageData));
      yield put(setManageSaveLoading(false));
      history.push(`/groups-programs-tier/manage-tier/${data.type}`);
    } else {
      // Create
      const { data } = yield call(createTierApi, params);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Tier Created!' },
      });

      yield put(setManageSaveLoading(false));
      history.push(`/groups-programs-tier/manage-tier/${data.type}`);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error saving data' },
    });
  }
}

function* deleteTierSaga({ data: id }) {
  try {
    const oldManageData = yield select(manageData);
    const { data } = yield call(deleteTierApi, id);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Tier Deleted!' },
    });
    const newManageData = oldManageData.filter(d => d.id !== id);
    yield put(setManageData(newManageData));
    history.push(`/groups-programs-tier/manage-tier/${data.type}`);
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error deleteing tier' },
    });
  }
}

function* fetchGroupTemplateSaga({ data: type = 'group' }) {
  try {
    let page = yield select(manageDataPage);
    if (!page) page = 1;
    yield put(setManageLoading(true));
    const { data } = yield call(fetchAllGroupTemplateApi, { type, page });
    yield put(setManageData(data.data));
    yield put(setManageDataCount(data.count));
    yield put(setManageDataPageSize(data.pageSize));
    yield put(setManageLoading(false));
  } catch (e) {
    console.log(e);
  }
}

function* fetchGroupTemplateByIdSaga({ data: id }) {
  try {
    yield put(setManageSingleLoading(true));
    const { data } = yield call(fetchGroupTemplateByIdApi, id);
    if (data) {
      const visibleToUserType = get(data, 'settings.visibleToUserType', []);
      const modifySettings = data.settings;
      if (visibleToUserType && visibleToUserType.length > 0) {
        delete modifySettings.visibleToUserType;
        const groupedUserTypes = groupBy(visibleToUserType, 'standardUserType');
        yield put(setGroupedUsertype(groupedUserTypes));
        yield put(setVisibleToUserType(visibleToUserType));
      }
      yield all([
        put(setName(data.name)),
        put(setInternalName(data.internalName)),
        put(setDescription(data.description)),
        put(setGroupTier(data.groupTier && data.groupTier.id)),
        put(setLogoUrl(data.logoUrl)),
        put(setCoverPhotoUrl(data.coverPhotoUrl)),
        put(setSettings(modifySettings)),
        put(setFieldInputsDisabled(false)),
        put(setSelectedGroup(data.selectedGroup)),
        put(setSelectedAssociatedGroupCount(data.associatedGroupCount)),
      ]);
    }
    yield put(setManageSingleLoading(false));
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Template Data.' },
    });
  }
}

function* fetchHubsBySearchSaga({ data: searchText }) {
  try {
    if (!searchText || !searchText.trim()) {
      yield put(setFilteredHubs([]));
    } else {
      yield put(setFilteredHubsLoading(true));
      yield put(setSelectedGroup(null));
      const { data } = yield call(fetchHubsBySearchApi, searchText);
      yield put(setFilteredHubs(data));
      yield put(setFilteredHubsLoading(false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchGroupsByHubSaga({ data: params }) {
  try {
    if (!params || !params.hubId) {
      yield put(setFilteredGroupsByHub([]));
    } else {
      yield put(setFilteredGroupsByHubLoading(true));
      const { data } = yield call(
        fetchGroupsByHubApi,
        params.hubId,
        params.type
      );
      yield put(setFilteredGroupsByHub(data));
      if (data && data.length === 0) yield put(setSelectedGroup(null));
      yield put(setFilteredGroupsByHubLoading(false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchActiveTiersSaga({ data: type }) {
  try {
    yield put(setActiveTiersLoading(true));
    const { data } = yield call(fetchActiveGroupTiersApi, type);
    yield put(setActiveTiers(data));
    yield put(setActiveTiersLoading(false));
  } catch (e) {
    console.log(e);
  }
}

function* saveTemplateDataSaga({ data: params }) {
  try {
    const newManageData = yield select(manageData);
    yield put(setManageSaveLoading(true));
    if (params.id) {
      // Update
      const { data } = yield call(updateTemplateApi, params);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Template Updated!' },
      });

      for (let i = 0; i < newManageData.length; ++i) {
        if (newManageData[i].id === data.id) {
          newManageData[i] = data;
          break;
        }
      }

      yield put(setManageData(newManageData));
      yield put(setManageSaveLoading(false));
      history.push(`/groups-programs-tier/manage-template/${data.type}`);
    } else {
      // Create
      const { data } = yield call(createTemplateApi, params);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Template Created!' },
      });
      yield put(setManageSaveLoading(false));
      history.push(`/groups-programs-tier/manage-template/${data.type}`);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error saving data' },
    });
  }
}

function* deleteTemplateSaga({ data: id }) {
  try {
    const oldManageData = yield select(manageData);
    const { data } = yield call(deleteTemplateApi, id);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Template Deleted!' },
    });
    const newManageData = oldManageData.filter(d => d.id !== id);
    yield put(setManageData(newManageData));
    history.push(`/groups-programs-tier/manage-template/${data.type}`);
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error deleteing template' },
    });
  }
}
