export const LIST_MODULE_TEMPLATES = 'LIST_MODULE_TEMPLATES';
export const SAVE_MODULE_TEMPLATES = 'SAVE_MODULE_TEMPLATES';
export const PATHWAY_SET_DATA = 'PATHWAY_SET_DATA';
export const GET_HUBS_PATHWAYS = 'GET_HUBS_PATHWAYS';
export const SAVE_NEW_TEMPLATE = 'SAVE_NEW_TEMPLATE';
export const FIND_ONE_TEMPLATE = 'FIND_ONE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const SET_LOADING = 'SET_LOADING';
export const GET_PROVISIONING_FILTERS = 'GET_PROVISIONING_FILTERS';

export const setData = data => ({
  type: PATHWAY_SET_DATA,
  data,
});

export const fetchModuleTemplates = data => ({
  type: LIST_MODULE_TEMPLATES,
  data,
});

export const saveModuleTemplates = data => ({
  type: SAVE_MODULE_TEMPLATES,
  data,
});

export const getHubsPathways = () => ({
  type: GET_HUBS_PATHWAYS,
});

export const saveNewTemplate = data => ({
  type: SAVE_NEW_TEMPLATE,
  data,
});

export const findTemplateById = data => ({
  type: FIND_ONE_TEMPLATE,
  data,
});

export const setLoading = data => ({
  type: SET_LOADING,
  data,
});

export const deleteTemplate = data => ({
  type: DELETE_TEMPLATE,
  data,
});

export const getProvisioningFilters = () => ({
  type: GET_PROVISIONING_FILTERS,
});

// SELECTORS
export const getDefaultPathwayState = ({ PathwaysProvisioning }) =>
  PathwaysProvisioning;
