import { takeEvery } from 'redux-saga/effects';

import {
  GET_HUBS_DATA,
  UPDATE_INVITED_BA_HUBS,
  GET_ALL_INVITED_BA_HUBS,
  DOWNLOAD_CSV_DATA_BA_HUBS,
} from '../actions';

import {
  fetchAllHubsOfCluster,
  updateBAInvitedHubs,
  getAllBaInvitedHubs,
  getAllBaInvitedHubsDownloadCSV,
} from './sagas';

export default [
  takeEvery(GET_HUBS_DATA, fetchAllHubsOfCluster),
  takeEvery(UPDATE_INVITED_BA_HUBS, updateBAInvitedHubs),
  takeEvery(GET_ALL_INVITED_BA_HUBS, getAllBaInvitedHubs),
  takeEvery(DOWNLOAD_CSV_DATA_BA_HUBS, getAllBaInvitedHubsDownloadCSV),
];
