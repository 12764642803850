import { notification } from 'antd';
import { get } from 'lodash';

export const notify = (messageToShow, type, descriptionToShow) => {
  notification.config({
    placement: 'bottomRight',
  });
  notification[type]({
    message: messageToShow,
    description: descriptionToShow,
  });
};

export default notify;

export const notifyError = (
  e,
  hideNotification = false,
  defaultErrorMessage = 'An error occurred, please try again.'
) => {
  const developerMessage =
    get(e, 'response.data.error.developer_message') ||
    get(e, 'error.developer_message') ||
    get(e, 'response.data.error') ||
    get(e, 'response.data');
  const userMessage =
    get(e, 'response.data.error.user_message') ||
    get(e, 'error.user_message', defaultErrorMessage);
  if (!hideNotification) notify(userMessage, 'error');
  // eslint-disable-next-line no-console
  console.error(developerMessage || e);
};
