const prefix = `GPT-Manage`;
export const FETCH_GROUP_TIERS = `${prefix}/FETCH_GROUP_TIERS`;
export const FETCH_GROUP_TIER_BASED_ON_ID = `${prefix}/FETCH_GROUP_TIER_BASED_ON_ID`;
export const SAVE_TIER_DATA = `${prefix}/SAVE_TIER_DATA`;
export const DELETE_TIER = `${prefix}/DELETE_TIER`;
export const SAVE_TEMPLATE_DATA = `${prefix}/SAVE_TEMPLATE_DATA`;
export const DELETE_TEMPLATE = `${prefix}/DELETE_TEMPLATE`;

export const FETCH_GROUP_TEMPLATES = `${prefix}/FETCH_GROUP_TEMPLATES`;
export const FETCH_GROUP_TEMPLATE_BASED_ON_ID = `${prefix}/FETCH_GROUP_TEMPLATE_BASED_ON_ID`;
export const FETCH_HUBS_BY_SEARCH = `${prefix}/FETCH_HUBS_BY_SEARCH`;
export const FETCH_GROUPS_BY_HUB = `${prefix}/FETCH_GROUPS_BY_HUB`;
export const FETCH_ACTIVE_TIERS = `${prefix}/FETCH_ACTIVE_TIERS`;

export const SET_NAME = `${prefix}/SET_NAME`;
export const SET_INTERNAL_NAME = `${prefix}/SET_INTERNAL_NAME`;
export const SET_DESCRIPTION = `${prefix}/SET_DESCRIPTION`;
export const SET_IMAGE = `${prefix}/SET_IMAGE`;
export const SET_IS_ACTIVE = `${prefix}/SET_IS_ACTIVE`;
export const SET_OBJECT_ID = `${prefix}/SET_OBJECT_ID`;
export const SET_GROUP_TIER = `${prefix}/SET_GROUP_TIER`;
export const SET_LOGO_URL = `${prefix}/SET_LOGO_URL`;
export const SET_COVER_PHOTO_URL = `${prefix}/SET_COVER_PHOTO_URL`;
export const SET_SETTINGS = `${prefix}/SET_SETTINGS`;
export const SET_FIELD_INPUTS_DISABLED = `${prefix}/SET_FIELD_INPUTS_DISABLED`;

export const SET_MANAGE_LOADING = `${prefix}/SET_MANAGE_LOADING`;
export const SET_MANAGE_DATA = `${prefix}/SET_MANAGE_DATA`;
export const SET_MANAGE_SINGLE_LOADING = `${prefix}/SET_MANAGE_SINGLE_DATA_LOADING`;
export const SET_MANAGE_DATA_PAGE = `${prefix}/SET_MANAGE_DATA_PAGE`;
export const SET_MANAGE_DATA_COUNT = `${prefix}/SET_MANAGE_DATA_COUNT`;
export const SET_MANAGE_DATA_PAGE_SIZE = `${prefix}/SET_MANAGE_DATA_PAGE_SIZE`;
export const CLEAR_FORM = `${prefix}/CLEAR_FORM`;
export const CLEAR_MANAGE_DATA = `${prefix}/CLEAR_MANAGE_DATA`;
export const SET_FILTERED_HUBS = `${prefix}/SET_FILTERED_HUBS`;
export const SET_FILTERED_HUBS_LOADING = `${prefix}/SET_FILTERED_HUBS_LOADING`;
export const SET_FILTERED_GROUPS = `${prefix}/SET_FILTERED_GROUPS`;
export const SET_FILTERED_GROUPS_LOADING = `${prefix}/SET_FILTERED_GROUPS_LOADING`;
export const SET_ACTIVE_TIERS = `${prefix}/SET_ACTIVE_TIERS`;
export const SET_ACTIVE_TIERS_LOADING = `${prefix}/SET_ACTIVE_TIERS_LOADING`;
export const SET_COPY_MODAL_VISIBILITY = `${prefix}/SET_COPY_MODAL_VISIBILITY`;
export const SET_DELETE_MODAL_VISIBILITY = `${prefix}/SET_DELETE_MODAL_VISIBILITY`;
export const SET_CANCEL_MODAL_VISIBILITY = `${prefix}/SET_CANCEL_MODAL_VISIBILITY`;
export const SET_MANAGE_TYPE = `${prefix}/SET_MANAGE_TYPE`;
export const SET_SHOW_FORM = `${prefix}/SET_SHOW_FORM`;
export const SET_CAN_DELETE_TIER = `${prefix}/SET_CAN_DELETE_TIER`;
export const SET_GROUPED_USERTYPE = `${prefix}/SET_GROUPED_USERTYPE`;
export const SET_SELECTED_HUB_ID_TO_COPY = `${prefix}/SET_SELECTED_HUB_ID_TO_COPY`;
export const SET_VISIBLE_TO_USERTYPE = `${prefix}/SET_VISIBLE_TO_USERTYPE`;
export const SET_SELECTED_GROUP = `${prefix}/SET_SELECTED_GROUP`;
export const SET_MANAGE_SAVE_LOADING = `${prefix}/SET_MANAGE_SAVE_LOADING`;
export const SET_SELECTED_ASSOCIATED_GROUP_COUNT = `${prefix}/SET_SELECTED_ASSOCIATED_GROUP_COUNT`;

export const fetchGroupTiers = data => ({
  type: FETCH_GROUP_TIERS,
  data,
});

export const fetchGroupTierBasedOnId = data => ({
  type: FETCH_GROUP_TIER_BASED_ON_ID,
  data,
});

export const saveTierData = data => ({
  type: SAVE_TIER_DATA,
  data,
});

export const deleteTier = data => ({
  type: DELETE_TIER,
  data,
});

export const fetchGroupTemplates = data => ({
  type: FETCH_GROUP_TEMPLATES,
  data,
});

export const fetchGroupTemplateBasedOnId = data => ({
  type: FETCH_GROUP_TEMPLATE_BASED_ON_ID,
  data,
});

export const fetchHubsBySearch = data => ({
  type: FETCH_HUBS_BY_SEARCH,
  data,
});

export const fetchGroupsByHub = data => ({
  type: FETCH_GROUPS_BY_HUB,
  data,
});

export const fetchActiveTiers = data => ({
  type: FETCH_ACTIVE_TIERS,
  data,
});

export const saveTemplateData = data => ({
  type: SAVE_TEMPLATE_DATA,
  data,
});

export const deleteTemplate = data => ({
  type: DELETE_TEMPLATE,
  data,
});

export const setCanDeleteTier = data => ({
  type: SET_CAN_DELETE_TIER,
  data,
});

export const setSelectedAssociatedGroupCount = data => ({
  type: SET_SELECTED_ASSOCIATED_GROUP_COUNT,
  data,
})

// -------------------- SET FIELDS -------------------------

export const setName = data => ({
  type: SET_NAME,
  data,
});

export const setInternalName = data => ({
  type: SET_INTERNAL_NAME,
  data,
});

export const setDescription = data => ({
  type: SET_DESCRIPTION,
  data,
});

export const setImage = data => ({
  type: SET_IMAGE,
  data,
});

export const setIsActive = data => ({
  type: SET_IS_ACTIVE,
  data,
});

export const setObjectId = data => ({
  type: SET_OBJECT_ID,
  data,
});

export const setGroupTier = data => ({
  type: SET_GROUP_TIER,
  data,
});

export const setLogoUrl = data => ({
  type: SET_LOGO_URL,
  data,
});

export const setCoverPhotoUrl = data => ({
  type: SET_COVER_PHOTO_URL,
  data,
});

export const setSettings = data => ({
  type: SET_SETTINGS,
  data,
});

export const setFieldInputsDisabled = data => ({
  type: SET_FIELD_INPUTS_DISABLED,
  data,
});

// ---------------------------------------------------------

export const setManageLoading = data => ({
  type: SET_MANAGE_LOADING,
  data,
});

export const setManageData = data => ({
  type: SET_MANAGE_DATA,
  data,
});

export const setManageDataPage = data => ({
  type: SET_MANAGE_DATA_PAGE,
  data,
});

export const setManageDataCount = data => ({
  type: SET_MANAGE_DATA_COUNT,
  data,
});

export const setManageDataPageSize = data => ({
  type: SET_MANAGE_DATA_PAGE_SIZE,
  data,
});

export const setManageSingleLoading = data => ({
  type: SET_MANAGE_SINGLE_LOADING,
  data,
});

export const clearForm = () => ({
  type: CLEAR_FORM,
});

export const clearManageData = () => ({
  type: CLEAR_MANAGE_DATA,
});

export const setFilteredHubs = data => ({
  type: SET_FILTERED_HUBS,
  data,
});

export const setFilteredHubsLoading = data => ({
  type: SET_FILTERED_HUBS_LOADING,
  data,
});

export const setFilteredGroupsByHub = data => ({
  type: SET_FILTERED_GROUPS,
  data,
});

export const setFilteredGroupsByHubLoading = data => ({
  type: SET_FILTERED_GROUPS_LOADING,
  data,
});

export const setActiveTiers = data => ({
  type: SET_ACTIVE_TIERS,
  data,
});

export const setActiveTiersLoading = data => ({
  type: SET_ACTIVE_TIERS_LOADING,
  data,
});

export const setDeleteModalVisibility = data => ({
  type: SET_DELETE_MODAL_VISIBILITY,
  data,
});

export const setCopyModalVisibility = data => ({
  type: SET_COPY_MODAL_VISIBILITY,
  data,
});

export const setCancelModalVisibility = data => ({
  type: SET_CANCEL_MODAL_VISIBILITY,
  data,
});

export const setManageType = data => ({
  type: SET_MANAGE_TYPE,
  data,
});

export const setShowForm = data => ({
  type: SET_SHOW_FORM,
  data,
});

export const setGroupedUsertype = data => ({
  type: SET_GROUPED_USERTYPE,
  data,
});

export const setSelectedHubIdToCopy = data => ({
  type: SET_SELECTED_HUB_ID_TO_COPY,
  data,
});

export const setVisibleToUserType = data => ({
  type: SET_VISIBLE_TO_USERTYPE,
  data,
});

export const setSelectedGroup = data => ({
  type: SET_SELECTED_GROUP,
  data,
});

export const setManageSaveLoading = data => ({
  type: SET_MANAGE_SAVE_LOADING,
  data
});

// Selectors
export const manageData = ({ groupsManage }) => groupsManage.manageData;
export const manageDataPage = ({ groupsManage }) => groupsManage.manageDataPage;
