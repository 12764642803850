import {
  ACTIVE_HUBS_MODAL_VISIBLE,
  CANCEL_MODAL_VISIBLE,
  CLEAR_DATA,
  CLEAR_FORM,
  DELETE_MODAL_VISIBLE,
  IS_SAVING,
  IS_SINGLE_LOADING,
  MANAGE_LOADING,
  RESET_TEMPLATE_STATE,
  SET_ACTIVE_HUBS_LOADING,
  SET_ACTIVE_ID_FOR_MODAL,
  SET_CAN_EDIT_TEMPLATE,
  SET_GROUPED_TEMPLATES,
  SET_MANAGE_DATA_COUNT,
  SET_MANAGE_DATA_PAGE_SIZE,
  SET_MANAGE_DATA_PAGE,
  SET_MANAGE_DATA,
  SET_TEMPLATE_NAME,
  SET_TEMPLATE_SETTINGS,
  SET_TEMPLATE_TABLE_ROWS,
  SET_TEMPLATE_USER_TYPE,
  SET_TYPE_OF_MANAGE,
  SET_UPSELL_CTA_LINK,
  SET_UPSELL_CTA_TEXT,
  SET_UPSELL_DATA,
  SET_UPSELL_DESCRIPTION,
  SET_UPSELL_EMAILS,
  SET_UPSELL_NAME,
  SET_UPSELL_OPTIONS,
  SET_UPSELL_TYPE,
  SET_USERTYPE_LIBRARY_DATA,
  SET_USERTYPE_LIBRARY_NAME,
  SET_USERTYPE_LIBRARY_SELECTED_USERTYPE_TEMPLATES,
  SET_USERTYPE_LIBRARY_TEMPLATES,
  SET_USERTYPE_LIBRARY_UPSELLS,
  SHOW_FORM,
  UPDATE_UPSELL_FORM,
  CONFIRM_MODAL_VISIBLE,
} from '../actions/manageActions';
import {
  CLEAR_HUB_FILTERS,
  SET_HUBS_DATA,
  SET_HUBS_DATA_COUNT,
  SET_HUBS_DATA_LOADING,
  SET_HUBS_DATA_PAGE,
  SET_HUBS_FILTER_APPLIED,
  SET_HUBS_SEARCH_TEXT,
  SET_LIBRARIES,
  SET_FILTERS_LOADING,
  SET_SELECTED_LIBRARIES,
  SET_SELECTED_USERTYPE_TEMPLATES,
  CLEAR_USERTYPE_SETTINGS,
} from '../actions';

const initialState = {
  manageLoading: false,
  manageData: [],
  manageDataCount: 0,
  manageDataPage: 1,
  manageDataPageSize: 5,
  manageType: '',
  showForm: '',
  cancelModalVisible: false,
  deleteModalVisible: false,
  confirmModalVisible: false,
  isSaving: false,
  isSingleLoading: false,

  // For Manage Upsells
  upsellFormData: {
    name: '',
    description: '',
    settings: {
      type: 'link',
      ctaText: '',
      ctaLink: '',
      emails: [],
    },
  },
  upsellForm: null,

  usertypeTemplates: [],
  upsells: [],
  libraries: [],
  usertypeLibraryFormData: {
    name: '',
    selectedUsertypeTemplates: [],
  },
  // For Manage Templates
  groupedTemplates: {},
  upsellOptions: [],
  templateSettingRows: [],
  template: {
    standardUsertype: '',
    name: '',
    settings: '',
  },

  canEditTemplate: true,
  activeHubsModalVisible: false,
  activeHubsLoading: false,
  activeId: null,

  // For UsertypeSettings
  hubsData: [],
  hubsDataCount: 0,
  hubsDataPage: 1,
  hubsDataLoading: false,
  hubsSearchText: '',
  hubsFilterApplied: false,
  filtersLoading: false,
  selectedLibraries: [],
  selectedUsertypeTemplates: [],
};

const manageReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SHOW_FORM:
      return {
        ...state,
        showForm: data,
      };
    case CLEAR_FORM:
      return {
        ...state,
        upsellFormData: {
          name: '',
          description: '',
          settings: {
            type: 'link',
            ctaText: '',
            ctaLink: '',
            emails: [],
          },
        },
        upsellForm: null,
        usertypeLibraryFormData: {
          name: '',
          selectedUsertypeTemplates: [],
        },
      };
    case SET_TYPE_OF_MANAGE:
      return {
        ...state,
        manageType: data,
      };
    case MANAGE_LOADING:
      return {
        ...state,
        manageLoading: data,
      };
    case IS_SAVING:
      return {
        ...state,
        isSaving: data,
      };
    case IS_SINGLE_LOADING:
      return {
        ...state,
        isSingleLoading: data,
      };
    case SET_MANAGE_DATA:
      return {
        ...state,
        manageData: data,
      };
    case SET_MANAGE_DATA_PAGE:
      return {
        ...state,
        manageDataPage: data,
      };
    case SET_MANAGE_DATA_PAGE_SIZE:
      return {
        ...state,
        manageDataPageSize: data,
      };
    case SET_MANAGE_DATA_COUNT:
      return {
        ...state,
        manageDataCount: data,
      };
    case CANCEL_MODAL_VISIBLE:
      return {
        ...state,
        cancelModalVisible: data,
      };
    case DELETE_MODAL_VISIBLE:
      return {
        ...state,
        deleteModalVisible: data,
      };
    case CONFIRM_MODAL_VISIBLE:
      return {
        ...state,
        confirmModalVisible: data,
      };
    case SET_UPSELL_DATA:
      return {
        ...state,
        upsellFormData: { ...data },
      };
    case SET_UPSELL_NAME:
      return {
        ...state,
        upsellFormData: {
          ...state.upsellFormData,
          name: data,
        },
      };
    case SET_UPSELL_DESCRIPTION:
      return {
        ...state,
        upsellFormData: {
          ...state.upsellFormData,
          description: data,
        },
      };
    case SET_UPSELL_CTA_TEXT:
      return {
        ...state,
        upsellFormData: {
          ...state.upsellFormData,
          settings: {
            ...state.upsellFormData.settings,
            ctaText: data,
          },
        },
      };
    case SET_UPSELL_CTA_LINK:
      return {
        ...state,
        upsellFormData: {
          ...state.upsellFormData,
          settings: {
            ...state.upsellFormData.settings,
            ctaLink: data,
          },
        },
      };
    case SET_UPSELL_EMAILS:
      return {
        ...state,
        upsellFormData: {
          ...state.upsellFormData,
          settings: {
            ...state.upsellFormData.settings,
            emails: data,
          },
        },
      };
    case SET_UPSELL_TYPE:
      return {
        ...state,
        upsellFormData: {
          ...state.upsellFormData,
          settings: {
            ...state.upsellFormData.settings,
            type: data,
          },
        },
      };
    case SET_USERTYPE_LIBRARY_DATA:
      return {
        ...state,
        usertypeLibraryFormData: { ...data },
      };
    case SET_USERTYPE_LIBRARY_NAME:
      return {
        ...state,
        usertypeLibraryFormData: {
          ...state.usertypeLibraryFormData,
          name: data,
        },
      };
    case SET_USERTYPE_LIBRARY_SELECTED_USERTYPE_TEMPLATES:
      return {
        ...state,
        usertypeLibraryFormData: {
          ...state.usertypeLibraryFormData,
          selectedUsertypeTemplates: data,
        },
      };
    case SET_USERTYPE_LIBRARY_UPSELLS:
      return {
        ...state,
        upsells: data,
      };
    case SET_USERTYPE_LIBRARY_TEMPLATES:
      return {
        ...state,
        usertypeTemplates: data,
      };
    case SET_TEMPLATE_NAME:
      return {
        ...state,
        template: {
          ...state.template,
          name: data,
        },
      };
    case SET_TEMPLATE_USER_TYPE:
      return {
        ...state,
        template: {
          ...state.template,
          standardUsertype: data,
        },
      };
    case SET_TEMPLATE_SETTINGS:
      return {
        ...state,
        template: {
          ...state.template,
          settings: data,
        },
      };
    case SET_UPSELL_OPTIONS:
      return {
        ...state,
        upsellOptions: data,
      };
    case SET_TEMPLATE_TABLE_ROWS:
      return {
        ...state,
        templateSettingRows: data,
      };
    case RESET_TEMPLATE_STATE:
      return {
        ...state,
        upsellOptions: [],
        templateSettingRows: [],
        template: {
          standardUserType: null,
          name: '',
          settings: '',
        },
      };
    case SET_GROUPED_TEMPLATES:
      return {
        ...state,
        groupedTemplates: data,
      };
    case ACTIVE_HUBS_MODAL_VISIBLE:
      return {
        ...state,
        activeHubsModalVisible: data,
      };
    case SET_ACTIVE_HUBS_LOADING:
      return {
        ...state,
        activeHubsLoading: data,
      };
    case SET_CAN_EDIT_TEMPLATE:
      return {
        ...state,
        canEditTemplate: data,
      };
    case SET_ACTIVE_ID_FOR_MODAL:
      return {
        ...state,
        activeId: data,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case SET_HUBS_DATA:
      return {
        ...state,
        hubsData: data,
      };
    case SET_HUBS_DATA_PAGE:
      return {
        ...state,
        hubsDataPage: data,
      };
    case SET_HUBS_DATA_COUNT:
      return {
        ...state,
        hubsDataCount: data,
      };
    case SET_HUBS_DATA_LOADING:
      return {
        ...state,
        hubsDataLoading: data,
      };
    case SET_HUBS_SEARCH_TEXT:
      return {
        ...state,
        hubsSearchText: data,
      };
    case SET_HUBS_FILTER_APPLIED:
      return {
        ...state,
        hubsFilterApplied: data,
      };
    case SET_LIBRARIES:
      return {
        ...state,
        libraries: data,
      };
    case SET_SELECTED_LIBRARIES:
      return {
        ...state,
        selectedLibraries: data,
      };
    case SET_SELECTED_USERTYPE_TEMPLATES:
      return {
        ...state,
        selectedUsertypeTemplates: data,
      };
    case SET_FILTERS_LOADING:
      return {
        ...state,
        filtersLoading: data,
      };
    case CLEAR_HUB_FILTERS: {
      return {
        ...state,
        hubsData: [],
        hubsDataCount: 0,
        hubsDataPage: 1,
        hubsDataLoading: false,
        hubsSearchText: '',
        hubsFilterApplied: false,
        selectedLibraries: [],
        selectedUsertypeTemplates: [],
      };
    }
    case CLEAR_USERTYPE_SETTINGS: {
      return {
        ...state,
        hubsData: [],
        hubsDataCount: 0,
        hubsDataPage: 1,
        hubsDataLoading: false,
        hubsSearchText: '',
        hubsFilterApplied: false,
        filtersLoading: false,
        selectedLibraries: [],
        selectedUsertypeTemplates: [],
        usertypeTemplates: [],
        upsells: [],
        libraries: [],
      };
    }
    case UPDATE_UPSELL_FORM:
      return {
        ...state,
        upsellForm: data,
      };
    default:
      return state;
  }
};

export default manageReducer;
