/* eslint-disable no-console */
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { get, find } from 'lodash';
import {
  // actions
  CREATE_CONTENT,
  EDIT_CONTENT,
  FETCH_CONTENT,
  DELETE_CONTENT,

  // action creators
  setShowForm,
  setFormData,
  setIsFormDataChanged,
  setIsFormDataSaving,
  setIsDeletingContent,
  fetchContent,

  // selectors
  formDataSelector,
  cmsContentTypeSelector,
  cmsDataPageSelector,
  setCmsDataLoading,
  setCmsData,
  FETCH_CONTENT_BY_IDENTIFIER,
  FETCH_CONTENT_BY_ID,
  setContentByIdentifier,
  setIsFetchingContentByIdentifier,
  setContentType,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';

import {
  createCMSContentApi,
  editCMSContentApi,
  fetchCmsContentApi,
  deleteCmsContentApi,
  fetchCmsContentByIdentifierApi,
  fetchCmsContentByIdApi,
} from '../../../api/cmsToolApi';

import { cmsContentTypes } from '../constants';

const sagas = [
  takeLatest(CREATE_CONTENT, createCMSContentSaga),
  takeLatest(EDIT_CONTENT, editCMSContentSaga),
  takeLatest(FETCH_CONTENT, fetchContentSaga),
  takeLatest(DELETE_CONTENT, deleteContentSaga),
  takeLatest(FETCH_CONTENT_BY_IDENTIFIER, fetchContentByIdentifierSaga),
  takeLatest(FETCH_CONTENT_BY_ID, fetchContentById),
];

export default sagas;

const defaultErrorMessage = 'Error Fetching Content.';

function* fetchContentByIdentifierSaga({ data }) {
  try {
    yield put(setIsFetchingContentByIdentifier(true));
    const { identifier } = data;
    const { data: content } = yield call(fetchCmsContentByIdentifierApi, {
      identifier,
    });
    yield put(setContentByIdentifier(content));
  } catch (error) {
    console.error('Error', error);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: defaultErrorMessage },
    });
  } finally {
    yield put(setIsFetchingContentByIdentifier(false));
  }
}

function* fetchContentById({ data }) {
  try {
    yield put(setCmsDataLoading(true));
    const { id } = data;
    const { data: content } = yield call(fetchCmsContentByIdApi, {
      id,
    });
    const { contentType = 'INBOX_TEMPLATE' } = content;
    const contentTypeKey = get(
      find(cmsContentTypes, { value: contentType }),
      'key',
      ''
    );
    yield put(setContentType(contentTypeKey));
    yield put(setFormData(content));
    yield put(setShowForm(true));
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: defaultErrorMessage },
    });
  } finally {
    yield put(setCmsDataLoading(false));
  }
}

function* createCMSContentSaga({ data }) {
  try {
    const formData = yield select(formDataSelector);
    const contentTypeKey = yield select(cmsContentTypeSelector);
    const contentTypeValue = get(
      find(cmsContentTypes, { key: contentTypeKey }),
      'value'
    );
    if (!formData.content || !contentTypeValue || !formData.name) {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Required fields missing.' },
      });
      yield put(setIsFormDataSaving(false));
      return;
    }
    yield call(createCMSContentApi, {
      ...formData,
      contentType: contentTypeValue,
    });
    yield call(postCMSFormProcessing, data);
  } catch (error) {
    console.error('Error', error);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: error?.response?.data?.message || 'Error Creating Content.',
      },
    });
    yield put(setIsFormDataSaving(false));
  }
}

function* editCMSContentSaga({ data }) {
  try {
    const formData = yield select(formDataSelector);
    if (!formData.content || !formData.name) {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Required fields missing.' },
      });
      yield put(setIsFormDataSaving(false));
      return;
    }
    yield call(editCMSContentApi, formData);
    yield call(postCMSFormProcessing, data);
  } catch (error) {
    console.error('Error', error);
    yield put(setIsFormDataSaving(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Updating Content.' },
    });
  }
}

function* postCMSFormProcessing() {
  yield all([
    call(fetchContentSaga),
    put(setShowForm(false)),
    put(setFormData({})),
    put(setIsFormDataChanged(false)),
    put(setIsFormDataSaving(false)),
  ]);
}

function* fetchContentSaga(params) {
  try {
    yield put(setCmsDataLoading(true));
    const type = get(params, 'data', '');
    const contentType = yield type || select(cmsContentTypeSelector);
    const page = yield select(cmsDataPageSelector);
    const contentTypeVal = get(
      find(cmsContentTypes, { key: contentType }),
      'value',
      ''
    );
    const { data } = yield call(fetchCmsContentApi, {
      size: 10,
      page: page || 1,
      contentType: contentTypeVal,
    });
    const { contents, total } = data;
    yield put(
      setCmsData({
        cmsData: contents,
        cmsDataCount: total,
      })
    );
  } catch (error) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: defaultErrorMessage },
    });
  } finally {
    yield put(setCmsDataLoading(false));
  }
}

function* deleteContentSaga({ data }) {
  try {
    // yield put(setIsDeletingContent(true));
    yield call(deleteCmsContentApi, { id: data.id });
    yield put(fetchContent());
  } catch (error) {
    console.error('Error', error);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Deleting Content.' },
    });
  } finally {
    yield put(setIsDeletingContent(false));
  }
}
