import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Divider,
  Icon,
  Input,
  PageHeader,
  Row,
  Select,
} from 'antd';
import Content from '../../component/Content';
import ClusterHubList from './components/ClusterHubList';
import VerificationModal from './components/VerficationModal';
import ProgressModal from './components/ProgressModal';

import {
  clearStandardState as clearStandardStateAction,
  fetchHubs as fetchHubsAction,
  handleActionSetFiltersEnabled as setFiltersEnabledAction,
  handleActionSetPage as setPageAction,
  handleActionSetPlatformType as handleActionSetPlatformTypeAction,
  handleActionSetSearchText as handleActionSetSearchTextAction,
  verifyAndGetData as verifyAndGetDataAction,
} from './actions';

import './standardHubManagement.scss';

const { Search } = Input;
const { Option } = Select;

const StandardHubManagement = ({
  clearStandardState,
  fetchHubs,
  filtersEnabled,
  history,
  hubsData,
  listLoading,
  page,
  platformType,
  searchText,
  setFiltersEnabled,
  setPage,
  setPlatformType,
  setSearchText,
  total,
  verifyAndGetData,
}) => {
  useEffect(() => {
    fetchHubs();
    return () => clearStandardState();
  }, [fetchHubs, clearStandardState]);

  const handleHubSearch = () => {
    fetchHubs({ searchText, platformType });
  };

  const handleSelectChange = value => {
    setPlatformType(value);
  };

  const handleApplyFiltersClick = () => {
    fetchHubs({ searchText, platformType });
  };

  const handleClearFiltersClick = () => {
    setSearchText('');
    setPlatformType();
    setPage(1);
    setFiltersEnabled(false);
    fetchHubs();
  };

  const handlePageChange = newPage => {
    setPage(newPage);
    fetchHubs({ searchText, platformType, page: newPage });
  };

  const handleManageBaseConfigClick = () => {
    history.push('/standard-hub-management/manage-config');
  };

  const isFilterButtonEnabled =
    searchText.length > 0 || (platformType && platformType.length > 0);

  return (
    <>
      <PageHeader
        title="V2 Hub Management"
        extra={[
          <Button
            key="manage-base-config"
            onClick={handleManageBaseConfigClick}
            type="primary"
            icon="plus-circle"
          >
            Manage Base Config
          </Button>,
        ]}
      >
        <Divider />
        <Row type="flex" justify="center">
          <Col span={12}>
            <Search
              placeholder="Find site by domain name, hub name, hub id, cluster name, cluster id or subdomain"
              style={{ width: '95%' }}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onSearch={handleHubSearch}
            />
          </Col>
          <Col span={12}>
            <Select
              placeholder="Filter by Platform Type"
              onChange={handleSelectChange}
              value={platformType}
              style={{ width: '40%' }}
            >
              <Option value="standard">V2 | PG Essentials | New</Option>
              <Option value="transitioned-standard">
                V2 | PG Essentials | Upgraded
              </Option>
              <Option value="standard-plus">V2 | PG + | New</Option>
              <Option value="standard-plus-fpu">V2 | PG + | Upgraded</Option>
              <Option value="enterprise">Classic</Option>
            </Select>
            <Divider type="vertical" />
            <Button
              disabled={!isFilterButtonEnabled}
              type="primary"
              onClick={handleApplyFiltersClick}
            >
              <Icon type="filter" />
              Apply Filters
            </Button>
            <Divider type="vertical" />
            <Button
              disabled={!filtersEnabled}
              onClick={handleClearFiltersClick}
            >
              <Icon type="sync" />
              Clear All
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <Content>
        <Card bordered={false} className="standard-hub--body">
          <ClusterHubList
            data={hubsData}
            loading={listLoading}
            page={page}
            total={total}
            handlePageChange={handlePageChange}
            verifyAndGetData={verifyAndGetData}
          />
        </Card>
        <VerificationModal />
        <ProgressModal />
      </Content>
    </>
  );
};

const mapStateToProps = ({
  standardHubManagement: {
    filtersEnabled,
    hubsData,
    listLoading,
    page,
    platformType,
    searchText,
    total,
  },
}) => ({
  filtersEnabled,
  hubsData,
  listLoading,
  page,
  platformType,
  searchText,
  total,
});

const mapDispatchToProps = {
  clearStandardState: clearStandardStateAction,
  fetchHubs: fetchHubsAction,
  setFiltersEnabled: setFiltersEnabledAction,
  setPage: setPageAction,
  setPlatformType: handleActionSetPlatformTypeAction,
  setSearchText: handleActionSetSearchTextAction,
  verifyAndGetData: verifyAndGetDataAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StandardHubManagement)
);
