const prefix = 'ANALYTICS_REPORT';
export const FETCH_ANALYTICS_REPORT_LIST = `${prefix}/FETCH_ANALYTICS_REPORT_LIST`;
export const SET_REPORT_LIST_STATE = `${prefix}/SET_REPORT_LIST_STATE`;
export const FETCH_ANALYTICS_REPORT = `${prefix}/FETCH_ANALYTICS_REPORT`;
export const SET_REPORT_STATE = `${prefix}/SET_REPORT_STATE`;
export const SET_REPORT_DATA = `${prefix}/SET_REPORT_DATA`;
export const TEST_REPORT_QUERY = `${prefix}/TEST_REPORT_QUERY`;
export const SUBMIT_REPORT_CHANGES = `${prefix}/SUBMIT_REPORT_CHANGES`;
export const CREATE_NEW_ANALYTICS_REPORT = `${prefix}/CREATE_NEW_ANALYTICS_REPORT`;
export const SET_ANALYTIC_REPORT_STATE = `${prefix}/SET_ANALYTIC_REPORT_STATE`;
export const FETCH_ALL_HUB_LIST = `${prefix}/FETCH_ALL_HUB_LIST`;
export const FETCH_ALL_CLUSTER_LIST = `${prefix}/FETCH_ALL_CLUSTER_LIST`;

export const fetchAnalyticsReportList = data => ({
  type: FETCH_ANALYTICS_REPORT_LIST,
  data,
});

export const setReportListState = data => ({
  type: SET_REPORT_LIST_STATE,
  data,
});

export const fetchAnalyticsReport = data => ({
  type: FETCH_ANALYTICS_REPORT,
  data,
});

export const setReportState = data => ({
  type: SET_REPORT_STATE,
  data,
});

export const setReportData = data => ({
  type: SET_REPORT_DATA,
  data,
});

export const testReportQuery = data => ({
  type: TEST_REPORT_QUERY,
  data,
});

export const submitReportChanges = data => ({
  type: SUBMIT_REPORT_CHANGES,
  data,
});

export const createNewAnalyticReport = data => ({
  type: CREATE_NEW_ANALYTICS_REPORT,
  data,
});

export const setAnalyticsReportState = data => ({
  type: SET_ANALYTIC_REPORT_STATE,
  data,
});

export const fetchAllHubList = data => ({
  type: FETCH_ALL_HUB_LIST,
  data,
});

export const fetchAllClusterList = data => ({
  type: FETCH_ALL_CLUSTER_LIST,
  data,
});
