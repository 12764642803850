import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setListActive } from '../../../api';
import { notify } from '../../../utils/notify';
import AchievementKeys from '../queryKeys/GlobalLists';

const publishList = async body => {
  const { data } = await setListActive(body);
  return data;
};

const usePublishListMutation = ({ setSelectedList, page }) => {
  const client = useQueryClient();
  return useMutation(publishList, {
    onSuccess: (data, payload) => {
      const { active: isActive, id } = payload;

      client.setQueryData([AchievementKeys.globalLists, page], oldData => ({
        ...oldData,
        lists: oldData.lists.map(obj =>
          id === obj.id ? { ...obj, active: isActive } : obj
        ),
      }));
      notify(
        `List ${isActive ? 'Published' : 'Unpublished'} successfully!`,
        'success'
      );
      setSelectedList(payload);
    },
    onsError: err => {
      console.log('err', err);
      notify(`Error in updating List`, 'error');
    },
  });
};

export default usePublishListMutation;
