import React, { useEffect, useMemo, useState } from 'react';
import { Col, Input, Modal, Row, Alert } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import './index.scss';
import { useDefaultTextUpdateMutation } from '../../queries';

const EditDefaultTextModal = ({
  visibility,
  setVisibility,
  identifier,
  text,
  isCluster,
  ...rest
}) => {
  const modules = rest.modules || '';
  // const [isDisabled, setIsDisabled] = useState(false);
  const [defaultText, setDefaultText] = useState(text);
  const [identifierModules, setIdentifierModules] = useState(modules);
  const { mutate: updateDefaultText } = useDefaultTextUpdateMutation({
    onSuccess: () => {
      setVisibility(false);
    },
  });

  const onSaveDisabled = useMemo(() => {
    console.log({ identifierModules, modules, defaultText, text });
    return defaultText === text && identifierModules === modules;
  }, [defaultText, identifierModules, text, modules]);

  useEffect(() => {
    setIdentifierModules(modules);
  }, [modules]);
  useEffect(() => {
    setDefaultText(text);
  }, [text]);
  const handleOnDefaultTextChange = ({ target: { value } }) => {
    setDefaultText(value);
  };
  const handleOnModuleChange = ({ target: { value } }) => {
    setIdentifierModules(value);
  };
  const onSave = () => {
    const payload = {
      isClusterText: isCluster,
      identifier,
      defaultText,
      modules: identifierModules,
    };
    updateDefaultText(payload);
  };
  return (
    <Modal
      visible={visibility}
      okText="Confirm"
      onOk={onSave}
      onCancel={() => setVisibility(false)}
      title={`Edit Default ${
        isCluster ? 'Cluster' : 'Hub'
      } Text (${identifier})`}
      className="edit-default-text-modal"
      okButtonProps={{ disabled: onSaveDisabled }}
    >
      <Alert
        message="Modifying the default text will update it across all hubs that have not
          customized it."
        type="warning"
      />
      <Row>
        <Col>
          <TextArea
            placeholder="Default text..."
            value={defaultText}
            autosize={{ minRows: 4, maxRows: 4 }}
            onChange={handleOnDefaultTextChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Save module tags as comma separated"
            value={identifierModules}
            onChange={handleOnModuleChange}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default EditDefaultTextModal;
