import React, { PureComponent } from 'react';
import s from './Auth.module.scss';

class Auth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
    };
  }

  authenticateUser = () => {
    const url = `${process.env.REACT_APP_API_URL}/auth/super-admin/saml`;
    window.open(url);
  };

  render() {
    const { errorMessage } = this.state;
    return (
      <div className={s['admin-auth-box']}>
        <div className={s['inner-auth-box']}>
          <img
            src="https://cdn.peoplegrove.com/admin/1663588009827pg_logo_horizontal.png"
            alt="Background"
          />
          <p className={s.error}>{errorMessage}</p>
          <div
            role="button"
            className={s['admin-button']}
            onClick={this.authenticateUser}
            onKeyPress={e => {
              if (e.key === 'Enter') this.authenticateUser();
            }}
            tabIndex={0}
          >
            Log in with Google
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;
