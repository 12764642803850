import React from 'react';
import { Select, Tag } from 'antd';
import useGetAutoTriggerListQuery from '../query/useGetAutoTriggerListQuery';

const { Option, OptGroup } = Select;

const TriggerSelect = ({ handleChange, value }) => {
  const { data, isLoading: autoTriggerListLoading } =
    useGetAutoTriggerListQuery();
  return (
    <Select
      mode="multiple"
      placeholder="Select A Notification Trigger"
      style={{ width: '95%' }}
      onChange={handleChange}
      disabled={autoTriggerListLoading}
      value={value}
    >
      {Object.keys(data?.triggers || []).map((triggerGroup, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <OptGroup label={triggerGroup} key={`${triggerGroup}-${index}`}>
          {(data.triggers[triggerGroup] || []).map(trigger => (
            <Option value={trigger.key} key={trigger.name}>
              {trigger.isEventDrivenImpl ? (
                <Tag color="green">{trigger.key}</Tag>
              ) : (
                <Tag color="gray">{trigger.key}</Tag>
              )}
              {trigger.name}
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  );
};

export default TriggerSelect;
