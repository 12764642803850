import React from 'react';
import { Spin, Icon } from 'antd';
import './loader.css';

const antIcon = (
  <Icon type="loading" style={{ fontSize: 40, fontWeight: 600 }} spin />
);

const ProgressBar = ({ style = {} }) => (
  <div className="loader-block" style={style}>
    <Spin indicator={antIcon} />
  </div>
);

export default ProgressBar;
