import React from 'react';
import { connect } from 'react-redux';
import { Select, Spin, Icon, Row, Col, Button } from 'antd';
import { find } from 'lodash';
import {
  fetchAllHubs,
  pollLogstashStatus,
  setEsLogstash,
  runLogstash,
} from './actions';
// import RunningLogstash from './RunningLogstash';
import s from './index.module.scss';
import LogstashStatus from './LogstashStatus';

const { Option } = Select;

class EsLogstash extends React.Component {
  componentDidMount() {
    const { fetchAllHubs, pollLogstashStatus } = this.props;
    fetchAllHubs();
    pollLogstashStatus(true);
  }

  componentWillUnmount() {
    this.props.pollLogstashStatus(false);
  }

  getHubOptions = () => {
    const { esLogstash: { allAvailableHubs } } = this.props;
    return allAvailableHubs.map(hub => <Option value={hub.id} key={hub.id}>{hub.id} - {hub.name}</Option>);
  }

  getFileOptions = () => {
    const { esLogstash: { logstashStatus } } = this.props;
    const { files } = logstashStatus;
    return files && files.map(file => <Option value={file} key={file}>{file}</Option>);
  }

  handleHubChange = (value) => {
    const { setEsLogstash } = this.props;
    setEsLogstash({ hubs: value });
  }

  handleFileChange = (value) => {
    const { setEsLogstash } = this.props;
    setEsLogstash({ files: value });
  }

  setAllFiles = (e) => {
    const { setEsLogstash } = this.props;
    setEsLogstash({ allFiles: e.target.checked });
  }

  filterHubs = (input, option) => {
    const { esLogstash: { allAvailableHubs } } = this.props;
    let opt = find(allAvailableHubs, { id: Number(option.key) })
    if (opt 
      && (opt.name.toLowerCase().indexOf(input.toLowerCase()) > -1
        || opt.id.toString().indexOf(input.toLowerCase()) > -1
      )
    ) {
      return true;
    }
    return false;
  }

  hasValue = () => {
    const { esLogstash } = this.props
    const { hubsLoading, hubs, allFiles, files } = esLogstash;
    if ((!hubs || !hubs.length)){
      return false;
    } else if ((!files || !files.length) && !allFiles) {
      return false;
    }
    return true;
  }

  renderRunButton = () => {
    console.log('rendering button');
    const { esLogstash, runLogstash } = this.props
    const { logstashStatus } = esLogstash;
    const { logstashRunning, loading } = logstashStatus;
    const hasValue = this.hasValue();
    // if (logstashRunning || !hasValue) {
    //   let title = logstashRunning ? `One logstash process is already running. Stop that process to run a new process.` :
    //   (!hasValue ? `Input the required values.` : null);
    //   return (
    //     <Tooltip title={title}>
    //       <Button disabled={true} type="primary">Run</Button>
    //     </Tooltip>
    //   );
    // } else {
    return (
      <Button disabled={loading} type="primary" onClick={() => runLogstash()}>
        Run
      </Button>
    );
  };

  render() {
    const { esLogstash } = this.props
    const { hubsLoading, hubs, allFiles, files } = esLogstash;
    if (hubsLoading) {
      return (
        <div className={s['es-logstgash-lodr']}>
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }
    return (
      <div className={s['es-logstgash']}>
        <div>
          <LogstashStatus />
          {/* <RunningLogstash /> */}
        </div>
        <div>
          <h3>Run a new logstash process:</h3>
          <Row gutter={16}>
            <Col span={6}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select Hubs"
                filterOption={this.filterHubs}
                onChange={this.handleHubChange}
                value={hubs}
              >
                {this.getHubOptions()}
              </Select>
            </Col>
            {/* <Col span={14} className={s['es-logstgash-col--2']}>
                <Checkbox onChange={this.setAllFiles} checked={allFiles}>Run All Files</Checkbox>
                <span> or </span>
                <Select
                  mode="multiple"
                  style={{ width: '50%' }}
                  placeholder="Select Files"
                  onChange={this.handleFileChange}
                  value={files}
                  disabled={allFiles}
                >
                  {this.getFileOptions()}
                </Select>
            </Col> */}
            <Col span={4} className={s['es-logstgash-col--3']}>
              {this.renderRunButton()}
            </Col>
          </Row>
        </div>
      </div> 
  )}
}

const mapStateToProps = ({ admin, esLogstash }) => ({ 
  admin,
  esLogstash
});

const mapDispatchToProps = {
  fetchAllHubs,
  pollLogstashStatus,
  setEsLogstash,
  runLogstash,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EsLogstash);
