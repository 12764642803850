import { takeLatest } from 'redux-saga/effects';

import {
  FETCH_SCHOOLS,
  FILTER_SCHOOLS,
  UPDATE_SCHOOL,
  FETCH_DEGREE_TYPES,
  UPDATE_DEGREE_TYPES,
} from '../actions';

import {
  fetchSchoolsSaga,
  filterSchoolsSaga,
  updateSchoolSaga,
  fetchDegreeTypesSaga,
  updateDegreeTypesSaga,
} from './sagas';

export default [
  takeLatest(FETCH_SCHOOLS, fetchSchoolsSaga),
  takeLatest(FILTER_SCHOOLS, filterSchoolsSaga),
  takeLatest(UPDATE_SCHOOL, updateSchoolSaga),
  takeLatest(FETCH_DEGREE_TYPES, fetchDegreeTypesSaga),
  takeLatest(UPDATE_DEGREE_TYPES, updateDegreeTypesSaga),
];
