import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import BannerTable from './BannerTable';
import BannerForm from './BannerForm';
import {
  fetchConfigurationBasedOnId,
  clearConfigurationForm
} from '../../actions/configureBanner';

class ConfigureAlertBanner extends React.Component{
  state = {
    showForm: false,
  }

  componentDidMount() {
    this.checkRoute();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.path !== match.path) {
      this.checkRoute();
    }
  }

  checkRoute = () => {
    const { match, fetchConfigurationBasedOnId, clearConfigurationForm } = this.props;
    if (
      match.path === '/configure-alert-banner/new' || match.path.indexOf('/configure-alert-banner/edit') > -1
    ) {
      if (match.path === '/configure-alert-banner/new') {
        clearConfigurationForm();
      }
      if (match.params && match.params.id) {
        fetchConfigurationBasedOnId(match.params.id);
      }
      this.setState({
        showForm: true
      })
    }
    if (match.path === '/configure-alert-banner') {
      this.setState({
        showForm: false
      })
    }
  }

  render() {
    const { showForm } = this.state;
    return (
      <Row gutter={16}>
        <Col span={12}>
          <BannerTable />
        </Col>
        <Col span={12}>
          {showForm && <BannerForm />}
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = {
  fetchConfigurationBasedOnId,
  clearConfigurationForm
}

export default withRouter(
  connect(null, mapDispatchToProps)(ConfigureAlertBanner)
);
