import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const SelectActions = props => {
  return (
    <div style={{ margin: '10px' }} >
      <Button type="link" onClick={props.onSelectAll}> Select All</Button>
      <Button type="link" onClick={props.onDeselectAll}> Deselect All</Button>
    </div>
  )
};

SelectActions.propTypes = {
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
}

export default SelectActions;