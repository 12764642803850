/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import LLMRecommendations from './LLMRecommendations';
import MLRecommendations from './MLRecommendations';
import UserConnections from './UserConnections';

const APIResponse = ({ currentMLService, ...props }) => {
  if (currentMLService === 'pg_ml_llm') {
    return <LLMRecommendations {...props} />;
  } else if (currentMLService === 'recommendation') {
    return <MLRecommendations {...props} />;
  } else if (currentMLService === 'userConnection') {
    return <UserConnections {...props} />;
  }
  return null;
};

export default APIResponse;
