import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageHeader, Row, Col, Modal } from 'antd';

import { get, isEqual } from 'lodash';

import TemplateList from './TemplateList';
import TemplateForm from './TemplateForm';

import { getProvisioningFilters, setData } from './actions';

import './index.scss';

class DefaultPathway extends React.Component {
  state = {
    templateId: undefined,
  };

  componentDidMount() {
    const {
      match,
      getProvisioningFilters,
      showPathwayForm,
      history,
    } = this.props;
    if (
      !showPathwayForm &&
      isEqual(get(match, ['path']), '/pathway-provisioning/create')
    ) {
      history.push('/pathway-provisioning');
      return;
    }
    const templateId = get(match, ['params', 'templateId']);

    getProvisioningFilters();

    if (templateId) this.setState({ templateId });
  }

  componentDidUpdate() {
    const { match, history } = this.props;
    const { templateId } = this.state;
    const id = get(match, ['params', 'templateId']);
    if (templateId != id) this.setState({ templateId: id });

    window.addEventListener('beforeunload', this.onRedirect);
    this.unblock = history.block(this.onRedirect);
  }

  componentWillUnmount() {
    const { setData } = this.props;
    setData({
      showPathwayForm: false,
      pathwayData: undefined,
      singleTemplate: undefined,
      preSelectedHubs: [],
    });
  }

  onRedirect = e => {
    const { setData, singleTemplate = {} } = this.props;
    const { isDirty = false, id } = singleTemplate;
    if (isDirty) {
      if (e.preventDefault) {
        e.preventDefault && e.preventDefault();
        e.stopImmediatePropagation && e.stopImmediatePropagation();
      } else {
        setData({ redirectModalPath: e.pathname });
        return false;
      }
    } else {
      this.cleanUp();
    }
  };

  cleanUp = () => {
    const { redirectModalPath, setData, history, showPathwayForm } = this.props;
    this.unblock && this.unblock();
    window.removeEventListener('beforeunload', this.onRedirect);
    setData({ redirectModalPath: undefined });
    if (redirectModalPath) {
      if (showPathwayForm) {
        setData({
          showPathwayForm: false,
          pathwayData: undefined,
          singleTemplate: undefined,
          preSelectedHubs: [],
        });
      }
      history.push(redirectModalPath);
    }
  };

  confirmModal = () => {
    const { redirectModalPath, setData, history } = this.props;
    if (!redirectModalPath) return true;

    return (
      <Modal
        title="Are you sure?"
        closable
        visible={!!redirectModalPath}
        cancelText="Cancel"
        onCancel={() => setData({ redirectModalPath: undefined })}
        destroyOnClose
        okText="Continue"
        onOk={() => this.cleanUp()}
      >
        <h3>You have unsaved changes that will be lost if you leave.</h3>
      </Modal>
    );
  };

  render() {
    const { templateId } = this.state;
    const { showPathwayForm } = this.props;
    return (
      <div className="pathway-provisioning">
        <PageHeader title="Pathways" />
        <Row className="container" gutter={{ lg: 25 }}>
          <Col span={12}>
            <TemplateList templateId={templateId} />
          </Col>
          <Col span={12}>
            {showPathwayForm ? <TemplateForm templateId={templateId} /> : null}
          </Col>
        </Row>
        <this.confirmModal />
      </div>
    );
  }
}

const mapStateToProps = ({ PathwaysProvisioning }) => PathwaysProvisioning;

const mapDispatchToProps = {
  getProvisioningFilters,
  setData,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DefaultPathway)
);
