import React from 'react';
import { capitalize } from 'lodash';

import { Table } from 'antd';
import UserData from '../UserData';

const LLMRecommendations = ({ currentApi, response }) => {
  if (!response?.length) {
    return <div>No Data Found</div>;
  }
  const columns = Object.keys(response[0]).map(key => {
    if (key === 'id' && response[0].userData) {
      return {
        title: capitalize(key),
        dataIndex: key,
        key,
        render: (t, r) => <UserData id={r.id} data={r.userData} />,
      };
    }
    return {
      title: capitalize(key),
      dataIndex: key,
      key,
    };
  });
  const dataSource = response.map(resp => ({
    ...resp,
    metadata: JSON.stringify(resp.metadata),
  }));

  return <Table columns={columns} dataSource={dataSource} />;
};

export default LLMRecommendations;
