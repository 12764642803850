import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { addOldDataToStandardData as addOldDataToStandardDataAction } from '../../actions';
import { accessGroupsVerified } from '../../../../utils/helpers';

const MapOldStandardDataToMigratedHubs = ({
  hubId,
  clusterId,
  addOldDataToStandardData,
  loading,
  oldIndustryHelpTopicMigrationStatus,
  admin,
  standardFieldsUpdated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const accessToModule = accessGroupsVerified(admin, ['standard-fields']);
  const handleOk = () => {
    addOldDataToStandardData({
      hubId,
      clusterId,
    });
  };
  if (!accessToModule) return '';
  return (
    <>
      <Tooltip
        title={
          oldIndustryHelpTopicMigrationStatus === 'in-progress'
            ? 'Process is already running! Please check after 5 to 10 minutes'
            : ''
        }
        placement="top"
      >
        <Button
          onClick={() => setModalVisible(true)}
          className="standard-fields-trigger"
          disabled={
            oldIndustryHelpTopicMigrationStatus !== 'ready' ||
            standardFieldsUpdated ||
            loading
          }
        >
          Standard fields
        </Button>
      </Tooltip>
      <Modal
        visible={modalVisible}
        className="modal-standard-custom-fields"
        onCancel={() => setModalVisible(false)}
        cancelText="Cancel"
        okText="Proceed"
        onOk={handleOk}
        title="Are you sure?"
        okButtonProps={{ loading, disabled: standardFieldsUpdated }}
      >
        <div className="modal-body">
          <p>
            Industries/Help Topics that were created prior to migration and do
            not match with the standard list will be visible in the hub.
          </p>
          <div>
            Please wait for approximately 5-10 minutes for this process to be
            completed.
          </div>
          {standardFieldsUpdated ? (
            <p className="success-message">
              Standard fields updated successfully!
            </p>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  standardHubManagement: { apiLoading, standardFieldsUpdated },
  admin,
}) => ({
  loading: apiLoading,
  admin,
  standardFieldsUpdated,
});

const mapDispatchToProps = {
  addOldDataToStandardData: addOldDataToStandardDataAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapOldStandardDataToMigratedHubs);
