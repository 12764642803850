import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Table,
  PageHeader,
  Card,
  Divider,
  Input,
  Row,
  Col,
  Icon,
} from 'antd';
import { find, get } from 'lodash';
import {
  fetchAllClusters,
  saveCluster,
  editCluster,
  cancelEdit,
  filterClusters,
} from './actions';
import { fetchGroupHubsCount } from '../FeatureManagement/actions';
import { fetchLaunchGroupChangeLog, setLaunchGroupChangeLog } from '../HubManagement/actions';
import Content from '../../component/Content';
import EditModalForm from './EditModal';
import ChangeLogModal from '../HubManagement/ChangeLogModal';
import MultiSelect from '../../component/MultiSelect';
import Select from '../../component/Select';
import variations from '../../constants/defaultSettingVariationConstants';
import s from './index.module.sass';

const { Search } = Input;

class ClusterManagement extends PureComponent {
  state = {
    clusterSearch: '',
    launchGroupsSelected: [],
    featureSelected: '',
    openChangeLogModal: false
  };

  componentDidMount = () => {
    const { fetchAllClusters, fetchGroupHubsCount } = this.props;
    fetchAllClusters();
    fetchGroupHubsCount('cluster');
  };

  componentWillUnmount = () => {
    const { filtersEnabled } = this.props;
    if (filtersEnabled) this.clearAllFilters();
  };

  saveCluster = clusterId =>
    this.props.history.push(`/clusters/save/${clusterId}`);

  renderActions = clusterId => {
    return (
      <span>
        <span className={s.actionDisplay} onClick={() => this.saveCluster(clusterId)}>
          Save Configuration{' '}
        </span>
        <Divider type="vertical" />
        <span className={s.actionDisplay} onClick={() => this.handleEditModal(clusterId)}>
          Edit{' '}
        </span>
      </span>
    );
  };

  handleEditModal = clusterId => {
    this.props.fetchGroupHubsCount('cluster')
    this.props.editCluster(clusterId)
  }

  renderTitle = (name, row) => (
    <div>
      <span className={s.name}>{name}</span>
      <span className={s.domain}>{row.domain}</span>
    </div>
  );

  closeChangeLogModal = () => {
    this.setState({openChangeLogModal: false})
    this.props.setLaunchGroupChangeLog()
  }

  openChangeLogModal = id => {
    this.setState({openChangeLogModal: true})
    this.props.fetchLaunchGroupChangeLog({id, source: 'Cluster'})
  }

  renderLaunchGroup = (defaultSetting, row) => {
    return (
      <span>
        <p>{this.renderLaunchGroupValue(defaultSetting)}</p>
        <span  className={s.actionDisplay} onClick={() => this.openChangeLogModal(row.id)}>Change Log</span>
      </span>
    )
  };

  renderLaunchGroupValue = variationKey => {
    const currentVariation = find(variations, { key: variationKey });

    return <span>{get(currentVariation, 'name', '')}</span>;
  };

  createNewCluster = () => this.props.history.push('/clusters/new/');

  viewClusterConfigs = () => this.props.history.push('/clusters/configs/');

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: this.renderTitle,
    },
    {
      title: 'Launch Group',
      dataIndex: 'defaultSetting',
      key: 'defaultSetting',
      render: this.renderLaunchGroup,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      width: 350,
      render: this.renderActions,
    },
  ];

  handleSave = cluster => {
    this.props.saveCluster(cluster);
  };

  renderTitle = () => <h3>Clusters</h3>;

  handleSearchChange = e => this.setState({ clusterSearch: e.target.value });

  handleNameSearch = () => {
    this.handleClusterFilters();
  };

  handleLaunchGroupFilterChange = launchGroupsSelected =>
    this.setState({ launchGroupsSelected });

  handleFeatureFilterChange = featureSelected =>
    this.setState({ featureSelected });

  handleClusterFilters = () => {
    const { clusterSearch, launchGroupsSelected, featureSelected } = this.state;
    const { filterClusters, modulesList } = this.props;
    let featureObj = {};
    if (featureSelected !== '') {
      const [key, value] = featureSelected.split('#$#');
      const data = find(modulesList, { key });
      if (value === 'enabled')
        featureObj = {
          key,
          value: true,
          filterKey: get(data, 'otherSettings.filterKey', 'enabled'),
        };
      else if (value === 'disabled')
        featureObj = {
          key,
          value: false,
          filterKey: get(data, 'otherSettings.filterKey', 'enabled'),
        };
    }
    filterClusters({
      nameSearch: clusterSearch,
      launchGroupsFilter: launchGroupsSelected,
      featureFilter: featureObj,
    });
  };

  filterButtonEnabled = () => {
    const { clusterSearch, launchGroupsSelected, featureSelected } = this.state;
    if (
      clusterSearch === '' &&
      launchGroupsSelected.length === 0 &&
      featureSelected === ''
    )
      return false;
    else return true;
  };

  clearAllFilters = () => {
    this.setState({
      clusterSearch: '',
      launchGroupsSelected: [],
      featureSelected: '',
    });
    this.props.fetchAllClusters();
  };

  render() {
    const {
      clustersLoading: loading,
      clusters,
      filtersEnabled,
      modulesList,
      groupClustersCount,
      changeLogs,
      changeLogsLoading
    } = this.props;
    const { featureSelected, launchGroupsSelected } = this.state;
    const isFilterButtonEnabled = this.filterButtonEnabled();
    return (
      <div>
        <PageHeader
          title="Cluster Management"
          extra={[
            <Button key="conf" onClick={this.viewClusterConfigs}>
              {' '}
              View Cluster Configs{' '}
            </Button>,
            <Button
              key="add"
              onClick={this.createNewCluster}
              type="primary"
              icon="plus-circle"
            >
              {' '}
              Add a Cluster{' '}
            </Button>,
          ]}
        >
          <Divider />
          <Row type="flex">
            <Col span={6}>
              <Search
                placeholder="Search By Name, Cluster ID"
                style={{ width: '80%' }}
                value={this.state.clusterSearch}
                onChange={this.handleSearchChange}
                onSearch={this.handleNameSearch}
              />
            </Col>
            <Col span={6}>
              <MultiSelect
                optionFilterProp="children"
                dataSource={variations}
                value={launchGroupsSelected}
                onChange={this.handleLaunchGroupFilterChange}
              />
            </Col>
            <Col span={12}>
              <Select
                placeholder="Filter by Features"
                dataSource={modulesList}
                value={featureSelected}
                onChange={this.handleFeatureFilterChange}
              />
              <Divider type="vertical" />
              <Button
                type="primary"
                disabled={!isFilterButtonEnabled}
                onClick={this.handleClusterFilters}
              >
                <Icon type="filter" />
                Apply Filters
              </Button>
              <Divider type="vertical" />
              <Button disabled={!filtersEnabled} onClick={this.clearAllFilters}>
                <Icon type="sync" />
                Clear All
              </Button>
            </Col>
          </Row>
        </PageHeader>
        <Content>
          <Card bordered={false}>
            <Table
              rowKey="id"
              loading={loading}
              columns={this.columns}
              dataSource={clusters}
              title={this.renderTitle}
            />
          </Card>
        </Content>
        {this.state.openChangeLogModal && 
          <ChangeLogModal
            close={() => this.closeChangeLogModal()}
            openModal={this.state.openChangeLogModal}
            changeLogs={changeLogs}
            loading={changeLogsLoading}
          />
        }
        <EditModalForm
          onCancel={this.props.cancelEdit}
          groupClustersCount={groupClustersCount}
          onSave={this.handleSave}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  hub: { changeLogsLoading, changeLogs },
  cluster: {
    clusters,
    clustersLoading,
    saveModalVisible,
    filtersEnabled,
    modulesList,
  },
  features: { groupClustersCount }
}) => ({
  clusters,
  clustersLoading,
  saveModalVisible,
  filtersEnabled,
  modulesList,
  groupClustersCount,
  changeLogsLoading,
  changeLogs
});

const mapDispatchToProps = {
  fetchAllClusters,
  saveCluster,
  editCluster,
  cancelEdit,
  filterClusters,
  fetchGroupHubsCount,
  fetchLaunchGroupChangeLog,
  setLaunchGroupChangeLog
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClusterManagement)
);
