import { SET_ML_SERVICE_API_RESPONSE } from '../actions';

const initialState = {
  mlServiceApi: {
    loading: false,
    response: null,
  },
};

const MLService = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ML_SERVICE_API_RESPONSE:
      return {
        ...state,
        mlServiceApi: {
          ...state.mlServiceApi,
          ...data,
        },
      };
    default:
      return state;
  }
};

export default MLService;
