import React from 'react';
import { Card, Table } from 'antd';
import { connect } from 'react-redux';
import {
  fetchProcessingImport,
  clearImportState,
  setImportListPage,
  handleRestartClick,
} from './actions';
import s from './index.module.scss';
import columns from './tableColumns';

class ManageImport extends React.Component {
  componentDidMount() {
    const { fetchProcessingImport } = this.props;
    fetchProcessingImport();
  }

  componentWillUnmount() {
    const { clearImportState } = this.props;
    clearImportState();
  }

  handlePageChange = page => {
    const { setImportListPage, fetchProcessingImport } = this.props;
    setImportListPage(page);
    fetchProcessingImport();
  };

  handleRestartClick = (id, status) => () => {
    const { handleRestartClick } = this.props;
    handleRestartClick({
      id,
      status,
    });
  };

  render() {
    const {
      importListLoading,
      importListCount,
      importList,
      queueStatus,
    } = this.props;
    return (
      <div className={s['manage-import']}>
        <h3>Manage Imports</h3>
        <Card>
          {!importListLoading && (
            <p>
              Current queue state: <b>{queueStatus.state}</b>, Current queue count: <b>{queueStatus.messages}</b>
            </p>
          )}
          <Table
            columns={columns(this.handleRestartClick)}
            dataSource={importList}
            loading={importListLoading}
            rowKey={record => record.id}
            pagination={{
              total: parseInt(importListCount, 10),
              onChange: this.handlePageChange,
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ manageImport }) => {
  const {
    importListLoading,
    importListCount,
    importList,
    queueStatus,
  } = manageImport;
  return {
    importListLoading,
    importListCount,
    importList,
    queueStatus,
  };
};

const mapDispatchToProps = {
  fetchProcessingImport,
  clearImportState,
  setImportListPage,
  handleRestartClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageImport);
