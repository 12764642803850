import { takeLatest } from 'redux-saga/effects';

import { REFRESH_ALL, REFRESH_USERS } from '../actions';

import { refreshAllSaga, refreshUsersSaga } from './sagas';

export default [
  takeLatest(REFRESH_ALL, refreshAllSaga),
  takeLatest(REFRESH_USERS, refreshUsersSaga),
];
