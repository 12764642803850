import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import ExperimentTable from './ExperimentTable';
import { fetchExperiments, updateExperiment, deleteExperiment, fetchAdminTags, addExperimentHub, deleteExperimentHub, setExperimentHubMapping } from '../../actions/index';
import './abTesting.scss';

class ABTesting extends PureComponent {

	constructor(props) {
		super(props);
		const { fetchExperiments, fetchAdminTags } = props;
		fetchExperiments();
		fetchAdminTags();
	}

	render() {
		const { experiments, experimentsLoading, updateExperiment, deleteExperiment, adminTag, addExperimentHub, deleteExperimentHub, setExperimentHubMapping } = this.props;
		return (
			<div>
				<ExperimentTable 
					experiments={experiments} 
					experimentsLoading={experimentsLoading} 
					updateExperiment={updateExperiment}
					deleteExperiment={deleteExperiment}
					adminTag={adminTag}
					addExperimentHub={addExperimentHub}
					deleteExperimentHub={deleteExperimentHub}
					setExperimentHubMapping={setExperimentHubMapping}
				/> 
			</div>
		)
	}
}



const mapStateToProps = ({ abTesting, adminTag }) => ({
	experiments: abTesting.experiments,
	experimentsLoading: abTesting.experimentsLoading,
	adminTag: adminTag
});

const mapDispatchToProps = {
	fetchExperiments,
	updateExperiment,
	deleteExperiment,
	addExperimentHub,
	deleteExperimentHub,
	fetchAdminTags,
	setExperimentHubMapping,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ABTesting);