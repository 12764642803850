/* eslint-disable import/prefer-default-export */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDefaultTextAPI } from '../../../api';
import DefaultTextKeys from '../queryKeys';

const { listClusterText, listHubText } = DefaultTextKeys;

const updateDefaultText = async payload => {
  await updateDefaultTextAPI(payload);
};

export const useDefaultTextUpdateMutation = (props = {}) => {
  const queryClient = useQueryClient();
  return useMutation(updateDefaultText, {
    ...(props || {}),
    onSuccess: (response, request) => {
      const invalidQueryKey = request.isClusterText
        ? listClusterText
        : listHubText;
      queryClient.invalidateQueries([invalidQueryKey]);
      props?.onSuccess?.();
    },
  });
};
