import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Form,
  Select,
  Button,
  Tabs,
  message,
  PageHeader,
  Card,
  Icon,
  Spin,
} from 'antd';
import Content from '../../../component/Content';
import { fetchAllHubConfig, fetchAllHubs, createHubs } from '../actions';
import { fetchAllClusters } from '../../ClusterManagement/actions';
import {
  breadCrumbItemRender,
  isValidHubIdentifier,
} from '../../../utils/helpers';
import HubForm from './HubForm';
import enablementGroups from '../../../constants/defaultSettingVariationConstants';
import { cluster } from '../../../api';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const breadcrumbConf = { 
  routes: [
    {
      path: '/hubs',
      breadcrumbName: 'Hubs',
    },
    {
      path: '/hubs/new',
      breadcrumbName: 'Create Hub',
    },
  ],
  itemRender: breadCrumbItemRender
}

const FormItem = props  => 
  <Form.Item labelCol={{ span: 2}} wrapperCol={{ span: 6 }} {...props}/>
class CreateHub extends PureComponent {

  newTabIndex = 0;

  getDefaultHub = () => ({
    key: (this.newTabIndex++).toString(),
    name: '',
    configType: 'none',
    copyHubId: undefined,
    hubConfigId: undefined,
    copyUsers: false,
    checked: [],
    expanded: [],
  });

  state = {
    clusterId: undefined,
    hasAnyV2Hub: false,
    newHubs: [ this.getDefaultHub() ],
    selectedCluster: undefined,
    hubTabKey: '0',
    selectedEnabledGroup: {},
  };

  componentDidMount() {
    if(!this.props.hubs) this.props.fetchAllHubs();
    if(!this.props.hubConfigs) this.props.fetchAllHubConfig();
    if(!this.props.clusters) this.props.fetchAllClusters();
  }

  onNewHubsChange = newHubs => this.setState({ newHubs });

  changeHubProp = newProps => {
    const { hubTabKey, newHubs } = this.state;
    const newnewHubs = newHubs.map(h => {
      if (h.key == hubTabKey)
        return {
          ...h,
          ...newProps,
        };
      return h;
    });
    this.onNewHubsChange(newnewHubs);
  };

  onClusterChange = async clusterId => {
    const { clusters } = this.props;
    const selectedCluster = clusters?.find(cluster => cluster.id === clusterId);
    const { data: { hasAnyV2Hub } } = await cluster.multiHubCheck({
      clusterId: selectedCluster.id,
    });
    this.setState({ selectedCluster, hasAnyV2Hub });
    const group = enablementGroups.find(
      gp => gp.key === selectedCluster?.defaultSetting
    );
    this.setState({ selectedEnabledGroup: group });

    return this.setState({ clusterId });
  };

  onHubTabChange = hubTabKey => this.setState({ hubTabKey });

  onHubTabEdit = (key, action) => {
    let { newHubs, hubTabKey } = this.state;
    switch(action) {
      case 'add': {
        const newHub = this.getDefaultHub();
        newHubs = newHubs.concat([ newHub ]);
        hubTabKey = newHub.key.toString();
        this.setState({ hubTabKey, newHubs });
        break;
      }
      case 'remove': {
        if(newHubs.length === 1) { 
          message.warn('Please configure atleast one hub to continue');
        } else {
          newHubs = newHubs.filter(h => h.key != key);
          hubTabKey = newHubs.find(h => h.key == hubTabKey) ? hubTabKey : newHubs[0].key.toString()
          this.setState({ newHubs, hubTabKey });
        }
        break;
      }
    }
  } 

  renderHubTabPane = hub => {
    const { hubTabKey, newHubs, selectedEnabledGroup, hasAnyV2Hub } = this.state;
    const { hubs, hubsLoading, hubConfigs } = this.props;
    return (
      <TabPane tab={hub.name || '(unnamed hub)'} key={hub.key}>
        <HubForm
          hubTabKey={hubTabKey}
          newHubs={newHubs}
          selectedEnabledGroup={selectedEnabledGroup}
          hubs={hubs}
          isV2Present={hasAnyV2Hub}
          hubsLoading={hubsLoading}
          hubConfigs={hubConfigs}
          hubConfigsLoading={this.hubConfigsLoading}
          changeHubProp={this.changeHubProp}
        />
      </TabPane>
    )
  }

  addHubBtn = () => {
    const { hasAnyV2Hub } = this.state;
    return (
      <Button
        type="primary"
        disabled={hasAnyV2Hub}
        onClick={() => this.onHubTabEdit(null, 'add')}
      >
        <Icon type="plus-circle" />
        Add hub
      </Button>
    );
  };

  validateForm = () => {
    const { clusterId, newHubs } = this.state;
    if (!clusterId)  {
      message.warn('Please select a cluster');
      return false  
    }

    const hubError = (hub, msg) => {
      this.setState({ hubTabKey: hub.key });
      message.warn(msg)
    }

    return newHubs.every(hub => {
      if (hub.name === '') return hubError(hub, 'Please input a name for the hub');
      if (hub.identifier && !isValidHubIdentifier(hub.identifier)) {
        return hubError(
          hub,
          `Invalid hub identifier - ${
            hub.identifier
          }. It should be a combination of alphabets, numbers or underscore`
        );
      }
      if (hub.configType === 'copy' && !hub.copyHubId) return hubError(hub, 'Please select a hub to copy from');
      if (hub.configType === 'hubConfig' && !hub.hubConfigId) return hubError('Please select a hubConfig to import from');
      return true;
    });
  };

  tryCreateHubs = () => {
    const { clusterId, newHubs, selectedEnabledGroup } = this.state;
    if(!this.validateForm()) return;
    this.props.createHubs({ 
      clusterId,
      newHubs,
      defaultSetting: selectedEnabledGroup.key,
    });
  }

  onClusterFilter = (input, option) => {
    const { clusters } = this.props;
    const cluster =  clusters.find(c => c.id === parseInt(option.key))
    return cluster.name.toLowerCase().includes(input.toLowerCase()) 
  }

  render() {
    const { hubTabKey, newHubs, clusterId, hasAnyV2Hub } = this.state;
    const { clusters, clustersLoading, hubCreateLoading } = this.props;
    return (
      <div>
        <PageHeader title="Create A New Hub" breadcrumb={breadcrumbConf} />
        <Content>
          <Spin spinning={hubCreateLoading}>
            <Card>
              <Form layout="horizontal">
                <FormItem label="Cluster">
                  <Select
                    style={{ width: 300 }}
                    showSearch
                    placeholder="Select Cluster"
                    onChange={this.onClusterChange}
                    filterOption={this.onClusterFilter}
                    loading={clustersLoading}
                    value={clusterId}
                  >
                    {clusters &&
                      clusters.map(cluster => (
                        <Option key={cluster.id} value={cluster.id}>
                          {cluster.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Form>
              <h3>Hub Configurations</h3>
              <Tabs
                onChange={this.onHubTabChange}
                activeKey={hubTabKey.toString()}
                type="editable-card"
                onEdit={this.onHubTabEdit}
                tabBarExtraContent={this.addHubBtn()}
                hideAdd
              >
                {newHubs.map(hub => this.renderHubTabPane(hub))}
              </Tabs>
              <Button
                style={{ marginTop: '20px' }}
                onClick={this.tryCreateHubs}
                disabled={hasAnyV2Hub}
              >
                Next
              </Button>
            </Card>
          </Spin>
        </Content>
      </div> 
    );
  }
}

const mapStateToProps = ({
  hub: { hubConfigs, hubConfigsLoading, hubs, hubsLoading, hubCreateLoading },
  cluster: { clusters, clustersLoading },
}) => ({
  hubConfigs,
  hubConfigsLoading,
  hubs,
  hubsLoading,
  clusters,
  clustersLoading,
  hubCreateLoading,
});

const mapDispatchToProps = {
  fetchAllHubConfig,
  fetchAllHubs,
  fetchAllClusters,
  createHubs,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateHub)
);
