import { get } from 'lodash';

const prefix = `AutomatedMarketing/`;

// Actions
export const SET_TEMPLATES_LOADING = `${prefix}SET_TEMPLATES_LOADING`;
export const SET_TEMPLATES = `${prefix}SET_TEMPLATES`;
export const SET_TEMPLATE_CATEGORIES = `${prefix}SET_TEMPLATE_CATEGORIES`;
export const SET_EDITOR_LOADING = `${prefix}SET_EDITOR_LOADING`;
export const SET_EMAIL_SENDING = `${prefix}SET_EMAIL_SENDING`;
export const SET_BEEFREE_TOKEN = `${prefix}SET_BEEFREE_TOKEN`;
export const SET_BEEFREE_DATA = `${prefix}SET_BEEFREE_DATA`;
export const SET_BEEFREE_INSTANCE = `${prefix}SET_BEEFREE_INSTANCE`;
export const SET_CONFIRM_CLOSE_MODAL_PATH = `${prefix}SET_CONFIRM_CLOSE_MODAL_PATH`;
export const RESET_STATE = `${prefix}RESET_STATE`;
export const SET_DRAFT_DATA = `${prefix}SET_DRAFT_DATA`;
export const SET_EMAILS_LOADING = `${prefix}SET_EMAILS_LOADING`;
export const SET_EMAILS = `${prefix}SET_EMAILS`;
export const ADD_EMAIL = `${prefix}ADD_EMAIL`;
export const SET_PREVIEW_TEMPLATE = `${prefix}SET_PREVIEW_TEMPLATE`;
export const SET_STATS_LOADING = `${prefix}SET_STATS_LOADING`;
export const SET_STATS = `${prefix}SET_STATS`;
export const SET_LOADING_TEMPLATE = `${prefix}SET_LOADING_TEMPLATE`;
export const REMOVE_TEMPLATE = `${prefix}REMOVE_TEMPLATE`;
export const SET_JOB_TEMPLATES = `${prefix}SET_JOB_TEMPLATES`;

export const SET_ALL_CAMPAIGNS_LOADING = `${prefix}SET_ALL_CAMPAIGNS_LOADING`;
export const SET_ALL_CAMPAIGNS = `${prefix}SET_ALL_CAMPAIGNS`;
export const SET_CAMPAIGN_LOADING = `${prefix}SET_CAMPAIGN_LOADING`;
export const SET_CAMPAIGN_INFO = `${prefix}SET_CAMPAIGN_INFO`;
export const UPDATE_CAMPAIGN_INFO = `${prefix}UPDATE_CAMPAIGN_INFO`;
export const RESET_CAMPAIGN_INFO =  `${prefix}RESET_CAMPAIGN_INFO`;

// Saga Actions
export const HANDLE_FETCH_TEMPLATES = `${prefix}HANDLE_FETCH_TEMPLATES`;
export const HANDLE_INIT_EMAIL_EDITOR = `${prefix}HANDLE_INIT_EMAIL_EDITOR`;
export const HANDLE_OPEN_EMAIL_EDITOR = `${prefix}HANDLE_OPEN_EMAIL_EDITOR`;
export const HANDLE_SAVE_EMAIL = `${prefix}HANDLE_SAVE_EMAIL`;
export const HANDLE_FETCH_EMAILS = `${prefix}HANDLE_FETCH_EMAILS`;
export const HANDLE_SEND_TEST_EMAIL = `${prefix}HANDLE_SEND_TEST_EMAIL`;
export const HANDLE_FETCH_STATS = `${prefix}HANDLE_FETCH_STATS`;
export const HANDLE_DELETE_TEMPLATE = `${prefix}HANDLE_DELETE_TEMPLATE`;
export const HANDLE_FETCH_ALL_CAMPAIGNS = `${prefix}HANDLE_FETCH_ALL_CAMPAIGNS`;
export const HANDLE_CAMPAIGN_ACTIVE_TOGGLE = `${prefix}HANDLE_CAMPAIGN_ACTIVE_TOGGLE`;
export const HANDLE_CAMPAIGN_DELETE = `${prefix}HANDLE_CAMPAIGN_DELETE`;
export const HANDLE_GET_CAMPAIGN_INFO = `${prefix}HANDLE_GET_CAMPAIGN_INFO`;
export const HANDLE_CREATE_OR_UPDATE_CAMPAIGN = `${prefix}HANDLE_CREATE_OR_UPDATE_CAMPAIGN`;
export const HANDLE_DELETE_CAMPAIGN_EMAIL = `${prefix}HANDLE_DELETE_CAMPAIGN_EMAIL`;
export const HANDLE_UPDATE_CAMPAIGN_EMAIL = `${prefix}HANDLE_UPDATE_CAMPAIGN_EMAIL`;
export const HANDLE_CREATE_CAMPAIGN_EMAIL = `${prefix}HANDLE_CREATE_CAMPAIGN_EMAIL`;

// Action Creators
export const setTemplatesLoading = data => ({
  type: SET_TEMPLATES_LOADING,
  data,
});
export const setTemplates = data => ({
  type: SET_TEMPLATES,
  data,
});
export const setTemplateCategories = data => ({
  type: SET_TEMPLATE_CATEGORIES,
  data,
});
export const setEditorLoading = data => ({
  type: SET_EDITOR_LOADING,
  data,
});
export const setEmailSending = data => ({
  type: SET_EMAIL_SENDING,
  data,
});
export const setBeefreeToken = data => ({
  type: SET_BEEFREE_TOKEN,
  data,
});
export const setBeefreeData = data => ({
  type: SET_BEEFREE_DATA,
  data,
});
export const setBeefreeInstance = data => ({
  type: SET_BEEFREE_INSTANCE,
  data,
});
export const setConfirmCloseModalPath = data => ({
  type: SET_CONFIRM_CLOSE_MODAL_PATH,
  data,
});
export const resetState = data => ({
  type: RESET_STATE,
  data,
});
export const setDraftData = data => ({
  type: SET_DRAFT_DATA,
  data,
});
export const setEmailsLoading = data => ({
  type: SET_EMAILS_LOADING,
  data,
});
export const setEmails = data => ({
  type: SET_EMAILS,
  data,
});
export const addEmail = data => ({
  type: ADD_EMAIL,
  data,
});
export const setPreviewTemplate = data => ({
  type: SET_PREVIEW_TEMPLATE,
  data,
});
export const setStatsLoading = data => ({
  type: SET_STATS_LOADING,
  data,
});
export const setStats = data => ({
  type: SET_STATS,
  data,
});
export const setLoadingTemplate = data => ({
  type: SET_LOADING_TEMPLATE,
  data,
});
export const removeTemplate = data => ({
  type: REMOVE_TEMPLATE,
  data,
});
export const setJobTemplates = data => ({
  type: SET_JOB_TEMPLATES,
  data,
});
export const setAllCampaignsLoading = data => ({
  type: SET_ALL_CAMPAIGNS_LOADING,
  data,
});

export const setAllCampaigns = data => ({
  type: SET_ALL_CAMPAIGNS,
  data,
});

export const setCampaignInfoLoading = data => ({
  type: SET_CAMPAIGN_LOADING,
  data
});

export const setCampaignInfo = data => ({
  type: SET_CAMPAIGN_INFO,
  data
});

export const updateCampaignInfo = data => ({
  type: UPDATE_CAMPAIGN_INFO,
  data
});

export const resetCampaignInfo = data => ({
  type: RESET_CAMPAIGN_INFO,
  data,
});


// Saga Action Creators
export const handleFetchTemplates = data => ({
  type: HANDLE_FETCH_TEMPLATES,
  data,
});
export const handleInitEmailEditor = data => ({
  type: HANDLE_INIT_EMAIL_EDITOR,
  data,
});
export const handleOpenEmailEditor = data => ({
  type: HANDLE_OPEN_EMAIL_EDITOR,
  data,
});
export const handleSaveEmail = data => ({
  type: HANDLE_SAVE_EMAIL,
  data,
});
export const handleFetchEmails = data => ({
  type: HANDLE_FETCH_EMAILS,
  data,
});
export const handleSendTestEmail = data => ({
  type: HANDLE_SEND_TEST_EMAIL,
  data,
});
export const handleFetchStats = data => ({
  type: HANDLE_FETCH_STATS,
  data,
});
export const handleDeleteTemplate = data => ({
  type: HANDLE_DELETE_TEMPLATE,
  data,
});

export const handleFetchAllCampaigns = data => ({
  type: HANDLE_FETCH_ALL_CAMPAIGNS,
  data,
}); 

export const handleIsActiveToggle = data => ({
  type: HANDLE_CAMPAIGN_ACTIVE_TOGGLE,
  data,
});

export const handleCampaignDelete = data => ({
  type: HANDLE_CAMPAIGN_DELETE,
  data
});

export const handleGetCampaignInfo = data => ({
  type: HANDLE_GET_CAMPAIGN_INFO,
  data
});

export const handleCreateOrUpdateCampaignInfo = data => ({
  type: HANDLE_CREATE_OR_UPDATE_CAMPAIGN,
  data
});

export const handleDeleteCampaignEmail = data => ({
  type: HANDLE_DELETE_CAMPAIGN_EMAIL,
  data
});

export const handleUpdateEmailTemplate = data => ({
  type: HANDLE_UPDATE_CAMPAIGN_EMAIL,
  data,
});

export const handleCreateEmailTemplate = data => ({
  type: HANDLE_CREATE_CAMPAIGN_EMAIL,
  data,
});

// SELECTORS
export const automatedMarketingSelector = state => state.automatedMarketing;
export const templatesSelector = state =>
  get(state, 'automatedMarketing.templates') || [];
export const emailsSelector = state =>
  get(state, 'automatedMarketing.emails') || [];
