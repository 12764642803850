import { useMutation, useQuery } from '@tanstack/react-query';
import { trim } from 'lodash';
import {
  createBenchmarkingGroup,
  fetchBenchmarkingGroups,
  updateBenchmarkingGroup,
  deleteBenchmarkingGroup,
  hub,
} from '../../../api';

const base = 'BenchmarkingGroups';
const queryKeys = {
  getBenchmarkingGroups: `${base}/GetBenchmarkingGroups`,
  searchHubs: `${base}/SearchHubs`,
};

const createHubBenchmarkingGroup = async data => {
  const addedBenchmarkingGroup = await createBenchmarkingGroup(data);
  return addedBenchmarkingGroup;
};

const useCreateBenchmarkingGroup = () =>
  useMutation(data => createHubBenchmarkingGroup(data));

const getAllBenchmarkingGroups = async () => {
  const response = await fetchBenchmarkingGroups();
  const { data } = response;
  return data;
};

const useGetBenchmarkingGroupsQuery = () =>
  useQuery([queryKeys.getBenchmarkingGroups], () => getAllBenchmarkingGroups());

const getHubsApi = async ({ searchText }) => {
  const trimmedSearchText = trim(searchText);
  if (!trimmedSearchText || trimmedSearchText.length < 3) {
    return [];
  }

  const params = {
    fields: ['id', 'name', 'identifier'],
    nameSearch: trimmedSearchText,
    launchGroupsFilter: [],
  };
  const response = await hub.filterHubs(params);
  const { data } = response;
  return data;
};

const useGetHubsQuery = ({ searchText }) =>
  useQuery([queryKeys.searchHubs, searchText], () =>
    getHubsApi({ searchText })
  );

const updateHubBenchmarkingGroup = async data => {
  const { id, ...rest } = data;
  const addedBenchmarkingGroup = await updateBenchmarkingGroup(id, rest);
  return addedBenchmarkingGroup;
};

const useUpdateBenchmarkingGroup = () =>
  useMutation(data => updateHubBenchmarkingGroup(data));

const deleteHubBenchmarkingGroup = async id => {
  const addedBenchmarkingGroup = await deleteBenchmarkingGroup(id);
  return addedBenchmarkingGroup;
};

const useDeleteBenchmarkingGroup = () =>
  useMutation(data => deleteHubBenchmarkingGroup(data));

export {
  useCreateBenchmarkingGroup,
  useUpdateBenchmarkingGroup,
  useDeleteBenchmarkingGroup,
  useGetHubsQuery,
  useGetBenchmarkingGroupsQuery,
};
