import {
  GET_HUBS_DATA,
  SET_HUBS_LOADING,
  SET_HUBS_DATA,
  SET_INVITED_HUBS_DATA,
} from '../actions';

const initialState = {
  hubsData: [],
  hubDataLoading: false,
  allInvitedHubs: [],
  allInvitedHubsLoading: false,
};

const bridgesAllianceReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_HUBS_DATA:
      return {
        ...state,
        hubsData: data || [],
      };
    case SET_HUBS_LOADING:
      return {
        ...state,
        hubDataLoading: data,
      };
    case SET_HUBS_DATA:
      return {
        ...state,
        hubsData: data || [],
      };
    case SET_INVITED_HUBS_DATA:
      return {
        ...state,
        allInvitedHubs: data.data || [],
        allInvitedHubsLoading: data.loading,
      };
    default:
      return state;
  }
};

export default bridgesAllianceReducer;
