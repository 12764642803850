import { useQuery } from '@tanstack/react-query';
import { fetchGlobalListsApi } from '../../../api';
import AchievementKeys from '../queryKeys/GlobalLists';

const getGlobalLists = async ({ page }) => {
  const response = await fetchGlobalListsApi(page);
  const { data } = response;
  return data;
};

const useGetGlobalListsQuery = page =>
  useQuery(
    [AchievementKeys.globalLists, page],
    () => getGlobalLists({ page }),
    {
      keepPreviousData: true,
    }
  );

export default useGetGlobalListsQuery;
