import { filter, map, intersection } from 'lodash';
import moment from 'moment';


export const shouldShowConfirmation = (allBanners = [], thisBanner) => {
    let thisBannerHubs = thisBanner.objectId || 'ALL';
    let activeBanners = filter(allBanners, 'active');
    let allHubBanners = filter(activeBanners, (o) => !o.objectId);

    if ((thisBannerHubs !== 'ALL' && !thisBannerHubs.length) || !activeBanners.length) {
        return false;
    }
    if (allHubBanners.length || thisBannerHubs === 'ALL') {
        return true;
    }

    let sameHubBanners = filter(activeBanners, (o) => {
        if(Array.isArray(o.objectId) && intersection(thisBannerHubs,o.objectId).length) {
            return true;
        }
    });
    
    return sameHubBanners.length > 0;
};

export const missingRequiredFieldMsg = (bannerObj, t) => {
    if (bannerObj.objectId && !bannerObj.objectId.length && bannerObj.launchGroup && !bannerObj.launchGroup.length) {
        return 'Both hub(s) and Launch Group(s) haven’t been specified for this banner. To continue, please select hub(s) or launch Group(s) where the banner should be active.';
    } else if (!t && moment(bannerObj.endDate).isBefore(moment(), 'day')) {
        return `The banner's end date has already passed. To continue, please set a new date range.`;
    }
};
