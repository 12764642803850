import {
  CLEAR_BASE_CONFIG_STATE,
  CLEAR_JOB_STATUS_MODAL_STATE,
  CLEAR_STANDARD_STATE,
  CLEAR_VERIFICATION_MODAL_STATE,
  INIT_VERIFICATION_MODAL_VISIBLE,
  SET_BASE_CONFIG_HUB_OPTIONS,
  SET_BASE_CONFIG_LOADING,
  SET_BASE_CONFIG_SAVING,
  SET_BASE_CONFIG,
  SET_BASE_CONFIG_DIFF,
  SET_CLUSTER_HUB_FOR_VERIFICATION,
  SET_CLUSTER_MODULES,
  SET_FILTERS_ENABLED,
  SET_HUB_MODULES,
  SET_HUBS_DATA,
  SET_JOB_ID,
  SET_JOB_STATUS_DATA,
  SET_JOB_STATUS_MODAL_VISIBLE,
  SET_LIST_LOADING,
  SET_PAGE,
  SET_PLATFORM_TYPE,
  SET_SEARCH_TEXT,
  SET_SELECTED_HUB_ID,
  SET_TOTAL,
  SET_VERIFICATION_STATUS,
  STANDARD_MIGRATION_STARTING,
  VERIFICATION_DATA_LOADING,
  SET_SELECTED_HUB_CUSTOM_FIELDS,
  SET_PG_STANDARD_DATA_FIELDS,
  SET_API_LOADING,
  SET_STANDARD_FIELDS_UPDATED,
  SET_ALLOWED_USER_TYPES,
} from '../actions';

const initialState = {
  hubsData: [],
  page: 1,
  total: 0,
  pageSize: 10,
  searchText: '',
  platformType: undefined,
  listLoading: false,
  filtersEnabled: false,

  baseConfigLoading: false,
  baseConfigSaving: false,
  baseConfig: {},
  baseConfigDiff: {},
  selectedHubId: undefined,
  baseConfigHubOptions: [],

  initVerificationModalVisible: false,
  verificationDataLoading: false,
  verificationStatus: [],
  clusterHubForVerification: {},
  hubModules: [],
  clusterModules: [],
  allowedUserTypes: [],

  standardMigrationStarting: false,
  jobStatusData: {},
  jobId: null,
  jobStatusModalVisible: false,
  selectedHubCustomFields: [],
  pgStandardDataFields: [],
  apiLoading: false,
  standardFieldsUpdated: false,
};

const manageReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CLEAR_BASE_CONFIG_STATE: {
      return {
        ...state,
        baseConfigLoading: false,
        baseConfig: {},
        baseConfigHubOptions: [],
      };
    }
    case CLEAR_JOB_STATUS_MODAL_STATE: {
      return {
        ...state,
        jobStatusData: {},
        jobId: null,
        jobStatusModalVisible: false,
      };
    }
    case CLEAR_STANDARD_STATE: {
      return {
        ...initialState,
      };
    }
    case CLEAR_VERIFICATION_MODAL_STATE: {
      return {
        ...state,
        initVerificationModalVisible: false,
        verificationDataLoading: false,
        verificationStatus: [],
        clusterHubForVerification: {},
        hubModules: [],
        clusterModules: [],
      };
    }
    case INIT_VERIFICATION_MODAL_VISIBLE: {
      return {
        ...state,
        initVerificationModalVisible: data,
      };
    }
    case SET_BASE_CONFIG_HUB_OPTIONS: {
      return {
        ...state,
        baseConfigHubOptions: data,
      };
    }
    case SET_BASE_CONFIG_LOADING: {
      return {
        ...state,
        baseConfigLoading: data,
      };
    }
    case SET_BASE_CONFIG_SAVING: {
      return {
        ...state,
        baseConfigSaving: data,
      };
    }
    case SET_BASE_CONFIG: {
      return {
        ...state,
        baseConfig: data,
      };
    }
    case SET_BASE_CONFIG_DIFF: {
      return {
        ...state,
        baseConfigDiff: data,
      };
    }
    case SET_CLUSTER_HUB_FOR_VERIFICATION: {
      return {
        ...state,
        clusterHubForVerification: data,
      };
    }
    case SET_CLUSTER_MODULES: {
      return {
        ...state,
        clusterModules: data,
      };
    }
    case SET_FILTERS_ENABLED: {
      return {
        ...state,
        filtersEnabled: data,
      };
    }
    case SET_HUB_MODULES: {
      return {
        ...state,
        hubModules: data,
      };
    }
    case SET_HUBS_DATA: {
      return {
        ...state,
        hubsData: data,
      };
    }
    case SET_JOB_ID: {
      return {
        ...state,
        jobId: data,
      };
    }
    case SET_JOB_STATUS_DATA: {
      return {
        ...state,
        jobStatusData: data,
      };
    }
    case SET_JOB_STATUS_MODAL_VISIBLE: {
      return {
        ...state,
        jobStatusModalVisible: data,
      };
    }
    case SET_LIST_LOADING: {
      return {
        ...state,
        listLoading: data,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: data,
      };
    }
    case SET_PLATFORM_TYPE:
      return {
        ...state,
        platformType: data,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: data,
      };
    case SET_SELECTED_HUB_ID: {
      return {
        ...state,
        selectedHubId: data,
      };
    }
    case SET_TOTAL: {
      return {
        ...state,
        total: data,
      };
    }
    case SET_VERIFICATION_STATUS: {
      return {
        ...state,
        verificationStatus: data,
      };
    }
    case STANDARD_MIGRATION_STARTING: {
      return {
        ...state,
        standardMigrationStarting: data,
      };
    }
    case VERIFICATION_DATA_LOADING: {
      return {
        ...state,
        verificationDataLoading: data,
      };
    }
    case SET_SELECTED_HUB_CUSTOM_FIELDS: {
      return {
        ...state,
        selectedHubCustomFields: data,
      };
    }
    case SET_PG_STANDARD_DATA_FIELDS:
      return {
        ...state,
        pgStandardDataFields: data,
      };
    case SET_API_LOADING:
      return {
        ...state,
        apiLoading: data,
      };
    case SET_STANDARD_FIELDS_UPDATED:
      return {
        ...state,
        standardFieldsUpdated: true,
      };
    case SET_ALLOWED_USER_TYPES:
      return {
        ...state,
        allowedUserTypes: data,
      };
    default:
      return state;
  }
};

export default manageReducer;
