import {
  SET_CMS_DATA,
  SET_CMS_DATA_LOADING,
  SET_CMS_DATA_PAGE,
  SET_CONTENT_TYPE,
  SET_FORM_DATA,
  SET_IS_FORM_DATA_CHANGED,
  SET_IS_FORM_DATA_SAVING,
  SET_SHOW_FORM,
  SET_IS_DELETING_CONTENT,
  CLEAR_CMS_DATA,
  SET_CONTENT_BY_IDENTIFIER,
  SET_IS_FETCHING_CONTENT_BY_IDENTIFIER,
  SET_EDIT_IDENTIFIER,
} from '../actions';

const initialState = {
  cmsData: [],
  cmsLoading: false,
  cmsDataCount: 0,
  cmsDataPage: 1,
  showForm: false,
  formData: {},
  mergeTags: [],
};

const cmsToolReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CLEAR_CMS_DATA:
      return {
        ...initialState,
      };
    case SET_CMS_DATA:
      return {
        ...state,
        cmsData: data.cmsData,
        cmsDataCount: data.cmsDataCount,
      };
    case SET_CMS_DATA_LOADING:
      return {
        ...state,
        cmsLoading: data,
      };
    case SET_CMS_DATA_PAGE:
      return {
        ...state,
        cmsDataPage: data,
      };
    case SET_SHOW_FORM:
      return {
        ...state,
        showForm: data,
      };
    case SET_FORM_DATA:
      return {
        ...state,
        formData: data,
      };
    case SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: data,
      };
    case SET_IS_FORM_DATA_SAVING:
      return {
        ...state,
        isFormDataSaving: data,
      };
    case SET_IS_FORM_DATA_CHANGED:
      return {
        ...state,
        isFormDataChanged: data,
      };
    case SET_IS_DELETING_CONTENT:
      return {
        ...state,
        isDeletingContent: data,
      };
    case SET_CONTENT_BY_IDENTIFIER:
      return {
        ...state,
        contentByIdentifier: data,
      };
    case SET_IS_FETCHING_CONTENT_BY_IDENTIFIER:
      return {
        ...state,
        isFetchingContentByIdentifier: data,
      };
    case SET_EDIT_IDENTIFIER:
      return {
        ...state,
        editIdentifier: data,
      };
    default:
      return state;
  }
};

export default cmsToolReducer;
