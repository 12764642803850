import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Card, Divider, Input, Button } from 'antd';
import Content from '../../component/Content';
import { refreshAll, refreshUsers } from './actions';

const { TextArea } = Input;

class ManageCache extends PureComponent {
  state = {
    users: '',
  };

  handleTextAreaChange = e => {
    this.setState({ users: e.target.value });
  };

  handleRefreshUsers = () => {
    const { users } = this.state;
    const { refreshUsers } = this.props;
    refreshUsers({ userIds: users });
    this.setState({ users: '' });
  };

  handleRefreshAll = () => {
    const { refreshAll } = this.props;
    refreshAll();
  };

  render() {
    const { users } = this.state;
    const { updatingAll, updatingUsers } = this.props;
    return (
      <Fragment>
        <PageHeader title="Manage Cache" />
        <Content>
          <Card>
            <h3>REMOVE CACHE OF SPECIFIC USERID/S</h3>
            <h4>Enter comma separated userIds</h4>
            <TextArea
              value={users}
              onChange={this.handleTextAreaChange}
              placeholder="For example - 12345, 345678, 987654"
              rows={3}
              style={{ marginBottom: 20 }}
            />
            <Button
              type="primary"
              loading={updatingUsers}
              onClick={this.handleRefreshUsers}
            >
              REFRESH USERS
            </Button>
            <Divider />
            <h3>REFRESH CACHE FOR ALL USERS</h3>
            <Button
              type="primary"
              loading={updatingAll}
              onClick={this.handleRefreshAll}
            >
              REFRESH CACHE
            </Button>
          </Card>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ cache: { updatingAll, updatingUsers } }) => ({
  updatingAll,
  updatingUsers,
});

const mapDispatchToProps = {
  refreshAll,
  refreshUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCache);
