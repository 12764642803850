import React, { PureComponent } from 'react';
import { Modal, Form, Input, Card, Select } from 'antd';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import { updateEditCluster as updateEditClusterAction } from './actions';
import defaultSettingVariations from '../../constants/defaultSettingVariationConstants';
import { isStandardHubCluster } from '../../utils/helpers';

const { Option } = Select;

class EditModal extends PureComponent {
  handleSave = e => {
    e.preventDefault();
    const { cluster, form, onSave } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        onSave({ ...cluster, ...values });
      }
    });
  };

  onJsonEdit = ({ updated_src }) => {
    const { cluster, updateEditCluster } = this.props;
    updateEditCluster({ ...cluster, settings: updated_src });
  };

  calculateHubCount(key) {
    const { groupClustersCount } = this.props;
    const launchGroup = groupClustersCount.filter(
      obj => obj.launchgroup === key
    );
    return launchGroup.length && launchGroup[0].count
      ? launchGroup[0].count
      : 0;
  }

  renderContent = () => {
    const {
      form: { getFieldDecorator },
      cluster,
    } = this.props;

    const isStandard = isStandardHubCluster(cluster);
    const launchGroups = isStandard
      ? Array.from(defaultSettingVariations)
      : defaultSettingVariations.filter(lg => !lg.forStandard);

    if (!cluster) return null;
    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input Cluster name!' }],
            initialValue: cluster.name,
          })(<Input placeholder="Cluster Name" />)}
        </Form.Item>
        <Form.Item label="Subdomain">
          {getFieldDecorator('subdomain', {
            initialValue: cluster.subdomain,
            rules: [{ required: true, message: 'Please input a subdomain' }],
          })(<Input placeholder="subdomain" />)}
        </Form.Item>
        <Form.Item label="Domain">
          {getFieldDecorator('domain', { initialValue: cluster.domain })(
            <Input placeholder="domain" />
          )}
        </Form.Item>
        <Form.Item label="Launch Group">
          {getFieldDecorator('defaultSetting', {
            initialValue: cluster.defaultSetting,
          })(
            <Select>
              {launchGroups.map(
                option =>
                  option.key !== 'default' && (
                    <Option key={option.key} value={option.key}>
                      {`${option.name} (${this.calculateHubCount(option.key)})`}
                    </Option>
                  )
              )}
            </Select>
          )}
        </Form.Item>
        {[
          'standard',
          'transitioned-standard',
          'standard-plus',
          'standard-plus-fpu',
        ].includes(cluster?.standardPlatformType) && (
          <Form.Item label="V2 Platform Type">
            {getFieldDecorator('standardPlatformType', {
              initialValue: cluster?.standardPlatformType,
            })(
              <Select>
                <Option value="enterprise">Classic</Option>
                <Option value="standard">V2 | PG Essentials | New</Option>
                <Option value="transitioned-standard">
                  V2 | PG Essentials | Upgraded
                </Option>
                <Option value="standard-plus">V2 | PG + | New</Option>
                <Option value="standard-plus-fpu">V2 | PG + | Upgraded</Option>
              </Select>
            )}
          </Form.Item>
        )}
        {cluster.settings && (
          <Card title="Cluster Settings">
            <ReactJson
              style={{
                overflow: 'auto',
                fontSize: '12px',
              }}
              name="settings"
              collapsed
              src={cluster.settings}
              onEdit={this.onJsonEdit}
              onAdd={this.onJsonEdit}
              onDelete={this.onJsonEdit}
            />
          </Card>
        )}
      </Form>
    );
  };

  render() {
    const { cluster, onCancel } = this.props;
    return (
      <Modal
        title="Edit Cluster"
        visible={cluster != null}
        onOk={this.handleSave}
        onCancel={onCancel}
        okText="Save"
        width={800}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

const mapStateToProps = ({ cluster: { editCluster: cluster } }) => ({
  cluster,
});

const mapDispatchToProps = {
  updateEditCluster: updateEditClusterAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({})(EditModal));
