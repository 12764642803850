import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Divider, Table, Tooltip } from 'antd';
import { findIndex } from 'lodash';

import { standardUserTypeOptions } from '../../../../../utils/helpers';
import {
  handleActionActiveHubsModalVisible,
  handleActionDeleteModalVisible,
} from '../../../actions/manageActions';

class ManageTemplateRow extends PureComponent {
  handleViewAllClick = templateId => {
    const {
      handleActionActiveHubsModalVisible,
      setActiveTemplateId,
    } = this.props;
    setActiveTemplateId(templateId);
    handleActionActiveHubsModalVisible(true);
  };

  handleEditClick = templateId => {
    const { history } = this.props;
    history.push(`/usertype-settings/manage-templates/edit/${templateId}`);
  };

  handleDeleteClick = templateId => {
    const { setActiveTemplateId, handleActionDeleteModalVisible } = this.props;
    setActiveTemplateId(templateId);
    handleActionDeleteModalVisible(true);
  };

  getColumns = () => [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 50,
      render: (t, record, index) => (
        <div className="usertype-card__table-index">{index + 1}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
      render: value => <div className="usertype-card__table-name">{value}</div>,
    },
    {
      title: 'Associated Hubs',
      dataIndex: 'count',
      key: 'count',
      render: (value, record) => (
        <div className="usertype-card__table-aschubs">
          <span className="usertype-card__table-aschubs__value">{value}</span>
          <Tooltip
            placement="top"
            title={
              value > 0
                ? 'View all hubs where this template is being used.'
                : ''
            }
          >
            <Button
              type="link"
              className="usertype-card__table-aschubs__btn"
              onClick={() => this.handleViewAllClick(record.id)}
              disabled={value === 0}
            >
              View All
            </Button>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'key1',
      key: 'key1',
      render: (value, record) => (
        <div className="usertype-card__table-actions">
          <Tooltip
            placement="top"
            title={
              record.count > 0
                ? 'Template currently in use. Can only be viewed.'
                : ''
            }
          >
            <Button type="link" onClick={() => this.handleEditClick(record.id)}>
              {record.count > 0 ? 'View' : 'Edit'}
            </Button>
          </Tooltip>

          <Divider type="vertical" />
          <Tooltip
            placement="top"
            title={
              !record.canDelete
                ? `Template currently in use. Can't be deleted.`
                : ''
            }
          >
            <Button
              type="link"
              className="usertype-card__table-actions__delete"
              disabled={!record.canDelete}
              onClick={() => this.handleDeleteClick(record.id)}
            >
              Delete
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  getTitle = () => {
    const { usertype } = this.props;
    if (usertype === 'none') return 'No Usertype';

    const index = findIndex(standardUserTypeOptions, { value: usertype });
    return standardUserTypeOptions[index].name;
  };

  render() {
    const { data } = this.props;
    return (
      <div className="usertype-card">
        <p className="usertype-card__title">{this.getTitle()}</p>
        <div className="usertype-card__table">
          <Table
            dataSource={data}
            columns={this.getColumns()}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  handleActionActiveHubsModalVisible,
  handleActionDeleteModalVisible,
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ManageTemplateRow)
);
