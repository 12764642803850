import { takeLatest, put, call } from 'redux-saga/effects';
import {
  AUTHENTICATE_USER,
  GET_CURRENT_USER,
  SIGN_IN_USER,
  SIGN_OUT_USER,
  USER_AUTH_PROCESS,
  LOGOUT_TOKEN,
  logoutToken,
  VERIFY_ADMIN_REDIRECT_TOKEN,
} from '../actions/auth';
import { POPUP_ALERT } from '../actions/alert';
import { STORE_ADMIN_DETAIL } from '../actions/admin';
import {
  getCurrentUserAPI,
  logoutUserApi,
  verifyAdminRedirectTokenApi,
} from '../api';
import { removeItem, setItem } from '../utils/localStorageService';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(AUTHENTICATE_USER, authenticateUser),
  takeLatest(GET_CURRENT_USER, getCurrentUser),
  takeLatest(SIGN_OUT_USER, signOutUser),
  takeLatest(LOGOUT_TOKEN, logoutTokenSaga),
  takeLatest(VERIFY_ADMIN_REDIRECT_TOKEN, verifyAdminRedirectTokenSaga),
];

function* authenticateUser() {
  try {
    yield getCurrentUser();
  } catch (e) {
    console.error(e);
  }
  // try {
  //     const url = `${process.env.REACT_APP_URL}auth/super-admin/saml`;
  //     window.open(url);
  // } catch (e) {
  //     console.error(e);
  // }
  // try {
  //     if (params && params.data && params.data.admintoken) {
  //         window.localStorage.setItem('token', params.data.admintoken);
  //         yield getCurrentUser();
  //     }
  //     else {
  //         const scopes = ['https://www.googleapis.com/auth/plus.me', 'email'];
  //         const clientId = '920017377485-67erjnben63tt70kf89oeml788msfcsa.apps.googleusercontent.com';

  //         window.gapi.auth.authorize({
  //             client_id: clientId,
  //             scope: scopes,
  //             immediate: false
  //         }, authResult => {
  //             // console.log(authResult);
  //         });
  //     }
  // } catch(e) {
  //     console.error(e);
  // }
}

function* getCurrentUser(params) {
  try {
    yield put({ type: USER_AUTH_PROCESS });

    const { data } = yield getCurrentUserAPI(params);
    if (data.accessLevelCode !== 10001) {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'User is not a superadmin.' },
      });
    } else {
      yield put({ type: STORE_ADMIN_DETAIL, data });
      yield put({ type: SIGN_IN_USER, data });
    }
  } catch (e) {
    yield put({ type: SIGN_OUT_USER });
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Invalid superadmin token.' },
    });
  }
}

function* signOutUser() {
  yield put(logoutToken());
  removeItem('isLoggedIn');
  yield put({ type: STORE_ADMIN_DETAIL, data: {} });
}

export function* logoutTokenSaga() {
  try {
    yield call(logoutUserApi);
  } catch (err) {
    console.error(err);
  }
}

export function* verifyAdminRedirectTokenSaga({ data }) {
  try {
    yield call(verifyAdminRedirectTokenApi, data);
    setItem('isLoggedIn', true);
    window.onunload = () => {
      window.opener.location.href = `/`;
    };
    window.close();
  } catch (err) {
    console.error(err);
  }
}
