import { request } from '../utils/request';

export const findTemplateById = data =>
  request.get('/superadmin/pathway-provisioning/find-template', {
    params: data,
  });
export const getModuleTemplates = data =>
  request.get('/superadmin/pathway-provisioning/list-template', {
    params: data,
  });
export const fetchHubsWithPathway = () =>
  request.get('/superadmin/pathway-provisioning/hubs-pathways');
export const addNewTemplate = data =>
  request.post('/superadmin/pathway-provisioning/add', data);
export const updateTemplate = data =>
  request.put('/superadmin/pathway-provisioning/update', data);
export const deleteTemplateById = id =>
  request.delete(`/superadmin/pathway-provisioning/delete/${id}`);

export const fetchProvisioningFilters = () =>
  request.get('/superadmin/pathway-provisioning/filters');
