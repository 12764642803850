import { useQuery } from '@tanstack/react-query';
import { request } from '../../../utils/request';

const getAutoTriggerList = async () => {
  const response = await request.get(`/admin/auto-message-trigger-list`);
  const { data } = response;
  return data;
};

const useGetAutoTriggerListQuery = () =>
  useQuery(['autoTriggerList'], () => getAutoTriggerList());

export default useGetAutoTriggerListQuery;
