import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Row, Col, Input, Card, Tabs, Icon, Spin } from 'antd';
import { debounce } from 'lodash';
import {
  setAccessManagementObj,
  fetchSuperadmins,
  fetchGroups,
  saveGroups,
  savePermissions,
  addNewGroups,
  deleteGroup,
  initialisePage,
} from './actions';
import Content from '../../component/Content';
import classes from './accessManagement.module.scss';
import TabRenderer from './TabRenderer';

const { TabPane } = Tabs;

class AccessManagement extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { initialisePage } = this.props;
    initialisePage();
  }

  onAdminGroupSelect = arr => {
    const { setAccessManagementObj } = this.props;
    setAccessManagementObj({ admins: arr });
  };

  onGroupPermissionSelect = arr => {
    const { setAccessManagementObj } = this.props;
    setAccessManagementObj({ groups: arr });
  };

  onPageChangeAdmin = page => {
    const { fetchSuperadmins } = this.props;
    fetchSuperadmins({ adminCurrentPage: page });
  };

  onPageChangeGroups = page => {
    const { fetchGroups } = this.props;
    fetchGroups({ groupCurrentPage: page });
  };

  fetchSuperadminsDebounce = debounce(this.props.fetchSuperadmins, 300);

  fetchGroupsDebounce = debounce(this.props.fetchGroups, 300);

  onAdminSearchFilter = txt => {
    const { setAccessManagementObj } = this.props;
    const obj = { adminFilterText: txt, adminCurrentPage: 1 };
    setAccessManagementObj(obj);
    this.fetchSuperadminsDebounce(obj);
  };

  onGroupSearchFilter = txt => {
    const { setAccessManagementObj } = this.props;
    const obj = { groupFilterText: txt, groupCurrentPage: 1 };
    setAccessManagementObj(obj);
    this.fetchGroupsDebounce(obj);
  };

  onTabChange = activeKey => {
    const { fetchGroups, initialisePage } = this.props;
    if (Number(activeKey) === 2) {
      fetchGroups();
    } else {
      initialisePage();
    }
  };

  render() {
    const {
      admins,
      groups,
      permissions,
      adminTotalCount,
      adminCurrentPage,
      adminPageSize,
      groupTotalCount,
      groupCurrentPage,
      groupPageSize,
      adminFilterText,
      groupFilterText,
      saveGroups,
      savePermissions,
      addNewGroups,
      deleteGroup,
      loading,
      loadingAdmins,
      loadingGroups,
      addingGroup,
      savingGroup,
      savingAdminPermission,
    } = this.props;
    return (
      <div className={classes.access_management}>
        <PageHeader title="Access Management" />
        <Content>
          <Card bordered={false}>
            {loading && (
              <Row type="flex" justify="center">
                <Spin />
              </Row>
            )}
            {!loading && (
              <Tabs defaultActiveKey="1" onChange={this.onTabChange}>
                <TabPane
                  tab={
                    <span>
                      <Icon type="user" />
                      Admins
                    </span>
                  }
                  key="1"
                >
                  <TabRenderer
                    fieldsToFilter={['firstName', 'lastName', 'email']}
                    selectedOptionKey="groups"
                    selectOnKey="id"
                    leftColArray={admins}
                    rightColSelection={groups}
                    resultCount={adminTotalCount}
                    currentPage={adminCurrentPage}
                    pageSize={adminPageSize}
                    onSelect={this.onAdminGroupSelect}
                    onPageChange={this.onPageChangeAdmin}
                    filterText={adminFilterText}
                    onSearchFilter={this.onAdminSearchFilter}
                    onSave={savePermissions}
                    loading={loadingAdmins}
                    saving={savingAdminPermission}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <Icon type="gold" />
                      Groups
                    </span>
                  }
                  key="2"
                >
                  <TabRenderer
                    fieldsToFilter={['name']}
                    selectedOptionKey="permissions"
                    selectOnKey="identifier"
                    leftColArray={groups}
                    rightColSelection={permissions}
                    showAddNewBlock
                    resultCount={groupTotalCount}
                    currentPage={groupCurrentPage}
                    pageSize={groupPageSize}
                    showDelete
                    onSelect={this.onGroupPermissionSelect}
                    onPageChange={this.onPageChangeGroups}
                    filterText={groupFilterText}
                    onSearchFilter={this.onGroupSearchFilter}
                    onSave={saveGroups}
                    onAdd={addNewGroups}
                    onDelete={deleteGroup}
                    loading={loadingGroups}
                    addingGroup={addingGroup}
                    saving={savingGroup}
                  />
                </TabPane>
              </Tabs>
            )}
          </Card>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({ accessManagement }) => ({
  ...accessManagement,
});

const mapDispatchToProps = {
  setAccessManagementObj,
  fetchSuperadmins,
  fetchGroups,
  saveGroups,
  savePermissions,
  addNewGroups,
  deleteGroup,
  initialisePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagement);
