import { request } from '../utils/request';

export const saveGlobalListGoalApi = body =>
  request.post('/superadmin/global-lists', body);

export const updateGlobalListGoalApi = body =>
  request.put('/superadmin/global-lists', body);

export const fetchGlobalListsApi = page =>
  request.get(`/superadmin/global-lists?page=${page}`);

export const fetchGlobalListGoals = listId =>
  request.get(`/global-list/${listId}`);

export const setListActive = body =>
  request.put(`superadmin/global-list-active/${body.id}`, body);

export const fetchActionItems = page =>
  request.get(`/compass-action-items?page=${page}`);

export const createActionItemApi = body =>
  request.post('/compass-action-items', body);

export const updateActionItemApi = body =>
  request.put(`/compass-action-items/${body.id}`, body);

export const setActionItemActive = body =>
  request.put(`/compass-action-items-active/${body.id}`, body);
