/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Button, Divider, Input, Card, Table, Modal } from 'antd';
import { isNil } from 'lodash';
import Content from '../../component/Content';
import classes from './InvitedUniversityContainer.module.scss';
import AddClusterHubsModal from './AddClusterHubsModal';
import { fetchAllClusters } from '../ClusterManagement/actions';
import {
  handleFetchHubsDataByClusterId,
  addInvitedHubsToBAList,
  getAllInvitedBAHubs,
  downloadCSVDataOfHubs,
} from './actions';
const { confirm } = Modal;
const { Search } = Input;

class InvitedUniversityContainer extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    showAddHubModal: false,
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Cluster',
      dataIndex: 'cluster_name',
      key: 'cluster_name',
    },
    {
      title: 'Date Invited',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: this.renderDate,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (k, v) => (
        <div>
          <Button
            type="danger"
            icon="delete"
            onClick={() => this.removeUniversityFromBAList(v)}
          />
        </div>
      ),
    },
  ];

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { clusters, fetchAllClusters, getAllInvitedBAHubs } = this.props;
    if (!clusters) fetchAllClusters();
    getAllInvitedBAHubs();
  }

  openAddHubModal = () => {
    this.setState({
      showAddHubModal: true,
    });
  };

  onModalClose = () => {
    this.setState({
      showAddHubModal: false,
    });
  };

  getAllHubByClusterId = id => {
    this.props.handleFetchHubsDataByClusterId(id);
  };

  addHubsToBAList = selectedHubs => {
    this.onModalClose();
    if (selectedHubs && selectedHubs.length) {
      this.props.addInvitedHubsToBAList(selectedHubs);
    }
  };

  removeUniversityFromBAList = hubObj => {
    confirm({
      title: 'Confirm delete selected hubs?',
      content: '',
      onOk: () => {
        hubObj.join_ba_hub = !hubObj.join_ba_hub;
        this.props.addInvitedHubsToBAList([hubObj]);
      },
      onCancel() {},
    });
  };

  handleChangeInSearch = event => {
    this.setState({
      searchText: event.target.value,
    });
  };

  renderDate = date => new Date(date).toLocaleString();

  downLoadCSV = async () => {
    this.props.downloadCSVDataOfHubs();
  };

  render() {
    const {
      clusters,
      clustersLoading,
      hubsData,
      hubDataLoading,
      allInvitedHubsLoading,
    } = this.props;
    let { allInvitedHubs } = this.props;
    const { searchText, showAddHubModal } = this.state;
    if (searchText && searchText.trim().length) {
      allInvitedHubs = allInvitedHubs.filter(hubObj => {
        if (!isNil(searchText) && isNaN(parseInt(searchText, 10)))
          return (
            hubObj.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            hubObj.domain.toLowerCase().indexOf(searchText.toLowerCase()) >
              -1 ||
            hubObj.cluster_name
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) > -1
          );
        return hubObj.id.toString() === searchText;
      });
    }
    return (
      <div className={classes.root}>
        <PageHeader
          title="BridgesAlliance: Invited Universities"
          extra={[
            <Button
              key="save"
              type="primary"
              onClick={this.openAddHubModal}
              icon="plus-circle"
            >
              Add a hub
            </Button>,
          ]}
        >
          <Divider />
          <div style={{ textAlign: 'right' }}>
            <Search
              placeholder="Search By Name, Hub ID"
              style={{ width: 250, float: 'left' }}
              value={searchText}
              onChange={this.handleChangeInSearch}
            />
            <Button key="save" type="primary" onClick={this.downLoadCSV}>
              Download CSV
            </Button>
          </div>
        </PageHeader>
        <Content>
          <AddClusterHubsModal
            visible={showAddHubModal}
            onClose={this.onModalClose}
            createSeed={this.createSeed}
            clusters={clusters}
            clustersLoading={clustersLoading}
            hubsData={hubsData}
            hubDataLoading={hubDataLoading}
            getAllHubByClusterId={this.getAllHubByClusterId}
            addHubsToBAList={this.addHubsToBAList}
          />
          <Card bordered={false}>
            <Table
              dataSource={allInvitedHubs}
              columns={this.columns}
              loading={allInvitedHubsLoading}
            />
          </Card>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({
  cluster: { clusters, clustersLoading },
  bridgesAlliance: {
    hubsData,
    hubDataLoading,
    allInvitedHubs,
    allInvitedHubsLoading,
  },
}) => ({
  clusters,
  clustersLoading,
  hubsData,
  hubDataLoading,
  allInvitedHubs,
  allInvitedHubsLoading,
});

const mapDispatchToProps = {
  fetchAllClusters,
  handleFetchHubsDataByClusterId,
  addInvitedHubsToBAList,
  getAllInvitedBAHubs,
  downloadCSVDataOfHubs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitedUniversityContainer);
