import { all, put, call, select } from 'redux-saga/effects';
import { cloneDeep, get, set } from 'lodash';
import FileDownload from 'js-file-download'

import {
  fetchFeatureSettings,
  updateFeatureSettings,
  getModulesList,
  fetchLaunchGroupHubs,
  fetchGroupHubsCount,
  startDownload,
  resetToDefault,
} from '../../../api';

import notify from '../../../utils/notify';
import { POPUP_ALERT } from '../../../../src/actions/alert';

import {
  setAllSettings,
  setAffectedHubFeature,
  setAllSettingsClone,
  setModulesList,
  settingsSelector,
  setSettingsUpdated,
  logError,
} from '../actions';

import { SET_PAGE_LOADING, SET_IS_PAGE_UPDATING, SET_GROUP_HUB_FIELDS, SET_GROUP_HUBS_COUNT } from '../actions';

export function* fetchLaunchGroupHubsSaga({ data }) {
  try {
    yield put({ type: SET_GROUP_HUB_FIELDS, data: { groupHubLoading: true } });
    const {
      key: launchGroupKey,
      affectedFeature,
      affectedHubFeatures = {},
    } = data;
    let { data: hubs } = yield call(fetchLaunchGroupHubs, data);
    hubs = hubs.map(_hub => {
      const hub = _hub;
      const { affectedfeature } = _hub;
      hub.key = hub.id;
      if (affectedfeature)
        hub.affectedFeature = Object.keys(affectedfeature).join(',\n');
      return hub;
    });
    yield put({
      type: SET_GROUP_HUB_FIELDS,
      data: {
        groupHubLoading: false,
        groupHubs: hubs,
      },
    });
    if (affectedFeature && hubs.length) {
      set(affectedHubFeatures, `${launchGroupKey}.${affectedFeature}`, true);
    }
    yield put(setAffectedHubFeature(affectedHubFeatures));
  } catch (e) {
    console.log('Error in fetchLaunchGroupHubsSaga: ', e);
    yield put(logError(e));
  }
}

export function* startDownloadSaga({data}) {
  try {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Preparing data' },
    });
    const { data: hubs } = yield call(startDownload, data);
    FileDownload(hubs, `${data.source}-${data.lgName}.csv`);
  } catch (e) {
    console.log('Error in startDownloadSaga: ', e);
    yield put(logError(e));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Failed to Download' },
    });
  }
}
export function* resetToDefaultSaga({data}) {
  try {
    const { data: hubs } = yield call(resetToDefault, data);
  } catch (e) {
    console.log('Error in startDownloadSaga: ', e);
    yield put(logError(e));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Failed to Download' },
    });
  }
}

export function* fetchGroupHubsCountSaga({data}) {
  try {
    const {data: counts} = yield call(fetchGroupHubsCount, data);
    let countData = {}
    countData[data === 'hub' ? 'groupHubsCount': 'groupClustersCount'] = counts
    yield put({
      type: SET_GROUP_HUBS_COUNT, 
      data: countData
    });
  } catch (e) {
    console.log('Error in fetchGroupHubsCountSaga: ', e);
    yield put(logError(e));
  }
}

export function* fetchAllSettingsSaga({ data }) {
  try {
    yield put({ type: SET_PAGE_LOADING, data: true });
    const { objectType } = data;
    const [{ data: settings }, { data: modulesList }] = yield all([
      call(fetchFeatureSettings, { objectType, updateDefault: true }),
      call(getModulesList, { moduleType: objectType }),
    ]);
    const allSettings = settings.reduce((obj, item) => {
      obj[item.variation] = item;
      return obj;
    }, {});
    if (modulesList.length) {
      modulesList.forEach(module => {
        const { key, settings } = module;
        const existingSetting = get(
          allSettings,
          `default.settings.moduleSettings.${key}`,
          null
        );
        if (!existingSetting) {
          set(allSettings, `default.settings.moduleSettings.${key}`, settings);
        }
      });
      yield put(setModulesList(modulesList));
    }
    yield put(setAllSettings(allSettings));
    yield put(setAllSettingsClone(cloneDeep(allSettings)));
    yield put({ type: SET_PAGE_LOADING, data: false });
  } catch (e) {
    console.log('Error in fetchAllSettingsSaga: ', e);
    yield put(logError(e));
  }
}

export function* updateLaunchGroupSettingsSaga(data) {
  try {
    yield put({ type: SET_PAGE_LOADING, data: true });
    yield put({ type: SET_IS_PAGE_UPDATING, data: true });
    const settings = yield select(settingsSelector);
    const res = yield call(updateFeatureSettings, {
      objectType: data.data,
      body: settings,
    });
    if (res.data.message && res.data.message === 'not_most_recent_setting') {
      notify(
        'Cannot Update Settings!',
        'error',
        `Please reload the page, as you don't have the most recent settings.`
      );
      yield put({ type: SET_PAGE_LOADING, data: false });
      yield put({ type: SET_IS_PAGE_UPDATING, data: false });
      yield put(setSettingsUpdated(false));
    } else {
      const allSettings = res.data.reduce((obj, item) => {
        obj[item.variation] = item;
        return obj;
      }, {});
      yield put(setAllSettings(allSettings));
      yield put(setAllSettingsClone(cloneDeep(allSettings)));
      yield put({ type: SET_PAGE_LOADING, data: false });
      yield put({ type: SET_IS_PAGE_UPDATING, data: false });
      yield put(setSettingsUpdated(false));
      notify('Settings Updated Successfully!', 'success');
    }
  } catch (e) {
    console.log('Error in updateLaunchGroupSettingsSaga: ', e.message);
    yield put({ type: SET_PAGE_LOADING, data: false });
    yield put({ type: SET_IS_PAGE_UPDATING, data: false });
    yield put(logError(e));
    notify(e.message, 'error');
  }
}
