import {
  MENTORSHIP_FEEDBACKS_LOADING,
  FETCH_MENTORSHIP_FEEDBACKS_SUCCESS,
  MENTORSHIP_FEEDBACKS_SET_PAGE,
  MENTORSHIP_FEEDBACKS_SET_PAGE_SIZE,
  MESSAGE_FEEDBACKS_LOADING,
  FETCH_MESSAGE_FEEDBACKS_SUCCESS,
  MESSAGE_FEEDBACKS_SET_PAGE,
  MESSAGE_FEEDBACKS_SET_PAGE_SIZE,
  RESET_FEEDBACKS_STATE,
} from '../actions';

const initialState = {
  mentorship: {
    data: null,
    current: 1,
    total: 0,
    totalPages: 0,
    limit: 25,
    loading: false,
  },
  message: {
    data: null,
    current: 1,
    total: 0,
    totalPages: 0,
    limit: 25,
    loading: false,
  },
};

const feedbackReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case MESSAGE_FEEDBACKS_LOADING:
      return {
        ...state,
        message: {
          ...state.message,
          loading: data,
        },
      };

    case FETCH_MESSAGE_FEEDBACKS_SUCCESS:
      return {
        ...state,
        message: {
          ...state.message,
          data: data.feedbacks,
          total: data.count,
        },
      };

    case MESSAGE_FEEDBACKS_SET_PAGE:
      return {
        ...state,
        message: {
          ...state.message,
          current: data,
        },
      };

    case MESSAGE_FEEDBACKS_SET_PAGE_SIZE:
      return {
        ...state,
        message: {
          ...state.message,
          limit: data,
        },
      };

    case MENTORSHIP_FEEDBACKS_LOADING:
      return {
        ...state,
        mentorship: {
          ...state.mentorship,
          loading: data,
        },
      };

    case FETCH_MENTORSHIP_FEEDBACKS_SUCCESS:
      return {
        ...state,
        mentorship: {
          ...state.mentorship,
          data: data.feedbacks,
          total: data.count,
        },
      };

    case MENTORSHIP_FEEDBACKS_SET_PAGE:
      return {
        ...state,
        mentorship: {
          ...state.mentorship,
          current: data,
        },
      };

    case MENTORSHIP_FEEDBACKS_SET_PAGE_SIZE:
      return {
        ...state,
        mentorship: {
          ...state.mentorship,
          limit: data,
        },
      };

    case RESET_FEEDBACKS_STATE:
      return initialState;

    default:
      return state;
  }
};

export default feedbackReducer;
