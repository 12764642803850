/* eslint-disable react/state-in-constructor */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Button, Checkbox, Row, Col, Select, Tooltip } from 'antd';
import { differenceBy, concat, isNumber, each } from 'lodash';
import { isValidString } from '../../../../../utils/helpers';

import CancelModal from '../modals/CancelModal';
import ProgressBar from '../../../../../component/ProgressBar';
import NotificationAlert from '../../../shared/NotificationAlert';

import {
  handleActionCancelModalVisible as handleActionCancelModalVisibleAction,
  handleActionDeleteModalVisible as handleActionDeleteModalVisibleAction,
  handleActionConfirmModalVisible as handleActionConfirmModalVisibleAction,
  setUsertypeLibraryName as setUsertypeLibraryNameAction,
  setUsertypeLibrarySelectedUsertypeTemplates as setUsertypeLibrarySelectedUsertypeTemplatesAction,
  saveUsertypeLibrary as saveUsertypeLibraryAction,
  fetchUsertypeLibraryUpsellsAndTemplates as fetchUsertypeLibraryUpsellsAndTemplatesAction,
  deleteUsertypeLibrary as deleteUsertypeLibraryAction,
} from '../../../actions/manageActions';
import ConfirmModal from '../modals/ConfirmModal';

const { Option } = Select;
function UsertypeLibraryForm({
  handleActionCancelModalVisible,
  handleActionDeleteModalVisible,
  handleActionConfirmModalVisible,
  isSaving,
  isSingleLoading,
  match,
  name = '',
  usertypeTemplates,
  isAssociatedToHub,
  selectedUsertypeTemplates = [],
  usertypeLibraryFormData,
  upsells,
  setUsertypeLibraryName,
  setUsertypeLibrarySelectedUsertypeTemplates,
  saveUsertypeLibrary,
  fetchUsertypeLibraryUpsellsAndTemplates,
}) {
  const [nameError, setNameError] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [
    editModeSelectedUsertypeTemplates,
    setEditModeSelectedUsertypeTemplates,
  ] = useState([]);

  useEffect(() => {
    fetchUsertypeLibraryUpsellsAndTemplates();
  }, [fetchUsertypeLibraryUpsellsAndTemplates]);

  useEffect(() => {
    const { params } = match;
    if (params && params.id) setIsEditMode(true);
    else setIsEditMode(false);
    setNameError(null);

    return () => setEditModeSelectedUsertypeTemplates([]);
  }, [match]);

  const handleInputChange = (key, e) => {
    const { value } = e.target;
    switch (key) {
      case 'name':
        setUsertypeLibraryName(value);
        if (!isValidString(value)) {
          setNameError({ key: 'name', message: 'Name cannot be empty' });
        } else {
          setNameError(null);
        }
        break;
      default:
    }
  };

  const handleKeyPress = event => {
    if (event.keyCode === 13) {
      event.target.click();
      if (event.stopImmediatePropagation) event.stopImmediatePropagation();
    }
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const onSelectedUsertypeCheckboxChange = (e, type) => {
    const { checked, value } = e.target;
    if (!checked) {
      if (type === 'selectList') {
        setUsertypeLibrarySelectedUsertypeTemplates(
          differenceBy(selectedUsertypeTemplates, [value], 'id')
        );
      } else if (type === 'editList') {
        setEditModeSelectedUsertypeTemplates(
          differenceBy(editModeSelectedUsertypeTemplates, [value], 'id')
        );
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (type === 'selectList') {
        setUsertypeLibrarySelectedUsertypeTemplates(
          concat(selectedUsertypeTemplates, value)
        );
      } else if (type === 'editList') {
        setEditModeSelectedUsertypeTemplates(
          concat(editModeSelectedUsertypeTemplates, value)
        );
      }
    }
  };

  const onUsertypeCheckboxChange = ([value]) => {
    // Set default values
    value.association = 'standard';
    value.usertypeTemplate = value.id;

    if (isEditMode) {
      setEditModeSelectedUsertypeTemplates([
        ...editModeSelectedUsertypeTemplates,
        value,
      ]);
    } else {
      setUsertypeLibrarySelectedUsertypeTemplates(
        concat(selectedUsertypeTemplates, value)
      );
    }
  };

  const onSelectOptionChange = (value, { id }, type) => {
    const listToConsider =
      type === 'editList'
        ? [...editModeSelectedUsertypeTemplates]
        : [...selectedUsertypeTemplates];

    each(listToConsider, usertype => {
      if (usertype.id === id) {
        if (isNumber(value)) {
          // Its a upsell id
          usertype.association = 'upsell';
          usertype.upsell = value;
        } else {
          usertype.association = 'standard';
          usertype.upsell = null;
        }

        usertype.usertypeTemplate = id;
      }
    });

    if (type === 'editList') {
      setEditModeSelectedUsertypeTemplates(listToConsider);
    } else {
      setUsertypeLibrarySelectedUsertypeTemplates(listToConsider);
    }
  };

  const getUpsellOptions = () =>
    upsells.map(upsell => (
      <Option key={upsell.id} value={upsell.id}>
        {upsell.name}
      </Option>
    ));

  const getUsertypeTemplates = usertype => (
    <Row key={usertype.id} style={{ marginBottom: '16px' }}>
      <Col>
        <Checkbox checked={false} value={usertype}>
          {usertype.name}
        </Checkbox>
      </Col>
    </Row>
  );

  const renderSelectedTemplates = (renderList, type = 'selectList') =>
    renderList.map(usertype => {
      const disableRow =
        isEditMode && type === 'editList'
          ? false
          : isAssociatedToHub && usertype.association === 'standard';
      return (
        <Row key={usertype.id} style={{ marginBottom: '16px' }}>
          <Col span={12}>
            <Tooltip
              placement="bottom"
              title={
                disableRow
                  ? 'You cannot remove this user type template because it is a standard template.'
                  : ''
              }
            >
              <Checkbox
                defaultChecked
                disabled={disableRow}
                value={usertype}
                onChange={e => onSelectedUsertypeCheckboxChange(e, type)}
              >
                {usertype.name}
              </Checkbox>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Select
              showSearch
              style={{
                width: '100%',
                marginTop: '-16px',
                height: '36px',
              }}
              placeholder="Select a type"
              optionFilterProp="children"
              value={
                usertype.association === 'standard'
                  ? 'standard'
                  : usertype.upsell
              }
              disabled={disableRow}
              onChange={val => onSelectOptionChange(val, usertype, type)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="standard">Standard Usertype</Option>
              {getUpsellOptions()}
            </Select>
          </Col>
        </Row>
      );
    });

  const renderUsertypeTemplatesToShow = () => {
    let usertypeTemplatesToShow = differenceBy(
      usertypeTemplates,
      selectedUsertypeTemplates,
      'id'
    );

    if (isEditMode) {
      usertypeTemplatesToShow = differenceBy(
        usertypeTemplatesToShow,
        editModeSelectedUsertypeTemplates,
        'id'
      );
    }

    return (
      (usertypeTemplatesToShow ?? []).length > 0 && (
        <Form.Item label="User type templates" className="ant-form-item">
          <Checkbox.Group value={[]} onChange={onUsertypeCheckboxChange}>
            {showMore
              ? (usertypeTemplatesToShow ?? []).map(usertype =>
                  getUsertypeTemplates(usertype)
                )
              : (usertypeTemplatesToShow ?? []).map((usertype, index) => {
                  if (index < 10) return getUsertypeTemplates(usertype);
                  return null;
                })}
          </Checkbox.Group>
          {usertypeTemplatesToShow && usertypeTemplatesToShow.length > 10 && (
            <span
              className="showmore-btn"
              onClick={handleShowMore}
              onKeyDown={handleKeyPress}
              role="button"
              tabIndex="0"
              aria-label={!showMore ? `Show More` : `Show Less`}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </span>
          )}
        </Form.Item>
      )
    );
  };

  const handleSubmit = () => {
    if (!isValidString(name)) {
      setNameError({ key: 'name', message: 'Name cannot be empty' });
      return;
    }

    if (isEditMode && editModeSelectedUsertypeTemplates.length > 0) {
      usertypeLibraryFormData.selectedUsertypeTemplates = [
        ...usertypeLibraryFormData.selectedUsertypeTemplates,
        ...editModeSelectedUsertypeTemplates,
      ];
      setEditModeSelectedUsertypeTemplates([]);
    }

    const objToSave = { ...usertypeLibraryFormData };
    delete objToSave.isAssociatedToHub;

    if (match.params && match.params.id) {
      objToSave.id = match.params.id;
    }
    saveUsertypeLibrary(objToSave);
  };

  if (isSingleLoading) {
    return (
      <div className="form-container">
        <ProgressBar
          style={{ minHeight: '500px', backgroundColor: '#ffffff' }}
        />
      </div>
    );
  }

  const isSaveDisabled = () => {
    const list = [
      ...editModeSelectedUsertypeTemplates,
      ...selectedUsertypeTemplates,
    ];
    return nameError || list.length === 0 || name.trim().length === 0;
  };

  return (
    <>
      {isAssociatedToHub && (
        <NotificationAlert message="Careful! Any changes to this user type library will be global and show up for hub admins even though the admins may not have yet reviewed or approved them." />
      )}
      <div className="ck-card form-container">
        <h3 className="form-container__heading">
          {match.params && match.params.id
            ? 'Edit User Type Library'
            : 'Create User Type Library'}
        </h3>
        <Form>
          <Form.Item
            label="Name"
            className="ant-form-item"
            validateStatus={nameError ? 'error' : ''}
            help={nameError ? nameError.message : ''}
          >
            <Input
              placeholder="Add name"
              onChange={e => handleInputChange('name', e)}
              value={name}
              autoComplete="off"
            />
          </Form.Item>
          {((selectedUsertypeTemplates ?? []).length > 0 ||
            (editModeSelectedUsertypeTemplates ?? []).length > 0) && (
            <Form.Item
              label="Selected User type templates"
              className="ant-form-item"
              style={{ width: '100%' }}
            >
              {renderSelectedTemplates(selectedUsertypeTemplates, 'selectList')}
              {renderSelectedTemplates(
                editModeSelectedUsertypeTemplates,
                'editList'
              )}
            </Form.Item>
          )}
          {renderUsertypeTemplatesToShow()}
          <Form.Item className="ant-form-item">
            <div className="buttons">
              <div className="buttons__left">
                {match.params && match.params.id && (
                  <>
                    <Button
                      type="danger"
                      disabled={
                        isAssociatedToHub || !match.params || !match.params.id
                      }
                      onClick={() => handleActionDeleteModalVisible(true)}
                      className="delete-btn"
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>
              <div className="buttons__right">
                <Button
                  type="default"
                  className="primary-btn"
                  onClick={() => handleActionCancelModalVisible(true)}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <CancelModal manageType="usertype-library" />
                <Button
                  type="primary"
                  className="primary-btn"
                  onClick={() =>
                    isAssociatedToHub
                      ? handleActionConfirmModalVisible(true)
                      : handleSubmit()
                  }
                  loading={isSaving}
                  disabled={isSaveDisabled()}
                >
                  {match.params && match.params.id ? 'Update' : 'Save'}
                </Button>
                <ConfirmModal onConfirmOk={handleSubmit} />
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

const mapStateToProps = ({
  usertypeManage: {
    isSaving,
    isSingleLoading,
    usertypeTemplates,
    usertypeLibraryFormData: {
      name,
      selectedUsertypeTemplates,
      isAssociatedToHub = false,
    },
    usertypeLibraryFormData,
    upsells,
  },
}) => ({
  isSaving,
  isSingleLoading,
  usertypeTemplates,
  name,
  selectedUsertypeTemplates,
  isAssociatedToHub,
  usertypeLibraryFormData,
  upsells,
});

const mapDispatchToProps = {
  handleActionCancelModalVisible: handleActionCancelModalVisibleAction,
  handleActionDeleteModalVisible: handleActionDeleteModalVisibleAction,
  handleActionConfirmModalVisible: handleActionConfirmModalVisibleAction,
  setUsertypeLibraryName: setUsertypeLibraryNameAction,
  setUsertypeLibrarySelectedUsertypeTemplates: setUsertypeLibrarySelectedUsertypeTemplatesAction,
  saveUsertypeLibrary: saveUsertypeLibraryAction,
  fetchUsertypeLibraryUpsellsAndTemplates: fetchUsertypeLibraryUpsellsAndTemplatesAction,
  deleteUsertypeLibrary: deleteUsertypeLibraryAction,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UsertypeLibraryForm)
);
