import { request } from '../utils/request';

export const getReportListApi = params =>
  request.get('/analytics-service/reports', params);
export const getReportWithIdApi = (id, params) =>
  request.get(`/analytics-service/reports/${id}`, params);
export const testReportQueryApi = data =>
  request.post(`/analytics-service/report-query/test`, data);
export const createReportApi = data =>
  request.post(`/analytics-service/reports`, data);
export const updateReportApi = (id, data) =>
  request.put(`/analytics-service/reports/${id}`, data);
