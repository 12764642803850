import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import AdminTagsTable from './AdminTagsTable';
import { fetchAdminTags, updateAdminTag, deleteAdminTag, addAdminTag, addAdminTagReference, deleteAdminTagReference } from '../../actions';
import './adminTag.scss';

class AdminTags extends PureComponent {

	constructor(props) {
		super(props);
		const { fetchAdminTags } = props;
		fetchAdminTags();
	}

	render() {
		const { adminTags, adminTagsLoading, updateAdminTag, deleteAdminTag, addAdminTag, addAdminTagReference, deleteAdminTagReference, clusterHubs } = this.props;
		return (
			<div>
				<AdminTagsTable 
					adminTags={adminTags} 
					adminTagsLoading={adminTagsLoading} 
					updateAdminTag={updateAdminTag}
					deleteAdminTag={deleteAdminTag}
					addAdminTag={addAdminTag}
					addAdminTagReference={addAdminTagReference}
					deleteAdminTagReference={deleteAdminTagReference}
					clusterHubs={clusterHubs}
				/> 
			</div>
		)
	}
}

const mapStateToProps = ({ adminTag }) => ({
	adminTags: adminTag.adminTags,
	clusterHubs: adminTag.clusterHubs,
	adminTagsLoading: adminTag.adminTagsLoading,
});

const mapDispatchToProps = {
	fetchAdminTags,
	updateAdminTag,
	deleteAdminTag,
	addAdminTag,
	addAdminTagReference,
	deleteAdminTagReference,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdminTags);