/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Spin, Collapse, Input, Icon } from 'antd';
import { get, isEmpty, isEqual, sortBy } from 'lodash';

import useAsyncFetch from '../../../../hooks/useAsyncFetch';
import usePrevious from '../../../../hooks/usePrevious';
import { fetchCmsContentApi } from '../../../../api/cmsToolApi';
import {
  fetchCustomUserTemplateDataApi,
  fetchTemplatesApi,
} from '../../../../api/templatesAndTips';
import { processTemplateResponse } from '../../utils';
import { sanitize } from '../../../../utils/dompurify';
import { notify } from '../../../../utils/notify';

const { Panel } = Collapse;

const CustomUserTemplate = () => {
  const [tableConfigs, setTableConfigs] = useState({
    page: 1,
    sortInfo: {},
    filterInfo: {},
  });
  const [viewDifference, setViewDifference] = useState({});

  const { page, sortInfo, filterInfo } = tableConfigs;

  const previousPage = usePrevious(page);
  const previousSortInfo = usePrevious(sortInfo);
  const previousFilterInfo = usePrevious(filterInfo);

  const { response: cmsContent, isLoading: cmsContentLoading } = useAsyncFetch(
    true,
    fetchCmsContentApi,
    {
      contentType: 'INBOX_TEMPLATE',
      fetchAll: true,
    },
    undefined,
    {
      onError: () => notify(`Couldn't fetch CMS Contents!`, 'error'),
    }
  );

  const {
    response: postConnectionTemplatesData,
    isLoading: isFetchingPostConnectionTemplates,
  } = useAsyncFetch(true, fetchTemplatesApi, { fetchAll: true }, undefined, {
    onError: () => notify(`Couldn't fetch post connection templates!`, 'error'),
  });
  const inboxTemplatesData = get(
    postConnectionTemplatesData,
    'data.inboxTemplates',
    []
  );

  const shouldFetchCustomUserTemplate =
    (previousPage && previousPage !== page) ||
    !isEqual(previousSortInfo, sortInfo) ||
    !isEqual(previousFilterInfo, filterInfo);
  const {
    response: customUserTemplateResponse,
    isLoading: isLoadingCustomUserTemplateData,
    refetch: refetchCustomUserTemplate,
  } = useAsyncFetch(true, fetchCustomUserTemplateDataApi, { page }, undefined, {
    onError: () => notify(`Couldn't fetch Custom User Templates!`, 'error'),
    dependency: [page],
  });

  useEffect(() => {
    if (shouldFetchCustomUserTemplate && !isLoadingCustomUserTemplateData) {
      refetchCustomUserTemplate({
        page,
        sortInfo,
        filterInfo,
      });
    }
  }, [
    shouldFetchCustomUserTemplate,
    isLoadingCustomUserTemplateData,
    page,
    sortInfo,
    filterInfo,
  ]);

  const { customUserTemplates, count } = processTemplateResponse(
    customUserTemplateResponse
  );

  const getColumnSearchProps = (dataIndex, placeholder = '') => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  const columns = [
    {
      title: 'Original Template',
      dataIndex: 'originalTemplate',
      key: 'originalTemplate',
      filters: inboxTemplatesData?.map?.(template => ({
        text: template.name,
        value: template.id,
      })),
    },
    {
      title: 'Saved as',
      dataIndex: 'savedAs',
      key: 'savedAs',
    },
    {
      title: 'Saved by',
      dataIndex: 'savedBy',
      key: 'savedBy',
      ...getColumnSearchProps('savedBy', 'user name'),
    },
    {
      title: 'Cluster',
      dataIndex: 'clusterName',
      key: 'clusterName',
      ...getColumnSearchProps('clusterName', 'cluster name'),
    },
    {
      title: 'No. of times used',
      dataIndex: 'usedCount',
      key: 'usedCount',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Date - first saved',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Deleted At',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
    },
    {
      title: 'View',
      dataIndex: 'viewContentDifference',
      key: 'viewContentDifference',
      render: (_, record) => (
        <>
          <Button onClick={e => onViewClick(e, record)}>View</Button>
        </>
      ),
    },
  ];

  const onViewClick = (e, record) => {
    if (e?.stopPropagation) {
      e.stopPropagation();
    }
    setViewDifference(record);
  };

  const closeViewContent = () => {
    setViewDifference({});
  };

  const handleTableChange = (pagination, filterValue, sorter) => {
    const newTableConfig = { ...tableConfigs };
    newTableConfig.page = get(pagination, 'current', 1);
    if (!isEmpty(sorter)) {
      newTableConfig.sortInfo = {
        [sorter.field]: sorter.order,
      };
    }
    if (!isEmpty(filterValue)) {
      const templateValue = get(filterValue, 'originalTemplate', []);
      const [clusterName = ''] = get(filterValue, 'clusterName', []);
      const [userName = ''] = get(filterValue, 'savedBy', []);
      newTableConfig.filterInfo = {
        originalTemplate: sortBy(templateValue),
        clusterName,
        userName,
      };
    }
    setTableConfigs(newTableConfig);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    if (clearFilters) {
      clearFilters();
    }
  };

  const cmsData =
    !isEmpty(viewDifference) &&
    cmsContent?.data?.contents?.find?.(
      c => c.identifier === viewDifference.originalTemplateIdentifier
    );

  return (
    <div>
      {!isLoadingCustomUserTemplateData && count ? (
        <p>{count} results found</p>
      ) : null}
      <Table
        dataSource={customUserTemplates}
        columns={columns}
        loading={
          isLoadingCustomUserTemplateData || isFetchingPostConnectionTemplates
        }
        rowKey={record => record.id}
        className="banner-table"
        pagination={{
          simple: false,
          total: count,
          current: page,
          pageSize: 10,
        }}
        scroll={{
          x: true,
        }}
        onChange={handleTableChange}
      />
      <Modal
        title="View"
        visible={!isEmpty(viewDifference)}
        onCancel={closeViewContent}
        closable
        footer={null}
        width={700}
      >
        <Spin spinning={cmsContentLoading}>
          <Collapse>
            <>
              {cmsData ? (
                <Panel header="Original Template" key="original-template">
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: sanitize(cmsData?.content) || '',
                    }}
                  />
                </Panel>
              ) : null}
              <Panel header="Custom User Template" key="custom-user-template">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: sanitize(viewDifference.content) || '',
                  }}
                />
              </Panel>
            </>
          </Collapse>
        </Spin>
      </Modal>
    </div>
  );
};

export default CustomUserTemplate;
