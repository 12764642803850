import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Input, Icon, Tooltip } from 'antd';
import MultiSelect from '../../../component/MultiSelect';

import {
  setHubsSearchText as setHubsSearchTextAction,
  setHubsDataPage as setHubsDataPageAction,
  setHubsFilterApplied as setHubsFilterAppliedAction,
  setSelectedLibraries as setSelectedLibrariesAction,
  setSelectedUsertypeTemplates as setSelectedUsertypeTemplatesAction,
  clearHubFilters as clearHubFiltersAction,
  clearUsertypeSettings as clearUsertypeSettingsAction,
  fetchHubs as fetchHubsAction,
  fetchLibrariesAndTemplatesAndUpsells as fetchLibrariesAndTemplatesAndUpsellsAction,
} from '../actions';

function UsertypeSettingHeader({
  history,
  hubsSearchText,
  hubsFilterApplied,
  hubsDataLoading,
  setHubsSearchText,
  setHubsDataPage,
  setHubsFilterApplied,
  setSelectedLibraries,
  setSelectedUsertypeTemplates,
  clearHubFilters,
  clearUsertypeSettings,
  filtersLoading,
  fetchHubs,
  fetchLibrariesAndTemplatesAndUpsells,
  usertypeTemplates,
  libraries,
  upsells,
  selectedLibraries,
  selectedUsertypeTemplates,
}) {
  useEffect(() => {
    fetchLibrariesAndTemplatesAndUpsells();
    return () => clearUsertypeSettings();
  }, [fetchLibrariesAndTemplatesAndUpsells, clearUsertypeSettings]);

  const goToPage = type => {
    if (type === 'libraries') {
      history.push('/usertype-settings/manage-usertype-library/');
    } else if (type === 'templates') {
      history.push('/usertype-settings/manage-templates/');
    } else if (type === 'upsells') {
      history.push('/usertype-settings/manage-upsells/');
    }
  };

  const handleApply = () => {
    setHubsDataPage(1);
    setHubsFilterApplied(true);
    fetchHubs();
  };

  const handleCancel = () => {
    clearHubFilters();
    fetchHubs();
  };

  const handleLibraryChange = values => {
    setSelectedLibraries(values);
    // Remove values from UsertypeTemplates
    setSelectedUsertypeTemplates([]);
  };

  const handleUsertypeTemplateChange = values => {
    setSelectedUsertypeTemplates(values);
    // Remove values from Library
    setSelectedLibraries([]);
  };

  const applyFilterButtonEnabled =
    !filtersLoading &&
    !hubsDataLoading &&
    (hubsSearchText ||
      (selectedLibraries && selectedLibraries.length > 0) ||
      (selectedUsertypeTemplates && selectedUsertypeTemplates.length > 0));

  const manageTemplatesButtonEnabled =
    !filtersLoading && upsells && upsells.length > 0;
  const manageLibrariesButtonEnabled =
    !filtersLoading &&
    manageTemplatesButtonEnabled &&
    usertypeTemplates &&
    usertypeTemplates.length > 0;

  return (
    <div className="header-container">
      <div className="header-container__upper">
        <p className="header-container__text">User Type Provisioning Tool</p>
        <div>
          <Tooltip
            placement="bottom"
            title={
              !manageLibrariesButtonEnabled
                ? 'You need to have created at least one upsell and one User Type template to begin creating User Type libraries.'
                : ''
            }
          >
            <Button
              className="header-container__btn header-container__btn-margin"
              onClick={() => goToPage('libraries')}
              disabled={!manageLibrariesButtonEnabled}
            >
              Manage User Type Libraries
            </Button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={
              !manageTemplatesButtonEnabled
                ? 'You need to have created at least one upsell to begin creating User Type templates.'
                : ''
            }
          >
            <Button
              className="header-container__btn header-container__btn-margin"
              onClick={() => goToPage('templates')}
              disabled={!manageTemplatesButtonEnabled}
            >
              Manage User Type Templates
            </Button>
          </Tooltip>
          <Button
            type="primary"
            className="header-container__btn"
            onClick={() => goToPage('upsells')}
          >
            Manage Upsell Actions
          </Button>
        </div>
      </div>
      <div className="header-container__row header-container__filter">
        <div className="header-container__row">
          <Input
            className="header-container__input header-container__input-margin"
            placeholder="Search by Name, Hub ID"
            prefix={<Icon type="search" />}
            onChange={e => setHubsSearchText(e.target.value)}
            value={hubsSearchText}
            onPressEnter={handleApply}
            style={{ width: '240px' }}
            autoComplete="off"
          />
          <MultiSelect
            optionFilterProp="children"
            placeholder="Filter by User Type Library"
            dataSource={libraries}
            value={selectedLibraries}
            onChange={handleLibraryChange}
            style={{ width: '300px' }}
            className="header__select header-container__input-margin"
          />
          <MultiSelect
            optionFilterProp="children"
            placeholder="Filter by User Type Template"
            dataSource={usertypeTemplates}
            value={selectedUsertypeTemplates}
            onChange={handleUsertypeTemplateChange}
            style={{ width: '300px' }}
            className="header__select"
          />
        </div>
        <div>
          <Button
            icon="filter"
            onClick={handleApply}
            disabled={!applyFilterButtonEnabled}
            className="header-container__btn header-container__btn-margin"
          >
            Apply Filters
          </Button>
          <Button
            disabled={!hubsFilterApplied}
            icon="sync"
            className="header-container__btn"
            onClick={handleCancel}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  usertypeManage: {
    hubsSearchText,
    hubsFilterApplied,
    hubsDataLoading,
    filtersLoading,
    usertypeTemplates,
    libraries,
    upsells,
    selectedLibraries,
    selectedUsertypeTemplates,
  },
}) => ({
  hubsSearchText,
  hubsFilterApplied,
  hubsDataLoading,
  filtersLoading,
  usertypeTemplates,
  libraries,
  upsells,
  selectedLibraries,
  selectedUsertypeTemplates,
});

const mapDispatchToProps = {
  setHubsSearchText: setHubsSearchTextAction,
  setHubsDataPage: setHubsDataPageAction,
  setHubsFilterApplied: setHubsFilterAppliedAction,
  setSelectedLibraries: setSelectedLibrariesAction,
  setSelectedUsertypeTemplates: setSelectedUsertypeTemplatesAction,
  clearHubFilters: clearHubFiltersAction,
  clearUsertypeSettings: clearUsertypeSettingsAction,
  fetchHubs: fetchHubsAction,
  fetchLibrariesAndTemplatesAndUpsells:
    fetchLibrariesAndTemplatesAndUpsellsAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsertypeSettingHeader)
);
