/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import FileUploader from '../../component/FileUploader';
import { getUploadUrl } from '../../utils/getUploadUrl';

import 'react-quill/dist/quill.snow.css';
import '../../component/QuillEditor/style.scss';

// Image Resize Module
Quill.register('modules/imageResize', ImageResize);

const toolbarModules = {
  container: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
};

const imageHandler = () => {
  const openFileUploader = document.getElementById('openFileUploader');
  if (openFileUploader) openFileUploader.click();
};

const QuillEditor = ({ style, onChange, value, getInitialCount }) => {
  const defaultRefValue = null;
  const [maxTries, setMaxTries] = useState(10);
  const [fetchingImage, setFetchingImage] = useState(false);
  const quillRef = useRef(defaultRefValue);

  useEffect(() => {
    const editor = quillRef.current.getEditor();
    getInitialCount(editor.getText().trim().length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, getInitialCount]);

  const modules = {
    toolbar: {
      ...toolbarModules,
      handlers: {
        image: imageHandler,
      },
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize'],
    },
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
  ];

  const checkAndSetImage = (src, editorRef, index) => {
    if (!editorRef) return;
    setFetchingImage(true);
    let img = new Image();
    img.src = src;
    img.onload = () => {
      img = null;
      setFetchingImage(false);
      setMaxTries(10);
      editorRef.current.getEditor().insertEmbed(index, 'image', src);
    };
    img.onerror = () => {
      if (maxTries > 0) {
        img = null;
        setMaxTries(maxTries - 1);
        setTimeout(() => checkAndSetImage(src, editorRef, index), 1000);
      } else {
        img = null;
        setFetchingImage(false);
        editorRef.current.getEditor().insertEmbed(index, 'image', src);
      }
    };
  };

  const handleUploadSuccess = ({ filesUploaded }) => {
    if (filesUploaded.length > 0) {
      const url = getUploadUrl(filesUploaded[0], '/admin/');
      if (!quillRef) return;
      quillRef.current.getEditor().focus();
      const range = quillRef.current.getEditor().getSelection();
      if (url) {
        let index = 0;
        if (range) {
          index = range.index;
        } else {
          index = quillRef.current.getEditor().getLength();
        }
        checkAndSetImage(url, quillRef, index);
      }
    }
  };

  return (
    <>
      <Spin spinning={fetchingImage} indicator={<Icon type="loading" spin />}>
        <ReactQuill
          className={`quill-wysiwyg-editor ${style}`}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          ref={quillRef}
        />
      </Spin>
      <FileUploader
        type="image"
        path="/admin/"
        render={({ onPick }) => <span onClick={onPick} id="openFileUploader" />}
        onSuccess={handleUploadSuccess}
        options={{ maxFiles: 1 }}
      />
    </>
  );
};

QuillEditor.propTypes = {
  style: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  getInitialCount: PropTypes.func,
};

QuillEditor.defaultProps = {
  style: '',
  getInitialCount: () => {},
};

export default QuillEditor;
