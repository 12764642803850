import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  PageHeader,
  Input,
  Divider,
  Row,
  Button,
  Card,
  Table,
  Modal,
} from 'antd';
import moment from 'moment';

import { isNaN, isNil } from 'lodash';

import {
  getHubDeletetionList,
  deleteRequestedHubById,
  approveRequestedHubById,
} from './actions';

import Content from '../../component/Content';
import s from './index.module.scss';

const { Search } = Input;
const { confirm } = Modal;

const ApproveDeletedHubs = ({
  getHubDeletetionListProps,
  deleteRequestedHubByIdProps,
  approveRequestedHubByIdProps,
  requestedHubDeletionList,
  loading,
}) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getHubDeletetionListProps();
  }, [getHubDeletetionListProps]);

  const confirmDeleteNote = v => {
    confirm({
      title: 'Are you sure you want to delete this request?',
      onOk: () => new Promise(resolve => resolve(removeRequestedHub(v))),
      onCancel: () => {},
    });
  };

  const confirmApproveOrDisApproveRequest = v => {
    confirm({
      title: `Are you sure you want to ${
        v.approved ? 'Disapprove' : 'Approve'
      } this request?`,
      onOk: () =>
        new Promise(resolve => resolve(approveOrUnapproveRequestedHubById(v))),
      onCancel: () => {},
    });
  };

  const removeRequestedHub = v => {
    deleteRequestedHubByIdProps(v);
  };

  const approveOrUnapproveRequestedHubById = v => {
    approveRequestedHubByIdProps({ id: v.id, approved: !v.approved });
  };
  const filterRequestedHubDeletionList = () => {
    const lowerCaseSarchtext = searchText && searchText.trim().toLowerCase();
    if (lowerCaseSarchtext.length && requestedHubDeletionList) {
      return requestedHubDeletionList.filter(obj => {
        if (
          !isNil(lowerCaseSarchtext) &&
          isNaN(parseInt(lowerCaseSarchtext, 10))
        )
          return (
            (obj.name && obj.name.toLowerCase().includes(lowerCaseSarchtext)) ||
            (obj.domain &&
              obj.domain.toLowerCase().includes(lowerCaseSarchtext)) ||
            (obj.hub && obj.hub.toString().includes(lowerCaseSarchtext))
          );
        return obj.id && obj.id.toString() === lowerCaseSarchtext;
      });
    }
    return requestedHubDeletionList;
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Hub Name',
      dataIndex: 'name',
      key: 'hub_name',
    },
    {
      title: 'Hub Id',
      dataIndex: 'hub',
      key: 'hub_id',
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Requested By',
      dataIndex: 'deleteRequestedBy',
      key: 'deleteRequestedBy',
    },
    {
      title: 'Requested Date',
      dataIndex: 'deleteRequestedAt',
      key: 'deleteRequestedAt',
      render: v => moment(v).format('L'),
    },
    {
      title: 'Delete Date',
      dataIndex: 'hubDeletedAt',
      key: 'hubDeletedAt',
      render: v => (v ? moment(v).format('L') : ''),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 100,
    },
    {
      title: 'isDeleted',
      dataIndex: 'isHubDeleted',
      key: 'isHubDeleted',
      render: (k, v) => <div>{v.isHubDeleted ? 'Yes' : 'No'}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Approve',
      dataIndex: 'approveOrUnapproved',
      key: 'approveOrUnapproved',
      render: (k, v) => (
        <div>
          <Button
            type="link"
            disabled={v.isHubDeleted && true}
            onClick={() => confirmApproveOrDisApproveRequest(v)}
          >
            {v.approved ? 'Disapprove' : 'Approve'}
          </Button>
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (k, v) => (
        <div>
          <Button
            type="danger"
            icon="delete"
            disabled={v.isHubDeleted && true}
            onClick={() => confirmDeleteNote(v)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="approve-hubs">
      <PageHeader title="Approve Deleted Hubs">
        <Divider />
        <Row type="flex">
          <Search
            placeholder="Search by name, Hub ID"
            style={{ width: 250, float: 'left' }}
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
          />
        </Row>
      </PageHeader>
      <Content>
        <Card bordered={false}>
          <Table
            className={s.approveHubDelete}
            dataSource={filterRequestedHubDeletionList()}
            columns={columns}
            loading={loading}
            rowKey="id"
          />
        </Card>
      </Content>
    </div>
  );
};

const mapStateToProps = ({
  requestedHubDeletion: { requestedHubDeletionList, loading },
}) => ({
  requestedHubDeletionList,
  loading,
});

const mapDispatchToProps = {
  getHubDeletetionListProps: getHubDeletetionList,
  deleteRequestedHubByIdProps: deleteRequestedHubById,
  approveRequestedHubByIdProps: approveRequestedHubById,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApproveDeletedHubs)
);
