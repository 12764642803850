import React from 'react';

export default [
  {
    key: 'users',
    title: 'User and Profile Data',
  },
  {
    key: 'manageAdmins', // TODO: missing in backend ?
    title: 'Manage Admins',
    children: [
      { key: 'admins', title: 'Admins', required: ['users'] },
      { key: 'adminAccessGroups', title: 'Admin Access Groups' },
    ],
  },
  {
    key: 'advising',
    title: 'Adivising',
    children: [
      { key: 'advisingConfig', title: 'Advising Configuraiton (Appointment Types, Audiences)', required: ['users']},
    ],
  },
  {
    key: 'evnts',
    title: 'Events',
    children: [ 
      { key: 'eventTypeConfig', title: 'Event Type COnfiguration' },
      { key: 'events', title: 'Events', required:['eventTypeConfig', 'users'] },
      { key: 'eventAttendees', title: 'Event Attendees', required: ['users', 'events']}
    ]
  },
  {
    key: 'notifications',
    title: 'Notificaitons',
    children: [ 
      { key: 'customNotifications', title: 'Custom Notificaions', required: ['users'] },
    ]
  },
  {
    key: 'rscs',
    title: 'Resources',
    children: [ 
      { key: 'resources', title: 'Resources', required: ['users', 'industriesHelpTopics']},
    ]
  }, 
  {
    key: 'initialSetup',
    title: 'Initial Setup',
    children: [ 
      { key: 'brandingSettings', title: 'Branding Settings' },
      { key: 'hubLandingPages', title: 'Hub Landing Pages' },
      { key: 'newsLetterSettings', title: 'Newsletter Settings' },
      { key: 'customFields', title: 'Custom Fields', required: ['users']},
      { key: 'networkSettings', title: 'Network Settings' },
      { key: 'defaultGoals', title: 'Default Goals' },
      { key: 'hubGroupCategory', title: 'Hub Group Category' },
      { key: 'invitePage', title: 'Invite Page', required: ['users'] },
      { key: 'hubBasicSettings', title: 'Hub Basic Settings' },
      { key: 'recruitingSettings', title: 'Recruiting Settings' },
      { key: 'staticContent', title: 'Static Content', required: ['users'] },
      { key: 'listBadges', title: 'List & Badges', required: ['badges','users']},
      { key: 'adviserSettings', title: 'Adviser Settings' },
      { key: 'industriesHelpTopics', title: 'Industries/Help Topics' },
      { key: 'opportunitiesSettings', title: 'Opportunities Settings' },
      { key: 'matchingFlowSettings', title: 'Matching Flow Settings', required: ['users'] },
      { key: 'signupAgreements', title: 'Signup Agreements', required: ['users']},
      { key: 'badges', title: 'Badges'},
      { key: 'surveys', title: 'Surveys(Includes Pathways)', required: ['badges'] },
      { key: 'jobs', title: 'Jobs' , required: ['industriesHelpTopics', 'users'] },
      { key: 'directorySettings', title: 'Directory Settings' },
      { key: 'blockingSignupStep', title: 'Blocking Signup Step' },
      { key: 'introPrograms', title: 'Automated Intros' },
      { key: 'banner', title: 'Banner Ad', required: ['users'] },
      { key: 'notificationPreferences', title: 'Notification Preferences' },
      { key: 'signupStages', title: 'Hub Signup Stages' },
      { key: 'matchingAlgorithm', title: 'Matching Algorithm' },
      { key: 'hubTableColumns', title: 'Hub Table Columns', required: ['users'] },
      { key: 'hubCompanies', title: 'Hub Companies' },
    ]
  }, 
  {
    key: 'grps',
    title: 'Groups',
    children: [ 
      { key: 'groups', title: 'Groups (All data, settings, resources, etc)', required: ['users']}, 
      { key: 'groupUsers', title: 'Group Users', required: ['users', 'groups']},
    ]
  }, 
  {
    key: 'prgrms',
    title: 'Programs',
    children: [ 
      { key: 'programs', title: 'Programs (All data, settings, resources, etc)', required: ['users']},
      { key: 'programUsers', title: 'Program Users', required:['users', 'programs']},
    ]
  }, 
  { key: 'hubModuleSettings', title: 'Hub Module Settings', required: true }, 
  { key: 'transactionalEmails', title: 'Transactional Emails' }, 
  { key: 'defaultMessageTemplate', title: 'Default Message Template', required: ['users']}, 
  { key: 'hubTextSettings', title: 'Hub Text Settings' }, 
  { key: 'flexCustomFields', title: 'Flex Custom Fields', required:['users'] },
  {
    key: 'communication',
    title: 'Communication',
    children: [ 
      { key: 'customTemplates', title: 'Custom Templates' },
    ]
  },
  {
    key: 'appIntegrations',
    title: 'App & Integrations',
  },
];
