import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { getReceivedResponsesCountApi } from '../../../api/askAnythingMetricsApi';
import { notifyError } from '../../../utils/notify';

const { responsesReceivedCount } = queryKeys;

const getReceivedResponsesCount = async filters => {
  const { data } = await getReceivedResponsesCountApi(filters);
  return data;
};

export const useGetReceivedResponsesCountQuery = filters =>
  useQuery(
    [responsesReceivedCount, filters],
    () => getReceivedResponsesCount(filters),
    {
      onError: err => {
        notifyError(err, false, 'Unable to fetch received responses count');
      },
    }
  );
