import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  FETCH_PROCESSING_IMPORT,
  HANDLE_RESTART_CLICK,
  setImportListLoading,
  setImportList,
  setImportListCount,
  setImportQueueStatus,

  // selectors
  importListPage,
  importList,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';
import { fetchImport } from '../../../api/manageImportApi';
import { createRestartJob } from '../../../api/manageBulkEmailApi';
import { get } from 'lodash';

export default [
  takeLatest(FETCH_PROCESSING_IMPORT, fetchProcessingImportSaga),
  takeLatest(HANDLE_RESTART_CLICK, handleRestartSaga),
];

function* fetchProcessingImportSaga() {
  try {
    const page = yield select(importListPage);
    yield put(setImportListLoading(true));
    const queryParams = {
      page,
    };
    const { data } = yield call(fetchImport, queryParams);
    yield put(setImportList(get(data, 'result', [])));
    yield put(setImportListCount(get(data, 'totalCount', 0)));
    yield put(setImportQueueStatus(get(data, 'queueStatus', {})));
    yield put(setImportListLoading(false));
  } catch (e) {
    console.log('Error', e);
    yield put(setImportListLoading(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching import.' },
    });
  }
}

function* handleRestartSaga({ data: { id, status } }) {
  try {
    const list = yield select(importList);
    const createStatus = ['creatingRows', 'errorCreatingRows', 'readyToCreate'];
    let object = 'IMPORT_START';
    if (createStatus.indexOf(status) > -1) {
      object = 'IMPORT_CREATE';
    }
    const body = {
      id,
      relatedObject: object,
    };
    const { data: resData } = yield call(createRestartJob, body);
    if (resData && resData.message === 'invalid_file') {
      yield put({
        type: POPUP_ALERT,
        data: {
          type: 'error',
          message: 'Cannot restart job import file invalid.',
        },
      });
      return;
    }
    const modifiedList = list.map(item => {
      if (item.id === id) {
        return {
          ...item,
          restartObj: resData,
        };
      }
      return item;
    });
    yield put(setImportList(modifiedList));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Successfully restarted!' },
    });
  } catch (e) {
    console.log('Error', e);
  }
}
