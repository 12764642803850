const prefix = 'COMPASS';

export const SET_FORM_DATA = `${prefix}/SET_FORM_DATA`;

export const SET_COMPASS_DATA = `${prefix}/SET_COMPASS_DATA`;

export const setFormData = data => ({
  type: SET_FORM_DATA,
  data,
});

export const setCompassData = data => ({
  type: SET_COMPASS_DATA,
  data,
});
