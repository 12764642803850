import React, { PureComponent } from 'react';
import {
  Button,
  Table,
  Row,
  Col,
  Card,
  Icon,
  Input,
  Dropdown,
  Menu,
  Tooltip,
  PageHeader,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { filesize } from 'filesize';
import CreateNewModal from './CreateNewModal';
import {
  getSeeds,
  createSeed,
  updateSeeds,
  deleteSeed,
  makeSeedDefault,
  downloadSeed,
} from './actions';
import Content from '../../component/Content';
import s from './index.module.sass';

const title = () => (
  <h3 className={s.tableTitle}> Available Database Seeds </h3>
);

const renderCreatedAt = createdAt => new Date(createdAt).toLocaleString();
const renderUserName = (userName, row) => (
  <Tooltip placement="topLeft" title={`User Id: ${row.userId}`}>
    {userName}
  </Tooltip>
);
const renderSize = size => <span> {filesize(size)} </span>;
const renderTitle = (title, row) => (
  <Tooltip placement="topLeft" title={`Database Id: ${row.id}`}>
    {row.isDefault && (
      <>
        {' '}
        <Icon theme="twoTone" type="star" />{' '}
      </>
    )}
    {title}
  </Tooltip>
);

class SeedDatabases extends PureComponent {
  state = {
    showCreateNewModal: false,
  };

  componentDidMount() {
    this.getSeeds();
  }

  renderActions = (isDefault, row) => (
    <Dropdown
      overlay={
        <Menu>
          {!isDefault && (
            <Menu.Item onClick={() => this.props.makeSeedDefault(row.id)}>
              <Icon type="star" /> Set As Default
            </Menu.Item>
          )}
          <Menu.Item onClick={() => this.props.deleteSeed(row.id)}>
            <Icon type="delete" /> Delete
          </Menu.Item>
        </Menu>
      }
    >
      <Button>...</Button>
    </Dropdown>
  );

  renderKey = (awsKey, seed) => {
    return (
      <Input
        addonAfter={
          <Icon
            title="download file"
            type="cloud-download"
            onClick={() => this.handleDownload(seed.id)}
          />
        }
        value={awsKey}
        onChange={this.doNothing}
      />
    );
  };

  handleDownload = id => {
    this.props.downloadSeed(id);
  };

  columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: renderTitle,
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      render: renderUserName,
    },
    {
      title: 'Aws Key',
      dataIndex: 'awsKey',
      key: 'awsKey',
      render: this.renderKey,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: renderSize,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: renderCreatedAt,
    },
    {
      title: 'Actions',
      dataIndex: 'isDefault',
      key: 'actions',
      render: this.renderActions,
    },
  ];

  getSeeds(options) {
    this.props.getSeeds({
      ...this.getDefFetchOptions(),
      ...options,
    });
  }

  getDefFetchOptions = () => ({
    page: this.props.pagination.current,
  });

  addNew = () => this.setState({ showCreateNewModal: true });
  onNewModalClose = () => this.setState({ showCreateNewModal: false });

  createSeed = createOptions => {
    this.onNewModalClose();
    this.props.createSeed(createOptions, this.getDefFetchOptions());
  };

  doNothing = () => {};

  renderDefaultSeed = () => {
    const { defaultSeed } = this.props;
    console.log('default seed is ---', defaultSeed);
    if (defaultSeed)
      return (
        <div className={s.defSeed}>
          <div>
            <label> Title:</label>
            <div className={s.betweenFlex}>
              {' '}
              <b> {defaultSeed.title} </b> <span> ({defaultSeed.id}) </span>{' '}
            </div>
          </div>
          <div>
            <label> Created By: </label>
            <div className={s.betweenFlex}>
              {' '}
              <b>{defaultSeed.username}</b>{' '}
              <span> {new Date(defaultSeed.createdAt).toLocaleString()} </span>{' '}
            </div>
          </div>
          <div>
            <label>Aws Key:</label>
            <div>{this.renderKey(defaultSeed.awsKey, defaultSeed)}</div>
          </div>
        </div>
      );
    else return 'No Default Found';
  };

  handlePageChange = pagination => {
    this.getSeeds({ page: pagination.current });
    this.props.updateSeeds({ pagination });
  };

  render() {
    return (
      <div>
        <PageHeader
          title="Seed Databases"
          extra={[
            <Button type="primary" onClick={this.addNew}>
              Add New Seed
            </Button>,
          ]}
        />
        <Content>
          <Row type="flex" className={s.info}>
            <CreateNewModal
              visible={this.state.showCreateNewModal}
              onClose={this.onNewModalClose}
              createSeed={this.createSeed}
            />
            <Col span={10}>
              <Card
                title={
                  <span>
                    <Icon theme="twoTone" type="star" /> Default Seed
                  </span>
                }
              >
                {this.renderDefaultSeed()}
              </Card>
            </Col>
          </Row>
          <Row className={s.content}>
            <Col>
              <Table
                onChange={this.handlePageChange}
                loading={this.props.seedsLoading}
                className={s.table}
                title={title}
                columns={this.columns}
                dataSource={this.props.seeds}
                pagination={this.props.pagination}
              />
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({
  seeds: { seeds, seedsLoading, defaultSeed, pagination },
}) => ({
  seeds,
  seedsLoading,
  defaultSeed,
  pagination,
});

const mapDispatchToProps = dispatch => ({
  getSeeds: options => dispatch(getSeeds(options)),
  createSeed: (createOptions, getOptions) =>
    dispatch(createSeed(createOptions, getOptions)),
  updateSeeds: data => dispatch(updateSeeds(data)),
  deleteSeed: id => dispatch(deleteSeed(id)),
  makeSeedDefault: id => dispatch(makeSeedDefault(id)),
  downloadSeed: id => dispatch(downloadSeed(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SeedDatabases)
);
