// eslint-disable-next-line import/prefer-default-export
export const cmsContentTypes = [
  {
    tab: 'Inbox Templates',
    key: 'inbox-templates',
    value: 'INBOX_TEMPLATE',
  },
  {
    tab: 'Inbox Tips',
    key: 'inbox-tips',
    value: 'INBOX_TIP',
  },
  {
    tab: 'Alum Student Templates',
    key: 'alum-student-outreach',
    value: 'ALUM_STUDENT_OUTREACH_TEMPLATES',
  },
];

export const MERGE_TAGS = [
  {
    key: 'inbox-templates',
    fields: [
      'menteeFirstName',
      'mentorFirstName',
      'menteeLastName',
      'mentorLastName',
      'mentorCollege',
      'mentorMajor',
      'menteeMajor',
      'mentorRole',
      'menteeIndustryName',
      'menteeYrsOfExperience',
      'mentorYrsOfExperience',
      'mentorIndustryName',
      'mentorCompanyName',
      'menteeCompanyName',
      'menteeRole',
      'availableTime',
      'availableDate',
      'otherUserFirstName',
      'currentUserFirstName',
      'blankField',
      'scheduledMeetingTime',
      'scheduledMeetingDate',
    ],
  },
  {
    key: 'inbox-tips',
    fields: [
      'menteeFirstName',
      'mentorFirstName',
      'menteeLastName',
      'mentorLastName',
      'mentorCollege',
      'mentorMajor',
      'menteeMajor',
      'mentorRole',
      'menteeIndustryName',
      'menteeYrsOfExperience',
      'mentorYrsOfExperience',
      'mentorIndustryName',
      'mentorCompanyName',
      'menteeCompanyName',
      'menteeRole',
      'availableTime',
      'availableDate',
      'otherUserFirstName',
      'currentUserFirstName',
      'blankField',
      'scheduledMeetingTime',
      'scheduledMeetingDate',
    ],
  },
  {
    key: 'alum-student-outreach',
    fields: ['blankField', 'studentFirstName', 'alumniFirstName'],
  },
];
