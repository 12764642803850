import React from 'react';
import { connect } from 'react-redux';
import GroupProgramTierHeader from './GroupProgramTierHeader';
import GroupProgramTable from './GroupProgramTable';
import ConfigurationModal from './ConfigurationModal';
import { clearGroupTableData } from './actions';
import './GroupTier.scss';

class GroupProgramTier extends React.Component {
  componentWillUnmount() {
    const { clearGroupTableData } = this.props;
    clearGroupTableData();
  }

  render() {
    return (
      <div className="groups_programs_tier">
        <GroupProgramTierHeader />
        <GroupProgramTable />
        <ConfigurationModal />
      </div>
    )
  }
}

const mapDispatchToProps = {
  clearGroupTableData,
}

export default connect(null, mapDispatchToProps)(GroupProgramTier);