const prefix = 'standard-hub-management/';

export const FETCH_HUBS = `${prefix}FETCH_HUBS`;
export const FETCH_BASE_CONFIG = `${prefix}FETCH_BASE_CONFIG`;
export const SAVE_BASE_CONFIG = `${prefix}SAVE_BASE_CONFIG`;
export const VERIFY_AND_GET_DATA = `${prefix}VERIFY_AND_GET_DATA`;
export const START_STANDARD_MIGRATION = `${prefix}START_STANDARD_MIGRATION`;
export const FETCH_JOB_STATUS = `${prefix}FETCH_JOB_STATUS`;
export const STOP_FETCH_JOB_STATUS = `${prefix}STOP_FETCH_JOB_STATUS`;

export const SET_LIST_LOADING = `${prefix}SET_LIST_LOADING`;
export const SET_HUBS_DATA = `${prefix}SET_HUBS_DATA`;
export const SET_PAGE = `${prefix}SET_PAGE`;
export const SET_TOTAL = `${prefix}SET_TOTAL`;
export const SET_SEARCH_TEXT = `${prefix}SET_SEARCH_TEXT`;
export const SET_PLATFORM_TYPE = `${prefix}SET_PLATFORM_TYPE`;
export const SET_FILTERS_ENABLED = `${prefix}SET_FILTERS_ENABLED`;
export const CLEAR_STANDARD_STATE = `${prefix}CLEAR_STANDARD_STATE`;

export const SET_BASE_CONFIG_LOADING = `${prefix}SET_BASE_CONFIG_LOADING`;
export const SET_BASE_CONFIG_SAVING = `${prefix}SET_BASE_CONFIG_SAVING`;
export const SET_BASE_CONFIG = `${prefix}SET_BASE_CONFIG`;
export const SET_BASE_CONFIG_DIFF = `${prefix}SET_BASE_CONFIG_DIFF`;
export const SET_BASE_CONFIG_HUB_OPTIONS = `${prefix}SET_BASE_CONFIG_HUB_OPTIONS`;
export const SET_SELECTED_HUB_ID = `${prefix}SET_SELECTED_HUB_ID`;
export const CLEAR_BASE_CONFIG_STATE = `${prefix}CLEAR_BASE_CONFIG_STATE`;

export const VERIFICATION_DATA_LOADING = `${prefix}VERIFICATION_DATA_LOADING`;
export const INIT_VERIFICATION_MODAL_VISIBLE = `${prefix}INIT_VERIFICATION_MODAL_VISIBLE`;
export const SET_VERIFICATION_STATUS = `${prefix}SET_VERIFICATION_STATUS`;
export const SET_CLUSTER_HUB_FOR_VERIFICATION = `${prefix}SET_CLUSTER_HUB_FOR_VERIFICATION`;
export const SET_HUB_MODULES = `${prefix}SET_HUB_MODULES`;
export const SET_CLUSTER_MODULES = `${prefix}SET_CLUSTER_MODULES`;
export const CLEAR_VERIFICATION_MODAL_STATE = `${prefix}CLEAR_VERIFICATION_MODAL_STATE`;

export const STANDARD_MIGRATION_STARTING = `${prefix}STANDARD_MIGRATION_STARTING`;
export const SET_JOB_ID = `${prefix}SET_JOB_ID`;
export const SET_JOB_STATUS_DATA = `${prefix}SET_JOB_STATUS_DATA`;
export const SET_JOB_STATUS_MODAL_VISIBLE = `${prefix}SET_JOB_STATUS_MODAL_VISIBLE`;
export const CLEAR_JOB_STATUS_MODAL_STATE = `${prefix}CLEAR_JOB_STATUS_MODAL_STATE`;
export const FETCH_HUB_CUSTOM_FIELDS = `${prefix}FETCH_HUB_CUSTOM_FIELDS`;
export const SET_SELECTED_HUB_CUSTOM_FIELDS = `${prefix}SET_SELECTED_HUB_CUSTOM_FIELDS`;
export const FETCH_PG_STANDARD_DATA_FIELDS = `${prefix}FETCH_PG_STANDARD_DATA_FIELDS`;
export const SET_PG_STANDARD_DATA_FIELDS = `${prefix}SET_PG_STANDARD_DATA_FIELDS`;
export const SAVE_CUSTOM_FIELD_MIGRATION_CONFIG = `${prefix}SAVE_CUSTOM_FIELD_MIGRATION_CONFIG`;
export const TRIGGER_MIGRATION = `${prefix}TRIGGER_MIGRATION`;
export const OPEN_CUSTOM_FIELD_PAGE = `${prefix}OPEN_CUSTOM_FIELD_PAGE`;
export const SET_API_LOADING = `${prefix}API_LOADING`;
export const UPDATE_OLD_DATA_TO_STANDARD_DATA_SET = `${prefix}UPDATE_OLD_DATA_TO_STANDARD_DATA_SET`;
export const SET_STANDARD_FIELDS_UPDATED = `${prefix}SET_STANDARD_FIELDS_UPDATED`;
export const SET_ALLOWED_USER_TYPES = `${prefix}SET_ALLOWED_USER_TYPES`;

export const fetchHubs = data => ({
  type: FETCH_HUBS,
  data,
});

export const fetchBaseConfig = data => ({
  type: FETCH_BASE_CONFIG,
  data,
});

export const saveBaseConfig = data => ({
  type: SAVE_BASE_CONFIG,
  data,
});

export const verifyAndGetData = data => ({
  type: VERIFY_AND_GET_DATA,
  data,
});

export const startStandardMigration = data => ({
  type: START_STANDARD_MIGRATION,
  data,
});

export const fetchJobStatus = data => ({
  type: FETCH_JOB_STATUS,
  data,
});

export const stopFetchJobStatus = data => ({
  type: STOP_FETCH_JOB_STATUS,
  data,
});

export const handleActionSetListLoading = data => ({
  type: SET_LIST_LOADING,
  data,
});

export const handleActionSetHubsData = data => ({
  type: SET_HUBS_DATA,
  data,
});

export const handleActionSetPage = data => ({
  type: SET_PAGE,
  data,
});

export const handleActionSetTotal = data => ({
  type: SET_TOTAL,
  data,
});

export const handleActionSetSearchText = data => ({
  type: SET_SEARCH_TEXT,
  data,
});

export const handleActionSetPlatformType = data => ({
  type: SET_PLATFORM_TYPE,
  data,
});

export const handleActionSetFiltersEnabled = data => ({
  type: SET_FILTERS_ENABLED,
  data,
});

export const handleActionSetBaseConfigLoading = data => ({
  type: SET_BASE_CONFIG_LOADING,
  data,
});

export const handleActionSetBaseConfigSaving = data => ({
  type: SET_BASE_CONFIG_SAVING,
  data,
});

export const handleActionSetBaseConfig = data => ({
  type: SET_BASE_CONFIG,
  data,
});

export const handleActionSetBaseConfigDiff = data => ({
  type: SET_BASE_CONFIG_DIFF,
  data,
});

export const handleActionSetBaseConfigHubOptions = data => ({
  type: SET_BASE_CONFIG_HUB_OPTIONS,
  data,
});

export const handleActionSetSelectedHubId = data => ({
  type: SET_SELECTED_HUB_ID,
  data,
});

export const handleActionVerificationDataLoading = data => ({
  type: VERIFICATION_DATA_LOADING,
  data,
});

export const handleActionInitVerificationModalVisible = data => ({
  type: INIT_VERIFICATION_MODAL_VISIBLE,
  data,
});

export const handleActionSetVerificationStatus = data => ({
  type: SET_VERIFICATION_STATUS,
  data,
});

export const handleActionSetClusterHubForVerification = data => ({
  type: SET_CLUSTER_HUB_FOR_VERIFICATION,
  data,
});

export const handleActionSetHubModules = data => ({
  type: SET_HUB_MODULES,
  data,
});

export const handleActionSetClusterModules = data => ({
  type: SET_CLUSTER_MODULES,
  data,
});

export const handleActionSetStandardMigrationStarting = data => ({
  type: STANDARD_MIGRATION_STARTING,
  data,
});

export const handleActionSetJobId = data => ({
  type: SET_JOB_ID,
  data,
});

export const handleActionSetJobStatusData = data => ({
  type: SET_JOB_STATUS_DATA,
  data,
});

export const handleActionSetAllowedUserTypes = data => ({
  type: SET_ALLOWED_USER_TYPES,
  data,
});

export const handleActionSetJobStatusModalVisible = data => ({
  type: SET_JOB_STATUS_MODAL_VISIBLE,
  data,
});

export const clearStandardState = () => ({
  type: CLEAR_STANDARD_STATE,
});

export const clearBaseConfigState = () => ({
  type: CLEAR_BASE_CONFIG_STATE,
});

export const clearVerificationModalState = () => ({
  type: CLEAR_VERIFICATION_MODAL_STATE,
});

export const clearJobStatusModalState = () => ({
  type: CLEAR_JOB_STATUS_MODAL_STATE,
});

export const fetchHubCustomFields = data => ({
  type: FETCH_HUB_CUSTOM_FIELDS,
  data,
});

export const setSelectedHubCustomFields = data => ({
  type: SET_SELECTED_HUB_CUSTOM_FIELDS,
  data,
});

export const fetchPgStandardFields = () => ({
  type: FETCH_PG_STANDARD_DATA_FIELDS,
});

export const setPgStandardFields = data => ({
  type: SET_PG_STANDARD_DATA_FIELDS,
  data,
});

export const saveCustomFieldMigrationConfig = data => ({
  type: SAVE_CUSTOM_FIELD_MIGRATION_CONFIG,
  data,
});

export const triggerMigration = data => ({
  type: TRIGGER_MIGRATION,
  data,
});

export const openCustomFieldPage = data => ({
  type: OPEN_CUSTOM_FIELD_PAGE,
  data,
});

export const setApiLoading = data => ({
  type: SET_API_LOADING,
  data,
});

export const addOldDataToStandardData = data => ({
  type: UPDATE_OLD_DATA_TO_STANDARD_DATA_SET,
  data,
});

export const getSelectedHubId = ({ standardHubManagement }) =>
  standardHubManagement.selectedHubId;
export const getJobId = ({ standardHubManagement }) =>
  standardHubManagement.jobId;
