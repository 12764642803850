import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MLServiceApi from './MLServiceApi';

const Routes = () => (
  <Switch>
    <Route path="/ml/api" component={MLServiceApi} />
  </Switch>
);

export default Routes;
