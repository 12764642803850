import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
  HANDLE_FETCH_TEMPLATES,
  HANDLE_INIT_EMAIL_EDITOR,
  HANDLE_OPEN_EMAIL_EDITOR,
  HANDLE_SAVE_EMAIL,
  HANDLE_FETCH_EMAILS,
  HANDLE_SEND_TEST_EMAIL,
  HANDLE_FETCH_STATS,
  HANDLE_DELETE_TEMPLATE,
  HANDLE_FETCH_ALL_CAMPAIGNS,
  HANDLE_CAMPAIGN_ACTIVE_TOGGLE,
  HANDLE_CAMPAIGN_DELETE,
  HANDLE_GET_CAMPAIGN_INFO,
  HANDLE_CREATE_OR_UPDATE_CAMPAIGN,
  HANDLE_DELETE_CAMPAIGN_EMAIL,
  HANDLE_UPDATE_CAMPAIGN_EMAIL,
} from '../actions';

import {
  fetchTemplatesSaga,
  initEmailEditorSaga,
  openEmailEditorSaga,
  saveEmailSaga,
  fetchEmailsSaga,
  sendTestEmailSaga,
  fetchStatsSaga,
  deleteTemplateSaga,
  fetchAllCampaignsSaga,
  updateCampaignActiveStatusSaga,
  deleteCampaignSaga,
  getCampaignInfoSaga,
  createOrUpdateCampaign,
  deleteCampaignEmailSaga,
  updateCampaignEmailSaga,
} from './sagas';

export default [
  takeLatest(HANDLE_FETCH_TEMPLATES, fetchTemplatesSaga),
  takeLatest(HANDLE_INIT_EMAIL_EDITOR, initEmailEditorSaga),
  takeLatest(HANDLE_OPEN_EMAIL_EDITOR, openEmailEditorSaga),
  takeLatest(HANDLE_SAVE_EMAIL, saveEmailSaga),
  takeLatest(HANDLE_FETCH_EMAILS, fetchEmailsSaga),
  takeLatest(HANDLE_SEND_TEST_EMAIL, sendTestEmailSaga),
  takeLatest(HANDLE_FETCH_STATS, fetchStatsSaga),
  takeLatest(HANDLE_DELETE_TEMPLATE, deleteTemplateSaga),
  takeEvery(HANDLE_FETCH_ALL_CAMPAIGNS,fetchAllCampaignsSaga),
  takeEvery(HANDLE_CAMPAIGN_ACTIVE_TOGGLE,updateCampaignActiveStatusSaga),
  takeEvery(HANDLE_CAMPAIGN_DELETE,deleteCampaignSaga),
  takeEvery(HANDLE_GET_CAMPAIGN_INFO, getCampaignInfoSaga),
  takeEvery(HANDLE_CREATE_OR_UPDATE_CAMPAIGN,createOrUpdateCampaign),
  takeEvery(HANDLE_DELETE_CAMPAIGN_EMAIL,deleteCampaignEmailSaga),
  takeLatest(HANDLE_UPDATE_CAMPAIGN_EMAIL,updateCampaignEmailSaga),
];
