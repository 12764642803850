import React from 'react';
import { Switch } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Table } from 'antd';
import {
  fetchExistingQuery,
  fetchQueryBasedOnId,
  clearQueryForm,
  setAnalyticsQueryActive,
  fetchExistingAssociatedChart,
} from '../../actions/analyticsQuery';
import FullPageLoading from '../../component/FullPageLoading';
import s from './Analytics.module.scss';

class AnalyticsQueryTable extends React.Component {
  componentDidMount() {
    const {
      fetchExistingQuery,
      fetchExistingAssociatedChart,
      type,
    } = this.props;
    if (type === 'build-analytics-chart') {
      fetchExistingQuery();
    }
    if (type === 'associate-charts') {
      fetchExistingAssociatedChart();
    }
  }

  columns = () => {
    const { type } = this.props;
    if (type === 'build-analytics-chart') {
      return [
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Is Active',
          dataIndex: 'isActive',
          key: 'isActive',
          align: 'center',
          render: (value, record) => {
            return (
              <Switch
                onClick={this.onToggleQuery(record)}
                size="small"
                checked={value}
              />
            );
          },
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
        {
          title: 'Updated At',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
        },
      ];
    }
    if (type === 'associate-charts') {
      return [
        {
          title: 'Chart Identifier',
          dataIndex: 'chartIdentifier',
          key: 'chartIdentifier',
          width: '30%',
          ellipsis: true,
        },
        {
          title: 'Chart type',
          dataIndex: 'chartType',
          width: '25%',
          key: 'chartType',
        },
        {
          title: 'Chart tag',
          dataIndex: 'chart',
          key: 'chart',
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
        },
      ];
    }
  };

  onToggleQuery = obj => (c, e) => {
    e && e.stopPropagation();
    const { setAnalyticsQueryActive } = this.props;
    setAnalyticsQueryActive({
      id: obj.id,
      isActive: !obj.isActive,
    });
  };

  handleCreate = () => {
    const { history, clearQueryForm, type } = this.props;
    clearQueryForm();
    history.push(`/${type}/new`);
  };

  onRowClick = record => {
    const { history, fetchQueryBasedOnId, type } = this.props;
    fetchQueryBasedOnId(record.id);
    history.push(`/${type}/edit/${record.id}`);
  };

  render() {
    const { queryList, chartList, queryListLoading, type } = this.props;
    return (
      <div className={`ck-card ${s['anaytics-query-table']}`}>
        <Row type="flex" justify="space-between">
          <h3>
            {type === 'build-analytics-chart'
              ? 'Analytics Query List'
              : 'Associated Chart List'}
          </h3>
          <Button type="primary" onClick={this.handleCreate}>
            Create
          </Button>
        </Row>
        <div>
          {queryListLoading && <FullPageLoading fontSize={30} />}
          {!queryListLoading && (
            <Table
              dataSource={
                type === 'build-analytics-chart' ? queryList : chartList
              }
              columns={this.columns()}
              rowKey={record => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => this.onRowClick(record, rowIndex),
                };
              }}
              className={`${s['query-table']}`}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyticsQuery }) => {
  const { queryList, queryListLoading, chartList } = analyticsQuery;
  return {
    queryList,
    queryListLoading,
    chartList,
  };
};

const mapDispatchToProps = {
  fetchExistingQuery,
  fetchQueryBasedOnId,
  clearQueryForm,
  setAnalyticsQueryActive,
  fetchExistingAssociatedChart,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AnalyticsQueryTable)
);
