import { SET_SEEDS } from './actions';

const initialState = {
    seeds: [],
    seedsLoading: false,
    defaultSeed: null,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_SEEDS: 
      return { ...state, ...data }
    default: return state
  }
};
