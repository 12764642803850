import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageHeader, Card, Row, Col } from 'antd';
import { breadCrumbItemRender } from '../../../../utils/helpers';
import ManageTable from './components/Table';
import UpsellForm from './components/UpsellForm';
import UsertypeLibraryForm from './components/UsertypeLibraryForm';
import { warningMessages } from './constants';
import DeleteModal from './modals/DeleteModal';

import {
  fetchUpsellById,
  fetchUpsells,
  handleActionClearData,
  handleActionClearForm,
  handleActionSetManageType,
  handleActionShowForm,
  fetchUsertypeLibraries,
  fetchUsertypeLibraryById,
} from '../../actions/manageActions';

import './styles/index.scss';

const breadcrumbConf = {
  upsells: {
    routes: [
      {
        path: '/usertype-settings',
        breadcrumbName: 'Usertype Settings',
      },
      {
        path: '/usertype-settings/manage-upsells',
        breadcrumbName: 'Upsells',
      },
    ],
    itemRender: breadCrumbItemRender,
  },
  'usertype-library': {
    routes: [
      {
        path: '/usertype-settings',
        breadcrumbName: 'Usertype Settings',
      },
      {
        path: '/usertype-settings/manage-usertype-library',
        breadcrumbName: 'UserType Libraries',
      },
    ],
    itemRender: breadCrumbItemRender,
  },
};

class Manage extends PureComponent {
  componentDidMount() {
    this.updateManageType();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.url !== match.url) {
      this.updateManageType();
    }
  }

  componentWillUnmount() {
    const { handleActionClearData } = this.props;
    handleActionClearData();
  }

  updateManageType = () => {
    const {
      match: { path },
      handleActionSetManageType,
    } = this.props;
    let manageType;
    if (path.includes('/manage-upsells')) {
      manageType = 'upsells';
    } else if (path.includes('/manage-usertype-library')) {
      manageType = 'usertype-library';
    }
    handleActionSetManageType(manageType);
    this.checkRoute(manageType);
  };

  checkRoute = manageType => {
    const {
      fetchUpsellById,
      fetchUpsells,
      handleActionClearForm,
      handleActionShowForm,
      match,
      fetchUsertypeLibraries,
      fetchUsertypeLibraryById,
    } = this.props;
    const id = match && match.params && match.params.id;

    if (manageType === 'upsells') fetchUpsells();
    else if (manageType === 'usertype-library') fetchUsertypeLibraries();

    if (match.path.includes('/new') || id) {
      if (match.path.includes('new')) {
        handleActionClearForm();
      }
      if (id) {
        if (manageType === 'upsells') {
          fetchUpsellById(id);
        } else if (manageType === 'usertype-library') {
          fetchUsertypeLibraryById(id);
        }
      }
      handleActionShowForm(true);
    }
    if (!match.path.includes('/new') && !id) {
      handleActionShowForm(false);
    }
  };

  render() {
    const { manageType, showForm } = this.props;
    let title = 'Manage ';
    let FormChild = UpsellForm; // default Upsell form
    if (manageType === 'upsells') {
      title += 'Upsell Actions';
      FormChild = UpsellForm;
    } else if (manageType === 'usertype-library') {
      title += 'User Type Library';
      FormChild = UsertypeLibraryForm;
    }

    return (
      <div className="usertype-manage">
        <PageHeader
          title={<p className="header__title">{title}</p>}
          breadcrumb={breadcrumbConf[manageType]}
          style={{ backgroundColor: '#fff' }}
        />
        <div className="main-container">
          <Row gutter={16}>
            <Col span={12}>
              <Row>
                <Card className="warning__card">
                  <div className="warning__description">
                    <p className="warning__text">
                      <span className="warning__text-bold">
                        {warningMessages[manageType]?.type}
                      </span>
                      {warningMessages[manageType]?.text}
                    </p>
                  </div>
                </Card>
              </Row>
              <Row>
                <ManageTable />
              </Row>
            </Col>
            <Col span={12}>{showForm && <FormChild />}</Col>
          </Row>
          <DeleteModal />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ usertypeManage: { showForm, manageType } }) => ({
  showForm,
  manageType,
});

const mapDispatchToProps = {
  fetchUpsellById,
  fetchUpsells,
  handleActionClearData,
  handleActionClearForm,
  handleActionSetManageType,
  handleActionShowForm,
  fetchUsertypeLibraries,
  fetchUsertypeLibraryById,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Manage)
);
