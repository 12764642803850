import React, { PureComponent } from 'react';
import {
  PageHeader,
  Card,
  Input,
  Table,
  Switch,
  Button,
  Form,
  Spin,
} from 'antd';
import { cloneDeep, isArray, debounce, isEqual } from 'lodash';

import Content from '../../component/Content';

import s from './UpsellPages.module.scss';

import notify, { notifyError } from '../../utils/notify';
import { getUpsellPages, updateUpsellPages } from '../../api/upsellPages';

const isURL = url => {
  const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);

  return url.match(regex);
};

class UpsellPages extends PureComponent {
  state = {
    initialData: [],
    data: [],
    hasDataChanged: false,
    isLoading: false,
    isSaving: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    const { data, initialData } = this.state;

    this.setState({ hasDataChanged: !isEqual(data, initialData) });
  }

  handleChange = (id, value, prop) => {
    const { data } = this.state;

    if (isArray(data) && data.length > 0) {
      const dataIndex = data.findIndex(d => d.id === id);
      if (dataIndex !== -1) {
        const newData = cloneDeep(data);

        if (prop === 'link') {
          if (!!(value && !isURL(value))) {
            newData[dataIndex].error = 'Please enter a valid link.';
          }
          newData[dataIndex][prop] = value === '' || !value ? null : value;
        } else {
          newData[dataIndex][prop] = value;
        }

        if (
          !newData[dataIndex].error &&
          newData[dataIndex].active &&
          (!newData[dataIndex].link || newData[dataIndex].link === '')
        ) {
          newData[dataIndex].error = 'Link is required.';
        } else {
          newData[dataIndex].error = null;
        }

        this.setState({ data: newData, hasDataChanged: true });
      }
    }
  };

  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const { data } = await getUpsellPages();

      const formattedData = data.map(item => {
        let error = null;

        if (!!(item.value && !isURL(item.value))) {
          error = 'Please enter a valid link.';
        } else if (item.active && (!item.link || item.link === '')) {
          error = 'Link is required.';
        }

        return {
          ...item,
          error,
        };
      });

      this.setState({ data: formattedData, initialData: formattedData });
    } catch (e) {
      notifyError(e);
    }

    this.setState({ isLoading: false });
  };

  updateData = async () => {
    const { data = [] } = this.state;

    try {
      let hasError = false;
      for (let i = 0; i < data.length; i++) {
        if (data[i].error) {
          hasError = true;
          break;
        }
      }

      if (hasError) {
        notify('Please resolve all errors.', 'error');
        return;
      }

      this.setState({ isSaving: true });

      await updateUpsellPages({ data });

      notify('Changes saved successfully!', 'success');
      this.setState({ hasDataChanged: false, initialData: data });
    } catch (e) {
      notifyError(e);
    }

    this.setState({ isSaving: false });
  };

  render() {
    const { data = [], isLoading, isSaving, hasDataChanged } = this.state;

    const columns = [
      {
        title: 'Feature Name',
        dataIndex: 'module',
        key: 'module',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.module.localeCompare(b.module),
      },
      {
        title: 'Link Title',
        dataIndex: 'title',
        key: 'title',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: 'Landing Page Link',
        dataIndex: 'link',
        key: 'link',
        render: (value, record) => (
          <Form.Item
            validateStatus={record.error ? 'error' : 'success'}
            help={record.error}
          >
            <Input
              disabled={isSaving || isLoading}
              value={value}
              onChange={e =>
                this.handleChange(record.id, e.target.value, 'link')
              }
            />
          </Form.Item>
        ),
      },
      {
        title: 'Active?',
        dataIndex: 'active',
        key: 'active',
        render: (value, record) => (
          <Switch
            disabled={isSaving || isLoading}
            checked={!!value}
            onChange={checked =>
              this.handleChange(record.id, checked, 'active')
            }
          />
        ),
      },
    ];

    return (
      <div className={s.upsell}>
        <PageHeader title="Upsell Landing Pages" />
        <Content>
          <Card bordered={false} title="Landing Pages">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={data}
              pagination={false}
              loading={isLoading}
            />
          </Card>
          <footer className={s.footer}>
            <Button
              type="primary"
              disabled={isLoading || !hasDataChanged}
              loading={isSaving}
              onClick={this.updateData}
            >
              Save
            </Button>
          </footer>
        </Content>
      </div>
    );
  }
}

export default UpsellPages;
