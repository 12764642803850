import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'antd';
import {
  setConfigurationModal,
  setSelectedConfigurationSetting,
  saveConfigurationSetting,
  clearConfigurationData,
} from './actions';
import ConfigurationBody from './modal/ConfigurationBody';
import ProgressBar from '../../component/ProgressBar';
import EmptyTier from './modal/EmptyTier';
import { popupAlert } from '../../actions/alert';

class ConfigurationModal extends React.Component {
  handleCancel = () => {
    const { setConfigurationModal } = this.props;
    setConfigurationModal(false);
  };

  goToManageTiers = () => {
    const { history, setConfigurationModal } = this.props;
    setConfigurationModal(false);
    history.push('/groups-programs-tier/manage-tier/group');
  };

  clearSelectedValue = () => {
    const { clearConfigurationData } = this.props;
    clearConfigurationData();
  };

  checkError = (value, limit) => {
    if (value < -1 || value === 0) {
      return true;
    }
    if (value !== -1 && value < limit) {
      return true;
    }
    return false;
  };

  handleOk = () => {
    const { saveConfigurationSetting, selectedConfigurationSetting, popupAlert } = this.props;
    let isError = selectedConfigurationSetting.map(item => {
      const errorValue = this.checkError(item.maxAllowed, item.achievedLimit);
      const schoolErrorValue = this.checkError(item.maxAllowedPerSchool, item.schoolAchievedLimit);
      return errorValue || schoolErrorValue || false;
    })
    const errorInConfig = isError.some(x => x);
    if (!errorInConfig) {
      saveConfigurationSetting();
      return
    }
    popupAlert({ type: 'error', message: 'Resolve error' })
  };

  renderFooter = () => {
    const {
      selectedConfigurationSetting,
      templateLoading,
      configurationLoading,
      isEnabled,
    } = this.props;
    return (
      <div>
        <Button onClick={this.handleCancel}>Cancel</Button>
        {isEnabled && selectedConfigurationSetting && (
          <Button
            onClick={this.handleOk}
            className={'update-configuration__save-btn'}
            type="primary"
            disabled={templateLoading || configurationLoading}
          >
            Save
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      configurationModalVisible,
      selectedConfigurationSetting,
      templateLoading,
      isEnabled,
    } = this.props;
    return (
      <Modal
        title="Update Configuration"
        visible={configurationModalVisible}
        footer={this.renderFooter()}
        onCancel={this.handleCancel}
        afterClose={this.clearSelectedValue}
        className="update-configuration"
        destroyOnClose={true}
      >
        {!isEnabled && (
          <div className="update-configuration__message">
            Turn on "Groups/Program Provisioning" in Hub Module settings"  to be able to provision the groups and programs for this hub.
          </div>
        )}
        {isEnabled && !selectedConfigurationSetting && (
          <EmptyTier goToManageTiers={this.goToManageTiers} />
        )}
        {templateLoading && (
          <div style={{ padding: '24px' }}>
            <ProgressBar />
          </div>
        )}
        {!templateLoading &&
          isEnabled &&
          selectedConfigurationSetting &&
          selectedConfigurationSetting.length > 0 && <ConfigurationBody />}
      </Modal>
    );
  }
}

const mapStateToProps = ({ groupsProgramsTier }) => {
  const {
    configurationModalVisible,
    configurationTab,
    selectedConfigurationSetting,
    templateLoading,
    configurationLoading,
    isEnabled,
  } = groupsProgramsTier;
  return {
    configurationModalVisible,
    configurationTab,
    selectedConfigurationSetting,
    templateLoading,
    configurationLoading,
    isEnabled,
  };
};

const mapDispatchToProps = {
  setConfigurationModal,
  setSelectedConfigurationSetting,
  saveConfigurationSetting,
  clearConfigurationData,
  popupAlert,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConfigurationModal)
);
