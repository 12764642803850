import React, { PureComponent } from 'react';
import { Table, Input, InputNumber, Switch, Form } from 'antd';

const FormItem = Form.Item;
const EditableContext = React.createContext();

class EditableCell extends PureComponent {
  getInput = data => {
    if (this.props.inputtype === 'number') {
      return <InputNumber />;
    }
    if (this.props.inputtype === 'boolean') {
      return <Switch defaultChecked={data} />;
    }
    return <Input />;
  };

  render() {
    const { editing, dataIndex, record, rules, ...restProps } = this.props;
    return (
      <EditableContext.Consumer>
        {form => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules,
                    initialValue: record[dataIndex],
                  })(this.getInput(record[dataIndex]))}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class ActionsColumn extends PureComponent {
  render() {
    const { save, cancel, editingKey, editable, record, edit } = this.props;
    return (
      <div>
        {editable ? (
          <span>
            <EditableContext.Consumer>
              {form => (
                <a
                  href="javascript:;"
                  onClick={() => save(form, record.key)}
                  style={{ marginRight: 10 }}
                >
                  Save
                </a>
              )}
            </EditableContext.Consumer>
            <a onClick={() => cancel(record.key)}>Cancel</a>
          </span>
        ) : (
          <a disabled={editingKey !== null} onClick={() => edit(record.key)}>
            Edit
          </a>
        )}
      </div>
    );
  }
}

class EditableTable extends PureComponent {
  constructor(props) {
    super(props);
    const { dataSource: data } = this.props;
    this.state = { data };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.dataSource !== this.props.dataSource) {
      this.setState({ data: this.props.dataSource });
      this.props.onEditingKeyChange(null);
    }
  }

  isEditing = record => record.key === this.props.editingKey;

  cancel = () => {
    this.props.onEditingKeyChange(null);
  };

  save = (form, key) => {
    form.validateFields((error, row) => {
      if (error) return;
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      const item = newData[index];
      Object.assign(newData, { [index]: { ...item, ...row } });
      this.setState({ data: newData });
      this.props.onEditingKeyChange(null);
      this.props.updateData(item, newData[index]);
    });
  };

  edit = key => {
    this.props.onEditingKeyChange(key);
  };

  handleTableChange = (...args) => {
    this.cancel();
    this.props.onChange(...args);
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    let { columns, pagination, loading } = this.props;

    // add actions column
    columns = columns
      .concat([
        {
          title: 'Actions',
          dataIndex: 'actions',
          render: (text, record) => {
            const editable = this.isEditing(record);
            return (
              <ActionsColumn
                save={this.save}
                cancel={this.cancel}
                editingKey={this.props.editingKey}
                editable={editable}
                edit={this.edit}
                record={record}
              />
            );
          },
        },
      ])
      .map(col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => {
            let inputtype = 'text';
            if (col.dataIndex === 'priority') inputtype = 'number';
            if (col.dataIndex === 'isVerified') inputtype = 'boolean';
            if (col.dataIndex === 'isHighSchool') inputtype = 'boolean';
            return {
              record,
              inputtype,
              dataIndex: col.dataIndex,
              title: col.title,
              editing: this.isEditing(record),
              rules: col.rules,
            };
          },
        };
      });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          columns={columns}
          rowClassName="editable-row"
          loading={loading}
          onChange={this.handleTableChange}
          pagination={pagination}
          size="small"
        />
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;
