import { SET_EXPERIMENTS, EXPERIMENTS_LOADING, EXPERIMENT_UPDATING } from '../actions';

const initialState = {
	experiments: [],
  experimentsLoading: false,
  experimentUpdating: false,
};

const userDetail = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_EXPERIMENTS:
      return {
        ...state,
        experiments: data,
      }
    case EXPERIMENTS_LOADING:
      return {
        ...state,
				experimentsLoading: data,
      }
    case EXPERIMENT_UPDATING:
      return {
        ...state,
        experimentUpdating: data
      }
    default: return state
  }
};

export default userDetail;