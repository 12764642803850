import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import {
  FETCH_ALL_CLUSTERS,
  SET_CLUSTER_MGMT,
  SAVE_CLUSTER_CONFIG,
  FETCH_ALL_CLUSTER_CONFIG,
  FETCH_LAUNCHGROUPS,
  CREATE_CLUSTER,
  SAVE_CLUSTER,
  EDIT_CLUSTER,
  FILTER_CLUSTERS,
  DELETE_CLUSTER_CONFIG,
  SET_LAUNCHGROUPS,
} from './actions';
import { message } from 'antd';
import * as api from '../../api';
import history from '../../history';

export default [
  takeLatest(FETCH_ALL_CLUSTERS, fetchAllClusters),
  takeLatest(SAVE_CLUSTER_CONFIG, saveClusterconfig),
  takeLatest(FETCH_ALL_CLUSTER_CONFIG, fetchAllClusterConfig),
  takeLatest(DELETE_CLUSTER_CONFIG, deleteClusterConfig),
  takeLatest(CREATE_CLUSTER, createCluster),
  takeLatest(SAVE_CLUSTER, saveCluster),
  takeLatest(EDIT_CLUSTER, editCluster),
  takeLatest(FILTER_CLUSTERS, getFilteredClusters),
];

const selects = {
  clusters: state => state.cluster.clusters,
};

function* fetchAllClusters() {
  yield put({
    type: SET_CLUSTER_MGMT,
    data: { clustersLoading: true },
  });
  const [{ data: clusters }, { data: modulesList }] = yield all([
    call(api.cluster.getAll, {
      fields: [
        'id',
        'name',
        'domain',
        'subdomain',
        'defaultSetting',
        'standardPlatformType',
      ],
    }),
    call(api.getModulesList, { moduleType: 'cluster' }),
  ]);
  clusters.forEach(c => (c.key = c.id));
  yield put({
    type: SET_CLUSTER_MGMT,
    data: {
      allClusters: clusters,
      clusters,
      clustersLoading: false,
      filtersEnabled: false,
      modulesList,
    },
  });
}

function* saveClusterconfig({ data }) {
  try {
    yield call(api.cluster.saveConfig, data);
    yield put({ type: SET_CLUSTER_MGMT, data: { clusterConfigs: null } });
    history.push('/clusters/configs');
  } catch (e) {
    message.error('Failed to save Cluster config');
    console.log(e);
  }
}

function* fetchAllClusterConfig({ data }) {
  yield put({
    type: SET_CLUSTER_MGMT,
    data: { clusterConfigsLoading: true },
  });
  let clusterConfigs = yield call(api.cluster.getClusterConfigs);
  clusterConfigs = clusterConfigs.data;
  clusterConfigs.forEach(c => (c.key = c.id));
  yield put({
    type: SET_CLUSTER_MGMT,
    data: {
      clusterConfigs,
      clusterConfigsLoading: false,
    },
  });
}

function* deleteClusterConfig({ clusterConfigId }) {
  yield put({
    type: SET_CLUSTER_MGMT,
    data: { clusterConfigsLoading: true },
  });
  let clusterConfigs = yield call(
    api.cluster.deleteClusterConfig,
    clusterConfigId
  );
  clusterConfigs = clusterConfigs.data;
  clusterConfigs.forEach(c => (c.key = c.id));
  yield put({
    type: SET_CLUSTER_MGMT,
    data: {
      clusterConfigs,
      clusterConfigsLoading: false,
    },
  });
}

function* createCluster({ data }) {
  const {
    name,
    subdomain,
    domain,
    clusterConfigType,
    clusterConfigId,
    clusterId,
    launchGroup,
    checked,
  } = data;

  try {
    if (['none', 'copy'].includes(clusterConfigType)) {
      yield call(api.cluster.createCopy, {
        copyFrom: clusterId,
        copySettings: clusterConfigType === 'copy',
        name,
        subdomain,
        domain,
        launchGroup,
      });
    }
    if (clusterConfigType === 'clusterConfig') {
      yield call(api.cluster.createFromConfig, {
        name,
        subdomain,
        domain,
        clusterConfigId,
        checked,
        launchGroup,
      });
    }
    message.success('Created new Cluster');
  } catch (e) {
    console.log({e})
    const errorMessage = get(e, 'response.data.message', 'failed to create cluster');
    message.error(errorMessage);
  }
}

function* saveCluster({ cluster }) {
  try {
    yield call(api.cluster.save, cluster);
    let clusters = yield select(selects.clusters);
    const ind = clusters.findIndex(c => c.id === cluster.id);
    clusters = Object.assign([], clusters, { [ind]: cluster });
    yield put({
      type: SET_CLUSTER_MGMT,
      data: { clusters, editCluster: null },
    });
    message.success('Cluster Saved');
  } catch (e) {
    message.error('Failed to save Cluster');
    console.log(e);
  }
}

function* editCluster({ clusterId }) {
  try {
    const clusters = yield select(selects.clusters);
    let editCluster = clusters.find(c => c.id === clusterId);
    editCluster = JSON.parse(JSON.stringify(editCluster));

    yield put({
      type: SET_CLUSTER_MGMT,
      data: {
        editCluster,
      },
    });
    let settings = yield call(api.cluster.getOne, clusterId);
    const standardPlatformType = get(
      settings,
      'data.standardPlatformType',
      'enterprise'
    );
    settings = settings.data.settings;
    editCluster = {
      ...editCluster,
      settings,
      standardPlatformType,
    };

    yield put({
      type: SET_CLUSTER_MGMT,
      data: {
        editCluster,
      },
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: SET_CLUSTER_MGMT,
      data: {
        editCluster: null,
      },
    });
    message.error('Failed to load cluster settings');
  }
}

function* getFilteredClusters({ data }) {
  try {
    yield put({
      type: SET_CLUSTER_MGMT,
      data: { clustersLoading: true },
    });
    data.fields = ['id', 'name', 'domain', 'subdomain', 'defaultSetting'];
    let filteredClusters = yield call(api.cluster.filterClusters, data);
    filteredClusters = filteredClusters.data;
    yield put({
      type: SET_CLUSTER_MGMT,
      data: {
        clusters: filteredClusters,
        clustersLoading: false,
        filtersEnabled: true,
      },
    });
  } catch (e) {
    console.log(e);
    message.error('Failed to filter clusters');
  }
}
