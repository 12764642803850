import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import AnalyticsReportList from './AnalyticsReportList';
import AnalyticsReportEdit from './AnalyticsReportEdit';

class AnalyticsReport extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`/analytics-reports`}
          component={AnalyticsReportList}
        />
        <Route
          exact
          path={`/analytics-reports/new`}
          component={AnalyticsReportEdit}
        />
        <Route
          exact
          path={`/analytics-reports/edit/:id`}
          component={AnalyticsReportEdit}
        />
      </Switch>
    );
  }
}

const mapDispatchToProps = {
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(AnalyticsReport)
);
