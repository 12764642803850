import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserTypeSettings from '.';
import Manage from './containers/Manage';
import ManageTemplates from './containers/ManageTemplates';
import ManageTemplate from './containers/ManageTemplates/ManageTemplate';

export default () => (
  <Switch>
    <Route path="/usertype-settings/manage-upsells/new" component={Manage} />
    <Route
      path="/usertype-settings/manage-upsells/edit/:id"
      component={Manage}
    />
    <Route path="/usertype-settings/manage-upsells" component={Manage} />
    <Route
      path="/usertype-settings/manage-templates/new"
      component={ManageTemplate}
    />
    <Route
      path="/usertype-settings/manage-templates/edit/:id"
      component={ManageTemplate}
    />
    <Route
      path="/usertype-settings/manage-templates"
      component={ManageTemplates}
    />
    <Route
      path="/usertype-settings/manage-usertype-library/new"
      component={Manage}
    />
    <Route
      path="/usertype-settings/manage-usertype-library/edit/:id"
      component={Manage}
    />
    <Route
      path="/usertype-settings/manage-usertype-library"
      component={Manage}
    />
    <Route path="/usertype-settings" component={UserTypeSettings} />
  </Switch>
);
