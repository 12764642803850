import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { getAskedQuesCountApi } from '../../../api/askAnythingMetricsApi';
import { notifyError } from '../../../utils/notify';

const { askedQuesCount } = queryKeys;

const getAskedQuesCount = async filters => {
  const { data } = await getAskedQuesCountApi(filters);
  return data;
};

export const useGetAskedQuesCountQuery = filters =>
  useQuery([askedQuesCount, filters], () => getAskedQuesCount(filters), {
    onError: err => {
      notifyError(err, false, 'Unable to fetch asked questions count');
    },
  });
