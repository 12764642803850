import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import getColumns from './Columns';

import {
  fetchUpsells,
  handleActionClearForm,
  handleActionSetManageDataPage,
  fetchUsertypeLibraries,
  handleActionDeleteModalVisible,
  setActiveIdForModal,
} from '../../../actions/manageActions';

class ManageTable extends PureComponent {
  handleCreate = () => {
    const { history, manageType } = this.props;

    history.push(`/usertype-settings/manage-${manageType}/new`);
  };

  handleEditClick = record => {
    const { history, manageType } = this.props;

    history.push(`/usertype-settings/manage-${manageType}/edit/${record.id}`);
  };

  handlePageChange = page => {
    const {
      manageType,
      handleActionSetManageDataPage,
      fetchUpsells,
      fetchUsertypeLibraries,
    } = this.props;

    handleActionSetManageDataPage(page);
    if (manageType === 'upsells') {
      fetchUpsells();
    } else if (manageType === 'usertype-library') {
      fetchUsertypeLibraries();
    }
  };

  handleDeleteClick = record => {
    const { setActiveIdForModal, handleActionDeleteModalVisible } = this.props;
    setActiveIdForModal(record.id);
    handleActionDeleteModalVisible(true);
  };

  render() {
    const {
      manageData,
      manageDataCount,
      manageDataPage,
      manageDataPageSize,
      manageLoading,
      manageType,
    } = this.props;

    let title = '';
    if (manageType === 'upsells') title = 'Upsell Actions';
    else if (manageType === 'usertype-library') title = 'UserType Library';

    return (
      <div className="manage-table-container">
        <div className="row-justify">
          <p className="manage-table-container__heading">{title}</p>
          <Button type="primary" icon="plus-circle" onClick={this.handleCreate}>
            Create
          </Button>
        </div>
        <Table
          dataSource={manageData}
          columns={getColumns(
            manageType,
            this.handleEditClick,
            this.handleDeleteClick
          )}
          loading={manageLoading}
          rowKey={record => record.id}
          className="banner-table"
          pagination={{
            simple: false,
            total: parseInt(manageDataCount, 10),
            current: manageDataPage,
            onChange: this.handlePageChange,
            pageSize: manageDataPageSize,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  usertypeManage: {
    manageData,
    manageDataCount,
    manageDataPage,
    manageDataPageSize,
    manageLoading,
    manageType,
  },
}) => ({
  manageData,
  manageDataCount,
  manageDataPage,
  manageDataPageSize,
  manageLoading,
  manageType,
});

const mapDispatchToProps = {
  fetchUpsells,
  handleActionClearForm,
  handleActionSetManageDataPage,
  fetchUsertypeLibraries,
  setActiveIdForModal,
  handleActionDeleteModalVisible,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManageTable)
);
