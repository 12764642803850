import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Card, Layout, Button } from 'antd';
import Content from '../../component/Content';
import MessageFeedback from './Message';
import MentorshipFeedback from './Mentorship';
import styles from './styles.module.scss';

import {
  fetchMentorshipFeedbacks,
  fetchMessageFeedbacks,
  messageFeedbacksSetPage,
  messageFeedbacksSetPageSize,
  mentorshipFeedbacksSetPage,
  mentorshipFeedbacksSetPageSize,
  openHubFromFeedbacks,
  resetFeedbacksState,
} from './actions';
import { Icon } from 'antd';
import { Divider } from 'antd';
import moment from 'moment';
import { sanitize } from 'dompurify';

const { Sider } = Layout;
class Feedback extends PureComponent {
  state = {
    key: 'message',
    aiMeetingSummary: null
  };

  componentWillUnmount() {
    const { resetFeedbacksState } = this.props;
    resetFeedbacksState();
  }

  tabList = [
    {
      key: 'message',
      tab: 'Message',
    },
    {
      key: 'mentorship',
      tab: 'Mentorship',
    },
  ];

  setSelectedAiMeetingSummary = aiMeetingSummary => {
    this.setState({ aiMeetingSummary })
  }

  getContentMap = () => {
    const {
      mentorship,
      message,
      fetchMentorshipFeedbacks,
      fetchMessageFeedbacks,
      messageFeedbacksSetPage,
      messageFeedbacksSetPageSize,
      mentorshipFeedbacksSetPage,
      mentorshipFeedbacksSetPageSize,
      openHubFromFeedbacks,
    } = this.props;
    return {
      message: (
        <MessageFeedback
          setSelectedAiMeetingSummary={this.setSelectedAiMeetingSummary}
          fetchData={fetchMessageFeedbacks}
          data={message}
          setPage={messageFeedbacksSetPage}
          setPageSize={messageFeedbacksSetPageSize}
          openHub={openHubFromFeedbacks}
        />
      ),
      mentorship: (
        <MentorshipFeedback
          setSelectedAiMeetingSummary={this.setSelectedAiMeetingSummary}
          fetchData={fetchMentorshipFeedbacks}
          data={mentorship}
          setPage={mentorshipFeedbacksSetPage}
          setPageSize={mentorshipFeedbacksSetPageSize}
          openHub={openHubFromFeedbacks}
        />
      ),
    };
  };

  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  };

  render() {
    const contentMap = this.getContentMap();
    const { title, updatedAt, summary } = this.state.aiMeetingSummary || {};

    return (
      <Fragment>
        <PageHeader title="Message / Mentorship Feedback" />
        <Content>
          <Card
            style={{ width: '100%' }}
            tabList={this.tabList}
            activeTabKey={this.state.key}
            onTabChange={key => {
              this.onTabChange(key, 'key');
            }}
          >
            {contentMap[this.state.key]}
          </Card>
        </Content>
        {this.state.aiMeetingSummary && (
          <div
            onClick={() => this.setSelectedAiMeetingSummary(null)}
            className={styles.overlay}
          />
        )}
        <Sider
          collapsedWidth={0}
          trigger={null}
          collapsible
          collapsed={!this.state.aiMeetingSummary}
          width={430}
          theme="light"
          className={styles.sider}
          id="ai-meeting-summary-sider"
        >
          <div className={styles.container}>
            <div className={styles.header}>
              <h3>Meeting Summary</h3>
              <div>
                <Button
                  className={styles.button}
                  onClick={() => this.setSelectedAiMeetingSummary(null)}
                >
                  Close <Icon type="close" />
                </Button>
              </div>
            </div>
            <Divider
              style={{
                marginTop: '16px',
              }}
            />
            <div className={styles.body}>
              <div>
                <div className={styles.modalContentTitle}>
                  <h3>{title}</h3>
                  <p className={styles.aiTag}>
                    <img src="https://cdn.peoplegrove.com/admin/1722506279612sparkles.svg" alt="ai icon" />
                    <span>Powered by AI</span>
                  </p>
                </div>
                <div>
                  <p className={styles.timestamp}>
                    {moment(updatedAt).format('MMMM DD, YYYY')}
                    <span>|</span>
                    {moment(updatedAt).format('hh:mma')}
                  </p>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: sanitize(summary) }} />
            </div>
          </div>
        </Sider>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ feedback: { mentorship, message } }) => ({
  mentorship,
  message,
});

const mapDispatchToProps = {
  fetchMentorshipFeedbacks,
  fetchMessageFeedbacks,
  messageFeedbacksSetPage,
  messageFeedbacksSetPageSize,
  mentorshipFeedbacksSetPage,
  mentorshipFeedbacksSetPageSize,
  openHubFromFeedbacks,
  resetFeedbacksState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);