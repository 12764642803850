export default [
  {
    key: 'manageAdmins',
    title: 'Manage Admins',
    children: [{ key: 'adminAccessGroups', title: 'Admin Access Groups' }]
  },
  {
    key: 'initialSetup',
    title: 'Initial Setup',
    children: [
      { key: 'brandingSettings', title: 'Branding Settings' },
      { key: 'landingPage', title: 'Landing Page' },
      { key: 'readOnlyFields', title: 'Read-Only Fields' },
    ],
  },
  {
    key: 'advancedSettings',
    title: 'Advanced Settings',
    children: [
      { key: 'clusterModuleSettings', title: 'Cluster Module Settings', required: true },
      { key: 'clusterText', title: 'Cluster Text' },
    ],
  }
];
