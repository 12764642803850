import {takeLatest, put, call, select} from 'redux-saga/effects';
import { FETCH_EXPERIMENTS, DELETE_EXPERIMENT, UPDATE_EXPERIMENT, ADD_EXPERIMENT_HUB, DELETE_EXPERIMENT_HUB, SET_EXPERIMENT_HUB_MAPPING, setExperimentsLoading, setExperiments, setExperimentUpdating, retrieveExperiments, retrieveAdminTags, retrieveClusterHubs } from '../actions/index';
import { POPUP_ALERT } from '../actions/alert';
import { fetchExperimentsAPI, updateExperimentAPI, deleteExperimentAPI, addExperimentHubAPI, deleteExperimentHubAPI, setExperimentHubMappingAPI } from '../api';

export default [
	takeLatest(FETCH_EXPERIMENTS, fetchExperimentsSaga),
	takeLatest(UPDATE_EXPERIMENT, updateExperimentsSaga),
	takeLatest(DELETE_EXPERIMENT, deleteExperimentSaga),
	takeLatest(ADD_EXPERIMENT_HUB, addExperimentHubSaga),
	takeLatest(DELETE_EXPERIMENT_HUB, deleteExperimentHubSaga),
	takeLatest(SET_EXPERIMENT_HUB_MAPPING, setExperimentHubMappingSaga),
]

function* fetchExperimentsSaga() {
	try {
		yield put(setExperimentsLoading(true));
		let { data: response } = yield call(fetchExperimentsAPI);
		yield put(setExperiments(response.data));
		yield put(setExperimentsLoading(false));
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
		yield put(setExperimentsLoading(false));
  }
}

function* updateExperimentsSaga({ data }) {
	try {
		yield put(setExperimentUpdating(true));
		yield call(updateExperimentAPI, data);
		yield put(setExperimentUpdating(false));
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Updated Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
		yield put(setExperimentUpdating(false));
  }
}


function* deleteExperimentSaga({ data }) {
	try {
		yield call(deleteExperimentAPI, data);
		const experiments = yield select(retrieveExperiments);
		yield put(setExperiments(experiments.filter(e => e.id !== data.id)));
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Deleted Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}

function* addExperimentHubSaga({ data }) {
	try {
		yield call(addExperimentHubAPI, data);
		yield fetchExperimentsSaga();
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Added Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}


function* deleteExperimentHubSaga({ data }) {
	try {
		yield call(deleteExperimentHubAPI, data);
		yield fetchExperimentsSaga();
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Deleted Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}


function* setExperimentHubMappingSaga(data) {
  try {
		yield call(setExperimentHubMappingAPI, data);
		yield fetchExperimentsSaga();
    yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Updated Hub Settings Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}