import React from 'react';
import { map } from 'lodash';

import { Row, Col, Input, Select, InputNumber } from 'antd';

const { Option } = Select;

const FilterFlags = [
  {
    value: 1,
    name: '1',
  },
  // {
  //   value: 0,
  //   name: '0',
  // },
];

const UserConnections = ({ currentApi, body = {}, setBody }) => {
  // console.log({ body });
  const allBodyTypes = [
    {
      id: 'hub_id',
      api: ['user-connections-search'],
      placeholder: 'Hub Id',
      Component: (
        <InputNumber
          placeholder="Hub Id"
          value={body.hub_id}
          onChange={val => setBody({ hub_id: val })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'job_titles',
      api: ['user-connections-search'],
      Component: (
        <Input
          placeholder="Job Title"
          value={body.job_titles}
          onChange={e => setBody({ job_titles: e.target.value })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'num_user_ids',
      api: ['user-connections-search'],
      Component: (
        <InputNumber
          placeholder="Limit"
          value={body.num_user_ids}
          onChange={val => setBody({ num_user_ids: val })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'filter_flag',
      api: ['user-connections-search'],
      type: 'number',
      Component: (
        <Select
          placeholder="Filter flag"
          value={body.filter_flag}
          onChange={val => setBody({ filter_flag: val })}
          allowClear
          style={{ width: '100%' }}
        >
          {map(FilterFlags, source => (
            <Option key={source.value} value={source.value}>
              {source.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      id: 'restricted_work_ids',
      api: ['user-connections-search'],
      Component: (
        <Input
          placeholder="Restricted Work IDs (Comma separated)"
          value={body.restricted_work_ids}
          onChange={e => setBody({ restricted_work_ids: e.target.value })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
  ];
  const apiBodyTypes = allBodyTypes.filter(({ api }) =>
    api.includes(currentApi)
  );
  if (!apiBodyTypes.length) {
    return null;
  }

  return (
    <>
      <Row type="flex" justify="space-around" gutter={8}>
        {apiBodyTypes.slice(0, 4).map(({ Component }) => (
          <Col span={6}>{Component}</Col>
        ))}
      </Row>
      {apiBodyTypes.length > 4 ? (
        <Row
          type="flex"
          justify="space-around"
          gutter={8}
          style={{ marginTop: '16px' }}
        >
          {apiBodyTypes.slice(4).map(({ Component }) => (
            <Col span={6}>{Component}</Col>
          ))}
        </Row>
      ) : null}
    </>
  );
};

export default UserConnections;
