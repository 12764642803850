export const dataTables = {
  QUESTIONS_ASKED: 'questions-asked',
  QUESTIONS_ROUTED: 'questions-routed',
  RESPONSES: 'responses',
};

const { QUESTIONS_ASKED, QUESTIONS_ROUTED, RESPONSES } = dataTables;

export const dataTableOptions = [
  {
    name: 'Questions Asked',
    value: QUESTIONS_ASKED,
  },
  {
    name: 'Question Routed',
    value: QUESTIONS_ROUTED,
  },
  {
    name: 'Responses Received',
    value: RESPONSES,
  },
];
