import moment from 'moment';
import { get, parseInt } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const makeUpdatedValuesFromFormValues = values =>
  Object.entries(values).reduce((c, [k, v]) => {
    if (v === undefined) {
      c[k] = null;
      return c;
    }

    c[k] = v;

    return c;
  }, {});

export const processTemplateResponse = response => {
  const customUserTemplates = get(response, 'data.customUserTemplates', []);
  const count = get(response, 'data.count', 0);
  const dateFormat = 'MMM D YYYY, h:mm a';

  const processedTemplates = customUserTemplates.map(row => ({
    id: row.id,
    savedAs: row.title,
    originalTemplate: row.origin_template_name || '',
    originalTemplateIdentifier: row.content_identifier,
    savedBy: `${row.first_name} ${row.last_name}`,
    clusterName: `${row.cluster_name} (${row.cluster_id})`,
    createdAt: row.created_at ? moment(row.created_at).format(dateFormat) : '',
    updatedAt: row.updated_at ? moment(row.updated_at).format(dateFormat) : '',
    deletedAt: row.deleted_at ? moment(row.deleted_at).format(dateFormat) : '',
    usedCount: row.used_count,
    content: row.content,
  }));

  return {
    customUserTemplates: processedTemplates,
    count: parseInt(count),
  };
};
