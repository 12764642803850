import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'antd';
import {
  deleteTier,
  deleteTemplate,
  setDeleteModalVisibility,
} from '../../actions/manage';
import { isNumber } from 'lodash';

class DeleteModal extends Component {
  handleDeleteOk = () => {
    const {
      manageType,
      match,
      setDeleteModalVisibility,
      deleteTier,
      deleteTemplate,
    } = this.props;
    const { id } = match.params;

    if (manageType === 'tier') deleteTier(id);
    else if (manageType === 'template') deleteTemplate(id);

    setDeleteModalVisibility(false);
  };

  handleDeleteCancel = () => {
    const { setDeleteModalVisibility } = this.props;
    setDeleteModalVisibility(false);
  };

  renderFooter = () => {
    const { canDeleteTier, manageType, associatedGroupCount } = this.props;

    if (
      (manageType === 'tier' && !canDeleteTier) ||
      (manageType === 'template' &&
        isNumber(associatedGroupCount) &&
        associatedGroupCount > 0)
    ) {
      return (
        <div>
          <Button onClick={this.handleDeleteCancel}>Go Back</Button>
        </div>
      );
    }

    return (
      <div>
        <Button onClick={this.handleDeleteCancel}>Cancel</Button>
        <Button className="delete-btn" onClick={this.handleDeleteOk}>
          Delete
        </Button>
      </div>
    );
  };

  renderBody = () => {
    const { manageType, canDeleteTier, associatedGroupCount } = this.props;

    if (manageType === 'tier' && !canDeleteTier) {
      return (
        <p style={{ marginBottom: '0px' }}>
          Oops! To delete this tier, you must remove the hub(s) associated with
          it.
        </p>
      );
    }

    if (
      manageType === 'template' &&
      isNumber(associatedGroupCount) &&
      associatedGroupCount > 0
    ) {
      return (
        <p style={{ marginBottom: '0px' }}>
          Oops! To delete this template, you must remove the active groups(s)
          associated with it.
        </p>
      );
    }

    return (
      <p>
        This is an irreversible action. Are you sure you want to delete this
        {' ' + manageType}?
      </p>
    );
  };

  render() {
    const { deleteModalVisible, manageType } = this.props;

    return (
      <Modal
        title={`Delete ${manageType === 'tier' ? 'Tier' : 'Template'}`}
        visible={deleteModalVisible}
        onCancel={this.handleDeleteCancel}
        footer={this.renderFooter()}
        destroyOnClose
        className="confirmation-modal"
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const {
    deleteModalVisible,
    manageType,
    canDeleteTier,
    associatedGroupCount,
  } = groupsManage;
  return {
    deleteModalVisible,
    manageType,
    canDeleteTier,
    associatedGroupCount,
  };
};

const mapDispatchToProps = {
  deleteTier,
  deleteTemplate,
  setDeleteModalVisibility,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeleteModal)
);
