import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { saveGlobalListGoalApi } from '../../../api';
import { notify } from '../../../utils/notify';
import { setFormData } from '../actions';
import AchievementKeys from '../queryKeys/GlobalLists';

const createGlobalListGoalApi = async body => {
  const { data } = await saveGlobalListGoalApi(body);
  return data;
};

const useCreateGlobalListMutation = ({ onSuccess }) => {
  const client = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(createGlobalListGoalApi, {
    onSuccess: data => {
      const listGoals = data.goals;
      client.invalidateQueries([AchievementKeys.globalLists]);
      if (listGoals) dispatch(setFormData(listGoals[0]));
      onSuccess(data);

      notify('Achievement Created successfully!', 'success');
    },
    onError: error => {
      notify(error.response.data.message, 'error');
    },
  });
};
export default useCreateGlobalListMutation;
