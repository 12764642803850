import {takeLatest, put, call, select} from 'redux-saga/effects';
import { FETCH_ADMIN_TAGS, DELETE_ADMIN_TAG, UPDATE_ADMIN_TAG, ADD_ADMIN_TAG, ADD_ADMIN_TAG_REFERENCE, DELETE_ADMIN_TAG_REFERENCE, setAdminTagsLoading, setAdminTags, setAdminTagUpdating, retrieveAdminTags, retrieveCurrentUser, setClusterHubs } from '../actions/index';
import { POPUP_ALERT } from '../actions/alert';
import { fetchAdminTagsAPI, updateAdminTagAPI, deleteAdminTagAPI, addAdminTagAPI, fetchClusterHubsAPI, addAdminTagReferenceAPI, deleteAdminTagReferenceAPI } from '../api';

export default [
	takeLatest(FETCH_ADMIN_TAGS, fetchAdminTagsSaga),
	takeLatest(UPDATE_ADMIN_TAG, updateAdminTagsSaga),
	takeLatest(DELETE_ADMIN_TAG, deleteAdminTagSaga),
	takeLatest(ADD_ADMIN_TAG, addAdminTagSaga),
	takeLatest(ADD_ADMIN_TAG_REFERENCE, addAdminTagReferenceSaga),
	takeLatest(DELETE_ADMIN_TAG_REFERENCE, deleteAdminTagReferenceSaga),
]

function* fetchAdminTagsSaga() {
	try {
		yield put(setAdminTagsLoading(true));
		let { data: response } = yield call(fetchAdminTagsAPI);
		yield put(setAdminTags(response.data));
		yield put(setAdminTagsLoading(false));
		let { data: clusterHubs } = yield call(fetchClusterHubsAPI);
		clusterHubs = clusterHubs.map(ch => ch.hub);
		yield put(setClusterHubs(clusterHubs));
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
		yield put(setAdminTagsLoading(false));
  }
}

function* addAdminTagSaga({ data }) {
	try {
		yield put(setAdminTagUpdating(true));
		const createdBy = yield select(retrieveCurrentUser);
		const newAdminTag = {...data, createdBy};
		yield call(addAdminTagAPI,newAdminTag);
		yield call(fetchAdminTagsSaga);
		yield put(setAdminTagUpdating(false));
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Created Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
		yield put(setAdminTagUpdating(false));
  }
}

function* updateAdminTagsSaga({ data }) {
	try {
		yield put(setAdminTagUpdating(true));
		yield call(updateAdminTagAPI, data);
		yield put(setAdminTagUpdating(false));
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Updated Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
		yield put(setAdminTagUpdating(false));
  }
}


function* deleteAdminTagSaga({ data }) {
	try {
		yield call(deleteAdminTagAPI, data);
		const adminTags = yield select(retrieveAdminTags);
		yield put(setAdminTags(adminTags.filter(e => e.id !== data.id)));
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Deleted Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}


// ------ Admin Tag References
function* addAdminTagReferenceSaga({ data }) {
	try {
		yield call(addAdminTagReferenceAPI, data);
		yield call(fetchAdminTagsSaga);
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Added Successfully" }});
  } catch (e) {
		console.log("Error::: ", e);
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}

function* deleteAdminTagReferenceSaga({ data }) {
	try {
		yield call(deleteAdminTagReferenceAPI, data);
		yield call(fetchAdminTagsSaga);
		yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Deleted Successfully" }});
  } catch (e) {
		yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}
