import { request } from '../utils/request';

// Manage Upsell APIs
export const createUpsellAPI = params =>
  request.post('/superadmin/usertype/create-upsell', params);
export const updateUpsellAPI = params =>
  request.put(`/superadmin/usertype/update-upsell/${params.id}`, params);
export const fetchUpsellsAPI = params =>
  request.get('/superadmin/usertype/fetch-upsells', { params });
export const fetchUpsellByIdAPI = id =>
  request.get(`/superadmin/usertype/fetch-upsell/${id}`);
export const deleteUpsellAPI = id =>
  request.delete(`/superadmin/usertype/delete-upsell/${id}`);

// Manage Usertype Library APIs
export const createUsertypeLibraryAPI = params =>
  request.post('/superadmin/usertype/create-usertype-library', params);
export const updateUsertypeLibraryAPI = params =>
  request.put(
    `/superadmin/usertype/update-usertype-library/${params.id}`,
    params
  );
export const fetchUsertypeLibrariesAPI = params =>
  request.get('/superadmin/usertype/fetch-usertype-libraries', { params });
export const fetchUsertypeLibraryByIdAPI = id =>
  request.get(`/superadmin/usertype/fetch-usertype-library/${id}`);
export const deleteUsertypeLibraryAPI = id =>
  request.delete(`/superadmin/usertype/delete-usertype-library/${id}`);
export const fetchUpsellsAndTemplatesAPI = () =>
  request.get('/superadmin/usertype/fetch-upsells-and-templates/');

// Manage Template
export const getUpsellOptionsAPI = () =>
  request.get('/superadmin/usertype/fetch-upsell-options');
export const createTemplateAPI = params =>
  request.post('/superadmin/usertype/create-template', params);
export const updateTemplateAPI = params =>
  request.put(`/superadmin/usertype/update-template/${params.id}`, params);
export const fetchTemplateByIdAPI = id =>
  request.get(`/superadmin/usertype/fetch-template/${id}`);
export const fetchTemplatesAPI = () =>
  request.get('/superadmin/usertype/fetch-templates');
export const fetchActiveHubsAPI = params =>
  request.get(`/superadmin/usertype/fetch-active-hubs/${params.id}`, {
    params,
  });
export const deleteTemplateAPI = id =>
  request.delete(`/superadmin/usertype/delete-template/${id}`);

// UsertypeSettings APIs
export const fetchHubsAPI = params =>
  request.post('/superadmin/usertype/fetch-usertype-settings', params);
export const fetchLibrariesAndTemplatesAndUpsellsAPI = () =>
  request.get('/superadmin/usertype/fetch-libraries-templates-upsells');
