import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { actionRestrictionMiddleware } from './actionRestrictionMiddleware';
import rootReducer from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(actionRestrictionMiddleware, sagaMiddleware))
);

const configureStore = () => ({
  ...store,
  runSaga: sagaMiddleware.run,
});

export default configureStore;
