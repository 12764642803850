export const moduleOptions = [
  { value: 'groups', name: 'Groups' },
  { value: 'pathways', name: 'Pathways' },
  { value: 'programs', name: 'Programs' },
  { value: 'careerPaths', name: 'Career Paths' },
  { value: 'videoAnswers', name: 'Video Answers' },
  { value: 'opportunities', name: 'Opportunities' },
  { value: 'socIntegration', name: 'Soc Integration' },
  { value: 'pathwayUIntegration', name: 'PathwayU Integration' },
  { value: 'alumStudentOutreach', name: 'Alum to student outreach' },
  { value: 'jobConnections', name: 'Job Connections' },
  { value: 'bridges', name: 'Projects' },
  { value: 'AutoGreeting', name: 'AutoGreeting' },
];

export const autoTrackEvents = [
  {
    name: 'Number of messages sent',
    type: 'MessagesSent',
    limit: 5,
  },
  {
    name: 'Number of jobs posted',
    type: 'TotalJobPosts',
    limit: 5,
  },
  {
    name: 'Number of groups joined',
    type: 'JoinedGroups',
    limit: 5,
  },
  {
    name: 'Number of programs joined',
    type: 'JoinedPrograms',
    limit: 5,
  },
  {
    name: 'Number of meetings accepted',
    type: 'MeetingAccepetedByUser',
    limit: 5,
  },
  {
    name: 'Number of discussion posts answered',
    type: 'PostAnswers',
    limit: 5,
  },
  {
    name: 'Number of Career Paths liked',
    type: 'LikedCareerPaths',
    limit: 1,
  },
  {
    name: 'Number of career stories on profile',
    type: 'CareerStories',
    limit: 3,
  },
  {
    name: 'Number of video answers',
    type: 'VideoAnswers',
    limit: 5,
  },
  {
    name: 'Profile video intro on profile page',
    type: 'VideoIntro',
    limit: 1,
  },
  {
    name: 'Number of successful meetings',
    type: 'SuccessfulMeetings',
    limit: 1,
  },
  {
    name: 'Profile Completed',
    type: 'ProfileScore',
    limit: 100,
  },
  {
    name: 'Number of Unique TwoWay Connections',
    type: 'UniqueConnections',
    limit: 1,
  },
];

export const userTypeOptions = [
  {
    value: 'Student',
    name: 'Student',
  },
  {
    value: 'Alumni',
    name: 'Alumni',
  },
  {
    value: 'Graduate Student',
    name: 'Graduate Student',
  },
];

export const compassActionItems = {
  select_interests: {
    heading: 'Complete Matching Quiz',
  },
  favorite_career_paths: {
    heading: 'Favorite Career Path',
  },
  build_your_network: {
    heading: 'Make connection(s)',
    showLimit: true,
  },
  get_notified: {
    heading: 'Get notified',
  },

  student_opt_in: {
    heading: 'Opt in for alum outreach',
  },

  resume_review: {
    heading: 'Request Resume',
  },

  job_connections: {
    heading: 'Make job connection(s)',
    showLimit: true,
  },

  schedule_mock_interview: {
    heading: 'Complete mock interview',
  },

  job_search_alerts: {
    heading: 'Set job alert',
  },

  offer_expertise: {
    heading: 'Share expertise',
  },

  share_your_experience: {
    heading: 'Share career paths that you are experienced in',
  },

  auto_greeting_welcome: {
    heading: 'Opt in for auto welcome',
  },
  complete_profile: {
    heading: 'Complete Profile',
  },
  sync_calendar: {
    heading: 'Sync Calendar',
  },
  career_stories: {
    heading: 'Add Career Stories',
    showLimit: true,
  },
  upload_documents: {
    heading: 'Upload Documents',
    showLimit: true,
  },
};
