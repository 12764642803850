import React from 'react';
import { connect } from 'react-redux';
import { find, debounce, isNumber, has } from 'lodash';
import {
  Button,
  Tabs,
  InputNumber,
  Switch,
  Divider,
  Icon,
  Popconfirm,
} from 'antd';
import {
  setConfigurationTab,
  setSelectedConfigurationSetting,
} from '../actions';
import AddTemplate from './AddTemplate';

const { TabPane } = Tabs;

const KEY_MAP = {
  group: 'notCategorizedGroups',
  program: 'notCategorizedPrograms',
};

class ConfigurationBody extends React.Component {
  state = {
    viewMore: {},
    addTemplate: {},
  };

  onTabChange = data => {
    const { setConfigurationTab } = this.props;
    setConfigurationTab(data);
  };

  handleViewMore = id => () => {
    const content = document.getElementById(`template-${id}`);
    if (content) {
      this.setState({ viewMore: { ...this.state.viewMore, [id]: true } });
      content.className = '';
    }
  };

  renderViewMore = (item, index) => {
    const { viewMore } = this.state;
    if (item.templates.length > 2 && index === 1 && !viewMore[item.id]) {
      return (
        <div className="tier-template__view-more">
          <span onClick={this.handleViewMore(item.id)}>View More</span>
        </div>
      );
    }
    return null;
  };

  isError = (value, limit) => {
    if (value < -1) {
      return 'negative';
    }
    if (value === 0) {
      return 'zero';
    }
    if (value !== -1 && value < limit) {
      return 'invalid';
    }
    return false;
  };

  renderMaximumAllowed = item => {
    const { viewMore } = this.state;
    const { configurationTab, templateData } = this.props;
    const errorValue = this.isError(item.maxAllowed, item.achievedLimit);
    const schoolErrorValue = this.isError(item.maxAllowedPerSchool, item.schoolAchievedLimit);
    const hasTemplates = has(templateData, item.id);
    const groupType = configurationTab === 'group' ? 'Groups' : 'Programs'
    const maxAllowedText = `Maximum Allowed ${groupType}`;
    const maxAllowedTexSAC = `Maximum Allowed ${groupType} per School and College`;

    return (
      <div
        style={
          item.templates &&
          ((item.templates.length <= 2 && item.templates.length > 0) ||
            viewMore[item.id])
            ? { borderTop: '1px solid #e4e6eb' }
            : {}
        }
        className="max-allowed-container"
      >
        {!hasTemplates ? (
          <span className="tier-template__sub-text">
            <h3>No templates exists for this tier currently</h3>
          </span>
        ) : (
          <>
            <>
              <div className="max-allowed-container__row">
                <div>
                  <p className="tier-template__title">
                    {maxAllowedText}
                  </p>
                  <span className="tier-template__sub-text">
                    Active {configurationTab === 'group' ? 'Groups' : 'Programs'}{' '}
                    - {item.achievedLimit || 0}
                  </span>
                </div>
                <InputNumber
                  style={{ width: '70px' }}
                  type="number"
                  min={-1}
                  value={item.maxAllowed ?? -1}
                  onChange={this.handleNumberChange(item)}
                  className={errorValue ? 'input-error' : ''}
                  disabled={!hasTemplates}
                />
              </div>
              {errorValue && (
                <span className="tier-template__sub-text tier-template--error">
                  <Icon type="info-circle" style={{ marginRight: '8px' }} />
                  {errorValue === 'negative' && (
                    <span>Enter a -1 for unlimited {configurationTab}s</span>
                  )}
                  {(errorValue === 'zero' || errorValue === 'invalid') && (
                    <span>
                      Enter a non-zero value higher than Active {configurationTab}s
                    </span>
                  )}
                </span>
              )}
            </>
            <>
              <div className="max-allowed-container__row mt-16">
                <div>
                  <p className="tier-template__title">
                    {maxAllowedTexSAC}
                  </p>
                  <span className="tier-template__sub-text">
                    Maximum active {configurationTab}s in a school and college count
                    - {item.schoolAchievedLimit || 0}
                  </span>
                </div>
                <InputNumber
                  style={{ width: '70px' }}
                  type="number"
                  min={-1}
                  value={item.maxAllowedPerSchool ?? -1}
                  onChange={this.handleMaxAllowedPerSchoolChange(item)}
                  className={schoolErrorValue ? 'input-error' : ''}
                  disabled={!hasTemplates}
                />
              </div>
              {schoolErrorValue && (
                <span className="tier-template__sub-text tier-template--error">
                  <Icon type="info-circle" style={{ marginRight: '8px' }} />
                  {schoolErrorValue === 'negative' && (
                    <span>Enter a -1 for unlimited {configurationTab}s</span>
                  )}
                  {(schoolErrorValue === 'zero' || schoolErrorValue === 'invalid') && (
                    <span>
                      Enter a non-zero value higher than maximum active {configurationTab}s in a school and college
                    </span>
                  )}
                </span>
              )}
            </>
          </>
        )}
        {item.templates && item.templates.length === 0 && hasTemplates && (
          <span className="tier-template__sub-text tier-template--warning">
            <Icon type="info-circle" style={{ marginRight: '8px' }} />
            <span>Add available templates and enable them active</span>
          </span>
        )}
      </div>
    );
  };

  handleNumberChange = tier => val => {
    if (isNumber(val)) {
      this.handleActionByType(tier, 'number')(val);
    }
  };

  handleMaxAllowedPerSchoolChange = tier => val => {
    if (isNumber(val)) {
      this.handleActionByType(tier, 'maxAllowedPerSchool')(val);
    }
  };

  handleAddTemplate = (id, val) => () => {
    this.setState({
      addTemplate: { ...this.state.addTemplate, [id]: val },
    });
  };

  handleSelect = item => val => {
    const {
      templateData,
      selectedConfigurationSetting,
      setSelectedConfigurationSetting,
    } = this.props;
    const selectedTemplate = find(templateData[item.id], { id: Number(val) });
    const modifiedSettings = selectedConfigurationSetting.map(tier => {
      if (tier.id === item.id) {
        if (!tier.templates) tier.templates = [];
        tier.templates.push(selectedTemplate);
      }
      return tier;
    });
    setSelectedConfigurationSetting(modifiedSettings);
    this.handleAddTemplate(item.id, false)();
  };

  handleActionByType = (template, type) => val => {
    const {
      selectedConfigurationSetting,
      setSelectedConfigurationSetting,
    } = this.props;
    const modifiedSettings = selectedConfigurationSetting.map(tier => {
      if (
        tier.id === template.group_tier_id &&
        ['remove', 'switch'].indexOf(type) > -1
      ) {
        if (type === 'remove') {
          tier.templates = tier.templates.filter(t => t.id !== template.id);
        }
        if (type === 'switch') {
          tier.templates = tier.templates.map(t => {
            if (t.id === template.id) {
              t.isActive = val;
            }
            return t;
          });
        }
      }
      if (tier.id === template.id && type === 'number') {
        tier.maxAllowed = val;
      }
      if (tier.id === template.id && type === 'maxAllowedPerSchool') {
        tier.maxAllowedPerSchool = val;
      }
      return tier;
    });
    setSelectedConfigurationSetting(modifiedSettings);
  };

  renderDelete = template => {
    const title = title => (
      <React.Fragment>
        <p style={{ fontSize: '16px', color: '#4d535e', marginBottom: 0 }}>
          Delete Template
        </p>
        <span style={{ fontSize: '13px' }}>{title}</span>
      </React.Fragment>
    );

    if (template.associatedGroupCount) {
      return (
        <Popconfirm
          title={title(
            'Oops! You can’t delete this template as it is being used by active groups in this hub.'
          )}
          cancelText="Go Back"
          overlayStyle={{ width: '250px' }}
          placement="bottom"
          overlayClassName="update-delete"
          icon={<Icon type="warning" style={{ color: '#cf1322' }} />}
        >
          <Icon type="delete" className="tier-template__icon" />
        </Popconfirm>
      );
    }
    return (
      <Popconfirm
        title={title(
          'Are you sure you want to remove this template out of the Tier? You can add this back again, if you decide to, at a later time.'
        )}
        onConfirm={this.handleActionByType(template, 'remove')}
        okText="Remove"
        okType="danger"
        cancelText="Cancel"
        overlayStyle={{ width: '250px' }}
        placement="bottom"
        icon={<Icon type="warning" style={{ color: '#cf1322' }} />}
        overlayClassName="template-delete"
      >
        <Icon type="delete" className="tier-template__icon" />
      </Popconfirm>
    );
  };

  renderTier = (item, index) => {
    const { addTemplate } = this.state;
    const { configurationTab, templateData } = this.props;
    return (
      <React.Fragment key={`tier-${index}`}>
        <div className="tier-header">
          <p className="tier-header__title">{item.name} Tier</p>
          <AddTemplate
            item={item}
            selectedTemplate={
              item.templates && item.templates.map(item => item.id)
            }
            addTemplate={addTemplate}
            templateData={templateData}
            handleAddTemplate={this.handleAddTemplate}
            handleSelect={this.handleSelect}
          />
        </div>
        <div style={{ margin: '0 24px' }}>
          <div id={`template-${item.id}`} className="template">
            {item.templates &&
              item.templates.map((template, index) => (
                <React.Fragment key={`template-${index}`}>
                  <div className="tier-template">
                    <p className="tier-template__title">{template.name}</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Switch
                        checked={template.isActive}
                        size="small"
                        onChange={this.handleActionByType(template, 'switch')}
                      />
                      <Divider
                        type="vertical"
                        className="tier-template__divider"
                      />
                      {this.renderDelete(template)}
                    </div>
                  </div>
                  {this.renderViewMore(item, index)}
                </React.Fragment>
              ))}
          </div>
          {this.renderMaximumAllowed(item, index)}
        </div>
      </React.Fragment>
    );
  };

  getTierBasedOnType = () => {
    const { configurationTab, selectedConfigurationSetting } = this.props;
    return selectedConfigurationSetting.filter(
      item => item.type === configurationTab
    );
  };

  render() {
    const { configurationTab, tierTabs, configurationObj } = this.props;
    const typeSetting = this.getTierBasedOnType();
    const infoValue = configurationObj[KEY_MAP[configurationTab]];
    return (
      <React.Fragment>
        <Tabs onChange={this.onTabChange} activeKey={configurationTab}>
          <TabPane
            tab="Groups"
            key="group"
            disabled={tierTabs.indexOf('group') === -1}
          />
          <TabPane
            tab="Programs"
            key="program"
            disabled={tierTabs.indexOf('program') === -1}
          />
        </Tabs>
        {infoValue && (
          <div className="info">
            <Icon type="info-circle" style={{ marginRight: '8px' }} />
            <span>
              You have {infoValue} {configurationTab}
              {infoValue > 1 ? 's' : ''} in your hub that haven't been
              categorised.
            </span>
          </div>
        )}
        <div>
          {typeSetting &&
            typeSetting.map((item, index) => this.renderTier(item, index))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ groupsProgramsTier }) => {
  const {
    configurationTab,
    selectedConfigurationSetting,
    templateData,
    tierTabs,
    configurationObj,
  } = groupsProgramsTier;
  return {
    configurationTab,
    selectedConfigurationSetting,
    templateData,
    tierTabs,
    configurationObj,
  };
};

const mapDispatchToProps = {
  setConfigurationTab,
  setSelectedConfigurationSetting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationBody);
