import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, PageHeader } from 'antd';
import { isEmpty } from 'lodash';
import { breadCrumbItemRender } from '../../../../utils/helpers';

import ProgressBar from '../../../../component/ProgressBar';
import ManageTemplateRow from './components/ManageTemplateRow';
import ActiveHubsModal from './components/ActiveHubsModal';
import DeleteModal from '../Manage/modals/DeleteModal';

import { fetchTemplates, deleteTemplate } from '../../actions/manageActions';

import './styles/index.scss';

const breadcrumbConf = {
  routes: [
    {
      path: '/usertype-settings',
      breadcrumbName: 'Usertype Settings',
    },
    {
      path: '/usertype-settings/manage-templates',
      breadcrumbName: 'Manage Templates',
    },
  ],
  itemRender: breadCrumbItemRender,
};

const EmptyState = () => (
  <div className="empty-state">
    <img
      src="https://cdn.peoplegrove.com/admin/1615289299836pg-info-circle-o.svg"
      alt="info-icon"
    />
    <p className="empty-state__heading">
      Click on "Create Templates" button above to get started.
    </p>
  </div>
);

class ManageTemplates extends PureComponent {
  state = {
    activeTemplateId: null,
  };

  componentDidMount() {
    const { fetchTemplates } = this.props;
    fetchTemplates();
  }

  handleCreateTemplate = () => {
    const { history } = this.props;
    history.push('/usertype-settings/manage-templates/new');
  };

  setActiveTemplateId = templateId => {
    this.setState({ activeTemplateId: templateId });
  };

  handleDeleteTemplate = templateId => {
    const { deleteTemplate } = this.props;
    deleteTemplate(templateId);
    this.setState({ activeTemplateId: null });
  };

  render() {
    const { groupedTemplates, manageLoading } = this.props;
    const { activeTemplateId } = this.state;

    const usertypeValues = Object.keys(groupedTemplates);

    return (
      <div className="manage-usertype-templates">
        {manageLoading ? (
          <ProgressBar style={{ minHeight: '100vh' }} />
        ) : (
          <>
            <PageHeader
              title={
                <p className="header__title">
                  {isEmpty(groupedTemplates)
                    ? 'No Templates Found'
                    : 'User Type Templates'}
                </p>
              }
              breadcrumb={breadcrumbConf}
              style={{ backgroundColor: '#fff' }}
              extra={[
                <Button
                  key="primary"
                  type="primary"
                  onClick={this.handleCreateTemplate}
                >
                  Create Template
                </Button>,
              ]}
            />
            <div className="main-container">
              {isEmpty(groupedTemplates) ? (
                <EmptyState />
              ) : (
                <>
                  <Card className="warning__card" style={{ width: '100%' }}>
                    <div className="warning__description">
                      <p className="warning__text">
                        <span className="warning__text-bold">Note: </span>
                        {` Any changes you make here will be reflected in all hubs whose user type library contains this template. Only make changes if you feel they are absolutely necessary. `}
                      </p>
                    </div>
                  </Card>
                  {usertypeValues.map(usertype => (
                    <ManageTemplateRow
                      key={usertype}
                      usertype={usertype}
                      data={groupedTemplates[usertype]}
                      setActiveTemplateId={this.setActiveTemplateId}
                    />
                  ))}
                  {activeTemplateId && (
                    <ActiveHubsModal
                      templateId={activeTemplateId}
                      setActiveTemplateId={this.setActiveTemplateId}
                    />
                  )}
                  <DeleteModal
                    templateId={activeTemplateId}
                    manageTypeOverride="templates"
                    deleteTemplate={this.handleDeleteTemplate}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  usertypeManage: { groupedTemplates, manageLoading },
}) => ({
  groupedTemplates,
  manageLoading,
});

const mapDispatchToProps = {
  fetchTemplates,
  deleteTemplate,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManageTemplates)
);
