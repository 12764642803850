import { request } from '../utils/request';

const baseURL = '/superadmin/ask-anything';

const getQueryParams = filters => {
  const { search, dateRange, currentPage: page, perPage = 10 } = filters;
  return {
    perPage,
    page,
    search,
    ...dateRange,
  };
}

const getAskedQuesCountApi = filters => {
  const params = getQueryParams(filters);
  return request.get(`${baseURL}/asked-ques`, { params });
};

const getRoutedQuesCountApi = filters => {
  const params = getQueryParams(filters);
  return request.get(`${baseURL}/routed-ques`, { params });
};

const getReceivedResponsesCountApi = filters => {
  const params = getQueryParams(filters);
  return request.get(`${baseURL}/received-res`, { params });
};

export {
  getAskedQuesCountApi,
  getRoutedQuesCountApi,
  getReceivedResponsesCountApi,
};
