import { request } from '../utils/request';

export const fetchExperimentsAPI = () => request.get('/experiments');

export const updateExperimentAPI = data => request.post(`/experiments/${data.id}`, data);

export const deleteExperimentAPI = data => request.delete(`/experiments/${data.id}`);

export const addExperimentHubAPI = data => request.put(`/experiment-hub`, data);

export const deleteExperimentHubAPI = data => request.delete(`experiment-hub/${data.id}`);

export const setExperimentHubMappingAPI = ({ data }) => request.put(`/experiment-hub`, data);
