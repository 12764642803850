import React from 'react';
import { Select, Row, Col, Divider, Button, Input, Tooltip, Icon } from 'antd';
import { get } from 'lodash';
import s from '../AnalyticsReport.module.scss';

const { Option } = Select;

const TOOLTIP_TEXT = `
Add the columns which can be edited on data viewer. Need to add that which table's which column should be updated if this column is updated on data viewer.
For main db, select which value should be used as id in where query for that value to be updated.
Since analytics database tables doesn't have id field in all tables, you can select multiple columns to be added in where condition to update.
`

class EditableColumns extends React.Component {

  getAllHeaderOptions = () => {
    const { allHeaders = [] } = this.props
    if (Array.isArray(allHeaders)) {
      return allHeaders.map(ah => {
        return <Option value={ah}>{ah}</Option>;
      });
    }
    return [];
  };

  onAddNewColumn = () => {
    const { editableColumns = [], onUpdate } = this.props;
    let newArr = [...editableColumns, {
      column: null,
      mainDbTable: '',
      mainDbColumn: '',
      mainDbIdValue: '',
      analyticsDbTable: '',
      analyticsDbColumn: '',
      analyticsDbWheres: [],
    }]
    onUpdate(newArr);
  }

  onRemoveColumn = (i) => () => {
    const { editableColumns = [], onUpdate } = this.props;
    let newArr = [...editableColumns]
    newArr.splice(i,1)
    onUpdate(newArr);
  }

  onAddNewAnalyticsWhere = (i) => () => {
    const { editableColumns = [], onUpdate } = this.props;
    let newArr = [...editableColumns]
    let ele = newArr[i]

    if (ele) {
      ele = {...ele}
      if (!Array.isArray(ele.analyticsDbWheres)) {
        ele.analyticsDbWheres = []
      }
      ele.analyticsDbWheres = [...ele.analyticsDbWheres, {}]
      newArr[i] = ele;
    }
    onUpdate(newArr);
  }

  onRemoveAnalyticsWhere = (i, j) => () => {
    const { editableColumns = [], onUpdate } = this.props;
    let newArr = [...editableColumns]
    let ele = newArr[i]

    if (ele) {
      ele = {...ele}
      if (Array.isArray(ele.analyticsDbWheres)) {
        ele.analyticsDbWheres = [...ele.analyticsDbWheres]
        ele.analyticsDbWheres.splice(j,1)
      }
      newArr[i] = ele;
    }
    onUpdate(newArr);
  }

  onUpdateColumn = (i, key) => e => {
    const value = get(e, 'target') ? get(e, 'target.value') : e;
    const { onUpdate, editableColumns = [] } = this.props;
    let newArr = [...editableColumns]
    if (newArr[i]) {
      newArr[i] = {...newArr[i]}
      newArr[i][key] = value;
    }
    onUpdate(newArr);
  };

  onUpdateAnalyticsWheres = (i, j, key) => e => {
    const value = get(e, 'target') ? get(e, 'target.value') : e;
    const { onUpdate, editableColumns = [] } = this.props;
    let newArr = [...editableColumns]
    if (newArr[i] && newArr[i].analyticsDbWheres && newArr[i].analyticsDbWheres[j]) {
      newArr[i].analyticsDbWheres[j] = {...newArr[i].analyticsDbWheres[j]}
      newArr[i].analyticsDbWheres[j][key] = value;
    }
    onUpdate(newArr);
  };

  render() {
    const { editableColumns = [], database } = this.props
    return (
      <React.Fragment>
        <Row className={`${s['ant-row']}`}>
          <Row className={`${s['ant-row']}`}>
            <h3>Editable Columns 
              &nbsp;
              <Tooltip title={TOOLTIP_TEXT}>
                <Icon type="info-circle" />
              </Tooltip>
            </h3>
          </Row>
          {editableColumns.map((ec, i) => (
            <div style={{ marginLeft: '20px' }}>
              <Row className={`${s['ant-row']}`}>
                <Col span={7}>
                  <Select
                    placeholder="Filter on Column"
                    onChange={this.onUpdateColumn(i, 'column')}
                    value={ec.column || undefined}
                    style={{ width: 120 }}
                    dropdownMatchSelectWidth={false}
                  >
                    {this.getAllHeaderOptions()}
                  </Select>
                </Col>
                <Col span={14}>
                  <div>
                    <span>UPDATE&nbsp;</span>
                    <Input
                      value={ec.mainDbTable}
                      style={{ width: 150 }}
                      placeholder="Main Db table"
                      onChange={this.onUpdateColumn(i, 'mainDbTable')}
                    />
                    <span>&nbsp;SET&nbsp;</span>
                    <Input
                      value={ec.mainDbColumn}
                      style={{ width: 150 }}
                      placeholder="Main Db column"
                      onChange={this.onUpdateColumn(i, 'mainDbColumn')}
                    />
                    <label>&nbsp;WHERE id in &nbsp;</label>
                    <Select
                      placeholder="Filter on Column"
                      onChange={this.onUpdateColumn(i, 'mainDbIdValue')}
                      value={ec.mainDbIdValue || undefined}
                      style={{ width: 120 }}
                      dropdownMatchSelectWidth={false}
                    >
                      {this.getAllHeaderOptions()}
                    </Select>
                  </div>
                </Col>
                <Col
                  onClick={this.onRemoveColumn(i)}
                  style={{ textAlign: 'right' }}
                  span={3}
                >
                  Remove
                </Col>
              </Row>
              <Divider />
              {database === 'analytics' && <React.Fragment>
                <Row className={`${s['ant-row']}`}>
                  <Col span={7} />
                  <Col span={14}>
                    <div>
                      <span>UPDATE&nbsp;</span>
                      <Input
                        value={ec.analyticsDbTable}
                        style={{ width: 150 }}
                        placeholder="Analytics Db table"
                        onChange={this.onUpdateColumn(i, 'analyticsDbTable')}
                      />
                      <span>&nbsp;SET&nbsp;</span>
                      <Input
                        value={ec.analyticsDbColumn}
                        style={{ width: 150 }}
                        placeholder="Analytics Db table"
                        onChange={this.onUpdateColumn(i, 'analyticsDbColumn')}
                      />
                      <label>&nbsp;WHERE</label>
                    </div>
                  </Col>
                  <Col span={3} />
                </Row>
                {Array.isArray(ec.analyticsDbWheres) && ec.analyticsDbWheres.map((whe, j) => (
                  <Row>
                    <Col span={7} />
                    <Col span={7}>
                      <Input
                        value={whe.tableCol}
                        style={{ width: 400 }}
                        placeholder="Match On"
                        onChange={this.onUpdateAnalyticsWheres(i, j, 'tableCol')}
                      />
                      <span> = </span>
                    </Col>
                    <Col span={7}>
                      <Select
                        placeholder="Match With"
                        onChange={this.onUpdateAnalyticsWheres(i, j, 'valueCol')}
                        value={whe.valueCol || undefined}
                        style={{ width: 120 }}
                        dropdownMatchSelectWidth={false}
                      >
                        {this.getAllHeaderOptions()}
                      </Select>
                    </Col>
                    <Col
                      onClick={this.onRemoveAnalyticsWhere(i, j)}
                      style={{ textAlign: 'left' }}
                      span={3}
                    >
                      Remove
                    </Col>
                  </Row>
                ))}
                <Row className={`${s['ant-row']}`} justify={'end'}>
                  <Col span={7} />
                  <Button
                    onClick={this.onAddNewAnalyticsWhere(i)}
                  >
                    Add Where Condition
                  </Button>
                </Row>
                <Divider />
                </React.Fragment>
              }
            </div>
          ))}
        </Row>
        <Row className={`${s['ant-row']}`} justify={'end'}>
          <Button
            onClick={this.onAddNewColumn}
          >
            Add Editable Column
          </Button>
        </Row>
      </React.Fragment>
    )
  }
}

export default EditableColumns;