import axios from 'axios';
import { includes, get, set } from 'lodash';

import history from '../history';
import { getItem, removeItem, setItem } from './localStorageService';
import { store } from '../store';
import { authSetAuthToken } from '../actions/auth';

export const LOCAL_URL =
  process.env.API_URL ||
  process.env.REACT_APP_API_URL ||
  'https://www.localhost.com:1337';

export const request = {
  init() {
    const req = axios.create({
      withCredentials: true,
      baseURL: LOCAL_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.attachInterceptor(req);
    return req;
  },

  attachInterceptor(req) {
    req.interceptors.response.use(
      response => {
        // Do something with response data
        if (response && response.message === 'token_expired') {
          removeItem('token');
          history.push(`/auth`);
          return null;
        }
        return response;
      },
      error => {
        // Do something with response error
        const { response, config } = error;
        const status = response?.status || error.request?.status;
        if (status === 401) {
          if (
            !getItem('refreshTokenFound') ||
            getItem('refreshTokenFound') === 'false'
          )
            return history.push(`/auth`);
          const refreshInstance = axios.create({
            withCredentials: true,
            baseURL: LOCAL_URL,
            headers: {
              'Content-Type': 'application/json',
            },
          });
          return refreshInstance.get('/auth/token/refresh').then(({ data }) => {
            if (data.error) {
              return history.push(`/auth`);
            }
            setItem('refreshTokenFound', 'true');
            store.dispatch(authSetAuthToken(data.token));
            const newConfig = {
              ...config,
              headers: { ...config.headers, Authorization: data.token },
            };
            return req(newConfig);
          });
        }
        const handledErrors = [404, 403];
        if (includes(handledErrors, status)) {
          history.push(`/${status}`);
        }
        // eslint-disable-next-line consistent-return
        return Promise.reject(error);
      }
    );
    req.interceptors.request.use(config => {
      const token = get(store.getState(), 'auth.authToken');
      if (!token) {
        const refreshInstance = axios.create({
          withCredentials: true,
          baseURL: LOCAL_URL,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return refreshInstance.get('/auth/token/refresh').then(({ data }) => {
          if (!data.error) {
            store.dispatch(authSetAuthToken(data.token));
            setItem('refreshTokenFound', 'true');
            return {
              ...config,
              headers: {
                ...config.headers,
                Authorization: `bearer ${data.token}`,
              },
            };
          }
          setItem('refreshTokenFound', 'false');
          return config;
        });
      }
      return {
        ...config,
        headers: { ...config.headers, Authorization: `bearer ${token}` },
      };
    });
  },

  get(...args) {
    return this.init().get.apply(null, args);
  },

  post(...args) {
    return this.init().post.apply(null, args);
  },

  delete(...args) {
    return this.init().delete.apply(null, args);
  },

  put(...args) {
    return this.init().put.apply(null, args);
  },
};
