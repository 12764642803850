import React, {Fragment} from 'react';
import { Table, Button, Switch, Icon, Modal } from 'antd';
import moment from 'moment';
import EditExperiment from './EditExperiment';
const { confirm } = Modal;
class ExperimentTable extends React.PureComponent {

	state = {
		showEditExperiment: false,
		editingExperimentId: null,
		experiments: this.props.experiments,
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if(JSON.stringify(nextProps.experiments) !== JSON.stringify(prevState.experiments)) {
			return { experiments: nextProps.experiments };
		}
		return null;
	}

	_editExperiment = experimentId => {
		this.setState({ showEditExperiment: true, editingExperimentId: experimentId });
	}

	_setEditMode = data => {
		this.setState({showEditExperiment: data});
	}

	_deleteExperiment = data => {
		const { deleteExperiment } = this.props;
		deleteExperiment(data);
	}

	columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			width: 250,
			key: 'name',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			width: 600,
			key: 'description',
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			width: 250,
			key: 'startDate',
			render: v => moment(v).format('M-D-YY')
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			width: 250,
			key: 'endDate',
			render: v => moment(v).format('M-D-YY')
		},
		{
			title: 'Active Groups & Hubs',
			width: 600,
			dataIndex: 'hubMappings',
			key: 'hubMappings',
			render: (hubMappings, v) => {
				if (!hubMappings || !Array.isArray(hubMappings)) {
					return null;
				}
				let res = hubMappings.map(hm => hm && hm.hubDetails && (
					<div key={`hub_${hm.hubDetails.id}`}>
						{`${hm.hubDetails.id}: ${hm.hubDetails.name}  `}
						<Icon type={`${hm.isActive ? 'check-circle' : 'close-circle'}`} />
					</div>
				)); 
				res.push(<hr key={`hub_adminTag_split_${v.id}`}/>);
				return res.concat(hubMappings.map(hm => hm.adminTagDetails && (
							<div key={`adminTagDetails_${hm.adminTagDetails.id}`}> 
								<span> 
									{`${hm.adminTagDetails.id}: ${hm.adminTagDetails.name} `}
									<Icon type={`${hm.isActive ? 'check-circle' : 'close-circle'}`} />
								</span>
								<ul>
									{hm.adminTagDetails.adminTagReferenceDetails.map(h => (
										<li key={`adminTag_hub_${h.id}`}>
											{h.id}: {h.name} &nbsp;
											{
												hm.isActive && <Icon type={`${h.isActive ? 'check-circle' : 'close-circle'}`} />
											}
										</li>
									))}
								</ul>
							</div>
						)
					)
				)
			}
			,
		},
		{
			title: 'Variant & Weights',
			dataIndex: 'settings.variants',
			width: 600,		
			key: 'settings.variants',
			render: variants => Array.isArray(variants) ? 
				variants.map(v => `${v.variantName}: ${v.weight}`).join(" , ") 
				: null
		},
		{
			title: 'Status',
			dataIndex: 'experiment_status',
			width: 100,
			key: 'experiment_status',
			render: (k, v) => (
				<Switch
					defaultChecked={v.experimentStatus || false}
					onChange={(experimentStatus) => this.props.updateExperiment({id: v.id, experimentStatus})}
				/>
			),
		},
		{
			title: 'Delete',
			dataIndex: 'deletedAt',
			width: 100,
			key: 'deletedAt',
			render: (k, v) => (
				<Button type="danger" icon="delete" onClick={
					() => {
						confirm({
							title: 'Are you sure you want to delete this experiment?',
							content: 'THIS ACTION CANNOT BE UNDONE! To temporarily disable experiment, please toggle status switch.',
							onOk: () => {
								this._deleteExperiment(v)
							},
							onCancel() {},
						});
					}
				} />					
			),
		},
		{
			title: 'Edit',
			dataIndex: 'updatedAt',
			width: 100,
			key: 'updatedAt',
			render: (k, v) => (
				<Button type="primary" icon="edit" onClick={() => this._editExperiment(v.id)}/>
			),
		},
	];


	render() {
		const { updateExperiment, setExperimentHubMapping, adminTag, addExperimentHub, deleteExperimentHub  } = this.props;
		const { experiments, showEditExperiment, editingExperimentId } = this.state;
		return (
		<Fragment>
			<Table
				rowKey={experiment => `experiment_${experiment.id}`}
				dataSource={experiments}
				columns={this.columns}
				bordered
				scroll={{ x: 'max-content' }}
			/>
			{showEditExperiment ? 
				<EditExperiment 
					experiment={experiments.find(e => e.id === editingExperimentId)} 
					visible={showEditExperiment}
					updateExperiment={updateExperiment}
					setEditMode = {this._setEditMode}
					adminTag={adminTag}
					addExperimentHub={addExperimentHub}
					deleteExperimentHub={deleteExperimentHub}
					setExperimentHubMapping={setExperimentHubMapping}
				/> 
				: null}
		</Fragment>
		)
	}
};

export default ExperimentTable;