import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Manage from './manage';
import GroupProgramTier from './index';

export default () => {
  return (
    <Switch>
      <Route
        path={`/groups-programs-tier/manage-tier/:type/new`}
        component={Manage}
      />
      <Route
        path={`/groups-programs-tier/manage-tier/:type/edit/:id`}
        component={Manage}
      />
      <Route path={`/groups-programs-tier/manage-tier/:type`} component={Manage} />
      <Route
        path={`/groups-programs-tier/manage-template/:type/new`}
        component={Manage}
      />
      <Route
        path={`/groups-programs-tier/manage-template/:type/edit/:id`}
        component={Manage}
      />
      <Route
        path={`/groups-programs-tier/manage-template/:type`}
        component={Manage}
      />
      <Route path={`/groups-programs-tier`} component={GroupProgramTier} />
    </Switch>
  );
};
