import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

class TopBar extends PureComponent {
  state = {
    showingPreview: false,
    showingStructure: false,
  };

  onPreview = () => {
    const { beeInstance } = this.props;

    this.setState(
      { showingPreview: !this.state.showingPreview },
      beeInstance.togglePreview
    );
  };

  onStructure = () => {
    const { beeInstance } = this.props;

    this.setState(
      { showingStructure: !this.state.showingStructure },
      beeInstance.toggleStructure
    );
  };

  render() {
    const { showingPreview, showingStructure } = this.state;
    const { beeInstance } = this.props;

    return (
      <>
        {beeInstance && (
          <div
            onClick={showingPreview ? this.onPreview : null}
            className="am__email-editor__top-bar"
          >
            <Button
              onClick={!showingPreview ? this.onPreview : null}
              tabIndex={2}
              type="link"
            >
              Preview
            </Button>
            <Button onClick={this.onStructure} tabIndex={3} type="link">
              {showingStructure ? `Hide structure` : `Show structure`}
            </Button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  automatedMarketing: {
    template,
    beeInstance,
    isSendingEmail,
    messageActionInfo,
    editorLoading,
  },
}) => ({
  editorLoading,
  isSendingEmail,
  messageActionInfo,
  template,
  beeInstance,
});

export default connect(
  mapStateToProps,
  null
)(TopBar);
