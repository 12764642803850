import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { handleActionCancelModalVisible } from '../../../actions/manageActions';

class CancelModal extends PureComponent {
  handleCancelOk = () => {
    const { history, handleActionCancelModalVisible, manageType } = this.props;

    if (history.action === 'PUSH') history.goBack();
    else history.push(`/usertype-settings/manage-${manageType}/`);

    handleActionCancelModalVisible(false);
  };

  handleOnCancel = () => {
    const { handleActionCancelModalVisible } = this.props;
    handleActionCancelModalVisible(false);
  };

  renderFooter = () => (
    <div>
      <Button onClick={this.handleCancelOk}>Don&apos;t Save</Button>
    </div>
  );

  getManageTypeText = () => {
    const { manageType } = this.props;
    switch (manageType) {
      case 'upsells':
        return 'Upsell';
      case 'usertype-library':
        return 'Usertype Library';
      case 'templates':
        return 'Usertype Template';
      default:
        return '';
    }
  };

  render() {
    const { cancelModalVisible, manageType } = this.props;

    return (
      <Modal
        title={`Save ${manageType === 'upsells' ? 'Upsell' : ''}`}
        visible={cancelModalVisible}
        onCancel={this.handleOnCancel}
        footer={this.renderFooter()}
        className="confirmation-modal"
        destroyOnClose
      >
        <p>
          Are you sure you don&apos;t want to save this
          {` ${this.getManageTypeText()}?`}
        </p>
      </Modal>
    );
  }
}

const mapStateToProps = ({ usertypeManage: { cancelModalVisible } }) => ({
  cancelModalVisible,
});

const mapDispatchToProps = { handleActionCancelModalVisible };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CancelModal)
);
