import React, { PureComponent } from 'react';
import { PageHeader, Breadcrumb, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import { Route, Switch, Link, withRouter } from 'react-router-dom';

import CampaignsList from './Campaign/CampaignsList';
import CampaignInfo from './Campaign/CampaignInfo';
import Templates from './Templates';
import EmailEditor from './EmailEditor';
import { resetCampaignInfo, setPreviewTemplate } from './actions';

import { get } from 'lodash';

import './campaigns.scss';
import PreviewTemplateModal from './_components/PreviewTemplateModal';

const routes = [
  {
    title: <Icon type="home" />,
    path: '/campaigns',
    component: CampaignsList,
  },
  {
    title: 'New Campaign',
    path: '/campaigns/:id',
    component: CampaignInfo,
    isCampaignName: true,
  },
  {
    title: 'Templates',
    path: '/campaigns/:id/templates',
    component: Templates,
  },
  {
    title: 'Email Editor',
    path: '/campaigns/:id/email-editor',
    component: EmailEditor,

  },
  {
    title: 'Email Editor',
    path: '/campaigns/:id/templates/email-editor',
    component: EmailEditor,
  },
];

class CampaignsPage extends PureComponent {
  BreadcrumbItems = ({ location: { pathname }, match, campaignInfo = {} }) => {
    const id = get(match, ['params', 'id']);
    const routePaths = routes.map(r => ({
      ...r,
      path: r.path.replace(':id', id),
    }));
    const pathSnippets = pathname.split('/').filter(i => i);
    const items = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      const routePath = routePaths.find(rp => rp.path === url) || {};
      const { isCampaignName, title, path } = routePath;
      const routeTitle =
        isCampaignName && id !== 'new'
          ? get(campaignInfo, 'name', title)
          : title;
      return (
        <Breadcrumb.Item key={url}>
          {pathname === path ? routeTitle : <Link to={url}>{routeTitle}</Link>}
        </Breadcrumb.Item>
      );
    });
    return items;
  };

  handleNewCampaign = () => {
    const { history, resetCampaignInfo } = this.props;
    resetCampaignInfo();
    setTimeout(() => history.push('/campaigns/new'), 0);
  };

  render() {
    const {
      location,
      match,
      campaignInfo,
      previewTemplate,
      setPreviewTemplate,
    } = this.props;
    return (
      <div className="campaigns">
        <PageHeader
          title="Campaigns"
          className="cm__page-header"
          subTitle={
            [routes[0].path, routes[0].path + '/'].includes(
              location.pathname
            ) ? (
              <Button
                type="primary"
                className="cm__page-header__right_btn"
                onClick={this.handleNewCampaign}
              >
                New Campaign
              </Button>
            ) : (
              <Breadcrumb>
                {this.BreadcrumbItems({ location, match, campaignInfo })}
              </Breadcrumb>
            )
          }
        />
        <Switch>
          {routes.map((route, key) => (
            <Route
              exact
              path={route.path}
              render={props => <route.component {...props} isCampaign={true} />}
              key={key}
            />
          ))}
        </Switch>
        <PreviewTemplateModal
          previewTemplate={previewTemplate}
          onCancel={() => setPreviewTemplate(null)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  automatedMarketing: {
    previewTemplate,
    campaign: { campaignInfo },
  },
}) => ({
  previewTemplate,
  campaignInfo,
});

const mapDispatchToProps = {
  resetCampaignInfo,
  setPreviewTemplate,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CampaignsPage)
);
