import React from 'react';

import QuestionsAskedDataTable from './DataTables/QuestionsAskedDataTable';
import QuestionsRoutedDataTable from './DataTables/QuestionsRoutedDataTable';
import ResponsesDataTable from './DataTables/ResponsesDataTable';
import { dataTables } from './constants';

const AskAnythingMetricsContent = ({ currentPage }) => {
  const { QUESTIONS_ASKED, QUESTIONS_ROUTED, RESPONSES } = dataTables;
  switch (currentPage) {
    case QUESTIONS_ASKED:
      return <QuestionsAskedDataTable />;
    case QUESTIONS_ROUTED:
      return <QuestionsRoutedDataTable />;
    case RESPONSES:
      return <ResponsesDataTable />;
    default:
      return null;
  }
};

export default AskAnythingMetricsContent;
