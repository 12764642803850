import React, { useMemo, useState } from 'react';
import { Card, Table } from 'antd';
import { useGetReceivedResponsesCountQuery } from '../query/useGetReceivedResponsesCountQuery';
import { get } from 'lodash';
import DataTableFilters from './DataTableFilters';
import classes from '../AskAnythingMetrics.module.scss';
import { numCompare, stringCompare } from '../util';

const ResponsesDataTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const pageSize = 10;

  const { data, isLoading: isFetchingResponsesCount } =
    useGetReceivedResponsesCountQuery({
      ...filters,
      currentPage,
      perPage: pageSize,
    });

  const totalRecords = useMemo(() => get(data, '0.total_records', 0), [data]);

  console.log({ data });
  const columns = [
    {
      title: 'Hub ID',
      dataIndex: 'hub_id',
      key: 'hub_id',
    },
    {
      title: 'Hub Name',
      dataIndex: 'hub_name',
      key: 'hub_name',
      sorter: (r1, r2) => stringCompare(r1, r2, 'hub_name'),
    },
    {
      title: 'Total # of Responses Received',
      dataIndex: 'total_count',
      key: 'total_count',
      sorter: (r1, r2) => numCompare(r1, r2, 'total_count'),
    },
    {
      title: 'From Platform',
      key: 'asked_from_platform',
      render: record => get(record, 'source_counts.platform', 0),
      sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.platform'),
    },
    {
      title: 'From Email',
      key: 'asked_from_Email',
      render: record => get(record, 'source_counts.email', 0),
      sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.email'),
    },
    {
      title: 'From Landing Page',
      key: 'asked_from_landing_page',
      render: record => get(record, 'source_counts.landingPage', 0),
      sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.landingPage'),
    },
    {
      title: 'From AI',
      key: 'asked_from_ai',
      render: record => get(record, 'source_counts.ai', 0),
      sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.ai'),
    },
    {
      title: 'From Admin-AI',
      key: 'asked_from_admin',
      render: record => get(record, 'source_counts.admin', 0),
      sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.admin'),
    },
  ];

  return (
    <>
      <DataTableFilters
        setFiltersToApply={setFilters}
        isApplyingFilters={isFetchingResponsesCount}
      />
      <Card bordered={false}>
        <Table
          className={classes.data_table}
          dataSource={data}
          columns={columns}
          bordered
          loading={isFetchingResponsesCount}
          pagination={{
            current: currentPage,
            pageSize,
            hideOnSinglePage: true,
            onChange: updatedCurrPage => {
              setCurrentPage(updatedCurrPage);
            },
            total: totalRecords,
          }}
        />
      </Card>
    </>
  );
};

export default ResponsesDataTable;
