import { request } from '../utils/request';

export const saveAnalyticsQueryApi = params =>
  request.post('/analytics-query', params);

export const fetchAnalyticsQueryApi = () => request.get('/analytics-query');

export const fetchQueryBasedOnIdApi = params =>
  request.get(`/analytics-query/${params}`);

export const updateAnalyticsQueryApi = params =>
  request.put(`/analytics-query/${params.id}`, params);

export const setActiveQueryApi = params =>
  request.put(`/analytics-query/set-active/${params.id}`, params);

export const fetchAssociatedChartApi = () =>
  request.get('/analytics-service/associated-chart');

export const fetchReportTablesApi = () =>
  request.get('/analytics-service/associated-chart/getTableName');

export const fetchAssociatedChartDataApi = params =>
  request.get(`/analytics-service/associated-chart/${params}`);

export const updateAssociatedChartApi = params =>
  request.put(`/analytics-service/associated-chart/${params.id}`, params);

export const saveAssociatedChartApi = params =>
  request.post(`/analytics-service/associated-chart`, params);
