import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClusterFeatures from './ClusterFeatures';
import HubFeatures from './HubFeatures';
import PrivateRoute from '../../containers/PrivateRoute/PrivateRoute';

export default () => {
  return (
    <Switch>
      <PrivateRoute path={`/features/cluster`} accessGroups={['cluster-features']} component={ClusterFeatures} />
      <PrivateRoute path={`/features/hub`} accessGroups={['hub-features']} component={HubFeatures} />
    </Switch>
  );
};
