import { SHOW_ALERT, HIDE_ALERT } from '../actions/alert';

const initialState = {
    show: false,
    message: '',
    type: ''
};

const alert = (state = initialState, { type, data }) => {
  switch (type) {
    case SHOW_ALERT:
      return {
        show: true,
        message: data.message,
        type: data.type
      }
    case HIDE_ALERT:
      return {
        ...initialState
      }
    default: return state
  }
};

export default alert;