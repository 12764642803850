import { SET_ADMIN_TAGS, ADMIN_TAGS_LOADING, ADMIN_TAG_UPDATING, SET_CLUSTER_HUBS } from '../actions/index';

const initialState = {
	adminTags: [],
  adminTagsLoading: false,
  adminTagUpdating: false,
  clusterHubs: [],
};

const userDetail = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ADMIN_TAGS:
      return {
        ...state,
        adminTags: data,
      }
    case ADMIN_TAGS_LOADING:
      return {
        ...state,
				adminTagsLoading: data,
      }
    case ADMIN_TAG_UPDATING:
      return {
        ...state,
        adminTagUpdating: data
      }
    case SET_CLUSTER_HUBS:
      return {
        ...state,
        clusterHubs: data,
      }
    default: return state
  }
};

export default userDetail;