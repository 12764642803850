import React from 'react';
import { Alert, Modal } from 'antd';

const ArchiveConfirmModal = ({ visible, onCancel, onOk, identifier }) => (
  <Modal
    title="Archive Default Text"
    closable
    visible={visible}
    cancelText="Cancel"
    onCancel={onCancel}
    destroyOnClose
    okText="Confirm"
    onOk={() => onOk && onOk()}
    okButtonProps={{ type: 'danger' }}
    cancelButtonProps={{ type: 'link' }}
    bodyStyle={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      textAlign: 'center',
    }}
  >
    <Alert
      message="Archiving this identifier will remove this text from all instances on the platform."
      type="warning"
    />
    <div>
      Are you sure you want to archive <strong>{identifier}?</strong>
    </div>
  </Modal>
);
export default ArchiveConfirmModal;
