import { Modal, Table } from 'antd';
import React, { useEffect } from 'react';

const ModifiedHubsModal = ({
  visibility,
  setVisibility,
  data,
  identifier,
  getColumnSearchProps,
  isCluster = false,
}) => {
  const modalTypeId = isCluster ? 'clusterId' : 'hubId';
  const columns = [
    {
      title: isCluster ? 'Cluster Id' : 'Hub Id',
      key: modalTypeId,
      dataIndex: modalTypeId,
      ...getColumnSearchProps(modalTypeId, modalTypeId),
      render: k => k,
    },
    {
      title: 'Updated Text',
      key: 'updatedText',
      dataIndex: 'updatedText',
      render: k => k,
    },
  ];
  return (
    <Modal
      visible={visibility}
      cancelButtonProps={{ hidden: true }}
      onOk={() => setVisibility(false)}
      onCancel={() => setVisibility(false)}
      title={`Modified text for ${identifier}`}
    >
      <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} />
    </Modal>
  );
};

export default ModifiedHubsModal;
