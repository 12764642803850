import {
  SET_IMPORT_LIST,
  SET_IMPORT_LIST_LOADING,
  SET_IMPORT_LIST_COUNT,
  SET_IMPORT_LIST_PAGE,
  CLEAR_IMPORT_STATE,
  SET_IMPORT_QUEUE_STATUS,
} from '../actions';

const initialState = {
  importList: [],
  importListLoading: false,
  importListCount: 0,
  page: 1,
  queueStatus: {},
};

const manageImportReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_IMPORT_LIST:
      return {
        ...state,
        importList: data,
      };
    case SET_IMPORT_LIST_LOADING:
      return {
        ...state,
        importListLoading: data,
      };
    case SET_IMPORT_LIST_COUNT:
      return {
        ...state,
        importListCount: data,
      };
    case SET_IMPORT_LIST_PAGE:
      return {
        ...state,
        page: data,
      };
    case CLEAR_IMPORT_STATE:
      return {
        ...state,
        importList: [],
        importListLoading: false,
        importListCount: 0,
        page: 1,
        queueStatus: {},
      };
    case SET_IMPORT_QUEUE_STATUS:
      return {
        ...state,
        queueStatus: data,
      };
    default:
      return state;
  }
};

export default manageImportReducer;
