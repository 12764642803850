import { Button, Divider, Icon, Input, Table, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import ModifiedHubsModal from '../modals/ModifiedHubsModal';

import './index.scss';
import EditDefaultTextModal from '../modals/EditModal';
import { useDeleteDefaultTextMutation } from '../queries';
import ArchiveConfirmModal from '../modals/ArchiveConfirmModal';

const DefaultTextTable = ({ data, loading, isCluster = false, isArchived }) => {
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modules, setModules] = useState('');
  const [defaultText, setDefaultText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modifiedHubsInfo, setModifiedHubsInfo] = useState([]);
  const [modalIdentifier, setIdentifier] = useState(null);

  const { mutate: archiveDefaultText } = useDeleteDefaultTextMutation({
    onSuccess: () => {
      setShowArchiveAlert(false);
      setEditModal(false);
    },
  });

  const getColumnSearchProps = (dataIndex, placeholder = '') => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            handleReset(clearFilters, dataIndex);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] || '')
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    if (clearFilters) {
      clearFilters();
    }
  };

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      className: 'default-text-id',
      ...getColumnSearchProps('id', 'id'),
      render: k => k || '-',
    },
    {
      title: 'Identifier',
      key: 'identifier',
      dataIndex: 'identifier',
      className: 'default-text-identifier',
      ...getColumnSearchProps('identifier', 'identifier'),
      render: k => <Tooltip title={k}>{k || '-'}</Tooltip>,
    },
    {
      title: 'Default Text',
      key: 'defaultText',
      dataIndex: 'defaultText',
      className: 'default-text-value',
      render: k => k || '-',
    },
    {
      title: 'Modules',
      key: 'module',
      dataIndex: 'module',
      className: 'default-text-modules',
      ...getColumnSearchProps('module', 'module'),
      render: k =>
        (k || '')
          .split(',')
          .map(tag => (tag ? <Tag color="blue">{tag}</Tag> : '-')),
    },
    {
      title: `Modified By ${isCluster ? 'Clusters' : 'Hubs'}`,
      key: isCluster ? 'modifiedByClusterCount' : 'modifiedByHubsCount',
      dataIndex: isCluster ? 'modifiedByClusterCount' : 'modifiedByHubsCount',
      className: 'default-text-modified-count',
      render: (k, row) =>
        (
          <Button
            type="link"
            disabled={!k}
            onClick={() => {
              const { modifiedByHubs, modifiedByClusters, identifier } = row;
              const overriddenHubsInfo = isCluster
                ? (modifiedByClusters || []).map(cluster => ({
                    clusterId: cluster.cluster_id,
                    updatedText: cluster.text,
                  }))
                : (modifiedByHubs || []).map(hub => ({
                    hubId: hub.hub_id,
                    updatedText: hub.text,
                  }));
              setModifiedHubsInfo(overriddenHubsInfo);
              setIdentifier(identifier);
              setModalVisible(true);
            }}
          >
            {k}
          </Button>
        ) || 0,
    },
    {
      title: 'Last Modified Date',
      key: 'modifiedOn',
      dataIndex: 'modifiedOn',
      className: 'default-text-modified-date',
      render: k => moment(k).format('LL') || '-',
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      className: 'default-text-actions',
      width: 150,
      render: (k, row) => (
        <>
          <Button
            type="link"
            disabled={isArchived}
            onClick={() => {
              const { module, identifier, defaultText: text } = row;
              setIdentifier(identifier);
              setModules(module);
              setDefaultText(text);
              setEditModal(true);
            }}
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            disabled={isArchived}
            onClick={() => {
              const { identifier } = row;
              setIdentifier(identifier);
              setShowArchiveAlert(true);
            }}
          >
            Archive
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        bodyStyle={{ background: '#fff' }}
        loading={loading}
        columns={columns}
        dataSource={data || []}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 550, x: 100 }}
      />
      <ModifiedHubsModal
        visibility={modalVisible}
        setVisibility={setModalVisible}
        data={modifiedHubsInfo}
        identifier={modalIdentifier}
        getColumnSearchProps={getColumnSearchProps}
        isCluster={isCluster}
      />
      <EditDefaultTextModal
        visibility={editModal}
        setVisibility={setEditModal}
        identifier={modalIdentifier}
        isCluster={isCluster}
        modules={modules}
        text={defaultText}
      />
      <ArchiveConfirmModal
        identifier={modalIdentifier}
        onOk={() => {
          archiveDefaultText({
            isClusterText: isCluster,
            identifier: modalIdentifier,
          });
        }}
        visible={showArchiveAlert}
        onCancel={() => {
          setShowArchiveAlert(false);
        }}
      />
    </>
  );
};

export default DefaultTextTable;
