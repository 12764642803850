import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';

import PreviewEmailTemplate from './PreviewEmailTemplate';

class PreviewTemplateModal extends PureComponent {
  render() {
    const { onCancel, previewTemplate } = this.props;

    const { title, html } = previewTemplate || {};

    return (
      <Modal
        title={title}
        closable={true}
        visible={!!html}
        wrapClassName="automated-marketing__modal--preview-email-template"
        destroyOnClose={true}
        onCancel={onCancel}
        footer={[
          <Button key="close" onClick={onCancel}>
            Close
          </Button>,
        ]}
      >
        <PreviewEmailTemplate
          previewHtml={html}
          className={'am__preview-email-template'}
        />
      </Modal>
    );
  }
}

export default PreviewTemplateModal;
