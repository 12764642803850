import React, { PureComponent, Fragment } from 'react';
import {
  find,
  findIndex,
  get,
  has,
  isEmpty,
  isEqual,
  set,
  unset,
} from 'lodash';
import {
  Collapse,
  Col,
  Row,
  Card,
  Table,
  Icon,
  Switch,
  Button,
  Divider,
  Tooltip,
} from 'antd';
import ReactJson from 'react-json-view';
import variations from '../../../constants/defaultSettingVariationConstants';
import s from '../index.module.sass';

const { Panel } = Collapse;

class Features extends PureComponent {
  handleFeatureSwitch = (checked, variationKey, featureKey) => {
    const {
      settings,
      settingsFeatureChange = {},
      setAllSettings,
      setAffectedFeature,
      openHubsModal,
    } = this.props;
    const variationSettings = get(settings, `${variationKey}`, {});
    const variationChange = get(settingsFeatureChange, `${variationKey}`, {});
    if (!variationChange[featureKey]) {
      variationChange[featureKey] = {};
    }
    set(settingsFeatureChange, `${variationKey}.${featureKey}.enabled`, true);
    set(
      variationSettings,
      `settings.moduleSettings.${featureKey}.enabled`,
      checked
    );
    setAllSettings({ ...settings, [variationKey]: variationSettings });
    setAffectedFeature({ ...settingsFeatureChange });
    this.setSettingsUpdatedFlag();
    openHubsModal(
      { key: variationKey },
      false,
      featureKey,
      settingsFeatureChange
    );
  };

  updateSettingsFeatureChange = (
    _updatedModuleSettings,
    settingsClone,
    variationKey
  ) => {
    const { moduleSettings: updatedModuleSettings } = _updatedModuleSettings;
    const {
      settingsFeatureChange = {},
      setAffectedFeature,
      openHubsModal,
    } = this.props;
    const moduleSettingsClone = get(
      settingsClone,
      `${variationKey}.settings.moduleSettings`,
      {}
    );
    Object.keys(updatedModuleSettings).forEach(featureKey => {
      const featureSettings = updatedModuleSettings[featureKey];
      if (typeof featureSettings === 'object') {
        const variationChange = get(
          settingsFeatureChange,
          `${variationKey}`,
          {}
        );
        if (!variationChange[featureKey]) {
          variationChange[featureKey] = {};
          set(settingsFeatureChange, `${variationKey}`, variationChange);
        }
        Object.keys(featureSettings).forEach(featureSettingKey => {
          if (
            featureSettings[featureSettingKey] !==
            get(moduleSettingsClone, `${featureKey}.${featureSettingKey}`)
          ) {
            set(
              settingsFeatureChange,
              `${variationKey}.${featureKey}.${featureSettingKey}`,
              true
            );
          } else {
            unset(
              settingsFeatureChange,
              `${variationKey}.${featureKey}.${featureSettingKey}`
            );
          }
        });
        // Prune removed settings
        Object.keys(variationChange[featureKey]).forEach(featureSettingKey => {
          if (
            !has(updatedModuleSettings, `${featureKey}.${featureSettingKey}`)
          ) {
            unset(
              settingsFeatureChange,
              `${variationKey}.${featureKey}.${featureSettingKey}`
            );
          }
        });
        // Remove Empty Object
        if (isEmpty(variationChange[featureKey]))
          unset(settingsFeatureChange, `${variationKey}.${featureKey}`);
      }
      if (get(settingsFeatureChange, `${variationKey}.${featureKey}`))
        openHubsModal(
          { key: variationKey },
          false,
          featureKey,
          settingsFeatureChange
        );
    });
    setAffectedFeature({ ...settingsFeatureChange });
  };

  handleJsonEdit = (updatedModuleSettings, variationKey) => {
    const { settings, setAllSettings, settingsClone } = this.props;
    const variationSettings = get(settings, `${variationKey}`, {});
    set(variationSettings, `settings`, updatedModuleSettings);
    setAllSettings({ ...settings, [variationKey]: variationSettings });
    this.updateSettingsFeatureChange(
      updatedModuleSettings,
      settingsClone,
      variationKey
    );
    this.setSettingsUpdatedFlag();
  };

  setSettingsUpdatedFlag = () => {
    const { settings, settingsClone, setSettingsUpdated } = this.props;
    if (isEqual(settings, settingsClone)) {
      setSettingsUpdated(false);
    } else {
      setSettingsUpdated(true);
    }
  };

  getSwitchAndButton = (
    value,
    settings,
    settingsFeatureChange,
    variationKey,
    featureKey,
    affectedHubFeatures
  ) => {
    if (variationKey === 'default') {
      return (
        <Switch
          key={featureKey}
          checked={value}
          onChange={checked =>
            this.handleFeatureSwitch(checked, variationKey, featureKey)
          }
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
        />
      );
    }

    if (variationKey === 'to_be_deleted') {
      return <>No change</>;
    }

    // For all other launch groups
    if (value === null) {
      return (
        <Tooltip
          placement="right"
          title="This will override the default settings"
        >
          <Button
            key={featureKey}
            onClick={() =>
              this.handleOverride(
                settings,
                settingsFeatureChange,
                variationKey,
                featureKey
              )
            }
            size="small"
          >
            Override Default
          </Button>
        </Tooltip>
      );
    }
    return (
      <Fragment key={featureKey}>
        <Switch
          checked={value}
          onChange={checked =>
            this.handleFeatureSwitch(checked, variationKey, featureKey)
          }
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
        />
        <Divider type="vertical" />
        <Tooltip
          placement="right"
          title="This will remove all the settings for this feature from this launch group"
        >
          <Button
            onClick={() =>
              this.handleResetToDefault(
                settings,
                settingsFeatureChange,
                variationKey,
                featureKey,
                affectedHubFeatures
              )
            }
            type="danger"
            size="small"
          >
            Reset to Default
          </Button>
        </Tooltip>
      </Fragment>
    );
  };

  getAffectedHubInfo = (affectedHubFeatures = {}, variationKey, featureKey) => {
    const variationChange = get(affectedHubFeatures, `${variationKey}`, {});
    if (get(variationChange, `${featureKey}`)) {
      return (
        <Fragment key={featureKey}>
          <a
            onClick={e =>
              this.handlePanelLink(e, { key: variationKey }, true, featureKey)
            }
          >
            {' '}
            Overridden Hubs
          </a>
        </Fragment>
      );
    }
  };

  handleOverride = (
    settings,
    settingsFeatureChange = {},
    variationKey,
    featureKey
  ) => {
    const {
      setAllSettings,
      setAffectedFeature,
      features,
      openHubsModal,
    } = this.props;
    const variationSettings = get(settings, `${variationKey}`, {});
    const featureIdx = findIndex(features, { key: featureKey });
    if (featureIdx >= 0) {
      set(
        variationSettings,
        `settings.moduleSettings.${featureKey}`,
        features[featureIdx].settings
      );
    } else {
      set(
        variationSettings,
        `settings.moduleSettings.${featureKey}.enabled`,
        false
      );
    }
    const variationChange = get(settingsFeatureChange, `${variationKey}`, {});
    if (!variationChange[featureKey]) {
      variationChange[featureKey] = {};
    }
    set(settingsFeatureChange, `${variationKey}.${featureKey}.enabled`, true);
    setAllSettings({ ...settings, [variationKey]: variationSettings });
    setAffectedFeature({ ...settingsFeatureChange });
    openHubsModal(
      { key: variationKey },
      false,
      featureKey,
      settingsFeatureChange
    );
    this.setSettingsUpdatedFlag();
  };

  handleResetToDefault = (
    settings,
    settingsFeatureChange = {},
    variationKey,
    featureKey,
    affectedHubFeatures = {}
  ) => {
    const {
      setAllSettings,
      setAffectedFeature,
      setAffectedHubFeature,
    } = this.props;
    const variationSettings = get(settings, `${variationKey}`, {});
    const featureSettings = get(
      variationSettings,
      `settings.moduleSettings.${featureKey}`,
      null
    );
    const variationChange = get(settingsFeatureChange, `${variationKey}`, {});
    if (!variationChange[featureKey]) {
      variationChange[featureKey] = {};
    }
    unset(settingsFeatureChange, `${variationKey}.${featureKey}`);
    unset(affectedHubFeatures, `${variationKey}.${featureKey}`);
    if (featureSettings !== null) {
      unset(variationSettings, `settings.moduleSettings.${featureKey}`);
    }
    setAllSettings({ ...settings, [variationKey]: variationSettings });
    setAffectedFeature({ ...settingsFeatureChange });
    setAffectedHubFeature({ ...affectedHubFeatures });
    this.setSettingsUpdatedFlag();
  };

  handlePanelLink(e, variation, showOverriddenFeatures, featureKey) {
    e.stopPropagation();
    this.props.openHubsModal(variation, showOverriddenFeatures, featureKey);
  }

  countAndModal(variation, source) {
    const countArray =
      source === 'hub'
        ? this.props.groupHubsCount
        : this.props.groupClustersCount;
    const launchGroup = countArray.filter(
      obj => obj.launchgroup === variation.key
    );
    return (
      <a onClick={e => this.handlePanelLink(e, variation)}>
        {launchGroup.length && launchGroup[0].count ? launchGroup[0].count : 0}{' '}
        {source === 'hub' ? 'Hubs' : 'Clusters'}
      </a>
    );
  }

  getJsonSource = variation => {
    const { settings, features } = this.props;
    const featureSettings = get(settings, `${variation.key}.settings`, {});

    const mergedModuleSettings = Object.keys(
      featureSettings.moduleSettings || {}
    ).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          ...(find(features, { key })?.settings || {}),
          ...featureSettings.moduleSettings[key],
        },
      }),
      {}
    );

    return {
      ...featureSettings,
      moduleSettings: {
        ...mergedModuleSettings,
      },
    };
  };

  render() {
    const {
      settings,
      settingsFeatureChange,
      affectedHubFeatures,
      loading,
      styleObj,
      features,
      source,
    } = this.props;
    const columns = [
      { title: 'Feature Name', dataIndex: 'name', key: 'name', width: '40%' },
      {
        title: 'Enabled',
        dataIndex: 'action',
        key: 'action',
        width: '40%',
      },
      {
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        width: '20%',
      },
    ];
    return (
      <Card
        title="Features Settings"
        loading={loading}
        className={styleObj.card}
      >
        {variations.map(variation => {
          const jsonSource = this.getJsonSource(variation);
          const data = [];
          return (
            <div className={s.collapse} key={variation.key}>
              <Collapse defaultActiveKey={['default']}>
                <Panel
                  header={variation.name}
                  key={variation.key}
                  extra={
                    variation.key !== 'default'
                      ? this.countAndModal(variation, source)
                      : null
                  }
                >
                  {features.map(feature => {
                    data.push({
                      ...feature,
                      action: this.getSwitchAndButton(
                        get(
                          settings,
                          `${variation.key}.settings.moduleSettings.${
                            feature.key
                          }.enabled`,
                          null
                        ),
                        settings,
                        settingsFeatureChange,
                        variation.key,
                        feature.key,
                        affectedHubFeatures
                      ),
                      info: this.getAffectedHubInfo(
                        affectedHubFeatures,
                        variation.key,
                        feature.key
                      ),
                    });
                  })}
                  <Row>
                    <Col span={11}>
                      <Table
                        size="medium"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        bordered={false}
                        showHeader
                      />
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Card>
                        {jsonSource && (
                          <ReactJson
                            theme="bright:inverted"
                            style={{
                              overflow: 'auto',
                            }}
                            name="settings"
                            collapsed={false}
                            src={jsonSource}
                            onEdit={({ updated_src }) =>
                              this.handleJsonEdit(
                                updated_src,
                                variation.key
                              )
                            }
                            onAdd={({ updated_src }) =>
                              this.handleJsonEdit(
                                updated_src,
                                variation.key
                              )
                            }
                            onDelete={({ updated_src }) =>
                              this.handleJsonEdit(
                                updated_src,
                                variation.key
                              )
                            }
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </div>
          );
        })}
      </Card>
    );
  }
}

export default Features;
