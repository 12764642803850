const prefix = 'CMS_TOOL';

export const SET_CMS_DATA = `${prefix}/SET_CMS_DATA`;
export const SET_CMS_DATA_LOADING = `${prefix}/SET_CMS_DATA_LOADING`;
export const SET_CMS_DATA_PAGE = `${prefix}/SET_CMS_DATA_PAGE`;
export const SET_SHOW_FORM = `${prefix}/SET_SHOW_FORM`;
export const SET_FORM_DATA = `${prefix}/SET_FORM_DATA`;
export const SET_IS_FORM_DATA_CHANGED = `${prefix}/SET_IS_FORM_DATA_CHANGED`;
export const SET_IS_FORM_DATA_SAVING = `${prefix}/SET_IS_FORM_DATA_SAVING`;
export const SET_CONTENT_TYPE = `${prefix}/SET_CONTENT_TYPE`;
export const SET_IS_DELETING_CONTENT = `${prefix}/SET_IS_DELETING_CONTENT`;
export const CLEAR_CMS_DATA = `${prefix}/CLEAR_CMS_DATA`;
export const SET_CONTENT_BY_IDENTIFIER = `${prefix}/SET_CONTENT_BY_IDENTIFIER`;
export const SET_IS_FETCHING_CONTENT_BY_IDENTIFIER = `${prefix}/SET_IS_FETCHING_CONTENT_BY_IDENTIFIER`;
export const SET_EDIT_IDENTIFIER = `${prefix}/SET_EDIT_IDENTIFIER`;

// for api's
export const CREATE_CONTENT = `${prefix}/CREATE_CONTENT`;
export const EDIT_CONTENT = `${prefix}/EDIT_CONTENT`;
export const DELETE_CONTENT = `${prefix}/DELETE_CONTENT`;
export const FETCH_CONTENT = `${prefix}/FETCH_CONTENT`;
export const FETCH_CONTENT_BY_IDENTIFIER = `${prefix}/FETCH_CONTENT_BY_IDENTIFIER`;
export const FETCH_CONTENT_BY_ID = `${prefix}/FETCH_CONTENT_BY_ID`;

export const clearCMSData = () => ({
  type: CLEAR_CMS_DATA,
});

export const setCmsData = data => ({
  type: SET_CMS_DATA,
  data,
});

export const setCmsDataLoading = data => ({
  type: SET_CMS_DATA_LOADING,
  data,
});

export const setCmsDataPage = data => ({
  type: SET_CMS_DATA_PAGE,
  data,
});

export const setShowForm = data => ({
  type: SET_SHOW_FORM,
  data,
});

export const setFormData = data => ({
  type: SET_FORM_DATA,
  data,
});

export const setContentType = data => ({
  type: SET_CONTENT_TYPE,
  data,
});

export const setIsFormDataSaving = data => ({
  type: SET_IS_FORM_DATA_SAVING,
  data,
});

export const setIsFormDataChanged = data => ({
  type: SET_IS_FORM_DATA_CHANGED,
  data,
});

export const createContent = data => ({
  type: CREATE_CONTENT,
  data,
});

export const editContent = data => ({
  type: EDIT_CONTENT,
  data,
});

export const deleteContent = data => ({
  type: DELETE_CONTENT,
  data,
});

export const setIsDeletingContent = data => ({
  type: SET_IS_DELETING_CONTENT,
  data,
});

export const fetchContent = data => ({
  type: FETCH_CONTENT,
  data,
});

export const fetchContentByIdentifier = data => ({
  type: FETCH_CONTENT_BY_IDENTIFIER,
  data,
});

export const fetchContentById = data => ({
  type: FETCH_CONTENT_BY_ID,
  data,
});

export const setIsFetchingContentByIdentifier = data => ({
  type: SET_IS_FETCHING_CONTENT_BY_IDENTIFIER,
  data,
});

export const setContentByIdentifier = data => ({
  type: SET_CONTENT_BY_IDENTIFIER,
  data,
});

export const setEditIdentifier = data => ({
  type: SET_EDIT_IDENTIFIER,
  data,
});

// selectors
export const formDataSelector = ({ cmsTool }) => cmsTool.formData;
export const cmsContentTypeSelector = ({ cmsTool }) => cmsTool.contentType;
export const cmsDataPageSelector = ({ cmsTool }) => cmsTool.cmsDataPage;
