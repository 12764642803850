import { takeEvery } from 'redux-saga/effects';

import {
  FETCH_REQUESTED_HUBS_FOR_DELETION,
  DELETE_REQUESTED_HUBS_BY_ID,
  APPROVE_REQUESTED_HUBS_BY_ID,
} from '../actions';

import {
  fetchRequestedDeletedHubsListSagas,
  deleteRequestedHubsByIdSagas,
  approveOrUnapproveRequestedHubsByIdSagas,
} from './sagas';

export default [
  takeEvery(
    FETCH_REQUESTED_HUBS_FOR_DELETION,
    fetchRequestedDeletedHubsListSagas
  ),
  takeEvery(DELETE_REQUESTED_HUBS_BY_ID, deleteRequestedHubsByIdSagas),
  takeEvery(
    APPROVE_REQUESTED_HUBS_BY_ID,
    approveOrUnapproveRequestedHubsByIdSagas
  ),
];
