import React from 'react';
import { Button, Select } from 'antd';

const { Option } = Select;

const AddTemplate = ({
  item,
  addTemplate = {},
  templateData = {},
  handleAddTemplate,
  handleSelect,
  selectedTemplate = [],
}) => {
  return (
    <React.Fragment>
      {!addTemplate[item.id] && (
        <Button size="small" onClick={handleAddTemplate(item.id, true)} disabled={!templateData[item.id]}>
          Add Templates
        </Button>
      )}
      {addTemplate && addTemplate[item.id] && (
        <Select
          size="small"
          style={{ width: '112px' }}
          autoFocus
          placeholder={'Type the template name'}
          onBlur={handleAddTemplate(item.id, false)}
          getPopupContainer={node => node.parentNode}
          onSelect={handleSelect(item)}
          showSearch
          open={addTemplate[item.id]}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {templateData[item.id].map(temp => (
            <Option key={temp.id} disabled={selectedTemplate.indexOf(temp.id) > -1}>{temp.name}</Option>
          ))}
        </Select>
      )}
    </React.Fragment>
  );
};

export default AddTemplate;
