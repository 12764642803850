export const GET_PROCESS_HISTORIES = 'GET_PROCESS_HISTORIES';
export const SET_PROCESS_HISTORIES = 'SET_PROCESS_HISTORIES';
export const RUN_CRON_JOB = 'RUN_CRON_JOB';
export const SET_CRON_JOB_LOADING = 'SET_CRON_JOB_LOADING';
export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';

export const getProcessHistoriesAction = data => ({
  type: GET_PROCESS_HISTORIES,
  data,
});

export const setProcessHistoriesAction = data => ({
  type: SET_PROCESS_HISTORIES,
  data,
});

export const runCronJobAction = data => ({
  type: RUN_CRON_JOB,
  data,
});

export const setCronJobLoadingAction = data => ({
  type: SET_CRON_JOB_LOADING,
  data,
});

export const setCurrentModalAction = data => ({
  type: SET_CURRENT_MODAL,
  data,
});

export const hubUrlsSelector = ({ processAlertService }) =>
  processAlertService.hubUrls;
