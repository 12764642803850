import React from 'react';
import { map } from 'lodash';
import { Popover } from 'antd';

const UserData = ({ id, data }) => {
  if (!data) {
    return <span>{id}</span>;
  }
  const userName = data.name;
  let whString = '';
  data.workHistory.forEach(wh => {
    if (wh.role) {
      whString += `- ${wh.role} at ${wh.companyTitle}. `;
    } else {
      whString += `- ${wh.companyTitle}\n`;
    }
  });

  let edString = '';
  data.schools.forEach(wh => {
    const majors = wh.majors.join(', ');
    const minors = wh.minors.join(', ');
    const degree = wh.degreeType;
    if (majors || minors || degree) {
      edString += `- ${majors} ${minors} ${degree}`;
    }
  });
  const industries = map(
    data?.standardData?.['job-industries']?.selectedOptions,
    'optionTitle'
  ).join('; ');
  const helpTopics = map(
    data?.standardData?.['help-topics']?.selectedOptions,
    'optionTitle'
  ).join('; ');
  const content = (
    <div>
      <div>
        <strong>Headline:</strong> {data.headline}
      </div>
      <div>
        <strong>WorkHistory:</strong> {whString}
      </div>
      <div>
        <strong>Education:</strong> {edString}
      </div>
      <div>
        <strong>Standard Industries:</strong> {industries}
      </div>
      <div>
        <strong>Standard help topics:</strong> {helpTopics}
      </div>
      <div>
        <strong>Location:</strong> {data.location}
      </div>
    </div>
  );
  return (
    <Popover content={content} title={`${userName} info`}>
      <span>{id}</span>
    </Popover>
  );
};

export default UserData;
