/* eslint-disable no-shadow */
import { takeLatest, put, call } from 'redux-saga/effects';
import { get, uniqBy, orderBy, isEmpty } from 'lodash';
import {
  FETCH_CLUSTER_HUBS,
  SET_CLUSTERS,
  SET_HUBS,
  SET_CLUSTER_HUB_LOADING,
  SET_CLUSTER_HUBS,
  FETCH_CLUSTER_HUBS_FOR_HOME,
  OPEN_HUB,
  CLUSTER_HUBS_LOADING,
  SET_CLUSTER_HUB_OPENING,
} from '../actions/clusterHubs';
import { POPUP_ALERT } from '../actions/alert';
import { fetchClusterHubsApi, openHubAPI, cluster, hub } from '../api';

function* fetchClusterHubSaga() {
  try {
    yield put({ type: SET_CLUSTER_HUB_LOADING, data: true });
    const { data } = yield call(fetchClusterHubsApi);
    if (data && data.length) {
      const hubs = data.map(item => item.hub);
      const clusters = uniqBy(
        data.map(item => item.cluster),
        'id'
      );
      yield put({ type: SET_CLUSTERS, data: clusters });
      yield put({ type: SET_HUBS, data: hubs });
    }
    yield put({ type: SET_CLUSTER_HUB_LOADING, data: false });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching hubs' },
    });
  }
}

export function* clusterHubsForHome() {
  try {
    yield put({ type: CLUSTER_HUBS_LOADING, data: true });
    let hubs = yield call(hub.getAll, {
      fields: [
        'id',
        'name',
        'identifier',
        'subdomain',
        'domain',
        'sfAccountId',
        'isProduction',
        'deleteRequestedAt',
      ],
      isHubCreationPending: true,
    });
    let clusters = yield call(cluster.getAll, {
      fields: ['id', 'name', 'domain', 'subdomain'],
    });
    hubs = hubs.data;
    clusters = clusters.data;
    hubs = hubs.map(hub => {
      const clusterId =
        get(hub.clusters, '0.cluster') || get(hub.clusters, '0');
      const clusterHubId = get(hub.clusters, '0.id');
      return {
        clusterId,
        clusterHubId,
        id: hub.id,
        identifier: hub.identifier,
        name: hub.name,
        domain: hub.domain,
        subDomain: hub.subdomain,
        isHubCreationPending: hub.isHubCreationPending,
        sfAccountId: hub.sfAccountId,
        isProduction: hub.isProduction,
        deleteRequestedAt: hub.deleteRequestedAt,
      };
    });

    clusters = clusters
      .map(cluster => {
        const hubsInCluster = hubs.filter(hub => hub.clusterId === cluster.id);
        // eslint-disable-next-line no-param-reassign
        cluster.hubs = hubsInCluster;
        return cluster;
      })
      .filter(cluster => !isEmpty(cluster.hubs));
    clusters = orderBy(clusters, 'id', 'desc');
    yield put({ type: SET_CLUSTER_HUBS, data: clusters });
    yield put({ type: CLUSTER_HUBS_LOADING, data: false });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching hubs' },
    });
  }
}

function* openHubSaga({ data }) {
  try {
    yield put({ type: SET_CLUSTER_HUB_OPENING, data: data.clusterHub });
    const res = yield call(openHubAPI, data);
    if (res.data.link) {
      window.open(`https://${res.data.link}`, '_blank');
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error Logging in to hub' },
      });
    }
  } catch (e) {
    if (get(e, 'response.data.error') === 'not allowed') {
      yield put({
        type: POPUP_ALERT,
        data: {
          type: 'error',
          message:
            'You currently do not have access to this hub. Please contact Ela Provost if you think this is a mistake.',
        },
      });
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error Logging in to hub' },
      });
    }
    let errMsg = 'Error Logging in to hub';
    if (get(e, 'response.data.error')) {
      errMsg = get(e, 'response.data.error');
    }
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: errMsg },
    });
  }
  yield put({ type: SET_CLUSTER_HUB_OPENING, data: null });
}

const sagas = [
  takeLatest(FETCH_CLUSTER_HUBS, fetchClusterHubSaga),
  takeLatest(FETCH_CLUSTER_HUBS_FOR_HOME, clusterHubsForHome),
  takeLatest(OPEN_HUB, openHubSaga),
];

export default sagas;
