import { isEmpty } from 'lodash';
import { isValidUrl } from '../../utils/helpers';

// defines the order of fields
export const fieldsOrder = [
  'title',
  'description',
  'imageUrl',
  'medianSalaryLow',
  'medianSalaryHigh',
  'highDemand',
  'jobResponsibilities',
  'dayInLifeVideos',
  'twitterAccounts',
  'resources',
  'skillsAbilities',
  'educationalRequirements',
  'personalities',
  'occupations',
  'alternativeTitles',
  'jobFunction',
  'standard-data-job-industries', // auxiliaryDataFields
  'standard-data-areas-of-study', // auxiliaryDataFields
  'standard-data-hobbies', // auxiliaryDataFields
  'standard-data-passion', // auxiliaryDataFields
  'tags',
  'version',
];

export const columnsToDrop = [
  'abilities',
  'skillsRequired',
  'createdAt',
  'deletedAt',
  'updatedAt',
  'onetOccupationId',
  'key',
  'slug',
  'standardDataRelationships',
  'id',
  'isActive',
  'level',
];

export const columnsToShowAtListingPage = [
  'id',
  'title',
  'version',
  'isActive',
];

export const isColumnDataRequired = col => {
  const columnVsRequired = {
    title: true,
    description: true,
    imageUrl: true,
    medianSalaryLow: true,
    medianSalaryHigh: true,
    highDemand: false,
    educationalRequirements: true,
    version: true,
    isActive: false,
    // abilities: true,
    jobFunction: true,
    jobResponsibilities: true,
    personalities: false,
    // skillsRequired: true,
    skillsAbilities: true,
    alternativeTitles: false,
    dayInLifeVideos: false,
  };

  return !!columnVsRequired[col];
};

const formatDataByType = (data, type) => {
  switch (type) {
    case 'json':
    case 'alternativeTitles':
    case 'dayInLifeVideos':
    case 'twitterAccounts':
    case 'tags':
      if (typeof data === 'string') return JSON.parse(data);
      return data;

    case 'string':
    case 'number':
    case 'boolean':
    default:
      return data;
  }
};

export const shapeCareerPathChanges = ({
  careerPathData,
  standardDataForCareerPaths,
  careerPathsColumns,
}) => {
  // format careerPath
  const columnVsType = Object.entries(careerPathsColumns).reduce(
    (pV, [columnTitle, columnDetail]) => ({
      ...pV,
      [columnTitle]: columnDetail.type,
    }),
    {}
  );

  const careerPathChanges = Object.entries(careerPathData).reduce(
    (pV, [key, val]) => ({
      ...pV,
      [key]: formatDataByType(val, columnVsType[key] || key),
    }),
    {}
  );

  careerPathChanges.jobFunction = careerPathChanges.jobFunction?.filter(j => j);

  const appendHttps = url => `https://${url}`;

  if (careerPathChanges.imageUrl.startsWith('www'))
    careerPathChanges.imageUrl = appendHttps(careerPathChanges.imageUrl);

  careerPathChanges.dayInLifeVideos = careerPathChanges.dayInLifeVideos.map(v =>
    v.startsWith('www') ? appendHttps(v) : v
  );

  careerPathChanges.resources = careerPathChanges.resources.map(resource =>
    resource.startsWith('www') ? appendHttps(resource) : resource
  );

  careerPathChanges.twitterAccounts = careerPathChanges.twitterAccounts.map(
    twitterAccountURL =>
      twitterAccountURL.startsWith('www')
        ? appendHttps(twitterAccountURL)
        : twitterAccountURL
  );

  // format standardDataRelationships
  const standardDataRelationships = [];
  const standardDataSlugVsId = Object.values(standardDataForCareerPaths).reduce(
    (pV, cV) => ({ ...pV, [cV.slug]: cV.data_set_id }),
    {}
  );
  const slugs = ['passion', 'job-industries', 'areas-of-study', 'hobbies'];

  slugs.forEach(slug => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 5; i++) {
      const optionKey = `${slug}-option-${i}`;
      const optionValue = careerPathChanges[optionKey]?.key;

      if (optionValue) {
        standardDataRelationships.push({
          standardDataField: standardDataSlugVsId[slug],
          standardDataFieldOption: optionValue,
        });
      }
      delete careerPathChanges[optionKey];
    }
  });

  careerPathChanges.occupations = parseString(careerPathChanges.occupations);

  return {
    ...careerPathChanges,
    ...(!isEmpty(standardDataRelationships) && { standardDataRelationships }),
  };
};

export const getEmptyDataForCareerPathKey = ({ type }) => {
  switch (type) {
    case 'json':
      return [];

    case 'string':
      return '';

    case 'number':
      return 0;

    case 'boolean':
    case 'highDemand':
      return false;

    default:
      return undefined;
  }
};

export const getMinValueForInputNumber = (key, minVal = null) => {
  if (key === 'level') return 1;
  if (key === 'medianSalaryHigh' || key === 'medianSalaryLow') return minVal;
  return -Infinity;
};

export const getMaxValueForInputNumber = key => {
  if (key === 'level') return 5;
  if (key === 'medianSalaryHigh' || key === 'medianSalaryLow')
    return 1000000000;
  return Infinity;
};

export const getTooltipForKey = key => {
  const tooltips = {
    title: 'Ensure the title is in plural form eg “Marketing Managers”',
    imageUrl:
      'Upload the thumbnail at the File Uploader under Tools on the superadmin panel. If adding multiple career paths, it might be useful to upload the thumbnails for all careers in one go and then copy-paste the links for each here.',
    description: 'Ideal text length is 50-75 words',
    medianSalaryLow: 'Enter absolute value in $',
    medianSalaryHigh: 'Enter absolute value in $',
    highDemand: 'Is this career path in high demand in the industry today?',
    dayInLifeVideos: 'Ensure the link is public and not broken',
    educationalRequirements:
      'Choose the educational level from the dropdown and fill in the corresponding percent values.',
    jobResponsibilities:
      'Each row forms a new bullet point on the final career path page',
    level:
      'Job seniority level from 1 (junior) to 5 (senior). For eg: 1 = Web Developers, while 5 = Chief Technology Officer',
    version:
      'For internal purposes only. Indicates the batch of career path set. A set of career paths added at once will share a common version.',
    alternativeTitles:
      'Please put one alternate title in one row. This is reflected under the “Common job and career titles” section in the career page, and also impacts a few backend logics related to the career paths tool.',
    associations: 'Choose from the list',
    occupations:
      'Link PathwayU Career Titles to parent Career Paths from here.',
  };
  const listInputs = ['skillsAbilities', 'abilities', 'personalities'];
  if (listInputs.includes(key))
    return 'Choose from the list or create a new one';
  return tooltips[key];
};

export const getCustomLabel = key => {
  const labels = {
    educationalRequirements: 'Educational Level',
    imageUrl: 'Thumbnail Url',
    skillsAbilities: 'Skills & Abilities',
    dayInLifeVideos: 'Day in the life videos',
    alternativeTitles: 'Alternative Job Titles',
    twitterAccounts: 'Twitter Accounts to follow (links)',
    resources: 'What’s happening around the world',
    occupations: 'Career Titles',
  };
  return labels[key];
};

export const parseString = str =>
  typeof str === 'string' ? JSON.parse(str) : str;

export const getErrorMessage = (defaultMsg, e) => {
  if (e?.response?.data?.message) {
    let msg = e.response.data.message.split('because').slice(-1)[0];
    msg = msg.replace(/[^a-zA-Z ]/g, '');
    if (msg.trim() === 'must be a valid uri') msg = `dayInLifeVideos ${msg}`;
    return msg;
  }
  return defaultMsg;
};

export const formatFieldName = str => {
  const custom = getCustomLabel(str);
  if (custom) return custom;
  return (str.charAt(0).toUpperCase() + str.slice(1))
    .split(/(?=[A-Z])/)
    .join(' ');
};

export const validateEducationalReq = (rule, val, callback) => {
  try {
    const v = parseString(val).reduce((prev, curr) => prev + curr.value, 0);
    if (v > 100)
      throw new Error(
        `"${formatFieldName(rule.field)}" total cannot be greater than 100%`
      );
    callback();
  } catch (e) {
    callback(e);
  }
};

export const isStringValid = (str, name = '') => {
  if (str.match(/^[^a-zA-Z0-9]+$/) || str.match(/^[0-9]+$/))
    throw new Error(
      `"${formatFieldName(name)}" field cannot contain only symbols or numbers`
    );
};

export const validateCustom = (rule, val, callback) => {
  try {
    const arr = parseString(val);
    if (arr.length === 0 && isColumnDataRequired(rule.field))
      throw new Error(`"${formatFieldName(rule.field)}" cannot be empty`);
    if (new Set(arr).size !== arr.length)
      throw new Error(
        `"${formatFieldName(rule.field)}" cannot contain duplicate values`
      );
    arr.forEach(str => {
      isStringValid(str, rule.field);
    });
    callback();
  } catch (e) {
    callback(e);
  }
};

export const validateURLs = (rule, val, callback) => {
  try {
    const arr = parseString(val);
    arr.forEach(url => {
      if (!isValidUrl(url)) {
        throw new Error(
          `"${formatFieldName(rule.field)}" value should be a valid URL`
        );
      }
    });
    validateCustom(rule, val, callback);
  } catch (e) {
    callback(e);
  }
};
