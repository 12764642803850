/* eslint-disable no-console */
import { call, put, select, race, take, delay, all } from 'redux-saga/effects';
import { get, isNil, isEmpty } from 'lodash';
import { message } from 'antd';

import { uniq } from 'lodash/array';
import { POPUP_ALERT } from '../../../actions/alert';

import {
  FETCH_JOB_STATUS,
  getJobId,
  getSelectedHubId,
  handleActionInitVerificationModalVisible,
  handleActionSetBaseConfig,
  handleActionSetBaseConfigDiff,
  handleActionSetBaseConfigHubOptions,
  handleActionSetBaseConfigLoading,
  handleActionSetBaseConfigSaving,
  handleActionSetClusterHubForVerification,
  handleActionSetClusterModules,
  handleActionSetFiltersEnabled,
  handleActionSetHubModules,
  handleActionSetHubsData,
  handleActionSetJobId,
  handleActionSetJobStatusData,
  handleActionSetJobStatusModalVisible,
  handleActionSetListLoading,
  handleActionSetSelectedHubId,
  handleActionSetStandardMigrationStarting,
  handleActionSetTotal,
  handleActionSetVerificationStatus,
  handleActionVerificationDataLoading,
  setApiLoading,
  setPgStandardFields,
  setSelectedHubCustomFields,
  STOP_FETCH_JOB_STATUS,
  SET_STANDARD_FIELDS_UPDATED,
  handleActionSetAllowedUserTypes,
} from '../actions';

import {
  fetchBaseConfig,
  fetchHubsList,
  fetchMigrationJobStatus,
  saveBaseConfig,
  startStandardMigration,
  verifyAndGetData,
  hub as hubApi,
  customField as getPgStandardFields,
  saveMigrationConfig,
  triggerMigration,
  updateStandardDataMapping,
} from '../../../api';

export function* fetchHubsSaga({ data = {} }) {
  try {
    yield put(handleActionSetListLoading(true));
    const { searchText = '', platformType = '', page = 1 } = data;
    if (searchText.length || platformType.length) {
      yield put(handleActionSetFiltersEnabled(true));
    }
    const { data: response } = yield call(fetchHubsList, {
      page,
      searchText,
      platformType,
    });
    yield put(handleActionSetHubsData(response.data));
    yield put(handleActionSetTotal(response.count));
    yield put(handleActionSetListLoading(false));
  } catch (e) {
    console.error('Error in fetchHubsSaga ---', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching hubs. Please try again.',
      },
    });
    yield put(handleActionSetListLoading(false));
  }
}

export function* fetchBaseConfigSaga({ data = {} }) {
  try {
    yield put(handleActionSetBaseConfigLoading(true));
    const { fetchHubOptions = false } = data;
    const { data: response } = yield call(fetchBaseConfig, { fetchHubOptions });
    yield put(handleActionSetBaseConfig(response.config || {}));
    yield put(handleActionSetBaseConfigDiff(response.diff || {}));
    yield put(
      handleActionSetSelectedHubId(get(response, 'config.hub', undefined))
    );
    yield put(handleActionSetBaseConfigHubOptions(response.hubOptions || []));
    yield put(handleActionSetBaseConfigLoading(false));
  } catch (e) {
    console.error('Error in fetchBaseConfig ---', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching base config. Please try again.',
      },
    });
    yield put(handleActionSetBaseConfigLoading(false));
  }
}

export function* saveBaseConfigSaga() {
  try {
    yield put(handleActionSetBaseConfigSaving(true));
    const hubId = yield select(getSelectedHubId);

    if (isNil(hubId)) {
      throw new Error('Please select a hub id before saving the config');
    }

    const { data } = yield call(saveBaseConfig, { hubId });
    yield put(handleActionSetBaseConfig(data.config || {}));
    yield put(handleActionSetBaseConfigSaving(false));
  } catch (e) {
    console.error('Error in saveBaseConfigSaga ---', e);
    if (get(e, 'response.data.error') === 'not allowed') {
      yield put({
        type: POPUP_ALERT,
        data: {
          type: 'error',
          message:
            'You currently do not have access to this hub. Please contact Ela Provost if you think this is a mistake.',
        },
      });
    } else {
      yield put({
        type: POPUP_ALERT,
        data: {
          type: 'error',
          message: 'Error saving base config. Please try again.',
        },
      });
    }
    yield put(handleActionSetBaseConfigSaving(false));
  }
}

export function* verifyAndGetDataSaga({ data = {} }) {
  try {
    yield put(handleActionVerificationDataLoading(true));
    yield put(handleActionInitVerificationModalVisible(true));
    const { hubId, clusterId } = data;
    const { data: result } = yield call(verifyAndGetData, { hubId, clusterId });
    const {
      status,
      clusterHub = {},
      hubModules = [],
      clusterModules = [],
      allowedUserTypes = [],
    } = result;
    yield all([
      put(handleActionSetVerificationStatus(status)),
      put(handleActionSetClusterHubForVerification(clusterHub)),
      put(handleActionSetHubModules(hubModules)),
      put(handleActionSetClusterModules(clusterModules)),
      put(handleActionSetAllowedUserTypes(allowedUserTypes)),
    ]);
    yield put(handleActionVerificationDataLoading(false));
  } catch (e) {
    console.error('Error in verifyAndGetDataSaga ---', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error getting data. Please try again.',
      },
    });
    yield put(handleActionVerificationDataLoading(false));
  }
}

export function* startStandardMigrationSaga({ data = {} }) {
  try {
    yield put(handleActionSetStandardMigrationStarting(true));
    const {
      preserveTitles = false,
      platformType,
      launchGroup,
      hubId,
      clusterId,
      settings,
    } = data;
    const { data: result } = yield call(startStandardMigration, {
      preserveTitles,
      platformType,
      launchGroup,
      hubId,
      clusterId,
      settings,
    });
    yield put(handleActionSetJobId(result.id));
    yield put(handleActionSetJobStatusModalVisible(true));
    yield put(handleActionSetStandardMigrationStarting(false));
    yield put(handleActionInitVerificationModalVisible(false));
    yield put({ type: FETCH_JOB_STATUS });
  } catch (e) {
    console.error('Error in startStandardMigrationSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error migrating to V2 platform. Please try again.',
      },
    });
    yield put(handleActionSetStandardMigrationStarting(false));
  }
}

function* fetchJobStatusPoll() {
  while (true) {
    try {
      const jobId = yield select(getJobId);
      if (!jobId) yield put({ type: STOP_FETCH_JOB_STATUS });
      const { data = {} } = yield call(fetchMigrationJobStatus, { jobId });
      const { job } = data;
      yield put(handleActionSetJobStatusData(job));
      if (job?.status === 'completed' || job?.status === 'error') {
        yield put({ type: STOP_FETCH_JOB_STATUS });
      }
      yield delay(1500);
    } catch (e) {
      console.error('Error in fetchJobStatusPoll ---', e);
      yield put({ type: STOP_FETCH_JOB_STATUS });
      yield put({
        type: POPUP_ALERT,
        data: {
          type: 'error',
          message: 'Error while fetching job status. Please try again.',
        },
      });
    }
  }
}

export function* fetchJobStatusWatcher() {
  while (true) {
    yield take(FETCH_JOB_STATUS);
    yield race([call(fetchJobStatusPoll), take(STOP_FETCH_JOB_STATUS)]);
  }
}

export function* fetchHubCustomFieldsSaga({ data }) {
  yield put(setApiLoading(true));
  try {
    const { data: result } = yield call(
      hubApi.getCustomFieldsByHub,
      data.hubId
    );
    yield put(setSelectedHubCustomFields(result));
  } catch (e) {
    console.error('Error in fetchHubCustomFieldSaga --- ', e);
    message.error('Something went wrong');
  }
  yield put(setApiLoading(false));
}

export function* fetchPgStandardFieldsSaga() {
  yield put(setApiLoading(true));
  try {
    const { data: result } = yield call(getPgStandardFields);
    yield put(setPgStandardFields(result));
  } catch (e) {
    console.error('Error in fetchPgStandardFieldsSaga --- ', e);
    message.error('Something went wrong');
  }
  yield put(setApiLoading(false));
}

export function* saveCustomFieldMigrationConfigSaga({ data }) {
  yield put(setApiLoading(true));
  try {
    const fieldsToUpdate = {};
    const { customFieldsMap, hubId, hubCustomFields } = data;
    const errorData = Object.keys(customFieldsMap).filter(
      el => !Number(customFieldsMap[el])
    );
    if (errorData && errorData.length) {
      yield put(setApiLoading(false));
      message.error(
        'Standard field mapping missing for one or more custom field'
      );
      return;
    }

    const mappedStandardFields = Object.keys(customFieldsMap).map(
      el => customFieldsMap[el]
    );
    if (uniq(mappedStandardFields).length !== mappedStandardFields.length) {
      yield put(setApiLoading(false));
      message.error(
        'More than on one custom field mapped to same standard field.'
      );
      return;
    }
    const alreadyMappedFields = hubCustomFields.filter(
      el => !isEmpty(el.migrationConfig)
    );

    if (alreadyMappedFields.length) {
      alreadyMappedFields.forEach(el => {
        if (!customFieldsMap[el.id]) {
          fieldsToUpdate[el.id] = { type: 'remove' };
        } else if (el.migrationConfig.status === 'succeed') {
          delete customFieldsMap[el.id];
        }
      });
    }
    if (isEmpty(customFieldsMap)) {
      yield put(setApiLoading(false));
      message.success('Config Saved');
      data.callback && data.callback();
      return;
    }
    Object.keys(customFieldsMap).forEach(el => {
      fieldsToUpdate[el] = {
        type: 'add',
        to: customFieldsMap[el],
      };
    });
    yield call(saveMigrationConfig, { customFieldsMap: fieldsToUpdate, hubId });
    data.callback && data.callback();
    message.success('Config Saved');
  } catch (e) {
    console.error('Error in saveCustomFieldMigrationConfig --- ', e);
    message.error('Something went wrong');
  }
  yield put(setApiLoading(false));
}

export function* triggerMigrationSaga({ data }) {
  yield put(setApiLoading(true));
  try {
    const { hubId, createOtherStandardFields } = data;
    yield call(triggerMigration, { hubId, createOtherStandardFields });
    data.callback && data.callback();
    message.success('Migration Initiated');
  } catch (e) {
    console.error('Error in triggerMigrationSaga ----', e);
    message.error('Something went wrong');
  }
  yield put(setApiLoading(false));
}

export function* openCustomFieldPageSaga({ data }) {
  try {
    const { customFieldId, hubId } = data;
    const page = `site-admin/initial-setup/custom-field/fields/view/${customFieldId}`;
    const { data: domain } = yield call(hubApi.getHubDomain, hubId);
    window.open(`${domain}${page}`, '_blank');
  } catch (e) {
    console.error('Error in openCustomFieldPageSaga ----', e);
    message.error('Something went wrong');
  }
}

export function* updateStandardDataMappingSaga({ data }) {
  try {
    yield put(setApiLoading(true));
    yield call(updateStandardDataMapping, {
      hubId: data.hubId,
      clusterId: data.clusterId,
    });
    yield put(setApiLoading(false));
    yield put({ type: SET_STANDARD_FIELDS_UPDATED });
    message.success('Standard fields added successfully!');
  } catch (e) {
    yield put(setApiLoading(false));
    console.error('Error in updateStandardDataMappingSaga ----', e);
    message.error(e.message || 'Something went wrong');
  }
}
