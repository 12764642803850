export const FETCH_ALL_HUBS = 'esLogstash/FETCH_ALL_HUBS';
export const SET_ES_LOGSTASH = 'esLogstash/SET_ES_LOGSTASH';
export const FETCH_LOGSTASH_STATUS = 'esLogstash/FETCH_LOGSTASH_STATUS';
export const SET_LOGSTASH_STATUS = 'esLogstash/SET_LOGSTASH_STATUS';
export const RUN_LOGSTASH = 'esLogstash/RUN_LOGSTASH';
export const STOP_LOGSTASH = 'esLogstash/STOP_LOGSTASH';
export const POLL_LOGSTASH_STATUS = 'esLogstash/POLL_LOGSTASH_STATUS';

export const fetchAllHubs = (data) => ({ type: FETCH_ALL_HUBS, data });
export const setEsLogstash = (data) => ({ type: SET_ES_LOGSTASH, data });
export const fetchLogstashStatus = (data) => ({ type: FETCH_LOGSTASH_STATUS, data });
export const setLogstashStatus = (data) => ({ type: SET_LOGSTASH_STATUS, data });
export const runLogstash = (data) => ({ type: RUN_LOGSTASH, data });
export const stopLogstash = data => ({ type: STOP_LOGSTASH, data });
export const pollLogstashStatus = run => ({
  type: POLL_LOGSTASH_STATUS,
  run,
});
