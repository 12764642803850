import { call, put } from 'redux-saga/effects';
import notify from '../../../utils/notify';

import { updatingAll, updatingUsers } from '../actions';
import { POPUP_ALERT } from '../../../actions/alert'; 
import { refreshAllAPI, refreshUsersAPI } from '../../../api';

export function* refreshAllSaga() {
  try {
    yield put(updatingAll(true));
    const { status } = yield call(refreshAllAPI);
    if (status === 200) {
      notify('Cache Updated!', 'success');
    } else {
      notify('Could not update Cache!', 'error', 'Please try again.');
    }
    yield put(updatingAll(false));
  } catch (e) {
    yield put(updatingAll(false));
    console.log('Error in refreshAllSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Refreshing Cache!' },
    });
  }
}

export function* refreshUsersSaga({ data }) {
  try {
    yield put(updatingUsers(true));
    if (data.userIds === '') {
      notify('Please enter userIds!', 'error');
    } else {
      const { status } = yield call(refreshUsersAPI, data);
      if (status === 200) {
        notify('User Cache Updated!', 'success');
      }
    }
    yield put(updatingUsers(false));
  } catch (e) {
    yield put(updatingUsers(false));
    console.log('Error in refreshUsersSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Refreshing Cache!' },
    });
  }
}
