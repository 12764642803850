/* eslint-disable import/prefer-default-export */
import { useQuery } from '@tanstack/react-query';
import DefaultTextKeys from '../queryKeys';
import { listClusterTextsAPI } from '../../../api';
import { notifyError } from '../../../utils/notify';

const { listClusterText } = DefaultTextKeys;

const listClusterTexts = async ({ isArchived }) => {
  const { data } = await listClusterTextsAPI({ isArchived });
  return data;
};

export const useClusterTextList = ({ isArchived = false }) =>
  useQuery(
    [listClusterText, isArchived],
    () => listClusterTexts({ isArchived }),
    {
      retry: false,
      onError: e => {
        notifyError(e, false, 'Error fetching cluster list');
        console.log('Error in useClusterTextList --- ', e);
      },
    }
  );
