import React from 'react';
import { map } from 'lodash';

import { Col, Input, Select, InputNumber, Row } from 'antd';

const { Option } = Select;

const QueryTypes = [
  {
    value: 'cp',
    name: 'Career Path',
  },
  {
    value: 'student',
    name: 'Student',
  },
  {
    value: 'alumni',
    name: 'Alumni',
  },
  {
    value: 'faculty',
    name: 'Faculty & Staff',
  },
  {
    value: 'friend',
    name: 'Friend Of',
  },
  {
    value: 'external',
    name: 'External Jobs',
  },
  {
    value: 'internal',
    name: 'Internal Jobs',
  },
];

const RecommendationTypes = [
  ...QueryTypes,
  {
    value: 'job',
    name: 'Jobs',
  },
];

const MLRecommendations = ({ currentApi, body = {}, setBody }) => {
  // console.log({ body });
  const allBodyTypes = [
    {
      id: 'hub_id',
      api: ['recommendations', 'search'],
      placeholder: 'Hub Id',
      Component: (
        <InputNumber
          placeholder="Hub Id"
          value={body.hub_id}
          onChange={val => setBody({ hub_id: val })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'query_type',
      api: ['recommendations', 'search'],
      Component: (
        <Select
          placeholder="Query Type"
          value={body.query_type}
          onChange={val => setBody({ query_type: val })}
          allowClear
          style={{ width: '100%' }}
        >
          {map(QueryTypes, source => (
            <Option key={source.value} value={source.value}>
              {source.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      id: 'query_id',
      api: ['recommendations'],
      Component: (
        <Input
          placeholder="Query Id"
          value={body.query_id}
          onChange={e => setBody({ query_id: e.target.value })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'query',
      api: ['search'],
      Component: (
        <Input
          placeholder="Query (JSON Stringify)"
          value={body.query}
          onChange={e => setBody({ query: e.target.value })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'user_id',
      api: ['search'],
      placeholder: 'User Id',
      Component: (
        <InputNumber
          placeholder="User Id"
          value={body.user_id}
          onChange={val => setBody({ user_id: val })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'recommendation_type',
      api: ['recommendations', 'search'],
      Component: (
        <Select
          placeholder="Recommendation Type"
          value={body.recommendation_type}
          onChange={val => setBody({ recommendation_type: val })}
          allowClear
          style={{ width: '100%' }}
        >
          {map(RecommendationTypes, source => (
            <Option key={source.value} value={source.value}>
              {source.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      id: 'use_profile',
      api: ['search'],
      Component: (
        <Select
          placeholder="Use Profile"
          value={body.use_profile}
          onChange={val => setBody({ use_profile: val })}
          allowClear
          style={{ width: '100%' }}
        >
          {map(
            [
              { value: true, name: 'True' },
              { value: false, name: 'False' },
            ],
            source => (
              <Option key={source.value} value={source.value}>
                {source.name}
              </Option>
            )
          )}
        </Select>
      ),
    },
    {
      id: 'profile_weight',
      api: ['search'],
      Component: (
        <InputNumber
          placeholder="Profile Weight"
          value={body.profile_weight}
          onChange={val => setBody({ profile_weight: val })}
          allowClear
          style={{ width: '100%' }}
          min={0}
          max={1}
        />
      ),
    },
    {
      id: 'number_reccomendations',
      api: ['recommendations', 'search'],
      Component: (
        <InputNumber
          placeholder="Limit"
          value={body.number_reccomendations}
          onChange={val => setBody({ number_reccomendations: val })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'static_weight',
      api: ['recommendations', 'search'],
      Component: (
        <InputNumber
          placeholder="Static Weight"
          value={body.static_weight}
          onChange={val => setBody({ static_weight: val })}
          allowClear
          style={{ width: '100%' }}
          min={0}
          max={1}
        />
      ),
    },
    {
      id: 'threshold',
      api: ['recommendations', 'search'],
      Component: (
        <InputNumber
          placeholder="Threshold"
          value={body.threshold}
          onChange={val => setBody({ threshold: val })}
          allowClear
          style={{ width: '100%' }}
          min={0}
          max={1}
        />
      ),
    },
    {
      id: 'excluded_ids',
      api: ['search'],
      Component: (
        <Input
          placeholder="Excluded IDs (Comma separated)"
          value={body.excluded_ids}
          onChange={e => setBody({ excluded_ids: e.target.value })}
          allowClear
          style={{ width: '100%' }}
        />
      ),
    },
  ];
  const apiBodyTypes = allBodyTypes.filter(({ api }) =>
    api.includes(currentApi)
  );
  if (!apiBodyTypes.length) {
    return null;
  }

  return (
    <>
      <Row type="flex" justify="space-around" gutter={8}>
        {apiBodyTypes.slice(0, 4).map(({ Component }) => (
          <Col span={6}>{Component}</Col>
        ))}
      </Row>
      {apiBodyTypes.length > 4 ? (
        <Row
          type="flex"
          justify="space-around"
          gutter={8}
          style={{ marginTop: '16px' }}
        >
          {apiBodyTypes.slice(4, 8).map(({ Component }) => (
            <Col span={6}>{Component}</Col>
          ))}
        </Row>
      ) : null}
      {apiBodyTypes.length > 8 ? (
        <Row
          type="flex"
          justify="space-around"
          gutter={8}
          style={{ marginTop: '16px' }}
        >
          {apiBodyTypes.slice(8).map(({ Component }) => (
            <Col span={6}>{Component}</Col>
          ))}
        </Row>
      ) : null}
    </>
  );
};

export default MLRecommendations;
