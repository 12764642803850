import { takeLatest, put, call } from 'redux-saga/effects';

import { CALL_ML_SERVICE_API, setMLServiceApiResponseAction } from '../actions';

import { callMLServiceApi } from '../../../api';
import { POPUP_ALERT } from '../../../actions/alert';

const { log, error: errLog } = console;

function* callMLServiceApiSaga({ data: payload = {} }) {
  try {
    log({ payload });
    yield put(setMLServiceApiResponseAction({ loading: true }));
    const { data } = yield call(callMLServiceApi, {
      ...payload,
    });
    yield put(
      setMLServiceApiResponseAction({ loading: false, response: data })
    );
  } catch (e) {
    yield put(setMLServiceApiResponseAction({ loading: false }));
    errLog(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error getting ML Service API Response' },
    });
  }
}

const MLServiceSaga = [takeLatest(CALL_ML_SERVICE_API, callMLServiceApiSaga)];

export default MLServiceSaga;
