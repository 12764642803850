import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Button } from 'antd';
import { get } from 'lodash';
import Content from '../../component/Content';
import Features from './Features';
import HubsViewModal from './HubsViewModal';

import {
  fetchAllSettings,
  setAllSettings,
  setAffectedFeature,
  setAffectedHubFeature,
  updateLaunchGroupSettings,
  setSettingsUpdated,
  fetchLaunchGroupHubs,
  fetchGroupHubsCount,
  startDownload,
  resetToDefault,
} from './actions';

import s from './index.module.sass';

class FeaturesContainer extends PureComponent {
  state = {
    openModal: false,
    showOverriddenFeatures: false,
    launchGroup: '',
    launchGroupKey: ''
  }

  componentDidMount() {
    const { source, fetchAllSettings, fetchGroupHubsCount } = this.props;
    fetchGroupHubsCount(source)
    if (source === 'cluster') {
      fetchAllSettings({ objectType: 'cluster' });
    } else if (source === 'hub') {
      fetchAllSettings({ objectType: 'hub' });
    }
  }

  handleSave = e => {
    e.preventDefault();
    const { source, updateLaunchGroupSettings } = this.props;
    updateLaunchGroupSettings(source);
  };

  handleModal(variation, forFeatures = false, featureKey, changedFeatures) {
    const {
      source,
      fetchLaunchGroupHubs: fetchHubs,
      affectedHubFeatures,
    } = this.props;
    let { settingsFeatureChange } = this.props;
    if (changedFeatures) settingsFeatureChange = changedFeatures;
    this.setState({
      openModal: featureKey ? forFeatures : true,
      launchGroup: variation.name,
      launchGroupKey: variation.key,
      showOverriddenFeatures: forFeatures,
      featureKey,
    });
    fetchHubs({
      key: variation.key,
      source,
      affectedHubFeatures,
      affectedFeature: featureKey,
      featureObject: get(
        settingsFeatureChange,
        `${variation.key}.${featureKey}`
      ),
    });
  }

  handleDownload(source, lg) {
    this.props.startDownload({source, lg, lgName: this.state.launchGroup})
  }

  resetToDefault(source, lg, fk, filterParams) {
    const { launchGroup } = this.state;
    const { resetToDefault: resetToDefaultAPI } = this.props;
    resetToDefaultAPI({
      source,
      lg,
      lgName: launchGroup,
      fk,
      filterParams,
    });
  }

  render() {
    const {
      source,
      settings,
      settingsFeatureChange,
      affectedHubFeatures,
      settingsClone,
      modulesList,
      loading,
      setAllSettings,
      setAffectedFeature,
      setAffectedHubFeature,
      areSettingsUpdated,
      setSettingsUpdated,
      isPageUpdating,
      groupHubLoading,
      groupHubs,
      groupHubsCount,
      groupClustersCount,
    } = this.props;
    const {
      showOverriddenFeatures,
      launchGroupKey,
      featureKey,
      openModal,
      launchGroup,
    } = this.state;
    const title = source === 'cluster' ? 'Cluster Features' : 'Hub Features';
    return (
      <div>
        <PageHeader
          title={title}
          extra={[
            <Button
              key="save"
              type="primary"
              onClick={this.handleSave}
              icon="save"
              disabled={!areSettingsUpdated}
              loading={isPageUpdating}
            >
              Save
            </Button>,
          ]}
        />
        <Content>
          <Features
            source={source}
            settings={settings}
            settingsFeatureChange={settingsFeatureChange}
            affectedHubFeatures={affectedHubFeatures}
            setAffectedHubFeature={setAffectedHubFeature}
            settingsClone={settingsClone}
            loading={loading}
            styleObj={s}
            features={modulesList}
            setAllSettings={setAllSettings}
            setAffectedFeature={setAffectedFeature}
            setSettingsUpdated={setSettingsUpdated}
            openHubsModal={(
              variation,
              forFeatures,
              affectedFeature,
              changedFeatures
            ) =>
              this.handleModal(
                variation,
                forFeatures,
                affectedFeature,
                changedFeatures
              )
            }
            groupHubsCount={groupHubsCount}
            groupClustersCount={groupClustersCount}
          />
        </Content>
        {openModal &&
          <HubsViewModal
            source={source}
            showOverriddenFeatures={showOverriddenFeatures}
            affectedFeatureObject={get(
              settingsFeatureChange,
              `${launchGroupKey}.${featureKey}`
            )}
            close={() => this.setState({ openModal: false, launchGroup: '' })}
            openModal={openModal}
            download={() => this.handleDownload(source, launchGroupKey)}
            resetToDefault={filterParams => {
              this.resetToDefault(
                source,
                launchGroupKey,
                featureKey,
                filterParams
              );
              this.setState({ openModal: false });
            }}
            loading={groupHubLoading}
            launchGroupHubs={groupHubs}
            launchGroup={launchGroup}
            featureKey={featureKey}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = ({
  features: {
    settings,
    settingsFeatureChange,
    affectedHubFeatures,
    settingsClone,
    modulesList,
    loading,
    areSettingsUpdated,
    isPageUpdating,
    groupHubLoading,
    groupHubs,
    groupHubsCount,
    groupClustersCount
  },
}) => ({
  settings,
  settingsFeatureChange,
  affectedHubFeatures,
  settingsClone,
  modulesList,
  loading,
  areSettingsUpdated,
  isPageUpdating,
  groupHubLoading,
  groupHubs,
  groupHubsCount,
  groupClustersCount
});

const mapDispatchToProps = {
  fetchAllSettings,
  setAllSettings,
  setAffectedFeature,
  setAffectedHubFeature,
  updateLaunchGroupSettings,
  setSettingsUpdated,
  fetchLaunchGroupHubs,
  fetchGroupHubsCount,
  startDownload,
  resetToDefault,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturesContainer);
