import { isNull, get } from 'lodash';
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import moment from 'moment';
import {
  SAVE_BANNER_CONFIGURATION,
  FETCH_EXISTING_BANNER_CONFIGURATION,
  SET_BANNER_LIST,
  SET_BANNER_LIST_LOADING,
  FETCH_CONFIGURATION_BASED_ON_ID,
  SET_BANNER_FORM_LOADING,
  SET_HUB_BANNER_TITLE,
  SET_HUB_BANNER_ACTIVE,
  SET_HUB_BANNER_DESCRIPTION,
  SET_HUB_BANNER_CTA_TEXT,
  SET_SELECTED_HUB_ID,
  SET_SELECTED_LAUNCH_GROUP,
  SET_HUB_BANNER_COVER_MEDIA_TYPE,
  SET_HUB_BANNER_IMAGE,
  SET_HUB_BANNER_VIDEO,
  SET_HUB_BANNER_START_DATE,
  SET_HUB_BANNER_END_DATE,
  DELETE_BANNER_CONFIGURATION,
  SET_HUB_BANNER_CTA_LINK,
  SET_HUB_BANNER_LINK_OPTION,
  bannerList,
  GET_IFRAME_CODE_FROM_VIDEO_URL,
  setIframeCodeLoading,
  setLoadingAllCustomImages,
  SET_ALL_CUSTOM_IMAGES,
  GET_ALL_CUSTOM_IMAGES,
} from '../actions/configureBanner';
import history from '../history';
import { POPUP_ALERT } from '../actions/alert';
import {
  saveBannerConfigurationApi,
  fetchConfigurationApi,
  fetchConfigurationBasedOnIdApi,
  updateBannerConfigurationApi,
  deleteBannerConfigurationApi,
  getVideoIframeApi,
  getAllUsedCustomImagesApi,
} from '../api';

function* saveBannerConfigurationSaga({ data }) {
  try {
    const list = yield select(bannerList);
    let newList;
    if (data.id) {
      const {
        data: [savedBanner],
      } = yield call(updateBannerConfigurationApi, data);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Alert Updated!' },
      });
      newList = list.map(item =>
        parseInt(savedBanner.id, 10) === parseInt(item.id, 10)
          ? {
              ...savedBanner,
              key: savedBanner.id,
              startDateStr: moment(savedBanner.startDate).format('Do MMM YYYY'),
              endDateStr: moment(savedBanner.endDate).format('Do MMM YYYY'),
            }
          : item
      );
    } else {
      const { data: savedBanner } = yield call(
        saveBannerConfigurationApi,
        data
      );
      history.push(`/configure-alert-banner/edit/${savedBanner.id}`);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Alert Saved!' },
      });
      newList = [
        ...list,
        {
          ...savedBanner,
          key: savedBanner.id,
          startDateStr: moment(savedBanner.startDate).format('Do MMM YYYY'),
          endDateStr: moment(savedBanner.endDate).format('Do MMM YYYY'),
        },
      ];
    }
    yield put({ type: SET_BANNER_LIST, data: newList });
  } catch (e) {
    if (e.response) {
      const message = get(e, 'response.data.message');
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message },
      });
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error Saving Product Update Post' },
      });
    }
  }
}

function* fetchExistingConfigurationSaga() {
  try {
    yield put({ type: SET_BANNER_LIST_LOADING, data: true });
    const { data } = yield call(fetchConfigurationApi);
    const fetchedBannerList = data.map((list, index) => ({
      ...list,
      key: list.id,
      active: list.active,
      startDateStr: moment(list.startDate).format('Do MMM YYYY'),
      endDateStr: moment(list.endDate).format('Do MMM YYYY'),
    }));
    yield put({ type: SET_BANNER_LIST, data: fetchedBannerList });
    yield put({ type: SET_BANNER_LIST_LOADING, data: false });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Configuration' },
    });
  }
}

function* fetchConfigurationBasedOnIdSaga({ id }) {
  try {
    yield put({ type: SET_BANNER_FORM_LOADING, data: true });
    const { data } = yield call(fetchConfigurationBasedOnIdApi, id);
    if (data) {
      yield all([
        put({ type: SET_HUB_BANNER_ACTIVE, data: data.active }),
        put({ type: SET_HUB_BANNER_TITLE, data: data.title }),
        put({ type: SET_HUB_BANNER_DESCRIPTION, data: data.description }),
        put({ type: SET_HUB_BANNER_CTA_TEXT, data: data.cta.text }),
        put({ type: SET_HUB_BANNER_CTA_LINK, data: data.cta.link }),
        put({
          type: SET_HUB_BANNER_LINK_OPTION,
          data: data.cta.openInSameWindow,
        }),
        put({
          type: SET_SELECTED_HUB_ID,
          data: isNull(data.objectId) ? ['ALL'] : data.objectId,
        }),
        put({
          type: SET_SELECTED_LAUNCH_GROUP,
          data: isNull(data.launchGroup) ? [] : data.launchGroup
        }),
        put({
          type: SET_HUB_BANNER_COVER_MEDIA_TYPE,
          data: data.coverMediaType,
        }),
        put({ type: SET_HUB_BANNER_IMAGE, data: data.image }),
        put({ type: SET_HUB_BANNER_VIDEO, data: data.video || '' }),
        put({ type: SET_HUB_BANNER_START_DATE, data: moment(data.startDate) }),
        put({ type: SET_HUB_BANNER_END_DATE, data: moment(data.endDate) }),
      ]);
    }
    yield put({ type: SET_BANNER_FORM_LOADING, data: false });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Fetching Configuration' },
    });
  }
}

function* deleteBannerConfigurationSaga({ id }) {
  try {
    const list = yield select(bannerList);
    const filterList = list.filter(item => item.id !== parseInt(id, 10));
    yield call(deleteBannerConfigurationApi, id);
    yield put({ type: SET_BANNER_LIST, data: filterList });
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Deleting Configuration' },
    });
  }
}

function* getIframeCodeFromVideoURl({ data }) {
  yield put(setIframeCodeLoading(true));
  try {
    const { data: iframeCode } = yield call(getVideoIframeApi, {
      url: data.url,
    });

    yield put({ type: SET_HUB_BANNER_VIDEO, data: iframeCode });
  } catch (error) {
    if (error.response) {
      const message = get(error, 'response.data.message');
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message },
      });
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error getting iframe code from url.' },
      });
    }
  }
  yield put(setIframeCodeLoading(false));
}

function* getAllUsedCustomImages() {
  yield put(setLoadingAllCustomImages(true));
  try {
    const { data: usedCustomImages } = yield call(getAllUsedCustomImagesApi);

    yield put({ type: SET_ALL_CUSTOM_IMAGES, data: usedCustomImages });
  } catch (error) {
    if (error.response) {
      const message = get(error, 'response.data.message');
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message },
      });
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error getting iframe code from url.' },
      });
    }
  }
  yield put(setLoadingAllCustomImages(false));
}

export default [
  takeLatest(SAVE_BANNER_CONFIGURATION, saveBannerConfigurationSaga),
  takeLatest(
    FETCH_EXISTING_BANNER_CONFIGURATION,
    fetchExistingConfigurationSaga
  ),
  takeLatest(FETCH_CONFIGURATION_BASED_ON_ID, fetchConfigurationBasedOnIdSaga),
  takeLatest(DELETE_BANNER_CONFIGURATION, deleteBannerConfigurationSaga),
  takeLatest(GET_IFRAME_CODE_FROM_VIDEO_URL, getIframeCodeFromVideoURl),
  takeLatest(GET_ALL_CUSTOM_IMAGES, getAllUsedCustomImages),
];
