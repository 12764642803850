import React from 'react';
import { connect } from 'react-redux';
import { Card, Table } from 'antd';
import {
  fetchBulkEmailList,
  setBulkEmailListPage,
  handleRestartClick,
  clearBulkEmailState,
} from './actions';
import s from './index.module.scss';
import columns from './tableColumns';

class ManageBulkEmail extends React.Component {
  componentDidMount() {
    const { fetchBulkEmailList } = this.props;
    fetchBulkEmailList();
  }

  componentWillUnmount() {
    const { clearBulkEmailState } = this.props;
    clearBulkEmailState();
  }

  handlePageChange = page => {
    const { setBulkEmailListPage, fetchBulkEmailList } = this.props;
    setBulkEmailListPage(page);
    fetchBulkEmailList();
  };

  handleClick = record => () => {
    const { handleRestartClick } = this.props;
    handleRestartClick({
      id: record.id,
      status: record.status,
      sendVia: record.send_via,
    });
  };

  render() {
    const {
      bulkEmailList,
      bulkEmailListLoading,
      listCount,
      queueStatus,
    } = this.props;
    return (
      <div className={s['manage-bulk-email']}>
        <h3>Manage Bulk Email</h3>
        <Card>
          {!bulkEmailListLoading && (
            <p>
              Current queue state: <b>{queueStatus.state}</b>, Current queue count: <b>{queueStatus.messages}</b>
            </p>
          )}
          <Table
            columns={columns(this.handleClick)}
            dataSource={bulkEmailList}
            loading={bulkEmailListLoading}
            rowKey={record => record.id}
            pagination={{
              total: parseInt(listCount, 10),
              onChange: this.handlePageChange,
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ bulkEmail }) => {
  const {
    bulkEmailList,
    listCount,
    bulkEmailListLoading,
    queueStatus,
  } = bulkEmail;
  return {
    bulkEmailList,
    bulkEmailListLoading,
    listCount,
    queueStatus,
  };
};

const mapDispatchToProps = {
  fetchBulkEmailList,
  setBulkEmailListPage,
  handleRestartClick,
  clearBulkEmailState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageBulkEmail);
