import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  clearForm,
  fetchGroupTemplateBasedOnId,
  fetchGroupTierBasedOnId,
  setManageDataPage,
  fetchGroupTiers,
  fetchGroupTemplates,
} from '../../actions/manage';
import Columns from './columns';

class TabBody extends Component {
  handleCreate = () => {
    const { history, manageType, match } = this.props;
    const { type } = match.params;

    history.push(`/groups-programs-tier/manage-${manageType}/${type}/new`);
  };

  onRowClick = record => {
    const { history, manageType, match } = this.props;
    const { type } = match.params;

    history.push(
      `/groups-programs-tier/manage-${manageType}/${type}/edit/${record.id}`
    );
  };

  handlePageChange = page => {
    const {
      manageType,
      match,
      setManageDataPage,
      fetchGroupTiers,
      fetchGroupTemplates,
    } = this.props;
    const { type } = match.params;

    setManageDataPage(page);

    if (manageType === 'tier') {
      fetchGroupTiers(type);
    } else if (manageType === 'template') {
      fetchGroupTemplates(type);
    }
  };

  render() {
    const {
      manageData,
      manageLoading,
      manageDataCount,
      manageDataPage,
      manageDataPageSize,
      manageType,
      match,
    } = this.props;
    const { type } = match.params;

    return (
      <div>
        <div className="row-justify">
          <p className="manage-table-container__heading">
            {type === 'group' ? 'Group ' : 'Program '}
            {manageType === 'tier' ? 'Tiers' : 'Templates'}
          </p>
          <Button type="primary" onClick={this.handleCreate} icon="plus-circle">
            Create
          </Button>
        </div>
        <Table
          dataSource={manageData}
          columns={Columns[manageType]}
          loading={manageLoading}
          rowKey={record => record.id}
          onRow={record => ({
            onClick: () => this.onRowClick(record),
          })}
          className="banner-table"
          pagination={{
            simple: false,
            total: parseInt(manageDataCount, 10),
            current: manageDataPage,
            onChange: this.handlePageChange,
            pageSize: manageDataPageSize,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const {
    manageData,
    manageLoading,
    manageType,
    manageDataPage,
    manageDataCount,
    manageDataPageSize,
  } = groupsManage;
  return {
    manageData,
    manageLoading,
    manageType,
    manageDataPage,
    manageDataCount,
    manageDataPageSize,
  };
};

const mapDispatchToProps = {
  fetchGroupTierBasedOnId,
  fetchGroupTemplateBasedOnId,
  clearForm,
  setManageDataPage,
  fetchGroupTiers,
  fetchGroupTemplates,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TabBody)
);
