import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import memoize from 'lodash/memoize';
import union from 'lodash/union';
import { fetchAllClusters, setClusterManagement, saveClusterConfig } from '../actions';
import { Spin, Button, Tree, PageHeader, Card, Descriptions } from 'antd';
import SaveModal from './SaveModal';
import Content from '../../../component/Content';
import SelectActions from '../../../component/SelectActions';
import { getRequiredFields, renderTreeNodes, getLeaves, getDependencies, getLabels } from '../../../utils/checkBoxTreeUtils';
import intersection from 'lodash/intersection';
import every from 'lodash/every';
import nodes from './nodes';
import { breadCrumbItemRender } from '../../../utils/helpers'

const DescriptionsItem = Descriptions.Item;

const breadcrumbConf = { 
  routes: [
    {
      path: '/clusters',
      breadcrumbName: 'Clusters',
    },
    {
      path: '/clusters/configs',
      breadcrumbName: 'Cluster Configs',
    },
  ],
  itemRender: breadCrumbItemRender
};

const labels = getLabels(nodes);

class ClusterSave extends PureComponent {

  requiredFields =  getRequiredFields(nodes);
  dependencies = getDependencies(nodes);

  state = {
    checked: this.requiredFields,
    expanded: nodes.map(node => node.key),
  }

  componentDidMount() {
    if(!this.props.clusters) this.props.fetchAllClusters()
  }

  getCluster = memoize(clusterId => 
    this.props.clusters.find(cluster => cluster.id === parseInt(clusterId))
  );

  renderLoader = () => {
    return <Spin size="large"/>
  }
  
  onCheck = checked => {
    checked = union(checked, this.requiredFields);
    checked = intersection(getLeaves(nodes), checked);
    checked = checked.filter(module => {
      const dependencies = this.dependencies[module];
      if(!dependencies) return true;
      return every(dependencies, dep => checked.includes(dep))
    });
    this.setState({ checked });
  }

  onExpand = expanded => {
    this.setState({ expanded })
  }

  saveCluster = () => {
    this.props.setClusterManagement({ saveModalVisible: true });
  }

  onSelectAll = () => this.onCheck(getLeaves(nodes))

  onDeselectAll = () => this.onCheck([])

  renderClusterContent = () => {
    const { match: { params: { clusterId }}} = this.props;
    const cluster = this.getCluster(clusterId);
    return (
      <div>
        <Descriptions title="Cluster Info" bordered size="small">
          <DescriptionsItem span={3} label="Name">{cluster.name}</DescriptionsItem>
          <DescriptionsItem label="Id">{cluster.id}</DescriptionsItem>
          <DescriptionsItem label="Domain">{cluster.domain}</DescriptionsItem>
          <DescriptionsItem label="Subdomain">{cluster.subdomain}</DescriptionsItem>
        </Descriptions>
        <div>
          <Card style={{ marginTop: '20px' }} title={<div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.45)'}}>Select settings to save</div>} >
            <SelectActions
              onSelectAll={this.onSelectAll}
              onDeselectAll={this.onDeselectAll}
            />
            <Tree
              checkable
              onExpand={this.onExpand}
              onCheck={this.onCheck}
              expandedKeys={this.state.expanded}
              checkedKeys={this.state.checked}
              selectedKeys={[]}
            >
              {renderTreeNodes(nodes, labels)}
            </Tree>
          </Card>
        </div>
        <Button 
          style={{ marginTop: '20px' }}
          onClick={this.saveCluster}
        >Save</Button>
      </div>
    )
  }

  renderContent = () => {
    const { clustersLoading: loading, clusters } = this.props;
    if(loading) return this.renderLoader()
    if(clusters) return this.renderClusterContent()
    return null;
  }

  onSaveModalOk = name => {
    const { checked } = this.state;
    const { match: { params: { clusterId }}} = this.props;
    this.props.saveClusterConfig({
      checked,
      name,
      clusterId,
    })
  }

  onSaveModalCancel = () => {
    this.props.setClusterManagement({ saveModalVisible: false });
  }

  render() {
    const {saveModalVisible} = this.props;
    return (
      <div>
        <PageHeader title="Save Cluster configuration file" breadcrumb={breadcrumbConf} />
        <Content>
          <div>
            <Card title="Cluster Configuration File">
              <p>
                Select all of the settings and content which you want to export. Only the items you select here 
                will be included in the configuration file download. When importing a configuration file, you 
                will still be able to de-select items if necessary.
              </p>
              {this.renderContent()}
            </Card>
          </div>
        </Content>
        <SaveModal
          visible={saveModalVisible}
          onOk={this.onSaveModalOk}
          onSaveModalCancel={this.onSaveModalCancel}
          onCancel={this.onSaveModalCancel}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  cluster: { clusters, clustersLoading, saveModalVisible }
}) => ({ clusters, clustersLoading, saveModalVisible  });

const mapDispatchToProps = { 
  fetchAllClusters,
  setClusterManagement,
  saveClusterConfig,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusterSave));
