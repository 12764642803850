import React, { useState } from 'react';
import { Modal } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import TriggerSelect from './TriggerSelect';

import s from '../index.module.scss';
import {
  useActivateTriggerMutation,
  useDeActivateTriggerMutation,
} from '../query/useTriggerMutation';
// eslint-disable-next-line import/no-named-as-default
import notify from '../../../utils/notify';

const TriggerActionModal = ({
  setModalAction,
  selectedHubs,
  action,
  setSeletectedHubs,
}) => {
  const [trigger, setTrigger] = useState([]);
  const { mutate: handleActivateEvent, isLoading } =
    useActivateTriggerMutation();

  const client = useQueryClient();

  const { mutate: handleDeActivateEvent } = useDeActivateTriggerMutation();

  const visible = ['activate', 'de-activate'].includes(action);
  if (!visible) {
    return null;
  }

  const label = action === 'activate' ? 'Activate' : 'De-Activate';
  const actionMethod =
    action === 'activate' ? handleActivateEvent : handleDeActivateEvent;
  return (
    <Modal
      title={`${label} Events`}
      visible={visible}
      onOk={() => {
        actionMethod(
          { trigger, hubs: selectedHubs },
          {
            onSuccess: data => {
              notify(data.message, 'success');
              client.invalidateQueries(['autoTriggerList']);
              client.invalidateQueries(['AllHubs']);
            },
            onError: err => {
              // eslint-disable-next-line no-console
              console.error(err);
              notify('Failed To Process Request', 'error');
            },
            onSettled: () => {
              setModalAction('');
              setSeletectedHubs([]);
              setTrigger([]);
            },
          }
        );
      }}
      onCancel={() => {
        setModalAction('');
      }}
      width={600}
      maskClosable={false}
      okText={label}
      confirmLoading={isLoading}
    >
      <div>
        <TriggerSelect
          handleChange={value => setTrigger(value)}
          value={trigger}
        />
      </div>
      <p className={s.activeModalSelectedHubText}>
        {`${label} Event on selected ${selectedHubs.length} hubs.`}
      </p>
      <div>
        Note: Please note that it may take a few minutes for the changes to be
        reflected. You can click the Refresh Data button to view the data.
      </div>
    </Modal>
  );
};

export default React.memo(TriggerActionModal);
