export const warningMessages = {
  upsells: {
    type: 'Warning: ',
    text:
      'These changes are global and affect all hubs whose User Type library containes templates that may be associated with the upsell actions from this screen.',
  },
  'usertype-library': {
    type: 'Note: ',
    text:
      'Any changes you make here will be reflected in all hubs whose user type library contains this template. Only make changes if you feel they are absolutely necessary.',
  },
};
