import { takeEvery, takeLatest } from 'redux-saga/effects';

import { 
  FETCH_ALL_SETTINGS, 
  UPDATE_LAUNCH_GROUP_SETTINGS,
  FETCH_LAUNCH_GROUP_HUBS,
  FETCH_GROUP_HUBS_COUNT,
  START_DOWNLOAD,
  RESET_TO_DEFAULT,
} from '../actions';

import {
  fetchAllSettingsSaga,
  updateLaunchGroupSettingsSaga,
  fetchLaunchGroupHubsSaga,
  fetchGroupHubsCountSaga,
  startDownloadSaga,
  resetToDefaultSaga,
} from './featureManagementSagas';

export default [
  takeLatest(FETCH_ALL_SETTINGS, fetchAllSettingsSaga),
  takeLatest(START_DOWNLOAD, startDownloadSaga),
  takeLatest(RESET_TO_DEFAULT, resetToDefaultSaga),
  takeLatest(FETCH_LAUNCH_GROUP_HUBS, fetchLaunchGroupHubsSaga),
  takeLatest(FETCH_GROUP_HUBS_COUNT, fetchGroupHubsCountSaga),
  takeEvery(UPDATE_LAUNCH_GROUP_SETTINGS, updateLaunchGroupSettingsSaga),
];
