/* eslint-disable import/prefer-default-export */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteDefaultTextAPI } from '../../../api';
import DefaultTextKeys from '../queryKeys';

const { listClusterText, listHubText } = DefaultTextKeys;

const deleteDefaultText = async payload => {
  await deleteDefaultTextAPI(payload);
};

export const useDeleteDefaultTextMutation = (props = {}) => {
  const queryClient = useQueryClient();
  return useMutation(deleteDefaultText, {
    ...(props || {}),
    onSuccess: (response, request) => {
      const invalidQueryKey = request.isClusterText
        ? listClusterText
        : listHubText;
      queryClient.invalidateQueries([invalidQueryKey]);
      props?.onSuccess?.();
    },
  });
};
