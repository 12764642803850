import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import {
  INITIALISE_PAGE,
  FETCH_SUPERADMINS,
  FETCH_GROUPS,
  ADD_NEW_GROUPS,
  SAVE_GROUP,
  SAVE_PERMISSION,
  DELETE_GROUP,
  setAccessManagementObj,
  accessManagementState,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';
import {
  fetchSuperadmins,
  fetchAccessGroups,
  updateSuperadminGroups,
  updateAccessGroups,
  createAccessGroups,
  deleteAccessGroups,
} from '../../../api';

export default [
  takeLatest(INITIALISE_PAGE, initialisePageSaga),
  takeLatest(FETCH_SUPERADMINS, fetchSuperadminsSaga),
  takeLatest(FETCH_GROUPS, fetchGroups),
  takeLatest(ADD_NEW_GROUPS, addNewGroup),
  takeLatest(SAVE_GROUP, saveGroup),
  takeLatest(DELETE_GROUP, deleteGroup),
  takeLatest(SAVE_PERMISSION, saveSuperadmissionPermission),
];

function* initialisePageSaga() {
  try {
    yield put(setAccessManagementObj({ loading: true }));
    yield all([
      call(fetchSuperadminsSaga),
      call(fetchGroups, { data: { noPagination: true } }),
    ]);

    yield put(setAccessManagementObj({ loading: false }));
  } catch (e) {
    console.log('ERR2 ', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Something went wrong.' },
    });
    yield put(setAccessManagementObj({ loading: false }));
  }
}

function* fetchSuperadminsSaga(obj) {
  try {
    yield put(setAccessManagementObj({ loadingAdmins: true }));
    if (obj && obj.data && typeof obj.data === 'object') {
      yield put(setAccessManagementObj({ ...obj.data }));
    }
    const stateObj = yield select(accessManagementState);

    const param = {
      params: {
        searchText: stateObj.adminFilterText,
        pageNumber: stateObj.adminCurrentPage,
        pageSize: stateObj.adminPageSize,
      },
    };
    const { data = {} } = yield call(fetchSuperadmins, param);
    // console.log("result ", result)
    yield put(
      setAccessManagementObj({
        loadingAdmins: false,
        admins: data.result || [],
        adminTotalCount: data.rowCount,
      })
    );
  } catch (e) {
    console.log('ERR1 ', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Something went wrong.' },
    });
    yield put(setAccessManagementObj({ loadingAdmins: false }));
  }
}

function* fetchGroups(obj) {
  try {
    yield put(setAccessManagementObj({ loadingGroups: true }));
    if (obj && obj.data && typeof obj.data === 'object') {
      yield put(setAccessManagementObj({ ...obj.data }));
    }
    const stateObj = yield select(accessManagementState);

    const param = {
      params: {
        searchText: stateObj.groupFilterText,
        pageNumber: stateObj.groupCurrentPage,
        pageSize: stateObj.groupPageSize,
      },
    };
    if (obj && obj.data && obj.data.noPagination) {
      param.params.noPagination = true;
    }
    const { data = {} } = yield call(fetchAccessGroups, param);
    // console.log("fetchGroups ", data)
    yield put(
      setAccessManagementObj({
        loadingGroups: false,
        groups: data.result || [],
        groupTotalCount: data.rowCount,
        permissions: data.permissions,
      })
    );
  } catch (e) {
    console.log('Err 2', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Something went wrong.' },
    });
    yield put(setAccessManagementObj({ loadingGroups: false }));
  }
}

function* addNewGroup({ data }) {
  try {
    yield put(setAccessManagementObj({ addingGroup: true }));

    const groupName = data && data.name;
    const isDefault = data && data.isDefault;
    if (groupName) {
      yield call(createAccessGroups, { name: groupName, data: [], isDefault });
      yield call(fetchGroups);
    }
    yield put(setAccessManagementObj({ addingGroup: false }));
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Something went wrong.' },
    });
    yield put(setAccessManagementObj({ addingGroup: false }));
  }
}

function* deleteGroup({ data }) {
  try {
    yield put(setAccessManagementObj({ deletingGroup: true }));
    yield call(deleteAccessGroups, data);
    yield call(fetchGroups);
    yield put(setAccessManagementObj({ deletingGroup: false }));
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Something went wrong.' },
    });
    yield put(setAccessManagementObj({ deletingGroup: false }));
  }
}

function* saveGroup() {
  try {
    yield put(setAccessManagementObj({ savingGroup: true }));
    const stateObj = yield select(accessManagementState) || {};
    const { groups } = stateObj;
    yield call(updateAccessGroups, { groups });
    yield put(setAccessManagementObj({ savingGroup: false }));
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Something went wrong.' },
    });
    yield put(setAccessManagementObj({ savingGroup: false }));
  }
}

function* saveSuperadmissionPermission() {
  try {
    yield put(setAccessManagementObj({ savingAdminPermission: true }));
    const stateObj = yield select(accessManagementState) || {};
    const { admins } = stateObj;
    yield call(updateSuperadminGroups, { admins });
    yield put(setAccessManagementObj({ savingAdminPermission: false }));
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Something went wrong.' },
    });
    yield put(setAccessManagementObj({ savingAdminPermission: false }));
  }
}
