/* eslint-disable no-console */
import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { message } from 'antd';
import { get, isNil } from 'lodash';
import {
  FETCH_ALL_HUBS,
  SET_HUB_MGMT,
  SAVE_HUB_CONFIG,
  FETCH_ALL_HUB_CONFIG,
  DELETE_HUB_CONFIG,
  CREATE_HUBS,
  SAVE_HUB,
  EDIT_HUB,
  FILTER_HUBS,
  SET_HUB_CREATE_LOADING,
  FETCH_LAUNCH_GROUP_CHANGE_LOG,
  SET_LAUNCH_GROUP_CHANGE_LOG,
  REQUEST_DELETE_HUB,
  REQUEST_DELETE_HUB_LOADING,
  FETCH_EL_ORGANIZATIONS,
  setELOrganizations,
} from './actions';
import * as api from '../../api';
import history from '../../history';

import { clusterHubsForHome } from '../../sagas/clusterHubSaga';

const selects = {
  hubs: state => state.hub.hubs,
};

function* fetchLaunchGroupChangeLogSaga({ data }) {
  try {
    yield put({
      type: SET_LAUNCH_GROUP_CHANGE_LOG,
      data: { changeLogsLoading: true },
    });
    const { data: logs } = yield call(api.hub.fetchLaunchGroupChangeLog, data);
    // eslint-disable-next-line no-return-assign, no-param-reassign
    logs.forEach(l => (l.key = l.id));
    yield put({
      type: SET_LAUNCH_GROUP_CHANGE_LOG,
      data: {
        changeLogsLoading: false,
        changeLogs: logs,
      },
    });
  } catch (e) {
    const msg = get(e, 'response.data.msg');
    if (msg) message.error(msg);
    else message.error('Failed to fetch logs');
    console.log(e);
  }
}

function* fetchAllHubs() {
  yield put({
    type: SET_HUB_MGMT,
    data: { hubsLoading: true },
  });
  const [{ data: hubs }, { data: modulesList }] = yield all([
    call(api.hub.getAll, {
      fields: ['id', 'name', 'identifier', 'defaultSetting'],
    }),
    call(api.getModulesList, { moduleType: 'hub' }),
  ]);
  // eslint-disable-next-line no-return-assign, no-param-reassign
  hubs.forEach(h => (h.key = h.id));
  yield put({
    type: SET_HUB_MGMT,
    data: {
      hubs,
      hubsLoading: false,
      filtersEnabled: false,
      modulesList,
    },
  });
}

function* saveHubConfig({ data }) {
  try {
    yield call(api.hub.saveConfig, data);
    yield put({ type: SET_HUB_MGMT, data: { hubConfigs: null } });
    history.push('/hubs/configs');
  } catch (e) {
    const msg = get(e, 'response.data.msg');
    if (msg) message.error(msg);
    else message.error('Failed to save hub config');
    console.log(e);
  }
}

function* fetchAllHubConfig({ data }) {
  yield put({
    type: SET_HUB_MGMT,
    data: { hubConfigsLoading: true },
  });
  let hubConfigs = yield call(api.hub.getHubConfigs);
  hubConfigs = hubConfigs.data;
  // eslint-disable-next-line no-return-assign, no-param-reassign
  hubConfigs.forEach(h => (h.key = h.id));
  yield put({
    type: SET_HUB_MGMT,
    data: {
      hubConfigs,
      hubConfigsLoading: false,
    },
  });
}

function* deleteHubConfig({ hubConfigId }) {
  yield put({
    type: SET_HUB_MGMT,
    data: { hubConfigsLoading: true },
  });
  let hubConfigs = yield call(api.hub.deleteHubConfig, hubConfigId);
  hubConfigs = hubConfigs.data;
  // eslint-disable-next-line no-return-assign, no-param-reassign
  hubConfigs.forEach(h => (h.key = h.id));
  yield put({
    type: SET_HUB_MGMT,
    data: {
      hubConfigs,
      hubConfigsLoading: false,
    },
  });
}

function* createHubs({ data }) {
  try {
    yield put({ type: SET_HUB_CREATE_LOADING, data: true });
    yield call(api.hub.createHubs, data);
    message.success('created hubs');
    history.push('/hubs');
    yield put({ type: SET_HUB_CREATE_LOADING, data: false });
  } catch (e) {
    if (e) {
      const errMessage = get(e, 'response.data.message', null);
      if (errMessage) {
        message.error(errMessage);
      } else {
        message.error('Failed to create hubs');
      }
    } else {
      message.error('Failed to create hubs');
    }
    yield put({ type: SET_HUB_CREATE_LOADING, data: false });
  }
}

function* saveHub({ hub }) {
  try {
    yield call(api.hub.saveHub, hub);
    let hubs = yield select(selects.hubs);
    const ind = hubs.findIndex(h => h.id === hub.id);
    hubs = Object.assign([], hubs, { [ind]: hub });
    yield put({ type: SET_HUB_MGMT, data: { hubs, editHub: null } });
    yield call(clusterHubsForHome, {});
    yield call(fetchAllHubs, {});
    message.success('Saved Hub');
  } catch (e) {
    message.error('Failed to save hub');
    console.log(e);
  }
}

function* requestDeleteHub({ data }) {
  try {
    const { hubId, reason } = data;
    const hubs = yield select(selects.hubs);
    let requestedHub = hubs.find(h => h.id === hubId);
    requestedHub = JSON.parse(JSON.stringify(requestedHub));
    yield put({ type: REQUEST_DELETE_HUB_LOADING, data: true });
    yield call(api.requestHubDeletionById, { id: hubId, reason });

    const {
      data: { hub, libraries },
    } = yield call(api.hub.getOne, hubId);
    hub.libraries = libraries;
    requestedHub = { ...requestedHub, ...hub };

    yield put({ type: SET_HUB_MGMT, data: { editHub: requestedHub } });
    yield put({ type: REQUEST_DELETE_HUB_LOADING, data: false });

    const successMessage =
      (!!requestedHub.deleteRequestedAt &&
        'Hub Deletion Requested Successfully') ||
      'Hub Deletion Request Cancelled Successfully';

    message.success(successMessage);
  } catch (error) {
    message.error('Failed to make hub deletion request');
    yield put({ type: REQUEST_DELETE_HUB_LOADING, data: false });
    console.log(error);
  }
}

function* editHub({ hubId }) {
  try {
    yield put({ type: SET_HUB_MGMT, data: { editHubLoading: true } });
    const hubs = yield select(selects.hubs);
    let editedHub = hubs.find(h => h.id === hubId);
    editedHub = JSON.parse(JSON.stringify(editedHub));

    yield call(fetchELOrganizations);
    const {
      data: { hub, libraries },
    } = yield call(api.hub.getOne, hubId);
    hub.libraries = libraries;
    editedHub = { ...hub, ...editedHub };
    editedHub.elOrganizationId = !isNil(editedHub.elOrganizationId)
      ? editedHub.elOrganizationId
      : undefined;

    yield put({
      type: SET_HUB_MGMT,
      data: { editHub: editedHub, editHubLoading: false },
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: SET_HUB_MGMT,
      data: { editHub: null, editHubLoading: false },
    });
    message.errro('failed to load hub settings');
  }
}

function* getFilteredHubs({ data }) {
  try {
    yield put({
      type: SET_HUB_MGMT,
      data: { hubsLoading: true },
    });
    // eslint-disable-next-line no-param-reassign
    data.fields = ['id', 'name', 'identifier', 'defaultSetting'];
    let filteredHubs = yield call(api.hub.filterHubs, data);
    filteredHubs = filteredHubs.data;
    yield put({
      type: SET_HUB_MGMT,
      data: {
        hubs: filteredHubs,
        hubsLoading: false,
        filtersEnabled: true,
      },
    });
  } catch (e) {
    console.log(e);
    message.error('Failed to filter hubs');
  }
}

function* fetchELOrganizations() {
  try {
    const organizationsData = yield call(api.hub.getELOrganizaions);
    yield put(setELOrganizations(organizationsData.data.organizations));
  } catch (e) {
    console.error(e);
    message.error('Failed to fetch EL Organizations');
  }
}

const sagas = [
  takeLatest(FETCH_ALL_HUBS, fetchAllHubs),
  takeLatest(SAVE_HUB_CONFIG, saveHubConfig),
  takeLatest(FETCH_ALL_HUB_CONFIG, fetchAllHubConfig),
  takeLatest(DELETE_HUB_CONFIG, deleteHubConfig),
  takeLatest(CREATE_HUBS, createHubs),
  takeLatest(SAVE_HUB, saveHub),
  takeLatest(EDIT_HUB, editHub),
  takeLatest(REQUEST_DELETE_HUB, requestDeleteHub),
  takeLatest(FILTER_HUBS, getFilteredHubs),
  takeLatest(FETCH_LAUNCH_GROUP_CHANGE_LOG, fetchLaunchGroupChangeLogSaga),
  takeLatest(FETCH_EL_ORGANIZATIONS, fetchELOrganizations),
];

export default sagas;
