import { select, put, call } from 'redux-saga/effects';
import {
  userHubIds,
  UPSERT_LOADING,
  SET_USER_HUB_IDS,
  SET_NOT_FOUND_IDS,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';
import { processUpsertUsersApi } from '../../../api';

export function* processUpsertUsersSaga() {
  try {
    const ids = yield select(userHubIds);
    const idsArr = ids.split(',');
    yield put({
      type: UPSERT_LOADING,
      data: true,
    });
    const { data } = yield call(processUpsertUsersApi, { ids: idsArr });
    if (data.message === 'started') {
      if (data.notFoundIds && data.notFoundIds.length > 0) {
        yield put({
          type: SET_NOT_FOUND_IDS,
          data: data.notFoundIds,
        });
      }
      yield put({
        type: UPSERT_LOADING,
        data: false,
      });
      yield put({
        type: SET_USER_HUB_IDS,
        data: '',
      });
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Started processing Refresh!' },
      });
    }
  } catch (e) {
    console.log('Error in processUpsertUsersSaga', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Refreshing ES document!' },
    });
  }
}
