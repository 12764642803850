import React from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import {
  fetchHubGroupsData,
  setHubGroupsPage,
  setConfigurationModal,
  setSelectedConfigurationSetting,
  fetchTemplateAssociatedToTier,
  setSelectedHub,
  setConfigurationTab,
  setTierTabs,
  setIsEnabled,
  setSelectedConfigurationObj,
} from './actions';
import { uniq, pick } from 'lodash';
import columns from './GroupTierColumns';

class GroupProgramTable extends React.Component {
  componentDidMount() {
    const { fetchHubGroupsData } = this.props;
    fetchHubGroupsData();
  }

  handleOnChange = page => {
    const { setHubGroupsPage, fetchHubGroupsData } = this.props;
    setHubGroupsPage(page);
    fetchHubGroupsData();
  };

  handleUpdateConfiguration = obj => () => {
    const {
      setConfigurationModal,
      setSelectedConfigurationSetting,
      fetchTemplateAssociatedToTier,
      setSelectedHub,
      setConfigurationTab,
      setTierTabs,
      setIsEnabled,
      setSelectedConfigurationObj,
    } = this.props;
    if (obj.tierSetting && obj.tierSetting.length > 0) {
      fetchTemplateAssociatedToTier(obj.tierSetting.map(x => x.id));
      const tierTabs = uniq(obj.tierSetting.map(item => item.type));
      setTierTabs(tierTabs);
      setConfigurationTab(tierTabs[0]);
    }
    setSelectedConfigurationObj(pick(obj, ['notCategorizedGroups', 'notCategorizedPrograms']));
    setSelectedConfigurationSetting(obj.tierSetting);
    setIsEnabled(obj.isGroupProvisioningEnabled);
    setSelectedHub(obj.id);
    setConfigurationModal(true);
  };

  render() {
    const { groupsDataLoading, hubGroupsData, totalCount, page } = this.props;
    return (
      <div className={'table-container'}>
        <div className={'table__header'}>
          <p className={'table__header--text'}>Hubs</p>
        </div>
        <Table
          dataSource={hubGroupsData}
          columns={columns(this.handleUpdateConfiguration)}
          loading={groupsDataLoading}
          rowKey={record => record.id}
          pagination={{
            simple: false,
            total: parseInt(totalCount, 10),
            current: page,
            onChange: this.handleOnChange,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ groupsProgramsTier }) => {
  const {
    groupsDataLoading,
    hubGroupsData,
    totalCount,
    page,
  } = groupsProgramsTier;
  return {
    groupsDataLoading,
    hubGroupsData,
    totalCount,
    page,
  };
};

const mapDispatchToProps = {
  fetchHubGroupsData,
  setHubGroupsPage,
  setConfigurationModal,
  setSelectedConfigurationSetting,
  fetchTemplateAssociatedToTier,
  setSelectedHub,
  setConfigurationTab,
  setTierTabs,
  setIsEnabled,
  setSelectedConfigurationObj,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupProgramTable);
