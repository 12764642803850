const prefix = 'Usertype-Settings';

export const FETCH_HUBS = `${prefix}/FETCH_HUBS`;
export const FETCH_LIBRARIES_TEMPLATES_AND_UPSELLS = `${prefix}/FETCH_LIBRARIES_TEMPLATES_AND_UPSELLS`;
export const OPEN_HUB = `${prefix}/OPEN_HUB`;

export const SET_HUBS_DATA = `${prefix}/SET_HUBS_DATA`;
export const SET_HUBS_DATA_PAGE = `${prefix}/SET_HUBS_DATA_PAGE`;
export const SET_HUBS_DATA_COUNT = `${prefix}/SET_HUBS_DATA_COUNT`;
export const SET_HUBS_DATA_LOADING = `${prefix}/SET_HUBS_DATA_LOADING`;
export const SET_HUBS_SEARCH_TEXT = `${prefix}/SET_HUBS_SEARCH_TEXT`;
export const SET_HUBS_FILTER_APPLIED = `${prefix}/SET_HUBS_FILTER_APPLIED`;
export const SET_LIBRARIES = `${prefix}/SET_LIBRARIES`;
export const SET_FILTERS_LOADING = `${prefix}/SET_FILTERS_LOADING`;
export const SET_SELECTED_LIBRARIES = `${prefix}/SET_SELECTED_LIBRARIES`;
export const SET_SELECTED_USERTYPE_TEMPLATES = `${prefix}/SET_SELECTED_USERTYPE_TEMPLATES`;
export const CLEAR_HUB_FILTERS = `${prefix}/CLEAR_HUB_FILTERS`;
export const CLEAR_USERTYPE_SETTINGS = `${prefix}/CLEAR_USERTYPE_SETTINGS`;

export const fetchHubs = data => ({
  type: FETCH_HUBS,
  data,
});

export const fetchLibrariesAndTemplatesAndUpsells = data => ({
  type: FETCH_LIBRARIES_TEMPLATES_AND_UPSELLS,
  data,
});

export const setHubsData = data => ({
  type: SET_HUBS_DATA,
  data,
});

export const setHubsDataPage = data => ({
  type: SET_HUBS_DATA_PAGE,
  data,
});

export const setHubsDataCount = data => ({
  type: SET_HUBS_DATA_COUNT,
  data,
});

export const setHubsDataLoading = data => ({
  type: SET_HUBS_DATA_LOADING,
  data,
});

export const setHubsSearchText = data => ({
  type: SET_HUBS_SEARCH_TEXT,
  data,
});

export const setHubsFilterApplied = data => ({
  type: SET_HUBS_FILTER_APPLIED,
  data,
});

export const clearHubFilters = () => ({
  type: CLEAR_HUB_FILTERS,
});

export const clearUsertypeSettings = () => ({
  type: CLEAR_USERTYPE_SETTINGS,
});

export const openHub = data => ({
  type: OPEN_HUB,
  data,
});

export const setLibraries = data => ({
  type: SET_LIBRARIES,
  data,
});

export const setFiltersLoading = data => ({
  type: SET_FILTERS_LOADING,
  data,
});

export const setSelectedLibraries = data => ({
  type: SET_SELECTED_LIBRARIES,
  data,
});

export const setSelectedUsertypeTemplates = data => ({
  type: SET_SELECTED_USERTYPE_TEMPLATES,
  data,
});

export const selectHubsData = ({ usertypeManage }) => usertypeManage.hubsData;
export const selectHubsDataPage = ({ usertypeManage }) =>
  usertypeManage.hubsDataPage;
export const selectHubsSearchText = ({ usertypeManage }) =>
  usertypeManage.hubsSearchText;
export const selectSelectedLibraries = ({ usertypeManage }) =>
  usertypeManage.selectedLibraries;
export const selectSelectedUsertypeTemplates = ({ usertypeManage }) =>
  usertypeManage.selectedUsertypeTemplates;
