import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import AnalyticQueryForm from './AnalyticQueryForm';
import s from './Analytics.module.scss';
import AnalyticsQueryTable from './AnalyticsQueryTable';
import {
  fetchQueryBasedOnId,
  clearQueryForm,
  fetchAssociatedChartData,
} from '../../actions/analyticsQuery';
class BuildAnalytics extends React.Component {
  state = {
    showForm: false,
  };

  componentDidMount() {
    this.checkRoute();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.url !== match.url) {
      this.checkRoute();
    }
  }

  checkRoute = () => {
    const {
      match,
      fetchQueryBasedOnId,
      clearQueryForm,
      type,
      fetchAssociatedChartData,
    } = this.props;
    if (
      match.path === '/build-analytics-chart/new' ||
      match.path === '/associate-charts/new' ||
      match.path.indexOf('/build-analytics-chart/edit') > -1 ||
      match.path.indexOf('/associate-charts/edit') > -1
    ) {
      if (
        match.path === '/build-analytics-chart/new' ||
        match.path === '/associate-charts/new'
      ) {
        clearQueryForm();
      }
      if (match.params && match.params.id) {
        if (type !== 'associate-charts') {
          fetchQueryBasedOnId(match.params.id);
        }
        if (type === 'associate-charts') {
          fetchAssociatedChartData(match.params.id);
        }
      }
      this.setState({
        showForm: true,
      });
    }
    if (
      match.path === '/build-analytics-chart' ||
      match.path === '/associate-charts'
    ) {
      this.setState({
        showForm: false,
      });
    }
  };

  render() {
    const { showForm } = this.state;
    const { type = 'build-analytics-chart' } = this.props;
    return (
      <div className={`${s['build-analytics-container']}`}>
        <Row gutter={16}>
          <Col span={12}>
            <AnalyticsQueryTable type={type} />
          </Col>
          <Col span={12}>{showForm && <AnalyticQueryForm type={type} />}</Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchQueryBasedOnId,
  clearQueryForm,
  fetchAssociatedChartData,
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(BuildAnalytics)
);
