import { call, put } from 'redux-saga/effects';

import { notifyError } from '../../../utils/notify';
import {
  fetchRequestedHubList,
  deleteRequestedHubById,
  updateRequestedByHubId,
} from '../../../api';
import { setHubDeletetionList } from '../actions';

export function* fetchRequestedDeletedHubsListSagas() {
  try {
    yield put(setHubDeletetionList({ loading: true }));
    const result = yield call(fetchRequestedHubList);

    yield put(
      setHubDeletetionList({
        requestedHubDeletionList: result.data,
        loading: false,
      })
    );
  } catch (e) {
    yield put(setHubDeletetionList({ loading: false }));
    notifyError(e, false, 'Error fetching all hubs');
  }
}

export function* deleteRequestedHubsByIdSagas({ data }) {
  try {
    yield put(setHubDeletetionList({ loading: true }));
    yield call(deleteRequestedHubById, data.id);
    yield call(fetchRequestedDeletedHubsListSagas);
  } catch (e) {
    yield put(setHubDeletetionList({ loading: false }));
    notifyError(e, false, 'Error in deleting requested hubs');
  }
}

export function* approveOrUnapproveRequestedHubsByIdSagas({ data }) {
  try {
    yield put(setHubDeletetionList({ loading: true }));
    yield call(updateRequestedByHubId, data);
    yield call(fetchRequestedDeletedHubsListSagas);
  } catch (e) {
    yield put(setHubDeletetionList({ loading: false }));
    notifyError(e, false, 'Error in deleting requested hubs');
  }
}
