import {
  SET_GROUPS_DATA_LOADING,
  SET_GROUPS_DATA_COUNT,
  SET_HUB_GROUPS_DATA,
  SET_HUB_GROUPS_PAGE,
  SET_HUB_GROUPS_SEARCH,
  SET_HUB_GROUPS_FILTER_APPLIED,
  SET_TIER_LOADING,
  SET_TIER_DATA,
  SET_SELECTED_TIER_DATA,
  SET_CONFIGURATION_MODAL,
  SET_CONFIGURATION_TAB,
  SET_SELECTED_CONFIGURATION_SETTING,
  SET_TEMPLATE_LOADING,
  SET_TEMPLATE_DATA,
  SET_SAVE_CONFIGURATION_LOADING,
  SET_SELECTED_HUB,
  SET_TIER_TABS,
  CLEAR_CONFIGURATION_DATA,
  CLEAR_GROUPS_TABLE_DATA,
  SET_IS_ENABLED,
  SET_SELECTED_CONFIGURATION_OBJ,
} from '../actions';

const initialState = {
  groupsDataLoading: false,
  page: 1,
  totalCount: 0,
  hubGroupsData: [],
  searchText: '',
  isFilterApplied: false,
  tierLoading: false,
  tierData: [],
  selectedTierData: [],
  configurationModalVisible: false,
  configurationTab: 'group',
  selectedConfigurationSetting: null,
  templateLoading: false,
  templateData: {},
  configurationLoading: false,
  selectedHub: null,
  tierTabs: [],
  isEnabled: false,
  configurationObj: {},
};

const groupsProgramsTierReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_GROUPS_DATA_LOADING:
      return {
        ...state,
        groupsDataLoading: data,
      };
    case SET_GROUPS_DATA_COUNT:
      return {
        ...state,
        totalCount: data,
      };
    case SET_HUB_GROUPS_DATA:
      return {
        ...state,
        hubGroupsData: data,
      };
    case SET_HUB_GROUPS_PAGE:
      return {
        ...state,
        page: data,
      };
    case SET_HUB_GROUPS_SEARCH:
      return {
        ...state,
        searchText: data,
      };
    case SET_HUB_GROUPS_FILTER_APPLIED:
      return {
        ...state,
        isFilterApplied: data,
      };
    case SET_TIER_LOADING:
      return {
        ...state,
        tierLoading: data,
      };
    case SET_TIER_DATA:
      return {
        ...state,
        tierData: data,
      };
    case SET_SELECTED_TIER_DATA:
      return {
        ...state,
        selectedTierData: data,
      };
    case SET_CONFIGURATION_MODAL:
      return {
        ...state,
        configurationModalVisible: data,
      };
    case SET_CONFIGURATION_TAB:
      return {
        ...state,
        configurationTab: data,
      };
    case SET_SELECTED_CONFIGURATION_SETTING:
      return {
        ...state,
        selectedConfigurationSetting: data,
      };
    case SET_TEMPLATE_LOADING:
      return {
        ...state,
        templateLoading: data,
      };
    case SET_TEMPLATE_DATA:
      return {
        ...state,
        templateData: data,
      };
    case SET_SAVE_CONFIGURATION_LOADING:
      return {
        ...state,
        configurationLoading: data,
      };
    case SET_SELECTED_HUB:
      return {
        ...state,
        selectedHub: data,
      };
    case SET_TIER_TABS:
      return {
        ...state,
        tierTabs: data,
      };
    case CLEAR_CONFIGURATION_DATA:
      return {
        ...state,
        tierTabs: [],
        selectedHub: null,
        selectedConfigurationSetting: null,
        templateData: {},
        configurationTab: 'group',
        configurationLoading: false,
        errorConfiguration: false,
      };
    case CLEAR_GROUPS_TABLE_DATA:
      return {
        ...state,
        hubGroupsData: [],
        searchText: '',
        isFilterApplied: false,
        page: 1,
        totalCount: 0,
      };
    case SET_IS_ENABLED:
      return {
        ...state,
        isEnabled: data,
      };
    case SET_SELECTED_CONFIGURATION_OBJ:
      return {
        ...state,
        configurationObj: data,
      };
    default:
      return state;
  }
};

export default groupsProgramsTierReducer;
