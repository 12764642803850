import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Switch,
  Row,
  Col,
  Modal,
  Spin,
  Icon,
  Tabs,
  Select,
  Popconfirm,
  Tooltip,
} from 'antd';
import {
  uniqBy,
  map,
  pull,
  isObject,
  isEmpty,
  get,
  upperFirst,
  findIndex,
  cloneDeep,
  filter,
  forEach,
  difference,
} from 'lodash';

import variations from '../../../constants/defaultSettingVariationConstants';

import { setData } from '../actions';

import '../index.scss';

const { TabPane } = Tabs;
const { Option } = Select;

class HubSelectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedHubs: [],
      selectedGroups: [],
      hubSearch: [],
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    this.initializeState();
  }

  componentDidUpdate(prevProps) {
    const { hubList: oldHubList } = prevProps;
    const { hubList } = this.props;
    if (isEmpty(oldHubList) && !isEmpty(hubList)) {
      this.initializeState();
    }
  }

  buildSearchOption = hubs => {
    const { hubList } = this.props;
    const [item = undefined] = hubs;
    if (item && isObject(item)) return hubs;
    if (hubList && hubList.length) {
      return map(hubList.filter(hub => hubs.indexOf(hub.id) !== -1), hub => ({
        key: hub.id,
        label: `${hub.name} (ID:${hub.id})`,
      }));
    }

    return [];
  };

  initializeState = () => {
    const { launch_in = {} } = this.props;
    const { hubs = [], groups = [] } = launch_in;
    this.setState({
      selectedGroups: groups,
      selectedHubs: this.buildSearchOption(hubs),
    });
  };

  checkForFiltersLaunch = () => {
    const {
      setData,
      saveLaunchIn,
      singleTemplate,
      keyedFilterOptions,
    } = this.props;
    const { selectedGroups, selectedHubs } = this.state;

    const filters = get(singleTemplate, 'setting.filters', {});
    // If no filters are set then save directly without any checks
    if (isEmpty(filters)) {
      saveLaunchIn({ selectedGroups, selectedHubs });
      return;
    }

    const hubsToLaunch = map(selectedHubs, 'key');
    const hubsNoFilter = {};
    let showHubSelectionWarningModal = false;

    forEach(filters, (filterVal, filterLabel) => {
      const keyedValues = keyedFilterOptions[filterLabel];
      let uniqueHubs = new Set();

      forEach(filterVal, tagName => {
        uniqueHubs = new Set([
          ...uniqueHubs,
          ...get(keyedValues, [tagName, 'hubs'], []),
        ]);
      });

      hubsNoFilter[filterLabel] = difference(hubsToLaunch, [...uniqueHubs]);

      if (hubsNoFilter[filterLabel].length) showHubSelectionWarningModal = true;
    });

    if (!showHubSelectionWarningModal)
      saveLaunchIn({ selectedGroups, selectedHubs });

    setData({
      showHubSelectionWarningModal,
      hubsNoFilter,
      selectedGroups,
      selectedHubs,
    });
  };

  toggleModal = onSubmit => {
    const { setData } = this.props;

    if (onSubmit) {
      this.checkForFiltersLaunch();
    }

    setData({
      showHubSelectionModal: false,
    });
  };

  handleModalSelect = (type, val) => {
    const { modalData } = this.state;
    this.setState({ modalData: { ...modalData, [type]: val } });
  };

  handleChange = item => {
    const { selectedHubs = [] } = this.state;
    this.setState({
      selectedHubs: uniqBy(selectedHubs.concat(item), 'key'),
      hubSearch: [],
      dropdownOpen: false,
    });
  };

  handleSearch = text => {
    const { hubList = [] } = this.props;
    const { selectedHubs = [] } = this.state;
    let result = [];
    const searchText = text && text.toLowerCase();
    if (searchText && hubList && hubList.length) {
      result = hubList.filter(hub => {
        const { name, domain, identifier, id } = hub;

        const index = findIndex(selectedHubs, { key: id });
        if (index !== -1) return false;

        if (
          (name && name.toLowerCase().includes(searchText)) ||
          (domain && domain.toLowerCase().includes(searchText)) ||
          (identifier && identifier.toLowerCase().includes(searchText)) ||
          (id &&
            id
              .toString()
              .toLowerCase()
              .includes(searchText))
        )
          return true;

        return false;
      });
    }
    this.setState({ hubSearch: result, dropdownOpen: true });
  };

  handleLaunchGroupSelection = (launchGroup, add = false) => {
    const { hubList, preSelectedHubs } = this.props;
    const { selectedHubs = [] } = this.state;

    const hubsArray = filter(hubList, { defaultSetting: launchGroup });

    if (add) {
      const hubLabels = map(hubsArray, hub => ({
        key: hub.id,
        label: `${hub.name} (ID:${hub.id})`,
      }));
      this.handleChange(hubLabels);
      return;
    }

    const hubIdsArray = map(hubsArray, 'id');

    this.setState({
      selectedHubs: filter(
        selectedHubs,
        hub =>
          hubIdsArray.indexOf(hub.key) === -1 ||
          preSelectedHubs.indexOf(hub.key) !== -1
      ),
    });
  };

  handleGroupChange = (key, eventState) => {
    const { selectedGroups } = this.state;
    if (eventState && selectedGroups.indexOf(key) === -1) {
      this.setState({ selectedGroups: selectedGroups.concat([key]) }, () =>
        this.handleLaunchGroupSelection(key, true)
      );
      return;
    }
    this.setState({ selectedGroups: pull(selectedGroups, key) }, () =>
      this.handleLaunchGroupSelection(key, false)
    );
  };

  handleDeleteHub = hubId => {
    const { selectedHubs = [] } = this.state;
    if (!hubId) return;
    const index = findIndex(selectedHubs, { key: hubId });
    if (index !== -1) {
      const hubs = cloneDeep(selectedHubs);
      hubs.splice(index, 1);
      this.setState({ selectedHubs: hubs });
    }
  };

  handleChangeBlur = () => {
    this.setState({ dropdownOpen: false });
  };

  renderLaunchGroupSwitch = group => {
    const { selectedGroups } = this.state;

    if (selectedGroups && selectedGroups.indexOf(group.key) === -1) {
      return (
        <Popconfirm
          title={
            <span>
              This will launch the default pathway
              <br /> to all eligible hubs in this launch group.
            </span>
          }
          onConfirm={() => this.handleGroupChange(group.key, 'add')}
          okText="Launch"
          cancelText="Cancel"
        >
          <Switch
            checked={selectedGroups && selectedGroups.indexOf(group.key) !== -1}
          />
        </Popconfirm>
      );
    }
    return (
      <Switch
        checked={selectedGroups && selectedGroups.indexOf(group.key) !== -1}
        onChange={eventState => this.handleGroupChange(group.key, eventState)}
      />
    );
  };

  render() {
    const {
      loading,
      showHubSelectionModal,
      allowDelete,
      preSelectedHubs,
    } = this.props;
    const { selectedHubs, hubSearch, dropdownOpen } = this.state;

    return (
      <Modal
        visible={showHubSelectionModal}
        className="provisioning_launch_modal"
        title="Launch Groups & Hubs Selection"
        destroyOnClose
        onCancel={() => this.toggleModal()}
        footer={[
          <Button key="cancel" onClick={() => this.toggleModal()}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={false}
            onClick={() => this.toggleModal(true)}
          >
            Save
          </Button>,
        ]}
      >
        {get(loading, 'launch_in', false) ? (
          <div style={{ textAlign: 'center' }}>
            <Spin
              tip="Fetching hubs and pathways"
              size="small"
              indicator={<Icon type="loading" spin />}
            />
          </div>
        ) : (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Launch Groups" key="1">
              <div className="provisioning_launch_modal__header">
                All Launch Groups
              </div>
              <Row className="provisioning_launch_modal__row">
                {variations.map(v => (
                  <>
                    <Col span={20}>{v.name}</Col>
                    <Col span={4}>{this.renderLaunchGroupSwitch(v)}</Col>
                  </>
                ))}
              </Row>
            </TabPane>
            <TabPane tab="Hubs" key="2">
              <div className="provisioning_launch_modal__header">
                Search Hub
              </div>
              <Select
                className="provisioning_launch_modal-search"
                mode="multiple"
                labelInValue
                placeholder="search hub by name, domain or id"
                value={[]}
                open={dropdownOpen}
                filterOption={false}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                onBlur={() => this.handleChangeBlur()}
              >
                {hubSearch.map(h => (
                  <Option value={h.id}>
                    {h.name} (ID:{h.id})
                  </Option>
                ))}
              </Select>
              <div className="provisioning_launch_modal__header">
                Active Hub(s)
              </div>
              {selectedHubs.length ? (
                <div className="provisioning_launch_modal__list">
                  {selectedHubs.map(hub => (
                    <div className="provisioning_launch_modal__hub">
                      <span className="hub_name">
                        {upperFirst(hub.label.toString().replace(/,/g, ''))}
                      </span>
                      {allowDelete &&
                      preSelectedHubs.indexOf(hub.key) !== -1 ? (
                        <Tooltip title="pathway already published">
                          <Button
                            type="link"
                            className="action"
                            icon="close"
                            disabled
                          />
                        </Tooltip>
                      ) : (
                        <Button
                          type="link"
                          className="action"
                          icon="close"
                          onClick={() => this.handleDeleteHub(hub.key)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="empty_state">No Hub Selected!</div>
              )}
            </TabPane>
          </Tabs>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({
  PathwaysProvisioning: {
    hubsPathways,
    showHubSelectionModal,
    loading,
    pathwayData,
    hubList,
    singleTemplate,
    filterData: { keyedFilterOptions },
    preSelectedHubs,
  },
}) => ({
  hubsPathways,
  showHubSelectionModal,
  loading,
  pathwayData,
  hubList,
  singleTemplate,
  keyedFilterOptions,
  preSelectedHubs,
});

const mapDispatchToProps = {
  setData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HubSelectionModal);
