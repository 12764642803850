import React from 'react';

import { Input, Modal, Form } from 'antd';
import { notify } from '../../../../utils/notify';

const EditTemplate = ({
  form,
  editModal,
  closeEditModal,
  updateLinkedProjects,
  addLinkedProjects,
  revertProjectTemplate,
  careerPathId,
  currentProject,
  wrapClassName,
  currentPage,
}) => {
  let titlePrefix = 'Add';
  switch (editModal) {
    case 'edit':
      titlePrefix = 'Update';
      break;
    case 'add':
      titlePrefix = 'Add';
      break;
    case 'revert':
      titlePrefix = 'Changelog';
      break;
    default:
      titlePrefix = 'Add';
  }
  const viewOnly = editModal === 'revert';
  return (
    <Modal
      title={`${titlePrefix} Project Template`}
      visible={!!editModal}
      okText={editModal === 'revert' ? 'Revert' : 'Save'}
      cancelText="Cancel"
      onCancel={closeEditModal}
      onOk={() => {
        form.validateFieldsAndScroll(
          null,
          { first: true, force: true, scroll: { alignWithTop: false } },
          (err, values) => {
            if (!err) {
              switch (editModal) {
                case 'edit':
                  updateLinkedProjects({
                    linkedProject: values,
                    projectTemplateId: currentProject.projectTemplate,
                    careerPathId,
                    page: currentPage,
                  });
                  break;
                case 'add':
                  addLinkedProjects({
                    linkedProject: values,
                    careerPathId,
                    page: currentPage,
                  });
                  break;
                case 'revert':
                  revertProjectTemplate();
                  break;
                default:
                  break;
              }
              closeEditModal();
            } else {
              notify(
                'Error',
                'error',
                `${Object.values(err)[0].errors[0].message}`
              );
            }
          }
        );
      }}
      wrapClassName={wrapClassName}
    >
      <Form>
        <Form.Item label="Name">
          {form.getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: `Project Name is required`,
              },
            ],
            initialValue: currentProject?.formData?.title,
          })(<Input disabled={viewOnly} />)}
        </Form.Item>
        <Form.Item label="Project Details">
          {form.getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: `Project Description is required`,
              },
            ],
            initialValue: currentProject?.formData?.description,
          })(<Input.TextArea disabled={viewOnly} />)}
        </Form.Item>
        <Form.Item label="Project Goal">
          {form.getFieldDecorator('goal', {
            initialValue: currentProject?.formData?.goal,
          })(<Input disabled={viewOnly} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default EditTemplate;
