import {
  SET_TEMPLATES_LOADING,
  SET_TEMPLATES,
  SET_EDITOR_LOADING,
  SET_BEEFREE_TOKEN,
  SET_BEEFREE_INSTANCE,
  SET_CONFIRM_CLOSE_MODAL_PATH,
  RESET_STATE,
  SET_DRAFT_DATA,
  SET_EMAILS_LOADING,
  SET_EMAILS,
  SET_BEEFREE_DATA,
  ADD_EMAIL,
  SET_EMAIL_SENDING,
  SET_PREVIEW_TEMPLATE,
  SET_STATS_LOADING,
  SET_STATS,
  SET_LOADING_TEMPLATE,
  REMOVE_TEMPLATE,
  SET_TEMPLATE_CATEGORIES,
  SET_JOB_TEMPLATES,
  SET_ALL_CAMPAIGNS_LOADING,
  SET_ALL_CAMPAIGNS,
  SET_CAMPAIGN_LOADING,
  SET_CAMPAIGN_INFO,
  UPDATE_CAMPAIGN_INFO,
  RESET_CAMPAIGN_INFO,
} from '../actions';
import { cloneDeep, get } from 'lodash';

const campaignInfoInitialState = {
    campaignInfo: {
      isActive: false, 
      name: '',
      module: undefined,
    },
    savedCampaignInfo: {
      isActive: false, 
      name: '',
      module: undefined,
    },
    campaignInfoLoading: false,
    savingCampaignInfo: false,
}

const campaignInitialState = {
  campaign: {
    allCampaignsLoading: false,
    allCampaigns: [],
    ...campaignInfoInitialState,
  }
}
const initialState = {
  templatesLoading: false,
  templates: [],
  templateCategories: [],
  emailsLoading: false,
  emails: [],
  editorLoading: false,
  beeInstance: null,
  confirmCloseModalPath: null,
  previewTemplate: null,
  draftData: {
    name: '',
    messageSubject: '',
    attachments: [],
  },
  statsLoading: false,
  loadingTemplate: null,
  jobTemplates: [],
};

const automatedMarketingReducer = (
  state = { ...initialState, ...campaignInitialState },
  { type, data }
) => {
  switch (type) {
    case SET_TEMPLATES_LOADING:
      return {
        ...state,
        templatesLoading: !!data,
      };
    case SET_TEMPLATES:
      return {
        ...state,
        templates: data || [],
      };
    case SET_TEMPLATE_CATEGORIES:
      return {
        ...state,
        templateCategories: data || [],
      };
    case SET_EDITOR_LOADING:
      return {
        ...state,
        editorLoading: !!data,
      };
    case SET_EMAIL_SENDING:
      return {
        ...state,
        emailSending: !!data,
      };
    case SET_BEEFREE_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_BEEFREE_DATA:
      return {
        ...state,
        ...data,
      };
    case SET_BEEFREE_INSTANCE:
      return {
        ...state,
        beeInstance: data,
      };
    case SET_CONFIRM_CLOSE_MODAL_PATH:
      return {
        ...state,
        confirmCloseModalPath: data,
      };
    case RESET_STATE:
      const { campaign } = state;
      return {
        ...initialState,
        campaign,
      };
    case SET_DRAFT_DATA: {
      const draftData = cloneDeep(state.draftData);
      return {
        ...state,
        draftData: { ...draftData, ...data },
      };
    }
    case SET_EMAILS_LOADING:
      return {
        ...state,
        emailsLoading: !!data,
      };
    case SET_EMAILS:
      return {
        ...state,
        emails: data || [],
      };
    case ADD_EMAIL:
      return {
        ...state,
        emails: [...(state.emails || []), data],
        emailId: data.id,
      };
    case SET_PREVIEW_TEMPLATE:
      return {
        ...state,
        previewTemplate: data,
      };
    case SET_STATS_LOADING:
      return {
        ...state,
        statsLoading: !!data,
      };
    case SET_STATS:
      return {
        ...state,
        stats: data,
        draftData: {
          ...state.draftData,
          selectedHubs: get(data, 'details', []).map(obj => obj.id),
        },
      };
    case SET_LOADING_TEMPLATE:
      return {
        ...state,
        loadingTemplate: data,
      };
    case REMOVE_TEMPLATE:
      if (data) {
        return {
          ...state,
          templates: state.templates.filter(template => template.id !== data),
        };
      }
      return state;
    case SET_JOB_TEMPLATES:
      return {
        ...state,
        jobTemplates: data || [],
      };
    case SET_ALL_CAMPAIGNS_LOADING:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          allCampaignsLoading: data,
        },
      };
    case SET_ALL_CAMPAIGNS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          allCampaigns: data || [],
        },
      };
    case SET_CAMPAIGN_LOADING:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignInfoLoading: data,
        },
      };
    case SET_CAMPAIGN_INFO:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          ...data
        },
      };
    case RESET_CAMPAIGN_INFO:
        return {
          ...state,
          campaign: {
            ...state.campaign,
            ...campaignInfoInitialState,
          },
    };
    case UPDATE_CAMPAIGN_INFO:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignInfo: Object.assign({}, state.campaign.campaignInfo, data),
        },
      };

    default:
      return state;
  }
};

export default automatedMarketingReducer;
