/* eslint-disable implicit-arrow-linebreak */
import { request } from '../utils/request';

export const saveProductUpdatePostApi = params =>
  request.post('/admin/product-update-posts', params);
export const updateProductUpdatePostApi = params =>
  request.put(`/admin/product-update-posts/${params.id}`, params);
export const fetchProductUpdatePostsApi = () =>
  request.get('/admin/product-update-posts');
export const fetchProductUpdatePostBasedOnIdApi = id =>
  request.get(`/admin/product-update-posts/${id}`);
export const deleteProductUpdatePostApi = id =>
  request.delete(`/admin/product-update-posts/${id}`);
