import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Row, Col, Input, Card } from 'antd';
import { cloneDeep, isNil } from 'lodash';
import Content from '../../component/Content';
import ClusterHubList from './ClusterHubList';
import notify from '../../utils/notify';

import { fetchClusterHubsForHome, openHub } from '../../actions/clusterHubs';

const { Search } = Input;

class Dashboard extends PureComponent {
  state = {
    searchText: '',
  };

  componentDidMount() {
    const { fetchClusterHubsForHome, clusterHubs } = this.props;
    if (clusterHubs.length === 0) {
      fetchClusterHubsForHome();
    }
  }

  componentWillUnmount() {
    this.setState({
      searchText: '',
    });
  }

  handleChangeInSearch = e => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleSearch = () => {
    const { searchText } = this.state;
    const { filterClusterHubs } = this.props;
    filterClusterHubs(searchText);
  };

  getDataAfterSearch = () => {
    const { searchText } = this.state;
    let { clusterHubs } = this.props;
    clusterHubs = cloneDeep(clusterHubs);
    if (searchText !== '') {
      const escapedRegEx = searchText.replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        '\\$&'
      );
      const searchRegExp = new RegExp(escapedRegEx, 'i');
      const dataToReturn = [];
      clusterHubs.map(ch => {
        let obj;
        if (!isNil(escapedRegEx) && !isNaN(parseInt(escapedRegEx, 10))) {
          if (ch.id && escapedRegEx === ch.id.toString()) obj = ch;
        } else if (
          (ch.name && searchRegExp.test(ch.name)) ||
          (ch.domain && searchRegExp.test(ch.domain))
        ) {
          obj = ch;
        }
        const hubs =
          ch.hubs &&
          ch.hubs.filter(hub => {
            if (!isNaN(+escapedRegEx)) {
              if (hub.id && escapedRegEx === hub.id.toString()) return true;
            } else if (
              (hub.name && searchRegExp.test(hub.name)) ||
              (hub.subDomain && searchRegExp.test(hub.subDomain))
            ) {
              return true;
            }
          });
        if (hubs && hubs.length !== 0) {
          if (!obj) obj = ch;
          obj.hubs = hubs;
        }
        obj && dataToReturn.push(obj);
      });
      return dataToReturn;
    } else return clusterHubs;
  };

  handleLogin = (clusterHubId, reason) => {
    if (!clusterHubId) {
      notify('ClusterHub Id not found', 'error');
      return;
    }
    const { openHub } = this.props;
    openHub({ clusterHub: clusterHubId, reason });
  };

  render() {
    const { searchText } = this.state;
    const { clusterHubsLoading } = this.props;
    const data = this.getDataAfterSearch();
    return (
      <Fragment>
        <PageHeader title="Quick Find">
          <Row justify="center">
            <Col span={24}>
              <div style={{ textAlign: 'center' }}>
                <Search
                  placeholder="Find site by domain name, hub name, hub id, cluster name, cluster id or subdomain"
                  style={{ width: 800 }}
                  value={searchText}
                  onChange={this.handleChangeInSearch}
                />
              </div>
            </Col>
          </Row>
        </PageHeader>
        <Content>
          <Card bordered={false}>
            <ClusterHubList
              data={data}
              handleLogin={this.handleLogin}
              loading={clusterHubsLoading}
            />
          </Card>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  admin,
  clusterHubs: { clusterHubs, clusterHubsLoading },
}) => ({
  admin,
  clusterHubs,
  clusterHubsLoading,
});

const mapDispatchToProps = {
  fetchClusterHubsForHome,
  openHub,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
