import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {Input, Form, Select, Checkbox, Radio, Tree, Card, Icon, Alert } from 'antd';
import memoize from 'lodash/memoize';
import { renderTreeNodes, trimTree, getRequiredFields, getDependencies, getLeaves, getLabels } from '../../../utils/checkBoxTreeUtils';
import nodes from '../HubSave/nodes';
import SelectActions from '../../../component/SelectActions';
import map from 'lodash/map';
import every from 'lodash/every';
import intersection from 'lodash/intersection';
import union from 'lodash/union';

const Option = Select.Option;

const FormItem = props  => 
  <Form.Item labelCol={{ span: 4}} wrapperCol={{ span: 6 }} {...props}/>

const labels = getLabels(nodes);

class HubForm extends PureComponent {

  requiredFields = getRequiredFields(nodes);

  dependencies = getDependencies(nodes);

  trimTree = memoize(
    clusterConfig => trimTree(nodes, clusterConfig.data.checked),
    ({ id }) => id
  );

  onNameChange = e => this.props.changeHubProp({ name: e.target.value });

  onConfigTypeChange = e => this.props.changeHubProp({configType: e.target.value});

  onHubConfigChange = hubConfigId => this.props.changeHubProp({ hubConfigId });

  onHubConfigChange = hubConfigId => this.props.changeHubProp({
    hubConfigId,
    checked: this.requiredFields,
    expanded: map(nodes, 'key'),
  })

  onHubChange = copyHubId => this.props.changeHubProp({ copyHubId });

  onCheck = checked => {
    checked = union(checked, this.requiredFields);
    checked = intersection(getLeaves(nodes), checked);
    checked = checked.filter(module => {
      const dependencies = this.dependencies[module];
      if(!dependencies) return true;
      return every(dependencies, dep => checked.includes(dep))
    });
    this.props.changeHubProp({checked})
  }

  onExpand = expanded => this.props.changeHubProp({expanded});

  onCopyUsersChange = e => this.props.changeHubProp({ copyUsers: e.target.checked });

  onIdentifierChange = e => this.props.changeHubProp({ identifier: e.target.value });

  onHubConfigFilter = (input, option) => {
    const { hubConfigs } = this.props;
    const hubConfig=  hubConfigs.find(c => c.id === parseInt(option.key))
    return hubConfig.name.toLowerCase().includes(input.toLowerCase()) 
  }

  onHubFilter = (input, option) => {
    const hub = this.props.hubs.find(c => c.id === parseInt(option.key))
    return hub.name.toLowerCase().includes(input.toLowerCase()) 
  }

  renderConfigSelectDropdown = () => {
    const { hubTabKey, newHubs, hubs, hubsLoading, hubConfigs, hubConfigsLoading } = this.props;
    const { configType, copyHubId, hubConfigId, copyUsers } = newHubs.find(h => h.key == hubTabKey);
    if(configType === 'hubConfig')
      return ( 
        <FormItem label="hub configuration">
          <Select
            style={{ width: 300 }}
            showSearch
            placeholder="Select Hub Config"
            onChange={this.onHubConfigChange}
            filterOption={this.onHubConfigFilter}
            loading={hubConfigsLoading}
            value={hubConfigId}
          >
            { hubConfigs.filter(h => h.status === 'done').map(config => (
              <Option key={config.id} value={config.id}> {config.name} </Option>
            ))} 
          </Select> 
        </FormItem>
      );
    else if(configType === 'copy')
      return ( 
        <div>
          <FormItem label="Hub to copy">
            <Select
              style={{ width: 300 }}
              showSearch
              placeholder="Select Hub"
              onChange={this.onHubChange}
              filterOption={this.onHubFilter}
              loading={hubsLoading}
              value={copyHubId}
            >
              { hubs.map(hub => (
                <Option key={hub.id} value={hub.id}> {hub.name} </Option>
              ))} 
            </Select> 
          </FormItem>
          <FormItem label="Copy Users and Content">
            <Checkbox 
              value={copyUsers}
              onChange={this.onCopyUsersChange}
            />
          </FormItem>
        </div>
      );
    else return null;
  }

  onSelectAll = () => {
    const { hubConfigs, hubTabKey, newHubs } = this.props;
    const { hubConfigId } = newHubs.find(h => h.key == hubTabKey);
    let hubConfig = hubConfigs.find(cc => cc.id === hubConfigId);
    this.onCheck(getLeaves(this.trimTree(hubConfig)))
  }

  onDeselectAll = () => this.onCheck([])

  renderHubConfigTree = () => {
    const { hubConfigs, hubTabKey, newHubs } = this.props;
    const { configType, hubConfigId, checked, expanded } = newHubs.find(h => h.key == hubTabKey);

    if ( configType === 'hubConfig' && hubConfigId ) {
      let hubConfig = hubConfigs.find(cc => cc.id === hubConfigId);
      return (
        <Card title={<div style={{fontSize: '14px', color: 'rgba(0,0,0,0.45)'}}>Select settings to import</div>} >
          <SelectActions
            onSelectAll={this.onSelectAll}
            onDeselectAll={this.onDeselectAll}
          />
          <Tree
            checkable
            onExpand={this.onExpand}
            onCheck={this.onCheck}
            expandedKeys={expanded}
            checkedKeys={checked}
            selectedKeys={[]}
          >
            {renderTreeNodes(this.trimTree(hubConfig), labels)}
          </Tree>
        </Card>
      )
    } return null;
  }

  render() {
    const { hubTabKey, newHubs, selectedEnabledGroup, isV2Present } = this.props;
    const { name, identifier, configType } = newHubs.find(
      h => h.key == hubTabKey
    );

    return (
      <div>
        {isV2Present && (
          <Alert
            message="A V2 hub already exists in this cluster. Please create a new cluster to proceed"
            type="error"
            showIcon
          />
        )}
        <Form layout="horizontal" labelAlign="left">
          <FormItem label="Hub Name">
            <Input
              placeholder="University Connect"
              value={name}
              disabled={isV2Present}
              onChange={this.onNameChange}
            />
          </FormItem>
          <FormItem
            label="Launch Group"
            help="Update selected cluster launch group to reflect changes here"
          >
            <Input
              placeholder="peoplegrove"
              value={selectedEnabledGroup?.name}
              disabled
            />
          </FormItem>
          <FormItem
            label="Hub Identifier (Optional)"
            help="Should be a combination of alphabets, numbers or underscore"
          >
            <Input
              placeholder="university-connect"
              value={identifier}
              disabled={isV2Present}
              onChange={this.onIdentifierChange}
            />
          </FormItem>
          <FormItem label="Hub Configuration" wrapperCol={{ span: 12 }}>
            <Radio.Group
              value={configType}
              onChange={this.onConfigTypeChange}
              disabled={isV2Present}
            >
              {!selectedEnabledGroup?.forStandard && (
                <Radio value="none">None</Radio>
              )}
              <Radio value="copy">Copy Hub</Radio>
              <Radio value="hubConfig">Use Hub Config</Radio>
            </Radio.Group>
          </FormItem>
        </Form>
        {this.renderConfigSelectDropdown()}
        {this.renderHubConfigTree()}
      </div>
    );
  }
}
export default HubForm;
