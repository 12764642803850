import DOMPurify from 'dompurify';

DOMPurify.addHook('uponSanitizeAttribute', function(node, hookData) {
  /*
    Keeps target attribute of tags with target='_blank'
   */
  if ('target' in node) {
    const { attrName, attrValue } = hookData;
    if (attrName === 'target' && attrValue === '_blank') {
      hookData.forceKeepAttr = true;
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', function(node) {
  /*
    Appends rel='noopener' for tags with target='_blank'
   */
  if (
    'target' in node &&
    (node.getAttribute('target') || '').includes('_blank')
  ) {
    const relAttr = node.getAttribute('rel') || '';
    if (!relAttr.includes('noopener'))
      node.setAttribute('rel', `noopener ${relAttr}`.trim());
  }
});

const sanitize = (input, params) => {
  params = params
    ? Object.assign(params, {
        FORBID_TAGS: ['form', 'input'],
        FORBID_ATTR: ['action'],
      })
    : {
        FORBID_TAGS: ['form', 'input'],
        FORBID_ATTR: ['action'],
      };
  return DOMPurify.sanitize(input, params);
};

export default DOMPurify;
export { sanitize };
