import { UPDATING_ALL, UPDATING_USERS } from '../actions';

const initialState = {
  updatingAll: false,
  updatingUsers: false,
};

const cacheReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case UPDATING_ALL:
      return {
        ...state,
        updatingAll: data,
      };
    case UPDATING_USERS:
      return {
        ...state,
        updatingUsers: data,
      };
    default:
      return state;
  }
};

export default cacheReducer;
