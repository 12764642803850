import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import HubText from './HubText';
import ClusterText from './ClusterText';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

import './index.scss';

const DefaultTexts = () => (
  <>
    <Switch>
      <PrivateRoute accessGroups={['cluster-hub-text']} exact path="/default-text" key="defaultText">
        <Redirect to="/default-text/hub" />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/default-text/hub"
        key="defaultHubText"
        component={HubText}
        accessGroups={['cluster-hub-text']}
      />
      <PrivateRoute
        exact
        path="/default-text/cluster"
        key="defaultClusterText"
        component={ClusterText}
        accessGroups={['cluster-hub-text']}
      />
    </Switch>
  </>
);

export default DefaultTexts;
