import React from 'react';
import { connect } from 'react-redux';
import { PageHeader, Card, Input, Button } from 'antd';
import Content from '../../component/Content';
import { setUserHubIds, processUpsertUsers } from './actions';

const { TextArea } = Input;

class RefreshES extends React.Component {
  handleTextAreaChange = e => {
    const { setUserHubIds } = this.props;
    setUserHubIds(e.target.value);
  };

  handleUpsertUsers = () => {
    const { processUpsertUsers } = this.props;
    processUpsertUsers();
  };

  render() {
    const { userHubIds, upsertLoading, notFoundIds } = this.props;
    return (
      <div>
        <PageHeader title="Refresh Elastic Search Document" />
        <Content>
          <Card>
            <h3>Upsert user document of specific user hub id's.</h3>
            <h4>Enter comma separated userHubIds</h4>
            <TextArea
              placeholder="For example - 12345, 345678, 987654"
              rows={3}
              value={userHubIds}
              style={{ marginBottom: 20 }}
              onChange={this.handleTextAreaChange}
            />
            {notFoundIds && notFoundIds.length > 0 ? (
              <p style={{ color: 'red' }}>
                Could not process following user hub id's:{' '}
                {notFoundIds.join(', ')}
              </p>
            ) : (
              <div style={{ height: '20px', marginBottom: '10px' }} />
            )}
            <Button
              type="primary"
              onClick={this.handleUpsertUsers}
              disabled={upsertLoading || !userHubIds}
            >
              Upsert Users
            </Button>
          </Card>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({ esDocument }) => {
  const { userHubIds, upsertLoading, notFoundIds } = esDocument;
  return {
    userHubIds,
    upsertLoading,
    notFoundIds,
  };
};

const mapDispatchToProps = {
  setUserHubIds,
  processUpsertUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefreshES);
