/* eslint-disable react/self-closing-comp */
/* eslint-disable no-shadow */
import {
  Tabs,
  Button,
  Input,
  Switch,
  Table,
  Modal,
  Alert,
  Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import {
  setFormData,
  fetchTemplates,
  fetchTips,
  setTableData,
  updateFormData,
  fetchAlumStudentOutreachTemplates,
} from '../../actions';
import {
  updateTemplateApi,
  updateTipApi,
} from '../../../../api/templatesAndTips';
import useAsyncFetch from '../../../../hooks/useAsyncFetch';
import { notify } from '../../../../utils/notify';
import { sanitize } from '../../../../utils/dompurify';

const { TabPane } = Tabs;

const tableTabs = [
  {
    key: 'template',
    title: 'Templates',
  },
  {
    key: 'tip',
    title: 'Tips',
  },
  {
    key: 'alum-student-outreach-templates',
    title: 'Alum Student Outreach Templates',
  },
];

// const pgLog = (...args) => console.log(30202, ...args);

const Manage = ({
  setActiveForm,
  topActionWrapperRef,
  activeTab,
  setActiveTab,
}) => {
  const dispatch = useDispatch();

  const { tableLoading, tableData, tableKey } = useSelector(
    ({ templatesAndTips }) => templatesAndTips
  );

  const { refetch: reSaveTemplateApi } = useAsyncFetch(
    false,
    updateTemplateApi,
    undefined,
    {
      onError: () => {
        notify(`Couldn't update template!`, 'error');
      },
      onSuccess: () => {
        notify(`Template updated successfully!`, 'success');
      },
    }
  );

  const { refetch: reSaveTipApi } = useAsyncFetch(
    false,
    updateTipApi,
    undefined,
    {
      onError: () => {
        notify(`Couldn't update tip!`, 'error');
      },
      onSuccess: () => {
        notify(`Tip updated successfully!`, 'success');
      },
    }
  );

  useEffect(() => {
    if (activeTab === 'template') dispatch(fetchTemplates());
    if (activeTab === 'tip') dispatch(fetchTips());
    if (activeTab === 'alum-student-outreach-templates')
      dispatch(fetchAlumStudentOutreachTemplates());
  }, [activeTab, dispatch]);

  const onFilter = (selectedKeys, confirm) => {
    confirm();
  };

  const handleEdit = record => {
    topActionWrapperRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    dispatch(setFormData(record));
    setActiveForm(activeTab); // template | tip | alum-student-outreach-templates
  };

  const makeColumnsByTab = activeTab => {
    switch (activeTab) {
      case 'template':
        return [
          {
            title: 'Internal Name',
            dataIndex: 'internalName',
            key: 'internalName',
            width: 291,
            sorter: true,
            sortDirections: ['ascend', 'descend', false],
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder="Search by name"
                  value={selectedKeys[0]}
                  onChange={e =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                  type="primary"
                  onClick={() => onFilter(selectedKeys, confirm)}
                  icon="search"
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    clearFilters();
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </div>
            ),
            ellipsis: true,
            render: val => (
              <div className="templates-and-tips-ellipsis-cell">
                <Tooltip placement="topLeft" title={val}>
                  {val}
                </Tooltip>
              </div>
            ),
          },
          {
            title: 'Created on',
            dataIndex: 'createdAt',
            sorter: true,
            width: 130,
            key: 'createdAt',
          },
          {
            title: 'Updated on',
            dataIndex: 'updatedAt',
            sorter: true,
            width: 130,
            key: 'updatedAt',
          },
          {
            title: 'Created for',
            dataIndex: 'showFor',
            filters: [
              { text: 'Mentee', value: 'mentee' },
              { text: 'Mentor', value: 'mentor' },
              { text: 'All', value: 'all' },
            ],
            key: 'showFor',
            width: 130,
          },
          {
            title: 'Status ',
            width: 130,
            dataIndex: 'active',
            key: 'active',
            render: (_, record) => (
              <Switch
                checked={record.active}
                onChange={value => {
                  reSaveTemplateApi({ id: record.id, active: value });
                  dispatch(updateFormData({ active: value }));
                  dispatch(
                    setTableData({
                      ...tableData,
                      inboxTemplates: tableData.inboxTemplates.map(td => ({
                        ...td,
                        active: td.id === record.id ? value : td.active,
                      })),
                    })
                  );
                }}
              />
            ),
          },
        ];
      case 'tip':
        return [
          {
            title: 'Internal Tip Name',
            dataIndex: 'internalTipName',
            key: 'internalTipName',
            width: 291,
            sorter: true,
            sortDirections: ['ascend', 'descend', false],
            ellipsis: true,
            render: val => (
              <div className="templates-and-tips-ellipsis-cell">
                <Tooltip placement="topLeft" title={val}>
                  {val}
                </Tooltip>
              </div>
            ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder="Search by name"
                  value={selectedKeys[0]}
                  onChange={e =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                  type="primary"
                  onClick={() => onFilter(selectedKeys, confirm)}
                  icon="search"
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    clearFilters();
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
              </div>
            ),
          },
          {
            title: 'Created on',
            dataIndex: 'createdAt',
            sorter: true,
            key: 'createdAt',
            width: 130,
          },
          {
            title: 'Updated on',
            dataIndex: 'updatedAt',
            sorter: true,
            width: 130,
            key: 'updatedAt',
          },
          {
            title: 'Tip Text',
            dataIndex: 'tip',
            key: 'tip',
            width: 100,
            render: (_, record) => (
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() =>
                  Modal.info({
                    content: (
                      <Alert
                        style={{ marginBottom: 24 }}
                        message={
                          <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: sanitize(record?.tip || ''),
                            }}
                          ></div>
                        }
                        type="info"
                      />
                    ),
                    icon: null,
                    onOk() {},
                    okText: 'Close',
                  })
                }
              >
                View Tip
              </Button>
            ),
          },
          {
            title: 'Status ',
            dataIndex: 'active',
            key: 'active',
            width: 130,
            render: (_, record) => (
              <Switch
                checked={record.active}
                onChange={value => {
                  reSaveTipApi({ id: record.id, active: value });
                  dispatch(updateFormData({ active: value }));
                  dispatch(
                    setTableData({
                      ...tableData,
                      inboxTips: tableData.inboxTips.map(td => ({
                        ...td,
                        active: td.id === record.id ? value : td.active,
                      })),
                    })
                  );
                }}
              />
            ),
          },
        ];
      case 'alum-student-outreach-templates':
        return [
          {
            title: 'Template Name',
            dataIndex: 'name',
            key: 'name',
            width: 291,
            sorter: true,
            sortDirections: ['ascend', 'descend', false],
            ellipsis: true,
            render: val => (
              <div className="templates-and-tips-ellipsis-cell">
                <Tooltip placement="topLeft" title={val}>
                  {val}
                </Tooltip>
              </div>
            ),
          },
          {
            title: 'Created on',
            dataIndex: 'createdAt',
            sorter: true,
            width: 130,
            key: 'createdAt',
          },
          {
            title: 'Updated on',
            dataIndex: 'updatedAt',
            sorter: true,
            width: 130,
            key: 'updatedAt',
          },
          {
            title: 'Status ',
            width: 130,
            dataIndex: 'active',
            key: 'active',
            render: (_, record) => (
              <Switch
                checked={record.active}
                onChange={value => {
                  reSaveTemplateApi({ id: record.id, active: value });
                  dispatch(updateFormData({ active: value }));
                  dispatch(
                    setTableData({
                      ...tableData,
                      inboxTemplates: tableData.inboxTemplates.map(td => ({
                        ...td,
                        active: td.id === record.id ? value : td.active,
                      })),
                    })
                  );
                }}
              />
            ),
          },
        ];
      default:
        return [];
    }
  };

  const formatTemplateData = data =>
    data.map(({ id: key, createdAt, updatedAt, ...rest }) => ({
      id: key, // needed for putApi calls
      key, // needed by antD table
      createdAt: new Date(createdAt).toLocaleDateString(),
      updatedAt: new Date(updatedAt).toLocaleDateString(),
      ...rest,
    }));

  const formatTipsData = data =>
    data.map(({ id: key, createdAt, updatedAt, tipText: tip, ...rest }) => ({
      id: key, // needed for putApi calls
      key, // needed by antD table
      createdAt: new Date(createdAt).toLocaleDateString(),
      updatedAt: new Date(updatedAt).toLocaleDateString(),
      tip,
      ...rest,
    }));

  const formatAlumStudentOutreachTemplatesData = data =>
    data.map(({ id: key, createdAt, updatedAt, ...rest }) => ({
      id: key, // needed for putApi calls
      key, // needed by antD table
      createdAt: new Date(createdAt).toLocaleDateString(),
      updatedAt: new Date(updatedAt).toLocaleDateString(),
      ...rest,
    }));

  const onTableChange = (pagination, filters, sorter) => {
    const filterParams = {
      searchText:
        activeTab === 'tip'
          ? filters?.internalTipName?.[0]
          : filters?.internalName?.[0],
      showFor: filters?.showFor,
      sort: { [sorter.field]: sorter.order },
      page:
        pagination.current === Number(tableData?.page) ? 1 : pagination.current,
    };

    if (activeTab === 'template') dispatch(fetchTemplates(filterParams));
    if (activeTab === 'tip') dispatch(fetchTips(filterParams));
    if (activeTab === 'alum-student-outreach-templates')
      dispatch(fetchAlumStudentOutreachTemplates(filterParams));
  };

  const getTableData = tab => {
    switch (tab) {
      case 'template':
        return formatTemplateData(tableData?.inboxTemplates || []);
      case 'tip':
        return formatTipsData(tableData?.inboxTips || []);
      case 'alum-student-outreach-templates':
        return formatAlumStudentOutreachTemplatesData(
          tableData?.alumStudentOutreachTemplates || []
        );
      default:
        return {};
    }
  };

  return (
    <div className="manage-table-container">
      <Tabs type="card" activeKey={activeTab} onChange={e => setActiveTab(e)}>
        {tableTabs.map(({ key, title }) => (
          <TabPane tab={title} key={key}>
            <Table
              key={tableKey}
              dataSource={getTableData(key)}
              scroll={{ x: 500 }}
              loading={tableLoading}
              columns={makeColumnsByTab(activeTab)}
              onChange={onTableChange}
              onRowClick={handleEdit}
              pagination={{
                simple: false,
                total: parseInt(tableData?.total, 10),
                current: Number(tableData?.page),
                pageSize: 10,
              }}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Manage;
