import moment from 'moment';
import {
  SET_HUB_BANNER_TITLE,
  SET_HUB_BANNER_ACTIVE,
  SET_HUB_BANNER_DESCRIPTION,
  SET_HUB_BANNER_CTA_TEXT,
  SET_HUB_BANNER_IMAGE,
  SET_HUB_BANNER_START_DATE,
  SET_HUB_BANNER_END_DATE,
  SET_SELECTED_HUB_ID,
  SET_SELECTED_LAUNCH_GROUP,
  SET_BANNER_LIST,
  SET_BANNER_LIST_LOADING,
  SET_BANNER_FORM_LOADING,
  CLEAR_CONFIGURATION_FORM,
  SET_HUB_BANNER_LINK_OPTION,
  SET_HUB_BANNER_CTA_LINK,
  SET_HUB_BANNER_COVER_MEDIA_TYPE,
  SET_HUB_BANNER_VIDEO,
  SET_IFRAME_CODE_LOADING,
  SET_ALL_CUSTOM_IMAGES,
  SET_LOADING_ALL_CUSTOM_IMAGES,
} from '../actions/configureBanner';

const initialState = {
  hubBannerActive: false,
  hubBannerTitle: '',
  hubBannerDescription: '',
  hubBannerCta: '',
  hubBannerCtaLink: '',
  hubBannerCtaLinkOption: false,
  hubBannerCoverMediaType: 'image',
  hubBannerVideo: '',
  hubBannerImage: '',
  hubBannerStartDate: moment(),
  hubBannerEndDate: moment().add(1, 'M'),
  selectedHubId: [],
  selectedLaunchGroups: [],
  bannerList: [],
  bannerListLoading: false,
  bannerFormLoading: false,
  iframeCodeLoading: false,
  loadingAllCustomImages: false,
  usedCustomImages: [],
}

const configureBanner = (state = initialState, { type, data }) => {
  switch(type) {
    case SET_HUB_BANNER_TITLE:
      return {
        ...state,
        hubBannerTitle: data
      }
    case SET_HUB_BANNER_ACTIVE:
        return {
          ...state,
          hubBannerActive: data
        }
    case SET_HUB_BANNER_DESCRIPTION:
      return {
        ...state,
        hubBannerDescription: data
      }
    case SET_HUB_BANNER_CTA_TEXT:
      return {
        ...state,
        hubBannerCta: data
      }
    case SET_HUB_BANNER_COVER_MEDIA_TYPE:
      return {
        ...state,
        hubBannerCoverMediaType: data
      }
    case SET_HUB_BANNER_VIDEO:
      return {
        ...state,
        hubBannerVideo: data
      }
    case SET_HUB_BANNER_IMAGE:
      return {
        ...state,
        hubBannerImage: data
      }
    case SET_HUB_BANNER_START_DATE:
      return {
        ...state,
        hubBannerStartDate: data
      }
    case SET_HUB_BANNER_END_DATE:
      return {
        ...state,
        hubBannerEndDate: data
      }
    case SET_SELECTED_HUB_ID:
      return {
        ...state,
        selectedHubId: data,
      }
    case SET_SELECTED_LAUNCH_GROUP:
      return {
        ...state,
        selectedLaunchGroups: data,
      }
    case SET_BANNER_LIST:
      return {
        ...state,
        bannerList: data,
      }
    case SET_BANNER_LIST_LOADING:
      return {
        ...state,
        bannerListLoading: data,
      }
    case SET_BANNER_FORM_LOADING:
      return {
        ...state,
        bannerFormLoading: data,
      }
    case CLEAR_CONFIGURATION_FORM:
      return {
        ...state,
        hubBannerActive: false,
        hubBannerTitle: '',
        hubBannerDescription: '',
        hubBannerCta: '',
        hubBannerCtaLink: '',
        hubBannerCoverMediaType: 'image',
        hubBannerVideo: '',
        hubBannerImage: '',
        hubBannerCtaLinkOption: false,
        hubBannerStartDate: moment(),
        hubBannerEndDate: moment().add(1, 'M'),
        selectedHubId: [],
        selectedLaunchGroups: []
      }
    case SET_HUB_BANNER_LINK_OPTION:
      return {
        ...state,
        hubBannerCtaLinkOption: data,
      }
    case SET_HUB_BANNER_CTA_LINK:
      return {
        ...state,
        hubBannerCtaLink: data,
      }
    case SET_IFRAME_CODE_LOADING:
      return {
        ...state,
        iframeCodeLoading: data,
      };
    case SET_ALL_CUSTOM_IMAGES:
      return {
        ...state,
        usedCustomImages: data,
      };
    case SET_LOADING_ALL_CUSTOM_IMAGES:
      return {
        ...state,
        loadingAllCustomImages: data,
      };
    default: 
      return state
  }
}

export default configureBanner;
