/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Input, Button, Table, Modal } from 'antd';
import {
  fetchUserDetail,
  deleteUserDetail,
  unlinkFacebook,
  unlinkLinkedin,
  unsetDatabaseKey,
  unsetSsoId,
  undeleteUserHub,
} from './actions';
import s from './UserDetail.module.scss';
import Loader from '../../component/Loader/Loader';
import { getLocaleDateTimeString } from '../../utils/helpers';
import uuid from '../../utils/uuid';

class UserDetailContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      detailFetchedForEmail: '',
      showConfirmationModal: false,
      confirmationModalMessage: '',
      currentActionData: {},
      currentActionType: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userDetail = {} } = this.props;
    const { detailFetchedForEmail } = this.state;
    if (userDetail && prevProps.userDetail) {
      if (
        userDetail.userFetchstatus === 'success' &&
        prevProps.userDetail.userFetchstatus === 'process'
      ) {
        // If user detail has been fetched succesfully, then save, for which email we are showing the detail on page.
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          detailFetchedForEmail: userDetail.user[0] && userDetail.user[0].email,
        });
      }
      if (
        userDetail.userActionStatus === 'success' &&
        prevProps.userDetail.userActionStatus === 'process'
      ) {
        // If user action is succesfull then refetch the user detail to show latest detail on page.
        this.fetchUserInfo(null, detailFetchedForEmail);
      }
    }
  }

  setEmail = e => {
    this.setState({ email: e.target.value });
  };

  fetchUserInfo = (e, passedEmail) => {
    const { email } = this.state;
    const { fetchUserDetailAction } = this.props;
    const emailToCheck = passedEmail || email;
    emailToCheck &&
      fetchUserDetailAction({
        email: encodeURIComponent(emailToCheck.trim()),
      });
  };

  preventDefault = e => {
    e.preventDefault();
  };

  onModalConfirm = () => {
    const { currentActionType, currentActionData } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    this.props[currentActionType](currentActionData);
    this.onModalCancel();
  };

  onModalCancel = () => {
    this.setState({
      showConfirmationModal: false,
      confirmationModalMessage: '',
      currentActionData: {},
      currentActionType: '',
    });
  };

  showConfirmationModal = confirmationModalMessage => {
    this.setState({ showConfirmationModal: true, confirmationModalMessage });
  };

  renderConfirmationModal = () => {
    const { showConfirmationModal, confirmationModalMessage } = this.state;
    return (
      <Modal
        visible={showConfirmationModal}
        title="Confirm?"
        onCancel={this.onModalCancel}
        footer={[
          <Button key="back" onClick={this.onModalCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={this.onModalConfirm}>
            Yes
          </Button>,
        ]}
      >
        <p>{confirmationModalMessage || 'Are you sure?'}</p>
      </Modal>
    );
  };

  confirmDeleteUser = userId => () => {
    this.setState(
      { currentActionData: { userId }, currentActionType: 'deleteUserDetail' },
      () => {
        this.showConfirmationModal(
          'This action will delete this user from all the clusters and hubs it belongs to. Are you sure you want to delete this user?'
        );
      }
    );
  };

  confirmDeleteCluster = (userId, clusterId) => () => {
    this.setState(
      {
        currentActionData: { userId, clusterId },
        currentActionType: 'deleteUserDetail',
      },
      () => {
        this.showConfirmationModal(
          'This action will delete this user from this cluster and all the hubs in this cluster. Are you sure you want to delete the user from this cluster?'
        );
      }
    );
  };

  confirmDeleteHub = (userId, clusterId, hubId) => () => {
    this.setState(
      {
        currentActionData: { userId, clusterId, hubId },
        currentActionType: 'deleteUserDetail',
      },
      () => {
        this.showConfirmationModal(
          'Are you sure you want to delete the user from this hub?'
        );
      }
    );
  };

  confirmUndeleteHub = (userId, clusterId, hubId) => () => {
    const { admin = {} } = this.props;
    const { id: adminId } = admin;
    this.setState(
      {
        currentActionData: { userId, clusterId, hubId, adminId },
        currentActionType: 'undeleteUserHub',
      },
      () => {
        this.showConfirmationModal(
          'This action will make the user active in the given hub & corresponding cluster. Are you sure you want to undelete the user from this hub?'
        );
      }
    );
  };

  confirmUnlinkFacebook = (userId, clusterId) => () => {
    this.setState(
      {
        currentActionData: { userId, clusterId },
        currentActionType: 'unlinkFacebook',
      },
      () => {
        this.showConfirmationModal('Are you sure you want to unlink facebook?');
      }
    );
  };

  confirmUnlinkLinkedin = (userId, clusterId) => () => {
    this.setState(
      {
        currentActionData: { userId, clusterId },
        currentActionType: 'unlinkLinkedin',
      },
      () => {
        this.showConfirmationModal('Are you sure you want to unlink linkedin?');
      }
    );
  };

  confirmUnsetDatabasekey = (userId, clusterId) => () => {
    this.setState(
      {
        currentActionData: { userId, clusterId },
        currentActionType: 'unsetDatabaseKey',
      },
      () => {
        this.showConfirmationModal(
          'Are you sure you want to unset database key?'
        );
      }
    );
  };

  confirmUnsetSsoId = (userId, clusterId) => () => {
    this.setState(
      {
        currentActionData: { userId, clusterId },
        currentActionType: 'unsetSsoId',
      },
      () => {
        this.showConfirmationModal('Are you sure you want to unset sso id?');
      }
    );
  };

  renderUserDetail = usr => {
    const columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: k => k || '-',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        render: k => k || '-',
      },
      {
        title: 'User Id',
        dataIndex: 'userId',
        key: 'userId',
        render: k => k || '-',
      },
      {
        title: 'Has Password?',
        key: 'hasPassword',
        dataIndex: 'hasPassword',
        render: k => (k ? 'Yes' : 'No'),
      },
      {
        title: 'Deleted At',
        key: 'deletedAt',
        dataIndex: 'deletedAt',
        render: k =>
          k ? (
            getLocaleDateTimeString(k)
          ) : (
            <Button type="danger" onClick={this.confirmDeleteUser(usr.userId)}>
              Delete
            </Button>
          ),
      },
    ];
    return <Table columns={columns} dataSource={[usr]} pagination={false} />;
  };

  renderClusterDetail = (clstr, user) => {
    const columns = [
      {
        title: 'Avatar',
        dataIndex: 'photoUrl',
        key: 'photoUrl',
        render: k => <img src={k} width="40px" height="40px" alt="Avatar" />,
      },
      {
        title: 'Cluster Id',
        dataIndex: 'clusterId',
        key: 'clusterId',
        render: k => k || '-',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: k => k || '-',
      },
      {
        title: 'Facebook Id',
        key: 'facebookId',
        dataIndex: 'facebookId',
        render: (k, r) =>
          k ? (
            <div>
              {k} (
              <span
                className={s['user-detail__unlink']}
                onClick={this.confirmUnlinkFacebook(user.userId, r.clusterId)}
                role="button"
              >
                Unlink
              </span>
              )
            </div>
          ) : (
            '-'
          ),
      },
      {
        title: 'Linkedin Id',
        key: 'linkedinId',
        dataIndex: 'linkedinId',
        render: (k, r) =>
          k ? (
            <div>
              {k} (
              <span
                className={s['user-detail__unlink']}
                onClick={this.confirmUnlinkLinkedin(user.userId, r.clusterId)}
                role="button"
              >
                Unlink
              </span>
              )
            </div>
          ) : (
            '-'
          ),
      },
      {
        title: 'Database Key',
        key: 'databaseKey',
        dataIndex: 'databaseKey',
        render: (k, r) =>
          k ? (
            <div>
              {k} (
              <span
                className={s['user-detail__unlink']}
                onClick={this.confirmUnsetDatabasekey(user.userId, r.clusterId)}
                role="button"
              >
                Unset
              </span>
              )
            </div>
          ) : (
            '-'
          ),
      },
      {
        title: 'SSO Id',
        key: 'ssoId',
        dataIndex: 'ssoId',
        render: (k, r) =>
          k ? (
            <div>
              {k} (
              <span
                className={s['user-detail__unlink']}
                onClick={this.confirmUnsetSsoId(user.userId, r.clusterId)}
                role="button"
              >
                Unset
              </span>
              )
            </div>
          ) : (
            '-'
          ),
      },
      {
        title: 'Deleted At',
        key: 'deletedAt',
        dataIndex: 'deletedAt',
        render: (k, r) =>
          k ? (
            getLocaleDateTimeString(k)
          ) : (
            <Button
              type="danger"
              onClick={this.confirmDeleteCluster(user.userId, r.clusterId)}
            >
              Delete
            </Button>
          ),
      },
    ];
    return (
      <div className={s['user-detail__subcontent--margin']}>
        <div className={s['user-detail__user--margin']}>Clusters</div>
        <Table
          columns={columns}
          rowClassName={(record, index) => s['user-detail__cluster--label']}
          dataSource={clstr}
          pagination={false}
          expandedRowRender={this.renderHubDetail(user)}
        />
      </div>
    );
  };

  renderHubDetail = user => clstr => {
    const hub = clstr.hubs;
    // Logic to check if any hub with same hubId is active, then don't show undelete button for those hubIds
    const hubDeleteStatus = {};
    // Set key as hubId and value as true (if not deleted) else false.
    const processedHubs = hub.map(h => {
      let toShowUndelete = false;
      if (!(h.hubId in hubDeleteStatus) || hubDeleteStatus[h.hubId]) {
        // Add to dictionary if hubId not present in list.
        // OR
        // Update current hub value if hubId's value was previously set to true (Show undeleted) [Check if hub is already active]
        toShowUndelete = !!h.deletedAt;
        hubDeleteStatus[h.hubId] = toShowUndelete;
      }
      return { ...h, toShowUndelete };
    });

    const columns = [
      {
        title: 'Hub Id',
        dataIndex: 'hubId',
        key: 'hubId',
        render: k => k || '-',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: k => k || '-',
      },
      {
        title: 'Created At',
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: k => getLocaleDateTimeString(k) || '-',
      },
      {
        title: 'Signup Stage',
        key: 'signupStage',
        dataIndex: 'signupStage',
        render: k => k || '-',
      },
      {
        title: 'Import Status',
        key: 'importStatus',
        dataIndex: 'importStatus',
        render: k => k || '-',
      },
      {
        title: 'Deleted At',
        key: 'deletedAt',
        dataIndex: 'deletedAt',
        render: (k, r) =>
          k ? (
            <div>
              {getLocaleDateTimeString(k)}
              &nbsp; &nbsp; &nbsp;
              {r.toShowUndelete && (
                <Button
                  type="danger"
                  onClick={this.confirmUndeleteHub(
                    user.userId,
                    clstr.clusterId,
                    r.hubId
                  )}
                >
                  Undelete
                </Button>
              )}
            </div>
          ) : (
            <Button
              type="danger"
              onClick={this.confirmDeleteHub(
                user.userId,
                clstr.clusterId,
                r.hubId
              )}
            >
              Delete
            </Button>
          ),
      },
    ];
    return (
      <div className={s['user-detail__subcontent--margin']}>
        <div className={s['user-detail__user--margin']}>Hubs</div>
        <Table
          columns={columns}
          dataSource={processedHubs}
          pagination={false}
        />
      </div>
    );
  };

  renderUserList = usr => {
    const { userDetail = {} } = this.props;
    const { user } = userDetail;
    if (
      userDetail &&
      (userDetail.userFetchstatus === 'process' ||
        userDetail.userActionStatus === 'process')
    ) {
      return <Loader />;
    }
    if (userDetail && userDetail.userFetchstatus === 'success') {
      if (user && user.length) {
        return user.map((userObject, i) => (
          <div key={uuid()} className={s['user-detail__user-container']}>
            <div className={s['user-detail__user--margin']}>Users</div>
            {this.renderUserDetail(userObject)}
            {this.renderClusterDetail(userObject.clusters, userObject)}
          </div>
        ));
      }
      return <h2>No user found for this email.</h2>;
    }
    return <h2>Type email id to see user detail.</h2>;
  };

  render() {
    const { email } = this.state;
    return (
      <div className={s['user-detail__container']}>
        <div className={s['user-detail__inputs']}>
          <form onSubmit={this.preventDefault}>
            <Input
              className={s['user-detail__inputs--input']}
              placeholder="Enter the email"
              value={email}
              onChange={this.setEmail}
            />
            <Button
              htmlType="submit"
              className={s['user-detail__inputs--button']}
              type="primary"
              onClick={this.fetchUserInfo}
            >
              Submit
            </Button>
          </form>
        </div>
        <div>{this.renderUserList()}</div>
        {this.renderConfirmationModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ userDetail, admin }) => ({
  userDetail,
  admin,
});

const mapDispatchToProps = dispatch => ({
  fetchUserDetailAction: params => dispatch(fetchUserDetail(params)),
  deleteUserDetail: params => dispatch(deleteUserDetail(params)),
  unlinkFacebook: params => dispatch(unlinkFacebook(params)),
  unlinkLinkedin: params => dispatch(unlinkLinkedin(params)),
  unsetDatabaseKey: params => dispatch(unsetDatabaseKey(params)),
  unsetSsoId: params => dispatch(unsetSsoId(params)),
  undeleteUserHub: params => dispatch(undeleteUserHub(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailContainer);
