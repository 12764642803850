/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PageHeader, Card, Row, Col } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import { notify } from '../../utils/notify';
import Manage from './components/Manage';
import CreateTemplate from './components/CreateTemplate';
import CreateTip from './components/CreateTip';
import CustomUserTemplate from './components/CustomUserTemplates';

import useAsyncFetch from '../../hooks/useAsyncFetch';
import {
  fetchThreadStages,
  fetchPreconnectionTemplates,
  fetchConnectionSources,
  fetchTemplateModelKeywordsData,
  deleteTemplateApi,
  deleteTipApi,
  deleteAlumStudentOutreachTemplateApi,
} from '../../api/templatesAndTips';
import {
  fetchAlumStudentOutreachTemplates,
  fetchTemplates,
  fetchTips,
  setFormData,
  setTableData,
} from './actions';
import './index.scss';
import CreateAlumStudentOutreachTemplate from './components/CreateAlumStudentOutreachTemplate';

const TemplatesAndTips = () => {
  const topActionWrapperRef = useRef();
  const dispatch = useDispatch();
  const [activeForm, setActiveForm] = useState(null);
  const [activeTab, setActiveTab] = useState('template'); // table to show
  const { view } = useParams();
  const history = useHistory();

  const { tableData, formData } = useSelector(
    ({ templatesAndTips }) => templatesAndTips
  );
  const { response: connectionSources, isLoading: connectionSourcesLoading } =
    useAsyncFetch(true, fetchConnectionSources, undefined, {
      onError: () => {
        notify(`Couldn't fetch connection sources!`, 'error');
      },
    });

  const {
    response: preConnectionTemplates,
    isLoading: preConnectionTemplatesLoading,
  } = useAsyncFetch(true, fetchPreconnectionTemplates, undefined, {
    onError: () => {
      notify(`Couldn't fetch post connection templates!`, 'error');
    },
  });

  const { response: threadStages, isLoading: threadStagesLoading } =
    useAsyncFetch(true, fetchThreadStages, undefined, {
      onError: () => {
        notify(`Couldn't fetch thread stages!`, 'error');
      },
    });

  const {
    response: templateModelNameKeywordData,
    isLoading: templateModelNameKeywordDataLoading,
  } = useAsyncFetch(true, fetchTemplateModelKeywordsData, undefined, {
    onError: () => {
      notify(`Couldn't fetch template modal keywords!`, 'error');
    },
  });

  const { refetch: reDeleteTemplateApi } = useAsyncFetch(
    false,
    deleteTemplateApi,
    undefined,
    {
      onError: () => {
        notify(`Couldn't delete template!`, 'error');
      },
      onSuccess: () => {
        dispatch(fetchTemplates());
        notify(`Template removed successfully!`, 'success');
      },
    }
  );

  const { refetch: reDeleteAlumStudentTemplateApi } = useAsyncFetch(
    false,
    deleteAlumStudentOutreachTemplateApi,
    undefined,
    {
      onError: () => {
        notify(`Couldn't delete template!`, 'error');
      },
      onSuccess: () => {
        dispatch(fetchAlumStudentOutreachTemplates());
        notify(`Template removed successfully!`, 'success');
      },
    }
  );

  const { refetch: reDeleteTipApi } = useAsyncFetch(
    false,
    deleteTipApi,
    undefined,
    {
      onError: () => {
        notify(`Couldn't delete tip!`, 'error');
      },
      onSuccess: () => {
        dispatch(fetchTips());
        notify(`Tip removed successfully!`, 'success');
      },
    }
  );

  const updateActiveForm = type => {
    if (view === 'custom-user-templates') {
      history.push('/templates-and-tips');
    }
    dispatch(setFormData({}));
    setActiveForm(type);
  };

  const goToCustomUserTemplateView = () => {
    if (view !== 'custom-user-templates') {
      history.push('/templates-and-tips/custom-user-templates');
    }
  };

  const onDelete = (key, id) => {
    if (key === 'inboxTemplates') reDeleteTemplateApi({ id });
    if (key === 'inboxTips') reDeleteTipApi({ id });
    if (key === 'alumStudentOutreachTemplates')
      reDeleteAlumStudentTemplateApi({ id });

    if (formData.id === id) {
      setActiveForm(null);
      dispatch(setFormData({}));
    }

    dispatch(
      setTableData({
        ...tableData,
        [key]: tableData[key].filter(td => td.id !== id),
      })
    );
  };

  const propsObj = {
    topActionWrapperRef,
    connectionSources: {
      loading: connectionSourcesLoading,
      data: connectionSources.data || [],
    },
    threadStages: {
      loading: threadStagesLoading,
      data: threadStages.data || [],
    },
    preConnectionTemplates: {
      loading: preConnectionTemplatesLoading,
      data: preConnectionTemplates.data || [],
    },
    templateModelNameKeywordData: {
      loading: templateModelNameKeywordDataLoading,
      data: templateModelNameKeywordData.data || [],
    },
    onDelete,
    setActiveTab,
  };

  return (
    <div
      className="templates-and-tips"
      ref={el => {
        topActionWrapperRef.current = el;
      }}
    >
      <PageHeader
        title={<p className="header__title">Templates And Tips</p>}
        style={{ backgroundColor: '#ffffff' }}
        extra={[
          <Button
            key="create-template"
            icon="file-add"
            onClick={() => updateActiveForm('template')}
            type="primary"
          >
            Create Template
          </Button>,
          <Button
            key="create-tip"
            icon="bulb"
            onClick={() => updateActiveForm('tip')}
            type="primary"
          >
            Create Tip
          </Button>,
          <Button
            key="create-tip"
            icon="bulb"
            onClick={() => updateActiveForm('alum-student-outreach-templates')}
            type="primary"
          >
            Create Alum Student Outreach Templates
          </Button>,
          <Button
            key="custom-user-template"
            icon="snippets"
            onClick={() => goToCustomUserTemplateView()}
            type="primary"
          >
            Custom User Templates
          </Button>,
        ]}
      />
      <div className="main-container">
        <Row gutter={16}>
          {view === 'custom-user-templates' ? (
            <CustomUserTemplate />
          ) : (
            <>
              <Col span={12}>
                <Row>
                  <Card className="warning__card">
                    <div className="warning__description">
                      <p className="warning__text">
                        <span className="warning__text-bold">Warning: </span>{' '}
                        {`Changes
                  you will make on this screen are global and will effect content for all associated hubs.`}
                      </p>
                    </div>
                  </Card>
                </Row>
                <Row>
                  <Manage
                    setActiveForm={setActiveForm}
                    topActionWrapperRef={topActionWrapperRef}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </Row>
              </Col>
              <Col span={12}>
                {activeForm === 'template' && <CreateTemplate {...propsObj} />}
                {activeForm === 'tip' && <CreateTip {...propsObj} />}
                {activeForm === 'alum-student-outreach-templates' && (
                  <CreateAlumStudentOutreachTemplate {...propsObj} />
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};
export default TemplatesAndTips;
