import { request } from '../utils/request';

export const fetchSchoolsAPI = page =>
  request.get(`/admin/school?page=${page}`);

export const filterSchoolsAPI = data =>
  request.get(
    `/admin/school?onlyVerified=${data.onlyVerified}&search=${
      data.search
    }&page=${data.page}&exactMatch=${data.exactMatch}&caseInsensitive=${
      data.caseInsensitive
    }`
  );

export const updateSchoolAPI = data =>
  request.put(`/admin/school?id=${data.id}`, data);

export const fetchDegreeTypesAPI = data =>
  request.post(`/schools/school-degree-types`, data);

export const updateDegreeTypesAPI = data =>
  request.put(`/schools/school-degree-types/${data.id}`, data);
