import { request } from '../utils/request';

export const fetchAllGroupTemplateApi = ({ type, page }) =>
  request.get(`/admin/fetch-all-group-template/?type=${type}&page=${page}`);
export const fetchGroupTemplateByIdApi = id =>
  request.get(`/admin/fetch-group-template/${id}`);
export const fetchHubsBySearchApi = search =>
  request.get(`/admin/search-hubs/?search=${search}`);
export const fetchGroupsByHubApi = (hubId, type) =>
  request.get(`/admin/search-groups/?hubId=${hubId}&type=${type}`);
export const fetchActiveGroupTiersApi = type =>
  request.get(`/admin/fetch-group-tier?type=${type}`);
export const createTemplateApi = params =>
  request.post('/admin/create-group-template', params);
export const updateTemplateApi = params =>
  request.put(`/admin/update-group-template/${params.id}`, params);
export const deleteTemplateApi = id =>
  request.delete(`/admin/delete-group-template/${id}`);
