import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getHubsWithCio,
  syncHubUsers,
  removeDataFromCustomerIOHubSync as removeDataFromCustomerIOHubSyncAction,
} from './actions/analyticsProfile';
import AnalyticsProfile from '../../component/AnalyticsProfile';

const AnalyticsProfileContainer = ({
  hubsWithCio,
  getHubs,
  syncUsers,
  removeDataFromCustomerIOHubSync,
  loading,
  loadingForHubId,
}) => {
  useEffect(() => {
    getHubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchHubs = value => {
    getHubs({ search: value });
  };

  return (
    <div style={{ margin: '10px' }}>
      {' '}
      <AnalyticsProfile
        searchHubs={searchHubs}
        hubs={hubsWithCio}
        removeDataFromCustomerIOHubSync={removeDataFromCustomerIOHubSync}
        syncHubUsers={syncUsers}
        loadingForHubId={loadingForHubId}
        loading={loading}
      />{' '}
    </div>
  );
};

const mapDispatchToProps = {
  getHubs: getHubsWithCio,
  syncUsers: syncHubUsers,
  removeDataFromCustomerIOHubSync: removeDataFromCustomerIOHubSyncAction,
};

const mapStateToProps = ({
  analyticsProfile: { hubs, loading, loadingForHubId },
}) => ({
  hubsWithCio: hubs,
  loading,
  loadingForHubId,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsProfileContainer)
);
