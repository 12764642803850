import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';

import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Table,
  Alert,
  InputNumber,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncFetch from '../../../../hooks/useAsyncFetch';
import { notify } from '../../../../utils/notify';
import { fetchArchetypes } from '../../../../api/templatesAndTips';
import { setCompassData, setFormData } from '../../actions';
import ModuleSelectModal from '../ModuleSelectModal';
import HubSelectionModal from '../HubSelectionModal';
import {
  autoTrackEvents,
  compassActionItems,
  userTypeOptions,
} from '../../utils';
import useCreateActionItemMutation from '../../query/useCreateActionItemMutation';
import useUpdateActionItemMutation from '../../query/useUpdateActonItemMutation';

const { Option } = Select;

const CreateAction = ({
  form,
  selectedActionItem,
  setSelectedActionItem,
  page,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [isFieldChanged, setIsFieldChanged] = useState(false);

  const { formData, showModuleSelectionModal, showHubSelectionModal } =
    useSelector(({ compassReducer }) => compassReducer);

  const { response: archetypeData, isLoading: archetypeLoading } =
    useAsyncFetch(true, fetchArchetypes, undefined, {
      onError: () => notify(`Couldn't fetch archetypes!`, 'error'),
    });

  const { mutate: createGlobalActionItem, isLoading: isActionItemCreating } =
    useCreateActionItemMutation({
      onSuccess: data => {
        setSelectedActionItem(data);
      },
    });

  const { mutate: updateActionItem, isLoading: isUpdatingActionItem } =
    useUpdateActionItemMutation(page);

  useEffect(() => {
    if (selectedActionItem) dispatch(setFormData(selectedActionItem));
  }, [selectedActionItem]);

  useEffect(() => {
    form.resetFields();
  }, [formData]);

  const { settings = {}, criteria = {} } = form.getFieldsValue();

  const onFinish = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        if (formData.id) {
          const updatedValues = {
            ...values,
            id: formData.id,
          };
          updateActionItem(updatedValues);
        } else {
          createGlobalActionItem({ ...values, isStandard: true });
        }
        setIsFieldChanged(false);
        form.resetFields();
      }
    });
  };

  const getSelectedArchetypes = (archetype = []) => {
    const arr = [];
    (archetypeData.data?.archetype?.options || []).forEach(
      ({ children = [] }) =>
        children.forEach(({ optionId, optionTitle }) => {
          if (archetype.includes(optionId)) arr.push(optionTitle);
        })
    );
    return arr.join(', ');
  };

  const makeFinalConditionsPreview = () => {
    const { archetypes, userTypes } = form.getFieldsValue()?.settings || {};

    return (
      <>
        <h3>Selected Conditions:</h3>
        <ul>
          {Array.isArray(archetypes) && !isEmpty(archetypes) && (
            <li>
              <strong>Archetype:</strong> {getSelectedArchetypes(archetypes)}
            </li>
          )}
          {Array.isArray(userTypes) && !isEmpty(userTypes) && (
            <li>
              <strong>UserType:</strong> {userTypes.join(', ')}
            </li>
          )}
        </ul>
      </>
    );
  };

  const handleShowModuleModal = () => {
    dispatch(setCompassData({ showModuleSelectionModal: true }));
  };

  const handleShowHubSelectionModal = () => {
    dispatch(setCompassData({ showHubSelectionModal: true }));
  };

  const renderTextRow = (text, record, index) => (
    <div key={index} className="list__table-box">
      {text}
    </div>
  );

  const getModuleColumns = () => [
    {
      title: 'Required Modules',
      dataIndex: 'modules',
      key: 'modules',
      render: renderTextRow,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <div key={index} className="list__table-box">
          <Button type="link" onClick={handleShowModuleModal}>
            View all
          </Button>
        </div>
      ),
    },
  ];

  const getModuleColumnData = () => {
    const { requiredModules } = form.getFieldsValue()?.settings || {};
    if (requiredModules && requiredModules.length) {
      return [{ modules: requiredModules.length, id: 1, key: 1 }];
    }

    return [];
  };

  const getHubSelectionColumns = () => [
    {
      title: 'Active Launch Groups',
      dataIndex: 'groups',
      key: 'groups',
      render: renderTextRow,
    },
    {
      title: 'Active Hubs',
      dataIndex: 'hubs',
      key: 'hubs',
      render: renderTextRow,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <div key={index} className="list__table-box">
          <Button type="link" onClick={handleShowHubSelectionModal}>
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const getHubSelectionColumnData = () => {
    const { launchIn } = form.getFieldsValue()?.settings || {};
    if (launchIn) {
      const { selectedGroups, selectedHubs } = launchIn;
      return [
        {
          groups: selectedGroups && selectedGroups.length,
          hubs: selectedHubs && selectedHubs.length,
          id: 1,
          key: 1,
        },
      ];
    }
    return [];
  };

  const previewAutoTrackLimitMsg = () => {
    const { autoTrackEvent, limit } = form.getFieldsValue()?.criteria || {};

    if (autoTrackEvent && limit) {
      const selectedEvent = autoTrackEvents.find(
        event => event.type === autoTrackEvent
      );
      return (
        <Alert
          style={{ marginBottom: 24 }}
          type="info"
          message={
            <div>
              <span>Triggered when the </span>
              <strong>{selectedEvent?.name}</strong>
              <span> by the user is greater than or equal to </span>
              <strong>{limit || `the number provided above`}</strong>
            </div>
          }
        />
      );
    }
    return null;
  };

  return (
    // eslint-disable-next-line no-return-assign
    <div ref={el => (containerRef.current = el)}>
      <Card>
        <h3>{formData.id ? 'Update' : 'Create'} Action Form</h3>
        <Form onSubmit={onFinish}>
          <Form.Item label="Action Name">
            {form.getFieldDecorator('title', {
              rules: [
                { required: true, message: 'Please fill Action Name!' },
                { max: 35, message: 'Name should be less than 35 character' },
              ],
              initialValue: formData.title,
            })(<Input placeholder="Enter Action Name" />)}
          </Form.Item>
          <Form.Item label="Action Description">
            {form.getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Please fill Action description!',
                },
              ],
              initialValue: formData.description,
            })(<Input placeholder="Enter Action Description" />)}
          </Form.Item>

          <div
            style={{
              display: 'flex',
              gap: '24px',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Form.Item label="Select Criteria">
              {form.getFieldDecorator('criteria.event', {
                rules: [{ required: true, message: 'Please select Criteria' }],
                initialValue: formData.criteria?.event,
              })(
                <Select
                  allowClear
                  getPopupContainer={() => containerRef.current}
                  style={{ minWidth: '300px' }}
                  disabled={!!formData.id}
                  onChange={value => {
                    form.setFieldsValue({ criteria: { event: value } });
                  }}
                >
                  {Object.keys(compassActionItems).map(key => (
                    <Option key={key} value={key}>
                      {compassActionItems[key].heading}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {form.getFieldsValue()?.criteria &&
            compassActionItems[form.getFieldsValue()?.criteria?.event]
              ?.showLimit ? (
              <>
                <strong style={{ marginTop: '10px' }}>{'>='}</strong>
                <Form.Item label="Select AutoTrack Event Limit">
                  {form.getFieldDecorator('criteria.limit', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select criteria limit',
                      },
                    ],
                    initialValue: formData.criteria?.limit,
                  })(<InputNumber min={1} disabled={!!formData.id} />)}
                </Form.Item>
              </>
            ) : null}
          </div>
          {previewAutoTrackLimitMsg()}
          <Form.Item label="Select archetype">
            {form.getFieldDecorator('settings.archetypes', {
              initialValue: formData.settings?.archetypes,
              rules: [{ required: true, message: 'Please select archetype' }],
            })(
              <Select
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                allowClear
                loading={archetypeLoading}
                disabled={archetypeLoading || selectedActionItem?.active}
                optionFilterProp="title"
              >
                {(archetypeData.data?.archetype?.options || []).reduce(
                  (options, option) => {
                    option.children.forEach(({ optionId, optionTitle }) => {
                      options.push(
                        <Option
                          key={optionId}
                          value={optionId}
                          title={optionTitle}
                        >
                          {optionTitle}
                        </Option>
                      );
                    });

                    return options;
                  },
                  []
                )}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Select usertypes">
            {form.getFieldDecorator('settings.userTypes', {
              initialValue: formData.settings?.userTypes,
              rules: [{ required: true, message: 'Please select Usertypes' }],
            })(
              <Select
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                allowClear
                disabled={selectedActionItem?.active}
              >
                {userTypeOptions.map(({ value, name }) => (
                  <Option key={value} value={value}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('settings.requiredModules', {
              initialValue: formData.settings?.requiredModules,
            })(
              <Card
                title="Require Module(s)"
                className="form__card"
                extra={
                  <div className="form__card-extra">
                    <Button onClick={handleShowModuleModal}>Select</Button>
                  </div>
                }
                bordered={false}
              >
                <p>
                  Select any modules that are necessary for the action to
                  complete. Any hubs that don’t have these modules enabled will
                  automatically excluded from the action.
                </p>
                <div className="form__card-table">
                  <Table
                    locale={{ emptyText: 'No Module Selected!' }}
                    columns={getModuleColumns()}
                    dataSource={getModuleColumnData()}
                    pagination={false}
                    rowkey="modules"
                  />
                </div>
              </Card>
            )}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('settings.launchIn', {
              initialValue: formData.settings?.launchIn,
            })(
              <Card
                title="Select Hubs"
                className="form__card"
                extra={
                  <div className="form__card-extra">
                    <Button onClick={handleShowHubSelectionModal}>
                      Select
                    </Button>
                  </div>
                }
                bordered={false}
              >
                <p>
                  Select the hubs or launch groups to which you’d like to enable
                  this action.
                </p>
                <div className="form__card-table">
                  <Table
                    locale={{ emptyText: 'Not hub Selected!' }}
                    columns={getHubSelectionColumns()}
                    dataSource={getHubSelectionColumnData()}
                    pagination={false}
                    rowkey="launch"
                  />
                </div>
              </Card>
            )}
          </Form.Item>
          <Alert
            style={{ marginBottom: 24 }}
            type="info"
            message={makeFinalConditionsPreview()}
          />
          <Form.Item className="templates-and-tips-form-footer">
            <Button
              loading={isActionItemCreating || isUpdatingActionItem}
              htmlType="submit"
              type="primary"
              disabled={!(form.isFieldsTouched() || isFieldChanged)}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
      {showModuleSelectionModal ? (
        <ModuleSelectModal
          modules={settings?.requiredModules || []}
          onSave={data => {
            form.setFieldsValue({ settings: { requiredModules: data } });
            setIsFieldChanged(true);
          }}
        />
      ) : null}

      {showHubSelectionModal ? (
        <HubSelectionModal
          launchIn={settings?.launchIn}
          saveLaunchIn={data => {
            form.setFieldsValue({ settings: { launchIn: data } });
            setIsFieldChanged(true);
          }}
        />
      ) : null}
    </div>
  );
};

export default Form.create({ name: ' create global actionItem' })(CreateAction);
