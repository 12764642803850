import { request } from '../utils/request';

export const fetchAdminTagsAPI = () => request.get('/superadmin/admin-tags');

export const addAdminTagAPI = data => request.put('/superadmin/admin-tags', data);

export const updateAdminTagAPI = data => request.post(`/superadmin/admin-tags/${data.id}`, data);

export const deleteAdminTagAPI = data => request.delete(`/superadmin/admin-tags/${data.id}`);


export const fetchAdminTagsReferenceAPI = () => request.get('/superadmin/admin-tags-reference');

export const addAdminTagReferenceAPI = data => request.put('/superadmin/admin-tags-reference', data);

export const deleteAdminTagReferenceAPI = data => request.delete(`/superadmin/admin-tags-reference/${data.id}`);

export const fetchClusterHubsAPI = () => request.get('/admin/cluster-hubs');