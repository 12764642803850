import { SET_FORM_DATA, SET_COMPASS_DATA } from '../actions';

const initialState = {
  formData: {},
  showHubSelectionModal: false,
  showModuleSelectionModal: false,
};

const compassReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_FORM_DATA:
      return {
        ...state,
        formData: data,
      };

    case SET_COMPASS_DATA:
      return {
        ...state,
        ...data,
      };

    default:
      return state;
  }
};

export default compassReducer;
