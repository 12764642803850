import PropTypes from 'prop-types';
import {
  SET_PRODUCT_UPDATE_POSTS_LOADING,
  SET_PRODUCT_UPDATE_POSTS,
  SET_PRODUCT_UPDATE_POST_UPDATING,
  SET_PRODUCT_UPDATE_POST_SAVING,
} from '../actions/productUpdates';

const initialState = {
  posts: {},
  loadingPosts: false,
  updatingPost: null,
  savingPost: false,
};

const productUpdatesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_PRODUCT_UPDATE_POSTS_LOADING:
      return {
        ...state,
        loadingPosts: data,
      };
    case SET_PRODUCT_UPDATE_POSTS:
      return {
        ...state,
        posts: data.reduce((acc, post) => ({ ...acc, [post.id]: post }), {}),
      };
    case SET_PRODUCT_UPDATE_POST_UPDATING:
      return {
        ...state,
        updatingPost: data,
      };
    case SET_PRODUCT_UPDATE_POST_SAVING:
      return {
        ...state,
        savingPost: data,
      };
    default:
      return state;
  }
};

export default productUpdatesReducer;

export const productUpdatePostPropType = PropTypes.shape({
  createdBy: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    openInSameWindow: PropTypes.bool.isRequired,
  }).isRequired,
  image: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  objectType: PropTypes.string.isRequired,
  objectId: PropTypes.arrayOf(PropTypes.number.isRequired),
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});
