import React, { useEffect } from 'react';
import { Icon, Spin } from 'antd';
import { connect } from 'react-redux';

import s from './AlertImageContainer.module.scss';
import { getAllCustomImages as getAllCustomImagesAction } from '../../actions/configureBanner';
import FullPageLoading from '../../component/FullPageLoading';

const checkedIcon = (
  <Icon
    type="check-circle"
    theme="twoTone"
    twoToneColor="#52c41a"
    className={s['checked-icon']}
  />
);

const AlertImageContainer = ({
  hubBannerImage,
  handleImageChange,
  usedCustomImages,
  getAllCustomImages,
}) => {
  useEffect(() => {
    getAllCustomImages();
  }, [getAllCustomImages]);

  return (
    <div className={s.row}>
      {usedCustomImages.loadingAllCustomImages ? (
        <FullPageLoading />
      ) : (
        usedCustomImages.map(usedCustomImage => (
          <div
            style={{
              backgroundImage: `url(${usedCustomImage})`,
            }}
            className={`${s['image-container']} ${
              hubBannerImage === usedCustomImage ? s['active-border'] : ''
            }`}
            onClick={() => handleImageChange(usedCustomImage)}
            onKeyDown={e =>
              e.key === 'Enter' && handleImageChange(usedCustomImage)
            }
            role="presentation"
            key={usedCustomImage}
          >
            {hubBannerImage === usedCustomImage && checkedIcon}
          </div>
        ))
      )}
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/2jQu2MfgSdG2TGwUlygF_group-11.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-1' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-1')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-1')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-1' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/rPJ9bAeuQ3CPszySd18E_image.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-2' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-2')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-2')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-2' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/aax9owhTd6lveo3OtVdT_alert-banner-3.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-3' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-3')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-3')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-3' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/t2CKjs8cTckJ3VNZFwR8_alert-banner-4.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-4' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-4')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-4')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-4' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/5JsEICrVTTqrivM3ylAR_sf.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-5' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-5')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-5')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-5' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/Wjqxw5OjT2CUQN7acYtC_rocket.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-6' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-6')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-6')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-6' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/OaQj9O9nSLKGwrfgxvC8_comp.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-7' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-7')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-7')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-7' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/ejBIdN7qRfO8foMAnRtD_gift.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-8' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-8')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-8')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-8' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/LdsiIfSRpuip8jPFdvjg_layer.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-9' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-9')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-9')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-9' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/OpmEIVfJRFydelMf89o3_shield.svg)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-10' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-10')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-10')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-10' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/svjF7bXjQwSP86WKXWQF_SolidarityLogo_2020.png)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-11' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-11')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-11')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-11' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/GyTYDeKyRlaNJNan919k_SolidarityIcon_2020.png)',
        }}
        className={`${s['image-container']} ${
          hubBannerImage === 'alert-banner-12' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('alert-banner-12')}
        onKeyDown={e =>
          e.key === 'Enter' && handleImageChange('alert-banner-12')
        }
        role="presentation"
      >
        {hubBannerImage === 'alert-banner-12' && checkedIcon}
      </div>
    </div>
  );
};

const mapStateToProps = ({ configureBanner }) => ({
  usedCustomImages: configureBanner.usedCustomImages,
});
const mapDispatchToProps = {
  getAllCustomImages: getAllCustomImagesAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertImageContainer);
