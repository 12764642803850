import React from 'react';
import { Icon } from 'antd';

import s from './styles/TilePicker.module.scss';

const checkedIcon = (
  <Icon
    type="check-circle"
    theme="twoTone"
    twoToneColor="#52c41a"
    className={s['checked-icon']}
  />
);

const TilePickerContainer = ({ tilePickerImage, handleImageChange }) => {
  return (
    <div className={s.row}>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/f43LI9iYQKuiEFnCedBc_010-promotion.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-1' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-1')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-1')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-1' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/zRsmMtCZRaCG1lR2Wf1e_021-time-is-money.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-2' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-2')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-2')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-2' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/Hg1Pqq0gTjif4MEUvlKU_041-sales-funnel.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-3' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-3')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-3')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-3' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/cDQb5qQSFyLCMQ0ZxYVm_022-price-tag.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-4' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-4')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-4')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-4' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/McJBuV88SU2ULxZorQmq_006-trophy.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-5' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-5')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-5')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-5' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/R1FgB50hRpa468Q6y8rd_042-money-magnet.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-6' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-6')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-6')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-6' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/oD8DOkjCR2q04FGuFEJv_018-medal.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-7' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-7')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-7')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-7' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/5zu7LbKXSjikNba0u3BU_033-target.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-8' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-8')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-8')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-8' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/laqSoQiTYO8xbJD3aK8A_034-monetization.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-9' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-9')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-9')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-9' && checkedIcon}
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://d1ctk4ronrg3qz.cloudfront.net/admin/xO9mlmRrRcKk0XX8tjGV_014-online-shop.svg)',
        }}
        className={`${s['image-container']} ${
          tilePickerImage === 'group-tier-10' ? s['active-border'] : ''
        }`}
        onClick={() => handleImageChange('group-tier-10')}
        onKeyDown={e => e.key === 'Enter' && handleImageChange('group-tier-10')}
        role="presentation"
      >
        {tilePickerImage === 'group-tier-10' && checkedIcon}
      </div>
    </div>
  );
};

export default TilePickerContainer;
