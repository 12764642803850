import { Button } from 'antd';
import { getPlainText } from '../../../utils/helpers';

const AiSummaryColumn = ({ setSelectedAiMeetingSummary, aIMeetingSummary }) => {
  const summary = aIMeetingSummary?.summary;

  return (
    <>
      <p
        style={{
          maxHeight: 113,
          padding: '10px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 5,
          WebkitBoxOrient: 'vertical',
        }}
        dangerouslySetInnerHTML={{
          __html: getPlainText(summary || 'N.A.'),
        }}
      />
      {summary && (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button
            onClick={() => setSelectedAiMeetingSummary(aIMeetingSummary)}
            type="link"
          >
            Read more
          </Button>
        </div>
      )}
    </>
  );
};

export default AiSummaryColumn;
