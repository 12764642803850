import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { split } from 'lodash';
import {
  Button,
  PageHeader,
  Card,
  Divider,
  Input,
  Row,
  Col,
  Checkbox,
  Icon,
  Menu,
  Dropdown,
} from 'antd';

import {
  fetchSchools,
  handlePageChange,
  searchTextChange,
  toggleOnlyVerified,
  toggleExactMatch,
  toggleCaseInsensitive,
  filterSchools,
  clearFilters,
  updateEditingKey,
  updateSchool,
  degreeTypesModalOpen,
  fetchDegreeTypes,
  updateDegreeTypes,
  resetState,
} from './actions';

import Content from '../../component/Content';
import EditableFormTable from './components/EditableTable';
import DegreeTypeModal from './components/DegreeTypeModal';

import './index.scss';

const { Search } = Input;

class SchoolManagement extends PureComponent {
  componentDidMount() {
    const { fetchSchools, data } = this.props;
    if (!data) {
      fetchSchools();
    }
  }

  componentWillUnmount() {
    this.clearAllFilters();
  }

  componentWillUnmount() {
    const { resetState } = this.props;
    resetState();
  }

  renderYesOrNo = yesCondition => yesCondition ? <span>Yes</span> : <span>No</span>;

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      rules: [{ required: true, message: 'Please Input Name' }],
    },
    {
      title: 'Verified',
      dataIndex: 'isVerified',
      key: 'verified',
      editable: true,
      render: isVerified => this.renderYesOrNo(isVerified),
    },
    {
      title: 'High School',
      dataIndex: 'isHighSchool',
      key: 'highSchool',
      editable: true,
      render: isHighSchool => this.renderYesOrNo(isHighSchool),
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier',
      editable: true,
    },
    {
      title: 'Shortcuts',
      dataIndex: 'shortcuts',
      key: 'shortcuts',
      editable: true,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      editable: true,
    },
    {
      title: 'Degree Types',
      dataIndex: 'degreeTypes',
      key: 'degreeTypes',
      render: (degreeTypes, { id }) => this.renderDegreeTypes(degreeTypes, id),
    },
  ];

  renderDegreeTypes = (degreeTypes, id) => {
    let dataToShow = '';
    let totalDegreeTypes = 0;
    if (degreeTypes) {
      const degreeArray = split(degreeTypes, ',');
      totalDegreeTypes = degreeArray.length;
      if (totalDegreeTypes > 2) {
        dataToShow = `${degreeArray[0]}, ${
          degreeArray[1]
        } & ${totalDegreeTypes - 2} more`;
      } else {
        dataToShow = degreeTypes;
      }
    }
    return (
      <Fragment>
        {totalDegreeTypes > 0 ? <p>{dataToShow}</p> : null}
        <Button type="primary" size="small" onClick={() => this.openModal(id)}>
          {totalDegreeTypes === 0 ? 'Add' : 'View All / Edit'}
        </Button>
      </Fragment>
    );
  };

  openModal = id => {
    const {
      degreeTypes: { schoolId },
      degreeTypesModalOpen,
      fetchDegreeTypes,
    } = this.props;
    if (id !== schoolId) {
      degreeTypesModalOpen(true);
      fetchDegreeTypes(id);
    }
    degreeTypesModalOpen(true);
    fetchDegreeTypes(id);
  };

  closeModal = () => {
    degreeTypesModalOpen(false);
  };

  paginationOptions = {
    showSizeChanger: false,
    showQuickJumper: true,
    total: this.props.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
  };

  handlePaginationChange = (page, pageSize) => {
    const { handlePageChange, fetchSchools, totalPages } = this.props;
    if (page < 1) page = 1;
    if (page > totalPages) page = totalPages;
    handlePageChange(page);
    fetchSchools();
  };

  handleTableChange = ({ current: page, pageSize }) => {
    this.handlePaginationChange(page, pageSize);
  };

  clearAllFilters = () => {
    const { clearFilters, fetchSchools } = this.props;
    clearFilters();
    fetchSchools();
  };

  handleChangeInSearch = e => {
    const { searchTextChange } = this.props;
    searchTextChange(e.target.value);
  };

  handleFilters = () => {
    const {
      searchText,
      onlyVerified,
      exactMatch,
      caseInsensitive,
      filterSchools,
    } = this.props;
    filterSchools({
      onlyVerified,
      exactMatch,
      caseInsensitive,
      search: searchText,
    });
  };

  updateSchoolData = (prev, newData) =>
    this.props.updateSchool({ previousData: prev, newData });

  onEditingKeyChange = editingKey =>
    this.props.updateEditingKey({ editingKey });

  render() {
    const {
      data,
      searchText,
      onlyVerified,
      toggleOnlyVerified,
      exactMatch,
      toggleExactMatch,
      caseInsensitive,
      toggleCaseInsensitive,
      total,
      current,
      pageSize,
      loading,
      filtersEnabled,
      editingKey,
      degreeTypes,
      degreeTypesModalOpen,
      updateDegreeTypes,
    } = this.props;
    const {
      isDegreeTypeModalOpen,
      degreeTypesLoading,
      data: degreeTypesData,
      updating,
    } = degreeTypes;
    const pagination = {
      ...this.paginationOptions,
      total,
      current,
      pageSize,
    };

    const menu = (
      <Menu onClick={() => {}}>
        <Menu.Item>
          <Checkbox checked={exactMatch} onChange={toggleExactMatch}>
            Exact Match
          </Checkbox>
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            disabled={!exactMatch}
            checked={caseInsensitive}
            onChange={toggleCaseInsensitive}
          >
            Case Insensitive
          </Checkbox>
        </Menu.Item>
      </Menu>
    );

    return (
      <Fragment>
        <PageHeader title="Manage Schools">
          <Row>
            <Col span={24}>
              <div style={{ textAlign: 'center' }}>
                <Search
                  placeholder="Search By Name"
                  style={{ width: 400 }}
                  value={searchText}
                  addonAfter={
                    <Dropdown
                      overlay={menu}
                      placement="bottomRight"
                      trigger={['click']}
                      className="search-dropdown-after"
                    >
                      <div className="ant-dropdown-icon">
                        <Icon type="setting" />
                        <Icon type="down" />
                      </div>
                    </Dropdown>
                  }
                  onChange={this.handleChangeInSearch}
                  onSearch={this.handleFilters}
                />
                <Divider type="vertical" />
                <Checkbox checked={onlyVerified} onChange={toggleOnlyVerified}>
                  Only Show Verified
                </Checkbox>
                <Divider type="vertical" />
                <Button type="primary" onClick={this.handleFilters}>
                  <Icon type="filter" />
                  Get Schools
                </Button>
                <Divider type="vertical" />
                <Button
                  onClick={this.clearAllFilters}
                  disabled={!filtersEnabled}
                >
                  <Icon type="sync" />
                  Clear Filters
                </Button>
              </div>
            </Col>
          </Row>
        </PageHeader>
        <Content>
          <Card bordered={false}>
            <EditableFormTable
              columns={this.columns}
              dataSource={data}
              onChange={this.handleTableChange}
              pagination={pagination}
              loading={loading}
              updateData={this.updateSchoolData}
              editingKey={editingKey}
              onEditingKeyChange={this.onEditingKeyChange}
            />
          </Card>
          <DegreeTypeModal
            isDegreeTypeModalOpen={isDegreeTypeModalOpen}
            loading={degreeTypesLoading}
            data={degreeTypesData}
            handleClose={degreeTypesModalOpen}
            updateDegreeTypes={updateDegreeTypes}
            updating={updating}
          />
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  schools: {
    data,
    searchText,
    onlyVerified,
    exactMatch,
    caseInsensitive,
    total,
    totalPages,
    current,
    pageSize,
    loading,
    filtersEnabled,
    editingKey,
    degreeTypes,
  },
}) => ({
  data,
  searchText,
  onlyVerified,
  exactMatch,
  caseInsensitive,
  total,
  totalPages,
  current,
  pageSize,
  loading,
  filtersEnabled,
  editingKey,
  degreeTypes,
});

const mapDispatchToProps = {
  fetchSchools,
  handlePageChange,
  searchTextChange,
  toggleOnlyVerified,
  toggleExactMatch,
  toggleCaseInsensitive,
  filterSchools,
  clearFilters,
  updateEditingKey,
  updateSchool,
  degreeTypesModalOpen,
  fetchDegreeTypes,
  updateDegreeTypes,
  resetState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolManagement);
