import { takeLatest, put, call, select } from 'redux-saga/effects';
import { pick, groupBy, cloneDeep } from 'lodash';
import {
  FETCH_HUB_GROUPS_DATA,
  FETCH_GROUP_TIERS,
  FETCH_TEMPLATE_ASSOCIATED_TO_TIER,
  SAVE_CONFIGURATION_SETTING,
  // action creators
  setGroupsDataLoading,
  setGroupsDataCount,
  setHubGroupsData,
  setTierLoading,
  setTierData,
  setTemplateLoading,
  setTemplateData,
  setSaveConfigurationLoading,
  setConfigurationModal,
  //action selectors
  groupProgramTierPage,
  groupProgramSearch,
  selectedTierData,
  configSetting,
  selectedHub,
  configurationTab,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';
import {
  fetchGroupsDataApi,
  fetchGroupTierApi,
  fetchTemplateApi,
  saveConfigurationApi,
} from '../../../api/groupsProgramTierApi';

import manageSaga from './manageSaga';

export default [
  ...manageSaga,
  takeLatest(FETCH_HUB_GROUPS_DATA, fetchHubGroupsDataSaga),
  takeLatest(FETCH_GROUP_TIERS, fetchGroupTiersSaga),
  takeLatest(FETCH_TEMPLATE_ASSOCIATED_TO_TIER, fetchTemplateSaga),
  takeLatest(SAVE_CONFIGURATION_SETTING, saveConfigurationSettingSaga),
];

function* fetchHubGroupsDataSaga() {
  try {
    // console.log('called fetchHubGroupsDataSaga')
    const page = yield select(groupProgramTierPage);
    const searchText = yield select(groupProgramSearch);
    const tierData = yield select(selectedTierData);
    yield put(setGroupsDataLoading(true));
    const bodyParams = {
      page,
    };
    if (searchText) {
      bodyParams.search = searchText;
    }
    if (tierData && tierData.length > 0) {
      bodyParams.tier = tierData;
    }
    const { data } = yield call(fetchGroupsDataApi, bodyParams);
    if (data) {
      yield put(setGroupsDataCount(data.count));
      yield put(setHubGroupsData(data.result));
    }
    yield put(setGroupsDataLoading(false));
    // const data =
  } catch (e) {
    console.log('Error', e);
  }
}

function* fetchGroupTiersSaga() {
  try {
    yield put(setTierLoading(true));
    let { data } = yield call(fetchGroupTierApi);
    data = data.map(item => ({
      name: `${item.name} (${item.type})`,
      key: item.id,
    }));
    yield put(setTierData(data));
    yield put(setTierLoading(false));
  } catch (e) {
    console.log('Error', e);
  }
}

function* fetchTemplateSaga({ data }) {
  try {
    yield put(setTemplateLoading(true));
    const params = {
      groupTier: data,
    };
    const { data: resData } = yield call(fetchTemplateApi, params);
    if (resData) {
      yield put(setTemplateData(resData));
    }
    yield put(setTemplateLoading(false));
  } catch (e) {
    console.log('Error', e);
  }
}

function* saveConfigurationSettingSaga() {
  try {
    const selectedConfigSetting = cloneDeep(yield select(configSetting));
    const configHub = yield select(selectedHub);
    const tab = yield select(configurationTab);
    yield put(setSaveConfigurationLoading(true));
    const updatedSettings = selectedConfigSetting.filter(config => config.type === tab).map(item => {
      if (item.templates && item.templates.length > 0) {
        const modifiedTemplates = item.templates.map(t =>
          pick(t, ['id', 'group_tier_id', 'isActive'])
        );
        item.templates = cloneDeep(modifiedTemplates);
      }
      return pick(item, ['id', 'templates', 'maxAllowed', 'maxAllowedPerSchool']);
    });
    // const groupedSetting = groupBy(updatedSettings, 'type');
    const params = {
      hub: configHub,
      setting: updatedSettings,
    };
    const { data } = yield call(saveConfigurationApi, params);
    yield put(setSaveConfigurationLoading(false));
    yield put(setConfigurationModal(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Successfully Saved Configuration!' },
    });
  } catch (e) {
    console.log('Error', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Saving Configuration.' },
    });
  }
}
