import { request } from '../utils/request';

export const fetchHubsList = params =>
  request.get('/superadmin/standard/hubs', { params });
export const fetchBaseConfig = params =>
  request.get('/superadmin/standard/base-config', { params });
export const saveBaseConfig = body =>
  request.post('/superadmin/standard/save-base-config', body);
export const verifyAndGetData = body =>
  request.post('/superadmin/standard/verify-and-get-settings', body);
export const startStandardMigration = body =>
  request.post('/superadmin/standard/migrate-to-standard', body);
export const fetchMigrationJobStatus = params =>
  request.get('/superadmin/standard/job-status', { params });
export const updateStandardDataMapping = body =>
  request.post(
    '/superadmin/standard-data-sets/update-standard-data-mapping',
    body
  );
