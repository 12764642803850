const prefix = 'ManageImport/';
export const FETCH_PROCESSING_IMPORT = `${prefix}FETCH_PROCESSING_IMPORT`;
export const SET_IMPORT_LIST = `${prefix}SET_IMPORT_LIST`;
export const SET_IMPORT_LIST_LOADING = `${prefix}SET_IMPORT_LIST_LOADING`;
export const SET_IMPORT_LIST_COUNT = `${prefix}SET_IMPORT_LIST_COUNT`;
export const SET_IMPORT_LIST_PAGE = `${prefix}SET_IMPORT_LIST_PAGE`;
export const CLEAR_IMPORT_STATE = `${prefix}CLEAR_IMPORT_STATE`;
export const HANDLE_RESTART_CLICK = `${prefix}HANDLE_RESTART_CLICK`;
export const SET_IMPORT_QUEUE_STATUS = `${prefix}SET_IMPORT_QUEUE_STATUS`;

export const fetchProcessingImport = () => ({
  type: FETCH_PROCESSING_IMPORT,
});

export const setImportList = data => ({
  type: SET_IMPORT_LIST,
  data,
});

export const setImportListLoading = data => ({
  type: SET_IMPORT_LIST_LOADING,
  data,
});

export const setImportListCount = data => ({
  type: SET_IMPORT_LIST_COUNT,
  data,
});

export const setImportListPage = data => ({
  type: SET_IMPORT_LIST_PAGE,
  data,
});

export const clearImportState = () => ({
  type: CLEAR_IMPORT_STATE,
});

export const handleRestartClick = data => ({
  type: HANDLE_RESTART_CLICK,
  data,
});

export const setImportQueueStatus = data => ({
  type: SET_IMPORT_QUEUE_STATUS,
  data,
});

// selectors
export const importListPage = ({ manageImport }) => manageImport.page;
export const importList = ({ manageImport }) => manageImport.importList;
