import React, { useEffect, useState } from 'react';
import { Modal, Input, Form } from 'antd';

const { TextArea } = Input;

const DeleteReasonModal = props => {
  const {
    isOpen = false,
    onSubmit,
    onClose,
    title = 'Reason',
    errorMessage = 'Reason is required!',
  } = props;
  const [reason, setReason] = useState('');
  const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);

  useEffect(() => setReason(''), []);

  const handleOnClose = () => {
    onClose();
    setReason('');
  };
  const handleLogin = () => {
    const { form } = props;
    setPrimaryButtonLoading(true);
    form
      .validateFields()
      .then(() => {
        onSubmit(reason);
        setReason('');
        form.resetFields();
        setPrimaryButtonLoading(false);
        handleOnClose();
      })
      .catch(err => {
        setPrimaryButtonLoading(false);
        // eslint-disable-next-line no-console
        console.log('Validate Failed:', err);
      });
  };

  const { form } = props;
  const { getFieldDecorator } = form;

  return (
    <Modal
      title={title}
      visible={isOpen}
      onOk={handleLogin}
      onCancel={handleOnClose}
      confirmLoading={primaryButtonLoading}
    >
      <Form>
        <Form.Item>
          {getFieldDecorator('filter-options', {
            rules: [
              {
                required: true,
                message: errorMessage,
              },
            ],
          })(
            <TextArea
              rows={3}
              // value={reason}
              onChange={e => setReason(e.target.value)}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(DeleteReasonModal);
