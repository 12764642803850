import React, { useState } from 'react';
import {
  Modal,
  Form,
  Select,
  Avatar,
  Button,
  Input,
  Row,
  Col,
  Table,
} from 'antd';
import { get, capitalize } from 'lodash';

import './company-industry-modal.scss';

const { Option } = Select;

const CompanyLogo = ({ logo }) => (
  <Avatar
    icon={logo ? null : 'shop'}
    shape="square"
    src={logo}
    alt="Company Logo"
    size={100}
    className="company-industry-model__company__avatar"
  />
);

const CompanyMatches = ({ show, loading, dataSource = [] }) => {
  if (!show) return null;

  const columns = [
    {
      title: 'Compnay Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Generic Industry',
      dataIndex: 'genericIndustry',
      key: 'genericIndustry',
    },
    {
      title: 'Standard Industry',
      dataIndex: 'standardIndustry',
      key: 'standardIndustry',
    },
    {
      title: 'Confidence',
      dataIndex: 'confidence',
      key: 'confidence',
    },
  ];
  return (
    <div className="">
      <Table
        className="company-industry-model__company__matches"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
      />
    </div>
  );
};

const CompanyInfo = ({ company }) => {
  if (!company) return null;

  const isApproved = get(company, 'approved') ? 'True' : 'False';
  const website = get(company, 'website');
  const officeHq = get(company, 'officeHq');
  return (
    <div className="company-industry-model__company__info">
      <div className="company-industry-model__company__subtext">
        {officeHq ? `${officeHq} ` : ''}(
        {isApproved ? 'Approved' : 'Un-Approved'} Company)
      </div>
      <div className="company-industry-model__company__subtext">
        Website:{' '}
        {website ? (
          <a href={website} target="_blank" rel="noreferrer">
            {website}
          </a>
        ) : (
          'NA'
        )}
      </div>
    </div>
  );
};

const CompanyIndustryModal = ({
  companyIndustry,
  standardIndustries = [],
  loading = false,
  form,
  onSave,
  onCancel,
  getKaggleComapniesMatches,
  isGettingKaggleCompaniesMatches,
  kaggleCompaniesMatches,
}) => {
  const { getFieldDecorator } = form;
  const [showKaggleMatches, setShowKaggleMatches] = useState(false);

  const handleSave = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (companyIndustry) {
          onSave({
            id: companyIndustry.id,
            standardIndustry: values.standardIndustry,
          });
        } else {
          onSave({
            company: values.company,
            standardIndustry: values.standardIndustry,
          });
        }
      }
    });
  };

  const getModelTitle = () =>
    `${companyIndustry ? 'Update' : 'Add'} Company Industry`;
  const companyName = get(companyIndustry, 'company.title');
  return (
    <Modal
      title={getModelTitle()}
      visible
      width={800}
      onCancel={onCancel}
      onOk={handleSave}
      okText={companyIndustry ? 'Update' : 'Add'}
      confirmLoading={loading}
      className="company-industry-model"
    >
      <Form>
        <div className="company-industry-model__company">
          <div className="company-industry-model__company__label">
            Company Info
          </div>
          <div className="company-industry-model__company__details">
            <CompanyLogo logo={get(companyIndustry, 'company.logoUrl')} />
            <div className="company-industry-model__company__info-wrapper">
              <Form.Item
                label="Title"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
              >
                {getFieldDecorator('companyTitle', {
                  rules: [{ required: true }],
                  initialValue: companyName,
                })(
                  <Select
                    placeholder="Company Name"
                    disabled={!!companyIndustry}
                    showArrow={false}
                  />
                )}
              </Form.Item>
              <div className="company-industry-model__company__info-wrapper2">
                <CompanyInfo company={companyIndustry?.company} />
                <Button
                  type="primary"
                  loading={isGettingKaggleCompaniesMatches}
                  disabled={loading}
                  onClick={() => {
                    setShowKaggleMatches(true);
                    if (
                      kaggleCompaniesMatches &&
                      kaggleCompaniesMatches[companyName]
                    ) {
                      return;
                    }
                    getKaggleComapniesMatches({
                      companyName,
                    });
                  }}
                >
                  Get Matches from Kaggle Dataset
                </Button>
              </div>
            </div>
          </div>
          <CompanyMatches
            show={showKaggleMatches}
            loading={isGettingKaggleCompaniesMatches}
            dataSource={kaggleCompaniesMatches[companyName]}
          />
        </div>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="Standard Industries">
              {getFieldDecorator('standardIndustry', {
                rules: [{ required: true }],
                initialValue: get(companyIndustry, 'industry'),
              })(
                <Select
                  placeholder="Please select a standard industry"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  disabled={loading}
                >
                  {standardIndustries.map(industry => (
                    <Option key={industry.value} value={industry.value}>
                      {industry.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Is Approved"
              extra="Would be automatically marked as approved while saving"
            >
              {getFieldDecorator('isApproved', {
                initialValue: capitalize(get(companyIndustry, 'isApproved')),
              })(<Input placeholder="Is Approved" disabled />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'company_industry_modal' })(
  CompanyIndustryModal
);
