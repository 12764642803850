import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { fetchActionItems } from '../../../api';
import { setFormData } from '../actions';
import ActionItemKeys from '../queryKeys/ActionItems';

const fetchActionItemsApi = async page => {
  const response = await fetchActionItems(page);
  const { data } = response;
  return data;
};

const useActionItemsQuery = page => {
  const dispatch = useDispatch();
  return useQuery([ActionItemKeys.ActionItems, page], () =>
    fetchActionItemsApi(page)
  );
};

export default useActionItemsQuery;
