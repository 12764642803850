/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Tooltip, PageHeader, Spin, Input, Alert, Row, Col } from 'antd';
import { fetchIcons } from '../../api/pgIconSetApi';
import Content from '../../component/Content';
import useAsyncFetch from '../../hooks/useAsyncFetch';
import styles from './IconSet.module.scss';

const IconSet = () => {
  const [icons, setIcons] = useState([]);
  const [tooltipText, setTooltipText] = useState({});
  const { success, error, response, isLoading } = useAsyncFetch(
    true,
    fetchIcons
  );

  useEffect(() => {
    if (success) {
      setIcons(response.data);
    }
  }, [success, response]);

  const copyUrlToClipboard = (url, id) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(url);
    }

    setTooltipText({ [id]: 'Address copied!' });

    setTimeout(() => {
      setTooltipText(ids => {
        const prevIds = { ...ids };
        delete prevIds[id];

        return prevIds;
      });
    }, 1000);
  };
  const handleSearch = e => {
    e.persist();
    setIcons(() =>
      response.data.filter(icon => icon.name.includes(e.target.value))
    );
  };

  return (
    <>
      <PageHeader title={`Icon set (${icons.length})`} />
      <Content className={styles.contentConatiner}>
        {isLoading ? (
          <div className={styles.spinnerWrapper}>
            <Spin />
          </div>
        ) : error ? (
          <Alert message="Couldn't fetch icons!" type="error" />
        ) : (
          <>
            <Input
              placeholder="Search"
              id="searchInput"
              onChange={handleSearch}
              className={styles.searchInput}
            />
            <div className={styles.container}>
              <Row gutter={[16, 16]} className={styles.row}>
                {icons.map(icon => (
                  <Col span={3}>
                    <Tooltip
                      key={icon.id}
                      title={tooltipText[icon.id] || 'Click To Copy Address'}
                      placement="bottom"
                    >
                      <div
                        className={styles.iconWrapper}
                        onClick={() => copyUrlToClipboard(icon.url, icon.id)}
                      >
                        <div className={styles.imageContainer}>
                          <div>
                            <img src={icon.url} alt={icon.name} />
                          </div>
                        </div>
                        <Tooltip title={icon.name}>
                          <p>{icon.name}</p>
                        </Tooltip>
                      </div>
                    </Tooltip>
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </Content>
    </>
  );
};

export default IconSet;
