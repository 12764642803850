import React from 'react';
import { Table, Tag } from 'antd';
import uuid from '../../../utils/uuid';
import variations from '../../../constants/defaultSettingVariationConstants';
import { getEnabledNotifications } from '../util';

const { Column } = Table;

const HubsTable = ({ data, isLoading, rowSelection }) => (
  <Table
    dataSource={data}
    loading={isLoading}
    rowSelection={rowSelection}
    rowKey="id"
  >
    <Column title="Id" dataIndex="id" key="id" />
    <Column title="Name" dataIndex="name" key="name" />
    <Column
      title="Launch Group"
      dataIndex="defaultSetting"
      key="defaultSetting"
      render={defaultSetting =>
        variations.find(v => v.key === defaultSetting)?.name || ''
      }
    />

    <Column
      title="Active Triggers"
      dataIndex="settings"
      key="settings"
      render={(settings, record) => {
        const evendDrivenNotificaitons = getEnabledNotifications(record);
        return (evendDrivenNotificaitons || []).map(key => (
          <Tag color="green" key={`${key}-${uuid()}`}>
            {key}
          </Tag>
        ));
      }}
    />
  </Table>
);

export default HubsTable;
