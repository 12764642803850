import { Button, Card, PageHeader, Spin, Tree } from 'antd';
import every from 'lodash/every';
import intersection from 'lodash/intersection';
import memoize from 'lodash/memoize';
import union from 'lodash/union';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Content from '../../../component/Content';
import SelectActions from '../../../component/SelectActions';
import { getDependencies, getLeaves, getRequiredFields, renderTreeNodes, getLabels } from '../../../utils/checkBoxTreeUtils';
import { breadCrumbItemRender } from '../../../utils/helpers';
import { fetchAllHubs, saveHubConfig, setHubManagement } from '../actions';
import nodes from './nodes';
import SaveModal from './SaveModal';

const breadcrumbConf = { 
  routes: [
    {
      path: '/hubs',
      breadcrumbName: 'Hubs',
    },
    {
      path: '',
      breadcrumbName: 'Save Configs',
    },
  ],
  itemRender: breadCrumbItemRender
}

const labels = getLabels(nodes);

class HubSave extends PureComponent {

  requiredFields = getRequiredFields(nodes);
  dependencies = getDependencies(nodes);

  state = {
    checked: this.requiredFields,
    expanded: nodes.map(node => node.key),
  }

  componentDidMount() {
    if(!this.props.hubs) this.props.fetchAllHubs()
  }

  getHub = memoize(hubId => 
    this.props.hubs.find(hub => hub.id === parseInt(hubId))
  );

  renderLoader = () => {
    return <Spin size="large"/>
  }

  onCheck = checked => {
    checked = union(checked, this.requiredFields);
    checked = intersection(getLeaves(nodes), checked);
    checked = checked.filter(module => {
      const dependencies = this.dependencies[module];
      if(!dependencies) return true;
      return every(dependencies, dep => checked.includes(dep))
    });
    this.setState({ checked });
  }

  onExpand = expanded => {
    this.setState({ expanded })
  }

  saveHub = () => {
    this.props.setHubManagement({ saveModalVisible: true });
  }

  onSelectAll = () => this.onCheck(getLeaves(nodes))

  onDeselectAll = () => this.onCheck([])

  renderHubContent = () => {
    const { match: { params: { hubId }}} = this.props;
    const hub = this.getHub(hubId);
    return (
      <div>
        <h1> Hub Name: <b>{hub.name}</b> </h1>
        <div>
          <Card title={<div style={{fontSize: '14px', color: 'rgba(0,0,0,0.45)'}}>Select settings to save</div>} >
            <SelectActions 
              onSelectAll={this.onSelectAll}
              onDeselectAll={this.onDeselectAll}
            />
            <Tree
              checkable
              onExpand={this.onExpand}
              onCheck={this.onCheck}
              expandedKeys={this.state.expanded}
              checkedKeys={this.state.checked}
              selectedKeys={[]}
            >
              {renderTreeNodes(nodes, labels)}
            </Tree>
          </Card>
        </div>
        <Button 
          style={{marginTop: '20px'}}
          onClick={this.saveHub}
        > 
          Save 
        </Button>
      </div>
    )
  }

  renderContent = () => {
    const { hubsLoading: loading, hubs } = this.props;
    if(loading) return this.renderLoader()
    if(hubs) return this.renderHubContent()
    return null;
  }

  onSaveModalOk = name => {
    const { checked } = this.state;
    const { match: { params: { hubId }}} = this.props;
    this.props.saveHubConfig({
      checked,
      name,
      hubId,
    })
  }

  onSaveModalCancel = () => {
    this.props.setHubManagement({ saveModalVisible: false });
  }

  render() {
    const {saveModalVisible} = this.props;
    return (
      <div>
        <PageHeader title="Save Hub configuration file" breadcrumb={breadcrumbConf}/>
        <Content>
          <div>
            <Card title="Hub Configuration File">
              <p>
                Select all of the settings and content which you want to export. Only the items you select here 
                will be included in the configuration file download. When importing a configuration file, you 
                will still be able to de-select items if necessary.
              </p>
              {this.renderContent()}
            </Card>
          </div>
          <SaveModal
            visible={saveModalVisible}
            onOk={this.onSaveModalOk}
            onSaveModalCancel={this.onSaveModalCancel}
            onCancel={this.onSaveModalCancel}
          />
        </Content>
      </div>
    )
  }
}

const mapStateToProps = ({
  hub: { hubs, hubsLoading, saveModalVisible }
}) => ({ hubs, hubsLoading, saveModalVisible  });

const mapDispatchToProps = { 
  fetchAllHubs,
  setHubManagement,
  saveHubConfig,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(HubSave));
