/* eslint-disable no-console */
import { all, call, put, select } from 'redux-saga/effects';
import { map, get } from 'lodash';
import history from '../../../history';

import { POPUP_ALERT } from '../../../actions/alert';
import { defaultUsertypeTemplateSettings } from '../../../utils/helpers';

import {
  handleActionSetIsSingleLoading,
  handleActionSetManageData,
  handleActionSetManageDataCount,
  handleActionSetManageDataPageSize,
  handleActionSetManageLoading,
  handleActionsSetIsSaving,
  selectManageData,
  selectManageDataPage,
  selectUpsellOptions,
  setActiveHubsLoading,
  setActiveIdForModal,
  setCanEditTemplate,
  setGroupedTemplates,
  setTemplateName,
  setTemplateSettings,
  setTemplateTableRows,
  setTemplateUserType,
  setUpsellData,
  setUpsellOptions,
  setUsertypeLibraryData,
  setUsertypeLibraryTemplates,
  setUsertypeLibraryUpsells,
} from '../actions/manageActions';

import {
  createTemplateAPI,
  createUpsellAPI,
  createUsertypeLibraryAPI,
  deleteTemplateAPI,
  deleteUpsellAPI,
  deleteUsertypeLibraryAPI,
  fetchActiveHubsAPI,
  fetchTemplateByIdAPI,
  fetchTemplatesAPI,
  fetchUpsellByIdAPI,
  fetchUpsellsAndTemplatesAPI,
  fetchUpsellsAPI,
  fetchUsertypeLibrariesAPI,
  fetchUsertypeLibraryByIdAPI,
  getUpsellOptionsAPI,
  updateTemplateAPI,
  updateUpsellAPI,
  updateUsertypeLibraryAPI,
} from '../../../api/usertypeSettings';

export function* fetchUpsellsSaga() {
  try {
    yield put(handleActionSetManageLoading(true));
    let page = yield select(selectManageDataPage);
    if (!page) page = 1;
    const { data: response } = yield call(fetchUpsellsAPI, { page });
    yield put(handleActionSetManageData(response.data));
    yield put(handleActionSetManageDataCount(response.count));
    yield put(handleActionSetManageDataPageSize(response.pageSize));
    yield put(handleActionSetManageLoading(false));
  } catch (e) {
    console.log('Error in fetchUpsellsSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching upsell data. Please try again.',
      },
    });
  }
}

export function* saveUpsellSaga({ data }) {
  try {
    const manageData = yield select(selectManageData);
    yield put(handleActionsSetIsSaving(true));

    if (data.id) {
      const { id, name, description, ctaText, type, ctaLink, emails } = data;
      const { data: response } = yield call(updateUpsellAPI, {
        id,
        name,
        description,
        ctaText,
        type,
        ctaLink,
        emails,
      });
      const updatedData = manageData.map(el => {
        if (el && el.id === data.id) {
          el = response;
        }
        return el;
      });
      yield put(handleActionSetManageData(updatedData));

      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Upsell Updated!' },
      });
    } else {
      const { data: response } = yield call(createUpsellAPI, data);
      yield call(fetchUpsellsSaga);

      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Upsell Created!' },
      });
    }

    history.push('/usertype-settings/manage-upsells/');
    yield put(handleActionsSetIsSaving(false));
  } catch (e) {
    console.log('Error in saveUpsellSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error saving upsell data. Please try again.',
      },
    });
  }
}

export function* fetchUpsellByIdSaga({ data: upsellId }) {
  try {
    yield put(handleActionSetIsSingleLoading(true));
    const { data } = yield call(fetchUpsellByIdAPI, upsellId);
    if (data) {
      yield put(setUpsellData(data));
    }
    yield put(handleActionSetIsSingleLoading(false));
  } catch (e) {
    console.log('Error in fetchUpsellByIdSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching upsell data. Please try again.',
      },
    });
  }
}

export function* saveUsertypeLibrarySaga({ data }) {
  try {
    yield put(handleActionsSetIsSaving(true));

    if (data.id) {
      yield call(updateUsertypeLibraryAPI, data);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Usertype Library Updated!' },
      });
    } else {
      yield call(createUsertypeLibraryAPI, data);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Usertype Library Created!' },
      });
    }

    history.push('/usertype-settings/manage-usertype-library/');
    yield put(handleActionsSetIsSaving(false));
  } catch (e) {
    console.log('Error in saveUsertypeLibrarySaga --- ', e);
    const errMessage = get(e, 'response.data.error', '');
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message:
          errMessage || 'Error saving usertype library data. Please try again.',
      },
    });
    yield put(handleActionsSetIsSaving(false));
  }
}

export function* fetchUsertypeLibrariesSaga() {
  try {
    yield put(handleActionSetManageLoading(true));
    let page = yield select(selectManageDataPage);
    if (!page) page = 1;
    const { data: response } = yield call(fetchUsertypeLibrariesAPI, { page });
    yield put(handleActionSetManageData(response.data));
    yield put(handleActionSetManageDataCount(response.count));
    yield put(handleActionSetManageDataPageSize(response.pageSize));
    yield put(handleActionSetManageLoading(false));
  } catch (e) {
    console.log('Error in fetchUsertypeLibrariesSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching usertype library data. Please try again.',
      },
    });
  }
}

export function* fetchUsertypeLibraryByIdSaga({ data: usertypeLibraryId }) {
  try {
    yield put(handleActionSetIsSingleLoading(true));
    const { data } = yield call(fetchUsertypeLibraryByIdAPI, usertypeLibraryId);
    if (data) {
      yield put(setUsertypeLibraryData(data));
    }
    yield put(handleActionSetIsSingleLoading(false));
  } catch (e) {
    console.log('Error in fetchUsertypeLibraryByIdSaga --- ', e);

    const errMessage = get(e, 'response.data.error', '');
    if (errMessage.toLowerCase().includes('no such usertype library')) {
      history.replace('/usertype-settings/manage-usertype-library/');
    }

    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message:
          errMessage ||
          'Error fetching usertype library data. Please try again.',
      },
    });
  }
}

export function* fetchUsertypeLibraryUpsellsAndTemplates() {
  try {
    yield put(handleActionSetIsSingleLoading(true));
    const { data } = yield call(fetchUpsellsAndTemplatesAPI);
    if (data) {
      yield put(setUsertypeLibraryUpsells(data.upsells));
      yield put(setUsertypeLibraryTemplates(data.templates));
    }
    yield put(handleActionSetIsSingleLoading(false));
  } catch (e) {
    console.log('Error in fetchUsertypeLibraryUpsellsAndTemplates --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching usertype library data. Please try again.',
      },
    });
  }
}

export function* deleteUsertypeLibrarySaga({ data: id }) {
  try {
    yield call(deleteUsertypeLibraryAPI, id);
    yield put(setActiveIdForModal(null));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Usertype Library Deleted!' },
    });
    yield call(fetchUsertypeLibrariesSaga);
    history.push(`/usertype-settings/manage-usertype-library/`);
  } catch (e) {
    console.log('Error in deleteUsertypeLibrarySaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error deleteing usertype library.' },
    });
  }
}

export function* fetchTemplateByIdSaga({ data: templateId }) {
  try {
    yield put(handleActionSetManageLoading(true));
    const upsellOpts = yield select(selectUpsellOptions);
    if (upsellOpts.length === 0) {
      const { data: upsellOptions } = yield call(getUpsellOptionsAPI);
      yield put(setUpsellOptions(upsellOptions));
    }

    if (templateId) {
      const { data: template } = yield call(fetchTemplateByIdAPI, templateId);
      yield all([
        put(setTemplateName(template.name)),
        put(setTemplateUserType(template.standardUsertype)),
        put(setCanEditTemplate(template.canEdit)),
        put(setTemplateSettings(template.settings)),
        put(setTemplateTableRows(map(template.settings, el => el))),
      ]);
    } else {
      yield put(
        setTemplateTableRows(map(defaultUsertypeTemplateSettings, el => el))
      );
      yield put(setCanEditTemplate(true));
    }

    yield put(handleActionSetManageLoading(false));
  } catch (e) {
    console.log('Error in fetchTemplateByIdSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching template data. Please try again.',
      },
    });
  }
}

export function* saveTemplateSaga({ data }) {
  try {
    yield put(handleActionsSetIsSaving(true));

    if (data.id) {
      const { data: response } = yield call(updateTemplateAPI, data);
      yield all([
        put(setTemplateName(response.name)),
        put(setTemplateUserType(response.standardUsertype)),
        put(setTemplateSettings(response.settings)),
        put(setTemplateTableRows(map(response.settings, el => el))),
      ]);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Template Updated!' },
      });
    } else {
      yield call(createTemplateAPI, data);
      yield put({
        type: POPUP_ALERT,
        data: { type: 'success', message: 'Template Created!' },
      });
    }

    yield put(handleActionsSetIsSaving(false));
    history.push('/usertype-settings/manage-templates/');
  } catch (e) {
    const errMessage = get(e, 'response.data.error', null);
    console.log('Error in saveTemplateSaga --- ', e);
    yield put(handleActionsSetIsSaving(false));
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: errMessage || 'Error saving template data. Please try again.',
      },
    });
  }
}

export function* fetchTemplatesSaga() {
  try {
    yield put(handleActionSetManageLoading(true));
    const { data: groupedTemplates } = yield call(fetchTemplatesAPI);
    yield put(setGroupedTemplates(groupedTemplates));
    yield put(handleActionSetManageLoading(false));
  } catch (e) {
    console.log('Error in fetchTemplateSaga --- ', e);
    yield put(handleActionSetManageLoading(false));
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching templates. Please try again.',
      },
    });
  }
}

export function* fetchActiveHubsSaga({ data: templateId }) {
  try {
    yield put(setActiveHubsLoading(true));
    let page = yield select(selectManageDataPage);
    if (!page) page = 1;
    const { data: response } = yield call(fetchActiveHubsAPI, {
      id: templateId,
      page,
    });
    yield put(handleActionSetManageData(response.data));
    yield put(handleActionSetManageDataCount(response.count));
    yield put(handleActionSetManageDataPageSize(response.pageSize));
    yield put(setActiveHubsLoading(false));
  } catch (e) {
    console.log('Error in fetchActiveHubsSaga --- ', e);
    yield put(setActiveHubsLoading(false));
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching associated hubs. Please try again.',
      },
    });
  }
}

export function* deleteTemplateSaga({ data: templateId }) {
  try {
    yield call(deleteTemplateAPI, templateId);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Usertype Template Deleted!' },
    });
    yield call(fetchTemplatesSaga);
  } catch (e) {
    const errMessage = get(e, 'response.data.error', null);
    console.log('Error in deleteTemplateSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: errMessage || 'Error deleting template. Please try again.',
      },
    });
  }
}

export function* deleteUpsellSaga({ data: upsellId }) {
  try {
    const manageData = yield select(selectManageData);
    if (manageData.length) {
      yield put(
        handleActionSetManageData(manageData.filter(el => el.id !== upsellId))
      );
    }
    yield call(deleteUpsellAPI, upsellId);
    yield put(setActiveIdForModal(null));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Upsell Deleted!' },
    });
    history.push(`/usertype-settings/manage-upsells`);
  } catch (e) {
    const errMessage = get(e, 'response.data.error', null);
    console.log('Error in deleteUpsellSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: errMessage || 'Error deleting upsell. Please try again.',
      },
    });
  }
}
