import React, { useMemo, useState } from 'react';
import { Card, Table } from 'antd';
import classes from '../AskAnythingMetrics.module.scss';
import { useGetAskedQuesCountQuery } from '../query/useGetAskedQuesCountQuery';
import { get } from 'lodash';
import DataTableFilters from './DataTableFilters';
import { numCompare, stringCompare } from '../util';

const QuestionsAskedDataTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const pageSize = 10;

  const { data, isLoading: isFetchingQuesCount } = useGetAskedQuesCountQuery({
    ...filters,
    currentPage,
    perPage: pageSize,
  });

  const totalRecords = useMemo(() => get(data, '0.total_records', 0), [data]);

  const columns = [
    {
      title: 'Hub ID',
      dataIndex: 'hub_id',
      key: 'hub_id',
    },
    {
      title: 'Hub Name',
      dataIndex: 'hub_name',
      key: 'hub_name',
      sorter: (r1, r2) => stringCompare(r1, r2, 'hub_name'),
    },
    {
      title: 'Total # of Questions Asked',
      dataIndex: 'total_asked_count',
      key: 'total_asked_count',
      sorter: (r1, r2) => numCompare(r1, r2, 'total_asked_count'),
    },
    {
      title: 'Total # of Questions Answered',
      dataIndex: 'total_answered_count',
      key: 'total_answered_count',
      sorter: (r1, r2) => numCompare(r1, r2, 'total_answered_count'),
    },
    {
      title: 'Platform',
      key: 'from_platform',
      children: [
        {
          title: '# of Ques Asked',
          key: 'asked_from_platform',
          render: record => get(record, 'source_counts.platform', 0),
          sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.platform'),
        },
        {
          title: '# of Ques Answered',
          key: 'answered_platform_ques',
          render: record => get(record, 'answered_counts.platform', 0),
          sorter: (r1, r2) => numCompare(r1, r2, 'answered_counts.platform'),
        },
      ],
    },
    {
      title: 'Email',
      key: 'from_email',
      children: [
        {
          title: '# of Ques Asked',
          key: 'asked_from_email',
          render: record => get(record, 'source_counts.email', 0),
          sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.email'),
        },
        {
          title: '# of Ques Answered',
          key: 'answered_email_ques',
          render: record => get(record, 'answered_counts.email', 0),
          sorter: (r1, r2) => numCompare(r1, r2, 'answered_counts.email'),
        },
      ],
    },
    {
      title: 'Landing Page',
      key: 'from_landing_page',
      children: [
        {
          title: '# of Ques Asked',
          key: 'asked_from_landing_page',
          render: record => get(record, 'source_counts.landingPage', 0),
          sorter: (r1, r2) => numCompare(r1, r2, 'source_counts.landingPage'),
        },
        {
          title: '# of Ques Answered',
          key: 'answered_landing_page_ques',
          render: record => get(record, 'answered_counts.landingPage', 0),
          sorter: (r1, r2) => numCompare(r1, r2, 'answered_counts.landingPage'),
        },
      ],
    },
  ];

  return (
    <>
      <DataTableFilters
        setFiltersToApply={setFilters}
        isApplyingFilters={isFetchingQuesCount}
      />
      <Card bordered={false}>
        <Table
          className={classes.data_table}
          dataSource={data}
          columns={columns}
          loading={isFetchingQuesCount}
          bordered
          pagination={{
            current: currentPage,
            pageSize,
            hideOnSinglePage: true,
            onChange: updatedCurrPage => {
              setCurrentPage(updatedCurrPage);
            },
            total: totalRecords,
          }}
        />
      </Card>
    </>
  );
};

export default QuestionsAskedDataTable;
