export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const SET_USER_DETAIL = 'SET_USER_DETAIL';

export const UPDATE_USER_ACTION_STATUS = 'UPDATE_USER_ACTION_STATUS';

export const DELETE_USER_DETAIL = 'DELETE_USER_DETAIL';

export const UNLINK_LINKEDIN = 'UNLINK_LINKEDIN';
export const UNLINK_FACEBOOK = 'UNLINK_FACEBOOK';

export const UNSET_DATABASE_KEY = 'UNSET_DATABASE_KEY';
export const UNSET_SSO_ID = 'UNSET_SSO_ID';

export const UNDELETE_USER_HUB = 'UNDELETE_USER_HUB';

export const fetchUserDetail = data => ({
  type: FETCH_USER_DETAIL,
  data 
});

export const deleteUserDetail = data => ({
  type: DELETE_USER_DETAIL,
  data 
});

export const unlinkLinkedin = data => ({
  type: UNLINK_LINKEDIN,
  data 
});

export const unlinkFacebook = data => ({
  type: UNLINK_FACEBOOK,
  data 
});

export const unsetDatabaseKey = data => ({
  type: UNSET_DATABASE_KEY,
  data 
});

export const unsetSsoId = data => ({
  type: UNSET_SSO_ID,
  data 
});

export const undeleteUserHub = data => ({
  type: UNDELETE_USER_HUB,
  data
})