import React from 'react';
import { Button, Divider, Tooltip } from 'antd';

const getColumns = (manageType, handleEditClick, handleDeleteClick) => {
  if (manageType === 'upsells')
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Active in User Type Template',
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => (
          <div className="usertype-actions">
            <Button type="link" onClick={() => handleEditClick(record)}>
              Edit
            </Button>
            <Divider type="vertical" />
            <Tooltip
              placement="bottom"
              title={
                !record.canDelete
                  ? "This Upsell Action is already provisioned to a User Type Template in an Active Hub. It can't be deleted."
                  : ''
              }
            >
              <Button
                type="link"
                className="usertype-actions__delete"
                disabled={!record.canDelete}
                onClick={() => handleDeleteClick(record)}
              >
                Delete
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];
  if (manageType === 'usertype-library')
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Standard Templates',
        dataIndex: 'standardUsertypes',
        key: 'standardUsertypes',
      },
      {
        title: 'Add-On Templates',
        dataIndex: 'upsells',
        key: 'upsells',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => (
          <div className="usertype-actions">
            <Button type="link" onClick={() => handleEditClick(record)}>
              Edit
            </Button>
            <Divider type="vertical" />
            <Tooltip
              placement="bottom"
              title={
                record.isAssociatedToHub
                  ? `This user type library is already provisioned to an active hub. It can't be deleted.`
                  : ''
              }
            >
              <Button
                type="link"
                className="usertype-actions__delete"
                disabled={record.isAssociatedToHub}
                onClick={() => handleDeleteClick(record)}
              >
                Delete
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];
};

export default getColumns;
