import {
  SET_QUERY_LIST,
  SET_QUERY_LIST_LOADING,
  SET_QUERY_IS_CUSTOM,
  SET_QUERY_TITLE,
  SET_QUERY_CODE,
  SET_IS_QUERY_CODE_CHANGED,
  CLEAR_QUERY_FORM,
  SET_QUERY_PROCESSING,
  SET_QUERY_FORM_LOADING,
  SET_SELECTED_QUERY_HUB,
  SET_QUERY_TABLE_NAME,
  SET_QUERY_DB,
  SET_TABLE_COLUMNS,
  SET_UPDATE_REQUIRED,
  SET_CHART_LIST,
  SET_REPORT_TABLE,
  SET_ASSOCIATED_REPORT_ID,
  SET_ASSOCIATED_CHART_TYPE,
  SET_ASSOCIATED_CHART_QUERY,
  SET_ASSOCIATED_CHART_TAG,
  SET_ASSOCIATED_CHART_IDENTIFIER,
  SET_INDEX_CODE,
  SET_RELATED_REPORT_ID,
  SET_CHANGE_MESSAGE,
  SET_QUERY_FREQUENCY,
} from '../actions/analyticsQuery';

const initialState = {
  queryList: [],
  queryListLoading: false,
  queryIsCustom: false,
  queryTitle: '',
  queryCode: '',
  indexCode:
    'create index {{tableName}}_hub_cluster_idx on {{tableName}}(hub_id, cluster_id);',
  isQueryCodeChanged: false,
  isQueryProcessing: false,
  queryFormLoading: false,
  selectedHub: undefined,
  queryTableName: '',
  queryDb: 'primary_db',
  tableColumns: [],
  updateRequired: false,
  chartList: [],
  reportTable: [],
  reportId: undefined,
  chartType: undefined,
  chartQuery: '',
  chartTag: undefined,
  chartIdentifier: '',
  relatedReportId: '',
  changeMessage: '',
  frequency: 'daily',
};

const analyticsQuery = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_QUERY_LIST:
      return {
        ...state,
        queryList: data,
      };
    case SET_QUERY_LIST_LOADING:
      return {
        ...state,
        queryListLoading: data,
      };
    case SET_QUERY_IS_CUSTOM:
      return {
        ...state,
        queryIsCustom: data,
      };
    case SET_QUERY_TITLE:
      return {
        ...state,
        queryTitle: data,
      };
    case SET_QUERY_CODE:
      return {
        ...state,
        queryCode: data,
      };
    case SET_IS_QUERY_CODE_CHANGED:
      return {
        ...state,
        isQueryCodeChanged: data,
      };
    case CLEAR_QUERY_FORM:
      return {
        ...state,
        queryTitle: '',
        queryCode: '',
        isQueryCodeChanged: false,
        queryIsCustom: false,
        queryTableName: '',
        tableColumns: [],
        reportId: undefined,
        chartQuery: '',
        chartTag: undefined,
        chartType: undefined,
        chartIdentifier: '',
        frequency: 'daily',
      };
    case SET_QUERY_PROCESSING:
      return {
        ...state,
        isQueryProcessing: data,
      };
    case SET_QUERY_FORM_LOADING:
      return {
        ...state,
        queryFormLoading: data,
      };
    case SET_SELECTED_QUERY_HUB:
      return {
        ...state,
        selectedHub: data,
      };
    case SET_QUERY_TABLE_NAME:
      return {
        ...state,
        queryTableName: data,
      };
    case SET_QUERY_DB:
      return {
        ...state,
        queryDb: data || initialState.queryDb,
      };
    case SET_QUERY_FREQUENCY:
      return {
        ...state,
        frequency: data || initialState.frequency,
      };
    case SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: data,
      };
    case SET_UPDATE_REQUIRED:
      return {
        ...state,
        updateRequired: data,
      };
    case SET_CHART_LIST:
      return {
        ...state,
        chartList: data,
      };
    case SET_REPORT_TABLE:
      return {
        ...state,
        reportTable: data,
      };
    case SET_ASSOCIATED_REPORT_ID:
      return {
        ...state,
        reportId: data,
      };
    case SET_ASSOCIATED_CHART_TYPE:
      return {
        ...state,
        chartType: data,
      };
    case SET_ASSOCIATED_CHART_QUERY:
      return {
        ...state,
        chartQuery: data,
      };
    case SET_ASSOCIATED_CHART_TAG:
      return {
        ...state,
        chartTag: data,
      };
    case SET_ASSOCIATED_CHART_IDENTIFIER:
      return {
        ...state,
        chartIdentifier: data,
      };
    case SET_INDEX_CODE:
      return {
        ...state,
        indexCode: data,
      };
    case SET_RELATED_REPORT_ID:
      return {
        ...state,
        relatedReportId: data,
      };
    case SET_CHANGE_MESSAGE:
      return {
        ...state,
        changeMessage: data,
      };
    default:
      return state;
  }
};

export default analyticsQuery;
