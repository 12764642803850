/* eslint-disable no-console */
import { put, call, select, fork } from 'redux-saga/effects';
import { cloneDeep, map, findIndex, get, isEmpty, keyBy } from 'lodash';

import {
  findTemplateById,
  getModuleTemplates,
  fetchHubsWithPathway,
  addNewTemplate,
  updateTemplate,
  deleteTemplateById,
  fetchProvisioningFilters,
} from '../../../api';
import history from '../../../history';
import * as hubApi from '../../../api';

import { POPUP_ALERT } from '../../../actions/alert';

import { setData, getDefaultPathwayState, setLoading } from '../actions';

export function* fetchModuleTemplates({ data: params = {} }) {
  try {
    const { page = 1 } = params;
    yield put(setLoading({ listing: true }));
    const { pagination, hubList } = yield select(getDefaultPathwayState);
    const {
      data: { templates, pagination: paginationData },
    } = yield call(getModuleTemplates, { ...pagination, current: page });
    const templateData = map(templates, t => ({ ...t, key: t.id }));
    yield put(setData({ templates: templateData, pagination: paginationData }));
    if (isEmpty(hubList)) yield fork(fetchHubs);
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching templates' },
    });
  }
  yield put(setLoading({ listing: undefined }));
}

export function* getHubsPathwayList() {
  try {
    yield put(setLoading({ pathway_list: true }));
    const { data } = yield call(fetchHubsWithPathway);
    yield put(setData({ hubsPathways: data.data }));
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching pathway list' },
    });
  }
  yield put(setLoading({ pathway_list: undefined }));
}

export function* getProvisioningFilters() {
  try {
    yield put(setLoading({ pathway_filters: true }));
    const { data } = yield call(fetchProvisioningFilters);

    const filterOptions = get(data, 'filters.filterOptions', {});
    const keyedFilterOptions = {};
    map(filterOptions, (options, filterLabel) => {
      keyedFilterOptions[filterLabel] = keyBy(options, 'name');

      filterOptions[filterLabel] = map(options, singleOption => ({
        ...singleOption,
        value: singleOption.name,
      }));
    });

    const updatedFilterData = { ...data.filters, keyedFilterOptions };

    yield put(setData({ filterData: updatedFilterData }));
  } catch (err) {
    console.log(err);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching pathway provisioning filters',
      },
    });
  }
  yield put(setLoading({ pathway_filters: undefined }));
}

export function* fetchHubs() {
  try {
    yield put(setLoading({ launch_in: true }));
    const { data: hubs } = yield call(hubApi.hub.getAll, {
      fields: ['id', 'name', 'identifier', 'defaultSetting', 'domain'],
    });
    yield put(setData({ hubList: hubs }));
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error hubs list' },
    });
  }
  yield put(setLoading({ launch_in: undefined }));
}

export function* addNewTemplateSaga({ data }) {
  try {
    const { templates } = yield select(getDefaultPathwayState);
    const clonedTemplates = cloneDeep(templates);
    if (data.id) {
      const { data: newTemplate } = yield call(updateTemplate, {
        formData: data,
      });
      const index = findIndex(clonedTemplates, { id: data.id });
      if (index !== -1) {
        clonedTemplates.splice(index, 1, newTemplate.data);
      }
    } else {
      const { data: newTemplate } = yield call(addNewTemplate, {
        formData: data,
      });
      clonedTemplates.unshift(newTemplate.data);
    }
    yield put(
      setData({
        templates: clonedTemplates,
        showPathwayForm: false,
        pathwayData: undefined,
        singleTemplate: undefined,
        preSelectedHubs: [],
      })
    );
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'success',
        message: data.id ? `Template Updated` : 'New Template Added',
      },
    });
    history.push('/pathway-provisioning');
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error adding new template' },
    });
  }
}

export function* fetchTemplateToEdit({ data: params = {} }) {
  try {
    const { templateId } = params;
    yield put(setLoading({ edit_template: true }));
    const {
      data: { template },
    } = yield call(findTemplateById, {
      templateId,
    });
    const toUpdate = {
      singleTemplate: {
        ...template,
        title: get(template, 'template.title'),
        description: get(template, 'template.description'),
        link: get(template, 'link'),
      },
    };
    toUpdate.showPathwayForm = true;
    toUpdate.preSelectedHubs = get(template, 'setting.launch_in.hubs', []);
    toUpdate.pathwayData = {
      hubId: get(template, ['setting', 'hubId']),
      pathwayId: get(template, ['setting', 'pathwayId']),
    };
    yield put(setData(toUpdate));
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching template' },
    });
  }
  yield put(setLoading({ edit_template: undefined }));
}
export function* deleteTemplateSaga({ data }) {
  try {
    const { templateId } = data;
    yield put(setLoading({ deleting: true }));
    yield call(deleteTemplateById, templateId);
    const { templates } = yield select(getDefaultPathwayState);
    const clonedTemplates = cloneDeep(templates);
    const index = findIndex(clonedTemplates, { id: templateId });
    if (index !== -1) {
      clonedTemplates.splice(index, 1);
      yield put(
        setData({
          templates: clonedTemplates,
          showPathwayForm: false,
          pathwayData: undefined,
          singleTemplate: undefined,
        })
      );
    }
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'success',
        message: `Template Deleted!`,
      },
    });
    history.push('/pathway-provisioning');
  } catch (e) {
    console.log(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error deleting template' },
    });
  }
  yield put(setLoading({ deleting: undefined }));
}
