/* eslint-disable implicit-arrow-linebreak */
import { request } from '../utils/request';

export const fetchArchetypes = () =>
  request.get(`/standard-data-sets/get-options`, {
    params: { slugs: JSON.stringify(['archetype']) },
  });

export const fetchHelpTopics = () =>
  request.get(`/standard-data-sets/get-options`, {
    params: { slugs: JSON.stringify(['help-topics']) },
  });

export const fetchConnectionSources = () =>
  request.get('/messages/connection-sources');

export const fetchPreconnectionTemplates = () =>
  request.get('/messages/pre-connection-templates');

export const fetchPostconnectionTemplates = params => request.get('', params);

export const fetchThreadStages = params =>
  request.get('/messages/stages', params);

export const fetchTemplateModelKeywordsData = params =>
  request.get('/messages/template-keywords-models', params);

export const saveTemplateApi = params =>
  request.post('/inbox-templates', params);

export const updateTemplateApi = ({ id, ...updates }) =>
  request.put(`/inbox-templates/${id}`, updates);

export const fetchTemplatesApi = params =>
  request.get('/inbox-templates', { params });

export const deleteTemplateApi = ({ id }) =>
  request.delete(`/inbox-templates/${id}`);

export const saveTipApi = params => request.post('/inbox-tip', params);

export const updateTipApi = ({ id, ...updates }) =>
  request.put(`/inbox-tip/${id}`, updates);

export const fetchTipsApi = params => request.get('/inbox-tip', { params });

export const deleteTipApi = ({ id }) => request.delete(`/inbox-tip/${id}`);

export const fetchCustomUserTemplateDataApi = ({ ...data }) =>
  request.post(`/custom-user-template/analytics`, data);

export const saveAlumStudentOutreachTemplateApi = params =>
  request.post(`/alum-student-outreach-template`, params);

export const fetchAlumStudentOutreachTemplateApi = params =>
  request.get(`/alum-student-outreach-template`, { params });

export const updateAlumStudentOutreachTemplateApi = ({ id, ...payload }) =>
  request.put(`/alum-student-outreach-template/${id}`, payload);

export const deleteAlumStudentOutreachTemplateApi = ({ id }) =>
  request.delete(`/alum-student-outreach-template/${id}`);
