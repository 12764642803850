export const FETCH_CLUSTER_HUBS = 'FETCH_CLUSTER_HUBS';
export const SET_CLUSTERS = 'SET_CLUSTERS';
export const SET_HUBS = 'SET_HUBS';
export const FETCH_CLUSTER_HUBS_FOR_HOME = 'FETCH_CLUSTER_HUBS_FOR_HOME';
export const CLUSTER_HUBS_LOADING = 'CLUSTER_HUBS_LOADING';
export const SET_CLUSTER_HUBS = 'SET_CLUSTER_HUBS';
export const SET_CLUSTER_HUB_LOADING = 'SET_CLUSTER_HUB_LOADING';
export const SET_CLUSTER_HUB_OPENING = 'SET_CLUSTER_HUB_OPENING';

export const OPEN_HUB = 'ClusterHubs/OPEB_HUB';
export const SET_LOGIN_MODAL = 'SET_LOGIN_MODAL';
export const SET_SELECTED_CLUSTER_HUB = 'SET_SELECTED_CLUSTER_HUB';

export const fetchClusterHubs = () => ({
  type: 'FETCH_CLUSTER_HUBS',
});

export const fetchClusterHubsForHome = () => ({
  type: FETCH_CLUSTER_HUBS_FOR_HOME,
});

export const openHub = data => ({
  type: OPEN_HUB,
  data,
});

export const setLoginModal = data => ({
  type: SET_LOGIN_MODAL,
  data,
});

export const setSelectedClusterHub = data => ({
  type: SET_SELECTED_CLUSTER_HUB,
  data,
});

export const clustersSelector = ({ clusterHubs: { clusters } }) => clusters;
