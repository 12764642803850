import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateGlobalListGoalApi } from '../../../api';
import { notify } from '../../../utils/notify';
import AchievementKeys from '../queryKeys/GlobalLists';

const updateGlobalListGoal = async body => {
  const { data } = await updateGlobalListGoalApi(body);
  return data;
};

const useUpdateGlobalListMutation = page => {
  const client = useQueryClient();
  return useMutation(updateGlobalListGoal, {
    onSuccess: data => {
      const listGoals = data.goals;
      client.setQueryData([AchievementKeys.globalLists, page], oldData => ({
        ...oldData,
        lists: oldData.lists.map(obj => (data.id === obj.id ? data : obj)),
      }));
      client.setQueryData([AchievementKeys.Achievements, data.slug], listGoals);
      notify('Achievement Updated successfully!', 'success');
    },
    onError: error => {
      notify(error.response.data.message, 'error');
    },
  });
};

export default useUpdateGlobalListMutation;
