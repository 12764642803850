import React from 'react';
import { Modal } from 'antd';
import { get } from 'lodash';

const DeleteTemplateModal = ({ data, onCancel, onOk }) => {
  return (
    <Modal
      title="Deleting a template?"
      closable={true}
      visible={!!data}
      onCancel={onCancel}
      onOk={() => onOk && onOk(data)}
      destroyOnClose={true}
      className="automated-marketing__modal--delete-template"
    >
      <p>Are you sure you want to delete this template?</p>
      <p>
        <b>Title: {get(data, 'title')}</b>
      </p>
      <p>
        <b>Category: {get(data, 'category')}</b>
      </p>
      <p>
        <b>This action cannot be undone.</b>
      </p>
    </Modal>
  );
};

export default DeleteTemplateModal;
