import React, { PureComponent } from 'react';
import { Select } from 'antd';

const { Option } = Select;

class SelectFilter extends PureComponent {
  constructor(props) {
    super(props);
    const value = props.value || [];
    this.state = {
      value,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        value: nextProps.value || [],
      };
    }
    return null;
  }

  triggerChange = changedValue => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(changedValue);
    }
  };

  handleChange = value => {
    console.log('value ---', value);
    this.setState({
      value,
    });
    this.triggerChange(value);
  };

  getOptions = () => {
    const { dataSource } = this.props;
    const children = [];

    if (dataSource && dataSource.length > 0)
      dataSource.map((option, key) => {
        if (
          typeof option === 'object' &&
          option.hasOwnProperty('key') &&
          option.hasOwnProperty('name')
        ) {
          children.push(
            <Option key={key} value={`${option.key}#$#enabled`}>
              {option.name}{' '}
              <b>
                <i>is enabled</i>
              </b>
            </Option>
          );
          children.push(
            <Option key={key} value={`${option.key}#$#disabled`}>
              {option.name}{' '}
              <b>
                <i>is disabled</i>
              </b>
            </Option>
          );
        }
      });

    if (children.length === 0) return null;
    return children;
  };

  render() {
    const { placeholder } = this.props;
    const { value } = this.state;
    return (
      <Select
        placeholder={placeholder}
        style={{ width: '40%' }}
        value={value}
        onChange={this.handleChange}
      >
        {this.getOptions()}
      </Select>
    );
  }
}

export default SelectFilter;
