import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StandardHubManagement from '.';
import ManageBaseConfig from './containers/ManageBaseConfig';

export default () => (
  <Switch>
    <Route
      path="/standard-hub-management/manage-config"
      component={ManageBaseConfig}
    />
    <Route path="/standard-hub-management" component={StandardHubManagement} />
  </Switch>
);
