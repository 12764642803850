import { request } from '../utils/request';

export default {
  getAll: params => request.get('/cluster/all', { params }),
  multiHubCheck: params => request.get('/cluster/multi-hub-check', { params }),
  getOne: id => request.get(`/cluster/find-one/${id}`),
  saveConfig: data => request.post('/cluster/save-config', data),
  getClusterConfigs: params => request.get('cluster-config/all', { params}),
  deleteClusterConfig: clusterConfigId => request.delete(`cluster-config/${clusterConfigId}`),
  createCopy: data => request.post('/cluster', data),
  createFromConfig: data=> request.post('/cluster/create', data),
  save: cluster => request.put(`/cluster/${cluster.id}`, cluster),
  filterClusters: body => request.post('/cluster/all/filter', body),
}
