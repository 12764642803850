import { STORE_ADMIN_DETAIL } from '../actions/admin';

const initialState = {};

const admin = (state = initialState, { type, data }) => {
  switch (type) {
    case STORE_ADMIN_DETAIL:
      return {
        ...data
      }
    default: return state
  }
};

export default admin;
