/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, PageHeader, Switch } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Content from '../../../component/Content';
import DefaultTextTable from '../DefaultTextTable';
import { useClusterTextList } from '../queries';

const ClusterText = () => {
  const history = useHistory();
  const [isArchived, setIsArchived] = useState(false);
  const { data, isLoading } = useClusterTextList({ isArchived });

  const hubTextList = React.useMemo(() => {
    if (!data) return [];
    const clusterTexts = get(data, 'clusterTexts');
    return (
      clusterTexts?.map(d => ({
        id: d.id,
        identifier: d.identifier,
        defaultText: d.text,
        modifiedByClusterCount: (d.modifiedclusters || []).length,
        modifiedByClusters: d.modifiedclusters || [],
        modifiedOn: d.updated_at,
        module: d.module,
      })) || []
    );
  }, [data]);

  return (
    <>
      <PageHeader
        title="Cluster Text Settings"
        extra={[
          <label>Archived Identifiers </label>,
          <Switch
            className="archived-identifiers-toggle"
            checked={isArchived}
            onChange={val => {
              setIsArchived(val);
            }}
            title="Archived Identifiers"
          />,
          <Button
            key="1"
            onClick={() => {
              history.push('/default-text/hub');
            }}
          >
            Hub Text Settings
          </Button>,
        ]}
      />
      <Content>
        <DefaultTextTable
          data={hubTextList}
          loading={isLoading}
          isCluster
          isArchived={isArchived}
        />
      </Content>
    </>
  );
};

export default ClusterText;
