import { request } from '../utils/request';

export const fetchGroupsDataApi = params =>
  request.post('/admin/groups-programs-tier', params);
export const fetchGroupTierApi = () => request.get('/admin/fetch-group-tier');
export const fetchAllGroupTierApi = ({ type, page }) =>
  request.get(`/admin/fetch-all-group-tier?type=${type}&page=${page}`);
export const fetchGroupTierByIdApi = id =>
  request.get(`/admin/fetch-group-tier/${id}`);
export const updateTierApi = params =>
  request.put(`/admin/update-group-tier/${params.id}`, params);
export const createTierApi = params =>
  request.post('/admin/create-group-tier', params);
export const deleteTierApi = id =>
  request.delete(`/admin/delete-group-tier/${id}`);
export const fetchTemplateApi = params =>
  request.post('/admin/fetch-template', params);
export const saveConfigurationApi = params =>
  request.post('/admin/save-tier-setting', params);
