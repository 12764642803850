import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Tabs, Card, Button, Table } from 'antd';
import { get, isEmpty } from 'lodash';

import PathwaySelectModal from './components/PathwaySelectModal';

import { fetchModuleTemplates, setData, findTemplateById } from './actions';

import './index.scss';

const { TabPane } = Tabs;

class TemplateList extends React.Component {
  componentDidMount() {
    const { fetchModuleTemplates, templateId, findTemplateById } = this.props;

    fetchModuleTemplates();
    if (templateId) findTemplateById();
  }

  componentDidUpdate(prevProps) {
    const { templateId, findTemplateById } = this.props;
    if (prevProps && templateId && prevProps.templateId !== templateId) {
      findTemplateById({ templateId });
    }
  }

  getColumns = () => [
    {
      title: 'Internal Name',
      dataIndex: 'templateName',
      rowKey: 'internal',
      render: (text, record, index) => (
        <div key={index} className="list__table-box">
          {text}
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'template',
      rowKey: 'template',
      render: (template, record, index) => (
        <div key={index} className="list__table-box">
          {(template.title || '').replace(/{{pathwayHubText}}/g, 'Pathway')}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      rowKey: 'id',
      render: (id, record, index) => (
        <div key={index} className="list__table-box">
          <Link to="#" onClick={e => this.goToEdit(e, id)}>
            Edit
          </Link>
        </div>
      ),
    },
  ];

  goToEdit = (event, id) => {
    event.preventDefault();
    const { history } = this.props;
    history.push(`/pathway-provisioning/edit/${id}`);
  };

  toggleModal = () => {
    const { setData } = this.props;
    setData({ showPathwayCopyModal: true });
  };

  render() {
    const {
      templates,
      loading,
      pagination,
      fetchModuleTemplates,
      singleTemplate = {},
    } = this.props;

    return (
      <div className="list">
        <Tabs type="card">
          <TabPane tab="Pathways" key="1">
            <Card
              className="list__card"
              loading={get(loading, 'listing', false)}
              bordered={false}
              title={
                <div className="list__header">
                  <h4>Pathway Templates</h4>
                  <Button
                    type="primary"
                    icon="plus-circle"
                    onClick={() => this.toggleModal()}
                    disabled={!isEmpty(singleTemplate) && !!!singleTemplate.id}
                  >
                    Create
                  </Button>
                </div>
              }
            >
              <Table
                className="list__table"
                columns={this.getColumns()}
                dataSource={templates}
                rowKey="list"
                pagination={{
                  ...pagination,
                  showTitle: false,
                  onChange: page => fetchModuleTemplates({ page }),
                }}
              />
            </Card>
          </TabPane>
        </Tabs>
        <PathwaySelectModal />
      </div>
    );
  }
}

const mapStateToProps = ({
  PathwaysProvisioning: {
    templates,
    loading,
    pagination,
    hubsPathways,
    showPathwayCopyModal,
    singleTemplate,
  },
}) => ({
  templates,
  loading,
  pagination,
  hubsPathways,
  showPathwayCopyModal,
  singleTemplate,
});

const mapDispatchToProps = {
  fetchModuleTemplates,
  setData,
  findTemplateById,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TemplateList)
);
