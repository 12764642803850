/* eslint-disable react/state-in-constructor */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, Input, Form, Select } from 'antd';

import { standardUserTypeOptions } from '../../../../../utils/helpers';
import {
  setTemplateName,
  setTemplateUserType,
} from '../../../actions/manageActions';

const { Option } = Select;

class ManageTemplateForm extends PureComponent {
  handleNameChange = e => {
    const { setTemplateName } = this.props;
    const { value } = e.target;
    setTemplateName(value);
  };

  handleUsertypeChange = value => {
    const { setTemplateUserType } = this.props;
    setTemplateUserType(value);
  };

  render() {
    const { form, name, standardUsertype, isSaving } = this.props;
    return (
      <Card className="manage-template-form">
        <Form>
          <Form.Item label="Name">
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Name cannot be empty.',
                },
              ],
              initialValue: name,
            })(
              <Input
                placeholder="Add a name"
                onChange={this.handleNameChange}
                disabled={isSaving}
                autoComplete="off"
              />
            )}
          </Form.Item>
          <Form.Item label="Standardized user types">
            {form.getFieldDecorator('standardUsertype', {
              rules: [
                {
                  required: true,
                  message: 'Please select a Standardized user type',
                },
              ],
              initialValue: standardUsertype || undefined,
            })(
              <Select
                id="standardUserType"
                placeholder="Select..."
                onChange={this.handleUsertypeChange}
                disabled={isSaving}
              >
                {standardUserTypeOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
                <Option key="none" value="none">
                  No Usertype
                </Option>
              </Select>
            )}
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = ({
  usertypeManage: {
    template: { name, standardUsertype },
    isSaving,
  },
}) => ({ name, standardUsertype, isSaving });

const mapDispatchToProps = {
  setTemplateName,
  setTemplateUserType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTemplateForm);
