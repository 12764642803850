import {
  SET_HUB_MGMT,
  SET_HUB_CREATE_LOADING,
  SET_LAUNCH_GROUP_CHANGE_LOG,
  REQUEST_DELETE_HUB_LOADING,
  SET_EL_ORGANIZATIONS,
} from './actions';

const initialState = {
  hubs: null,
  hubsLoading: false,
  saveModalVisible: false,
  hubConfigs: null,
  hubConfigsLoading: false,
  hubCreateLoading: false,
  editHub: null,
  editHubLoading: false,
  filtersEnabled: false,
  modulesList: [],
  changeLogsLoading: false,
  changeLogs: [],
  deleteHubLoading: false,
  elOrganizations: [],
};

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_HUB_MGMT:
      return { ...state, ...data };
    case SET_HUB_CREATE_LOADING:
      return { ...state, hubCreateLoading: data };
    case SET_LAUNCH_GROUP_CHANGE_LOG:
      return { ...state, ...data };
    case REQUEST_DELETE_HUB_LOADING:
      return { ...state, deleteHubLoading: data };
    case SET_EL_ORGANIZATIONS:
      return { ...state, elOrganizations: data };
    default:
      return state;
  }
};

export default reducer;
