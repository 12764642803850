import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClusterManagement from './index';
import ClusterSave from './ClusterSave';
import CreateCluster from './CreateCluster';
import ClusterConfigs from './ClusterConfigs';

export default () => {
  return (
    <Switch>
      <Route path={`/clusters/save/:clusterId`} component={ClusterSave} />
      <Route path={`/clusters/new`} component={CreateCluster} />
      <Route path={`/clusters/configs`} component={ClusterConfigs} />
      <Route path={`/clusters`} component={ClusterManagement} />
    </Switch>
  );
};

