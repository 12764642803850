import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import CareerPathForm from './careerPathForm';

const EditModal = ({
  careerPathWithAssociatedData,
  auxiliaryDataForCareerPaths,
  onSave,
  onCancel,
  isLoading,
  isEditing,
  careerPathChangeLog,
  revertCareerPathChange,
  editingKey,
}) => {
  const [changedFields, setChangedFields] = useState([]);
  const [changeLogId, setChangeLogId] = useState('');
  useEffect(() => {
    if (careerPathChangeLog && !isEditing) {
      setChangeLogId(careerPathChangeLog?.id);
      setChangedFields(
        careerPathChangeLog?.diff.filter(
          val => !['/createdAt', '/updatedAt'].includes(val.path)
        )
      );
    }
  }, [careerPathChangeLog, isEditing]);
  return (
    <Modal
      title={isEditing ? 'Add/Modify Career Path' : 'Changelog'}
      visible
      closable={!(isLoading.addCareerPath || isLoading.updateCareerPath)}
      maskClosable={!(isLoading.addCareerPath || isLoading.updateCareerPath)}
      onCancel={() => {
        onCancel();
      }}
      footer={null}
      width={650}
    >
      <CareerPathForm
        careerPathWithAssociatedData={
          isEditing
            ? careerPathWithAssociatedData
            : careerPathChangeLog.oldValue
        }
        auxiliaryDataForCareerPaths={auxiliaryDataForCareerPaths}
        isLoading={isLoading}
        isEditing={isEditing}
        onSave={onSave}
        onCancel={onCancel}
        changedFields={changedFields}
        changeLogId={changeLogId}
        revertCareerPathChange={revertCareerPathChange}
        editingKey={editingKey}
      />
    </Modal>
  );
};

export default EditModal;
