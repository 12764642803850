import React from 'react';
import { connect } from 'react-redux';
import { Button, Switch, Row, Col, Modal } from 'antd';
import { pull } from 'lodash';

import { moduleOptions } from '../../AutomatedMarketing/utils';

import { setData } from '../actions';

import '../index.scss';

class ModuleSelectModal extends React.Component {
  state = {
    selectedModules: [],
  };

  componentDidMount() {
    const { modules = [] } = this.props;
    if (modules && modules.length)
      this.setState({
        selectedModules: modules,
      });
  }

  toggleModal = onSubmit => {
    const { setData, onSave } = this.props;
    const { selectedModules } = this.state;
    if (onSubmit) {
      onSave(selectedModules);
    }
    setData({
      showModuleSelectionModal: false,
    });
  };

  handleGroupChange = (key, eventState) => {
    const { selectedModules } = this.state;
    if (eventState && selectedModules.indexOf(key) === -1) {
      this.setState({ selectedModules: selectedModules.concat([key]) });
      return;
    }
    this.setState({ selectedModules: pull(selectedModules, key) });
  };

  render() {
    const { showModuleSelectionModal } = this.props;
    const { selectedModules } = this.state;

    return (
      <Modal
        visible={showModuleSelectionModal}
        className="provisioning_launch_modal"
        title="Require Modules"
        destroyOnClose
        onCancel={() => this.toggleModal()}
        footer={[
          <Button key="cancel" onClick={() => this.toggleModal()}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={false}
            onClick={() => this.toggleModal(true)}
          >
            Save
          </Button>,
        ]}
      >
        <div className="provisioning_launch_modal__header">All Modules</div>
        <Row className="provisioning_launch_modal__row provisioning_launch_modal__row-module">
          {moduleOptions.map(v => (
            <>
              <Col span={20}>{v.name}</Col>
              <Col span={4}>
                <Switch
                  checked={
                    selectedModules && selectedModules.indexOf(v.value) !== -1
                  }
                  onChange={eventState =>
                    this.handleGroupChange(v.value, eventState)
                  }
                />
              </Col>
            </>
          ))}
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  PathwaysProvisioning: { showModuleSelectionModal },
}) => ({
  showModuleSelectionModal,
});

const mapDispatchToProps = {
  setData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModuleSelectModal);
