import { fork, takeLatest } from 'redux-saga/effects';

import {
  FETCH_BASE_CONFIG,
  FETCH_HUBS,
  SAVE_BASE_CONFIG,
  START_STANDARD_MIGRATION,
  VERIFY_AND_GET_DATA,
  FETCH_HUB_CUSTOM_FIELDS,
  FETCH_PG_STANDARD_DATA_FIELDS,
  SAVE_CUSTOM_FIELD_MIGRATION_CONFIG,
  TRIGGER_MIGRATION,
  OPEN_CUSTOM_FIELD_PAGE,
  UPDATE_OLD_DATA_TO_STANDARD_DATA_SET,
} from '../actions';

import {
  fetchBaseConfigSaga,
  fetchHubsSaga,
  fetchJobStatusWatcher,
  saveBaseConfigSaga,
  startStandardMigrationSaga,
  verifyAndGetDataSaga,
  fetchHubCustomFieldsSaga,
  fetchPgStandardFieldsSaga,
  saveCustomFieldMigrationConfigSaga,
  triggerMigrationSaga,
  openCustomFieldPageSaga,
  updateStandardDataMappingSaga,
} from './sagas';

const sagas = [
  takeLatest(FETCH_HUBS, fetchHubsSaga),
  takeLatest(FETCH_BASE_CONFIG, fetchBaseConfigSaga),
  takeLatest(SAVE_BASE_CONFIG, saveBaseConfigSaga),
  takeLatest(VERIFY_AND_GET_DATA, verifyAndGetDataSaga),
  takeLatest(START_STANDARD_MIGRATION, startStandardMigrationSaga),
  takeLatest(FETCH_HUB_CUSTOM_FIELDS, fetchHubCustomFieldsSaga),
  takeLatest(FETCH_PG_STANDARD_DATA_FIELDS, fetchPgStandardFieldsSaga),
  takeLatest(
    SAVE_CUSTOM_FIELD_MIGRATION_CONFIG,
    saveCustomFieldMigrationConfigSaga
  ),
  takeLatest(TRIGGER_MIGRATION, triggerMigrationSaga),
  takeLatest(OPEN_CUSTOM_FIELD_PAGE, openCustomFieldPageSaga),
  takeLatest(
    UPDATE_OLD_DATA_TO_STANDARD_DATA_SET,
    updateStandardDataMappingSaga
  ),
  fork(fetchJobStatusWatcher),
];

export default sagas;
