import { takeLatest, put } from 'redux-saga/effects'; 
import { FETCH_USER_DETAIL, SET_USER_DETAIL, DELETE_USER_DETAIL, UNLINK_FACEBOOK, UNLINK_LINKEDIN, UNSET_DATABASE_KEY, UNSET_SSO_ID, UPDATE_USER_ACTION_STATUS, UNDELETE_USER_HUB } from './actions';
import { POPUP_ALERT } from '../../actions/alert';
import { getUserDetailsAPI, deleteUserDetailAPI, unlinkFacebookAPI, unlinkLinkedinAPI, unsetDatabaseKeyAPI, unsetSsoIdAPI, undeleteUserHubAPI } from './api';

export default [
  takeLatest(FETCH_USER_DETAIL, fetchUserDetail),
  takeLatest(DELETE_USER_DETAIL, deleteUserDetail),
  takeLatest(UNLINK_FACEBOOK, unlinkFacebook),
  takeLatest(UNLINK_LINKEDIN, unlinkLinkedin),
  takeLatest(UNSET_DATABASE_KEY, unsetDatabaseKey),
  takeLatest(UNSET_SSO_ID, unsetSsoId),
  takeLatest(UNDELETE_USER_HUB, undeleteUserHub)
];

function* fetchUserDetail(params) {
  try {
    let { data } = yield getUserDetailsAPI(params.data);

    yield put({ type: SET_USER_DETAIL, data });
  } catch (e) {
    yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
  }
}

function* deleteUserDetail(params) {
  try {
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "process" }});

    yield deleteUserDetailAPI(params.data);

    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "success" }});
    yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Successfully deleted the records." }});

  } catch (e) {
    yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "fail" }});
  }
}

function* unlinkFacebook(params) {
  try {
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "process" }});

    yield unlinkFacebookAPI(params.data);

    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "success" }});
    yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Successfully unlinked facebook account." }});

  } catch (e) {
    yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "fail" }});
  }
}

function* unlinkLinkedin(params) {
  try {
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "process" }});
  
    yield unlinkLinkedinAPI(params.data);
  
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "success" }});
    yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Successfully unlinked linkedin account." }});

  } catch (e) {
    yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "fail" }});
  }
}

function* unsetDatabaseKey(params) {
  try {
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "process" }});
  
    yield unsetDatabaseKeyAPI(params.data);

    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "success" }});
    yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Successfully unset database key." }});

  } catch (e) {
    yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "fail" }});
  }
}

function* unsetSsoId(params) {
  try {
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "process" }});

    yield unsetSsoIdAPI(params.data);

    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "success" }});
    yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Successfully unset sso id." }});
  
  } catch (e) {
    yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "fail" }});
  }
}

function* undeleteUserHub(params) {
  try {
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "process" }});

    yield undeleteUserHubAPI(params.data);

    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "success" }});
    yield put({ type: POPUP_ALERT, data: { type: 'success', message: "Successfully undeleted the user from hub" }});

  } catch (e) {
    yield put({ type: POPUP_ALERT, data: { type: 'error', message: "Something went wrong." }});
    yield put({ type: UPDATE_USER_ACTION_STATUS, data: { status: "fail" }});
  }
}