export const FETCH_EXPERIMENTS = 'ABTesting/FETCH_EXPERIMENTS';
export const SET_EXPERIMENTS = 'ABTesting/SET_EXPERIMENTS';
export const DELETE_EXPERIMENT = 'ABTesting/DELETE_EXPERIMENT';
export const UPDATE_EXPERIMENT = 'ABTesting/UPDATE_EXPERIMENT';
export const EXPERIMENTS_LOADING = 'ABTesting/EXPERIMENTS_LOADING';
export const EXPERIMENT_UPDATING = 'ABTesting/EXPERIMENT_UPDATING';
export const ADD_EXPERIMENT_HUB = 'ABTesting/ADD_EXPERIMENT_HUB';
export const DELETE_EXPERIMENT_HUB = 'ABTesting/DELETE_EXPERIMENT_HUB';
export const SET_EXPERIMENT_HUB_MAPPING = `ABTesting/SET_EXPERIMENT_HUB_MAPPING`;

export const fetchExperiments = () => ({
	type: FETCH_EXPERIMENTS,
});

export const setExperiments = data => ({
	type: SET_EXPERIMENTS,
	data,
})

export const deleteExperiment = data => ({
	type: DELETE_EXPERIMENT,
	data
});

export const updateExperiment = data => ({
	type: UPDATE_EXPERIMENT,
	data
});

export const setExperimentsLoading = data => ({
	type: EXPERIMENTS_LOADING,
	data,
})

export const setExperimentUpdating = data => ({
	type: EXPERIMENT_UPDATING,
	data,
});

export const addExperimentHub = data => ({
	type: ADD_EXPERIMENT_HUB,
	data,
})

export const deleteExperimentHub = data => ({
	type: DELETE_EXPERIMENT_HUB,
	data,
})

export const setExperimentHubMapping = data => ({
	type: SET_EXPERIMENT_HUB_MAPPING,
	data,
})

// Selectors:
export const retrieveExperiments = ({abTesting}) => abTesting.experiments;