import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  PageHeader,
  Card,
  Divider,
  Button,
  Row,
  Col,
  Typography,
  Checkbox,
  Input,
  Alert,
} from 'antd';
import Content from '../../component/Content';
import ImageSettings from './components/ImageSettings';
import FileUploader from '../../component/FileUploader';
import {
  setImageQuality,
  setMaxImageWidth,
  setFilePath,
  resetFileUploadState,
} from './actions';
import notify from '../../utils/notify';
import { getUploadUrl } from '../../utils/getUploadUrl';
import { createIcon } from '../../api/pgIconSetApi';
import styles from './index.module.scss';

const { Paragraph } = Typography;

class FileUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploadingIcon: false,
      iconName: '',
    };
  }

  componentWillUnmount() {
    const { resetFileUploadState } = this.props;
    resetFileUploadState();
  }

  handleUpload = data => {
    const { setFilePath } = this.props;
    const { filesFailed, filesUploaded } = data;
    if (filesFailed.length > 0) {
      notify(
        'Unable to upload the file',
        'error',
        'Please try again in some time'
      );
    } else if (filesUploaded.length > 0) {
      const files = filesUploaded.map(file => getUploadUrl(file, '/admin/'));
      setFilePath(files);

      if (this.state.isUploadingIcon) {
        createIcon({
          name: 'pg-' + this.state.iconName,
          url: files[0],
          addedBy: this.props.adminId,
        })
          .then(() => {
            this.setState({ iconName: '' });
            notify('Icon added successfully!', 'success');
          })
          .catch(error => {
            const { message } = error.response.data;
            notify(message, 'error');
          });
      }

      notify('Files uploaded successfully!', 'success');
    }
  };

  handleIconCheck = e => {
    const updates = {
      isUploadingIcon: e.target.checked,
    };

    if (!e.target.checked) updates.iconName = '';

    this.setState(updates);
  };

  handleIconNameChange = e => {
    this.setState({ iconName: e.target.value.trim().toLowerCase() });
  };

  render() {
    const {
      imageQuality,
      maxImageWidth,
      setImageQuality,
      setMaxImageWidth,
      filePath,
    } = this.props;

    const { iconName, isUploadingIcon } = this.state;

    return (
      <>
        <PageHeader title="File Upload" />
        <Content>
          <Card>
            <h3>Normal Files</h3>
            <Divider />
            {isUploadingIcon && (
              <>
                <label htmlFor="iconNameInput">Icon name</label>
                <Input
                  id="iconNameInput"
                  onChange={this.handleIconNameChange}
                  value={iconName}
                  className={styles.iconNameInput}
                  prefix="pg-"
                />
                <Alert
                  className={styles.iconSetInfo}
                  message="Please make sure the icon does not already exist in the icon set before adding."
                  type="info"
                  showIcon
                />
              </>
            )}
            <FileUploader
              path="/admin/"
              options={
                isUploadingIcon
                  ? {
                      maxFiles: 1,
                      acceptFn: (file, options) =>
                        options
                          .mimeFromMagicBytes(file.originalFile)
                          .then(res => {
                            if (res !== 'image/svg+xml') {
                              throw new Error(
                                'Please upload only svg for icon.'
                              );
                            }

                            return Promise.resolve();
                          }),
                    }
                  : {}
              }
              render={({ onPick }) => (
                <Button
                  size="large"
                  type="primary"
                  disabled={isUploadingIcon && !iconName}
                  onClick={onPick}
                >
                  Upload a File
                </Button>
              )}
              onSuccess={this.handleUpload}
            />
            <div className={styles.iconCheckBoxWrapper}>
              <Checkbox onChange={this.handleIconCheck}>
                Uploading an icon?
              </Checkbox>
            </div>
          </Card>
          <Card style={{ marginTop: 20 }}>
            <h3>Images</h3>
            <Divider />
            <Row style={{ marginBottom: 20 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <ImageSettings
                  imageQuality={imageQuality}
                  maxImageWidth={maxImageWidth}
                  setImageQuality={setImageQuality}
                  setMaxImageWidth={setMaxImageWidth}
                />
              </Col>
            </Row>
            <FileUploader
              type="image"
              quality={imageQuality}
              maxSize={maxImageWidth}
              path="/admin/"
              render={({ onPick }) => (
                <Button size="large" type="primary" onClick={onPick}>
                  Upload an Image
                </Button>
              )}
              onSuccess={this.handleUpload}
            />
          </Card>
          {filePath && (
            <Card style={{ marginTop: 20 }}>
              <h3>Files Uploaded</h3>
              <Divider />
              {filePath.map((path, index) => (
                <Paragraph key={index} copyable>
                  {path}
                </Paragraph>
              ))}
            </Card>
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = ({
  admin: { id: adminId },
  fileUpload: {
    imageSettings: { imageQuality, maxImageWidth },
    filePath,
  },
}) => ({ imageQuality, maxImageWidth, filePath, adminId });

const mapDispatchToProps = {
  setImageQuality,
  setMaxImageWidth,
  setFilePath,
  resetFileUploadState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUpload);
