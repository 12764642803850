import React, { PureComponent } from 'react';
import { PageHeader, Breadcrumb, Icon } from 'antd';
import { connect } from 'react-redux';
import { Route, Switch, Link, withRouter } from 'react-router-dom';

import PreviewTemplateModal from './_components/PreviewTemplateModal';

import { setPreviewTemplate } from './actions';

import Emails from './Emails';
import Templates from './Templates';
import EmailEditor from './EmailEditor';
import './AutomatedMarketing.scss';

const routes = [
  {
    title: <Icon type="home" />,
    path: '/automated-marketing',
    component: Emails,
  },
  {
    title: 'Templates',
    path: '/automated-marketing/templates',
    component: Templates,
  },
  {
    title: 'Email Editor',
    path: '/automated-marketing/email-editor',
    component: EmailEditor,
  },
];

const BreadcrumbItems = ({ location }) => {
  const items = [];

  for (let i = 0; i < routes.length; i++) {
    if (location.pathname === routes[i].path) {
      items.push(<Breadcrumb.Item key={i}>{routes[i].title}</Breadcrumb.Item>);
      break;
    }
    items.push(
      <Breadcrumb.Item key={i}>
        <Link to={routes[i].path}>{routes[i].title}</Link>
      </Breadcrumb.Item>
    );
  }

  return items;
};

class AutomatedMarketing extends PureComponent {
  render() {
    const { location, previewTemplate, setPreviewTemplate } = this.props;

    return (
      <div className="automated-marketing">
        <PageHeader
          title="Automated Marketing"
          className="am__page-header"
          subTitle={
            location.pathname === routes[0].path ? (
              <>
                Overview of all emails sent from Automated Marketing can be seen
                here (
                <a href="https://app.periscopedata.com/app/peoplegrove/707701/Automated-Marketing-Emails">
                  View Data
                </a>
                )
              </>
            ) : (
              <Breadcrumb>
                <BreadcrumbItems location={location} />
              </Breadcrumb>
            )
          }
        />
        <Switch>
          {routes.map((route, key) => (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={key}
            />
          ))}
        </Switch>
        <PreviewTemplateModal
          previewTemplate={previewTemplate}
          onCancel={() => setPreviewTemplate(null)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ automatedMarketing: { previewTemplate } }) => ({
  previewTemplate,
});

const mapDispatchToProps = {
  setPreviewTemplate,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AutomatedMarketing)
);
