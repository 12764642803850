export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const USER_AUTH_PROCESS = 'USER_AUTH_PROCESS';
export const LOGOUT_TOKEN = 'LOGOUT_TOKEN';
export const VERIFY_ADMIN_REDIRECT_TOKEN = 'VERIFY_ADMIN_REDIRECT_TOKEN';
export const AUTH_SET_AUTH_TOKEN = 'AUTH_SET_AUTH_TOKEN';

export const authenticateUser = data => ({
  type: AUTHENTICATE_USER,
  data,
});

export const getCurrentUser = data => ({
  type: GET_CURRENT_USER,
  data,
});

export const signOutUser = data => ({
  type: SIGN_OUT_USER,
  data,
});

export const logoutToken = data => ({
  type: LOGOUT_TOKEN,
  data,
});

export const verifyAdminRedirectToken = data => ({
  type: VERIFY_ADMIN_REDIRECT_TOKEN,
  data,
});

export const authSetAuthToken = data => ({
  type: AUTH_SET_AUTH_TOKEN,
  data,
});
