import React, { PureComponent } from 'react';
import { Modal, Checkbox, Form, Input, Button, Tag, Icon, message } from 'antd';
import { request } from '../../../utils/request';
import { PickerOverlay } from 'filestack-react';
import get from 'lodash/get';
import s from './index.module.sass';

const { TextArea } = Input;

const FormItem = Form.Item;
class CreateNewModal extends PureComponent {
  state = {
    seedTitle: '',
    seedNotes: '',
    seedIsDefault: false,
    uploadedFile: null,
    security: null,
    showPicker: false,
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  cancelFile = () => this.setState({ uploadedFile: null });

  handleSeedUpload = result => {
    let uploadedFile = get(result, ['filesUploaded', 0]);
    if (uploadedFile) this.setState({ uploadedFile });
  };

  showPicker = async () => {
    const payload = {
      location: "s3",
      container: process.env.REACT_APP_AWS_UPLOAD_BUCKET,
      path: "/seeds/",
    };
    await request.post('/filestack-upload', payload)
      .then(({ status, data }) => {
        if (status === 200) {
          this.setState({ showPicker: true, security: data });
        }
      })
      .catch(({response}) => { 
        message.error(response.data.error);
      });
  };

  validateFields = () => {
    let valid = true;
    if (this.state.seedTitle.trim() === '') {
      message.warning('Please fill in a title.');
      valid = false;
    }
    if (!this.state.uploadedFile) {
      valid = false;
      message.warning('You have to upload a file first.');
    }
    return valid;
  };

  onOk = () => {
    if (!this.validateFields()) return;
    this.props.createSeed({
      title: this.state.seedTitle,
      notes: this.state.seedNotes,
      makeDefault: this.state.seedIsDefault,
      uploadedFile: this.state.uploadedFile,
    });
  };

  render() {
    return (
      <Modal
        visible={this.props.visible}
        onOk={this.onOk}
        onCancel={this.props.onClose}
        title="Create New Seed"
        onClose={() => {
          debugger;
        }}
        className={s.modal}
      >
        <Form>
          <FormItem label="Title">
            <Input
              value={this.state.seedTitle}
              id="title"
              name="seedTitle"
              onChange={this.handleChange}
            />
          </FormItem>
          <FormItem label="Notes">
            <TextArea
              value={this.state.seedNotes}
              id="seedNotes"
              name="seedNotes"
              onChange={this.handleChange}
            />
          </FormItem>
          <FormItem>
            <Checkbox
              id="isDefault"
              value={this.state.seedIsDefault}
              name="seedIsDefault"
              onChange={this.handleChange}
            >
              Make Default
            </Checkbox>
          </FormItem>
        </Form>
        <Button onClick={this.showPicker}>
          <Icon type="cloud-upload" /> Upload File{' '}
        </Button>
        {this.state.showPicker && <PickerOverlay
          apikey={process.env.REACT_APP_FILE_PICKER_KEY}
          onSuccess={this.handleSeedUpload}
          pickerOptions={{
            maxFiles: 1,
            accept: ['.dump', '.zip', '.sql'], // TODO: accept only required formats
            storeTo: {
              location: 's3',
              region: process.env.REACT_APP_AWS_PRIVATE_UPLOAD_REGION,
              path: '/seeds/',
              container: process.env.REACT_APP_AWS_UPLOAD_BUCKET,
              access: 'private',
            },
            onClose: () => {
              this.setState({ showPicker: false });
            }
          }}
          clientOptions={{ security: this.state.security }}
        />
        }
        {this.state.uploadedFile !== null && (
          <Tag className={s.selectedFile} closable onClose={this.cancelFile}>
            {this.state.uploadedFile.filename}
          </Tag>
        )}
      </Modal>
    );
  }
}

export default CreateNewModal;
