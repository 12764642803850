import { useMutation } from '@tanstack/react-query';
import { request } from '../../../utils/request';

const activateTrigger = async body => {
  const response = await request.post(`/admin/auto-message-activate`, body);
  const { data } = response;
  return data;
};

const deactivateTrigger = async body => {
  const response = await request.post(`/admin/auto-message-de-activate`, body);
  const { data } = response;
  return data;
};

export const useActivateTriggerMutation = () => useMutation(activateTrigger);

export const useDeActivateTriggerMutation = () =>
  useMutation(deactivateTrigger);
