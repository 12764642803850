const prefix = 'hubs/';
export const FETCH_ALL_HUBS = `${prefix}FETCH_ALL_HUBS`;
export const SET_HUB_MGMT = `${prefix}SET_HUBS_MGMT`;
export const SAVE_HUB_CONFIG = `${prefix}SAVE_HUBS_CONFIG`;
export const FETCH_ALL_HUB_CONFIG = `${prefix}FETCH_ALL_HUB_CONFIG`;
export const DELETE_HUB_CONFIG = `${prefix}DELETE_HUB_CONFIG`;
export const CREATE_HUBS = `${prefix}CREATE_HUBS`;
export const FILTER_HUBS = `${prefix}FILTER_HUBS`;
export const SAVE_HUB = `${prefix}SAVE_HUB`;
export const EDIT_HUB = `${prefix}EDIT_HUB`;
export const REQUEST_DELETE_HUB = `${prefix}REQUEST_DELETE_HUB`;
export const REQUEST_DELETE_HUB_LOADING = `${prefix}REQUEST_DELETE_HUB_LOADING`;
export const SET_HUB_CREATE_LOADING = `${prefix}SET_HUB_CREATE_LOADING`;
export const FETCH_LAUNCH_GROUP_CHANGE_LOG = `${prefix}FETCH_LAUNCH_GROUP_CHANGE_LOG`;
export const SET_LAUNCH_GROUP_CHANGE_LOG = `${prefix}SET_LAUNCH_GROUP_CHANGE_LOG`;
export const FETCH_EL_ORGANIZATIONS = `${prefix}FETCH_EL_ORGANIZATIONS`;
export const SET_EL_ORGANIZATIONS = `${prefix}SET_EL_ORGANIZATIONS`;

export const fetchAllHubs = () => ({ type: FETCH_ALL_HUBS });
export const setHubManagement = data => ({ type: SET_HUB_MGMT, data });
export const saveHubConfig = data => ({ type: SAVE_HUB_CONFIG, data });
export const fetchAllHubConfig = data => ({ type: FETCH_ALL_HUB_CONFIG, data });
export const deleteHubConfig = hubConfigId => ({
  type: DELETE_HUB_CONFIG,
  hubConfigId,
});
export const createHubs = data => ({ type: CREATE_HUBS, data });
export const saveHub = hub => ({ type: SAVE_HUB, hub });
export const editHub = hubId => ({ type: EDIT_HUB, hubId });
export const requestDeleteHub = data => ({ type: REQUEST_DELETE_HUB, data });
export const cancelEdit = () => ({
  type: SET_HUB_MGMT,
  data: { editHub: null },
});
export const updateEditHub = editHubData => ({
  type: SET_HUB_MGMT,
  data: { editHub: editHubData },
});
export const filterHubs = data => ({ type: FILTER_HUBS, data });
export const fetchLaunchGroupChangeLog = data => ({
  type: FETCH_LAUNCH_GROUP_CHANGE_LOG,
  data,
});
export const setLaunchGroupChangeLog = () => ({
  type: SET_LAUNCH_GROUP_CHANGE_LOG,
  data: {
    changeLogs: [],
  },
});
export const fetchElOrganizations = () => ({
  type: FETCH_EL_ORGANIZATIONS,
});
export const setELOrganizations = data => ({
  type: SET_EL_ORGANIZATIONS,
  data,
});
