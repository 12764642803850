import {
  SET_BULK_EMAIL_LIST_COUNT,
  SET_BULK_EMAIL_LIST,
  SET_BULK_EMAIL_LIST_LOADING,
  SET_BULK_EMAIL_LIST_PAGE,
  CLEAR_BULK_EMAIL_STATE,
  SET_BULK_QUEUE_STATE,
} from '../actions';

const initialState = {
  bulkEmailList: [],
  bulkEmailListLoading: false,
  listCount: 0,
  page: 1,
  queueStatus: {},
};

const bulkEmailReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_BULK_EMAIL_LIST:
      return {
        ...state,
        bulkEmailList: data,
      };
    case SET_BULK_EMAIL_LIST_COUNT:
      return {
        ...state,
        listCount: data,
      };
    case SET_BULK_EMAIL_LIST_LOADING:
      return {
        ...state,
        bulkEmailListLoading: data,
      };
    case SET_BULK_EMAIL_LIST_PAGE:
      return {
        ...state,
        page: data,
      };
    case CLEAR_BULK_EMAIL_STATE:
      return {
        ...state,
        bulkEmailList: [],
        bulkEmailListLoading: false,
        listCount: 0,
        page: 1,
        queueStatus: {},
      };
    case SET_BULK_QUEUE_STATE:
      return {
        ...state,
        queueStatus: data,
      };
    default:
      return state;
  }
};

export default bulkEmailReducer;
