import {
  SET_PROCESS_HISTORIES,
  SET_CRON_JOB_LOADING,
  SET_CURRENT_MODAL,
} from '../actions';

const initialState = {
  processHistories: [],
  types: [],
  statuses: [],
  count: 0,
  loading: false,
  cronJobLoading: false,
  currentModal: null,
};

const processAlertService = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_PROCESS_HISTORIES:
      return { ...state, ...data };
    case SET_CRON_JOB_LOADING:
      return { ...state, cronJobLoading: data };
    case SET_CURRENT_MODAL:
      return { ...state, currentModal: data };
    default:
      return state;
  }
};

export default processAlertService;
