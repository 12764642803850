import React from 'react';
import { Button, Tooltip } from 'antd';
import { get, parseInt, isEmpty } from 'lodash';

const BottomBar = ({
  onSaveDraftClick,
  onSaveTemplateClick,
  onBackClick,
  onNextClick,
  onScheduleClick,
  onGetStatsClick,
  page,
  disabled = false,
  stats = {},
  editTemplateMode = false,
  selectedHubs = [],
}) => (
  <div className="am__email-editor__bottom-bar">
    {editTemplateMode ? (
      <Button onClick={onSaveTemplateClick} disabled={disabled}>
        Update template
      </Button>
    ) : (
      <>
        {page === 'editor' ? (
          <Button onClick={() => onSaveTemplateClick(page)} disabled={disabled}>
            Save as Template
          </Button>
        ) : (
          <Button onClick={onBackClick} disabled={disabled}>
            Back
          </Button>
        )}
        <Button onClick={() => onSaveDraftClick(page)} disabled={disabled}>
          Save as Draft
        </Button>
        {page === 'editor' ? (
          <Button type="primary" onClick={onNextClick} disabled={disabled}>
            Schedule Send
          </Button>
        ) : !isEmpty(stats) ? (
          !parseInt(get(stats, 'nbUsers', 0)) ||
          !parseInt(get(stats, 'nbHubs', 0)) ||
          selectedHubs.length === 0 ? (
            <Tooltip
              title={
                selectedHubs.length === 0
                  ? 'Select at least 1 hub'
                  : 'Select filters with at least 1 user'
              }
            >
              <Button type="primary" disabled={true}>
                Confirm Send
              </Button>
            </Tooltip>
          ) : (
            <Button
              type="primary"
              onClick={onScheduleClick}
              disabled={disabled}
            >
              Confirm Send
            </Button>
          )
        ) : (
          <Button type="primary" onClick={onGetStatsClick} disabled={disabled}>
            Get Stats
          </Button>
        )}
      </>
    )}
  </div>
);

export default BottomBar;
