import { request } from '../utils/request';

export const fetchSuperadmins = (data) => request.get('/superadmin/access-groups/users', data);
export const fetchAccessGroups = (data) => request.get('/superadmin/access-groups/groups', data);

export const updateSuperadminGroups = data => request.put(`/superadmin/access-groups/users`, data);
export const updateAccessGroups = data => request.put(`/superadmin/access-groups/groups`, data);

export const createAccessGroups = data => request.post(`superadmin/access-groups/groups`, data);
export const deleteAccessGroups = id => request.delete(`/superadmin/access-groups/groups/${id}`);
