import React from 'react';
import { Button } from 'antd';
import moment from 'moment';

const columns = handleClick => [
  {
    title: 'Title',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    render: (name, record) => {
      return (
        <span>
          {record.id} - {name}
        </span>
      );
    },
  },
  {
    title: 'Hub',
    dataIndex: 'hub',
    key: 'hub',
    width: '20%',
    render: (hub, record) => {
      return (
        <span>
          {record.hubName} ({record.hub_id})
        </span>
      );
    },
  },
  {
    title: 'CreatedAt',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '20%',
    render: created_at => {
      return <span>{moment(created_at).format('MMM Do YYYY, h:mm:ss a')}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: status => {
      return <span style={{ textTransform: 'capitalize' }}>{status}</span>;
    },
  },
  {
    title: 'LastUpdatedAt',
    dataIndex: 'last_updated_at',
    key: 'last_updated_at',
    width: '20%',
    render: last_updated_at => {
      return <span>{moment(last_updated_at).format('MMM Do YYYY, h:mm:ss a')}</span>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    width: '10%',
    render: (text, record) => {
      return (
        <Button
          icon="redo"
          type="primary"
          onClick={handleClick(record)}
          disabled={
            record.restartObj &&
            ['pending', 'processing'].indexOf(record.restartObj.status) > -1
          }
        >
          Restart
        </Button>
      );
    },
  },
];

export default columns;
