import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Switch, Row, Col, Modal } from 'antd';

import { moduleOptions } from '../utils';

import { setCompassData } from '../actions';

import '../index.scss';

function ModuleSelectModal(props) {
  const [selectedModules, setSelectedModules] = React.useState([]);
  const dispatch = useDispatch();

  const showModuleSelectionModal = useSelector(
    ({ compassReducer }) => compassReducer.showModuleSelectionModal
  );
  const { modules = [], onSave } = props;

  useEffect(() => {
    if (modules && modules.length) setSelectedModules(modules);
  }, [modules]);

  const closeModal = () => {
    dispatch(setCompassData({ showModuleSelectionModal: false }));
  };

  const saveRequiredModules = () => {
    onSave(selectedModules);
    closeModal();
  };

  const handleGroupChange = (key, eventState) => {
    if (eventState && selectedModules.indexOf(key) === -1) {
      setSelectedModules(selectedModules.concat([key]));
      return;
    }

    setSelectedModules(selectedModules.filter(item => item !== key));
  };
  return (
    <Modal
      visible={showModuleSelectionModal}
      className="provisioning_launch_modal"
      title="Require Modules"
      destroyOnClose
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={false}
          onClick={saveRequiredModules}
        >
          Save
        </Button>,
      ]}
    >
      <div className="provisioning_launch_modal__header">All Modules</div>
      <Row className="provisioning_launch_modal__row provisioning_launch_modal__row-module">
        {moduleOptions.map(v => (
          <div key={v.value}>
            <Col span={20}>{v.name}</Col>
            <Col span={4}>
              <Switch
                checked={
                  selectedModules && selectedModules.indexOf(v.value) !== -1
                }
                onChange={eventState => handleGroupChange(v.value, eventState)}
              />
            </Col>
          </div>
        ))}
      </Row>
    </Modal>
  );
}

export default ModuleSelectModal;
