import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, PageHeader, Card, Badge, Button }  from 'antd';
import { fetchAllHubConfig, deleteHubConfig } from '../actions';
import { breadCrumbItemRender } from '../../../utils/helpers'
import Content from '../../../component/Content';
import enablementGroups from '../../../constants/defaultSettingVariationConstants';

const breadcrumbConf = { 
  routes: [
    {
      path: '/hubs',
      breadcrumbName: 'Hubs',
    },
    {
      path: '/hubs/configs',
      breadcrumbName: 'Hub Configs',
    },
  ],
  itemRender: breadCrumbItemRender
}

class HubConfigs extends PureComponent {

  componentDidMount() {
    this.props.fetchAllHubConfig()
  }

  renderActions = hubConfigId => {
    return (
      <Button
        type="link"
        onClick={() => this.props.deleteHubConfig(hubConfigId) }>
        Delete
      </Button>
    );
  };

  renderDate = date => new Date(date).toLocaleString();
  renderStatus = (status, row) => {
    switch(status) {
      case 'pending':
        return <Badge status="processing" text="in Queue"/>
      case 'processing':
        return <Badge status="processing" text="Processing"/>
      case 'error':
        return <Badge status="error" text="Error"/>
      case 'done':
        return <Badge status="success" text="Exported"/>
      default: //unreachable
    }
  } 

  renderLaunchgroup = launchgroup => {
    const group = enablementGroups.filter(grp => grp.key === launchgroup);
    if (!group.length) return 'null';
    return group[0].name || 'Classic | Client';
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hub Id',
      dataIndex: 'hub',
      key: 'hub',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: this.renderStatus,
    },
    {
      title: 'Launch Group',
      dataIndex: 'data.srcHub.defaultSetting',
      key: 'launchgroup',
      render: this.renderLaunchgroup,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Created By User',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Delete Config',
      dataIndex: 'id',
      key: 'deleteConfig',
      render: this.renderActions
    }
  ];

  render() {
    const { loading, hubConfigs } = this.props;
    return (
      <div>
        <PageHeader title="Hub Configs" breadcrumb={breadcrumbConf} />
        <Content>
          <Card bordered={false}>
            <Table 
              loading={loading} 
              columns={this.columns} 
              dataSource={hubConfigs} 
            />
          </Card>
        </Content>
      </div>
    )
  }
}

const mapStateToProps = ({
  hub: {
    hubConfigs,
    hubConfigsLoading: loading,
  }
}) => ({
  hubConfigs,
  loading
});

const mapDispatchToProps = {
  fetchAllHubConfig,
  deleteHubConfig,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(HubConfigs));
