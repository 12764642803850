import {
  SET_REPORT_LIST_STATE,
  SET_REPORT_STATE,
  SET_REPORT_DATA,
  SET_ANALYTIC_REPORT_STATE,
} from './actions';

const initialState = {
  reportList: {
    loading: false,
    reports: [],
  },
  report: {
    loading: false,
    reportData: {},
  },
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_REPORT_LIST_STATE:
      return { ...state, reportList: { ...state.reportList, ...data } };
    case SET_REPORT_STATE:
      return { ...state, report: { ...state.report, ...data } };
    case SET_REPORT_DATA:
      return {
        ...state,
        report: {
          ...state.report,
          reportData: { ...state.report.reportData, ...data },
        },
      };
    case SET_ANALYTIC_REPORT_STATE:
      return { ...state, ...data };
    default:
      return state;
  }
};
