import { SET_SESSIONS, SET_PAGE_LOADING } from './actions';

const initialState = {
  sessions: [],
  isPageLoading: true,
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_SESSIONS:
      return { ...state, ...data };
    case SET_PAGE_LOADING:
      return { ...state, ...data };
    default:
      return state;
  }
};
