import {
  SET_COMPANY_INDUSTRY,
  SET_STANDARD_INDUSTRY,
  SET_COMPANY_INDUSTRY_LOADING,
  SET_COMPANY_INDUSTRY_SAVING,
  SET_IS_GETTING_KAGGLE_COMPANIES_MATCHES,
  SET_KAGGLE_COMPANIES_MATCHES,
} from '../actions';

const initialState = {
  loading: false,
  totalCount: 0,
  companyIndustries: [],
  standardIndustries: [],
  isCompanyIndustrySaving: false,
  isGettingKaggleCompaniesMatches: false,
  kaggleCompaniesMatches: {},
};

const CompanyIndustry = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_COMPANY_INDUSTRY:
      return { ...state, ...data };
    case SET_STANDARD_INDUSTRY:
      return { ...state, standardIndustries: data };
    case SET_COMPANY_INDUSTRY_LOADING:
      return { ...state, loading: data };
    case SET_COMPANY_INDUSTRY_SAVING:
      return { ...state, isCompanyIndustrySaving: data };
    case SET_IS_GETTING_KAGGLE_COMPANIES_MATCHES:
      return { ...state, isGettingKaggleCompaniesMatches: data };
    case SET_KAGGLE_COMPANIES_MATCHES:
      return {
        ...state,
        kaggleCompaniesMatches: {
          ...initialState.kaggleCompaniesMatches,
          ...data,
        },
      };
    default:
      return state;
  }
};

export default CompanyIndustry;
