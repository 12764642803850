/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { findIndex } from 'lodash';

import {
  DELETE_TEMPLATE,
  DELETE_UPSELL,
  DELETE_USERTYPE_LIBRARY,
  FETCH_ACTIVE_HUBS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATES,
  FETCH_UPSELL_BY_ID,
  FETCH_UPSELLS,
  FETCH_USERTYPE_LIBRARIES,
  FETCH_USERTYPE_LIBRARY_BY_ID,
  FETCH_USERTYPE_LIBRARY_UPSELLS_AND_TEMPLATES,
  SAVE_TEMPLATE,
  SAVE_UPSELL,
  SAVE_USERTYPE_LIBRARY,
  setUsertypeLibraryTemplates,
  setUsertypeLibraryUpsells,
} from '../actions/manageActions';
import { POPUP_ALERT } from '../../../actions/alert';
import {
  FETCH_HUBS,
  OPEN_HUB,
  FETCH_LIBRARIES_TEMPLATES_AND_UPSELLS,
  setHubsData,
  setHubsDataCount,
  setHubsDataLoading,
  setLibraries,
  // selectors
  selectHubsDataPage,
  selectHubsSearchText,
  setFiltersLoading,
  selectSelectedLibraries,
  selectSelectedUsertypeTemplates,
} from '../actions';
import {
  fetchHubsAPI,
  fetchLibrariesAndTemplatesAndUpsellsAPI,
} from '../../../api/usertypeSettings';
import { openHubAPI } from '../../../api/clusterHubs';

import {
  deleteTemplateSaga,
  deleteUpsellSaga,
  deleteUsertypeLibrarySaga,
  fetchActiveHubsSaga,
  fetchTemplateByIdSaga,
  fetchTemplatesSaga,
  fetchUpsellByIdSaga,
  fetchUpsellsSaga,
  fetchUsertypeLibrariesSaga,
  fetchUsertypeLibraryByIdSaga,
  fetchUsertypeLibraryUpsellsAndTemplates,
  saveTemplateSaga,
  saveUpsellSaga,
  saveUsertypeLibrarySaga,
} from './manageSaga';

export default [
  takeLatest(DELETE_USERTYPE_LIBRARY, deleteUsertypeLibrarySaga),
  takeLatest(FETCH_TEMPLATE, fetchTemplateByIdSaga),
  takeLatest(FETCH_TEMPLATES, fetchTemplatesSaga),
  takeLatest(FETCH_UPSELL_BY_ID, fetchUpsellByIdSaga),
  takeLatest(FETCH_UPSELLS, fetchUpsellsSaga),
  takeLatest(FETCH_USERTYPE_LIBRARIES, fetchUsertypeLibrariesSaga),
  takeLatest(FETCH_USERTYPE_LIBRARY_BY_ID, fetchUsertypeLibraryByIdSaga),
  takeLatest(
    FETCH_USERTYPE_LIBRARY_UPSELLS_AND_TEMPLATES,
    fetchUsertypeLibraryUpsellsAndTemplates
  ),
  takeLatest(SAVE_TEMPLATE, saveTemplateSaga),
  takeLatest(SAVE_UPSELL, saveUpsellSaga),
  takeLatest(SAVE_USERTYPE_LIBRARY, saveUsertypeLibrarySaga),
  takeLatest(FETCH_ACTIVE_HUBS, fetchActiveHubsSaga),
  takeLatest(DELETE_TEMPLATE, deleteTemplateSaga),
  takeLatest(DELETE_UPSELL, deleteUpsellSaga),
  takeLatest(FETCH_HUBS, fetchHubsDataSaga),
  takeLatest(OPEN_HUB, openHubSaga),
  takeLatest(
    FETCH_LIBRARIES_TEMPLATES_AND_UPSELLS,
    fetchLibrariesAndTemplatesAndUpsellsSaga
  ),
];

export function* fetchHubsDataSaga() {
  try {
    const searchText = yield select(selectHubsSearchText);
    const libraries = yield select(selectSelectedLibraries);
    const usertypeTemplates = yield select(selectSelectedUsertypeTemplates);

    let page = yield select(selectHubsDataPage);
    if (!page) page = 1;

    const bodyParams = {
      page,
    };

    if (searchText) {
      bodyParams.search = searchText;
    }
    if (libraries && libraries.length > 0) {
      const librariesArr = Array.from(libraries);
      const nullIndex = findIndex(librariesArr, el => el === 'null');
      if (nullIndex > -1) {
        librariesArr.splice(nullIndex, 1, null);
      }
      bodyParams.libraries = librariesArr;
    }
    if (usertypeTemplates && usertypeTemplates.length > 0) {
      bodyParams.usertypeTemplates = usertypeTemplates;
    }

    yield put(setHubsDataLoading(true));
    const { data: response } = yield call(fetchHubsAPI, bodyParams);
    yield put(setHubsData(response.data));
    yield put(setHubsDataCount(response.count));
    yield put(setHubsDataLoading(false));
  } catch (e) {
    console.log('Error in fetchHubsDataSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Error fetching hubs data. Please try again.',
      },
    });
  }
}

export function* openHubSaga({ data }) {
  try {
    const res = yield call(openHubAPI, {
      ...data,
      page: '/site-admin/advanced-settings/user-types',
    });
    if (res.data.link) {
      window.open(`https://${res.data.link}`, '_blank');
    } else {
      yield put({
        type: POPUP_ALERT,
        data: { type: 'error', message: 'Error Logging in to hub' },
      });
    }
  } catch (e) {
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error Logging in to hub' },
    });
  }
}

export function* fetchLibrariesAndTemplatesAndUpsellsSaga() {
  try {
    yield put(setFiltersLoading(true));
    const { data } = yield call(fetchLibrariesAndTemplatesAndUpsellsAPI);
    if (data) {
      yield put(setLibraries(data.libraries));
      yield put(setUsertypeLibraryTemplates(data.usertypeTemplates));
      yield put(setUsertypeLibraryUpsells(data.upsells));
    }
    yield put(setFiltersLoading(false));
  } catch (e) {
    console.log('Error in fetchLibrariesAndTemplatesAndUpsellsSaga --- ', e);
    yield put({
      type: POPUP_ALERT,
      data: {
        type: 'error',
        message: 'Could not load filters. Please try again later.',
      },
    });
    yield put(setFiltersLoading(true));
  }
}
