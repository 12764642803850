import React from 'react';
import { Modal, Input, DatePicker, Switch, Row, Col, AutoComplete, Button, Table, Tooltip, Icon } from 'antd';
import moment from 'moment';
import { fetchAdminTags } from '../../actions';
const { Option } = AutoComplete;
const { confirm } = Modal;

export default class EditExperiment extends React.Component {
	constructor(props) {
		super(props);
		fetchAdminTags();
		this.state = {
			experiment: props.experiment,
			visible: props.visible,
			selectedHubAdminTag: null,
			autoSearchValue: ''
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if(JSON.stringify(nextProps.experiment) !== JSON.stringify(prevState.experiment)) {
			return { experiment: nextProps.experiment };
		}
		return null;
	}

	_onChange = p => e => {
		let { experiment } = this.state;
		if (p==='startDate' || p=== 'endDate') {
			experiment[p] = e;
		} else if( p === 'settings.availableUserTypes') {
			experiment.settings.availableUserTypes = e;
		}	else {
			experiment[p] = e.target.value;
		}
		this.setState({experiment});
	}

	_deleteExperimentHub = experiment => {
		const { deleteExperimentHub } = this.props;
		deleteExperimentHub(experiment);
	}
	
	userTypeOptions = [
		{
			label: 'Student', value: 'student'
		},
		{
			label: 'Alumni', value: 'alumni',
		}, 
		{
			label: 'Advisor', value: 'advisor',
		}
	];

	_handleSubmit = () => {
		const { updateExperiment, setEditMode } = this.props;
		const { experiment } = this.state;
		updateExperiment(experiment);
		setEditMode(false);
	}

	_handleCancel = () => {
		const { setEditMode } = this.props;
		setEditMode(false)
	}

	_toggleIsActive = ({hubDetails, hubExperimentStatus}) => {
		const { setExperimentHubMapping } = this.props;
		const experimentHubMappingObject = {
						experimentId: hubDetails.experimentId,
						hubId: hubDetails.hubId,
						adminTagId: hubDetails.adminTagId,
						isActive: hubExperimentStatus
					};
		setExperimentHubMapping(experimentHubMappingObject);
	}

	_updateVariant = (key, v, e) => {
		let { experiment } = this.state;
		let newValue = e.target.value;
		if ( key === 'weight' ) {
			newValue = parseInt(newValue);
		}
		experiment.settings.variants = 
			experiment.settings.variants.map(variant => variant.id === v.id ? 
						({...variant, [key]: newValue})
						: variant
					);
		this.setState({ experiment });
	}

	_renderVariants = ({variants}) => 
	(
		Array.isArray(variants) ? 
			(
				<div key={`variants`}>
				{
					variants && variants.map(v => (
						<Row className="variants-table" key={`variant_${v.variantName}`}>
							<Col span={20}>
								<Input placeholder="Variant Name" defaultValue={v.variantName} disabled onChange={(e) => this._updateVariant('variantName', v, e)} />
							</Col>
							<Col span={4}>
								<Input placeholder="Weight" defaultValue={v.weight} onChange={(e) => this._updateVariant('weight', v, e)} />
							</Col>
						</Row>
					))
				}
				</div> 
			)
			:
			null
	);


	_addExperimentHub = () => {
		const { addExperimentHub } = this.props;
		const { selectedHubAdminTag, autoSearchValue } = this.state;
		if (selectedHubAdminTag && autoSearchValue){
			addExperimentHub(selectedHubAdminTag);
			this.setState({ autoSearchValue: '' });
		}
	}

	_handleSelect = e => {
		try {
			const { id: experimentId } = this.state.experiment;
			const selectedHubAdminTag = {};
			let isAdminTag = e[0]==='*';
			let splitData = e.split(":");
			let id = parseInt(splitData[0].match(/\d+/g)[0]);
			selectedHubAdminTag.experimentId = experimentId;
			selectedHubAdminTag.isActive = true;
			if (isAdminTag) {
				selectedHubAdminTag.adminTagId = id;
			} else {
				selectedHubAdminTag.hubId = id;
			}
			this.setState({ selectedHubAdminTag });
		} catch (e) {
			console.log("Error::: ", e);
		}
	}

	columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			width: 250,
			key: 'id',
			render: (k, v) => v.adminTagId || v.hubId
		},
		{
			title: 'Hub Name',
			dataIndex: 'hubDetails.name',
			width: 600,
			key: 'hubDetails.name',
		},
		{
			title: 'Admin Tag Name',
			dataIndex: 'adminTagDetails.name',
			width: 600,
			key: 'adminTagDetails.name',
		},
		{
			title: 'Listed Hubs',
			dataIndex: 'adminTagDetails.adminTagReferenceDetails',
			width: 600,
			key: 'adminTagDetails.adminTagReferenceDetails',
			render: k => k && Array.isArray(k) && k.map(h => `[${h.id}: ${h.name}]`).join(', ')
		},
		{
			title: <Tooltip title="Temporarily disable this hub/group from this experiment">Is Active <Icon type="info-circle" /></Tooltip>,
			dataIndex: 'isActive',
			width: 600,
			key: 'isActive',
			render: (k, v) => {
				return (
					<div>
						<Tooltip title="Temporarily disable this hub/group from this experiment">
							<Switch
								onChange={(hubExperimentStatus) => this._toggleIsActive({hubDetails: v, hubExperimentStatus})}
								defaultChecked = {k} 
							/>
						</Tooltip>
					</div>
				)

			}
		},
		{
			title: 'Delete',
			dataIndex: 'adminTagDetails.deletedAt',
			width: 50,
			key: 'adminTagDetails.deletedAt',
			render: (k, v) => 
				<Tooltip title="Delete this hub/group from this experiment">
					<Button type="danger" icon="delete" onClick = {
						() => {
							confirm({
								title: 'Are you sure you want to delete this hub/group for this experiment?',
								content: 'This will opt-out the hub/group for the given experiment.',
								onOk: () => {
									this._deleteExperimentHub(v)
								},
								onCancel() {},
							});
					}}/>
				</Tooltip>
		}
	];

	_handleAutoSearchValue = e => {
		this.setState({ autoSearchValue: e });
	}

	_renderHubsGroups = hubMappings => {
		const { adminTag } = this.props;
		hubMappings = Array.isArray(hubMappings) ? hubMappings : [];
		let hubAdminTagData = Array.isArray(adminTag.adminTags) ? adminTag.adminTags.map(at => ({id: at.id, name: at.name, isAdminTag: true})): [];
		let hubData = Array.isArray(adminTag.clusterHubs) ? adminTag.clusterHubs.map(h => ({id: h.id, name: h.name, isAdminTag: false})) : [];
		hubAdminTagData = hubAdminTagData.concat(hubData);
		// Check if hubMappings[0]adminTagDetails.adminTagReferenceDetails contains a given hub and disable it in dropdown.
		hubAdminTagData = hubAdminTagData.map(e => {
			if(Array.isArray(hubMappings) && hubMappings.find(hm => ((hm.hubId === e.id && !e.isAdminTag) || (hm.adminTagId === e.id && e.isAdminTag)))) {
				e.alreadyPresent = true;
			}
			return e;
		});
		const hubAdminTagDataSource = hubAdminTagData.map(e => <Option key={`hub_option_${e.id}`} disabled={e.alreadyPresent} value={(e.isAdminTag ? `* ${e.id}: ${e.name}`: `${e.id}: ${e.name}`)}>
			{(e.isAdminTag ? `* ${e.id}: ${e.name}`: `${e.id}: ${e.name}`)}
		</Option>);
			return (			
				<div>
					<Row>
						<Col span={23}>
							<AutoComplete
								onSelect={this._handleSelect}
								style={{ width: '100%' }}
								placeholder={`Search admin tags or hubs here`}
								filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
								value={this.state.autoSearchValue}
								onChange={this._handleAutoSearchValue}
								dataSource={hubAdminTagDataSource}
							>
								{hubAdminTagDataSource}
							</AutoComplete>
						</Col>
						<Col span={1}>
							<Button icon="plus" type="primary" shape="circle" onClick={this._addExperimentHub}></Button>
						</Col>
					</Row>
					<Table rowKey={hubMapping => `hubMapping_${hubMapping.id}`} dataSource={hubMappings} columns={this.columns} pagination={{pageSize: 5}} />
				</div>
			)
	}

	render() {
		const { visible, experiment } = this.state;
		return (
			<Modal
				title="Edit Experiment"
				visible={visible}
				onOk={this._handleSubmit}
				onCancel={this._handleCancel}
				width={1000}
			>
				<div className="editor-modal">
				<Row className="edit-field">
					<Col span={6}> Name: </Col>
					<Col span={18}><Input defaultValue={experiment.name} disabled onChange={this._onChange('name')}/></Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> Description: </Col>
					<Col span={18}> <Input defaultValue={experiment.description} onChange={this._onChange('description')}/> </Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> Variants: </Col>
					<Col span={18}> 
						{this._renderVariants(experiment.settings)}
					</Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> 
						<p>Hubs & Groups: </p>
						<p><i> Entries in dropdown starting with <strong>*</strong> denote groups/admin-tags. </i></p>
						<p><i> Hubs added separately will be given assignment priority as compared to hubs which are a part of an admin-tag </i></p>
					</Col>
					<Col span={18}> 
						{this._renderHubsGroups(experiment.hubMappings)}
					</Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> Start Date: </Col>
					<Col span={18}>  
						<DatePicker 
							defaultValue={moment(experiment.startDate)}
							onChange={this._onChange('startDate')}
						/>
					</Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> End Date: </Col>
					<Col span={18}> <DatePicker 
							defaultValue={moment(experiment.endDate)}
							onChange={this._onChange('endDate')}
						/>
					</Col>
				</Row>
				</div>
			</Modal>
		)
	}
}