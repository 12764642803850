import React, { useRef, useState } from 'react';
import { PageHeader, Card, Row, Menu } from 'antd';
import { map } from 'lodash';
import './index.scss';
import AchievementsTab from './components/AchievementsTab';
import ActionsTab from './components/ActionsTab';

const Compass = () => {
  const topActionWrapperRef = useRef();

  const [activeTab, setActiveTab] = useState('achievements'); // table to show

  const TABS = React.useMemo(
    () => ({
      achievements: {
        key: 'achievements',
        label: 'Achievements',
        content: <AchievementsTab />,
        disabled: false,
      },
      actions: {
        key: 'actions',
        label: 'Actions',
        content: <ActionsTab />,
      },
    }),
    []
  );
  return (
    <div
      className="compass"
      ref={el => {
        topActionWrapperRef.current = el;
      }}
    >
      <PageHeader
        title={<p className="header__title">Compass</p>}
        style={{ backgroundColor: '#ffffff' }}
      />
      <div className="main-container">
        <Row gutter={16}>
          <>
            <Row>
              <Card className="warning__card">
                <div className="warning__description">
                  <p className="warning__text">
                    <span className="warning__text-bold">Warning: </span>{' '}
                    {`Changes
                  you will make on this screen are global and will effect content for all associated hubs.`}
                  </p>
                </div>
              </Card>
            </Row>
            <Row>
              <Menu
                onClick={({ key }) => setActiveTab(key)}
                selectedKeys={[activeTab]}
                mode="horizontal"
              >
                {map(TABS, (item, key) => (
                  <Menu.Item key={key} disabled={item.disabled}>
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>

              <div>{TABS[activeTab]?.content}</div>
            </Row>
          </>
        </Row>
      </div>
    </div>
  );
};
export default Compass;
