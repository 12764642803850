import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Form, Select, Spin, Icon } from 'antd';
import { isEmpty, find, get } from 'lodash';

import { setData, getHubsPathways } from '../actions';

import '../index.scss';

const { Option } = Select;

class PathwaySelectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalData: {
        hubId: undefined,
        pathwayId: undefined,
      },
      showHubFilterConfirmModal: false,
    };
  }

  componentDidMount() {
    const {
      hubsPathways,
      getHubsPathways,
      pathwayData,
      singleTemplate = {},
    } = this.props;
    if (isEmpty(hubsPathways)) getHubsPathways();
    if (!isEmpty(pathwayData) && !singleTemplate.id) {
      const { hubId, pathwayId } = pathwayData;
      this.setState({ modalData: { hubId, pathwayId } });
    } else {
      this.setState({ modalData: { hubId: undefined, pathwayId: undefined } });
    }
  }

  componentDidUpdate(prevProps) {
    const { pathwayData = {}, singleTemplate = {} } = this.props;
    const { pathwayData: data } = prevProps;
    if (get(pathwayData, 'pathwayId') !== get(data, 'pathwayId')) {
      if (!isEmpty(pathwayData.pathwayId) && !singleTemplate.id) {
        const { hubId, pathwayId } = pathwayData;
        this.setState({ modalData: { hubId, pathwayId } });
      } else {
        this.setState({
          modalData: { hubId: undefined, pathwayId: undefined },
        });
      }
    }
  }

  toggleModal = (submitCalled = false) => {
    const { setData, hubsPathways, history } = this.props;
    const { modalData } = this.state;
    if (submitCalled) {
      const [singleTemplate = {}] = (hubsPathways &&
        find(hubsPathways, { id: modalData.hubId }).pathways.filter(
          p => p.id === modalData.pathwayId
        )) || [undefined];
      const { id, hubSpecificFilter, ...templateData } = singleTemplate;

      // Show Hub Filter Confirm Modal if hubSpecificFilter is true
      if (hubSpecificFilter) this.setState({ showHubFilterConfirmModal: true });

      setData({
        pathwayData: { ...modalData },
        showPathwayForm: true,
        showPathwayCopyModal: false,
        singleTemplate: templateData,
        preSelectedHubs: [],
      });
      history.push('/pathway-provisioning/create');
      return;
    }
    setData({
      showPathwayCopyModal: false,
    });
  };

  confirmModal = () => this.setState({ showHubFilterConfirmModal: false });

  handleModalSelect = (type, val) => {
    const { modalData } = this.state;
    this.setState({ modalData: { ...modalData, [type]: val } });
  };

  render() {
    const { loading, hubsPathways, showPathwayCopyModal } = this.props;
    const { modalData, showHubFilterConfirmModal } = this.state;

    return (
      <>
        <Modal
          visible={showPathwayCopyModal}
          className="hubs_pathways_modal"
          title="Select Source Pathway"
          destroyOnClose
          onCancel={() => this.toggleModal()}
          footer={[
            <Button key="cancel" onClick={() => this.toggleModal()}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!modalData.hubId || !modalData.pathwayId}
              onClick={() => this.toggleModal(true)}
            >
              Copy
            </Button>,
          ]}
        >
          {get(loading, 'pathway_list', false) ? (
            <div style={{ textAlign: 'center' }}>
              <Spin
                tip="Fetching hubs and pathways"
                size="small"
                indicator={<Icon type="loading" spin />}
              />
            </div>
          ) : (
            <>
              <Form>
                <Form.Item name="hub" label="Select Hub" colon={false}>
                  <Select
                    placeholder="Select Hub"
                    onChange={event => this.handleModalSelect('hubId', event)}
                    defaultValue={modalData && modalData.hubId}
                  >
                    {hubsPathways &&
                      hubsPathways.map(hp => (
                        <Option value={hp.id}>
                          {hp.id} - {hp.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="pathway" label="Select Pathway" colon={false}>
                  <Select
                    placeholder="Select Pathway"
                    disabled={!modalData.hubId}
                    onChange={event =>
                      this.handleModalSelect('pathwayId', event)
                    }
                    defaultValue={modalData && modalData.pathwayId}
                  >
                    {hubsPathways &&
                      modalData.hubId &&
                      find(hubsPathways, {
                        id: modalData.hubId,
                      }).pathways.map(p => (
                        <Option value={p.id}>
                          {p.id} -{' '}
                          {(p.title || '').replace(
                            /{{pathwayHubText}}/g,
                            'Pathway'
                          )}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
              <p className="alert-text">
                <i>Only pathways with filters will be visible</i>
              </p>
            </>
          )}
        </Modal>
        <Modal
          visible={showHubFilterConfirmModal}
          className="hubs_pathways_modal"
          title="Warning! Hub Specific Filters Present!"
          destroyOnClose
          closable={false}
          footer={[
            <Button
              className="confirm-button"
              key="submit"
              type="primary"
              onClick={this.confirmModal}
            >
              I Understand
            </Button>,
          ]}
        >
          <p>
            The selected source pathway includes assignment filters that are
            hub-specific and won’t translate to the default pathway. To use
            these filters for the default pathway, please add them in the
            &quot;Hub Specific Filters&quot; section.
          </p>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  PathwaysProvisioning: {
    hubsPathways,
    showPathwayCopyModal,
    loading,
    pathwayData,
    singleTemplate,
  },
}) => ({
  hubsPathways,
  showPathwayCopyModal,
  loading,
  pathwayData,
  singleTemplate,
});

const mapDispatchToProps = {
  setData,
  getHubsPathways,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PathwaySelectModal)
);
