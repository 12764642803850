import {takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import {GET_SEEDS, SET_SEEDS, CREATE_SEED, DELETE_SEED, MAKE_DEFAULT, DOWNLOAD_SEED } from './actions';
import {request} from '../../utils/request';
import {message} from 'antd';
import * as api from '../../api';
import downloadFile from '../../utils/downloadFile';

export default [
  takeLatest(GET_SEEDS, fetchSeedsSaga),
  takeLatest(CREATE_SEED, createSeedSaga),
  takeLatest(DELETE_SEED, deleteSeedSaga),
  takeLatest(MAKE_DEFAULT, makeSeedDefaultSaga),
  takeEvery(DOWNLOAD_SEED, downloadSeed)
];

const selects = {
  pagination: state => state.seeds.pagination,
  seeds: state => state.seeds.seeds
}

const formatSeed = seed => {
  return {
    id: seed.id,
    key: seed.id,
    userId: seed.user_id,
    username: `${seed.first_name} ${seed.last_name}`,
    title: seed.title,
    size: seed.size,
    awsKey: seed.aws_key,
    createdAt: seed.created_at,
    isDefault: seed.is_default,
  }

}

function *fetchSeedsSaga({options}) {
  yield put({ type: SET_SEEDS, data: { seedsLoading: true }});
  try {
    let res = yield api.getSeeds(options);
    let {total, seeds, defaultSeed} = res.data;
    total = parseInt(total)
    seeds = seeds.map(s => formatSeed(s));
    defaultSeed = defaultSeed && formatSeed(defaultSeed);
    let pagination = yield select(selects.pagination);
    pagination = { ...pagination, total }
    yield put({ type: SET_SEEDS, data: { seedsLoading: false, seeds, defaultSeed, pagination }});
  } catch(e) {
    message.error('There was an error fetching the available seeds.')
    yield put({ type: SET_SEEDS, data: { seedsLoading: false }});
  }
}

function *createSeedSaga({ createOptions, getOptions }) {
  try {
    yield api.createSeed(createOptions);
    yield call(fetchSeedsSaga, { options: getOptions });
  } catch(e) { message.error('There was an error creating a new Seed.') }
}

function *deleteSeedSaga({ id }) {
  try {
    yield put({ type: SET_SEEDS, data: { seedsLoading: true }});
    yield call(api.updateSeed, { id, action: 'delete' });
    const { current, total, pageSize } = yield select(selects.pagination);
    let page = current;
    if (total % pageSize === 1 && current === Math.ceil(total /pageSize)) 
      page--;
    yield call(fetchSeedsSaga, {options: { page }});
  } catch(e) {
    yield put({ type: SET_SEEDS, data: { seedsLoading: false }});
    message.error("An error occured while deleting the seed");
  }
}

function *makeSeedDefaultSaga({ id }) {
  try {
    yield put({ type: SET_SEEDS, data: { seedsLoading: true }});
    yield call(api.updateSeed, { id, action: 'makeDefault' });
    let seeds = yield select(selects.seeds);
    seeds = seeds.map(s => ({ ...s, isDefault: false}));
    const ind = seeds.findIndex(s => s.id === id);
    seeds = Object.assign([], seeds, {
      [ind]: {...seeds[ind], isDefault: true}
    });
    yield put({ type: SET_SEEDS, data: { seedsLoading: false, seeds, defaultSeed: seeds[ind] }});
  } catch(e) {
    message.error("An error occured while setting the seed as default")
  }
}

function *downloadSeed({ id }) {
    try {
      let data = {}
      try {
        ({ data } = yield call(api.getDownloadLink, { image_id: id }));
      } catch (e) {
        throw 'Error Fetching Download Url';
      }
      try {
        downloadFile(data.url)
      } catch(e) {
        console.log(' Faild to download Url:', data.url);
        throw 'Error Dowloading Url, You can find the Download URL in the developer console.';
      }
    } catch(e) {
      if(typeof error === 'string') message.error(e)
      else console.log(e)
      message.error('Somemthing went wrong downloading the file.');
    }
}
