import React, { useState } from 'react';
import { Divider, PageHeader, Select } from 'antd';
import Content from '../../component/Content';
import classes from './AskAnythingMetrics.module.scss';
import AskAnythingMetricsContent from './AskAnythingMetricsContent';
import { dataTableOptions } from './constants';

const { Option } = Select;

const AskAnythingMetrics = () => {
  const [currentPage, setCurrentPage] = useState(dataTableOptions[0].value);
  return (
    <div className={classes.aam_page}>
      <PageHeader
        title="Ask Anything Metrics"
        extra={
          <Select
            className={classes.page_select}
            value={currentPage}
            onChange={selectedValue => setCurrentPage(selectedValue)}
          >
            {dataTableOptions.map(option => (
              <Option value={option.value}>{option.name}</Option>
            ))}
          </Select>
        }
        className={classes.aam_page__header}
      />
      <Content>
        <Divider />
        <AskAnythingMetricsContent currentPage={currentPage} />
      </Content>
    </div>
  );
};

export default AskAnythingMetrics;
