export const FETCH_SCHOOLS = '/SchoolManagement/FETCH_SCHOOLS';
export const FETCH_SCHOOLS_SUCCESS = '/SchoolManagement/FETCH_SCHOOLS_SUCCESS';
export const FILTER_SCHOOLS = '/SchoolManagement/FILTER_SCHOOLS';
export const FILTER_SCHOOLS_SUCCESS =
  '/SchoolManagement/FILTER_SCHOOLS_SUCCESS';
export const SET_FILTERS_ENABLED = '/SchoolManagement/SET_FILTERS_ENABLED';
export const CLEAR_FILTERS = '/SchoolManagement/CLEAR_FILTERS';
export const IS_LOADING = '/SchoolManagement/IS_LOADING';

export const HANDLE_PAGE_CHANGE = '/SchoolManagement/HANDLE_PAGE_CHANGE';
export const SEARCH_TEXT_CHANGE = '/SchoolManagement/SEARCH_TEXT_CHANGE';
export const TOGGLE_ONLY_VERIFIED = '/SchoolManagement/TOGGLE_ONLY_VERIFIED';
export const TOGGLE_EXACT_MATCH = '/SchoolManagement/TOGGLE_EXACT_MATCH';
export const TOGGLE_CASE_INSENSITIVE =
  '/SchoolManagement/TOGGLE_CASE_INSENSITIVE';
export const UPDATE_EDITING_KEY = '/SchoolManagement/UPDATE_EDITING_KEY';

export const DEGREE_TYPES_MODAL_OPEN =
  '/SchoolManagement/DEGREE_TYPES_MODAL_OPEN';
export const DEGREE_TYPES_LOADING = '/SchoolManagement/DEGREE_TYPES_LOADING';
export const FETCH_DEGREE_TYPES = '/SchoolManagement/FETCH_DEGREE_TYPES';
export const FETCH_DEGREE_TYPES_SUCCESS =
  '/SchoolManagement/FETCH_DEGREE_TYPES_SUCCESS';
export const UPDATE_DEGREE_TYPES = '/SchoolManagement/UPDATE_DEGREE_TYPES';
export const DEGREE_TYPES_UPDATING = '/SchoolManagement/DEGREE_TYPES_UPDATING';

export const UPDATE_SCHOOL = '/SchoolManagement/UPDATE_SCHOOL';
export const SET_SCHOOLS = '/SchoolManagement/SET_SCHOOLS';

export const RESET_STATE = '/SchoolManagement/RESET_STATE';

export const fetchSchools = () => {
  return {
    type: FETCH_SCHOOLS,
  };
};

export const fetchSchoolsSuccess = data => {
  return {
    type: FETCH_SCHOOLS_SUCCESS,
    data,
  };
};

export const filterSchools = data => {
  return {
    type: FILTER_SCHOOLS,
    data,
  };
};

export const filterSchoolsSuccess = data => {
  return {
    type: FILTER_SCHOOLS_SUCCESS,
    data,
  };
};

export const setFiltersEnabled = data => {
  return {
    type: SET_FILTERS_ENABLED,
    data,
  };
};

export const clearFilters = () => {
  return {
    type: CLEAR_FILTERS,
  };
};

export const isLoading = data => {
  return {
    type: IS_LOADING,
    data,
  };
};

export const handlePageChange = data => {
  return {
    type: HANDLE_PAGE_CHANGE,
    data,
  };
};

export const searchTextChange = data => {
  return {
    type: SEARCH_TEXT_CHANGE,
    data,
  };
};

export const toggleOnlyVerified = () => {
  return {
    type: TOGGLE_ONLY_VERIFIED,
  };
};

export const toggleExactMatch = () => {
  return {
    type: TOGGLE_EXACT_MATCH,
  };
};

export const toggleCaseInsensitive = () => {
  return {
    type: TOGGLE_CASE_INSENSITIVE,
  };
};

export const updateEditingKey = data => {
  return {
    type: UPDATE_EDITING_KEY,
    data,
  };
};

export const updateSchool = data => {
  return {
    type: UPDATE_SCHOOL,
    data,
  };
};

export const setSchools = data => {
  return {
    type: SET_SCHOOLS,
    data,
  };
};

export const degreeTypesModalOpen = data => {
  return {
    type: DEGREE_TYPES_MODAL_OPEN,
    data,
  };
};

export const degreeTypesLoading = data => {
  return {
    type: DEGREE_TYPES_LOADING,
    data,
  };
};

export const fetchDegreeTypes = data => {
  return {
    type: FETCH_DEGREE_TYPES,
    data,
  };
};

export const fetchDegreeTypesSuccess = data => {
  return {
    type: FETCH_DEGREE_TYPES_SUCCESS,
    data,
  };
};

export const updateDegreeTypes = data => {
  return {
    type: UPDATE_DEGREE_TYPES,
    data,
  };
};

export const degreeTypesUpdating = data => {
  return {
    type: DEGREE_TYPES_UPDATING,
    data,
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

// Selectors
export const pageSelector = ({ schools: { current } }) => current;
export const filtersEnabledSelector = ({ schools: { filtersEnabled } }) =>
  filtersEnabled;
export const searchTextSelector = ({ schools: { searchText } }) => searchText;
export const onlyVerifiedSelector = ({ schools: { onlyVerified } }) =>
  onlyVerified;
export const schoolsSelector = ({ schools: { data } }) => data;
export const degreeTypesSelector = ({ schools: { degreeTypes } }) =>
  degreeTypes;
export const totalSelector = ({ schools: { total } }) => total;
export const pageSizeSelector = ({ schools: { pageSize } }) => pageSize;
