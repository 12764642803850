export const validateHeaders = (headers = []) => {
  let isValid = true;
  let message = '';
  headers.forEach(header => {
    if (!header.column) {
      message = 'Please select column for all the headers.';
      isValid = false;
      return;
    }
    if (!header.title) {
      message = 'Header title is require for all the headers.';
      isValid = false;
      return;
    }
  });
  return { isValid, message };
};

export const validateFilters = (filters = []) => {
  let isValid = true;
  let message = '';
  filters.forEach(filter => {
    if (!filter.value) {
      message = 'Please select column for all the filters.';
      isValid = false;
      return;
    }
    if (!filter.name) {
      message = 'Filter name is require for all the filters.';
      isValid = false;
      return;
    }
    if (!filter.desc) {
      message = 'Filter description is require for all the filters.';
      isValid = false;
      return;
    }
    if (!filter.type) {
      message = 'Filter type is require for all the filters.';
      isValid = false;
      return;
    }
    if (filter.type === 'list' && !filter.options) {
      message = 'Filter options is require for all list type filters.';
      isValid = false;
      return;
    }
  });
  return { isValid, message };
};

export const validateEditableColumns = (editableColumns = [], database) => {
  let isValid = true;
  let message = '';
  let warning = '';
  if (editableColumns.length) {
    editableColumns.forEach(ec => {
      if (!ec || !ec.column || !ec.mainDbTable || !ec.mainDbColumn || !ec.mainDbIdValue) {
        isValid = false;
        message = `Please fill table/column/id field of main database editable column section.`
      } else if (database === 'analytics' && ec && ec.analyticsDbTable && ec.analyticsDbColumn) {
        if (!ec.analyticsDbWheres || !ec.analyticsDbWheres.length) {
          isValid = false;
          message = 'Please add at least one condition for analytics editable column section'
        } else if (ec.analyticsDbWheres.length) {
          ec.analyticsDbWheres.forEach(adw => {
            if (!adw || !adw.tableCol || !adw.valueCol) {
              isValid = false;
              message = 'Please fill match column and column value for analytics editable column section'
            }
          })
        }
      } else if (database === 'analytics' && (!ec || !ec.analyticsDbTable || !ec.analyticsDbColumn)) {
        warning = `One of the editable column hasn't defined how to update the data in analytics database.`
      }
    })
  }
  return { isValid, message, warning };
};
