import React, { Component } from 'react';
import { Button, Card, Table, Switch, Modal,Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';

import Content from '../../../component/Content';
import {
  handleFetchAllCampaigns,
  handleIsActiveToggle,
  handleCampaignDelete,
} from '../actions';
import { Link } from 'react-router-dom';




export const ConfirmDeleteModal = ({ path, onCancel, onOk, description }) => {
  return (
    <Modal
      title={'Are you sure?'}
      className='campaigns_delete_modal'
      closable={true}
      visible={!!path}
      cancelText='Cancel'
      onCancel={onCancel}
      destroyOnClose={true}
      okText='Delete'
      onOk={() => onOk && onOk(path)}
    >
      <p>
       {description}
      </p>
    </Modal>
  );
};


class CampaignsList extends Component {
  state = {
    deleteCampaignInfo: null
  }

  componentDidMount() {
    const { handleFetchAllCampaigns } = this.props;
    handleFetchAllCampaigns();
  }

  handleClickIsActiveToggle = campaign => {
    const { handleIsActiveToggle } = this.props;
    handleIsActiveToggle({ id: campaign.id, isActive: !campaign.isActive });
  };

  handleEditCampaign = () => {};

  handleConfirmDeleteCampaign = value => {
    const { handleCampaignDelete } = this.props;
    const { id } = value;
    handleCampaignDelete(id);
    this.setState({deleteCampaignInfo: null});
  };

  render() {
    const { allCampaigns, allCampaignsLoading } = this.props;
    const { deleteCampaignInfo } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        className: 'cm__table__name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: '# of Emails',
        className: 'cm__table__noOfEmails',
        dataIndex: 'noOfEmails',
      },
      {
        title: 'Is Active',
        className: '',
        render: (value, record) => {
          return (
            <Switch
              onClick={() => {
                this.handleClickIsActiveToggle(value);
              }}
              checked={get(value, 'isActive', false)}
              loading={get(value, 'loading', false)}
            />
          );
        },
      },
      {
        title: 'Actions',
        className: 'cm__table__actions',
        render: (value, record) => (
          <React.Fragment>
            <Link
              className="cm__table__actions_edit_link"
              to={`/campaigns/${value.id}`}
            >
              Edit
            </Link>
            <Button
              size="small"
              className="cm__table__actions_delete"
              type="link"
              onClick={() => this.setState({deleteCampaignInfo: value})}
           >
            Delete
          </Button>
          </React.Fragment>
        ),
      },
    ];

    return (
      <React.Fragment>
      <Content>
        <Table
          columns={columns}
          dataSource={allCampaigns || []}
          rowKey="id"
          loading={allCampaignsLoading}
          className="cm__table"
          pagination={allCampaigns &&  allCampaigns.length > 10}
        />
      </Content>
      <ConfirmDeleteModal
        path={deleteCampaignInfo}
        onCancel= {() => this.setState({deleteCampaignInfo: null})}
        onOk= {this.handleConfirmDeleteCampaign}
        description={
          <div>
          Once you delete this campaign, it will be gone forever. Consider disabling this campaign instead.
          <br/>
          <br/>
          Note that deleting this campaign will not delete it from any hubs that have already enabled it.
        </div>
       }
      />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  automatedMarketing: { campaign: {allCampaignsLoading, allCampaigns} },
}) => ({
  allCampaignsLoading,
  allCampaigns,
});

const mapDispatchToProps = {
  handleFetchAllCampaigns,
  handleIsActiveToggle,
  handleCampaignDelete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignsList);
