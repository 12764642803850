import React from 'react';
import { Button, Tooltip } from 'antd';
import { get, parseInt, isEmpty } from 'lodash';

const CampaignBottomBar = ({
  onBackClick,
  onAddToCampaignEmail,
  onEditCampaignEmail,
  disabled = false,
  campaignEmailId,
}) => (
  <div className="am__email-editor__bottom-bar">
    <Button onClick={onBackClick} disabled={disabled}>
      Cancel
    </Button>
    {campaignEmailId ? (
      <Button type="primary" onClick={onEditCampaignEmail} disabled={disabled}>
        Update Campaign Email
      </Button>
    ) :(
      <Button type="primary" onClick={onAddToCampaignEmail} disabled={disabled}>
          Add to Campaign
      </Button> 
    )}
  </div>
);

export default CampaignBottomBar;
