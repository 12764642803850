import React from 'react';
import { Button } from 'antd';

const columns = (handleUpdateConfiguration) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'hubName',
    key: 'hubName',
    render: (text, record) => (
      <React.Fragment>
        <p className={'table-text'}>{record.hubName}</p>
        <span className={'table-identifier-text'}>{record.identifier}</span>
      </React.Fragment>
    ),
  },
  {
    title: 'Cluster',
    dataIndex: 'clusterName',
    key: 'clusterName',
  },
  {
    title: 'Active Groups',
    dataIndex: 'activeGroups',
    key: 'activeGroups',
  },
  {
    title: 'Active Programs',
    dataIndex: 'activePrograms',
    key: 'activePrograms',
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <Button
        type="link"
        onClick={handleUpdateConfiguration(record)}
      >
        Update Configuration
      </Button>
    ),
  },
];

export default columns;