import { useEffect, useState, useRef } from 'react';

const useDebouncedValue = (value, delay, setIsDebouncing) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const firstDebounce = useRef(true);

  useEffect(() => {
    if (value && firstDebounce.current) {
      setDebouncedValue(value);
      firstDebounce.current = false;
      return;
    }

    setIsDebouncing?.(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing?.(false);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay, setIsDebouncing]);

  return debouncedValue;
};

export default useDebouncedValue;
