import React, { PureComponent } from 'react';
import variations from '../../constants/defaultSettingVariationConstants';
import { Modal, Table } from 'antd';
import moment from 'moment';

export default class ChangeLogModal extends PureComponent {
  columns = [
    {
      title: 'Changed By',
      dataIndex: ['user', 'name'],
      key: 'user',
    },
    {
      title: 'Previous Launch Group',
      dataIndex: ['oldValue', 'value'],
      key: 'oldValue',
      render: text => {
        const variation = variations.filter(obj => obj.key === text)
        return variation && variation.length ? variation[0].name : 'NA'
      }
    },
    {
      title: 'New Launch Group',
      dataIndex: ['newValue', 'value'],
      key: 'newValue',
      render: text => {
        const variation = variations.filter(obj => obj.key === text)
        return variation && variation.length ? variation[0].name : 'NA'
      }
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a")
    }
  ]

  render () {
    return (
      <Modal 
        title={`Change Log`}
        visible={this.props.openModal}
        onOk={this.props.close}
        onCancel={this.props.close}
        width={1000}
      >
        <Table 
          columns={this.columns}
          dataSource={this.props.changeLogs}
          loading={this.props.loading}
        />
      </Modal>
    )
  }
}