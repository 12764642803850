import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Table } from 'antd';

import {
  fetchActiveHubs,
  handleActionActiveHubsModalVisible,
  handleActionSetManageDataPage,
} from '../../../actions/manageActions';

class ActiveHubsModal extends PureComponent {
  componentDidMount() {
    const { templateId, fetchActiveHubs, activeHubsModalVisible } = this.props;
    if (activeHubsModalVisible) fetchActiveHubs(templateId);
  }

  getColumns = () => [
    {
      title: 'Hub Name',
      dataIndex: 'hubName',
      key: 'hubName',
      render: (value, record) => (
        <div className="active-hubs-modal__table--hub">
          <span className="active-hubs-modal__table--hub__name">{value}</span>
          <span className="active-hubs-modal__table--hub__identifier">
            {record.hubIdentifier}
          </span>
        </div>
      ),
    },
    {
      title: 'Cluster',
      dataIndex: 'clusterName',
      key: 'clusterName',
      render: value => (
        <div className="active-hubs-modal__table--cluster">
          <span className="active-hubs-modal__table--cluster__name">
            {value}
          </span>
        </div>
      ),
    },
  ];

  handlePageChange = page => {
    const { handleActionSetManageDataPage, fetchActiveHubs } = this.props;
    handleActionSetManageDataPage(page);
    fetchActiveHubs();
  };

  handleClose = () => {
    const {
      setActiveTemplateId,
      handleActionActiveHubsModalVisible,
    } = this.props;
    setActiveTemplateId(null);
    handleActionActiveHubsModalVisible(false);
  };

  renderFooter = () => (
    <div>
      <Button onClick={this.handleClose}>Close</Button>
    </div>
  );

  render() {
    const {
      activeHubsModalVisible,
      activeHubsLoading,
      manageData,
      manageDataCount,
      manageDataPage,
      manageDataPageSize,
    } = this.props;
    return (
      <Modal
        title="Active Hubs"
        className="active-hubs-modal"
        visible={activeHubsModalVisible}
        onCancel={this.handleClose}
        footer={this.renderFooter()}
        destroyOnClose
      >
        <Table
          dataSource={manageData}
          columns={this.getColumns()}
          loading={activeHubsLoading}
          className="active-hubs-modal__table"
          pagination={{
            total: manageDataCount,
            pageSize: manageDataPageSize,
            onChange: this.handlePageChange,
            current: manageDataPage,
            hideOnSinglePage: true,
          }}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({
  usertypeManage: {
    activeHubsModalVisible,
    activeHubsLoading,
    manageData,
    manageDataCount,
    manageDataPage,
    manageDataPageSize,
  },
}) => ({
  activeHubsModalVisible,
  activeHubsLoading,
  manageData,
  manageDataCount,
  manageDataPage,
  manageDataPageSize,
});

const mapDispatchToProps = {
  fetchActiveHubs,
  handleActionActiveHubsModalVisible,
  handleActionSetManageDataPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveHubsModal);
