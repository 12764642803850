import React from 'react';
import { connect } from 'react-redux';
import { capitalize, get } from 'lodash';
import { Button, Divider, Modal, Spin } from 'antd';

import {
  clearJobStatusModalState as clearJobStatusModalStateAction,
  fetchHubs as fetchHubsAction,
  handleActionSetJobStatusModalVisible as handleActionSetJobStatusModalVisibleAction,
  stopFetchJobStatus as stopFetchJobStatusAction,
} from '../../actions';

import { getStandardEnablementGroups } from '../../../../constants/defaultSettingVariationConstants';

const standardTypeMap = {
  standard: 'V2 | PG Essentials | New',
  'transitioned-standard': 'V2 | PG Essentials | Upgraded',
  'standard-plus': 'V2 | PG + | New',
  'standard-plus-fpu': 'V2 | PG + | Upgraded',
};

const statusMap = {
  completed: 'The hub/cluster is successfully migrated to V2 Platform.',
  processing: 'The hub/cluster is being migrated to V2 Platform.',
  pending: 'The hub/cluster will soon be migrated to V2 Platform.',
  error:
    'There was an error while migrating the hub/cluster. Please try again.',
};

const migrationTypeMap = {
  migrateResources: 'Resources Migration',
  migrateGroups: 'Groups Migration',
  removeConnectionSuccessPathway: 'Remove Connection Success Pathway',
  disableHubAutoMessage: 'Disable Custom Notifications',
  enableWayUpIntegration: 'Enable WayUp Integration',
  disableHubLandingPage: `Disable Hub Landing Page`,
  replaceMessageDefaultTemplates: 'Replace Message Default Templates',
  addDefaultSurveys: 'Add default surveys',
  updateCustomFields: 'Update custom fields',
  migrateToStandardIndustry: 'Migrate to Standard Industry',
  migrateToStandardHelpTopics: 'Migrate to Standard HelpTopics',
  updateWayupJobFlexFields: 'Update Wayup jobs flex fields',
  createDefaultListAndBadges: 'Create PG Standard Lists',
  updateZendeskRole: 'Update admin role in the Zendesk',
  migrateHubBanners: 'Migrate Hub Banners',
  updateAutoGreetingSettings: 'Updated Auto Greeting Settings',
};

const ProgressModal = ({
  clearJobStatusModalState,
  fetchHubs,
  jobStatusData,
  jobStatusModalVisible,
  page,
  platformType,
  searchText,
  setJobStatusModalVisible,
  stopFetchJobStatus,
}) => {
  const handleCloseClick = () => {
    setJobStatusModalVisible(false);
    fetchHubs({ searchText, platformType, page });
  };

  const renderFooter = () => (
    <div>
      <Button onClick={handleCloseClick}>Close</Button>
    </div>
  );

  const hubName = get(jobStatusData, 'hub.name');
  const clusterId = get(jobStatusData, 'cluster.id');
  const jobStatus = get(jobStatusData, 'status', 'processing');
  const jobPlatformType = get(jobStatusData, 'platformType');
  const jobLaunchGroupKey = get(jobStatusData, 'launchGroup');
  const jobLaunchGroup = getStandardEnablementGroups().find(
    lg => lg.key === jobLaunchGroupKey
  );
  const jobLaunchGroupName = jobLaunchGroup?.name || '';
  const migrationStatusArray = get(jobStatusData, 'updatesMap.migrations', []);
  const Domain = get(jobStatusData, 'updatesMap.domain', '...') || 'NA';
  const isLoading = jobStatus === 'processing';

  const migrationStatusArrayList = migrationStatusArray.map(migrationType => {
    const keyName = Object.keys(migrationType)[0];
    const migrationName = migrationTypeMap[keyName];

    if (!migrationName) return null;

    return (
      <div
        key={keyName}
        className="standard-hub--status-modal--body__status--migration"
      >
        {migrationName}:{' '}
        {capitalize(get(migrationType, `${keyName}.status`, 'fail'))}
      </div>
    );
  });

  return (
    <Modal
      title="V2 Migration Process"
      className="standard-hub--status-modal"
      visible={jobStatusModalVisible}
      onCancel={handleCloseClick}
      footer={renderFooter()}
      maskClosable={false}
      afterClose={() => {
        stopFetchJobStatus();
        clearJobStatusModalState();
      }}
      centered
      destroyOnClose
    >
      <div className="standard-hub--status-modal--body">
        {hubName && clusterId && jobPlatformType ? (
          <>
            <div className="standard-hub--status-modal--body__title">
              Running V2 Migration for:{' '}
              <b>
                Hub - {hubName} (Cluster ID - {clusterId})
              </b>
            </div>
            <div className="standard-hub--status-modal--body__domain">
              <b> Domain: {Domain} </b>
            </div>
            <div className="standard-hub--status-modal--body__desc">
              Migration flag is set to: {standardTypeMap[jobPlatformType]}
            </div>
            {jobLaunchGroupName.length && (
              <div className="standard-hub--status-modal--body__desc">
                Enablement Group is set to: {jobLaunchGroupName}
              </div>
            )}
            <Divider />
          </>
        ) : null}
        <Spin spinning={isLoading}>
          {jobStatus && statusMap[jobStatus] ? (
            <div className="standard-hub--status-modal--body__status">
              {statusMap[jobStatus]}
              {migrationStatusArrayList.length > 0 ? (
                <>
                  <Divider />
                  {migrationStatusArrayList}
                </>
              ) : null}
            </div>
          ) : null}
        </Spin>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  standardHubManagement: {
    jobStatusData,
    jobStatusModalVisible,
    page,
    platformType,
    searchText,
  },
}) => ({
  jobStatusData,
  jobStatusModalVisible,
  page,
  platformType,
  searchText,
});

const mapDispatchToProps = {
  clearJobStatusModalState: clearJobStatusModalStateAction,
  setJobStatusModalVisible: handleActionSetJobStatusModalVisibleAction,
  stopFetchJobStatus: stopFetchJobStatusAction,
  fetchHubs: fetchHubsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressModal);
