import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Input,
  Form,
  Select,
  Button,
  Radio,
  Tree,
  PageHeader,
  Card,
  Dropdown,
  Menu,
} from 'antd';
import memoize from 'lodash/memoize';
import map from 'lodash/map';
import union from 'lodash/union';
import intersection from 'lodash/intersection';
import every from 'lodash/every';
import {
  renderTreeNodes,
  trimTree,
  getDependencies,
  getLeaves,
  getLabels,
  getRequiredFields,
} from '../../../utils/checkBoxTreeUtils';
import {
  fetchAllClusterConfig,
  fetchAllClusters,
  createCluster,
} from '../actions';
import Content from '../../../component/Content';
import SelectActions from '../../../component/SelectActions';
import nodes from '../ClusterSave/nodes';
import { breadCrumbItemRender } from '../../../utils/helpers';
import enablementGroups from '../../../constants/defaultSettingVariationConstants';

const breadcrumbConf = {
  routes: [
    {
      path: '/clusters',
      breadcrumbName: 'Clusters',
    },
    {
      path: '/clusters/new',
      breadcrumbName: 'Create Configs',
    },
  ],
  itemRender: breadCrumbItemRender,
};

const { Option } = Select;
const FormItem = props => (
  <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 6 }} {...props} />
);

const labels = getLabels(nodes);

class CreateCluster extends PureComponent {
  requiredFields = getRequiredFields(nodes);

  dependencies = getDependencies(nodes);

  trimTree = memoize(
    clusterConfig => trimTree(nodes, clusterConfig.data.checked),
    ({ id }) => id
  );

  constructor() {
    super();
    this.state = {
      name: '',
      subdomain: '',
      domain: '',
      launchGroup: {},
      clusterConfigType: 'none',
      clusterConfigId: undefined,
      clusterId: undefined,
      checked: [],
      expanded: [],
    };
  }

  componentDidMount() {
    const {
      fetchAllClusters: fetchClusters,
      fetchAllClusterConfig: fetchClusterConfig,
      clusters,
      clusterConfig,
    } = this.props;

    if (!clusters) fetchClusters();
    if (!clusterConfig) fetchClusterConfig();
  }

  onClusterConfigFilter = (input, option) => {
    const { clusterConfigs } = this.props;
    return clusterConfigs
      .find(c => c.id === parseInt(option.key))
      .name.toLowerCase()
      .includes(input.toLowerCase());
  };

  onClusterFilter = (input, option) => {
    const { clusters } = this.props;
    return clusters
      .find(c => c.id === parseInt(option.key))
      .name.toLowerCase()
      .includes(input.toLowerCase());
  };

  onNameChange = e => this.setState({ name: e.target.value });

  onDomainChange = e => this.setState({ domain: e.target.value });

  onSubdomainChange = e => this.setState({ subdomain: e.target.value });

  onLaunchGroupChange = e => this.setState({ launchGroup: e.key });

  onClusterConfigTypeChange = e =>
    this.setState({ clusterConfigType: e.target.value });

  onClusterConfigChange = clusterConfigId => {
    this.setState({
      clusterConfigId,
      checked: this.requiredFields,
      expanded: map(nodes, 'key'),
    });
  };

  onClusterChange = clusterId => this.setState({ clusterId });

  onExpand = expanded => this.setState({ expanded });

  onCheck = checked => {
    checked = union(checked, this.requiredFields);
    checked = intersection(getLeaves(nodes), checked);
    checked = checked.filter(module => {
      const dependencies = this.dependencies[module];
      if (!dependencies) return true;
      return every(dependencies, dep => checked.includes(dep));
    });
    this.setState({ checked });
  };

  renderConfigSelectDropdown = () => {
    const { clusterConfigType, clusterId, clusterConfigId } = this.state;
    const {
      clusters,
      clusterConfigs,
      clustersLoading,
      clustersConfigsLoading,
    } = this.props;
    if (clusterConfigType === 'clusterConfig')
      return (
        <FormItem label="Cluster Configuration">
          <Select
            style={{ width: 300 }}
            showSearch
            placeholder="Select Cluster Config"
            onChange={this.onClusterConfigChange}
            filterOption={this.onClusterConfigFilter}
            loading={clustersConfigsLoading}
            value={clusterConfigId}
          >
            {clusterConfigs
              .filter(c => c.status === 'done')
              .map(config => (
                <Option key={config.id} value={config.id}>
                  {' '}
                  {config.name}{' '}
                </Option>
              ))}
          </Select>
        </FormItem>
      );
    else if (clusterConfigType === 'copy')
      return (
        <FormItem label="Cluster to copy">
          <Select
            style={{ width: 300 }}
            showSearch
            placeholder="Select Cluster"
            onChange={this.onClusterChange}
            filterOption={this.onClusterFilter}
            loading={clustersLoading}
            value={clusterId}
          >
            {clusters.map(cluster => (
              <Option key={cluster.id} value={cluster.id}>
                {' '}
                {cluster.name}{' '}
              </Option>
            ))}
          </Select>
        </FormItem>
      );
    return null;
  };

  onSelectAll = () => {
    const { clusterConfigs } = this.props;
    const { clusterConfigId } = this.state;
    const clusterConfig = clusterConfigs.find(cc => cc.id === clusterConfigId);
    this.onCheck(getLeaves(this.trimTree(clusterConfig)));
  };

  onDeselectAll = () => this.onCheck([]);

  renderClusterConfigTree = () => {
    const { clusterConfigs } = this.props;
    const { clusterConfigType, clusterConfigId, expanded, checked } =
      this.state;
    if (clusterConfigType === 'clusterConfig' && clusterConfigId) {
      const clusterConfig = clusterConfigs.find(
        cc => cc.id === clusterConfigId
      );
      return (
        <Card
          title={
            <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.45)' }}>
              Select settings to import
            </div>
          }
        >
          <SelectActions
            onSelectAll={this.onSelectAll}
            onDeselectAll={this.onDeselectAll}
          />
          <Tree
            checkable
            onExpand={this.onExpand}
            onCheck={this.onCheck}
            expandedKeys={expanded}
            checkedKeys={checked}
            selectedKeys={[]}
          >
            {renderTreeNodes(this.trimTree(clusterConfig), labels)}
          </Tree>
        </Card>
      );
    }
    return null;
  };

  createCluster = () => {
    const {
      name,
      subdomain,
      domain,
      clusterConfigType,
      clusterConfigId,
      clusterId,
      checked,
      launchGroup,
    } = this.state;
    this.props.createCluster({
      name,
      subdomain,
      launchGroup: launchGroup.key,
      domain,
      clusterConfigType,
      clusterConfigId,
      clusterId,
      checked,
    });
  };

  onLaunchGroupSearch = (input, option) => {
    const group = enablementGroups.find(gp => gp.key === option.key);
    return group.name.toLowerCase().includes(input.toLowerCase());
  };

  setLaunchGroup = key => {
    const selectedLaunchgroup = enablementGroups.find(g => g.key === key);
    this.setState({ launchGroup: selectedLaunchgroup || {} });
  };

  render() {
    const { name, domain, subdomain, clusterConfigType, launchGroup } = this.state;

    return (
      <div>
        <PageHeader breadcrumb={breadcrumbConf} title="Create A New Site" />
        <Content>
          <Card>
            <Form layout="horizontal" labelAlign="left">
              <FormItem label="Cluster Name">
                <Input
                  placeholder="University Connect"
                  value={name}
                  onChange={this.onNameChange}
                />
              </FormItem>
              <FormItem label="Domain">
                <Input
                  placeholder="unviersityconnect.peoplgrove.com"
                  value={domain}
                  onChange={this.onDomainChange}
                />
              </FormItem>
              <FormItem label="Subdomain">
                <Input
                  placeholder="peoplgrove"
                  value={subdomain}
                  onChange={this.onSubdomainChange}
                />
              </FormItem>
              <FormItem label="Launch Group">
                <Select
                  showSearch
                  placeholder="peoplegrove"
                  onChange={this.setLaunchGroup}
                  filterOption={this.onLaunchGroupSearch}
                  value={launchGroup.name}
                >
                  {enablementGroups.map(lg => (
                    <Option key={lg.key} value={lg.key}>
                      {lg.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem label="Configure Cluster" wrapperCol={{ span: 12 }}>
                <Radio.Group
                  value={clusterConfigType}
                  onChange={this.onClusterConfigTypeChange}
                >
                  <Radio value="none">None</Radio>
                  <Radio value="copy">Copy Cluster</Radio>
                  <Radio value="clusterConfig">Use Cluster Config</Radio>
                </Radio.Group>
              </FormItem>
              {this.renderConfigSelectDropdown()}
              {this.renderClusterConfigTree()}
            </Form>
            <Button style={{ marginTop: '20px' }} onClick={this.createCluster}>
              Create
            </Button>
          </Card>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({
  cluster: {
    clusterConfigs,
    clustersConfigsLoading,
    clusters,
    clustersLoading,
  },
}) => ({
  clusterConfigs,
  clustersConfigsLoading,
  clusters,
  clustersLoading,
});

const mapDispatchToProps = {
  fetchAllClusterConfig,
  fetchAllClusters,
  createCluster,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateCluster)
);
