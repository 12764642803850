import React, { useState } from 'react';
import { Modal, Input, Form, Select, Spin } from 'antd';
import { isEmpty, isEqual, trim, map } from 'lodash';

import { withRouter } from 'react-router-dom';

import { useGetHubsQuery } from '../query';
import useDebouncedValue from '../../../hooks/useDebouncedValue';

import classes from '../index.module.scss';

const { Option } = Select;

const BenchmarkingGroupModal = props => {
  const {
    modalType,
    showModal = false,
    onSubmit,
    onClose,
    previousData = {},
    title = 'TITLE',
    subHeader = '',
    checkForDuplicates,
  } = props;
  const [hubSearchText, setHubSearchText] = useState(null);

  const debouncedHubSearchText = useDebouncedValue(hubSearchText, 300);
  const { data: hubsData = [], isLoading: hubDataLoading } = useGetHubsQuery({
    searchText: debouncedHubSearchText,
  });
  const { form } = props;
  const { getFieldDecorator, getFieldsValue } = form;

  const closeModal = () => {
    setHubSearchText(null);
    form.resetFields();
    onClose();
  };

  const handleSave = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
        form.resetFields();
        closeModal();
      }
    });
  };

  const handleClose = () => {
    const changesMade = checkForChanges();

    if (changesMade) {
      unSavedChangesConfirmation();
    } else {
      closeModal();
    }
  };

  const unSavedChangesConfirmation = () =>
    Modal.confirm({
      title: 'Cancel?',
      content: 'If you cancel, the information you entered will not be saved.',
      okText: 'Cancel',
      cancelText: 'Back',
      onOk() {
        closeModal();
      },
      onCancel() {},
    });

  const checkForChanges = () => {
    const currentFormValues = getFieldsValue();

    let hasChanges = false;
    if (modalType === 'create') {
      hasChanges = Object.keys(currentFormValues).some(
        field => currentFormValues[field] && !isEmpty(currentFormValues[field])
      );
    }

    if (modalType === 'edit') {
      hasChanges = Object.keys(currentFormValues).some(field => {
        if (['name', 'externalName'].includes(field)) {
          return !isEqual(currentFormValues[field], previousData[field]);
        }

        if (field === 'hubIds') {
          return !isEqual(
            currentFormValues[field].sort(),
            map(previousData.hubs, 'id').sort()
          );
        }

        return false;
      });
    }

    return hasChanges;
  };

  return (
    <Modal
      title={title}
      visible={!!showModal}
      onCancel={handleClose}
      onOk={handleSave}
      className={classes.benchmarkingModal}
      maskClosable={false}
      okText="Save"
    >
      <div className={classes.text}>
        <span>{subHeader}</span>
      </div>
      <Form>
        <Form.Item label="Group internal name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please enter the group internal name!',
                whitespace: true,
              },
              ...(modalType === 'create'
                ? [
                    {
                      validator: (rule, value, cb) => {
                        checkForDuplicates('name', trim(value))
                          ? cb('Benchmarking Group name is already in use!')
                          : cb();
                      },
                    },
                  ]
                : []),
            ],
            initialValue: previousData.name || '',
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Group external name">
          {getFieldDecorator('externalName', {
            rules: [
              {
                required: true,
                message: 'Please enter the external group name!',
                whitespace: true,
              },
              ...(modalType === 'create'
                ? [
                    {
                      validator: (rule, value, cb) => {
                        checkForDuplicates('name', trim(value))
                          ? cb(
                              'Benchmarking External Group name is already in use!'
                            )
                          : cb();
                      },
                    },
                  ]
                : []),
            ],
            initialValue: previousData.externalName || '',
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Hubs" className="ant-form-item">
          {getFieldDecorator('hubIds', {
            rules: [
              {
                required: true,
                message: 'Please select at-least one hub!',
              },
            ],
            initialValue: (previousData.hubs || []).map(({ id }) => id),
          })(
            <Select
              showSearch
              mode="multiple"
              notFoundContent={
                hubDataLoading ? <Spin size="small" /> : 'No hubs found'
              }
              onSearch={setHubSearchText}
              placeholder="Select One or More Hubs"
              style={{ width: '100%' }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {[...hubsData, ...(previousData.hubs || [])].map(hub => (
                <Option value={hub.id} key={hub.id}>
                  {`${hub.name} (${hub.id})`}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withRouter(Form.create()(BenchmarkingGroupModal));
