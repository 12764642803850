/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Button, Select, Icon, Card, Divider } from 'antd';
import ReactJson from 'react-json-view';
import { find, isEmpty, clone, pick, each, concat } from 'lodash';
import FileUploader from '../../../component/FileUploader';
import CopyFromProgramModal from './modals/CopyFromProgramModal';
import ProgressBar from '../../../component/ProgressBar';
import {
  saveTemplateData,
  deleteTemplate,
  setName,
  setInternalName,
  setDescription,
  setGroupTier,
  setLogoUrl,
  setCoverPhotoUrl,
  setCopyModalVisibility,
  setDeleteModalVisibility,
  setCancelModalVisibility,
  setVisibleToUserType,
} from '../actions/manage';
import { popupAlert } from '../../../actions/alert';
import DeleteModal from './modals/DeleteModal';
import CancelModal from './modals/CancelModal';
import { getUploadUrl } from '../../../utils/getUploadUrl';

const { Option } = Select;

const MAX_NAME_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;

class TemplateForm extends React.Component {
  handleInputChange = (key, e) => {
    const { setName, setInternalName, setDescription } = this.props;
    const value = e.target.value;

    if (key === 'name') setName(value);
    else if (key === 'internalName') setInternalName(value);
    else if (key === 'description') setDescription(value);
  };

  handleUpload = (key, { filesUploaded }) => {
    const { setLogoUrl, setCoverPhotoUrl } = this.props;
    const fileUploadedUrl = getUploadUrl(filesUploaded[0], '/admin/');
    if (key === 'logoUrl') setLogoUrl(fileUploadedUrl);
    else if (key === 'coverPhotoUrl') setCoverPhotoUrl(fileUploadedUrl);
  };

  handleSubmit = () => {
    const {
      name,
      internalName,
      description,
      groupTier,
      logoUrl,
      coverPhotoUrl,
      settings,
      match,
      popupAlert,
      saveTemplateData,
      groupedUserType,
      visibleToUserType,
      selectedGroup,
    } = this.props;
    let error = false;

    if (name.trim().length > MAX_NAME_LENGTH) {
      popupAlert({
        type: 'error',
        message: `Name cannot be more than ${MAX_NAME_LENGTH} characters.`,
      });
      error = true;
    }

    if (internalName.trim().length > MAX_NAME_LENGTH) {
      popupAlert({
        type: 'error',
        message: `Name cannot be more than ${MAX_NAME_LENGTH} characters.`,
      });
      error = true;
    }

    if (description.trim().length > MAX_DESCRIPTION_LENGTH) {
      popupAlert({
        type: 'error',
        message: `Description cannot be more than ${MAX_DESCRIPTION_LENGTH} characters.`,
      });
      error = true;
    }

    if (!name.trim() || !internalName.trim() || !description.trim()) {
      popupAlert({
        type: 'error',
        message: `Please fill all mandatory fields - Name, InternalName, Description.`,
      });
      error = true;
    }

    if (!groupTier) {
      popupAlert({
        type: 'error',
        message: `Select a Tier.`,
      });
      error = true;
    }

    if (
      match.params.type === 'program' &&
      Object.keys(groupedUserType).length > visibleToUserType.length
    ) {
      popupAlert({
        type: 'error',
        message: `Select role for all standard usertypes.`,
      });
      error = true;
    }

    if (error) return;

    const obj = {
      name,
      internalName,
      description,
      groupTier,
      logoUrl,
      coverPhotoUrl,
      settings,
      selectedGroup: pick(selectedGroup, ['id', 'name', 'hub_id']),
    };

    const type = match && match.params.type;
    obj['type'] = type;
    if (type === 'program') {
      obj.settings = {
        ...settings,
        visibleToUserType,
      };
    }

    const id = match && match.params.id;
    if (id) obj['id'] = id;

    saveTemplateData(obj);
  };

  handleUserTypeChange = key => value => {
    const {
      groupedUserType,
      visibleToUserType,
      setVisibleToUserType,
    } = this.props;
    const userTypeObj = groupedUserType[key][0];
    const obj = [
      {
        id: userTypeObj.id,
        name: userTypeObj.title,
        userType: value,
        standardUserType: key,
        isVisibleToGroup: true,
      },
    ];

    let modifiedToUserType = clone(visibleToUserType);
    const isExist =
      visibleToUserType.findIndex(item => item.standardUserType === key) > -1;
    if (isExist) {
      modifiedToUserType = modifiedToUserType.filter(
        item => item.standardUserType !== key
      );
    }
    setVisibleToUserType(modifiedToUserType.concat(obj));
  };

  getSelectValue = value => {
    const { groupedUserType, visibleToUserType } = this.props;
    if (value && !isEmpty(groupedUserType)) {
      const visibleToUserTypeObj = find(visibleToUserType, {
        standardUserType: value,
      });
      if (visibleToUserTypeObj && visibleToUserTypeObj.userType !== 'userOpt') {
        return visibleToUserTypeObj.userType;
      }
      if (visibleToUserTypeObj && visibleToUserTypeObj.userType === 'userOpt') {
        return 'User can choose';
      }
    }
    return undefined;
  };

  renderUserTypeSelect = () => {
    const { match, settings, groupedUserType } = this.props;
    const { allowBoth } = settings;

    if (match.params && match.params.type === 'program' && !isEmpty(settings)) {
      return (
        <div className="user-type__container">
          <p className="user-type__title">Standardized Usertypes</p>
          <span className="user-type__sub-text">
            Select the Standardized User Types mapped from the copied Program.
            Or choose new ones.
          </span>
          <hr />
          {Object.keys(groupedUserType)
            .sort()
            .map(key => (
              <div className="user-type__row" key={key}>
                <div>
                  {/* <Switch size="small" /> */}
                  <span className="user-type__switch-text">{key}</span>
                </div>
                <Select
                  style={{ width: '250px' }}
                  value={this.getSelectValue(key)}
                  className={
                    !this.getSelectValue(key) ? 'user-type__select-error' : ''
                  }
                  placeholder="No usertype selected"
                  onChange={this.handleUserTypeChange(key)}
                >
                  <Option key="mentee">Mentee</Option>
                  <Option key="mentor">Mentor</Option>
                  {allowBoth && (
                    <Option key="both">Both Mentee and Mentor</Option>
                  )}
                  <Option key="userOpt">User can choose</Option>
                </Select>
              </div>
            ))}
        </div>
      );
    }
    return undefined;
  };

  render() {
    const {
      match,
      manageSingleLoading,
      activeTiers,
      activeTiersLoading,
      name,
      internalName,
      description,
      groupTier,
      logoUrl,
      coverPhotoUrl,
      settings,
      fieldInputsDisabled,
      setGroupTier,
      setCopyModalVisibility,
      setDeleteModalVisibility,
      setCancelModalVisibility,
      manageSaveLoading,
      selectedGroup,
    } = this.props;

    if (manageSingleLoading || activeTiersLoading)
      return (
        <div className="form-container">
          <ProgressBar
            style={{ minHeight: '500px', backgroundColor: '#ffffff' }}
          />
        </div>
      );

    const tier = find(activeTiers, { id: groupTier });
    const { type } = match.params;
    const capitalizedType =
      type && type.charAt(0).toUpperCase() + type.slice(1);

    return (
      <div className="ck-card form-container">
        <h3 className="form-container__heading">Create Templates</h3>
        <Form>
          <Form.Item className="ant-form-item">
            <Button
              className="full__width copy-btn"
              onClick={() => setCopyModalVisibility(true)}
            >
              Copy from Existing {type === 'group' ? 'Group' : 'Program'}
            </Button>
            <CopyFromProgramModal />
          </Form.Item>
          <Form.Item label="Copied Hub" className="ant-form-item">
            <Input
              placeholder="Hub Name"
              disabled={true}
              value={selectedGroup && selectedGroup.hub_name}
              onChange={e => this.handleInputChange('internalName', e)}
            />
          </Form.Item>
          <Form.Item
            label={`Copied ${capitalizedType}`}
            className="ant-form-item"
          >
            <Input
              placeholder={`${capitalizedType} Name`}
              disabled={true}
              value={selectedGroup && selectedGroup.name}
              onChange={e => this.handleInputChange('name', e)}
            />
          </Form.Item>
          <Divider />
          <Form.Item label="Choose Tier" className="ant-form-item">
            <Select
              showSearch
              mode="single"
              placeholder="Associate a Tier"
              style={{ width: '100%' }}
              optionFilterProp="children"
              disabled={fieldInputsDisabled}
              onChange={setGroupTier}
              loading={activeTiersLoading}
              value={tier && tier.name}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeTiers.map(tier => (
                <Option value={tier.id} key={tier.id}>
                  {tier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Internal Template Name" className="ant-form-item">
            <Input
              placeholder="Add Internal Name"
              disabled={fieldInputsDisabled}
              value={internalName}
              onChange={e => this.handleInputChange('internalName', e)}
            />
          </Form.Item>
          <Form.Item label="Name" className="ant-form-item">
            <Input
              placeholder="Add Display Name"
              disabled={fieldInputsDisabled}
              value={name}
              onChange={e => this.handleInputChange('name', e)}
            />
          </Form.Item>
          <Form.Item label="Description" className="ant-form-item">
            <Input.TextArea
              placeholder="Add Description"
              disabled={fieldInputsDisabled}
              value={description}
              onChange={e => this.handleInputChange('description', e)}
            />
          </Form.Item>
          {this.renderUserTypeSelect()}
          <Form.Item label="Logo Upload" className="ant-form-item">
            <Input
              type="text"
              placeholder="Click to Upload Image"
              disabled={fieldInputsDisabled}
              value={logoUrl}
              addonAfter={
                <FileUploader
                  type="image"
                  path="/admin/"
                  render={({ onPick }) => (
                    <Icon type="paper-clip" onClick={onPick} />
                  )}
                  onSuccess={img => this.handleUpload('logoUrl', img)}
                  options={{ maxFiles: 1 }}
                />
              }
            />
          </Form.Item>
          <Form.Item label="Cover Upload" className="ant-form-item">
            <Input
              type="text"
              placeholder="Click to Upload Image"
              disabled={fieldInputsDisabled}
              value={coverPhotoUrl}
              addonAfter={
                <FileUploader
                  type="image"
                  path="/admin/"
                  render={({ onPick }) => (
                    <Icon type="paper-clip" onClick={onPick} />
                  )}
                  onSuccess={img => this.handleUpload('coverPhotoUrl', img)}
                  options={{ maxFiles: 1 }}
                />
              }
            />
          </Form.Item>
          <Card
            title={`Copied ${type === 'group' ? 'Group' : 'Program'} Settings`}
            style={{ marginTop: '16px' }}
          >
            <ReactJson
              style={{
                overflow: 'auto',
                fontSize: '12px',
              }}
              name="settings"
              collapsed={true}
              src={settings || {}}
            />
          </Card>
          <Form.Item className="ant-form-item">
            <div className="buttons">
              <div className="buttons__left">
                {match.params && match.params.id && (
                  <Button
                    type="danger"
                    disabled={!match.params || !match.params.id}
                    onClick={() => setDeleteModalVisibility(true)}
                    className="delete-btn"
                  >
                    Delete
                  </Button>
                )}
                <DeleteModal />
              </div>
              <div className="buttons__right">
                <Button
                  type="default"
                  className="primary-btn"
                  onClick={() => setCancelModalVisibility(true)}
                  disabled={manageSaveLoading}
                >
                  Cancel
                </Button>
                <CancelModal />
                <Button
                  type="primary"
                  className="primary-btn"
                  onClick={this.handleSubmit}
                  disabled={manageSaveLoading}
                >
                  {match.params && match.params.id ? 'Update' : 'Save'}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const {
    manageSingleLoading,
    activeTiers,
    activeTiersLoading,
    name,
    internalName,
    description,
    groupTier,
    logoUrl,
    coverPhotoUrl,
    settings,
    fieldInputsDisabled,
    groupedUserType,
    visibleToUserType,
    selectedGroup,
    manageSaveLoading,
  } = groupsManage;
  return {
    manageSingleLoading,
    activeTiers,
    activeTiersLoading,
    name,
    internalName,
    description,
    groupTier,
    logoUrl,
    coverPhotoUrl,
    settings,
    fieldInputsDisabled,
    groupedUserType,
    visibleToUserType,
    selectedGroup,
    manageSaveLoading,
  };
};

const mapDispatchToProps = {
  saveTemplateData,
  deleteTemplate,
  setName,
  setInternalName,
  setDescription,
  setGroupTier,
  setLogoUrl,
  setCoverPhotoUrl,
  popupAlert,
  setCopyModalVisibility,
  setDeleteModalVisibility,
  setCancelModalVisibility,
  setVisibleToUserType,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TemplateForm)
);
