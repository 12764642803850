import { request } from '../utils/request';

export const fetchCompanyIndustryDataApi = params =>
  request.get('/superadmin/company-industry-data', { params });

export const updateCompanyIndustryApi = ({ id, data }) =>
  request.put(`/superadmin/company-industry/${id}`, data);

export const addCompanyIndustryApi = data =>
  request.post('/superadmin/company-industry', data);

export const deleteCompanyIndustryApi = id =>
  request.delete(`/superadmin/company-industry/${id}`);

export const getKaggleCompaniesMatchesApi = params =>
  request.get('/superadmin/company-industry/kaggle-companies-matches', {
    params,
  });
