import React from 'react';
import s from './PageLoading.module.scss';
import { Spin, Icon } from 'antd';

const FullPageLoading = ({ fontSize }) => (
    <div className={s['loader-container']}>
        <Spin indicator={<Icon type="loading" style={{ fontSize: fontSize, fontWeight: 500 }} spin />} />
    </div>
);

FullPageLoading.defaultProps = {
    fontSize: 50
}

export default FullPageLoading;