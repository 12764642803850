import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { handleActionConfirmModalVisible as handleActionConfirmModalVisibleAction } from '../../../actions/manageActions';

function ConfirmModal({
  confirmModalVisible,
  handleActionConfirmModalVisible,
  onConfirmOk = () => {},
}) {
  const handleOnCancel = () => {
    handleActionConfirmModalVisible(false);
  };

  const handleConfirmOk = () => {
    onConfirmOk();
    handleActionConfirmModalVisible(false);
  };

  const renderFooter = () => (
    <div>
      <Button onClick={() => handleActionConfirmModalVisible(false)}>
        Cancel
      </Button>
      <Button type="primary" onClick={handleConfirmOk}>
        Yes
      </Button>
    </div>
  );

  return (
    <Modal
      title="Save"
      visible={confirmModalVisible}
      onCancel={handleOnCancel}
      footer={renderFooter()}
      className="confirmation-modal"
      destroyOnClose
    >
      <p>Are you sure you want to update this active User Type Library?</p>
    </Modal>
  );
}

const mapStateToProps = ({ usertypeManage: { confirmModalVisible } }) => ({
  confirmModalVisible,
});

const mapDispatchToProps = {
  handleActionConfirmModalVisible: handleActionConfirmModalVisibleAction,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConfirmModal)
);
