export const SET_USER_HUB_IDS = '/RefreshES/SET_USER_HUB_IDS';
export const PROCESS_UPSERT_USERS = '/RefreshES/PROCESS_UPSERT_USERS';
export const UPSERT_LOADING = '/RefreshES/UPSERT_LOADING';
export const SET_NOT_FOUND_IDS = '/RefreshES/SET_NOT_FOUND_IDS';

export const setUserHubIds = data => ({
  type: SET_USER_HUB_IDS,
  data,
});

export const processUpsertUsers = () => ({
  type: PROCESS_UPSERT_USERS,
});

export const upsertLoading = data => ({
  type: UPSERT_LOADING,
  data,
});

//selectors
export const userHubIds = ({ esDocument }) => esDocument.userHubIds;
