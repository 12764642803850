
const prefix = `GroupProgramTier`
export const FETCH_HUB_GROUPS_DATA = `${prefix}/FETCH_HUB_GROUPS_DATA`;
export const SET_GROUPS_DATA_LOADING = `${prefix}/SET_GROUPS_DATA_LOADING`;
export const SET_GROUPS_DATA_COUNT = `${prefix}/SET_GROUPS_DATA_COUNT`;
export const SET_HUB_GROUPS_DATA = `${prefix}/SET_HUB_GROUPS_DATA`;
export const SET_HUB_GROUPS_PAGE = `${prefix}/SET_HUB_GROUPS_PAGE`;
export const SET_HUB_GROUPS_SEARCH = `${prefix}/SET_HUB_GROUPS_SEARCH`;
export const SET_HUB_GROUPS_FILTER_APPLIED = `${prefix}/SET_HUB_GROUPS_FILTER_APPLIED`;
export const FETCH_GROUP_TIERS = `${prefix}/FETCH_GROUP_TIERS`;
export const SET_TIER_LOADING = `${prefix}/SET_TIER_LOADING`;
export const SET_TIER_DATA = `${prefix}/SET_TIER_DATA`;
export const SET_SELECTED_TIER_DATA = `${prefix}/SET_SELECTED_TIER_DATA`;
export const SET_CONFIGURATION_MODAL = `${prefix}/SET_CONFIGURATION_MODAL`;
export const SET_CONFIGURATION_TAB = `${prefix}/SET_CONFIGURATION_TAB`;
export const SET_SELECTED_CONFIGURATION_SETTING = `${prefix}/SET_SELECTED_CONFIGURATION_SETTING`;
export const FETCH_TEMPLATE_ASSOCIATED_TO_TIER = `${prefix}/FETCH_TEMPLATE_ASSOCIATED_TO_TIER`;
export const SET_TEMPLATE_LOADING = `${prefix}/SET_TEMPLATE_LOADING`;
export const SET_TEMPLATE_DATA = `${prefix}/SET_TEMPLATE_DATA`;
export const SAVE_CONFIGURATION_SETTING = `${prefix}/SAVE_CONFIGURATION_SETTING`;
export const SET_SAVE_CONFIGURATION_LOADING = `${prefix}/SET_SAVE_CONFIGURATION_LOADING`;
export const SET_SELECTED_HUB = `${prefix}/SET_SELECTED_HUB`;
export const SET_TIER_TABS = `${prefix}/SET_TIER_TABS`;
export const CLEAR_CONFIGURATION_DATA = `${prefix}/CLEAR_CONFIGURATION_DATA`;
export const CLEAR_GROUPS_TABLE_DATA = `${prefix}/CLEAR_GROUPS_TABLE_DATA`;
export const SET_IS_ENABLED = `${prefix}/SET_IS_ENABLED`;
export const SET_SELECTED_CONFIGURATION_OBJ = `${prefix}/SET_SELECTED_CONFIGURATION_OBJ`;

export const fetchHubGroupsData = () => ({
  type: FETCH_HUB_GROUPS_DATA
});

export const setGroupsDataLoading = data => ({
  type: SET_GROUPS_DATA_LOADING,
  data,
});

export const setGroupsDataCount = data => ({
  type: SET_GROUPS_DATA_COUNT,
  data,
});

export const setHubGroupsData = data => ({
  type: SET_HUB_GROUPS_DATA,
  data,
});

export const setHubGroupsPage = data => ({
  type: SET_HUB_GROUPS_PAGE,
  data,
});

export const setHubGroupsSearch = data => ({
  type: SET_HUB_GROUPS_SEARCH,
  data,
});

export const setHubGroupsFilterApplied = data => ({
  type: SET_HUB_GROUPS_FILTER_APPLIED,
  data,
});

export const fetchGroupTiers = () => ({
  type: FETCH_GROUP_TIERS,
});

export const setTierLoading = data => ({
  type: SET_TIER_LOADING,
  data,
});

export const setTierData = data => ({
  type: SET_TIER_DATA,
  data,
});

export const setSelectedTierData = data => ({
  type: SET_SELECTED_TIER_DATA,
  data,
});

export const setConfigurationModal = data => ({
  type: SET_CONFIGURATION_MODAL,
  data,
});

export const setConfigurationTab = data => ({
  type: SET_CONFIGURATION_TAB,
  data,
});

export const setSelectedConfigurationSetting = data => ({
  type: SET_SELECTED_CONFIGURATION_SETTING,
  data,
});

export const fetchTemplateAssociatedToTier = data => ({
  type: FETCH_TEMPLATE_ASSOCIATED_TO_TIER,
  data,
});

export const setTemplateLoading = data => ({
  type: SET_TEMPLATE_LOADING,
  data,
});

export const setTemplateData = data => ({
  type: SET_TEMPLATE_DATA,
  data,
});

export const saveConfigurationSetting = () => ({
  type: SAVE_CONFIGURATION_SETTING,
});

export const setSaveConfigurationLoading = data => ({
  type: SET_SAVE_CONFIGURATION_LOADING,
  data,
});

export const setSelectedHub = data => ({
  type: SET_SELECTED_HUB,
  data,
});

export const setTierTabs = data => ({
  type: SET_TIER_TABS,
  data,
});

export const clearConfigurationData = () => ({
  type: CLEAR_CONFIGURATION_DATA,
});

export const clearGroupTableData = () => ({
  type: CLEAR_GROUPS_TABLE_DATA,
});

export const setIsEnabled = data => ({
  type: SET_IS_ENABLED,
  data,
});

export const setSelectedConfigurationObj = data => ({
  type: SET_SELECTED_CONFIGURATION_OBJ,
  data,
});

// action selectors
export const groupProgramTierPage = ({ groupsProgramsTier }) => groupsProgramsTier.page;
export const groupProgramSearch = ({ groupsProgramsTier }) => groupsProgramsTier.searchText;
export const selectedTierData = ({ groupsProgramsTier }) => groupsProgramsTier.selectedTierData;
export const configSetting = ({ groupsProgramsTier }) => groupsProgramsTier.selectedConfigurationSetting;
export const selectedHub = ({ groupsProgramsTier }) => groupsProgramsTier.selectedHub;
export const configurationTab = ({ groupsProgramsTier }) => groupsProgramsTier.configurationTab