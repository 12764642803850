import { request } from '../utils/request';

export const createCMSContentApi = params =>
  request.post('/cms-content', params);

export const editCMSContentApi = ({ id, ...data }) =>
  request.put(`/cms-content/${id}`, data);

export const fetchCmsContentApi = data =>
  request.get('/cms-content', { params: data });

export const deleteCmsContentApi = ({ id }) =>
  request.delete(`/cms-content/${id}`);

export const fetchCmsContentByIdentifierApi = ({ identifier }) =>
  request.get(`/cms-content/identifier/${identifier}`);

export const fetchCmsContentByIdApi = ({ id }) =>
  request.get(`/cms-content/${id}`);
