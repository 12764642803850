/* eslint-disable react/no-danger */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Form,
  Input,
  DatePicker,
  Button,
  Select,
  Checkbox,
  Radio,
  Icon,
} from 'antd';
import moment from 'moment';
import AlertImageContainer from './AlertImageContainer';
import QuillEditor from '../../component/QuillEditor';
import launchGroups from '../../constants/defaultSettingVariationConstants';
import {
  setHubBannerCtaText as setHubBannerCtaTextAction,
  setHubBannerTitle as setHubBannerTitleAction,
  setHubBannerDescription as setHubBannerDescriptionAction,
  setHubBannerStartDate as setHubBannerStartDateAction,
  setHubBannerEndDate as setHubBannerEndDateAction,
  setHubBannerImage as setHubBannerImageAction,
  setSelectedHubId as setSelectedHubIdAction,
  setSelectedLaunchGroups as setSelectedLaunchGroupsAction,
  saveBannerConfiguration as saveBannerConfigurationAction,
  deleteBannerConfiguration as deleteBannerConfigurationAction,
  setHubBannerLinkOption as setHubBannerLinkOptionAction,
  setHubBannerCtaLink as setHubBannerCtaLinkAction,
  setHubBannerCoverMediaType as setHubBannerCoverMediaTypeAction,
  setHubBannerVideo as setHubBannerVideoAction,
  getIframeCodeFromVideoURL as getIframeCodeFromVideoURLAction,
} from '../../actions/configureBanner';
import s from './Alert.module.scss';
import FullPageLoading from '../../component/FullPageLoading';
import FileUploader from '../../component/FileUploader';
import { popupAlert as popupAlertAction } from '../../actions/alert';
import { isValidUrl, isValidImageUrl } from '../../utils/helpers';
import { getUploadUrl } from '../../utils/getUploadUrl';
import * as api from '../../api';

const { Option } = Select;
const { RangePicker } = DatePicker;

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_CTA_LENGTH = 50;

class BannerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionLength: 0,
      videoLink: '',
      isEditingVideo: false,
      hubs: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const { data } = await api.hub.getAll({
      fields: ['id', 'name', 'identifier'],
    });
    this.setState({ hubs: data, loading: false });
  }

  componentDidUpdate = prevProps => {
    const { iframeCodeLoading: prevIframeCodeLoading, location: prevLocation } =
      prevProps;
    const { iframeCodeLoading, location } = this.props;

    if (prevLocation !== location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ videoLink: '', isEditingVideo: false });
    }

    if (prevIframeCodeLoading && !iframeCodeLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isEditingVideo: false });
    }
  };

  handleInputChange = (param, e) => {
    const { setHubBannerTitle, setHubBannerCtaText, setHubBannerCtaLink } =
      this.props;
    const val = e.target.value;
    if (param === 'title') {
      setHubBannerTitle(val);
    } else if (param === 'cta') {
      setHubBannerCtaText(val);
    } else if (param === 'ctaLink') {
      setHubBannerCtaLink(val);
    }
  };

  handleDescriptionChange = (description, delta, source, editor) => {
    const { setHubBannerDescription } = this.props;
    setHubBannerDescription(description);
    this.setState({ descriptionLength: editor.getText().trim().length });
  };

  handleDateChange = date => {
    const { setHubBannerStartDate, setHubBannerEndDate } = this.props;
    if (date && date.length > 0) {
      setHubBannerStartDate(moment(date[0]));
      setHubBannerEndDate(moment(date[1]));
    }
  };

  handleImageChange = name => {
    const { setHubBannerImage } = this.props;
    setHubBannerImage(name);
  };

  handleHubChange = val => {
    const { setSelectedHubId } = this.props;
    let valueToSet = val;
    if (val.indexOf('ALL') > -1) {
      valueToSet = ['ALL'];
    }
    setSelectedHubId(valueToSet);
  };

  handleLaunchGroupChange = val => {
    const { setSelectedLaunchGroups } = this.props;
    let valueToSet = val;
    if (val.indexOf('ALL') > -1) {
      valueToSet = ['ALL'];
    }
    setSelectedLaunchGroups(valueToSet);
  };

  handleSubmit = () => {
    const {
      hubBannerCoverMediaType,
      hubBannerImage,
      hubBannerVideo,
      hubBannerTitle,
      hubBannerCta,
      hubBannerDescription,
      hubBannerStartDate,
      hubBannerEndDate,
      selectedHubId,
      selectedLaunchGroups,
      saveBannerConfiguration,
      match,
      hubBannerCtaLink,
      hubBannerCtaLinkOption,
      popupAlert,
    } = this.props;

    const { descriptionLength, isEditingVideo } = this.state;

    let error = false;

    if (hubBannerTitle.length > MAX_TITLE_LENGTH) {
      popupAlert({
        type: 'error',
        message: `Title cannot be more than ${MAX_TITLE_LENGTH} characters`,
      });
      error = true;
    }

    if (descriptionLength > MAX_DESCRIPTION_LENGTH) {
      if (!error) {
        popupAlert({
          type: 'error',
          message: `Description cannot be more than ${MAX_DESCRIPTION_LENGTH} characters`,
        });
        error = true;
      }
    }

    if (hubBannerCta && hubBannerCta.length > MAX_CTA_LENGTH) {
      if (!error) {
        popupAlert({
          type: 'error',
          message: `Banner CTA cannot be more than ${MAX_CTA_LENGTH} characters`,
        });
        error = true;
      }
    }

    if (isEditingVideo) {
      if (!error) {
        popupAlert({
          type: 'error',
          message: `Save video before ${
            match.params && match.params.id ? 'updating' : 'submitting'
          }.`,
        });
        error = true;
      }
    }

    if (
      typeof hubBannerCtaLink.trim === 'function' &&
      hubBannerCtaLink.trim()
    ) {
      if (typeof hubBannerCta.trim !== 'function' || !hubBannerCta.trim()) {
        if (!error && hubBannerCta) {
          popupAlert({
            type: 'error',
            message: hubBannerCta
              ? `CTA button cannot contain only spaces.`
              : `Please set Hub Banner CTA text`,
          });
          error = true;
        }
      }
    }

    if (hubBannerCta && !isValidUrl(hubBannerCtaLink)) {
      if (!error) {
        popupAlert({
          type: 'error',
          message: 'Please enter a valid CTA Link.',
        });
        error = true;
      }
    }

    if (error) {
      return;
    }

    const obj = {
      title: hubBannerTitle,
      description: hubBannerDescription,
      cta: {
        text: hubBannerCta,
        link: hubBannerCtaLink,
        openInSameWindow: hubBannerCtaLinkOption,
      },
      coverMediaType: hubBannerCoverMediaType,
      image: hubBannerImage,
      video: hubBannerVideo,
      startDate: hubBannerStartDate,
      endDate: hubBannerEndDate,
      objectType: 'Hub',
      objectId: selectedHubId.indexOf('ALL') > -1 ? null : selectedHubId,
      launchGroup: selectedLaunchGroups,
    };
    if (match.params && match.params.id) {
      obj.id = match.params.id;
    }
    saveBannerConfiguration(obj);
  };

  handleDelete = id => () => {
    const { deleteBannerConfiguration, history } = this.props;
    deleteBannerConfiguration(id);
    history.push('/configure-alert-banner');
  };

  handleCheckboxChange = e => {
    const { setHubBannerLinkOption } = this.props;
    setHubBannerLinkOption(e.target.checked);
  };

  handleUpload = ({ filesUploaded }) => {
    console.log(filesUploaded);
    const fileUploadedUrl = getUploadUrl(filesUploaded[0], '/admin/');
    setTimeout(() => this.handleImageChange(fileUploadedUrl), 1500);
  };

  handleHUbBannerVideoChange = e => {};

  handleVideoSave = () => {
    const { getIframeCodeFromVideoURL, setHubBannerVideo } = this.props;
    const { videoLink } = this.state;

    if (isValidUrl(videoLink)) {
      getIframeCodeFromVideoURL({ url: videoLink });
    } else {
      this.setState({ isEditingVideo: false });
      setHubBannerVideo(videoLink);
    }
  };

  render() {
    const {
      hubBannerCoverMediaType,
      hubBannerVideo,
      hubBannerImage,
      hubBannerTitle,
      hubBannerCta,
      hubBannerCtaLink,
      hubBannerDescription,
      hubBannerStartDate,
      hubBannerEndDate,
      selectedHubId,
      selectedLaunchGroups,
      bannerFormLoading,
      match,
      hubBannerCtaLinkOption,
      setHubBannerCoverMediaType,
      iframeCodeLoading,
      usedCustomImages,
      form: { getFieldDecorator },
    } = this.props;

    const { descriptionLength, isEditingVideo, videoLink, hubs, loading } =
      this.state;

    // const shouldShowCTALinkError =
    // this.shouldShowCTALinkError(hubBannerCtaLink);
    const disableSelectHubOptions = selectedHubId.indexOf('ALL') > -1;
    const disableSelectLaunchGroupOptions =
      selectedLaunchGroups.indexOf('ALL') > -1;

    const isImageFromSuggestion =
      usedCustomImages.includes(hubBannerImage) ||
      String(hubBannerImage).startsWith('alert-banner');

    const imagePreviewLink =
      !isImageFromSuggestion && isValidImageUrl(hubBannerImage)
        ? hubBannerImage
        : '';

    return (
      <div className={`ck-card ${s.alert_module_container}`}>
        {(loading || bannerFormLoading) && <FullPageLoading fontSize={30} />}
        {!loading && !bannerFormLoading && hubs.length > 0 && (
          <>
            <h3>Hub Banner Configuration</h3>
            <Form>
              <Form.Item
                label={`Title (${hubBannerTitle.length} / ${MAX_TITLE_LENGTH})`}
                required
                className={s['ant-form-item']}
              >
                <Input
                  value={hubBannerTitle}
                  onChange={e => this.handleInputChange('title', e)}
                />
              </Form.Item>
              <Form.Item
                label={`Description (${descriptionLength} / ${MAX_DESCRIPTION_LENGTH})`}
                required
                className={s['ant-form-item']}
              >
                <QuillEditor
                  style={s['quill-editor']}
                  onChange={this.handleDescriptionChange}
                  value={hubBannerDescription}
                  getInitialCount={count => {
                    this.setState({ descriptionLength: count });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={`CTA Button (${hubBannerCta.length} / ${MAX_CTA_LENGTH})`}
                className={s['ant-form-item']}
              >
                <Input
                  value={hubBannerCta}
                  onChange={e => this.handleInputChange('cta', e)}
                />
              </Form.Item>
              {hubBannerCta && (
                <>
                  <Form.Item
                    label="CTA Link"
                    name="ctaLink"
                    className={s['ant-form-item']}
                  >
                    {getFieldDecorator('ctaLink', {
                      rules: [
                        {
                          validator: (_, value = '', callback) => {
                            if (value?.length > 0) {
                              const isValid = isValidUrl(value);
                              isValid
                                ? callback()
                                : callback(
                                    'Please enter a valid URL! (For example: https://www.peoplegrove.com)'
                                  );
                            }
                            callback();
                          },
                        },
                      ],
                      initialValue: hubBannerCtaLink,
                    })(
                      <Input
                        id="ctaLink"
                        placeholder="e.g. https://www.peoplegrove.com"
                        value={hubBannerCtaLink}
                        onChange={e => this.handleInputChange('ctaLink', e)}
                      />
                    )}
                  </Form.Item>
                  <Checkbox
                    checked={hubBannerCtaLinkOption}
                    onChange={this.handleCheckboxChange}
                  >
                    Open in same window
                  </Checkbox>
                </>
              )}
              <Form.Item label="Select Hub">
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select a hub"
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  onChange={this.handleHubChange}
                  value={selectedHubId}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="ALL">Select All</Option>
                  {hubs.map(hub => (
                    <Option
                      value={hub.id}
                      key={hub.id}
                      disabled={disableSelectHubOptions}
                    >
                      {hub.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Select Launch Group">
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select a Launch Group"
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  onChange={this.handleLaunchGroupChange}
                  value={selectedLaunchGroups}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="ALL">Select All</Option>
                  {launchGroups.map(lg => (
                    <Option
                      value={lg.key}
                      key={lg.name}
                      disabled={disableSelectLaunchGroupOptions}
                    >
                      {lg.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Banner Cover Media"
                required
                className={s['ant-form-item']}
              >
                <Radio.Group
                  name="banner-cover-media"
                  value={hubBannerCoverMediaType}
                  onChange={e => setHubBannerCoverMediaType(e.target.value)}
                >
                  <Radio.Button value="image">Image</Radio.Button>
                  <Radio.Button value="video">Video</Radio.Button>
                </Radio.Group>
                <div className={s['cover-media-container']}>
                  {hubBannerCoverMediaType === 'video' ? (
                    <div>
                      {isEditingVideo || !hubBannerVideo ? (
                        <>
                          <p className={s.label}>Video Link(URL)/Embed Code:</p>
                          <Input
                            type="url"
                            placeholder="Video embed code"
                            value={videoLink}
                            onChange={e =>
                              this.setState({ videoLink: e.target.value })
                            }
                          />
                          <Button
                            onClick={this.handleVideoSave}
                            loading={iframeCodeLoading}
                          >
                            Save
                          </Button>
                        </>
                      ) : (
                        <div>
                          <p>Preview:</p>
                          <div
                            className={s['video-preview-container']}
                            dangerouslySetInnerHTML={{ __html: hubBannerVideo }}
                          />
                          <Button
                            onClick={() =>
                              this.setState({
                                isEditingVideo: true,
                                videoLink: videoLink || hubBannerVideo,
                              })
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className={s['custom-image-input-container']}>
                        <p className={s.label}>
                          Custom Image (Recommended size 400px &times; 225px):
                        </p>
                        <Input
                          type="text"
                          placeholder="Upload Image or put a link here"
                          value={
                            String(hubBannerImage).startsWith('alert-banner')
                              ? ''
                              : hubBannerImage
                          }
                          onChange={e => this.handleImageChange(e.target.value)}
                          addonAfter={
                            <FileUploader
                              type="image"
                              // quality={imageQuality}
                              // maxSize={maxImageWidth}
                              path="/admin/"
                              render={({ onPick }) => (
                                <Icon type="paper-clip" onClick={onPick} />
                              )}
                              onSuccess={this.handleUpload}
                            />
                          }
                        />
                        {imagePreviewLink ? (
                          <div>
                            <p>preview:</p>
                            <img
                              src={imagePreviewLink}
                              style={{
                                maxWidth: '100%',
                                height: 200,
                                objectFit: 'contain',
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                      <AlertImageContainer
                        hubBannerImage={hubBannerImage}
                        handleImageChange={this.handleImageChange}
                      />
                    </div>
                  )}
                </div>
              </Form.Item>
              <Form.Item
                label="Date Range"
                required
                className={s['ant-form-item']}
              >
                <RangePicker
                  value={[hubBannerStartDate, hubBannerEndDate]}
                  onChange={this.handleDateChange}
                />
              </Form.Item>
              <Form.Item className={`${s['ant-form-item']} ${s['submit-btn']}`}>
                {match.params && match.params.id && (
                  <Button
                    type="danger"
                    onClick={this.handleDelete(match.params.id)}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  type="primary"
                  className={s['primary-btn']}
                  disabled={
                    !(hubBannerCoverMediaType === 'image'
                      ? hubBannerImage
                      : hubBannerVideo) ||
                    !hubBannerTitle ||
                    (hubBannerCta && !hubBannerCtaLink) ||
                    !descriptionLength > 0 ||
                    !hubBannerStartDate ||
                    !hubBannerEndDate
                  }
                  onClick={this.handleSubmit}
                >
                  {match.params && match.params.id ? 'Update' : 'Submit'}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ configureBanner }) => {
  const {
    hubBannerActive,
    hubBannerCoverMediaType,
    hubBannerVideo,
    hubBannerImage,
    hubBannerTitle,
    hubBannerDescription,
    hubBannerCta,
    hubBannerStartDate,
    hubBannerEndDate,
    selectedHubId,
    selectedLaunchGroups,
    bannerFormLoading,
    hubBannerCtaLink,
    hubBannerCtaLinkOption,
    iframeCodeLoading,
    usedCustomImages,
  } = configureBanner;
  return {
    hubBannerActive,
    hubBannerImage,
    hubBannerCoverMediaType,
    hubBannerVideo,
    hubBannerTitle,
    hubBannerDescription,
    hubBannerCta,
    hubBannerCtaLink,
    hubBannerStartDate,
    hubBannerEndDate,
    selectedHubId,
    selectedLaunchGroups,
    bannerFormLoading,
    hubBannerCtaLinkOption,
    iframeCodeLoading,
    usedCustomImages,
  };
};

const mapDispatchToProps = {
  setHubBannerTitle: setHubBannerTitleAction,
  setHubBannerCtaText: setHubBannerCtaTextAction,
  setHubBannerDescription: setHubBannerDescriptionAction,
  setHubBannerStartDate: setHubBannerStartDateAction,
  setHubBannerEndDate: setHubBannerEndDateAction,
  setHubBannerImage: setHubBannerImageAction,
  setSelectedHubId: setSelectedHubIdAction,
  setSelectedLaunchGroups: setSelectedLaunchGroupsAction,
  saveBannerConfiguration: saveBannerConfigurationAction,
  deleteBannerConfiguration: deleteBannerConfigurationAction,
  setHubBannerLinkOption: setHubBannerLinkOptionAction,
  setHubBannerCtaLink: setHubBannerCtaLinkAction,
  setHubBannerCoverMediaType: setHubBannerCoverMediaTypeAction,
  setHubBannerVideo: setHubBannerVideoAction,
  getIframeCodeFromVideoURL: getIframeCodeFromVideoURLAction,
  popupAlert: popupAlertAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form.create({})(BannerForm))
);
