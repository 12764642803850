import {
  SET_ALL_SETTINGS,
  SET_AFFECTED_FEATURE,
  SET_AFFECTED_HUB_FEATURE,
  SET_ALL_SETTINGS_CLONE,
  SET_MODULES_LIST,
  SET_PAGE_LOADING,
  SET_IS_PAGE_UPDATING,
  SET_SETTINGS_UPDATED,
  LOG_ERROR,
  SET_GROUP_HUB_FIELDS,
  SET_GROUP_HUBS_COUNT
} from '../actions';

import { isEqual, cloneDeep } from 'lodash';

const initialState = {
  settings: null,
  groupHubLoading: false,
  groupHubs: null,
  groupHubsCount: [],
  groupClustersCount: [],
  loading: false,
  areSettingsUpdated: false,
  settingsClone: null, // to check if data in the page has been updated or not
  isPageUpdating: false,
  modulesList: [],
  error: false,
};

const featureManagementReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ALL_SETTINGS:
      return {
        ...state,
        settings: data,
      };

    case SET_AFFECTED_FEATURE:
      return {
        ...state,
        settingsFeatureChange: data,
      };

    case SET_AFFECTED_HUB_FEATURE:
      return {
        ...state,
        affectedHubFeatures: data,
      };

    case SET_ALL_SETTINGS_CLONE:
      return {
        ...state,
        settingsClone: data,
      };

    case SET_MODULES_LIST:
      return {
        ...state,
        modulesList: data,
      };

    case SET_PAGE_LOADING:
      return {
        ...state,
        loading: data,
      };

    case SET_GROUP_HUB_FIELDS:
      return {
        ...state,
        ...data,
      };

    case SET_GROUP_HUBS_COUNT:
      return {
        ...state,
        ...data,
      };

    case SET_IS_PAGE_UPDATING:
      return {
        ...state,
        isPageUpdating: data,
      };

    case SET_SETTINGS_UPDATED:
      const stateObj = cloneDeep({ ...state });
      let flagToUpdate = false;
      if (isEqual(stateObj.settings, stateObj.settingsClone)) {
        flagToUpdate = false;
      } else {
        flagToUpdate = true;
      }
      return {
        ...state,
        areSettingsUpdated: flagToUpdate,
      };

    case LOG_ERROR:
      return {
        ...state,
        error: data,
      };

    default:
      return state;
  }
};

export default featureManagementReducer;
