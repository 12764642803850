import { takeLatest, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import {
  FETCH_SESSIONS,
  SET_PAGE_LOADING,
  SET_SESSIONS,
  DELETE_SESSION,
} from './actions';

import { fetchSessionAPI, deleteSessionAPI } from '../../api';

function* fetchSessions() {
  try {
    const res = yield call(fetchSessionAPI);
    if (res.status !== 200 || !res.data) {
      throw new Error('Failed to fetch sessions');
    }

    yield put({
      type: SET_SESSIONS,
      data: { sessions: res.data, isPageLoading: false },
    });
  } catch (error) {
    message.error('Failed to fetch sessions');
    console.log(error);
  }
}

function* deleteSession({ data }) {
  try {
    yield put({ type: SET_PAGE_LOADING, data: { isPageLoading: true } });
    const res = yield call(deleteSessionAPI, data);
    if (res.status !== 200) {
      throw new Error('Failed to delete session');
    }
    yield call(fetchSessions);
  } catch (error) {
    message.error('Failed to delete session');
    console.log(error);
  }
};

export default [
  takeLatest(FETCH_SESSIONS, fetchSessions),
  takeLatest(DELETE_SESSION, deleteSession)
];
