
const prefix = 'ManageBulkEmail/';
export const FETCH_BULK_EMAIL_LIST = `${prefix}FETCH_BULK_EMAIL_LIST`;
export const SET_BULK_EMAIL_LIST_COUNT = `${prefix}SET_BULK_EMAIL_LIST_COUNT`;
export const SET_BULK_EMAIL_LIST = `${prefix}SET_BULK_EMAIL_LIST`;
export const SET_BULK_EMAIL_LIST_LOADING = `${prefix}SET_BULK_EMAIL_LIST_LOADING`;
export const SET_BULK_EMAIL_LIST_PAGE = `${prefix}SET_BULK_EMAIL_LIST_PAGE`;
export const HANDLE_RESTART_CLICK = `${prefix}HANDLE_RESTART_CLICK`;
export const CLEAR_BULK_EMAIL_STATE = `${prefix}CLEAR_BULK_EMAIL_STATE`;
export const SET_BULK_QUEUE_STATE = `${prefix}SET_BULK_QUEUE_STATE`;

export const fetchBulkEmailList = () => ({
  type: FETCH_BULK_EMAIL_LIST,
});

export const setBulkEmailList = data => ({
  type: SET_BULK_EMAIL_LIST,
  data,
});

export const setBulkEmailListCount = data => ({
  type: SET_BULK_EMAIL_LIST_COUNT,
  data,
});

export const setBulkEmailListLoading = data => ({
  type: SET_BULK_EMAIL_LIST_LOADING,
  data,
});

export const setBulkEmailListPage = data => ({
  type: SET_BULK_EMAIL_LIST_PAGE,
  data,
});

export const handleRestartClick = data => ({
  type: HANDLE_RESTART_CLICK,
  data,
});

export const clearBulkEmailState = () => ({
  type: CLEAR_BULK_EMAIL_STATE,
});

export const setBulkQueueStatus = data => ({
  type: SET_BULK_QUEUE_STATE,
  data,
});

export const bulkEmailPage = ({ bulkEmail }) => bulkEmail.page;
export const bulkEmailList = ({ bulkEmail }) => bulkEmail.bulkEmailList;
