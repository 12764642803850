import {
  SET_TABLE_DATA,
  SET_ACTIVE_FORM,
  SET_FORM_DATA,
  CLEAR_DATA,
  SET_TABLE_LOADING,
  SET_TABLE_KEY,
  UPDATE_FORM_DATA,
} from '../actions';

const initialState = {
  tableData: {},
  tableLoading: true,
  activeForm: '', // template or tip
  formData: {},
  tableKey: 1,
};

const templatesAndTips = (state = initialState, { type, data }) => {
  switch (type) {
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case SET_TABLE_DATA:
      return {
        ...state,
        tableData: data,
      };
    case SET_ACTIVE_FORM:
      return {
        ...state,
        activeForm: data,
      };
    case SET_TABLE_LOADING:
      return {
        ...state,
        tableLoading: data,
      };
    case SET_FORM_DATA:
      return {
        ...state,
        formData: data,
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...data,
        },
      };
    case SET_TABLE_KEY:
      return {
        ...state,
        tableKey: state.tableKey + 1,
      };
    default:
      return state;
  }
};

export default templatesAndTips;
