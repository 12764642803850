import React, { PureComponent } from 'react';
import { Table, Slider } from 'antd';

class ImageSettings extends PureComponent {
  columns = [
    {
      title: 'Setting',
      key: 'setting',
      dataIndex: 'key',
      width: '40%',
      render: (key, obj) => {
        if (key === 'imageQuality')
          return <span>Image Quality - {obj.value}</span>;
        else return <span>Max Image Width - {obj.value}</span>;
      },
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      width: '60%',
      render: (value, obj) => {
        if (obj.key === 'imageQuality')
          return (
            <Slider
              onChange={this.handleImageQualityChange}
              defaultValue={value}
              min={5}
              max={100}
              step={5}
            />
          );
        else
          return (
            <Slider
              onChange={this.handleMaxImageWidthChange}
              defaultValue={value}
              min={100}
              max={2000}
              step={50}
            />
          );
      },
    },
  ];

  handleImageQualityChange = value => {
    const { setImageQuality } = this.props;
    setImageQuality(value);
  };

  handleMaxImageWidthChange  = value => {
    const { setMaxImageWidth } = this.props;
    setMaxImageWidth(value);
  };

  getDataForTable = () => {
    const { imageQuality, maxImageWidth } = this.props;
    const dataToReturn = [];
    dataToReturn.push({ key: 'imageQuality', value: imageQuality });
    dataToReturn.push({ key: 'maxImageWidth', value: maxImageWidth });
    return dataToReturn;
  };

  render() {
    const data = this.getDataForTable();
    return (
      <Table
        size="medium"
        columns={this.columns}
        pagination={false}
        dataSource={data}
      />
    );
  }
}

export default ImageSettings;
