import React, { PureComponent } from 'react';

class Forbidden extends PureComponent {
  componentDidMount() {
    setTimeout(() => {
      window.location.href = '/';
      console.log('redirecting');
    }, 1000);
  }

  render() {
    return <h1>403 Forbidden</h1>;
  }
}

export default Forbidden;
