import { takeLatest, put, delay } from 'redux-saga/effects';

import { SHOW_ALERT, HIDE_ALERT, POPUP_ALERT } from '../actions/alert';

export default [
  takeLatest(POPUP_ALERT, popupAlert),
];

function* popupAlert({ data }) {
    yield put({ type: SHOW_ALERT, data });

    yield delay(4000)

    yield put({ type: HIDE_ALERT, data });
}
