import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Divider,
  Icon,
  Input,
  PageHeader,
  Row,
  Select,
  DatePicker,
} from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import ProcessAlertService from '../../component/ProcessAlertService';
import Content from '../../component/Content';
import {
  getProcessHistoriesAction,
  runCronJobAction,
  setCurrentModalAction,
} from './actions';
import { openHub as openHubAction } from '../../actions/clusterHubs';
import TriggerModal from './TriggerModal';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ProcessAlertServiceTriggerModal = 'ProcessAlertServiceTriggerModal';

const ProcessAlertServiceContainer = ({
  getProcessHistories,
  processHistories,
  types,
  statuses,
  count,
  loading,
  runCronJob,
  cronJobLoading,
  currentModal,
  setCurrentModal,
  hubUrls,
  openHub,
}) => {
  useEffect(() => {
    getProcessHistories();
  }, [getProcessHistories]);

  const [searchText, setSearchText] = useState('');
  const [selectedHubId, setHubId] = useState('');
  const [selectedType, setType] = useState();
  const [selectedStatus, setStatus] = useState();
  const [selectedDateRange, setDateRange] = useState([]);
  const [selectedPage, setPage] = useState(1);

  const handleClearFiltersClick = () => {
    setSearchText('');
    setHubId('');
    setType();
    setStatus();
    setPage(1);
    setDateRange([]);
    getProcessHistories();
  };

  const setStandardDateFormat = (date, isEndDate = false) => {
    const sqlFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    if (isEndDate) return moment(date).endOf('day').format(sqlFormat);
    return moment(date).startOf('day').format(sqlFormat);
  };

  const handleDateChange = date => {
    setDateRange(date);
  };

  const handlePageNumber = page => {
    const pageNumber = parseInt(page, 10);
    handleApplyFilters(pageNumber);
  };

  const handleApplyFilters = (page = 1) => {
    setPage(page);
    const payload = {
      page,
      ...(searchText && { name: searchText }),
      ...(selectedHubId && { hub: selectedHubId }),
      ...(selectedType && { type: selectedType }),
      ...(selectedStatus && { status: selectedStatus }),
      ...(selectedDateRange[0] && {
        startDate: setStandardDateFormat(selectedDateRange[0]),
      }),
      ...(selectedDateRange[1] && {
        endDate: setStandardDateFormat(selectedDateRange[1], true),
      }),
    };
    getProcessHistories(payload);
  };

  const onRefresh = () => handleApplyFilters();

  const isFilterButtonEnabled =
    searchText.length > 0 ||
    selectedHubId.length > 0 ||
    selectedDateRange.length > 0 ||
    (selectedStatus && selectedStatus.length > 0) ||
    (selectedType && selectedType.length > 0);

  return (
    <>
      <PageHeader
        title="System Monitoring and Alerts"
        extra={[
          <Button
            type="primary"
            icon="sync"
            onClick={() => setCurrentModal(ProcessAlertServiceTriggerModal)}
            disabled={loading}
          >
            Trigger
          </Button>,
          <Button
            type="primary"
            icon="reload"
            onClick={onRefresh}
            disabled={loading}
          >
            Refresh
          </Button>,
        ]}
      >
        <Divider />
        <Row type="flex" justify="space-between" gutter={10}>
          <Col span={4}>
            <Search
              placeholder="Name (of the process)"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onSearch={searchTerm => handleApplyFilters()}
              allowClear
            />
          </Col>
          <Col span={3}>
            <Input
              placeholder="Hub ID"
              value={selectedHubId}
              onChange={e => setHubId(e.target.value)}
              allowClear
            />
          </Col>
          <Col span={5}>
            <Select
              placeholder="Select Process Type"
              onChange={d => setType(d)}
              value={selectedType}
              style={{ width: '100%' }}
              allowClear
            >
              {types &&
                types.length &&
                types.map(type => (
                  <Option value={type}>{capitalize(type)}</Option>
                ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              placeholder="Select Status"
              onChange={d => setStatus(d)}
              value={selectedStatus}
              style={{ width: '100%' }}
              allowClear
            >
              {statuses &&
                statuses.length &&
                statuses.map(status => (
                  <Option value={status}>{capitalize(status)}</Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <RangePicker
              value={selectedDateRange}
              onChange={handleDateChange}
              allowClear
            />
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          gutter={10}
          style={{ marginTop: '10px' }}
        >
          <Col span={5}>
            <Button
              disabled={!isFilterButtonEnabled}
              type="primary"
              onClick={() => handleApplyFilters()}
              style={{ width: '100%' }}
            >
              <Icon type="filter" />
              Apply Filters
            </Button>
          </Col>
          <Col span={4}>
            <Button
              disabled={!isFilterButtonEnabled}
              onClick={() => handleClearFiltersClick()}
              style={{ width: '100%' }}
            >
              <Icon type="sync" />
              Clear All
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <Content>
        <ProcessAlertService
          processHistories={processHistories}
          count={count}
          loading={loading}
          onChange={({ current: page }) => handlePageNumber(page)}
          page={selectedPage}
          hubUrls={hubUrls}
          openHub={openHub}
        />
        {currentModal === ProcessAlertServiceTriggerModal ? (
          <TriggerModal
            id={ProcessAlertServiceTriggerModal}
            onSave={runCronJob}
            onCancel={() => setCurrentModal(null)}
            processTypes={types}
            cronJobLoading={cronJobLoading}
          />
        ) : null}
      </Content>
    </>
  );
};

const mapDispatchToProps = {
  getProcessHistories: getProcessHistoriesAction,
  runCronJob: runCronJobAction,
  setCurrentModal: setCurrentModalAction,
  openHub: openHubAction,
};

const mapStateToProps = ({
  processAlertService: {
    processHistories,
    types,
    statuses,
    count,
    loading,
    cronJobLoading,
    currentModal,
    hubUrls,
  },
}) => ({
  processHistories,
  types,
  statuses,
  count,
  loading,
  cronJobLoading,
  currentModal,
  hubUrls,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProcessAlertServiceContainer)
);
