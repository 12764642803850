import React from 'react';
import { connect } from 'react-redux';
import './usertypeSettings.scss';
import UsertypeSettingHeader from './components/UsertypeSettingHeader';
import HubsTable from './components/HubsTable';

function UsertypeSettings() {
  return (
    <div className="usertype_settings">
      <UsertypeSettingHeader />
      <HubsTable />
    </div>
  );
}

export default connect(
  null,
  null
)(UsertypeSettings);
