import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { authenticateUser } from './actions/auth';
import Routes from './Routes';
import Alert from './containers/Alert/Alert';
import { fetchClusterHubs } from './actions/clusterHubs';

import './App.sass';
import { getItem } from './utils/localStorageService';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  // eslint-disable-next-line import/extensions
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(d => ({
    default: d.ReactQueryDevtools,
  }))
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDevtools: false,
    };
  }

  componentDidMount() {
    const { getClusterHubs, auth, verifyUser } = this.props;
    this.loadGoogleApiScript();
    const isLoggedIn = getItem('isLoggedIn');
    if (isLoggedIn) {
      verifyUser();
    }
    this.checkAdminToken();
    if (auth.authStatus === 'signin') {
      getClusterHubs();
    }
    window.toggleDevtools = this.toggleDevtools;
  }

  toggleDevtools = () => {
    this.setState(prevState => ({
      showDevtools: !prevState.showDevtools,
    }));
  };

  loadGoogleApiScript = () => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/client.js';
    document.body.appendChild(script);
  };

  checkAdminToken = () => {
    const { location, history, verifyUser } = this.props;
    const parsed = queryString.parse(location.search);

    if (parsed && parsed.adminToken) {
      history.push(location.pathname);
      verifyUser();
    }
  };

  render() {
    const { showDevtools } = this.state;
    return (
      <div className="App">
        <ReactQueryDevtools />
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        )}
        <Routes />
        <Alert />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  verifyUser: authenticateUser,
  getClusterHubs: fetchClusterHubs,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
