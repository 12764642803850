import { request } from '../utils/request';

export const saveBannerConfigurationApi = params =>
  request.post('/admin/banner', params);
export const updateBannerConfigurationApi = params =>
  request.put(`/admin/banner/${params.id}`, params);
export const fetchConfigurationApi = () => request.get('/admin/banner');
export const fetchConfigurationBasedOnIdApi = id =>
  request.get(`/admin/banner/${id}`);
export const deleteBannerConfigurationApi = id =>
  request.delete(`/admin/banner/${id}`);
export const getAllUsedCustomImagesApi = () =>
  request.get(`/admin/banner-all-custom-images`);
