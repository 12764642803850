import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Switch, Row, Col, Modal, Spin, Icon, Tabs, Input } from 'antd';
import {
  uniqBy,
  map,
  isObject,
  upperFirst,
  findIndex,
  cloneDeep,
  filter,
} from 'lodash';

import variations from '../../../constants/defaultSettingVariationConstants';

import { setCompassData } from '../actions';

import '../index.scss';
import useGetHubListQuery from '../query/useGetHubListQuery';

const { TabPane } = Tabs;
const { Search } = Input;

function HubSelectionModal(props) {
  const dispatch = useDispatch();

  const [selectedHubs, setSelectedHubs] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [searchBarText, setSearchBarText] = useState('');
  const [hubSearch, setHubSearch] = useState([]);

  const { saveLaunchIn, launchIn = {} } = props;

  const { showHubSelectionModal } = useSelector(
    ({ compassReducer }) => compassReducer
  );

  const { data: hubList, isLoading: isHubListLoading } = useGetHubListQuery();

  const buildSearchOption = hubs => {
    const [item = undefined] = hubs;
    if (item && isObject(item)) return hubs;
    if (hubList && hubList.length) {
      return map(
        hubList.filter(hub => hubs.indexOf(hub.id) !== -1),
        hub => ({
          key: hub.id,
          label: `${hub.name} (ID:${hub.id})`,
        })
      );
    }

    return [];
  };

  const initializeState = () => {
    const { selectedHubs: hubs = [], selectedGroups: groups = [] } = launchIn;
    setSelectedGroups(groups);
    setSelectedHubs(buildSearchOption(hubs));
  };

  useEffect(() => {
    initializeState();
  }, []);

  const closeModal = () => {
    dispatch(setCompassData({ showHubSelectionModal: false }));
  };

  const saveSelectedHubs = () => {
    saveLaunchIn({
      selectedGroups,
      selectedHubs: selectedHubs.map(h => h.key),
    });
    closeModal();
  };

  const handleChange = item => {
    setSelectedHubs(uniqBy(selectedHubs.concat(item), 'key'));
    setHubSearch([]);
  };

  const handleSearch = text => {
    let result = [];
    setSearchBarText(text);
    const searchText = text && text.toLowerCase();
    if (searchText && hubList && hubList.length) {
      result = hubList
        .filter(hub => {
          const { name, domain, identifier, id } = hub;

          const index = findIndex(selectedHubs, { key: id });
          if (index === -1) return false;

          if (
            (name && name.toLowerCase().includes(searchText)) ||
            (domain && domain.toLowerCase().includes(searchText)) ||
            (identifier && identifier.toLowerCase().includes(searchText)) ||
            (id && id.toString().toLowerCase().includes(searchText))
          )
            return true;

          return false;
        })
        ?.map(({ id }) => id);
    }
    setHubSearch(buildSearchOption(result));
  };

  const handleLaunchGroupSelection = (launchGroup, add = false) => {
    const hubsArray = filter(hubList, { defaultSetting: launchGroup });
    if (add) {
      const hubLabels = map(hubsArray, hub => ({
        key: hub.id,
        label: `${hub.name} (ID:${hub.id})`,
      }));
      handleChange(hubLabels);
      return;
    }
    const hubIdsArray = map(hubsArray, 'id');
    setSelectedHubs(
      filter(selectedHubs, hub => hubIdsArray.indexOf(hub.key) === -1)
    );
  };

  const handleGroupChange = (key, eventState) => {
    if (eventState && selectedGroups.indexOf(key) === -1) {
      setSelectedGroups(selectedGroups.concat([key]));
      handleLaunchGroupSelection(key, true);
      return;
    }
    setSelectedGroups(selectedGroups.filter(item => item !== key));
    handleLaunchGroupSelection(key, false);
  };

  const handleDeleteHub = hubId => {
    if (!hubId) return;
    const index = findIndex(selectedHubs, { key: hubId });
    if (index !== -1) {
      const hubs = cloneDeep(selectedHubs);
      const filteredHubSearch = hubSearch?.filter(({ key }) => key !== hubId);
      hubs.splice(index, 1);
      setSelectedHubs(hubs);
      setHubSearch(filteredHubSearch);
    }
  };

  const renderLaunchGroupSwitch = group => {
    if (selectedGroups && selectedGroups.indexOf(group.key) === -1) {
      return (
        <Switch
          onChange={() => handleGroupChange(group.key, 'add')}
          checked={selectedGroups && selectedGroups.indexOf(group.key) !== -1}
        />
      );
    }
    return (
      <Switch
        checked={selectedGroups && selectedGroups.indexOf(group.key) !== -1}
        onChange={eventState => handleGroupChange(group.key, eventState)}
      />
    );
  };

  return (
    <Modal
      visible={showHubSelectionModal}
      className="provisioning_launch_modal"
      title="Launch Groups & Hubs Selection"
      destroyOnClose
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={false}
          onClick={saveSelectedHubs}
        >
          Save
        </Button>,
      ]}
    >
      {isHubListLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin
            tip="Fetching hubs and pathways"
            size="small"
            indicator={<Icon type="loading" spin />}
          />
        </div>
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Launch Groups" key="1">
            <div className="provisioning_launch_modal__header">
              All Launch Groups
            </div>
            <Row className="provisioning_launch_modal__row">
              {variations.map(v => (
                <div key={v.key}>
                  <Col span={20}>{v.name}</Col>
                  <Col span={4}>{renderLaunchGroupSwitch(v)}</Col>
                </div>
              ))}
            </Row>
          </TabPane>
          <TabPane tab="Hubs" key="2">
            <div className="provisioning_launch_modal__header">
              Active Hub(s)
            </div>
            <Search
              placeholder="search hub by name, domain or id"
              value={searchBarText}
              onChange={event => handleSearch(event.target.value)}
              allowClear
              className="compass_launch_modal_list_search"
            />
            {selectedHubs.length ? (
              <div className="provisioning_launch_modal__list compass_launch_modal_list_container">
                {(searchBarText.length ? hubSearch : selectedHubs).map(hub => (
                  <div key={hub.key} className="provisioning_launch_modal__hub">
                    <span className="hub_name">
                      {upperFirst(hub.label.toString().replace(/,/g, ''))}
                    </span>

                    <Button
                      type="link"
                      className="action"
                      icon="close"
                      onClick={() => handleDeleteHub(hub.key)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty_state">No Hub Selected!</div>
            )}
          </TabPane>
        </Tabs>
      )}
    </Modal>
  );
}

export default HubSelectionModal;
