import React from 'react';
import { Modal, Input, Radio, Row, Col, Table, Button, AutoComplete } from 'antd';
const { Option } = AutoComplete;
const { confirm } = Modal;

export default class EditAdminTag extends React.Component {
	constructor(props) {
		super(props);
		const { clusterHubs, adminTag } = props;
		const adminTagReferences = adminTag ? adminTag.adminTagReferences : [];
		this.state = {
			adminTag: props.adminTag || {},
			visible: props.visible,
			editing: props.editing,
			adminTagReferencesDataSource: clusterHubs,
			adminTagReferences: adminTagReferences,
			elementToAdd: {},
			autoSearchValue: ''
		};
	}

	_onChange = p => e => {
		let { adminTag } = this.state;
		adminTag[p] = e.target.value;
		this.setState({adminTag});
	}

	_deleteAdminTagReference = data => {
		const { deleteAdminTagReference } = this.props;
		let { adminTagReferences } = this.state;
		deleteAdminTagReference(data);
		adminTagReferences = adminTagReferences.filter(atr => atr.id !== data.id);
		this.setState({ adminTagReferences });
	}
	
	typeOptions = [
		{
			label: 'Hub', value: 'hub',
		},
		{
			label: 'Cluster', value: 'cluster', disabled: true,
		},
		{
			label: 'User', value: 'users', disabled: true,
		}, 
		{
			label: 'Group', value: 'group', disabled: true,
		},
		{
			label: 'Program', value: 'program', disabled: true,
		}
	];

	columns =  [{
		title: 'ID',
		dataIndex: 'objectDetails.id',
		width: 5,
		key: 'id',
	}, {
		title: 'Name',
		dataIndex: 'objectDetails.name',
		key: 'name',
		width: 200,
	}, {
		title: 'Delete',
		dataIndex: 'delete',
		key: 'delete',
		width: 5,
		render: (k, v) => (
			<Button type="danger" icon="delete" onClick={
				() => {
					confirm({
						title: 'Are you sure you want to delete this hub from this admin tag?',
						content: 'This will remove the mapping of this hub from all sources wherever this admin tag is used.',
						onOk: () => {
							this._deleteAdminTagReference(v)
						},
						onCancel() {},
					});
				}
			} />
		),
	}];

	_handleSubmit = () => {
		const { updateAdminTag, setEditMode, addAdminTag } = this.props;
		const { adminTag, editing } = this.state;
		setEditMode(false);
		if (editing) {
			updateAdminTag(adminTag);
		} else {
			addAdminTag(adminTag);
		}
	}

	_handleCancel = () => {
		const { setEditMode } = this.props;
		setEditMode(false)
	}

	_onAdminTagReferenceSelect = objectId => {
		const { clusterHubs } = this.props;
		const { adminTag } = this.state;
		const [elementDetails] = clusterHubs.filter(ch => ch.id.toString() === objectId.toString());
		this.setState({
			elementToAdd: { objectId, objectType: adminTag.type, adminTagsId: adminTag.id, objectDetails: { id: elementDetails.id, name: elementDetails.name } }
		});
	}

	_addAdminTagReference = () => {
		const { addAdminTagReference } = this.props;
		let { adminTagReferences } = this.state;
		adminTagReferences.push(this.state.elementToAdd);
		this.setState({adminTagReferences});
		addAdminTagReference(this.state.elementToAdd);
		this.setState({ autoSearchValue: '' });
	}

	_handleAutoSearchValue = e => {
		this.setState({ autoSearchValue: e });
	}

	render() {
		const { visible, adminTag, editing, adminTagReferences, autoSearchValue } = this.state;
		const isTypeChangeDisabled = editing ? !!adminTag.adminTagReferences.length: false;
		return (
			<Modal
				title={editing ? "Edit AdminTag" : "Add AdminTag"}
				visible={visible}
				onOk={this._handleSubmit}
				onCancel={this._handleCancel}
			>
				<div className="editor-modal">
				<Row className="edit-field">
					<Col span={6}> Name: </Col>
					<Col span={18}><Input defaultValue={editing ? adminTag.name : ''} onChange={this._onChange('name')}/></Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> Description: </Col>
					<Col span={18}> <Input defaultValue={editing ? adminTag.description: ''} onChange={this._onChange('description')}/> </Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> Type </Col>
					<Col span={18}> 
						<Radio.Group disabled={isTypeChangeDisabled} options={this.typeOptions} defaultValue={editing ? adminTag.type: ''} onChange={this._onChange('type')}></Radio.Group>
					</Col>
				</Row>
				<Row  className="edit-field">
					<Col span={6}> Elements </Col>
					{editing ? 
						<Col span={18}>
							<Row>
								<Col span={22}>
									<AutoComplete
										onSelect={this._onAdminTagReferenceSelect}
										style={{ width: '100%' }}
										placeholder={`Search ${editing ? adminTag.type: ''} here`}
										filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}	
										value={autoSearchValue}
										onChange={this._handleAutoSearchValue}
									>
										{this.state.adminTagReferencesDataSource.map(ch =>  <Option key={ch.id} disabled={!!adminTagReferences.find(e => (e.objectType === 'hub' && e.objectId === ch.id))} >{`${ch.name} (ID: ${ch.id})`}</Option>)
										}
									</AutoComplete>
								</Col>
								<Col span={2}>
									<Button icon="plus" type="primary" shape="circle" onClick={this._addAdminTagReference}></Button>
								</Col>
							</Row>
							<Table rowKey={atr => `adminTagReference_${atr.id}`} dataSource={this.state.adminTagReferences} columns={this.columns}  pagination={{pageSize: 5}} />
						</Col>
						:
						<Col span={18}> Hubs or Clusters can be added once you create the Admin tags.</Col>
					}
				</Row>
				</div>
			</Modal>
		)
	}
}