import { SET_ANALYTICS_PROFILE } from '../actions/analyticsProfile';

const initialState = {
  hubs: [],
  loading: false,
  loadingForHubId: null,
};

const analyticsProfile = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ANALYTICS_PROFILE:
      return { ...state, ...data };
    default:
      return state;
  }
};

export default analyticsProfile;
