import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HubManagement from './index';
import HubSave from './HubSave';
import CreateHub from './CreateHub';
import HubConfigs from './HubConfigs';

export default () => {
  return (
    <Switch>
      <Route path={`/hubs/save/:hubId`} component={HubSave} />
      <Route path={`/hubs/new`} component={CreateHub} />
      <Route path={`/hubs/configs`} component={HubConfigs} />
      <Route path={`/hubs`} component={HubManagement} />
    </Switch>
  );
};

