import { SET_ES_LOGSTASH, SET_LOGSTASH_STATUS } from './actions';

const initialState = {
  allAvailableHubs: [],
  hubsLoading: true,
  hubs: [],
  allFiles: false,
  files: [],
  logstashStatus: {
    loading: true,
    status: [],
  },
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ES_LOGSTASH: 
      return { ...state, ...data }
    case SET_LOGSTASH_STATUS: 
      return { ...state, logstashStatus: { ...state.logstashStatus, ...data }}
    default: return state
  }
};
