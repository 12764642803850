import {
  SET_USER_HUB_IDS,
  UPSERT_LOADING,
  SET_NOT_FOUND_IDS,
} from '../actions';

const initialState = {
  userHubIds: '',
  upsertLoading: false,
  notFoundIds: [],
};

const esDocumentReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_USER_HUB_IDS:
      return {
        ...state,
        userHubIds: data,
      };
    case UPSERT_LOADING:
      return {
        ...state,
        upsertLoading: data,
      };
    case SET_NOT_FOUND_IDS:
      return {
        ...state,
        notFoundIds: data,
      };
    default:
      return state;
  }
};

export default esDocumentReducer;
