import React from 'react';
import { Input, Row, Col } from 'antd';
import s from '../AnalyticsReport.module.scss';

class AddUpdateLog extends React.Component {
    render() {
        const { onChange } = this.props
        return (
            <React.Fragment>
                <Row className={`${s['ant-row']}`}>
                    <Row className={`${s['ant-row']}`}>
                        <Col span={7}>
                            <h3>Add Update Log <span className={`${s['required-asterisk']}`}>*</span></h3>
                        </Col>
                        <Col span={17}>
                            <Input
                                placeholder="Reason for Update (format: PG-12345 - 12 May,2022)"
                                onChange={onChange}
                            />
                        </Col>
                    </Row>
                </Row>
            </React.Fragment>
        )
    }
}

export default AddUpdateLog;