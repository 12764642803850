import { STORE_ADMIN_DETAIL } from '../../../actions/admin';
import { SET_ACCESS_MANAGEMENT_OBJ } from  '../actions';

const initialState = {
  adminTotalCount: 30,
  adminCurrentPage: 1,
  adminPageSize: 10,
  groupTotalCount: 30,
  groupCurrentPage: 1,
  groupPageSize: 10,
  admins: [],
  permissions: [],
  groups: []
};

const accessManagement = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ACCESS_MANAGEMENT_OBJ:
      return {
        ...state,
        ...data,
      }
    default: return state
  }
};

export default accessManagement;
