import { useQuery } from '@tanstack/react-query';
import * as hubApi from '../../../api';

const queryKey = 'HubList';

const getHubListsApi = async () => {
  const { data: hubs } = await hubApi.hub.getAll({
    fields: ['id', 'name', 'identifier', 'defaultSetting', 'domain'],
  });
  return hubs;
};

const useGetHubListQuery = () => useQuery([queryKey], () => getHubListsApi());

export default useGetHubListQuery;
