import React from 'react';
import { Alert } from 'antd';
import s from './Alert.module.scss';
import { connect } from 'react-redux';

const AlertComponent = (props) => {
    if (props.alert.show === true) {
        return (
            <div className={s.alert__container}>
                <Alert message={props.alert.message} type={props.alert.type} />
            </div>
        )
    }
    else {
        return null;
    }
}

const mapStateToProps = ({ alert }) => ({
    alert,
});

export default connect(
    mapStateToProps,
    null
)(AlertComponent);
