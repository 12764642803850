import { request } from '../utils/request';

export const authenticateUserAPI = params => request.post('/auth', { params });

export const getCurrentUserAPI = params =>
  request.get('/users/current', { params });

export const logoutUserApi = data => request.get(`/auth/token/logout`, data);

export const verifyAdminRedirectTokenApi = token =>
  request.get('/auth/admin-redirect-token', {
    headers: { 'X-ADMIN-REDIRECT-TOKEN': token },
  });

export const getCSRFTokenApi = () => request.get('/auth/csrf-token');
