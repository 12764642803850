import { takeLatest, put, call } from 'redux-saga/effects';
// import { message } from 'antd';

import {
  GET_COMPANY_INDUSTRY_DATA,
  setCompanyIndustryAction,
  setStandardIndustryAction,
  setCompanyIndustryLoadingAction,
  SAVE_COMPANY_INDUSTRY,
  setCompanyIndustrySavingAction,
  GET_KAGGLE_COMPANIES_MATCHES,
  setIsGettingKaggleCompaniesMatchesAction,
  setKaggleCompaniesMatchesAction,
  DELETE_COMPANY_INDUSTRY,
} from '../actions';
import {
  fetchCompanyIndustryDataApi,
  updateCompanyIndustryApi,
  addCompanyIndustryApi,
  deleteCompanyIndustryApi,
  getKaggleCompaniesMatchesApi,
} from '../../../api';
import { POPUP_ALERT } from '../../../actions/alert';

const { error: errLog } = console;

function* getCompanyIndustryDataSaga({ data: payload = {} }) {
  try {
    // console.log({ payload });
    yield put(setCompanyIndustryLoadingAction(true));
    const { data = {} } = yield call(fetchCompanyIndustryDataApi, {
      ...payload,
      isStandardHub: true,
    });
    yield put(
      setCompanyIndustryAction({
        companyIndustries: data.companyIndustries || [],
        totalCount: data.totalCount,
      })
    );
    yield put(setStandardIndustryAction(data.standardIndustries || []));
    yield put(setCompanyIndustryLoadingAction(false));
  } catch (e) {
    yield put(setCompanyIndustryLoadingAction(false));
    errLog(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error getting company industry data' },
    });
  }
}

function* saveCompanyIndustrySaga({ data: companyIndustry = {} }) {
  try {
    // console.log({ companyIndustry });
    yield put(setCompanyIndustrySavingAction(true));
    if (companyIndustry.id) {
      // Update the existing company industry
      yield call(updateCompanyIndustryApi, {
        id: companyIndustry.id,
        data: companyIndustry,
      });
    } else {
      // Creating new company industry
      yield call(addCompanyIndustryApi, companyIndustry);
    }
    yield put(setCompanyIndustrySavingAction(false));
  } catch (e) {
    yield put(setCompanyIndustrySavingAction(false));
    errLog(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error saving company industry' },
    });
  }
}

function* deleteCompanyIndustrySaga({ data: companyIndustryId }) {
  try {
    // console.log({ companyIndustry });
    yield put(setCompanyIndustrySavingAction(true));
    yield call(deleteCompanyIndustryApi, companyIndustryId);
    yield put(setCompanyIndustrySavingAction(false));
  } catch (e) {
    yield put(setCompanyIndustrySavingAction(false));
    errLog(e);
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error deleting company industry' },
    });
  }
}

function* getKaggleCompaniesMatchesSaga({ data }) {
  try {
    yield put(setIsGettingKaggleCompaniesMatchesAction(true));
    const { companyName } = data;
    if (!companyName) {
      throw new Error('Comapny Not found');
    }

    const { data: kaggleMatches } = yield call(getKaggleCompaniesMatchesApi, {
      companyName,
    });
    yield put(
      setKaggleCompaniesMatchesAction({ [companyName]: kaggleMatches })
    );
    yield put(setIsGettingKaggleCompaniesMatchesAction(false));
  } catch (e) {
    yield put(setIsGettingKaggleCompaniesMatchesAction(false));
    errLog(e);
    yield put({
      type: POPUP_ALERT,
      // eslint-disable-next-line prettier/prettier
      data: {
        type: 'error',
        message:
          e.message || 'Error getting company name matches from Kaggle dataset',
      },
    });
  }
}

const CompanyIndustrySaga = [
  takeLatest(GET_COMPANY_INDUSTRY_DATA, getCompanyIndustryDataSaga),
  takeLatest(SAVE_COMPANY_INDUSTRY, saveCompanyIndustrySaga),
  takeLatest(GET_KAGGLE_COMPANIES_MATCHES, getKaggleCompaniesMatchesSaga),
  takeLatest(DELETE_COMPANY_INDUSTRY, deleteCompanyIndustrySaga),
];

export default CompanyIndustrySaga;
