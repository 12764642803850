import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Col,
  Divider,
  Input,
  PageHeader,
  Row,
  Button,
  Icon,
  Select,
  InputNumber,
} from 'antd';
import { capitalize, map } from 'lodash';
import Content from '../../component/Content';
import {
  getCompanyIndustryDataAction,
  saveCompanyIndustryAction,
  getKaggleComapniesMatchesAction,
  deleteCompanyIndustryAction,
} from './actions';
import { popupAlert as popupAlertAction } from '../../actions/alert';
import CompanyIndustryTable from './CompanyIndustryTable/index';
import CompanyIndustryModal from './CompanyIndustryModal/index';

import './index.scss';

const { Search } = Input;
const { Option } = Select;
const CompanyIndustryModalId = 'CompanyIndustryModal';

const booleanValues = ['true', 'false'];
const sources = [
  {
    value: 'kaggle_company_data',
    name: 'Kaggle',
  },
  {
    value: 'user_clusters',
    name: 'Users',
  },
];

const CompanyIndustryContainer = ({
  getCompanyIndustryData,
  loading,
  totalCount,
  companyIndustries,
  standardIndustries,
  isCompanyIndustrySaving,
  saveCompanyIndustry,
  getKaggleComapniesMatches,
  isGettingKaggleCompaniesMatches,
  kaggleCompaniesMatches,
  popupAlert,
  deleteCompanyIndustry,
}) => {
  const [currentModel, setCurrentModal] = useState();
  const [currentCompanyIndustry, setCurrentCompanyIndustry] = useState();
  const [selectedPage, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [industryFilter, setIndustryFilter] = useState();
  const [isApprovedFilter, setIsApprovedFilter] = useState();
  const [minScoreFilter, setMinScoreFilter] = useState();
  const [maxScoreFilter, setMaxScoreFilter] = useState();
  const [sourceFilter, setSourceFilter] = useState();
  const [updatedByAdminFilter, setUpdatedByAdminFilter] = useState();

  const handleClearFiltersClick = () => {
    setPage(1);
    setSearchText('');
    setIndustryFilter();
    setIsApprovedFilter();
    setMinScoreFilter();
    setMaxScoreFilter();
    setSourceFilter();
    getCompanyIndustryData();
  };

  const handlePageNumber = page => {
    const pageNumber = parseInt(page, 10);
    handleApplyFilters(pageNumber);
  };

  const handleApplyFilters = (page = 1, changePage = true) => {
    // Validation
    if (minScoreFilter && (minScoreFilter < 0 || minScoreFilter > 1)) {
      popupAlert({
        type: 'error',
        message: 'Min Score value shold be between 0 and 1',
      });
      return;
    }
    if (maxScoreFilter && (maxScoreFilter < 0 || maxScoreFilter > 1)) {
      popupAlert({
        type: 'error',
        message: 'Max Score value shold be between 0 and 1',
      });
      return;
    }
    if (maxScoreFilter && minScoreFilter && minScoreFilter > maxScoreFilter) {
      popupAlert({
        type: 'error',
        message: 'Min score value should be less than Max score value',
      });
      return;
    }

    if (changePage) {
      setPage(page);
    }
    const payload = {
      ...{ page: changePage ? page : selectedPage },
      ...(searchText && { companyName: searchText }),
      ...(industryFilter && {
        industry: industryFilter,
      }),
      ...(booleanValues.includes(isApprovedFilter) && {
        isApproved: isApprovedFilter,
      }),
      ...(minScoreFilter &&
        minScoreFilter !== 0 && {
          minScore: minScoreFilter,
        }),
      ...(maxScoreFilter &&
        maxScoreFilter !== 1 && {
          maxScore: maxScoreFilter,
        }),
      ...(sourceFilter && {
        source: sourceFilter,
      }),
      ...(booleanValues.includes(updatedByAdminFilter) && {
        updatedByAdmin: updatedByAdminFilter,
      }),
    };
    getCompanyIndustryData(payload);
  };

  useEffect(() => {
    if (!isCompanyIndustrySaving) {
      handleApplyFilters(null, false);
      setCurrentModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanyIndustrySaving]);

  const isFilterButtonEnabled =
    !!searchText ||
    !!industryFilter ||
    booleanValues.includes(isApprovedFilter) ||
    (minScoreFilter && minScoreFilter !== 0) ||
    (maxScoreFilter && maxScoreFilter !== 1) ||
    !!sourceFilter ||
    booleanValues.includes(updatedByAdminFilter);

  return (
    <>
      <PageHeader
        title="Company Industry (For Standard hubs only)"
        subTitle="List/Update/Delete Industry for the Job Posts related companies"
      >
        <Divider />
        <Row
          type="flex"
          justify="space-around"
          gutter={8}
          className="company-industry-filters"
        >
          <Col span={4}>
            <Search
              placeholder="Company Name"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onSearch={searchTerm => handleApplyFilters()}
              allowClear
            />
          </Col>
          <Col span={5}>
            <Select
              placeholder="Select Standard Indsutry"
              onChange={d => setIndustryFilter(d)}
              value={industryFilter}
              style={{ width: '100%' }}
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {map(standardIndustries, industry => (
                <Option key={industry.value} value={industry.value}>
                  {industry.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              placeholder="Is Approved"
              onChange={d => setIsApprovedFilter(d)}
              value={isApprovedFilter}
              style={{ width: '100%' }}
              allowClear
            >
              {map(booleanValues, val => (
                <Option key={val === 'true'} value={val}>
                  {capitalize(val)}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <InputNumber
              placeholder="Min Score"
              value={minScoreFilter}
              onChange={val => setMinScoreFilter(val)}
              allowClear
              style={{ width: '50%' }}
            />
            <InputNumber
              placeholder="Max Score"
              value={maxScoreFilter}
              onChange={val => setMaxScoreFilter(val)}
              allowClear
              style={{ width: '50%' }}
            />
          </Col>
          <Col span={5}>
            <Select
              placeholder="Select Source"
              onChange={d => setSourceFilter(d)}
              value={sourceFilter}
              style={{ width: '100%' }}
              allowClear
            >
              {map(sources, source => (
                <Option key={source.value} value={source.value}>
                  {source.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={5}>
            <Select
              placeholder="Update By Admin"
              onChange={d => setUpdatedByAdminFilter(d)}
              value={updatedByAdminFilter}
              style={{ width: '100%' }}
              allowClear
            >
              {map(booleanValues, val => (
                <Option key={val === 'true'} value={val}>
                  {capitalize(val)}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          gutter={10}
          style={{ marginTop: '10px' }}
        >
          <Col span={5}>
            <Button
              type="primary"
              onClick={() => handleApplyFilters()}
              style={{ width: '100%' }}
            >
              <Icon type="filter" />
              Apply Filters
            </Button>
          </Col>
          <Col span={4}>
            <Button
              disabled={!isFilterButtonEnabled}
              onClick={() => handleClearFiltersClick()}
              style={{ width: '100%' }}
            >
              <Icon type="sync" />
              Clear All
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <Content>
        <CompanyIndustryTable
          loading={loading}
          count={totalCount}
          page={selectedPage}
          companyIndustries={companyIndustries}
          standardIndustries={standardIndustries}
          onEdit={data => {
            setCurrentCompanyIndustry(data);
            setCurrentModal(CompanyIndustryModalId);
          }}
          handlePageNumber={handlePageNumber}
          onDelete={deleteCompanyIndustry}
        />
        {currentModel === CompanyIndustryModalId ? (
          <CompanyIndustryModal
            id={CompanyIndustryModalId}
            companyIndustry={currentCompanyIndustry}
            standardIndustries={standardIndustries}
            onSave={saveCompanyIndustry}
            onCancel={() => setCurrentModal(null)}
            loading={isCompanyIndustrySaving}
            getKaggleComapniesMatches={getKaggleComapniesMatches}
            isGettingKaggleCompaniesMatches={isGettingKaggleCompaniesMatches}
            kaggleCompaniesMatches={kaggleCompaniesMatches}
          />
        ) : null}
      </Content>
    </>
  );
};

const mapDispatchToProps = {
  getCompanyIndustryData: getCompanyIndustryDataAction,
  saveCompanyIndustry: saveCompanyIndustryAction,
  getKaggleComapniesMatches: getKaggleComapniesMatchesAction,
  popupAlert: popupAlertAction,
  deleteCompanyIndustry: deleteCompanyIndustryAction,
};

const mapStateToProps = ({
  CompanyIndustry: {
    loading,
    totalCount,
    companyIndustries,
    standardIndustries,
    isCompanyIndustrySaving,
    isGettingKaggleCompaniesMatches,
    kaggleCompaniesMatches,
  },
}) => ({
  loading,
  totalCount,
  companyIndustries,
  standardIndustries,
  isCompanyIndustrySaving,
  isGettingKaggleCompaniesMatches,
  kaggleCompaniesMatches,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyIndustryContainer)
);
