import React from 'react';
import { Table, Button, Divider, Popover, Popconfirm } from 'antd';
import { get, isEmpty } from 'lodash';

import './company-industry-table.scss';

const getIsApprovedColor = isApproved => (isApproved ? '#36b37e' : '#ff5630');

const SourceColumn = ({ sourceName, sourceInfo = {} }) => {
  let content = null;
  let name = sourceName;

  if (sourceName === 'kaggle_company_data') {
    name = 'Kaggle';
    content = (
      <div>
        <div>
          <strong>Company Name:</strong> {sourceInfo.companyName}
        </div>
        <div>
          <strong>Generic Industry:</strong> {sourceInfo.genericIndustry}
        </div>
      </div>
    );
  } else if (sourceName === 'user_clusters') {
    name = 'User';
    content = (
      <div>
        <div>
          <strong>Name:</strong> {sourceInfo.firstName} {sourceInfo.lastName}
        </div>
        <div>
          <strong>Email:</strong> {sourceInfo.email}
        </div>
      </div>
    );
  }

  return (
    <Popover content={content} title={`${name} info`}>
      <span>{name}</span>
    </Popover>
  );
};

const CompanyIndustryTable = ({
  loading,
  count,
  page,
  companyIndustries,
  standardIndustries = [],
  onEdit,
  handlePageNumber,
  onDelete = () => {},
}) => {
  if (!loading && isEmpty(companyIndustries)) {
    return <div>No Company Industry found.</div>;
  }

  const getStandardIndustryName = id => {
    const industry = standardIndustries.find(ind => ind.value === id);
    if (industry) {
      return industry.name;
    }
    return 'Invalid Industry';
  };

  const pageSize = 10;
  const columns = [
    {
      title: 'Standard Industry',
      dataIndex: 'industry',
      key: 'industry',
      render: t => <span>{getStandardIndustryName(t)}</span>,
    },
    {
      title: 'Compnay Name',
      dataIndex: 'name',
      key: 'name',
      render: (t, r) => <span>{get(r, 'company.title')}</span>,
    },
    {
      title: 'Is Approved',
      dataIndex: 'isApproved',
      key: 'isApproved',
      render: (t, r) => (
        <div>
          <span
            style={{
              color: getIsApprovedColor(r.isApproved),
              fontWeight: 'bold',
            }}
          >
            {r.isApproved ? 'True' : 'False'}
          </span>
        </div>
      ),
    },
    {
      title: 'Confidence',
      dataIndex: 'confidence',
      key: 'confidence',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (t, r) => (
        <SourceColumn sourceName={r.source} sourceInfo={r.sourceInfo} />
      ),
    },
    {
      title: 'Updated By Admin',
      dataIndex: 'updatedByAdmin',
      key: 'updatedByAdmin',
      render: (t, r) => (
        <div>
          <span
            style={{
              color: getIsApprovedColor(r.updatedByAdmin),
              fontWeight: 'bold',
            }}
          >
            {r.updatedByAdmin ? 'True' : 'False'}
          </span>
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      render: (t, r) => (
        <div>
          <Button type="primary" icon="edit" onClick={() => onEdit(r)} />
          <Divider type="vertical" />
          <Popconfirm
            title={
              <>
                <div className="analytics_profile__popover__header">
                  Are you sure you want to delete this company industry?
                </div>
              </>
            }
            onConfirm={() => onDelete(r.id)}
          >
            <Button type="danger" icon="delete" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="company-industry-table-wrapper">
      <Table
        className="company-industry-table__table"
        scroll={{
          x: 'max-content',
        }}
        loading={loading}
        columns={columns}
        dataSource={companyIndustries.map(ph => ({ ...ph, key: ph.id }))}
        pagination={{
          simple: false,
          total: Math.floor(count / pageSize) * pageSize,
          pageSize,
          current: page,
        }}
        onChange={({ current: pageNum }) => handlePageNumber(pageNum)}
      />
    </div>
  );
};

export default CompanyIndustryTable;
