import React, { PureComponent } from 'react';
import { Select } from 'antd';
import { has, map } from 'lodash';
import s from './MultiSelect.module.scss';

const { Option, OptGroup } = Select;

class MultiSelect extends PureComponent {
  constructor(props) {
    super(props);

    const value = props.value || [];
    this.state = {
      value,
      showSelected: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        value: nextProps.value || [],
      };
    }
    return null;
  }

  triggerChange = changedValue => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange([...changedValue]);
    }
  };

  handleChange = value => {
    const { dataSource } = this.props;

    if (!dataSource) return;

    switch (value) {
      case 'all': {
        const allVal = dataSource
          .map(option => option.name)
          .filter(name => name !== 'Default');
        const allValKeys = dataSource
          .map(option => option.key || option.value)
          .filter(key => key !== 'default');

        if (!('value' in this.props)) {
          this.setState({ value: allVal });
        }
        this.triggerChange(allValKeys);
        break;
      }
      case 'clear':
        if (!('value' in this.props)) {
          this.setState({ value: [] });
        }
        this.triggerChange([]);
        break;
      default:
        if (!('value' in this.props)) {
          this.setState({ value });
        }
        this.triggerChange(value);
        break;
    }
  };

  DeselectAll = () => (
    <span
      className={s['multiselect__control-button']}
      onClick={() => this.handleChange('clear')}
    >
      De-select All
    </span>
  );

  SelectAll = () => (
    <span
      className={s['multiselect__control-button']}
      onClick={() => this.handleChange('all')}
    >
      Select All
    </span>
  );

  // Controls = () => {
  //   return (

  //   );
  // };

  getOptions = () => {
    const { dataSource } = this.props;
    const { showSelected, value } = this.state;
    const children = [];

    map(dataSource, (option, key) => {
      let valueKey;
      if (has(option, 'key')) valueKey = option.key;
      else if (has(option, 'value')) valueKey = option.value;

      if (
        typeof option === 'object' &&
        has(option, 'name') &&
        valueKey !== undefined
      ) {
        if (option.key !== 'default') {
          if (!showSelected || value.indexOf(valueKey) > -1) {
            children.push(
              <Option key={key} value={valueKey}>
                {option.name}
              </Option>
            );
          } else if (!showSelected || value.indexOf(option) > -1) {
            children.push(<Option key={option}>{option}</Option>);
          }
        }
      }
    });

    if (children.length === 0)
      children.push(
        <Option key="0" disabled={true}>
          No Option Available
        </Option>
      );

    return children;
  };

  render() {
    const { value } = this.state;
    const {
      placeholder = 'Filter by Launch Group',
      style = { width: '80%' },
      className = '',
    } = this.props;
    return (
      <Select
        style={style}
        mode="multiple"
        value={value}
        onChange={this.handleChange}
        placeholder={placeholder}
        className={className}
      >
        <OptGroup
          label={
            <div className={s['multiselect__controls']}>
              <span className={s['left']}>
                <b>Select:</b> <this.DeselectAll /> | <this.SelectAll />
              </span>
            </div>
          }
        >
          {this.getOptions()}
        </OptGroup>
      </Select>
    );
  }
}

export default MultiSelect;
