import React, { useEffect } from 'react';
import { Table, PageHeader, Card } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import { fetchSessions, deleteSession } from './actions';
import Content from '../../component/Content';

const SessionManagement = props => {
  const {
    isPageLoading,
    sessions = [],
    deleteSession: deleteSessionAction,
    fetchSessions: fetchSessionsAction,
  } = props;

  useEffect(() => {
    fetchSessionsAction();
  }, []);

  const columns = [
    {
      title: 'Hub ID',
      dataIndex: 'hubId',
      key: 'hubId',
    },
    {
      title: 'Cluster ID',
      dataIndex: 'clusterId',
      key: 'clusterId',
    },
    {
      title: 'Cluster',
      dataIndex: 'clusterName',
      key: 'clusterName',
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      key: 'dateAndTime',
      render: createdAt => moment(createdAt).format('DD-MM-YYYY, hh:mm:ss A'),
    },
    {
      title: 'Action',
      key: 'action',
      render: ({ id }) => {
        return (
          <a onClick={() => deleteSessionAction(id)} role="button">
            Delete
          </a>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader title="Session Management" />
      <Content>
        <Card bordered={false}>
          <Table
            columns={columns}
            dataSource={sessions}
            loading={isPageLoading}
          />
        </Card>
      </Content>
    </div>
  );
};

const mapStateToProps = ({ session }) => {
  const { sessions, isPageLoading } = session;
  return {
    sessions,
    isPageLoading,
  }
};

const mapDispatchToProps = {
  fetchSessions,
  deleteSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionManagement);
