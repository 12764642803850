import { call, put } from 'redux-saga/effects';

import notify, { notifyError } from '../../../utils/notify';
import * as api from '../../../api';
import { setHubsLoading, setHubsData, setAllInvitedHubsData } from '../actions';

export function* getAllBaInvitedHubs() {
  try {
    yield put(setAllInvitedHubsData({ loading: true }));
    const result = yield call(api.hub.getAllBaInvitedHubs);
    yield put(setAllInvitedHubsData({ data: result.data, loading: false }));
  } catch (e) {
    yield put(setAllInvitedHubsData({ loading: false }));
    notifyError(e, false, 'Error fetching all hubs');
  }
}

export function* fetchAllHubsOfCluster(data) {
  try {
    yield put(setHubsLoading(true));
    const result = yield call(api.hub.getAllHubsOfCluster, data.id);
    yield put(setHubsData(result.data));
  } catch (e) {
    notifyError(e, false, 'Error fetching templates');
  }
  yield put(setHubsLoading(false));
}

export function* updateBAInvitedHubs({ data }) {
  try {
    yield put(setAllInvitedHubsData({ loading: true }));
    yield call(api.hub.updateBAInvitedHubs, { selectedHubs: data });
    notify('Invited Hubs updated successfully', 'success');
    yield call(getAllBaInvitedHubs);
  } catch (e) {
    notifyError(e, false, 'Error fetching templates');
    yield put(setAllInvitedHubsData({ loading: false }));
  }
}

export function* getAllBaInvitedHubsDownloadCSV() {
  try {
    const res = yield api.hub.downloadAllBaInvitedHubsCSV();
    const encodedUri = `data:application/csv;charset=utf-8, ${encodeURIComponent(
      res.data
    )}`;
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Invited Universities.csv');
    link.innerHTML = 'Invited Universities';
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    notifyError(e, false, 'Error in downloading csv data');
  }
}
