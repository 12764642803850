import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';

import ProductUpdateTable from './ProductUpdateTable';
import ProductUpdateForm from './ProductUpdateForm';
import { fetchIndvProductUpdatePost as fetchIndvProductUpdatePostAction } from '../../actions/productUpdates';

const ConfigureProductReleasePosts = ({
  match,
  fetchIndvProductUpdatePost,
}) => {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (
      match.path === '/product-releases/new' ||
      match.path.indexOf('/product-releases/edit') > -1
    ) {
      if (match.params && match.params.id) {
        fetchIndvProductUpdatePost({ id: match.params.id });
      }
      setShowForm(true);
    }
    if (match.path === '/product-releases') {
      setShowForm(false);
    }
  }, [match, fetchIndvProductUpdatePost]);

  return (
    <Row gutter={16}>
      <Col span={12}>
        <ProductUpdateTable />
      </Col>
      <Col span={12}>{showForm && <ProductUpdateForm />}</Col>
    </Row>
  );
};

ConfigureProductReleasePosts.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  fetchIndvProductUpdatePost: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchIndvProductUpdatePost: fetchIndvProductUpdatePostAction,
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ConfigureProductReleasePosts)
);
