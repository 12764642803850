import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import TabBody from './shared/TabBody';

const { TabPane } = Tabs;

class Table extends Component {
  handleTabChange = activeKey => {
    const { history, manageType } = this.props;

    history.replace(`/groups-programs-tier/manage-${manageType}/${activeKey}`);
  };

  render() {
    const { match } = this.props;
    const { type } = match.params;

    return (
      <div className='manage-table-container'>
        <Tabs
          type="card"
          activeKey={type}
          onChange={this.handleTabChange}
        >
          <TabPane tab="Groups" key="group">
            <TabBody />
          </TabPane>
          <TabPane tab="Programs" key="program">
            <TabBody />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const { manageType } = groupsManage;

  return {
    manageType
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Table)
);
