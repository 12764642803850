import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Table,
  PageHeader,
  Card,
  Divider,
  Input,
  Row,
  Col,
  Icon,
} from 'antd';
import { get, find } from 'lodash';
import {
  cancelEdit as cancelEditAction,
  editHub as editHubAction,
  fetchAllHubs as fetchAllHubsAction,
  fetchLaunchGroupChangeLog as fetchLaunchGroupChangeLogAction,
  filterHubs as filterHubsAction,
  requestDeleteHub as requestDeleteHubAction,
  saveHub as saveHubAction,
  setLaunchGroupChangeLog as setLaunchGroupChangeLogAction,
} from './actions';
import { fetchGroupHubsCount as fetchGroupHubsCountAction } from '../FeatureManagement/actions';
import { fetchAllClusters as fetchAllClustersAction } from '../ClusterManagement/actions';
import Content from '../../component/Content';
import EditModalForm from './EditModal';
import ChangeLogModal from './ChangeLogModal';
import MultiSelect from '../../component/MultiSelect';
import Select from '../../component/Select';
import variations from '../../constants/defaultSettingVariationConstants';
import s from './index.module.sass';

const { Search } = Input;

class HubManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hubSearch: '',
      launchGroupsSelected: [],
      featureSelected: '',
      openChangeLogModal: false,
    };
  }

  componentDidMount() {
    const { clusters, fetchAllClusters, fetchAllHubs, fetchGroupHubsCount } =
      this.props;

    fetchAllHubs();
    fetchGroupHubsCount('hub');
    if (!clusters || !clusters.length) fetchAllClusters();
  }

  saveHub = hubId => {
    const { history } = this.props;
    history.push(`/hubs/save/${hubId}`);
  };

  createNewHub = () => {
    const { history } = this.props;
    history.push(`/hubs/new`);
  };

  viewHubConfigs = () => {
    const { history } = this.props;
    history.push(`/hubs/configs`);
  };

  handleKeyPress = event => {
    if (event.keyCode === 13) {
      event.target.click();
      if (event.stopImmediatePropagation) event.stopImmediatePropagation();
    }
  };

  renderActions = hubId => (
    <span>
      <span
        tabIndex={0}
        role="button"
        className={s.actionDisplay}
        onClick={() => this.saveHub(hubId)}
        onKeyDown={this.handleKeyPress}
      >
        Save Configuration{' '}
      </span>
      <Divider type="vertical" />
      <span
        tabIndex={0}
        role="button"
        className={s.actionDisplay}
        onClick={() => this.handleEditModal(hubId)}
        onKeyDown={this.handleKeyPress}
      >
        Edit{' '}
      </span>
      <Divider type="vertical" />
    </span>
  );

  renderTitle = (name, row) => (
    <div>
      <span className={s.name}>{name}</span>
      <span className={s.identifier}>{row.identifier}</span>
    </div>
  );

  renderCluster = clusterHubs => {
    const { clusters } = this.props;
    const clusterId = get(clusterHubs, '0.cluster') || get(clusterHubs, '0');

    let cluster;
    if (clusterId && clusters && clusters.length)
      cluster = clusters.find(c => c.id === clusterId);

    return get(cluster, 'name', '');
  };

  handleEditModal = hubId => {
    const { fetchGroupHubsCount, editHub } = this.props;
    fetchGroupHubsCount('hub');
    editHub(hubId);
  };

  openChangeLogModal = id => {
    const { fetchLaunchGroupChangeLog } = this.props;
    this.setState({ openChangeLogModal: true });
    fetchLaunchGroupChangeLog({ id, source: 'Hub' });
  };

  closeChangeLogModal = () => {
    const { setLaunchGroupChangeLog } = this.props;
    this.setState({ openChangeLogModal: false });
    setLaunchGroupChangeLog();
  };

  renderLaunchGroup = (defaultSetting, row) => (
    <span>
      <p>{this.renderLaunchGroupValue(defaultSetting)}</p>
      <span
        tabIndex={0}
        role="button"
        className={s.actionDisplay}
        onClick={() => this.openChangeLogModal(row.id)}
        onKeyDown={this.handleKeyPress}
      >
        Change Log
      </span>
    </span>
  );

  renderLaunchGroupValue = variationKey => {
    const currentVariation = find(variations, { key: variationKey });

    return <span>{get(currentVariation, 'name', '')}</span>;
  };

  renderTitle = () => <h3>Hubs</h3>;

  handleCancel = () => {
    const { cancelEdit } = this.props;
    cancelEdit();
  };

  handleSave = hub => {
    const { saveHub } = this.props;
    const hubToSave = { ...hub, clusters: [hub.clusters] };
    saveHub(hubToSave);
  };

  handleRequestDelete = (hubId, reason) => {
    const { requestDeleteHub } = this.props;
    requestDeleteHub({ hubId, reason });
  };

  handleSearchChange = e => this.setState({ hubSearch: e.target.value });

  handleNameSearch = () => {
    this.handleHubFilters();
  };

  handleLaunchGroupFilterChange = launchGroupsSelected =>
    this.setState({ launchGroupsSelected });

  handleFeatureFilterChange = featureSelected =>
    this.setState({ featureSelected });

  handleHubFilters = () => {
    const { hubSearch, launchGroupsSelected, featureSelected } = this.state;
    const { filterHubs, modulesList } = this.props;
    let featureObj = {};
    if (featureSelected !== '') {
      const [key, value] = featureSelected.split('#$#');
      const data = find(modulesList, { key });
      if (value === 'enabled')
        featureObj = {
          key,
          value: true,
          filterKey: get(data, 'otherSettings.filterKey', 'enabled'),
        };
      else if (value === 'disabled')
        featureObj = {
          key,
          value: false,
          filterKey: get(data, 'otherSettings.filterKey', 'enabled'),
        };
    }
    filterHubs({
      nameSearch: hubSearch,
      launchGroupsFilter: launchGroupsSelected,
      featureFilter: featureObj,
    });
  };

  filterButtonEnabled = () => {
    const { hubSearch, launchGroupsSelected, featureSelected } = this.state;
    if (
      hubSearch === '' &&
      launchGroupsSelected.length === 0 &&
      featureSelected === ''
    )
      return false;
    return true;
  };

  clearAllFilters = () => {
    const { fetchAllHubs } = this.props;
    this.setState({
      hubSearch: '',
      launchGroupsSelected: [],
      featureSelected: '',
    });
    fetchAllHubs();
  };

  // eslint-disable-next-line react/sort-comp
  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cluster',
      dataIndex: 'clusters',
      key: 'cluster',
      render: this.renderCluster,
    },
    {
      title: 'Launch Group',
      dataIndex: 'defaultSetting',
      key: 'defaultSetting',
      render: this.renderLaunchGroup,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      width: 350,
      render: this.renderActions,
    },
  ];

  render() {
    const {
      cancelEdit,
      changeLogs,
      changeLogsLoading,
      clusters,
      elOrganizations,
      fetchAllClusters,
      filtersEnabled,
      groupHubsCount,
      hubs,
      hubsLoading: loading,
      modulesList,
    } = this.props;
    const {
      featureSelected,
      hubSearch,
      launchGroupsSelected,
      openChangeLogModal,
    } = this.state;
    const isFilterButtonEnabled = this.filterButtonEnabled();

    return (
      <div>
        <PageHeader
          title="Hub Management"
          extra={[
            <Button key="view" onClick={this.viewHubConfigs}>
              {' '}
              View Hub Configs{' '}
            </Button>,
            <Button
              icon="plus-circle"
              key="copy"
              onClick={this.createNewHub}
              type="primary"
            >
              {' '}
              Add a Hub{' '}
            </Button>,
          ]}
        >
          <Divider />
          <Row type="flex">
            <Col span={6}>
              <Search
                onChange={this.handleSearchChange}
                onSearch={this.handleNameSearch}
                placeholder="Search By Name, Hub ID"
                style={{ width: '75%' }}
                value={hubSearch}
              />
            </Col>
            <Col span={6}>
              <MultiSelect
                dataSource={variations}
                onChange={this.handleLaunchGroupFilterChange}
                optionFilterProp="children"
                value={launchGroupsSelected}
              />
            </Col>
            <Col span={12}>
              <Select
                dataSource={modulesList}
                onChange={this.handleFeatureFilterChange}
                placeholder="Filter by Features"
                value={featureSelected}
              />
              <Divider type="vertical" />
              <Button
                disabled={!isFilterButtonEnabled}
                onClick={this.handleHubFilters}
                type="primary"
              >
                <Icon type="filter" />
                Apply Filters
              </Button>
              <Divider type="vertical" />
              <Button disabled={!filtersEnabled} onClick={this.clearAllFilters}>
                <Icon type="sync" />
                Clear All
              </Button>
            </Col>
          </Row>
        </PageHeader>
        <Content>
          <Card bordered={false}>
            <Table
              columns={this.columns}
              dataSource={hubs}
              loading={loading}
              rowKey="id"
              title={this.renderTitle}
            />
          </Card>
        </Content>
        {openChangeLogModal && (
          <ChangeLogModal
            changeLogs={changeLogs}
            close={() => this.closeChangeLogModal()}
            loading={changeLogsLoading}
            openModal={openChangeLogModal}
          />
        )}
        <EditModalForm
          clusters={clusters}
          elOrganizations={elOrganizations}
          fetchAllClusters={fetchAllClusters}
          groupHubsCount={groupHubsCount}
          onCancel={cancelEdit}
          onRequestDelete={this.handleRequestDelete}
          onSave={this.handleSave}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  hub: {
    hubs,
    hubsLoading,
    saveModalVisible,
    filtersEnabled,
    modulesList,
    changeLogsLoading,
    changeLogs,
    elOrganizations,
  },
  cluster: { allClusters },
  features: { groupHubsCount },
}) => ({
  hubs,
  hubsLoading,
  saveModalVisible,
  clusters: allClusters,
  filtersEnabled,
  modulesList,
  groupHubsCount,
  changeLogsLoading,
  changeLogs,
  elOrganizations,
});

const mapDispatchToProps = {
  fetchAllHubs: fetchAllHubsAction,
  fetchAllClusters: fetchAllClustersAction,
  saveHub: saveHubAction,
  editHub: editHubAction,
  cancelEdit: cancelEditAction,
  filterHubs: filterHubsAction,
  fetchGroupHubsCount: fetchGroupHubsCountAction,
  fetchLaunchGroupChangeLog: fetchLaunchGroupChangeLogAction,
  setLaunchGroupChangeLog: setLaunchGroupChangeLogAction,
  requestDeleteHub: requestDeleteHubAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HubManagement)
);
