import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Select, Switch, Table } from 'antd';
import { clone, findIndex } from 'lodash';

import { setTemplateTableRows } from '../../../actions/manageActions';

const { Option } = Select;

class ManageTemplateTable extends PureComponent {
  getUpdatedArray = (arr, obj, keyToMatch) => {
    const matchedSettingIndex = findIndex(arr, { key: keyToMatch });
    if (matchedSettingIndex > -1) {
      arr.splice(matchedSettingIndex, 1, obj);
    } else {
      arr.push(obj);
    }
    return arr;
  };

  handleSwitchChange = (checked, type, setting) => {
    const { templateSettingRows, setTemplateTableRows } = this.props;
    let rows = clone(templateSettingRows);
    const { key } = setting;
    let updateObj = {};
    switch (type) {
      case 'visible':
      case 'editable':
        updateObj = {
          ...setting,
          [type]: checked,
          upsellId: checked ? undefined : setting.upsellId,
        };
        break;
      default:
    }
    rows = this.getUpdatedArray(rows, updateObj, key);
    setTemplateTableRows(rows);
  };

  handleSelectChange = (value, setting) => {
    const { templateSettingRows, setTemplateTableRows } = this.props;
    let rows = clone(templateSettingRows);
    const { key } = setting;
    const updateObj = { ...setting, upsellId: value };
    rows = this.getUpdatedArray(rows, updateObj, key);
    setTemplateTableRows(rows);
  };

  getColumns = () => {
    const { upsellOptions, hasErrors, isSaving } = this.props;
    return [
      {
        title: 'Settings',
        dataIndex: 'name',
        key: 'name',
        width: 600,
      },
      {
        title: 'Visibility',
        dataIndex: 'visible',
        key: 'visible',
        width: 200,
        render: (value, record) => (
          <div className={`manage-template-switch${value ? ' active' : ''}`}>
            <Switch
              checked={value}
              onChange={checked =>
                this.handleSwitchChange(checked, 'visible', record)
              }
              disabled={isSaving}
            />
          </div>
        ),
      },
      {
        title: 'Editability',
        dataIndex: 'editable',
        key: 'editable',
        width: 200,
        render: (value, record) => (
          <div className={`manage-template-switch${value ? ' active' : ''}`}>
            <Switch
              checked={value}
              onChange={checked =>
                this.handleSwitchChange(checked, 'editable', record)
              }
              disabled={isSaving}
            />
          </div>
        ),
      },
      {
        title: 'Upsell Action',
        dataIndex: 'key',
        key: 'key',
        width: 300,
        render: (v, record) => {
          const { visible, editable, upsellId } = record;
          const disabled = visible && editable;
          const hasError = hasErrors && visible && !editable && !upsellId;
          return (
            <div className="manage-template-select">
              <Select
                className={`select-upsell--input${
                  hasError ? ' select-upsell--err' : ''
                }`}
                disabled={disabled || isSaving}
                placeholder={disabled ? 'No upsell action' : 'Select action'}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={val => this.handleSelectChange(val, record)}
                value={upsellId}
              >
                {upsellOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
              {hasError && (
                <div className="select-upsell--err-label">
                  <span>This cannot be unselected</span>
                </div>
              )}
            </div>
          );
        },
      },
    ];
  };

  render() {
    const { templateSettingRows } = this.props;
    return (
      <div className="manage-template-table">
        <Table
          dataSource={templateSettingRows}
          columns={this.getColumns()}
          pagination={false}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  usertypeManage: {
    upsellOptions,
    templateSettingRows,
    template: { settings },
    isSaving,
  },
}) => ({
  upsellOptions,
  templateSettingRows,
  settings,
  isSaving,
});

const mapDispatchToProps = {
  setTemplateTableRows,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTemplateTable);
