import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.scss';

const QuillEditor = ({ style, onChange, value, getInitialCount }) => {
  /** @type {ReactQuill} */
  const defaultRefValue = null;
  const quillRef = useRef(defaultRefValue);

  useEffect(() => {
    const editor = quillRef.current.getEditor();
    getInitialCount(editor.getText().trim().length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactQuill
      className={`quill-wysiwyg-editor ${style}`}
      value={value}
      onChange={onChange}
      ref={quillRef}
    />
  );
};

QuillEditor.propTypes = {
  style: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  getInitialCount: PropTypes.func,
};

QuillEditor.defaultProps = {
  style: '',
  getInitialCount: () => {},
};

export default QuillEditor;
