import React from 'react';
import { Button, List, Card, Tag, Tooltip } from 'antd';
import HubSFAssociationStatus from '../../../HubSFAssociationStatus/HubSFAssociationStatus';
import MapOldStandardDataToMigratedHubs from '../../containers/MapOldStandardDataToMigratedHubs';

const standardTypeMap = {
  enterprise: {
    color: 'purple',
    text: 'Classic',
  },
  standard: {
    color: 'green',
    text: 'V2 | PG Essentials | New',
  },
  'transitioned-standard': {
    color: 'gold',
    text: 'V2 | PG Essentials | Upgraded',
  },
  'standard-plus': {
    color: 'red',
    text: 'V2 | PG + | New',
  },
  'standard-plus-fpu': {
    color: 'magenta',
    text: 'V2 | PG + | Upgraded',
  },
};
const PROPS_TEXT_MIGRATED = 'V2 Migrated';

const PROPS_TEXT_MIGRATE = 'Migrate to V2';
const PROPS_TEXT_ERROR_MIGRATE = 'Retry Migration';
const PROPS_TEXT_PROGRESS_MIGRATE = 'Migration in progress';
const getCTAProps = ({
  clusterId,
  hubId,
  hubPlatformType,
  jobStatus,
  verifyAndGetData,
}) => {
  const props = {};

  if (
    [
      'standard',
      'transitioned-standard',
      'standard-plus',
      'standard-plus-fpu',
    ].includes(hubPlatformType)
  ) {
    props.className = 'standard-hub--list-card__cta--green';
    props.onClick = () => {};
    props.text = PROPS_TEXT_MIGRATED;
  } else {
    props.className = 'standard-hub--list-card__cta--gold';
    props.onClick = () => verifyAndGetData({ hubId, clusterId });
    props.text = PROPS_TEXT_MIGRATE;
  }

  if (jobStatus === 'error') {
    props.className = 'standard-hub--list-card__cta--red';
    props.onClick = () => verifyAndGetData({ hubId, clusterId });
    props.text = PROPS_TEXT_ERROR_MIGRATE;
  }

  if (jobStatus === 'processing') {
    props.className = 'standard-hub--list-card__cta--green';
    props.onClick = () => {};
    props.text = PROPS_TEXT_PROGRESS_MIGRATE;
  }

  return props;
};

const ClusterHubList = ({
  data,
  loading,
  total,
  page,
  handlePageChange,
  verifyAndGetData,
}) => (
  <List
    loading={loading}
    itemLayout="vertical"
    dataSource={data}
    pagination={{
      simple: false,
      total: parseInt(total, 10),
      current: page,
      onChange: handlePageChange,
    }}
    renderItem={item => {
      const {
        hubId,
        clusterId,
        hubName,
        hubIdentifier,
        hubSubDomain,
        clusterName,
        clusterDomain,
        hubPlatformType,
        jobStatus,
        hubDomain,
        totalStandardFieldsMapped,
        oldIndustryHelpTopicMigrationStatus,
      } = item;
      const {
        onClick: ctaOnClick,
        className,
        text,
      } = getCTAProps({
        clusterId,
        hubId,
        hubPlatformType,
        jobStatus,
        verifyAndGetData,
      });
      return (
        <List.Item key={`${clusterId}-${hubId}`}>
          <Card
            type="inner"
            title={`${clusterName} - ${clusterDomain} (Cluster ID - ${clusterId})`}
            hoverable={false}
            className="standard-hub--list-card"
          >
            <List
              size="small"
              dataSource={[`${hubId}`]}
              itemLayout="vertical"
              bordered
              renderItem={el => (
                <List.Item
                  key={el}
                  extra={
                    <div className="standard-hub--list-card__button-group">
                      {text === PROPS_TEXT_MIGRATED ? (
                        <>
                          <MapOldStandardDataToMigratedHubs
                            hubId={hubId}
                            hubIdentifier={hubIdentifier}
                            hubDomain={hubDomain}
                            clusterId={clusterId}
                            oldIndustryHelpTopicMigrationStatus={
                              oldIndustryHelpTopicMigrationStatus
                            }
                          />
                        </>
                      ) : null}

                      <Button
                        onClick={ctaOnClick}
                        className={`standard-hub--list-card__cta ${className}`}
                      >
                        {text}
                      </Button>
                    </div>
                  }
                >
                  <List.Item.Meta
                    title={
                      <div className="standard-hub--title">
                        <span>Hub - {hubName}</span>
                        {standardTypeMap[hubPlatformType] && (
                          <Tag color={standardTypeMap[hubPlatformType].color}>
                            {standardTypeMap[hubPlatformType].text}
                          </Tag>
                        )}
                        <HubSFAssociationStatus hubData={item} showBreak />
                        {jobStatus === 'error' && (
                          <Tooltip
                            title="You can restart the migration by clicking on the CTA"
                            placement="top"
                          >
                            <Tag color="red">Error in Migration</Tag>
                          </Tooltip>
                        )}
                        {jobStatus === 'processing' && (
                          <Tag color="green">In Progress</Tag>
                        )}
                      </div>
                    }
                    description={`(Subdomain - ${hubSubDomain}), Hub ID - ${hubId}`}
                  />
                </List.Item>
              )}
            />
          </Card>
        </List.Item>
      );
    }}
  />
);

export default ClusterHubList;
