import React, { PureComponent } from 'react';
import { Modal, Table, Input, Button, Icon } from 'antd';
import { has } from 'lodash';
import Highlighter from 'react-highlight-words';

export default class HubsViewModal extends PureComponent {
  state = {
    searchText: '',
    searchedColumn: '',
    filterParams: {},
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      ...this.getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name'),
    },
    {
      title: 'Subdomain',
      dataIndex: 'subdomain',
      key: 'subdomain',
      ...this.getColumnSearchProps('subdomain'),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      ...this.getColumnSearchProps('domain'),
    },
  ];

  componentDidMount() {
    const {
      source,
      showOverriddenFeatures,
      affectedFeatureObject = {},
      resetToDefault,
    } = this.props;
    const { filterParams = {}, selectedRowKeys = [] } = this.state;
    if (source === 'hub') {
      this.columns.push({
        title: 'Identifier',
        dataIndex: 'identifier',
        key: 'identifier',
        ...this.getColumnSearchProps('identifier'),
      });
    }
    if (showOverriddenFeatures) {
      this.columns.push({
        title: 'Affected Feature',
        dataIndex: 'affectedFeature',
        key: 'affectedFeature',
        filters: Object.keys(affectedFeatureObject).map(key => ({
          text: key,
          value: key,
        })),
        onFilter: (value, record) => {
          const { affectedfeature: affectedFeature } = record;
          return has(affectedFeature, value);
        },
      });
      filterParams.defaultFeatureKeys = Object.keys(affectedFeatureObject);
    }
    this.setState({ filterParams });
  }

  getModalFooters = () => {
    const {
      showOverriddenFeatures,
      resetToDefault,
      source
    } = this.props;
    const {
      selectedRowKeys = [], filterParams
    } = this.state;
    const type = source === 'hub' ? 'Hubs' : 'Clusters';
    const modalFooters = [];
    if (showOverriddenFeatures) {
      modalFooters.push(
        <Button
          onClick={() => resetToDefault(filterParams)}
          style={{ height: 32 }}
          type="danger"
          size="small"
        >
          Reset
          {selectedRowKeys.length ? ' selected ' : ' all '}
          {type} to Default
        </Button>
      );
    } else {
      modalFooters.push(<Button key="submit" type="primary" onClick={this.props.download}>
        Download
      </Button>)
    }
    modalFooters.push(<Button key="submit" type="primary" onClick={this.props.close}>
      Close
    </Button>)
    return modalFooters;
  }
  onTableChange = (_, filters) => {
    const { affectedFeature } = filters;
    const { filterParams } = this.state;
    filterParams.featureKeys = affectedFeature;
    this.setState({ filterParams });
  };

  getColumnSearchProps(dataIndex) {
    return ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon
          type="search"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      render: text =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  downloadHubList = () => {
    // download the data in csv format
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onSelectedRowKeysChange = selectedRowKeys => {
    const { filterParams } = this.state;
    filterParams.hubIds = selectedRowKeys;
    this.setState({ filterParams, selectedRowKeys });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    const type = this.props.source === 'hub' ? 'Hubs' : 'Clusters';
    return (
      <Modal 
        title={`${type} for ${
          this.props.launchGroup
            ? this.props.launchGroup
            : `overridden ${this.props.featureKey}`
        }`}
        visible={this.props.openModal}
        onOk={this.props.close}
        onCancel={this.props.close}
        width={1200}
        footer={this.getModalFooters()}
      >
        <Table
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={this.props.launchGroupHubs}
          loading={this.props.loading}
          onChange={this.onTableChange}
        />
      </Modal>
    )
  }
}