import { request } from '../../utils/request';

export const getUserDetailsAPI = params =>
  request.get('/superadmin/user-detail', { params });

export const deleteUserDetailAPI = params =>
  request.delete('/superadmin/user-detail', { params });

export const unlinkLinkedinAPI = params =>
  request.post('/superadmin/unlink-account/linkedin', params);

export const unlinkFacebookAPI = params =>
  request.post('/superadmin/unlink-account/facebook', params);

export const unsetDatabaseKeyAPI = params =>
  request.post('/superadmin/unset/database-key', params);

export const unsetSsoIdAPI = params =>
  request.post('/superadmin/unset/ssoid', params);

export const undeleteUserHubAPI = params => 
  request.post('/superadmin/undelete', params)
