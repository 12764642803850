import {
  SET_MANAGE_LOADING,
  SET_MANAGE_DATA,
  SET_MANAGE_SINGLE_LOADING,
  CLEAR_FORM,
  CLEAR_MANAGE_DATA,
  SET_FILTERED_HUBS,
  SET_FILTERED_HUBS_LOADING,
  SET_FILTERED_GROUPS,
  SET_FILTERED_GROUPS_LOADING,
  SET_ACTIVE_TIERS,
  SET_ACTIVE_TIERS_LOADING,
  SET_NAME,
  SET_INTERNAL_NAME,
  SET_DESCRIPTION,
  SET_IMAGE,
  SET_IS_ACTIVE,
  SET_OBJECT_ID,
  SET_GROUP_TIER,
  SET_LOGO_URL,
  SET_COVER_PHOTO_URL,
  SET_SETTINGS,
  SET_FIELD_INPUTS_DISABLED,
  SET_COPY_MODAL_VISIBILITY,
  SET_DELETE_MODAL_VISIBILITY,
  SET_CANCEL_MODAL_VISIBILITY,
  SET_MANAGE_TYPE,
  SET_SHOW_FORM,
  SET_CAN_DELETE_TIER,
  SET_GROUPED_USERTYPE,
  SET_SELECTED_HUB_ID_TO_COPY,
  SET_VISIBLE_TO_USERTYPE,
  SET_SELECTED_GROUP,
  SET_MANAGE_SAVE_LOADING,
  SET_MANAGE_DATA_COUNT,
  SET_MANAGE_DATA_PAGE,
  SET_MANAGE_DATA_PAGE_SIZE,
  SET_SELECTED_ASSOCIATED_GROUP_COUNT,
} from '../actions/manage';

const initialState = {
  manageLoading: false,
  manageData: [],
  manageDataCount: 0,
  manageDataPage: 1,
  manageDataPageSize: 5,
  manageSingleLoading: false,
  filteredHubsLoading: false,
  filteredHubs: [],
  filteredGroupsByHubLoading: false,
  filteredGroupsByHub: [],
  activeTiersLoading: false,
  activeTiers: [],
  manageType: '',
  showForm: false,
  canDeleteTier: false,

  name: '',
  internalName: '',
  description: '',
  image: '',
  isActive: true,
  objectId: [],
  groupTier: '',
  logoUrl: '',
  coverPhotoUrl: '',
  settings: {},
  fieldInputsDisabled: true,

  copyModalVisible: false,
  deleteModalVisible: false,
  cancelModalVisible: false,

  groupedUserType: {},
  selectedHubId: null,
  visibleToUserType: [],
  selectedGroup: null,
  manageSaveLoading: false,
  associatedGroupCount: null,
};

const manageReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_MANAGE_LOADING:
      return {
        ...state,
        manageLoading: data,
      };
    case SET_MANAGE_DATA:
      return {
        ...state,
        manageData: data,
      };
    case SET_MANAGE_SINGLE_LOADING:
      return {
        ...state,
        manageSingleLoading: data,
      };
    case SET_FILTERED_HUBS:
      return {
        ...state,
        filteredHubs: data,
      };
    case SET_FILTERED_HUBS_LOADING:
      return {
        ...state,
        filteredHubsLoading: data,
      };
    case SET_FILTERED_GROUPS:
      return {
        ...state,
        filteredGroupsByHub: data,
      };
    case SET_FILTERED_GROUPS_LOADING:
      return {
        ...state,
        filteredGroupsByHubLoading: data,
      };
    case SET_ACTIVE_TIERS:
      return {
        ...state,
        activeTiers: data,
      };
    case SET_ACTIVE_TIERS_LOADING:
      return {
        ...state,
        activeTiersLoading: data,
      };
    case SET_NAME:
      return {
        ...state,
        name: data,
      };
    case SET_INTERNAL_NAME:
      return {
        ...state,
        internalName: data,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        description: data,
      };
    case SET_IMAGE:
      return {
        ...state,
        image: data,
      };
    case SET_IS_ACTIVE:
      return {
        ...state,
        isActive: data,
      };
    case SET_OBJECT_ID:
      return {
        ...state,
        objectId: data,
      };
    case SET_GROUP_TIER:
      return {
        ...state,
        groupTier: data,
      };
    case SET_LOGO_URL:
      return {
        ...state,
        logoUrl: data,
      };
    case SET_COVER_PHOTO_URL:
      return {
        ...state,
        coverPhotoUrl: data,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: data,
      };
    case SET_FIELD_INPUTS_DISABLED:
      return {
        ...state,
        fieldInputsDisabled: data,
      };
    case SET_COPY_MODAL_VISIBILITY:
      return {
        ...state,
        copyModalVisible: data,
      };
    case SET_DELETE_MODAL_VISIBILITY:
      return {
        ...state,
        deleteModalVisible: data,
      };
    case SET_CANCEL_MODAL_VISIBILITY:
      return {
        ...state,
        cancelModalVisible: data,
      };
    case SET_MANAGE_TYPE:
      return {
        ...state,
        manageType: data,
      };
    case SET_SHOW_FORM:
      return {
        ...state,
        showForm: data,
      };
    case SET_CAN_DELETE_TIER:
      return {
        ...state,
        canDeleteTier: data,
      };
    case SET_MANAGE_DATA_COUNT:
      return {
        ...state,
        manageDataCount: data,
      };
    case SET_MANAGE_DATA_PAGE:
      return {
        ...state,
        manageDataPage: data,
      };
    case SET_MANAGE_DATA_PAGE_SIZE:
      return {
        ...state,
        manageDataPageSize: data,
      };
    case CLEAR_FORM:
      return {
        ...state,
        name: '',
        internalName: '',
        description: '',
        image: '',
        isActive: true,
        objectId: [],
        groupTier: '',
        logoUrl: '',
        coverPhotoUrl: '',
        settings: {},
        fieldInputsDisabled: true,
        filteredHubs: [],
        filteredGroupsByHub: [],
        copyModalVisible: false,
        deleteModalVisible: false,
        cancelModalVisible: false,
        canDeleteTier: false,
        selectedGroup: null,
        selectedHubId: null,
      };
    case CLEAR_MANAGE_DATA:
      return {
        ...state,
        manageData: [],
        filteredHubs: [],
        filteredGroupsByHub: [],
        activeTiers: [],
        manageDataCount: 0,
        manageDataPage: 1,
        manageDataPageSize: 5,
      };
    case SET_GROUPED_USERTYPE:
      return {
        ...state,
        groupedUserType: data,
      };
    case SET_SELECTED_HUB_ID_TO_COPY:
      return {
        ...state,
        selectedHubId: data,
      };
    case SET_VISIBLE_TO_USERTYPE:
      return {
        ...state,
        visibleToUserType: data,
      };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: data,
      };
    case SET_MANAGE_SAVE_LOADING:
      return {
        manageSaveLoading: data,
      };
    case SET_SELECTED_ASSOCIATED_GROUP_COUNT:
      return {
        ...state,
        associatedGroupCount: data,
      };
    default:
      return state;
  }
};

export default manageReducer;
