import React from 'react';
import cx from 'classnames';
import s from './index.module.sass';

export default props => {
  const {children, className, ...rest} = props;
  return (
    <div {...rest} className={cx(className, s.content)}>
      {props.children}
    </div>
  );
}