import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  PageHeader,
  Divider,
  Button,
  Table,
  Switch,
  Modal,
  Tooltip,
  Row,
  Input,
} from 'antd';

import { isEmpty, pick, isNil } from 'lodash';
import BenchmarkingGroupModal from './_components/BenchmarkingGroupModal';
import {
  useCreateBenchmarkingGroup,
  useUpdateBenchmarkingGroup,
  useGetBenchmarkingGroupsQuery,
  useDeleteBenchmarkingGroup,
} from './query';
import classes from './index.module.scss';

const { Search } = Input;

const SUB_HEADER =
  'Add at least 10 hubs in the group to maintain anonymity of our clients and to offer more reliable statistics';

const ManageBenchmarkingGroups = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selectedGroupForEdit, setSelectedGroupForEdit] = useState(null);
  const createBenchmarkingGroup = useCreateBenchmarkingGroup();
  const updateBenchmarkingGroup = useUpdateBenchmarkingGroup();
  const deleteBenchmarkingGroup = useDeleteBenchmarkingGroup();
  const {
    data: benchmarkingGroups = [],
    isLoading: benchmarkingGroupsLoading,
    refetch,
  } = useGetBenchmarkingGroupsQuery();

  const handleIsActiveClick = (id, oldData, updatedData) => {
    const filteredOldData = pick(oldData, ['name', 'externalName', 'hubIds']);
    const updateObject = { ...filteredOldData, ...updatedData };
    updateBenchmarkingGroup.mutate(
      { id, ...updateObject },
      { onSuccess: () => refetch() }
    );
  };

  const handleEditClick = groupDetails => {
    const filteredData = pick(groupDetails, [
      'id',
      'name',
      'externalName',
      'hubs',
    ]);
    setSelectedGroupForEdit(filteredData);
    setActiveModal('edit');
  };

  const handleDeleteClick = groupDetails =>
    Modal.confirm({
      title: 'Delete group?',
      content:
        'If you delete, all the hubs in this group will not be able to view their benchmarks report. Are you sure you want to delete this group?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteBenchmarkingGroup.mutate(groupDetails.id, {
          onSuccess: () => refetch(),
        });
      },
      onCancel() {},
    });

  const handleClose = () => {
    setActiveModal(null);
    setSelectedGroupForEdit(null);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 500,
    },
    {
      title: '# of Hubs',
      dataIndex: 'noOfHubs',
      key: 'hub_id',
      render: (k, v) => (
        <Tooltip
          title={v.hubs.map(({ name, id }) => `${name} (${id})`).join(', ')}
        >
          {v.hubs.length}
        </Tooltip>
      ),
    },
    {
      title: 'isActive',
      dataIndex: 'isActive',
      key: 'active',
      render: (k, v) => (
        <Switch
          defaultChecked={v.isActive || false}
          onChange={isActiveStatus =>
            handleIsActiveClick(v.id, v, { isActive: isActiveStatus })
          }
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (k, v) => (
        <div className={classes.actions}>
          <Button type="primary" onClick={() => handleEditClick(v)}>
            Edit
          </Button>
          <Divider type="vertical" className={classes.divider} />
          <Button type="danger" onClick={() => handleDeleteClick(v)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const onSubmit = data => {
    if (activeModal === 'create') {
      createBenchmarkingGroup.mutate(data, {
        onSuccess: () => refetch(),
      });
    }

    if (activeModal === 'edit') {
      updateBenchmarkingGroup.mutate(
        { id: selectedGroupForEdit.id, ...data },
        {
          onSuccess: () => refetch(),
        }
      );
    }

    setSelectedGroupForEdit(null);
  };

  const checkForDuplicates = (keyName, keyValue) => {
    if (benchmarkingGroupsLoading || benchmarkingGroups.length === 0) {
      return false;
    }

    const foundData = benchmarkingGroups.find(
      groupData => groupData[keyName] === keyValue
    );
    return !isEmpty(foundData);
  };

  const filterBenchmarkingGroupsList = () => {
    const lowerCaseSearchText = searchText && searchText.trim().toLowerCase();
    if (lowerCaseSearchText.length && benchmarkingGroups) {
      return benchmarkingGroups.filter(obj => {
        const { hubs = [] } = obj;
        if (
          !isNil(lowerCaseSearchText) &&
          isNaN(parseInt(lowerCaseSearchText, 10))
        ) {
          return hubs.some(
            ({ name: hubName }) =>
              hubName && hubName.toLowerCase().includes(lowerCaseSearchText)
          );
        }
        return hubs.some(
          ({ id: hubID }) => hubID && hubID.toString() === lowerCaseSearchText
        );
      });
    }
    return benchmarkingGroups;
  };

  return (
    <div>
      <PageHeader
        title="Manage Benchmarking Groups"
        extra={[
          <Button
            key="new-benchmarking-group"
            type="primary"
            onClick={() => setActiveModal('create')}
          >
            New Group
          </Button>,
        ]}
      >
        <Divider />
        <Row type="flex">
          <Search
            placeholder="Search by hub name, hub ID"
            style={{ width: 250, float: 'left' }}
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
          />
        </Row>
      </PageHeader>
      <Table
        className={classes.benchmarkingGroupsList}
        columns={columns}
        dataSource={filterBenchmarkingGroupsList().map(groupData => ({
          key: groupData.id,
          ...groupData,
        }))}
        loading={benchmarkingGroupsLoading}
        bordered
        pagination={false}
      />
      <BenchmarkingGroupModal
        onClose={handleClose}
        modalType={activeModal}
        onSubmit={onSubmit}
        previousData={selectedGroupForEdit || {}}
        showModal={!!activeModal}
        title={
          activeModal === 'create'
            ? 'Create benchmarking group'
            : 'Edit benchmarking group'
        }
        subHeader={SUB_HEADER}
        checkForDuplicates={checkForDuplicates}
      />
    </div>
  );
};

export default withRouter(ManageBenchmarkingGroups);
