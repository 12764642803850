import React, { PureComponent } from 'react';
import { Button, Card, Table, Tooltip, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';

import Content from '../../../component/Content';

import {
  handleFetchEmails,
  handleOpenEmailEditor,
  setPreviewTemplate,
} from '../actions';
import { DATE_FORMAT, TIME_FORMAT } from '../utils';

class Emails extends PureComponent {
  state = { previewTemplate: null };

  componentDidMount() {
    const { handleFetchEmails } = this.props;

    handleFetchEmails();
  }

  handleEditOnClick = id => {
    const { handleOpenEmailEditor } = this.props;

    handleOpenEmailEditor({ id, type: 'email' });
  };

  handleCopyOnClick = id => {
    const { handleOpenEmailEditor } = this.props;

    handleOpenEmailEditor({ id, type: 'emailCopy' });
  };

  handlePreviewModalToggle = template => {
    this.setState({ previewTemplate: template });
  };

  render() {
    const { emails, emailsLoading, setPreviewTemplate } = this.props;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        title: 'Sent Date',
        dataIndex: 'sentTime',
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          if (!get(a, 'draftData.date')) {
            return -1;
          } else if (!get(b, 'draftData.date')) {
            return 1;
          } else {
            const format = `${DATE_FORMAT} ${TIME_FORMAT}`;
            return moment(
              `${get(a, 'draftData.date')} ${get(a, 'draftData.startTime')}`,
              format
            ).diff(
              `${get(b, 'draftData.date')} ${get(b, 'draftData.startTime')}`,
              format
            );
          }
        },
        render: (value, record) =>
          `${get(record, 'draftData.date', '')} ${get(
            record,
            'draftData.startTime',
            ''
          )}`,
      },
      {
        title: (
          <div>
            Stats{' '}
            <Tooltip title="Total number of hubs">
              <Icon type="info-circle" />
            </Tooltip>
          </div>
        ),
        dataIndex: 'stats',
        className: 'am__table__stats',
        render: value => {
          if (value) {
            return (
              <ul>
                {Object.keys(value).map((key, index) => (
                  <li key={index}>
                    <span className="am__table__stats__label">{key}:</span>{' '}
                    {value[key]}
                  </li>
                ))}
              </ul>
            );
          }

          return value;
        },
      },
      {
        title: 'Actions',
        className: 'am__table__actions',
        render: (value, record) => (
          <>
            {record.status && record.status !== 'draft' ? (
              <Button
                size="small"
                onClick={() =>
                  setPreviewTemplate({
                    title: record.name,
                    html: record.messageTemplate,
                  })
                }
              >
                View
              </Button>
            ) : (
              <Button
                size="small"
                onClick={() => this.handleEditOnClick(record.id)}
              >
                Edit
              </Button>
            )}
            <Button
              size="small"
              onClick={() => this.handleCopyOnClick(record.id)}
            >
              Copy
            </Button>
          </>
        ),
      },
    ];

    return (
      <Content>
        <Card
          title="Emails"
          extra={
            <Link to="/automated-marketing/templates">
              <Button type="primary">New Email</Button>
            </Link>
          }
        >
          <Table
            columns={columns}
            dataSource={emails || []}
            rowKey="id"
            loading={emailsLoading}
          />
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = ({
  automatedMarketing: { emailsLoading, emails },
}) => ({
  emailsLoading,
  emails,
});

const mapDispatchToProps = {
  handleFetchEmails,
  handleOpenEmailEditor,
  setPreviewTemplate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Emails);
