import { call, put, select, all } from 'redux-saga/effects';

import notify, { notifyError } from '../../../utils/notify';
import {
  getTemplates,
  getBeefreeTokenAPI,
  postMarketingEmail,
  getMarketingEmails,
  putMarketingEmail,
  postTestEmail,
  postScheduleEmail,
  getStats,
  postBulkEmailTemplate,
  deleteBulkEmailTemplate,
  putBulkEmailTemplate,
  getTemplateCategories,
  getJobTemplates,
  fetchAllCampaigns,
  updateCampaign,
  deleteCampaign,
  fetchCampaignInfo,
  createCampaign,
  createCampaignEmail,
  deleteCampaignEmail,
  updateCampaignEmail,
} from '../../../api/automatedMarketing';
import history from '../../../history';
import get, { cloneDeep, omit, reject } from 'lodash';
import {
  setTemplates,
  setTemplatesLoading,
  setEditorLoading,
  setBeefreeToken,
  automatedMarketingSelector,
  setEmailsLoading,
  setEmails,
  setBeefreeData,
  templatesSelector,
  emailsSelector,
  addEmail,
  setEmailSending,
  setStatsLoading,
  setStats,
  setLoadingTemplate,
  removeTemplate,
  setTemplateCategories,
  setJobTemplates,
  setAllCampaigns,
  setAllCampaignsLoading,
  setCampaignInfoLoading,
  setCampaignInfo,
  updateCampaignInfo,
  handleUpdateEmailTemplate,
  handleCreateEmailTemplate,
} from '../actions';
import {
  personalizeTemplate,
  revertPersonalizedChanges,
  fallbackPreviewUrl,
} from '../utils';
import variations from '../../../constants/defaultSettingVariationConstants';

export function* fetchTemplatesSaga({}) {
  try {
    yield put(setTemplatesLoading(true));

    const [templates, categories] = yield all([
      call(getTemplates),
      call(getTemplateCategories, {}),
    ]);

    const { data } = templates;
    const { data: categoriesData } = categories;

    yield all([
      put(setTemplates(data)),
      put(setTemplateCategories(categoriesData)),
    ]);
  } catch (e) {
    notifyError(e, false, 'Error fetching templates');
  }
  yield put(setTemplatesLoading(false));
}

export function* initEmailEditorSaga() {
  try {
    yield put(setEditorLoading(true));

    const [{ data: token }, { data: jobTemplates }] = yield all([
      call(getBeefreeTokenAPI),
      call(getJobTemplates),
    ]);

    yield put(setJobTemplates(jobTemplates.templates));
    yield put(setBeefreeToken(token));

    yield put(setEditorLoading(false));
  } catch (e) {
    notifyError(e, false, 'Error initializing editor');
  }
}

export function* openEmailEditorSaga({ data: { id, type, edit, redirectPath: baseUrl } }) {
  try {
    const redirectPath = baseUrl ? `${baseUrl}/email-editor` 
    : `/automated-marketing/email-editor`;
    console.log(redirectPath);

    switch (type) {
      case 'template':
        const templates = yield select(templatesSelector) || [];
        const template = templates.find(t => t.id === id);
        if (template) {
          yield put(
            setBeefreeData({
              template: personalizeTemplate(template),
              templateId: type === 'template' && edit && id,
              draftData: {
                ...template,
                name: edit && template.title,
              },
            })
          );
          yield call(history.push, redirectPath);
        }
        break;
      case 'email':
      case 'emailCopy':
      case 'campaignEmail':
        const { emails, campaign:{ campaignInfo : { campaignEmails}} } = 
        yield select(automatedMarketingSelector) || {};
        const mails = type === 'campaignEmail' ? campaignEmails : emails; 
        const email = mails.find(t => t.id === id);
        console.log(email);
        if (email) {
          const { draftData, name, messageSubject, id, 
            delay,
            importStatus,
            standardUserType
          } = email;
          yield put(
            setBeefreeData({
              template: personalizeTemplate(email),
              draftData: { ...draftData, name, messageSubject, delay,
                importStatus,
                standardUserType },
              emailId: type === 'email' && id,
              campaignEmailId: type === 'campaignEmail' && id,
            })
          );
          yield call(history.push, redirectPath);
        }
        break;
      default:
        break;
    }
  } catch (e) {
    notifyError(e, false, 'Error opening editor');
  }
}

export function* saveEmailSaga({ data: { actionType, json, html, callback } }) {
  try {
    const {
      emailId,
      draftData,
      templateId,
      campaignEmailId,
      campaign: { campaignInfo },
    } = yield select(automatedMarketingSelector) || {};
    const { id : campaignId, campaignEmails=[] } = campaignInfo;

    const { name, messageSubject, category, newTemplateCategory } =
      draftData || {};
    let params = {
      name,
      messageSubject,
      draftData,
    };

    if (json && html) {
      yield put(setBeefreeData({ messageJson: json, messageTemplate: html }));

      const template = revertPersonalizedChanges({
        messageJson: json,
        messageTemplate: html,
      });
      params = { ...params, ...template };
    }

    if (['saveDraft', 'schedule', 'nextPage'].includes(actionType)) {
      if (actionType === 'schedule') {
        const result = yield call(postScheduleEmail, {
          id: emailId,
          data: params,
        });
        if (!emailId) yield put(addEmail(result.data));
      } else {
        if (emailId) {
          // Update
          yield call(putMarketingEmail, {
            id: emailId,
            data: params,
          });
        } else {
          // Create
          const result = yield call(postMarketingEmail, params);
          yield put(addEmail(result.data));
        }
      }
    } else if (actionType === 'saveTemplate') {
      params = {
        ...params,
        category: newTemplateCategory || category || 'Basic Templates',
        selectedDropdown: newTemplateCategory || category || 'Basic Templates',
        previewUrl: fallbackPreviewUrl,
        title: name,
        messageHtml: html,
      };
      if (templateId) {
        // Update
        yield call(putBulkEmailTemplate, {
          id: templateId,
          data: { ...draftData, ...params, title: name },
        });
      } else {
        // Create
        const result = yield call(postBulkEmailTemplate, params);
        // yield put(addEmail(result.data));
      }
    } else if (actionType === 'addEmailToCampaign') {
      params = {
        ...params,
        category: newTemplateCategory || category || 'Basic Templates',
        selectedDropdown: newTemplateCategory || category || 'Basic Templates',
        previewUrl: fallbackPreviewUrl,
        title: name,
        messageHtml: html,
        id: new Date().getTime(),
      };
      let createdEmail = { ...draftData, ...params};
      if(campaignId) {
        const { data } = yield call(createCampaignEmail, {
          campaignId, 
          data: omit(createdEmail,'id'),
        });
        createdEmail = data;
        notify('Email added to campaign.','success');
      }
      const updatedCampaignInfo = { campaignEmails: [...campaignEmails,createdEmail] };
      yield put(updateCampaignInfo(updatedCampaignInfo));

    } else if (actionType === 'editCampaignEmail' && campaignEmailId) {
       yield put(handleUpdateEmailTemplate({
          record: { id: campaignEmailId},
          updatedData: {
            ...draftData, 
            ...params,
            messageHtml: html,
          }
        }));
    }
    callback && callback();
  } catch (e) {
    notifyError(e, false, 'Failed to process request.');
  }
  yield put(setEmailSending(false));
}

export function* fetchEmailsSaga({}) {
  try {
    yield put(setEmailsLoading(true));
    const { data } = yield call(getMarketingEmails);

    yield put(setEmails(data));
  } catch (e) {
    notifyError(e, false, 'Error fetching emails');
  }
  yield put(setEmailsLoading(false));
}

export function* sendTestEmailSaga({ data: email }) {
  try {
    yield put(setEmailSending(true));
    const { draftData, messageTemplate } = yield select(
      automatedMarketingSelector
    ) || {};

    const { messageSubject, attachments } = draftData || {};

    const { messageTemplate: newMessageTemplate } = revertPersonalizedChanges({
      messageTemplate,
    });

    console.log(
      '{[logoImgWidth]}',
      newMessageTemplate.indexOf(`{[logoImgWidth]}`)
    );

    yield call(postTestEmail, {
      toEmail: email,
      replyToEmail: email,
      subject: messageSubject,
      attachments,
      messageTemplate: newMessageTemplate,
    });

    notify('Test email sent successfully', 'success');
  } catch (e) {
    notifyError(e, false, 'Error sending test email');
  }
  yield put(setEmailSending(false));
}

export function* fetchStatsSaga({ data }) {
  try {
    yield put(setStatsLoading(true));

    const { data: result } = yield call(getStats, data);

    yield put(setStats(result));
  } catch (e) {
    notifyError(e, false, 'Error fetching stats');
  }
  yield put(setStatsLoading(false));
}

export function* deleteTemplateSaga({ data }) {
  try {
    const { id } = data;
    yield put(setLoadingTemplate(id));

    yield call(deleteBulkEmailTemplate, id);

    yield put(removeTemplate(id));
  } catch (e) {
    notifyError(e, false, 'Error fetching stats');
  }
  yield put(setLoadingTemplate(null));
}


export function* fetchAllCampaignsSaga ({}) {
  try {
    yield put(setAllCampaignsLoading(true));
    const { data } = yield call(fetchAllCampaigns);
    yield put(setAllCampaigns(data));
  } catch (e) {
    notifyError(e, false, 'Error fetching all campaigns');
  }
  yield put(setAllCampaignsLoading(false));
}

export function* updateCampaignActiveStatusSaga ({ data }) {
  try {
    const  { id , isActive}  = data;
    const { campaign: { allCampaigns } } = yield select(
      automatedMarketingSelector
    ) || [];
    const campaignIndex = allCampaigns.findIndex(ac => ac.id === id);
    allCampaigns[campaignIndex].loading = true; 
    yield put(setAllCampaigns([...allCampaigns]));
 
    const result = yield call(updateCampaign, {
      id: id,
      data: {isActive},
    });

    allCampaigns[campaignIndex] = Object.assign({}, 
      allCampaigns[campaignIndex],{
      loading: false,
      isActive
    });
    yield put(setAllCampaigns([...allCampaigns]));
    notify('Campaign status updated.', 'success');
  }
  catch(e) {
    notifyError(e, false, 'Error while updating campaign status');
  }
}

export function* deleteCampaignSaga ({data: id}) {
  try {
    const { campaign: { allCampaigns } } = yield select(
      automatedMarketingSelector
    ) || [];
    yield call(deleteCampaign, id);
    const newAllCampaigns = allCampaigns.filter(ac => ac.id !== id);
    yield put(setAllCampaigns(newAllCampaigns));
    notify('Campaign deleted.', 'success');
  }
  catch(e) {
    notifyError(e, false, 'Error while updating campaign status');
  }
}

export function* getCampaignInfoSaga ({data: id}) {
  try {
    yield put(setCampaignInfoLoading(true));
    if(id !== 'new') {
      const { data } = yield call(fetchCampaignInfo,id);
      yield put(setCampaignInfo({
        campaignInfo: data,
        savedCampaignInfo: cloneDeep(data)
      }));
    }
  } catch (e) {
    notifyError(e, false, 'Error fetching campaign info');
  }
  yield put(setCampaignInfoLoading(false));
}

export function* createOrUpdateCampaign ({ data }) {
  console.log(data);
  try {
    const { id ,...payload} = data;
    let campaignId = id;
    const { campaign: { campaignInfo } } = yield select(
      automatedMarketingSelector
    ) || {};
    yield put(setCampaignInfo({
      savingCampaignInfo: true
    }));
    if(id) { //update
      const { data } = yield call(updateCampaign, {
        id: id,
        data: payload,
      });
      yield put(setCampaignInfo({
        campaignInfo: {...campaignInfo,...payload},
        savedCampaignInfo: cloneDeep(campaignInfo)
      }));
      notify('Campaign updated.', 'success');
    } else {
      const { data } = yield call(createCampaign, {campaign: payload});
      campaignId = data.id;
      yield put(setCampaignInfo({
        campaignInfo: data,
        savedCampaignInfo: cloneDeep(data)
      }));
      yield call(history.push, `/campaigns/${campaignId}`);  
      notify('New Campaign created.', 'success');
    }
 
  }
  catch(e) {
    notifyError(e, false, 'Error while saving campaign');
  }
  finally {
    yield put(setCampaignInfo({
      savingCampaignInfo: false
    }));
  }
}


export function* deleteCampaignEmailSaga({ data }) {
  try {
    const { id } = data;
    const {
      campaign: { campaignInfo },
    } = yield select(automatedMarketingSelector);
    const {id :campaignId, campaignEmails =[] } = campaignInfo;
    if (id && campaignId) {
    const result =  yield call(deleteCampaignEmail, {
        campaignId,
        id
      });
    }
    const updatedCampaignEmails = reject(
      campaignEmails,
      c => c.id === id
    );
    yield put(updateCampaignInfo({campaignEmails: updatedCampaignEmails}));
    notify('Email removed from campaign.', 'success');
  } catch (e) {
    console.log(e);
  }
}

export function* updateCampaignEmailSaga ({ data }) {
  try {
  const { record, skipApiSave = false } = data;
  let { updatedData } = data;
  const {
    campaign: { campaignInfo },
  } = yield select(automatedMarketingSelector);
 const { id: campaignId, campaignEmails } = campaignInfo;
 const standardUserType = _.get(updatedData, 'standardUserType');
 if(standardUserType && standardUserType.length && standardUserType.indexOf('Corporate Partner & Employer') !== -1){
   // skip employer usertype for v2 hubs
   const launchGroups = [].concat(_.get(campaignInfo, 'launchGroup', []), _.get(campaignInfo, 'clusterLaunchGroup', []));
   const standardLaunchGroups = variations.filter(({ key, forStandard = false }) => launchGroups.indexOf(key) !== -1 && forStandard);
   console.log('launchGroups ', launchGroups, standardLaunchGroups);
   if(standardLaunchGroups && standardLaunchGroups.length){
    notify(`Standard launch group can't have Corporate Partner & Employer usertype`,'error');
    return true;
   }
 }
 const { id: campaignEmailId } = record;
 updatedData = omit(updatedData,'id');
 const emailIndex = campaignEmails.findIndex( e => e.id === campaignEmailId);
 campaignEmails[emailIndex] = {
  ...campaignEmails[emailIndex],
  ...updatedData};
  if(campaignId && !skipApiSave) {
    const { data } = yield call(updateCampaignEmail, {
      campaignId, 
      id:campaignEmailId, 
      data: updatedData,
    });
    notify('Campaign email updated.', 'success');
  }
  yield put(updateCampaignInfo({campaignEmails: [...campaignEmails]}));
  } catch(e) {
    notify('Failed to update email template', 'error');
  }
}
