import React, { PureComponent } from 'react';
import { message, Input, Form, Modal } from 'antd';

class SaveModal extends PureComponent {

  state = {
    name: ''
  }

  validateName = () => {
    const {name} = this.state;
    const nameLen = name.trim().length;
    return nameLen > 0; 
  }

  onOk = () => {
    if(this.validateName())
      this.props.onOk(this.state.name);
    else 
      message.warning('Please fill in a name for the config.'); 
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  }

  render() {
    return (
      <Modal
        title="Save Cluster Config"
        visible={this.props.visible}
        onOk={this.onOk}
        onCancel={this.props.onCancel}
        okText={'Save'}
      >
        <div>
          <Form>
            <Form.Item label="Cluster Config Name">
              <Input
                size="large" 
                placeholder="Name" 
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default SaveModal; 