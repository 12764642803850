import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageHeader, Card, Row, Col } from 'antd';
import { breadCrumbItemRender } from '../../../utils/helpers';
import Table from './Table';
import TierForm from './TierForm';
import TemplateForm from './TemplateForm';
import './styles/index.scss';

import {
  clearForm,
  fetchActiveTiers,
  clearManageData,
  setManageType,
  setShowForm,
  fetchGroupTierBasedOnId,
  fetchGroupTemplateBasedOnId,
  fetchGroupTiers,
  fetchGroupTemplates,
} from '../actions/manage';

const breadcrumbConf = {
  tier: {
    routes: [
      {
        path: '/groups-programs-tier',
        breadcrumbName: 'Groups and Program Tiers',
      },
      {
        path: '/groups-programs-tier/manage-tier/group',
        breadcrumbName: 'Tier',
      },
    ],
    itemRender: breadCrumbItemRender,
  },
  template: {
    routes: [
      {
        path: '/groups-programs-tier',
        breadcrumbName: 'Groups and Program Tiers',
      },
      {
        path: '/groups-programs-tier/manage-template/group',
        breadcrumbName: 'Templates',
      },
    ],
    itemRender: breadCrumbItemRender,
  },
};

class Manage extends Component {
  componentDidMount() {
    this.updateManageType();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.url !== match.url) {
      this.updateManageType();
    }
  }

  componentWillUnmount() {
    const { clearManageData } = this.props;
    clearManageData();
  }

  updateManageType = () => {
    const { match, setManageType } = this.props;
    let manageType;

    if (match.path.includes('/manage-tier')) {
      setManageType('tier');
      manageType = 'tier';
    } else if (match.path.includes('/manage-template')) {
      setManageType('template');
      manageType = 'template';
    }

    this.checkRoute(manageType);
  };

  checkRoute = manageType => {
    const {
      match,
      clearForm,
      setShowForm,
      fetchActiveTiers,
      fetchGroupTierBasedOnId,
      fetchGroupTemplateBasedOnId,
      fetchGroupTiers,
      fetchGroupTemplates,
    } = this.props;
    const id = match && match.params.id;
    const type = match && match.params.type;

    if (manageType === 'tier') fetchGroupTiers(type);
    else if (manageType === 'template') fetchGroupTemplates(type);

    if (match.path.includes('/new') || id) {
      if (match.path.includes('new')) {
        clearForm();
      }
      if (id) {
        if (manageType === 'tier') {
          fetchGroupTierBasedOnId(id);
        } else if (manageType === 'template') {
          fetchGroupTemplateBasedOnId(id);
        }
      }
      if (manageType === 'template') {
        fetchActiveTiers(type);
      }
      setShowForm(true);
    }

    if (!match.path.includes('/new') && !match.params.id) {
      setShowForm(false);
    }
  };

  render() {
    const { manageType, showForm } = this.props;
    let FormChild = TierForm;
    let title = 'Tiers';

    if (manageType === 'tier') {
      title = 'Tiers';
      FormChild = TierForm;
    } else if (manageType === 'template') {
      title = 'Templates';
      FormChild = TemplateForm;
    }

    return (
      <>
        <PageHeader
          title={<p className="header__title">Manage {title}</p>}
          breadcrumb={breadcrumbConf[manageType]}
          style={{ backgroundColor: '#ffffff' }}
        />
        <div className="main-container">
          <Row gutter={16}>
            <Col span={12}>
              <Row>
                <Card className="warning__card">
                  <div className="warning__description">
                    <p className="warning__text">
                      <span className="warning__text-bold">Warning: </span>{' '}
                      {manageType === 'tier'
                        ? `Changes
                you will make on this screen are global and will impact groups
                and programs for all associated hubs.`
                        : `Though changes made will not be reflected in existing group(s) or program(s) associated with this template, they will show in all groups and programs created from now.`}
                    </p>
                  </div>
                </Card>
              </Row>
              <Row>
                <Table />
              </Row>
            </Col>
            <Col span={12}>{showForm && <FormChild />}</Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ groupsManage }) => {
  const { showForm, manageType } = groupsManage;
  return {
    showForm,
    manageType,
  };
};

const mapDispatchToProps = {
  clearForm,
  fetchActiveTiers,
  clearManageData,
  setManageType,
  setShowForm,
  fetchGroupTierBasedOnId,
  fetchGroupTemplateBasedOnId,
  fetchGroupTiers,
  fetchGroupTemplates,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Manage)
);
