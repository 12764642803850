import { Button, Input, Icon } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  setHubGroupsSearch,
  fetchHubGroupsData,
  setHubGroupsPage,
  setHubGroupsFilterApplied,
  fetchGroupTiers,
  setSelectedTierData,
} from './actions';
import MultiSelect from '../../component/MultiSelect';

class GroupProgramTierHeader extends React.Component {
  componentDidMount() {
    const { fetchGroupTiers } = this.props;
    fetchGroupTiers();
  }

  handleSearch = e => {
    const { setHubGroupsSearch } = this.props;
    const val = e.target.value;
    setHubGroupsSearch(val);
  };

  handleClearAll = () => {
    const {
      setHubGroupsSearch,
      fetchHubGroupsData,
      setHubGroupsPage,
      setHubGroupsFilterApplied,
      setSelectedTierData,
    } = this.props;
    setHubGroupsSearch('');
    setSelectedTierData([]);
    setHubGroupsPage(1);
    setHubGroupsFilterApplied(false);
    fetchHubGroupsData();
  };

  handleApply = () => {
    const { fetchHubGroupsData, setHubGroupsFilterApplied, setHubGroupsPage } = this.props;
    setHubGroupsPage(1);
    setHubGroupsFilterApplied(true);
    fetchHubGroupsData();
  };

  handleTierChange = val => {
    const { setSelectedTierData } = this.props;
    setSelectedTierData(val);
  };

  goToPage = type => () => {
    const { history } = this.props;
    if (type === 'TIER') {
      history.push('/groups-programs-tier/manage-tier/group');
    } else if (type === 'TEMPLATE') {
      history.push('/groups-programs-tier/manage-template/group');
    }
  };

  render() {
    const {
      searchText,
      isFilterApplied,
      selectedTierData,
      tierData,
      groupsDataLoading,
    } = this.props;
    return (
      <div className="header-container">
        <div className="header-container__upper">
          <p className="header-container__text">Group and Program Tiers</p>
          <div>
            <Button
              className="header-container__btn header-container__btn-margin"
              onClick={this.goToPage('TIER')}
            >
              Manage Tiers
            </Button>
            <Button
              type="primary"
              icon="plus-circle"
              className="header-container__btn"
              onClick={this.goToPage('TEMPLATE')}
            >
              Manage Template
            </Button>
          </div>
        </div>
        <div className="header-container__row header-container__filter">
          <div className="header-container__row">
            <Input
              className="header-container__input header-container__input-margin"
              placeholder="Search by Name, Hub ID"
              prefix={<Icon type="search" />}
              onChange={this.handleSearch}
              value={searchText}
              onPressEnter={this.handleApply}
              style={{ width: '240px' }}
            />
            <MultiSelect
              optionFilterProp="children"
              dataSource={tierData}
              value={selectedTierData}
              onChange={this.handleTierChange}
              placeholder={'Filter by Tier'}
              style={{ minWidth: '230px', height: '36px' }}
              className="header__select"
            />
          </div>
          <div>
            <Button
              icon="filter"
              className="header-container__btn header-container__btn-margin"
              disabled={(!searchText && selectedTierData && selectedTierData.length === 0) || groupsDataLoading}
              onClick={this.handleApply}
            >
              Apply Filters
            </Button>
            <Button
              icon="sync"
              className={'header-container__btn'}
              disabled={!isFilterApplied}
              onClick={this.handleClearAll}
            >
              Clear All
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ groupsProgramsTier }) => {
  const {
    searchText,
    isFilterApplied,
    tierData,
    selectedTierData,
    groupsDataLoading,
  } = groupsProgramsTier;
  return {
    searchText,
    isFilterApplied,
    tierData,
    selectedTierData,
    groupsDataLoading,
  };
};

const mapDispatchToProps = {
  setHubGroupsSearch,
  fetchHubGroupsData,
  setHubGroupsPage,
  setHubGroupsFilterApplied,
  fetchGroupTiers,
  setSelectedTierData,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroupProgramTierHeader)
);
