const prefix = 'ML_SERVICE';

export const CALL_ML_SERVICE_API = `${prefix}/CALL_ML_SERVICE_API`;
export const SET_ML_SERVICE_API_RESPONSE = `${prefix}/SET_ML_SERVICE_API_RESPONSE`;

export const callMLServiceApiAction = data => ({
  type: CALL_ML_SERVICE_API,
  data,
});

export const setMLServiceApiResponseAction = data => ({
  type: SET_ML_SERVICE_API_RESPONSE,
  data,
});
