import { Button, Col, DatePicker, Divider, Icon, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import classes from '../AskAnythingMetrics.module.scss';

const { RangePicker } = DatePicker;

const DataTableFilters = ({ setFiltersToApply, isApplyingFilters }) => {
  const [filters, setFilters] = useState({});
  const [isFiltersApplied, setFiltersApplied] = useState(false);

  const handleSearchChange = useCallback(e => {
    setFilters(prevData => ({
      ...prevData,
      search: e.target.value,
    }));
  }, []);

  const handleDateRangeChange = useCallback(
    (dateObj, dateStrs) => {
      const updatedFilters = { ...filters };
      if (isEmpty(dateObj)) {
        updatedFilters.dateRange = null;
      } else {
        const [startDate, endDate] = dateStrs;
        Object.assign(updatedFilters, {
          dateRange: { start: startDate, end: endDate },
        });
      }
      setFilters(updatedFilters);
    },
    [filters]
  );

  const handleApplyFilters = useCallback(() => {
    const filtersToApply = {...filters};
    const dateFilter = get(filtersToApply, 'dateRange', null);
    if (dateFilter) {
      const { start, end } = dateFilter;
      filtersToApply.dateRange = {
        start: moment(start).utc().toISOString(),
        end: moment(end).add(1, 'day').utc().toISOString(),
      }
    }
    setFiltersToApply(filtersToApply);
    setFiltersApplied(true);
  }, [filters]);

  const clearAllFilters = useCallback(() => {
    setFilters({});
    setFiltersToApply({});
    setFiltersApplied(false);
  }, []);
  return (
    <div className={classes.dtFilters}>
      <Row type="flex">
        <Col span={9}>
          <Search
            value={get(filters, 'search', '')}
            onChange={handleSearchChange}
            placeholder="Search By Name, Hub ID"
          />
        </Col>
        <Divider className={classes.v_divider} type="vertical" />
        <Col className={classes.dateFilter}>
          <label>Filter by date</label>
          <RangePicker
            value={
              filters.dateRange
                ? [
                    moment(get(filters, 'dateRange.start')),
                    moment(get(filters, 'dateRange.end')),
                  ]
                : []
            }
            onChange={handleDateRangeChange}
            allowClear
          />
        </Col>
        <Divider className={classes.v_divider} type="vertical" />
        <Col className={classes.filterActions}>
          <Button
            type="primary"
            disabled={isApplyingFilters}
            onClick={handleApplyFilters}
          >
            <Icon type="filter" />
            Apply Filters
          </Button>
          <Button disabled={!isFiltersApplied} onClick={clearAllFilters}>
            <Icon type="sync" />
            Clear All
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DataTableFilters;
