import React from 'react';
import { capitalize } from 'lodash';
import { Table } from 'antd';

import UserData from '../UserData';

const MLRecommendations = ({ currentApi, response }) => {
  const recommendations = response?.recommendations;
  if (!recommendations?.length) {
    return <div>No Data Found</div>;
  }
  const columns = Object.keys(recommendations[0]).map(key => {
    if (key === 'id' && recommendations[0].userData) {
      return {
        title: capitalize(key),
        dataIndex: key,
        key,
        render: (t, r) => <UserData id={r.id} data={r.userData} />,
      };
    }
    return {
      title: capitalize(key),
      dataIndex: key,
      key,
    };
  });
  const dataSource = recommendations.map(resp => ({
    ...resp,
  }));

  return <Table columns={columns} dataSource={dataSource} />;
};

export default MLRecommendations;
