import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { updateActionItemApi } from '../../../api';
import { notify } from '../../../utils/notify';
import ActionItemKeys from '../queryKeys/ActionItems';
import { setFormData } from '../actions';

const updateGlobalActionItem = async body => {
  const { data } = await updateActionItemApi(body);
  return data;
};

const useUpdateActionItemMutation = page => {
  const client = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(updateGlobalActionItem, {
    onSuccess: data => {
      client.setQueryData([ActionItemKeys.ActionItems, page], oldData => ({
        ...oldData,
        actions: oldData.actions.map(obj => (data.id === obj.id ? data : obj)),
      }));
      if (data) dispatch(setFormData(data));

      notify('Action Item Updated successfully!', 'success');
    },
    onError: error => {
      notify(error.response.data.message, 'error');
    },
  });
};

export default useUpdateActionItemMutation;
