import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DefaultPathway from './index';

export default () => {
  return (
    <Switch>
      <Route
        path={`/pathway-provisioning/edit/:templateId`}
        component={DefaultPathway}
      />
      <Route path={`/pathway-provisioning/create`} component={DefaultPathway} />
      <Route path={`/pathway-provisioning`} component={DefaultPathway} />
    </Switch>
  );
};
