import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { createActionItemApi } from '../../../api';
import { notify } from '../../../utils/notify';
import { setFormData } from '../actions';
import ActionItemKeys from '../queryKeys/ActionItems';

const createGlobalActionItem = async body => {
  const { data } = await createActionItemApi(body);
  return data;
};

const useCreateActionItemMutation = ({ onSuccess }) => {
  const client = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(createGlobalActionItem, {
    onSuccess: data => {
      client.invalidateQueries([ActionItemKeys.ActionItems]);
      if (data) dispatch(setFormData(data));
      onSuccess(data);

      notify('ActionItem Created successfully!', 'success');
    },
    onError: error => {
      notify(error.response.data.message, 'error');
    },
  });
};
export default useCreateActionItemMutation;
