import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { fetchGlobalListGoals } from '../../../api';
import { setFormData } from '../actions';
import AchievementKeys from '../queryKeys/GlobalLists';

const getListGoalsApi = async listId => {
  const response = await fetchGlobalListGoals(listId);
  const { data } = response;
  return data;
};

const useGetGlobalListGoalsQuery = listId => {
  const dispatch = useDispatch();
  return useQuery(
    [AchievementKeys.Achievements, listId],
    () => getListGoalsApi(listId),
    {
      onSuccess: result => {
        dispatch(setFormData(result[0]));
      },
      enabled: !!listId,
    }
  );
};

export default useGetGlobalListGoalsQuery;
