import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';

import {
  fetchHubs as fetchHubsAction,
  setHubsDataPage as setHubsDataPageAction,
  openHub as openHubAction,
} from '../actions';

function HubsTable({
  hubsData,
  hubsDataLoading,
  hubsDataCount,
  hubsDataPage,
  fetchHubs,
  setHubsDataPage,
  openHub,
}) {
  useEffect(() => {
    fetchHubs();
  }, [fetchHubs]);

  const onPageChange = page => {
    setHubsDataPage(page);
    fetchHubs();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'hubName',
      key: 'hubName',
      render: (_, record) => (
        <>
          <p className="table-text">{record.hubName}</p>
          <span className="table-identifier-text">{record.identifier}</span>
        </>
      ),
    },
    {
      title: 'User Type Library',
      dataIndex: 'usertypeLibraryName',
      key: 'usertypeLibraryName',
      render: (_, record) => (
        <p>{record.usertypeLibraryName ? record.usertypeLibraryName : 'N.A'}</p>
      ),
    },
    {
      title: 'Cluster',
      dataIndex: 'clusterName',
      key: 'clusterName',
    },
    {
      title: 'Enabled User Type Templates',
      key: 'enabledUsertypes',
      render: (_, record) => (
        <p>
          {record.totalUsertypesAvailable
            ? `${record.usertypesInUse ?? '0'} of ${
                record.totalUsertypesAvailable
              }`
            : 'N.A'}
        </p>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (_, { clusterHub }) => (
        <Button type="link" onClick={() => openHub({ clusterHub })}>
          View User Types
        </Button>
      ),
    },
  ];

  return (
    <div className="table-container">
      <div className="table__header">
        <p className="table__header--text">Hubs</p>
      </div>
      <Table
        dataSource={hubsData}
        columns={columns}
        loading={hubsDataLoading}
        rowKey={record => record.id}
        pagination={{
          simple: false,
          total: parseInt(hubsDataCount, 10),
          current: hubsDataPage,
          onChange: onPageChange,
        }}
      />
    </div>
  );
}

const mapStateToProps = ({
  usertypeManage: { hubsData, hubsDataLoading, hubsDataCount, hubsDataPage },
}) => ({
  hubsData,
  hubsDataLoading,
  hubsDataCount,
  hubsDataPage,
});

const mapDispatchToProps = {
  fetchHubs: fetchHubsAction,
  setHubsDataPage: setHubsDataPageAction,
  openHub: openHubAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HubsTable);
