export const SET_IMAGE_QUALITY = 'SET_IMAGE_QUALITY';
export const SET_MAX_IMAGE_WIDTH = 'SET_MAX_IMAGE_WIDTH';
export const SET_FILE_PATH = 'FileUpload/SET_FILE_PATH';
export const RESET_FILE_UPLOAD_STATE = 'RESET_FILE_UPLOAD_STATE';

export const setImageQuality = data => ({
  type: SET_IMAGE_QUALITY,
  data,
});

export const setMaxImageWidth = data => ({
  type: SET_MAX_IMAGE_WIDTH,
  data,
});

export const setFilePath = data => ({
  type: SET_FILE_PATH,
  data,
});

export const resetFileUploadState = () => ({
  type: RESET_FILE_UPLOAD_STATE,
});
