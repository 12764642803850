import React, { useState } from 'react';
import { Modal, Input, Form, Radio } from 'antd';
import { find, isEmpty } from 'lodash';
import './loginReasonModal.scss';

const LoginReasonModal = props => {
  const {
    isOpen = false,
    onSubmit,
    onClose,
    title = 'Reason',
    errorMessage = 'Reason is required!',
  } = props;
  const [reason, setReason] = useState('');
  const [ticket, setTicket] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
  const [validationError, setValidationError] = useState('');

  const clearFormValues = () => {
    setReason('');
    setOtherReason('');
    setTicket('');
    setValidationError('');
  };

  const handleOnClose = () => {
    setPrimaryButtonLoading(false);
    clearFormValues();
    onClose();
  };
  const handleLogin = () => {
    // custom validation
    if (!reason) {
      setValidationError(errorMessage);
      return;
    }

    if (reason === 'ALL' && !ticket) {
      setValidationError('Please enter the ticket number');
      return;
    }

    if (reason === 'OTHER' && !otherReason) {
      setValidationError('Please enter the reason');
      return;
    }

    const getReasonToSubmit = (loginOption, key) => {
      if (key === 'ALL') {
        return `${loginOption.label} : ${ticket}`;
      }

      if (key === 'OTHER') {
        return `${loginOption.label} : ${otherReason}`;
      }

      return loginOption.label;
    };

    setPrimaryButtonLoading(true);
    const loginOption = find(loginOptions, ['key', reason]);
    const reasonToSubmit = getReasonToSubmit(loginOption, reason);
    onSubmit(reasonToSubmit);
    clearFormValues();
    setPrimaryButtonLoading(false);
    handleOnClose();
  };

  const getValidateStatus = fieldKey => {
    if (fieldKey === 'ALL' && validationError && reason === 'ALL' && !ticket) {
      return 'error';
    }

    if (
      fieldKey === 'OTHER' &&
      validationError &&
      reason === 'OTHER' &&
      !otherReason
    ) {
      return 'error';
    }

    return '';
  };

  const TextInput = ({ label, key, value, onChange, placeholder }) => (
    <Form.Item validateStatus={getValidateStatus(key)}>
      {label}{' '}
      <Input
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        style={{ width: 240 }}
        disabled={reason !== key}
      />
    </Form.Item>
  );

  const loginOptions = [
    {
      label: 'SPM - Accessing my client’s site',
      key: 'SPM',
    },
    {
      label: 'Support - Reproducing a bug',
      key: 'SUPPORT',
    },
    {
      label: 'All - Testing a ticket',
      key: 'ALL',
      inputProps: {
        label: 'All - Testing a ticket',
        key: 'ALL',
        value: ticket,
        onChange: setTicket,
        placeholder: 'PG-1234 or Paste Link',
      },
    },
    {
      label: 'Demo for a client',
      key: 'DEMO',
    },
    {
      label: 'Other',
      key: 'OTHER',
      inputProps: {
        label: 'Other',
        key: 'OTHER',
        value: otherReason,
        onChange: setOtherReason,
        placeholder: 'Add your own reason',
      },
    },
  ];

  return (
    <Modal
      title={title}
      visible={isOpen}
      onOk={handleLogin}
      okText="Confirm"
      onCancel={handleOnClose}
      confirmLoading={primaryButtonLoading}
      className="login-reason-modal"
    >
      <Form>
        <Radio.Group
          onChange={e => {
            setValidationError('');
            setReason(e.target.value);
          }}
          value={reason}
        >
          {loginOptions.map(option => (
            <Radio
              className="radio-login-reason-options"
              key={option.key}
              value={option.key}
            >
              {isEmpty(option.inputProps)
                ? option.label
                : TextInput(option.inputProps)}
            </Radio>
          ))}
          {validationError ? (
            <span className="custom-error">{validationError}</span>
          ) : null}
        </Radio.Group>
      </Form>
    </Modal>
  );
};

export default Form.create()(LoginReasonModal);
