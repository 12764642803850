export const fallbackPreviewUrl =
  'https://campuskudos-uploads.s3.amazonaws.com/admin/GJ8KFeOiQQ25pkNxTdVU_basic_email.svg';
export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'hh:mm A';

export const mergeTags = [
  {
    value: '{{firstName}}',
    name: 'First Name',
  },
  {
    value: '{{lastName}}',
    name: 'Last Name',
  },
  {
    value: '{{location}}',
    name: 'location',
  },
  {
    value: '{{fullHubDomain}}',
    name: 'Hub URL',
  },
  {
    value: '{{fullClusterDomain}}',
    name: 'Site URL',
  },
  {
    value: '{{profileURL}}',
    name: 'Profile URL',
  },
  {
    value: '{{siteName}}',
    name: 'Site Name',
  },
  {
    value: '{{matchingQuizURL}}',
    name: 'Matching Quiz',
  },
];

const SUPERADMIN_LOGO_IMG_WIDTH = '"259"';
const SUPERADMIN_TEMPLATE_HEADER_LOGO_URL =
  'https://d29j6mv5ylc4kb.cloudfront.net/admin/lPowFQtETeWYckYQRTnZ_Header-Placeholder.jpg';

// CONSTANTS FOR REPLACEMENT
const HEADER_LOGO_URL = '{[headerLogoUrl]}';
const LOGO_IMG_WIDTH = '"{[logoImgWidth]}"';
const EMAIL_BODY_TEMPLATE = '{[emailBodyTemplate]}';
const FOOTER_TEMPLATE = '{[footerTemplate]}';

// Unique color identifier to to add as replacer and also consdered valid by beefree api.
export const SUPERADMIN_HEADER_BG_COLOR_REPLACER = '#ffffffffffffff';
const HEADER_BG_COLOR = '{[headerBgColor]}';

const defaultEmailBody = `<div class=\\"txtTinyMce-wrapper\\" style=\\"font-size: 12px; line-height: 14px;\\" data-mce-style=\\"font-size: 12px; line-height: 14px;\\"><p style=\\"font-size: 12px; line-height: 14px;\\" data-mce-style=\\"font-size: 12px; line-height: 14px;\\"><span style=\\"font-size: 18px; line-height: 21px;\\" data-mce-style=\\"font-size: 18px;\\"></span></p></div>`;
const defaultFooterBody = `<div>{[footerTemplate]}</div>`;

export const personalizeHtml = messageHtml => {
  const stringifiedTemplate = messageHtml
    .replace(HEADER_LOGO_URL, SUPERADMIN_TEMPLATE_HEADER_LOGO_URL)
    .replace(LOGO_IMG_WIDTH, SUPERADMIN_LOGO_IMG_WIDTH);
  return stringifiedTemplate;
};

export const personalizeTemplate = template => {
  const stringifiedTemplate = JSON.stringify(template.messageJson)
    .replace(HEADER_LOGO_URL, SUPERADMIN_TEMPLATE_HEADER_LOGO_URL)
    .replace(LOGO_IMG_WIDTH, SUPERADMIN_LOGO_IMG_WIDTH)
    .replace(EMAIL_BODY_TEMPLATE, defaultEmailBody)
    .replace(FOOTER_TEMPLATE, defaultFooterBody)
    .replace(HEADER_BG_COLOR, SUPERADMIN_HEADER_BG_COLOR_REPLACER);

  const personalizeTemplateResult = {
    ...template,
    messageJson: JSON.parse(stringifiedTemplate),
  };
  return personalizeTemplateResult;
};

export const revertPersonalizedChanges = ({
  messageJson: json = null,
  messageTemplate: html = null,
}) => {
  let messageJson = json;
  let messageTemplate = html;

  if (messageJson) {
    const messageJsonObj = JSON.parse(messageJson);
    messageJson = JSON.stringify(messageJsonObj)
      .replace(SUPERADMIN_TEMPLATE_HEADER_LOGO_URL, HEADER_LOGO_URL)
      .replace(SUPERADMIN_LOGO_IMG_WIDTH, LOGO_IMG_WIDTH)
      .replace(SUPERADMIN_HEADER_BG_COLOR_REPLACER, HEADER_BG_COLOR);
    messageJson = JSON.parse(messageJson);
  }

  if (messageTemplate) {
    messageTemplate = messageTemplate
      .replace(SUPERADMIN_TEMPLATE_HEADER_LOGO_URL, HEADER_LOGO_URL)
      .replace(SUPERADMIN_LOGO_IMG_WIDTH, LOGO_IMG_WIDTH)
      .replace(SUPERADMIN_HEADER_BG_COLOR_REPLACER, HEADER_BG_COLOR);
  }

  return { messageJson, messageTemplate };
};

export const getFileSize = size => {
  if (size) {
    const sizeInKb = size / 1000;
    const roundedSize = Math.round(sizeInKb * 10) / 10;
    return `${roundedSize} KB`;
  }
  return '';
};

export const isTotalAttachmentSizeInLimit = attrs => {
  const MAX_TOTAL_SIZE = 10000000;

  const totalSize = attrs.reduce((a, b) => a + b.size, 0);

  return totalSize <= MAX_TOTAL_SIZE;
};

export const moduleOptions = [
  { value: 'resources', name: 'Resources' },
  { value: 'events', name: 'Events' },
  { value: 'AMA', name: 'AMA' },
  { value: 'matchmaker', name: 'Match Maker' },
  { value: 'autoMessages', name: 'Auto Messages' },
  { value: 'opportunities', name: 'Opportunities' },
  { value: 'privateProfiles', name: 'Private Profiles' },
  { value: 'intros', name: 'Intros' },
  { value: 'discussion', name: 'Discussion' },
  { value: 'userInvitation', name: 'User Invitation' },
  { value: '3rdPartyApproval', name: '3rd Party Approval' },
  { value: 'adminTasks', name: 'Admin Tasks' },
  { value: 'companies', name: 'Companies' },
  { value: 'groups', name: 'Groups' },
  { value: 'programs', name: 'Programs' },
  { value: 'convertAccount', name: 'Convert Account' },
  { value: 'customReports', name: 'Custom Reports' },
  { value: 'resumeBuilder', name: 'Resume Builder' },
  { value: 'customCards', name: 'Custom Cards' },
  { value: 'bulkEmail', name: 'Bulk Email' },
  { value: 'approveUsers', name: 'Approve Users' },
  { value: 'messageTypes', name: 'Message Types' },
  { value: 'vipProfiles', name: 'VIP Profiles' },
  { value: 'reminders', name: 'Reminder Emails' },
  { value: 'surveys', name: 'Surveys' },
  { value: 'messageProofreader', name: 'Message Proof Reader' },
  { value: 'listAndBadges', name: 'List And Badges' },
  { value: 'advisingAnalytics', name: 'Advising Analytics' },
  { value: 'tutormatch', name: 'Tutor Match' },
  { value: 'advising', name: 'Advising' },
  { value: 'siteAdminV2', name: 'Site Admin V2' },
  { value: 'messageV2', name: 'Message V2' },
  { value: 'pathways', name: 'Pathways' },
  { value: 'exploreUsersV2', name: 'Explore Users V2' },
  { value: 'membership-management', name: 'Manage Membership' },
  { value: 'app-integrations', name: 'App Integrations' },
  { value: 'userPrivacyBlock', name: 'User Privacy' },
  { value: 'helpingHands', name: 'Helping Hands Initiative' },
  { value: 'lightSignup', name: 'Light Access' },
];

export const subModuleOptions = [{ value: 'projects', name: 'Projects' }];

// export const standardUserTypeOptions = [
//   {
//     value: 'Student',
//     name: 'Student',
//   },
//   {
//     value: 'Alumni',
//     name: 'Alumni',
//   },
//   {
//     value: 'Admin',
//     name: 'Admin',
//   },
//   {
//     value: 'Advisor',
//     name: 'Advisor',
//   },
//   {
//     value: 'Restricted',
//     name: 'Restricted',
//   },
//   {
//     value: 'Graduate Student',
//     name: 'Graduate Student',
//   },
//   {
//     value: 'Corporate Partner & Employer',
//     name: 'Corporate Partner & Employer',
//   },
//   {
//     value: 'Prospective Student',
//     name: 'Prospective Student',
//   },
//   {
//     value: 'Faculty & Staff',
//     name: 'Faculty & Staff',
//   },
//   {
//     value: 'Family & Parent',
//     name: 'Family & Parent',
//   },
//   {
//     value: 'Friend of',
//     name: 'Friend of',
//   },
//   {
//     value: 'Guest Access',
//     name: 'Guest Access',
//   },
//   {
//     value: 'Other',
//     name: 'Other',
//   },
// ];

export const getSpecialLinks = (jobTemplates = []) => {
  const data = [];
  const mainLink = {
    type: 'Join Main Site',
    label: 'Main site link',
    link: '{{fullClusterDomain}}?invite={{importToken}}',
  };
  data.push(mainLink);

  const profileLink = {
    type: 'Go to Profile Page',
    label: 'Profile page link',
    link: '{{profileURL}}',
  };
  data.push(profileLink);

  const inboxLink = {
    type: 'Go to Inbox',
    label: 'Inbox link',
    link: '{{inboxURL}}',
  };
  data.push(inboxLink);

  const matchingQuizLink = {
    type: 'Go to Matching Quiz',
    label: 'Matching Quiz',
    link: '{{matchingQuizURL}}',
  };
  data.push(matchingQuizLink);

  // Add links for shareable templates
  data.push({
    type: 'Links for Shared Templates',
    label: `Join the Community - link`,
    link: `{{sharedTemplateLink}}?invite={{importToken}}`,
  });

  const projectTemplateSpecialLink = {
    type: 'Links for Project Templates',
    label: '',
    link: `{{fullHubDomain}}projects/create?tkn={{importToken}}&jobTemplate=`,
  };
  (jobTemplates || []).forEach(jobTemplate => {
    data.push({
      ...projectTemplateSpecialLink,
      label: jobTemplate.name,
      link: `${projectTemplateSpecialLink.link}${jobTemplate.id}`,
    });
  });

  return data;
};
