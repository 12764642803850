import {
  AUTH_SET_AUTH_TOKEN,
  SIGN_IN_USER,
  SIGN_OUT_USER,
  USER_AUTH_PROCESS,
} from '../actions/auth';

const initialState = {
  authStatus: 'signout',
  isAdmin: false,
  authToken: null,
};

const auth = (state = initialState, { type, data }) => {
  const isAdmin = !!(data && data.accessLevelCode);
  switch (type) {
    case USER_AUTH_PROCESS:
      return {
        ...state,
        authStatus: 'processing',
      };
    case SIGN_IN_USER:
      return {
        ...state,
        authStatus: 'signin',
        isAdmin,
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        authStatus: 'signout',
        isAdmin: false,
      };
    case AUTH_SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: data,
      };
    default:
      return state;
  }
};

export default auth;
