/* eslint-disable react/self-closing-comp */
/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Switch,
  Checkbox,
  Alert,
  Modal,
  Spin,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncFetch from '../../../../hooks/useAsyncFetch';
import { fetchCmsContentApi } from '../../../../api/cmsToolApi';
import clustersApi from '../../../../api/cluster';
import { notify } from '../../../../utils/notify';
import { sanitize } from '../../../../utils/dompurify';
import {
  fetchHelpTopics,
  saveAlumStudentOutreachTemplateApi,
  updateAlumStudentOutreachTemplateApi,
} from '../../../../api/templatesAndTips';
import { fetchAlumStudentOutreachTemplates, setFormData } from '../../actions';
import { makeUpdatedValuesFromFormValues } from '../../utils';

const { Option } = Select;

const CreateAlumStudentOutreachTemplate = ({
  form,
  topActionWrapperRef,
  onDelete,
  setActiveTab,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [error, setError] = useState(null);
  const [selectedContent, setSelectedContent] = useState({});
  const formData = useSelector(
    ({ templatesAndTips }) => templatesAndTips.formData
  );

  const { response: cmsContent, isLoading: cmsContentLoading } = useAsyncFetch(
    true,
    fetchCmsContentApi,
    {
      contentType: 'ALUM_STUDENT_OUTREACH_TEMPLATES',
      fetchAll: true,
    },
    undefined,
    { onError: () => notify(`Couldn't fetch CMS Contents!`, 'error') }
  );

  const { response: helpTopics, isLoading: helpTopicsLoading } = useAsyncFetch(
    true,
    fetchHelpTopics,
    undefined,
    { onError: () => notify(`Couldn't fetch Help Topics!`, 'error') }
  );

  const { response: clustersData, isLoading: clustersLoading } = useAsyncFetch(
    true,
    clustersApi.getAll,
    undefined,
    { onError: () => notify(`Couldn't fetch clusters!`, 'error') }
  );

  const { refetch: reSaveTemplateApi, isLoading: isSavingTemplate } =
    useAsyncFetch(false, saveAlumStudentOutreachTemplateApi, undefined, {
      onError: error => {
        notify(
          get(error, 'response.data.message', 'Failed to create template'),
          'error'
        );
      },
      onSuccess: () => {
        topActionWrapperRef.current.scrollIntoView({
          behavior: 'smooth',
        });

        dispatch(fetchAlumStudentOutreachTemplates());
        form.resetFields();
        setSelectedContent({});
        notify('Template created successfully!', 'success');
      },
    });

  const {
    refetch: reUpdateAlumStudentTemplateApi,
    isLoading: isTemplateUpdating,
  } = useAsyncFetch(false, updateAlumStudentOutreachTemplateApi, undefined, {
    onError: e => {
      notify(e.response.data.message, 'error');
    },
    onSuccess: () => {
      topActionWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
      });
      dispatch(fetchAlumStudentOutreachTemplates());
      notify('Template updated successfully!', 'success');
    },
  });

  const setPreviewContent = useCallback(
    identifier => {
      if (cmsContent?.data?.contents) {
        const data =
          cmsContent.data.contents.find(c => c.identifier === identifier) || {};
        setSelectedContent(data);
      }
    },
    [cmsContent?.data?.contents]
  );

  useEffect(() => {
    if (formData.id) {
      form.resetFields();
      setPreviewContent(formData.cmsContent);
    } else {
      setSelectedContent({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, setPreviewContent]);

  const onFinish = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const { standardDataSetOption, isGeneric, active } = values;

        if (isGeneric === false && !standardDataSetOption) {
          setError('Please select help topics for non generic templates');
          return;
        }
        setError(null);

        if (formData.id) {
          const updatedValues = makeUpdatedValuesFromFormValues(values);
          reUpdateAlumStudentTemplateApi({
            id: formData.id,
            ...updatedValues,
            standardDataSet: get(helpTopics, 'data.help-topics.fieldId'),
            active,
          });
          dispatch(
            setFormData({
              id: formData.id,
              ...updatedValues,
              active,
            })
          );
          return;
        }
        reSaveTemplateApi({
          ...values,
          standardDataSet: get(helpTopics, 'data.help-topics.fieldId'),
        });
        setActiveTab('alum-student-outreach-templates');
      }
    });
  };

  return (
    // eslint-disable-next-line no-return-assign
    <div ref={el => (containerRef.current = el)}>
      <Card>
        <h3>{formData.id ? 'Update' : 'Create'} Template Form</h3>
        <Form onSubmit={onFinish}>
          <Form.Item label="Name">
            {form.getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please input template name!' },
              ],
              initialValue: formData.name,
            })(<Input />)}
          </Form.Item>
          <Spin spinning={cmsContentLoading}>
            <Form.Item label="Select template body" style={{ marginBottom: 5 }}>
              {form.getFieldDecorator('cmsContent', {
                rules: [
                  {
                    required: true,
                    message: 'Please select template content!',
                  },
                ],
                initialValue: formData.cmsContent,
              })(
                <Select
                  allowClear
                  getPopupContainer={() => containerRef.current}
                  onChange={v => setPreviewContent(v)}
                  loading={cmsContentLoading}
                  disabled={cmsContentLoading || Boolean(formData.id)}
                >
                  {(cmsContent.data?.contents || []).map(content => (
                    <Option key={content.id} value={content.identifier}>
                      {content.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {selectedContent?.content && (
              <Alert
                style={{ marginBottom: 24 }}
                message={
                  <div>
                    <p style={{ marginBottom: 3 }}>
                      <strong>Content preview: </strong>
                      <Link to={`/cms-tool?contentId=${selectedContent.id}`}>
                        Edit content
                      </Link>
                    </p>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitize(selectedContent?.content || ''),
                      }}
                    ></div>
                  </div>
                }
                type="info"
              />
            )}
          </Spin>
          <Form.Item label="Help Topics">
            {form.getFieldDecorator('standardDataSetOption', {
              initialValue: formData.standardDataSetOption,
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                allowClear
                loading={helpTopicsLoading}
                disabled={
                  helpTopicsLoading || form.getFieldValue('isGeneric') === true
                }
              >
                {get(helpTopics, 'data.help-topics.options', []).map(
                  ({ optionId, optionTitle }) => (
                    <Option key={optionId} value={optionId}>
                      {optionTitle}
                    </Option>
                  )
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Generic template" valuePropName="checked">
            <p style={{ marginBottom: 5, lineHeight: '25px' }}>
              (This template would show in the all alum student outreach message
              view times)
            </p>
            {form.getFieldDecorator('isGeneric', {
              initialValue: formData.isGeneric,
              valuePropName: 'checked',
            })(
              <Switch disabled={form.getFieldValue('standardDataSetOption')} />
            )}
          </Form.Item>
          <Form.Item label="Clusters">
            {form.getFieldDecorator('clusters', {
              initialValue: formData.clusters || [],
            })(
              <Select
                getPopupContainer={() => containerRef.current}
                mode="multiple"
                allowClear
                loading={clustersLoading}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {(clustersData.data || []).map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('isDefault', {
              initialValue: formData.isDefault,
              valuePropName: 'checked',
            })(
              <Checkbox>
                Set as default (This template is enabled for all clusters)
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item label="Active">
            {form.getFieldDecorator('active', {
              initialValue: formData.active,
              valuePropName: 'checked',
            })(<Switch />)}
          </Form.Item>
          {error && (
            <Alert
              style={{ marginBottom: 15 }}
              description={error}
              type="error"
            />
          )}
          <Form.Item className="templates-and-tips-form-footer">
            {formData.id ? (
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete this Template?',
                    okText: 'Delete',
                    okButtonProps: {
                      type: 'danger',
                    },
                    onOk: () =>
                      onDelete('alumStudentOutreachTemplates', formData.id),
                  });
                }}
                type="danger"
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <Button
              loading={isSavingTemplate || isTemplateUpdating}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Form.create({ name: 'create alum student outreach templates' })(
  CreateAlumStudentOutreachTemplate
);
