import React from 'react';
import { PickerOverlay } from 'filestack-react';
import { message } from 'antd';
import { request } from '../../utils/request';
import './styles/FileUploader.css';

/** File upload input field */
class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      security: {},
      showPicker: false,
    };
  }

  showPicker = async () => {
    const {
      location = 'gcs',
      container = process.env.REACT_APP_GCS_BUCKET,
      path = '/uploads/',
    } = this.props;

    await request
      .post('/filestack-upload', {
        location,
        container,
        path,
      })
      .then(({ status, data }) => {
        if (status === 200) {
          this.setState({ showPicker: true, security: data });
        }
      })
      .catch(({ response }) => {
        message.error(response.data.error);
      });
  };

  render() {
    const {
      options,
      maxSize,
      quality,
      type = 'any',
      path = '/uploads/',
      render,
    } = this.props;
    const { showPicker, security } = this.state;

    const defaultOptions = {
      maxFiles: 100,
      storeTo: {
        location: 'gcs',
        path,
      },
      disableStorageKey: true,
      onFileSelected: file => ({
        ...file,
        filename: `${new Date().getTime()}${file.filename}`,
      }),
      onClose: () => {
        this.setState({ showPicker: false });
      },
    };

    switch (type) {
      case 'image':
        defaultOptions.accept = 'image/*';
        if (maxSize) {
          defaultOptions.transformations = {
            crop: {
              force: true,
              aspectRatio: maxSize / 500,
            },
            rotate: true,
          };
          if (quality) {
            defaultOptions.imageDim = [
              Math.round(maxSize - maxSize / quality),
              null,
            ];
          }
        }
        break;
      default:
        break;
    }

    return (
      <>
        {render({ onPick: this.showPicker })}
        {showPicker ? (
          <PickerOverlay
            apikey={process.env.REACT_APP_FILE_PICKER_KEY}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            pickerOptions={{ ...defaultOptions, ...options }}
            clientOptions={{ security }}
          />
        ) : null}
      </>
    );
  }
}

export default FileUploader;
