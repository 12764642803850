/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/state-in-constructor */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Form, Icon, Input, Radio, Select, Tooltip } from 'antd';
import { isEmpty, some, mapValues } from 'lodash';

import CancelModal from '../modals/CancelModal';
import ProgressBar from '../../../../../component/ProgressBar';
import NotificationAlert from '../../../shared/NotificationAlert';

import {
  deleteUpsell,
  handleActionCancelModalVisible,
  handleActionDeleteModalVisible,
  saveUpsell,
  setUpsellCtaLink,
  setUpsellCtaText,
  setUpsellDescription,
  setUpsellEmails,
  setUpsellName,
  setUpsellType,
  updateUpsellForm,
} from '../../../actions/manageActions';

import { emailRegexValidator } from '../../../../../utils/helpers';

class UpsellForm extends PureComponent {
  state = {
    emailFieldHasError: false,
    emailFieldSearchText: '',
    emailFieldErrorText: null,
  };

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.url !== match.url) {
      this.setState({
        emailFieldHasError: false,
        emailFieldSearchText: '',
        emailFieldErrorText: null,
      });
    }
  }

  checkForm = () => {
    let hasErrors = false;
    const { form } = this.props;
    if (form) {
      form.validateFields(errors => {
        if (
          !isEmpty(errors) &&
          some(errors, el => el.errors && el.errors.length)
        ) {
          hasErrors = true;
        } else {
          hasErrors = false;
        }
      });
    }
    return hasErrors;
  };

  handleInputChange = (key, e) => {
    const {
      form,
      setUpsellCtaLink,
      setUpsellCtaText,
      setUpsellDescription,
      setUpsellName,
      setUpsellType,
    } = this.props;
    const { emailFieldHasError } = this.state;
    const { value } = e.target;
    if (form) {
      form.setFieldsValue({ [key]: value });
    }
    switch (key) {
      case 'name':
        setUpsellName(value);
        break;
      case 'description':
        setUpsellDescription(value);
        break;
      case 'ctaText':
        setUpsellCtaText(value);
        break;
      case 'ctaLink':
        setUpsellCtaLink(value);
        break;
      case 'type':
        if (value === 'link' && emailFieldHasError) {
          this.setState({
            emailFieldHasError: false,
            emailFieldErrorText: null,
            emailFieldSearchText: '',
          });
          setUpsellEmails([]);
        } else if (value === 'emails') {
          setUpsellCtaLink('');
        }
        setUpsellType(value);
        break;
      default:
    }
  };

  handleSubmit = () => {
    const { upsellFormData, match, saveUpsell } = this.props;
    const hasErrors = this.checkForm();
    if (hasErrors) return;
    const objToSave = { ...upsellFormData, ...upsellFormData.settings };
    delete objToSave.settings;
    if (objToSave.type === 'link') {
      objToSave.emails = [];
      this.setState({
        emailFieldHasError: false,
        emailFieldErrorText: null,
        emailFieldSearchText: '',
      });
    } else if (objToSave.type === 'emails') {
      objToSave.ctaLink = '';
      if (isEmpty(objToSave.emails)) {
        this.setState({
          emailFieldHasError: true,
          emailFieldErrorText: 'Select email recipients cannot be empty.',
        });
        return;
      }
    }
    if (match.params && match.params.id) {
      objToSave.id = match.params.id;
    }
    saveUpsell(objToSave);
  };

  handleEmailChange = value => {
    const { setUpsellEmails } = this.props;
    const emails = value.filter(email => emailRegexValidator(email));

    setUpsellEmails(emails);
    if (isEmpty(value)) {
      this.setState({
        emailFieldHasError: true,
        emailFieldErrorText: 'Select email recipients cannot be empty.',
        emailFieldSearchText: '',
      });
    } else {
      this.setState({
        emailFieldSearchText: '',
        emailFieldHasError: false,
        emailFieldErrorText: '',
      });
    }
  };

  onEmailSearchChange = value => {
    const validEmail = emailRegexValidator(value);
    const isValidEmail = value === '' ? true : validEmail;
    let emailFieldErrorText = null;
    if (!isValidEmail)
      emailFieldErrorText =
        'Please make sure all the emails entered are valid.';

    this.setState({
      emailFieldHasError: !isValidEmail,
      emailFieldSearchText: value,
      emailFieldErrorText,
    });
  };

  checkForUrl = url => {
    const reg = /(https?):\/\/[^ "]+\.+(\w)*(\/.*)?$/;
    return reg.test(url);
  };

  validateUrl = (rule, value, cb) => {
    if (!this.checkForUrl(value)) cb('Please enter a valid URL');
    cb();
  };

  getRulesForCTALink = () => [
    {
      required: true,
      whitespace: true,
      message: 'CTA Link cannot be empty.',
    },
    {
      validator: this.validateUrl,
    },
  ];

  getHasErrors = () => {
    const { upsellForm, type } = this.props;
    let data = { ...upsellForm };
    const { emailFieldHasError } = this.state;
    if (type === 'emails') {
      data = { ...upsellForm, ctaLink: undefined };
    }
    const hasErrors = some(data, _data => {
      if (_data && !isEmpty(_data.errors)) return true;
      return false;
    });
    return hasErrors || emailFieldHasError;
  };

  render() {
    const {
      canDelete,
      ctaLink,
      ctaText,
      description,
      emails,
      form,
      handleActionCancelModalVisible,
      handleActionDeleteModalVisible,
      isSaving,
      isSingleLoading,
      match,
      name,
      type,
    } = this.props;
    const {
      emailFieldHasError,
      emailFieldSearchText,
      emailFieldErrorText,
    } = this.state;

    if (isSingleLoading) {
      return (
        <div className="form-container">
          <ProgressBar
            style={{ minHeight: '500px', backgroundColor: '#ffffff' }}
          />
        </div>
      );
    }

    const formHasErrors = this.getHasErrors();

    return (
      <>
        {match.params && match.params.id && !canDelete && (
          <NotificationAlert message="Note: Only edit this upsell action if absolutely necessary as any changes to it will be reflected in any user type templates it's associated with and hence impact the hub admin's view of this upsell modal." />
        )}
        <div className="ck-card form-container">
          <h3 className="form-container__heading">
            {match.params && match.params.id
              ? 'Edit Upsell Modal Action'
              : 'Create Upsell Modal Action'}
          </h3>
          <Form>
            <Form.Item label="Name" className="ant-form-item">
              {form.getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Name cannot be empty.',
                  },
                  {
                    max: 20,
                    message: 'Name cannot be more than 20 characters.',
                  },
                ],
                initialValue: name,
              })(
                <Input
                  placeholder="Add Name"
                  onChange={e => this.handleInputChange('name', e)}
                  autoComplete="off"
                />
              )}
            </Form.Item>
            <Form.Item label="Modal Content" className="ant-form-item">
              {form.getFieldDecorator('description', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Description cannot be empty.',
                  },
                  {
                    max: 200,
                    message: 'Description cannot be more than 200 characters.',
                  },
                ],
                initialValue: description,
              })(
                <Input.TextArea
                  placeholder="Add description text for this modal."
                  onChange={e => this.handleInputChange('description', e)}
                  autoComplete="off"
                />
              )}
            </Form.Item>
            <Form.Item label="CTA Text" className="ant-form-item">
              {form.getFieldDecorator('ctaText', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'CTA Text cannot be empty.',
                  },
                  {
                    max: 20,
                    message: 'CTA Text cannot be more than 20 characters.',
                  },
                ],
                initialValue: ctaText,
              })(
                <Input
                  placeholder="Add CTA button text"
                  onChange={e => this.handleInputChange('ctaText', e)}
                  autoComplete="off"
                />
              )}
            </Form.Item>
            <Form.Item label="Action Type" className="ant-form-item">
              {form.getFieldDecorator('type', {
                initialValue: type,
              })(
                <Radio.Group
                  onChange={e => this.handleInputChange('type', e)}
                  value={type}
                >
                  <Radio value="emails">Send Email</Radio>
                  <Radio value="link">Go to link</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            {type === 'link' && (
              <Form.Item label="Redirect URL" className="ant-form-item">
                {form.getFieldDecorator('ctaLink', {
                  rules: this.getRulesForCTALink(),
                  initialValue: ctaLink,
                })(
                  <Input
                    placeholder="Enter URL redirect link"
                    onChange={e => this.handleInputChange('ctaLink', e)}
                    autoComplete="off"
                  />
                )}
              </Form.Item>
            )}
            {type === 'emails' && (
              <Form.Item className="ant-form-item">
                <div className="ant-form-item-label">
                  <label className="ant-form-item-required">
                    Select email recipients
                  </label>
                </div>
                <div className="pg-tag-select">
                  <Select
                    mode="tags"
                    placeholder="Enter Email/s"
                    value={emails}
                    onChange={this.handleEmailChange}
                    onSearch={this.onEmailSearchChange}
                    className={`pg-tag-select--input${
                      emailFieldHasError ? ' pg-tag-select--err' : ''
                    }`}
                    dropdownClassName="pg-tag-select--dropdown"
                    searchValue={emailFieldSearchText}
                    aria-label="Enter Email/s"
                    disabled={isSaving}
                    style={{ width: '100%' }}
                  />
                  {emailFieldSearchText && !emailFieldHasError && (
                    <span className="pg-tag-select--help">
                      <Tooltip title="Hit 'enter' to add more recipients">
                        <Icon type="enter" />
                        <span>Hit enter to add more recipients</span>
                      </Tooltip>
                    </span>
                  )}
                  {emailFieldHasError && (
                    <span className="pg-tag-select--err-label">
                      <span>{emailFieldErrorText}</span>
                    </span>
                  )}
                </div>
              </Form.Item>
            )}
            <Form.Item className="ant-form-item">
              <div className="buttons">
                <div className="buttons__left">
                  {match.params && match.params.id && (
                    <>
                      <Button
                        type="danger"
                        disabled={
                          !canDelete || !match.params || !match.params.id
                        }
                        onClick={() => handleActionDeleteModalVisible(true)}
                        className="delete-btn"
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>
                <div className="buttons__right">
                  <Button
                    type="default"
                    className="primary-btn"
                    onClick={() => handleActionCancelModalVisible(true)}
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                  <CancelModal manageType="upsells" />
                  <Button
                    type="primary"
                    className="primary-btn"
                    onClick={this.handleSubmit}
                    loading={isSaving}
                    disabled={formHasErrors}
                  >
                    {match.params && match.params.id ? 'Update' : 'Save'}
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
}

const UpsellFormComponent = Form.create({
  onFieldsChange: (props, _field, allFields) => {
    props.onFieldsChange(mapValues(allFields));
  },
})(UpsellForm);

const WrappedUpsellForm = props => {
  const { updateUpsellForm } = props;
  return (
    <>
      <UpsellFormComponent {...props} onFieldsChange={updateUpsellForm} />
    </>
  );
};

const mapStateToProps = ({
  usertypeManage: {
    isSaving,
    isSingleLoading,
    manageSingleLoading,
    upsellFormData,
    upsellFormData: {
      name,
      description,
      settings: { type = '', ctaText = '', ctaLink = '', emails = [] },
      canDelete = true,
    },
    upsellForm,
  },
}) => ({
  canDelete,
  ctaLink,
  ctaText,
  description,
  emails,
  isSaving,
  isSingleLoading,
  manageSingleLoading,
  name,
  type,
  upsellFormData,
  upsellForm,
});

const mapDispatchToProps = {
  deleteUpsell,
  handleActionCancelModalVisible,
  handleActionDeleteModalVisible,
  saveUpsell,
  setUpsellCtaLink,
  setUpsellCtaText,
  setUpsellDescription,
  setUpsellEmails,
  setUpsellName,
  setUpsellType,
  updateUpsellForm,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedUpsellForm)
);
