import React from 'react';
import { get } from 'lodash';

import { personalizeHtml } from '../utils';

class PreviewEmailTemplate extends React.Component {
  isIframeContentLoaded = false;

  handleIframeRef = e => {
    this.Iframe = e;
    !this.isIframeContentLoaded && this.loadIframeContent();
  };

  setIframeHeight = () => {
    if (this.Iframe) {
      this.Iframe.height =
        get(this, 'Iframe.contentWindow.document.body.scrollHeight') &&
        get(this, 'Iframe.contentWindow.document.body.scrollHeight') + 'px';
    }
  };

  loadIframeContent = () => {
    const { previewHtml } = this.props;

    try {
      if (
        this.Iframe &&
        !this.isIframeContentLoaded &&
        this.props.previewHtml
      ) {
        let iframeDoc = this.Iframe.contentWindow.document;
        iframeDoc.open('text/html', 'replace');
        iframeDoc.write(personalizeHtml(previewHtml));
        iframeDoc.close();
        this.isIframeContentLoaded = true;
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { className } = this.props;
    return (
      <iframe
        ref={this.handleIframeRef}
        className={className}
        onLoad={this.setIframeHeight}
      />
    );
  }
}

export default PreviewEmailTemplate;
