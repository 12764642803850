import isArray from "lodash/isArray";
import {Tree} from 'antd';
import React from 'react';
import { Tooltip, Icon, Tag } from 'antd';

const { TreeNode } = Tree;

export const getRequiredFields = nodes => {
  const required = [];
  const traverse = nodes => {
    nodes.forEach(node => {
      if (node.children) traverse(node.children)
      else if(node.required && typeof node.required === 'boolean') required.push(node.key);
    })
  }
  traverse(nodes);
  return required;
};

export const getDependencies = nodes => {
  const dependencies = {};
  const traverse = nodes => {
    nodes.forEach(node => {
      if (node.children) traverse(node.children);
      else if(isArray(node.required)) dependencies[node.key] = node.required;
    });
  };
  traverse(nodes);
  return dependencies;
}

export const getLabels = nodes => {
  const labels = {};
  const traverse = nodes => {
    nodes.forEach(node => {
      if (node.children) traverse(node.children);
      else labels[node.key] = node.title;
    });
  };
  traverse(nodes);
  return labels;
}

export const trimTree = (nodes, checked) => {
  nodes = JSON.parse(JSON.stringify(nodes));
  const traverse = nodes => {
    for(let i=0; i<nodes.length; i++) {
      let node = nodes[i];
      if (node.children) {
        traverse(node.children);
        if (node.children.length === 0) nodes.splice(i--, 1);
      }
      else if(!checked.includes(node.key)) nodes.splice(i--, 1);
    }
  };
  traverse(nodes);
  return nodes
}

export const getLeaves = nodes => {
  const leaves = [];
  const traverse = nodes => {
    nodes.forEach(node => {
      if (node.children) traverse(node.children);
      else leaves.push(node.key);
    });
  };
  traverse(nodes);
  return leaves;
}

const renderTitle = (item, labels) => {
  if (!item.required) return item.title
  let content = '! required';
  if (isArray(item.required)) 
    content = `requires: ${item.required.map(rq => labels[rq]).join(", ")}`
    return (
      <div>
        <Tooltip title={content} placement="right">
          <Icon style={{ fontSize: 12 }} theme="twoTone" type="info-circle" /> {item.title}
        </Tooltip>
      </div>
  )
}

export const renderTreeNodes = (data, labels) =>
  data.map(item => {
    if (item.children) {
      return (
        <TreeNode title={item.title} key={item.key} dataRef={item}>
          {renderTreeNodes(item.children, labels)}
        </TreeNode>
      );
    }
    return <TreeNode {...item} title={renderTitle(item, labels)} />;
});
