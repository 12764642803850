const prefix = `BridgesAlliance/`;

// Actions
export const SET_HUBS_LOADING = `${prefix}SET_HUBS_LOADING`;
export const SET_HUBS_DATA = `${prefix}SET_HUBS_DATA`;
export const SET_INVITED_HUBS_DATA = `${prefix}SET_INVITED_HUBS_DATA`;

export const UPDATE_INVITED_BA_HUBS = `${prefix}UPDATE_INVITED_BA_HUBS`;
export const GET_ALL_INVITED_BA_HUBS = `${prefix}GET_ALL_INVITED_BA_HUBS`;

export const DOWNLOAD_CSV_DATA_BA_HUBS = `${prefix}DOWNLOAD_CSV_DATA_BA_HUBS`;

// Saga Actions
export const GET_HUBS_DATA = `${prefix}GET_HUBS_DATA`;

// Action Creators
export const setHubsLoading = data => ({
  type: SET_HUBS_LOADING,
  data,
});

export const setHubsData = data => ({
  type: SET_HUBS_DATA,
  data,
});

export const setAllInvitedHubsData = data => ({
  type: SET_INVITED_HUBS_DATA,
  data,
});


// Saga Action Creators
export const handleFetchHubsDataByClusterId = id => ({
  type: GET_HUBS_DATA,
  id,
});

export const addInvitedHubsToBAList = data => ({
  type: UPDATE_INVITED_BA_HUBS,
  data,
});

export const getAllInvitedBAHubs = data => ({
  type: GET_ALL_INVITED_BA_HUBS,
  data,
});

export const downloadCSVDataOfHubs = data => ({
  type: DOWNLOAD_CSV_DATA_BA_HUBS,
  data,
});
