import { request } from '../utils/request';

// eslint-disable-next-line import/prefer-default-export
export const listHubTextsAPI = data =>
  request.get('/superadmin/hub-texts', {
    params: data,
  });

export const listClusterTextsAPI = data =>
  request.get('/superadmin/cluster-texts', { params: data });

export const updateDefaultTextAPI = data =>
  request.put('/superadmin/edit-default-text', data);

export const deleteDefaultTextAPI = data =>
  request.delete('/superadmin/delete-default-text', { params: data });
