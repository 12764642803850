import { request } from '../utils/request';

export const createBenchmarkingGroup = data =>
  request.post(
    '/hub-analytics-service/superadmin/hub-benchmarking-groups',
    data
  );
export const fetchBenchmarkingGroups = () =>
  request.get('/hub-analytics-service/superadmin/hub-benchmarking-groups');
export const updateBenchmarkingGroup = (id, updatedData) =>
  request.put(
    `/hub-analytics-service/superadmin/hub-benchmarking-groups/${id}`,
    updatedData
  );
export const deleteBenchmarkingGroup = id =>
  request.delete(
    `/hub-analytics-service/superadmin/hub-benchmarking-groups/${id}`
  );
