const prefix = 'approve-deleted-hubs/';

export const FETCH_REQUESTED_HUBS_FOR_DELETION = `${prefix}FETCH_REQUESTED_HUBS_FOR_DELETION`;
export const SET_REQUESTED_HUBS_FOR_DELETION_LIST = `${prefix}SET_REQUESTED_HUBS_FOR_DELETION_LIST`;
export const DELETE_REQUESTED_HUBS_BY_ID = `${prefix}DELETE_REQUESTED_HUBS_BY_ID`;
export const APPROVE_REQUESTED_HUBS_BY_ID = `${prefix}APPROVE_REQUESTED_HUBS_BY_ID`;

// Action Creators
export const getHubDeletetionList = data => ({
  type: FETCH_REQUESTED_HUBS_FOR_DELETION,
  data,
});

export const setHubDeletetionList = data => ({
  type: SET_REQUESTED_HUBS_FOR_DELETION_LIST,
  data,
});

export const deleteRequestedHubById = data => ({
  type: DELETE_REQUESTED_HUBS_BY_ID,
  data,
});

export const approveRequestedHubById = data => ({
  type: APPROVE_REQUESTED_HUBS_BY_ID,
  data,
});
